import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Prompt } from 'react-router-dom'
import { push } from 'react-router-redux';
import moment from 'moment'
import ReactJson from 'react-json-view'

// import {

// } from '../../actions/actions.export'

import CustomField from 'kit/components/CustomField/CustomField.js'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js'
import CustomButton from 'kit/components/CustomButton/CustomButton.js'
import TabNav from 'kit/components/TabNav/TabNav.js'
import Callout from 'kit/components/Callout/Callout.js'

import './ComponentFunctionTool.scss';

var jsonSchemaGenerator = require('json-schema-generator');
  

// Load the default initialized state for this content, might include parsing or some bonus arrays or something
export const createInitPacket = (content) => {
  return {
    id: content.id,
    display_name: content.display_name,
    description: content.description,
    parameters: content.parameters,
    example_args: content.example_args
  }
}


// Parse relevant data from the content to show any unsaved changes
export const createStructuredPacket = (content) => {
  return {
    id: content.id,
    display_name: content.display_name,
    description: content.description,
    parameters: content.parameters,
    example_args: content.example_args
  }
}

// Append relevant info to download zip
const createDownloadPacket = (content, zip) => {
  zip.file(content.display_name + ".json", JSON.stringify({
    display_name: content.display_name,
    description: content.description,
    parameters: content.parameters,
    example_args: content.example_args
  }, null, 2))

  return zip;
}


// Don't change anything here, this little guy just helps us run our menu updates up the tree to the manager
const Observer = ({ value, didUpdate }) => {
  useEffect(() => {
    didUpdate(value)
  }, [value])
  return null // component does not render anything
}

class CustomComponent extends Component {
  constructor(props){
    super(props);


    this.state = {
      mode: 'generator',
      showCode: false
    }

    this.updateMenu = this.updateMenu.bind(this);
  }

  componentWillMount(){
    // setup default params
    if(!this.props.data.parameters){
      this.props.onChange({
        parameters: '{"type": "object", "properties": {}}'
      })
    }
  }

  updateMenu(){
    this.props.menuMerge([
      
    ])
  }

  render(){

    const { content, canWrite } = this.props;


    
    return <div className="component-template-tool">
      {/*invisible component to tuck the hook within this class component, don't edit*/}
      <Observer value={this.state} didUpdate={this.updateMenu} />
      {/*invisible component to tuck the hook within this class component, don't edit*/}
      <Callout
        style="info"
        title="Callable Functions are only used by OpenAI's models."
        dismissable={true}
        content={<div>
          <div>These features are unique to OpenAI and will not be leveraged if another provider's model is chosen for this agent.</div>
        </div>}
        />
      <div className="margin-bottom-2rem box">
       
        <div className="row">
          <div className="col-md-6">
            <CustomField
              value={this.props.data.display_name}
              label="Function Name"
              inline={true}
              placeholder="myFunction"
              description="How is this function declared in your code?"
              maxLength={64}
              disabled={!canWrite}
              onChange={(v)=>{
                this.props.onChange({
                  display_name: v.value
                })
              }}
              />
          </div>

          <div className="col-md-6">
            <CustomField
              value={this.props.data.description}
              label="Description"
              inline={true}
              placeholder="What does this do?"
              description="The LLM will use this to help determine the appropriate time to call the function."
              maxLength={256}
              rows={3}
              disabled={!canWrite}
              onChange={(v)=>{
                this.props.onChange({
                  description: v.value
                })
              }}
              />
          </div>
        </div>
        <div className="spacer-3rem"/>
        <TabNav
          value={this.state.mode}
          selectedStaysLit={true}
          onChange={(v)=>{
            this.setState({mode: v})
          }}
          items={[

            {
              value: 'generator',
              display: 'Schema Generator'
            },
            {
              value: 'json',
              display: 'Raw Schema Editor'
            }
          ]}
          />
        {
          this.state.mode === 'json' &&
          
          <CustomField
            value={this.props.data.parameters}
            rows={15}
            disabled={!canWrite}
            label="Valid JSON Schema"
            clientError={(v) => {
              try{
                JSON.parse(this.props.data.parameters);
                return;
              } catch(e){
                return 'Not valid JSON';
              }
              
            }}
            onChange={(v)=>{
              this.props.onChange({
                parameters: v.value
              })
            }}
            />
        }

        {
          this.state.mode === 'generator' &&
          <div className="row">
            <div className="col">
              <CustomField
                value={this.props.data.example_args}
                label="Example Argument Object"
                rows={15}
                placeholder="Paste or write an example of the JSON you'd like the LLM to pass to start creating the schema."
                clientError={(v) => {
                  try{
                    JSON.parse(this.props.data.example_args);
                    return;
                  } catch(e){
                    return 'Not valid JSON';
                  }
                  
                }}
                disabled={!canWrite}
                onChange={(v)=>{
                  let parameters = this.props.data.parameters;
                  try{
                    parameters = JSON.stringify(jsonSchemaGenerator(JSON.parse(v.value.trim())), null, 2);
                  } catch(e){
                    parameters = this.props.data.parameters;
                  }
                  this.props.onChange({
                    example_args: v.value,
                    parameters: parameters
                  })
                }}
                />
            </div>
            <div className="col">
              <CustomField
                value={this.props.data.parameters}
                label="Generated Schema"
                rows={15}
                disabled={true}
                onChange={(v)=>{
                  this.props.onChange({
                    parameters: v.value
                  })
                }}
                />
            </div>
          </div>
        }

        <Callout
          title="What is a JSON Schema?"
          style="info"
          content={<div>
            <p className="no-margin-bottom">JSON Schema is a vocabulary that allows you to annotate and validate JSON documents. It's a powerful tool for validating the structure of JSON data. For more information, visit <a href="https://json-schema.org/" target="_blank">json-schema.org</a></p>
            </div>}
          />
              
      </div>

    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, componentReducer, orgReducer, guiReducer } = state;

  return {
    userReducer, 
    componentReducer,
    orgReducer,
    guiReducer
  }
}

const Tool = connect(mapStateToProps)(CustomComponent);

export default {
  createInitPacket,
  createStructuredPacket,
  createDownloadPacket,
  Tool
}
