import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomSelect from 'kit/components/CustomSelect/CustomSelect'

export const NodeSettings_openai_embedding = ({ node, setNodeSettings, canWrite }) => {

  const [settings, setSettings] = useState(node.settings || {});
  const [supportsDimensionCropping, setSupportsDimensionCropping] = useState(false);

  useEffect(()=>{
    setSettings(node.settings);

    if(node.settings.model){
      let modelSelected = intelReducer.embedding_models.find(m => m.name === node.settings.model);
      setSupportsDimensionCropping(modelSelected.supportsDimensionCropping);
    }
  }, [node.settings]);

  const intelReducer = useSelector(state => state.intelReducer);

  return (
    <div key={settings.model}>
      <CustomSelect
        value={settings.model}
        label="Which embeddings model would you like to use?"
        placeholder="text-embedding-ada-002, text-embedding-3-small, etc"
        inline={true}
        large={true}
        required={true}
        disabled={!canWrite}
        options={intelReducer.embedding_models.filter(m => m.provider === 'OpenAI').map((model, i)=>{
          return {
            label: <div className="thin-line-height list-left">
              {model.provider === 'OpenAI' && <img src="/img/openai_logo_small.png" width="30"/>}
              {model.provider === 'Google PaLM2' && <img src="/img/palm2_logo_small.png" width="30"/>}
              {model.provider === 'Google Gemini' && <img src="/img/gemini_logo_small.png" width="30"/>}
              {model.provider === "Anthropic" && <img src="/img/anthropic_logo_small.png" width="30"/>}
              {model.provider === "Meta" && <img src="/img/meta_logo_small.png" width="30"/>}
              <div>
                <strong>{model.name}</strong><br/>
                <div className="text-ellipsis-1-lines"><small>by {model.provider}  {model.legacy ? <span className="margin-left-1rem text-tag text-tag-tiny text-tag-danger">LEGACY</span> : model.preview ? <span className="margin-left-1rem text-tag text-tag-tiny text-tag-warning">PREVIEW</span> : ""}</small></div>
              </div>
            </div>,
            value: model.name
          }
        })}
        onChange={(e) => {
          let modelSelected = intelReducer.embedding_models.find(m => m.name === e);

          setNodeSettings({
            ...settings,
            model: e,
            dimensions: modelSelected.dimensions
          })
        }}
        />

      <CustomSelect
        value={settings.dimensions}
        label="How many dimensions should the embeddings have?"
        placeholder="256, 512, 1024, etc"
        description="This will determine the size of the embeddings and cannot be changed later."
        required={true}
        large={true}
        disabled={!supportsDimensionCropping}
        options={[
          {
            label: "64",
            value: 64
          },
          {
            label: "128",
            value: 128
          },
          {
            label: "256",
            value: 256
          },
          {
            label: "512",
            value: 512
          },
          {
            label: "1024",
            value: 1024
          },
          {
            label: "1536",
            value: 1536
          },
          {
            label: "2048",
            value: 2048
          },
          // {
          //   label: "3072",
          //   value: 3072
          // }
        ].filter(d => {
          let modelSelected = intelReducer.embedding_models.find(m => m.name === settings.model);
          if(modelSelected){
            return d.value <= modelSelected.dimensions
          }
          return true;
        })}
        onChange={(e) => {

          setNodeSettings({
            ...settings,
            dimensions: e
          })
        }}
        />
    </div>
  );
}


export const NodeRenderer_openai_embedding = ({ node }) => {
  
  return (
    <div>
      <div>
        <strong>LLM</strong>
      </div>
      <div>
        <small>test</small>
      </div>
    </div>
  );
}