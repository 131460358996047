import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

// import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

// import {
  
// } from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Create an organization
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestGetMemberships = packet => {
  return {
    type: 'REQUEST_GET_MEMBERSHIPS',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveGetMembershipsSuccess = data => ({
  type: 'RECEIVE_GET_MEMBERSHIPS_SUCCESS',
  data
})

export const receiveGetMembershipsFail = data => ({
  type: 'RECEIVE_GET_MEMBERSHIPS_FAIL',
  data
})

export const tryToGetMemberships = packet => (dispatch, getState) => {

  dispatch(requestGetMemberships(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/organizations/memberships', {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveGetMembershipsSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveGetMembershipsFail({errors:e, lastSubmit: packet}));
    })
}
