import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import { addToast } from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Add content to a component's draft
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestTestComponent = packet => {
  return {
    type: 'REQUEST_AGENT_TEST_COMPONENT',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveTestComponentSuccess = data => ({
  type: 'RECEIVE_AGENT_TEST_COMPONENT_SUCCESS',
  data
})

export const receiveTestComponentFail = data => ({
  type: 'RECEIVE_AGENT_TEST_COMPONENT_FAIL',
  data
})


export const tryToTestComponent = (packet, useStream = false) => (dispatch, getState) => {


  let headers = {
    "Content-Type": "application/json"
  }
  
  
  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  let url = `/api/components/test/${packet.id}/${packet.version}/${packet.test_id}`;


  // Initialize streaming mode
  dispatch(requestTestComponent(packet));
  
  return fetch(url, {
      method: 'post',
      body: JSON.stringify(packet.data),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveTestComponentSuccess(json));
    })
    .catch(e => {
      // console.log(e);
      forceLogoutHandler(e);
      dispatch(addToast({type:'error', title: <span className="text-danger">An error occurred</span>, detail: e.message ? e.message : e}));
      dispatch(receiveTestComponentFail({errors:e, lastSubmit: packet}));
    })
    ;

}


function parseEventData(text) {
  // Initialize an object to store the parsed data
  let parsedData = {
    eventType: '',
    dataPacket: null
  };

  // Split the input text by new lines and iterate over each line
  const lines = text.split('\n');
  lines.forEach(line => {
    // Check if the line starts with 'event: '
    if (line.startsWith('event: ')) {
      parsedData.eventType = line.substring('event: '.length);
    }
    // Check if the line starts with 'data: '
    else if (line.startsWith('data: ')) {
      try {
        // Attempt to parse the JSON string after 'data: '
        parsedData.dataPacket = JSON.parse(line.substring('data: '.length));
      } catch (error) {
        // console.error('Error parsing data packet:', error);
      }
    }
  });

  return parsedData;
}


