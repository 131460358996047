import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import './MegaMarkdown.scss'; // Assuming SCSS module usage

import CodeHighlighter from 'components/CodeHighlighter/CodeHighlighter';


function useThrottledCallback(callback, delay) {
  const lastCallRef = useRef(0);
  const requestRef = useRef();

  return useCallback((...args) => {
    const now = Date.now();
    const timeSinceLastCall = now - lastCallRef.current;

    if (timeSinceLastCall >= delay) {
      lastCallRef.current = now;
      callback(...args);
    } else {
      if (requestRef.current) cancelAnimationFrame(requestRef.current);
      requestRef.current = requestAnimationFrame(() => {
        lastCallRef.current = now;
        callback(...args);
      });
    }
  }, [callback, delay]);
}

// This is the placeholder image URL you want to use
const placeholderImageUrl = '/img/loading_image.png';

// Custom renderer for links
const renderers = {
  table: ({ node, ...props }) => (
    <table className="table-basic" {...props} />
  ),
  paragraph: 'span',
  code({ node, inline, className, children, ...props }) {
    const match = /language-(\w+)/.exec(className || '');

    return !inline && match ? (
      <CodeHighlighter
        code={children}
        block={true}
        language={match ? match[1] : 'javascript'}
      />
    ) : (
      <code className={'code-styled ' + className} {...props}>
        {children}
      </code>
    );
  }
};

function preprocessMarkdown(markdown) {
  // safely convert it to a string
  markdown = String(markdown);
  
  return markdown.replace(/!\[.*?\]\([^)\s]*$/, `![Placeholder](${placeholderImageUrl})`);
}

const MegaMarkdown = React.memo(({ text }) => {
  const [processedText, setProcessedText] = useState(text);

  // Create a throttled function for processing text
  const throttledProcess = useThrottledCallback((newText) => {
    setProcessedText(preprocessMarkdown(newText));
  }, 500); // Adjust delay as needed

  useEffect(() => {
    throttledProcess(text);
  }, [text, throttledProcess]);

  return (
    <div className="mega-markdown">
      <ReactMarkdown components={renderers} remarkPlugins={[remarkGfm]}>
        {processedText}
      </ReactMarkdown>
    </div>
  );
});

MegaMarkdown.propTypes = {
  text: PropTypes.string
};

MegaMarkdown.defaultProps = {
  text: ''
};

export default MegaMarkdown;
