
import KnowledgeHome from '../tools/knowledge/KnowledgeHome/KnowledgeHome.js'

//Admin
import KnowledgeSettings from '../tools/knowledge/KnowledgeSettings/KnowledgeSettings.js'
import KnowledgeInfo from '../tools/knowledge/KnowledgeInfo/KnowledgeInfo.js'
import KnowledgeFiles from '../tools/knowledge/KnowledgeFiles/KnowledgeFiles.js'
import KnowledgeAdder from '../tools/knowledge/KnowledgeAdder/KnowledgeAdder.js'

import Team from '../tools/shared/Team/Team.js'


const tool_config = [
  // { 
  //   name: undefined,
  //   display_name: "Overview",
  //   display_icon: "book-spells",
  //   category: "",
  //   roleless: true,
  //   description: "The home dashboard for this Knowledge Base.",
  //   component: (id, canWrite) => <KnowledgeHome id={id} canWrite={canWrite}/>,
  //   keywords: "home dashboard"
  // },
  { 
    name: undefined,
    display_name: "Data",
    display_icon: "database",
    fullWidth: true,
    roleless: true,
    list_name: "info",
    description: "View and manage the chunks of information in this Knowledge Base.",
    component: (id, canWrite, item) => <KnowledgeInfo id={id} canWrite={canWrite} item={item}/>,
    keywords: "info information chunk bits info",
    getCounter: (knowledge) => {
      if(knowledge && knowledge.total_info){
        return knowledge.total_info;
      }
      return 0;
    },
  },
  { 
    name: "add",
    display_name: "Add Information",
    display_icon: "plus",
    fullWidth: true,
    description: "A guided tool for uploading and chunking information into this Knowledge Base.",
    component: (id, canWrite, item) => <KnowledgeAdder id={id} canWrite={canWrite} item={item}/>,
    keywords: "add chunk bits info information"
  },
  // { 
  //   name: "files",
  //   display_name: "Files",
  //   display_icon: "file-alt",
  //   fullWidth: true,
  //   description: "View and manage the files in this Knowledge Base.",
  //   component: (id, canWrite, item) => <KnowledgeFiles id={id} canWrite={canWrite} item={item}/>,
  //   keywords: "files information chunk bits info"
  // },
  { 
    name: "settings",
    display_name: "Settings",
    display_icon: "cog",
    description: "Change the name, description, and other metadata about this Knowledge Base.",
    component: (id, canWrite) => <KnowledgeSettings id={id} canWrite={canWrite}/>,
    keywords: "name team description meta info information metadata naming title thumbnail tags"
  },
  // { 
  //   name: "team",
  //   display_name: "Team & Permissions",
  //   display_icon: "users",
  //   description: "Invite others to collaborate on this Knowledge Base.",
  //   component: (id, canWrite, item) => <Team id={id} kind={'knowledge'} canWrite={canWrite} item={item}/>,
  //   keywords: "users user members member roles privilege editor share team"
  // },

]


export default tool_config