import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// DELETE an inquiry
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestDeleteInquiry = packet => {
  return {
    type: 'REQUEST_DELETE_INQUIRY',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveDeleteInquirySuccess = data => ({
  type: 'RECEIVE_DELETE_INQUIRY_SUCCESS',
  data
})

export const receiveDeleteInquiryFail = data => ({
  type: 'RECEIVE_DELETE_INQUIRY_FAIL',
  data
})

export const tryToDeleteInquiry = packet => (dispatch, getState) => {

  dispatch(requestDeleteInquiry(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/inquiries/' + packet.id, {
      method: 'delete',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(push('/workbench/' + packet.scope));
      dispatch(receiveDeleteInquirySuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveDeleteInquiryFail({errors:e, lastSubmit: packet}));
    })
}
