import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js';
import Callout from 'kit/components/Callout/Callout.js';

function InaccuracyReporter({ options = {}, onChange, enabled, org_id, canWrite}) {

  return (
    <div>
      <p>
        The Inaccuracy Reporter plugin allows users to report inaccuracies in the Agent's responses.
      </p>
      <p>
        When interacting with a conversational Agent, the user can simply tell it when it makes a mistake. The Agent might then ask the user to provide a correction if needed. The Agent will then log the issue and the correction automatically in the Quality Control Center for your review.
      </p>
      <CustomSelect
        label="Do users need to provide a correction for each inaccuracy they report?"
        options={[
          { value: "required", label: "Required" },
          { value: "requested", label: "Requested" },
          { value: "not_requested", label: "Not Requested" }
        ]}
        value={options.correct_answer}
        onChange={(e) => {
          onChange({
            correct_answer: e
          });
        }}
        />

      <Callout
        title="Where do inaccuracies go?"
        content={<div>
          Agent-reported inaccuracies will appear on this agent's Quality Control Center, along side any manually flagged reports via the API (coming soon).
        </div>}
        />
    </div>
  );
}

export default InaccuracyReporter;
