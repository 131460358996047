import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { push } from 'react-router-redux';

import TabNav from '../../kit/components/TabNav/TabNav.js'
import CustomButton from '../../kit/components/CustomButton/CustomButton.js'


import EditUserProfileForm from '../UserSettings/EditUserProfileForm.js'
import EditUserAvatarForm from '../UserSettings/EditUserAvatarForm.js'
import EditUserProfileVisibilityForm from '../UserSettings/EditUserProfileVisibilityForm.js'
import EditUserEmailAddressForm from '../UserSettings/EditUserEmailAddressForm.js'
import EditUserEmailSettingsForm from '../UserSettings/EditUserEmailSettingsForm.js'
import VerifyUserEmailAddressForm from '../UserSettings/VerifyUserEmailAddressForm.js'

import EditUserPasswordForm from '../UserSettings/EditUserPasswordForm.js'

import UserLoginHistory from '../UserSettings/UserLoginHistory.js'
import UserPasswordHistory from '../UserSettings/UserPasswordHistory.js'
import UserEmailHistory from '../UserSettings/UserEmailHistory.js'
import UserNotifications from '../UserNotifications/UserNotifications.js';

import UserMemberships from '../UserSettings/UserMemberships.js'
import DeleteUserAccountForm from '../UserSettings/DeleteUserAccountForm.js'

import Footer from '../Footer/Footer.js'


import {
  
} from '../../actions/actions.export'



const menu = [
  {
    display: "Name & Info",
    autoIcon: "far fa-address-card",
    value: undefined,
    section: 'profile'
  },
  {
    display: "Avatar",
    autoIcon: "far fa-user-circle",
    value: 'avatar',
    section: 'profile'
  },
  {
    display: "Email",
    autoIcon: "far fa-envelope",
    value: 'email',
    section: 'access'
  },
  {
    display: "Password",
    autoIcon: "far fa-key",
    value: 'password',
    section: 'access'
  },
  {
    display: "Logins",
    autoIcon: "far fa-door-open",
    value: 'logins',
    section: 'access'
  },
  {
    display: "All Notifications",
    autoIcon: "far fa-bell",
    value: 'notifications',
    section: 'organizations'
  },
  {
    display: "Memberships",
    autoIcon: "far fa-users",
    value: 'memberships',
    section: 'organizations'
  },
  {
    display: "Invites",
    autoIcon: "far fa-users-medical",
    value: 'invites',
    section: 'organizations'
  },

  // {
  //   display: "Manage Data",
  //   autoIcon: "far fa-file-user",
  //   value: 'manage-data',
  //   section: 'danger-zone'
  // },
  {
    display: "Delete Account",
    autoIcon: "far fa-trash",
    value: 'delete',
    section: 'danger-zone'
  }
]


class UserSettingsRoute extends Component {

  componentDidMount(){
    window.document.title = "zerowidth.ai - Settings";
  }

  componentWillReceiveProps(newprops){
    const { dispatch, orgReducer } = newprops;

    // if(orgReducer.personalOrg){
    //   dispatch(push('/dashboard/' + orgReducer.personalOrg.id + '/settings'));
    // }
  }

  componentWillMount(){
    const { dispatch, orgReducer } = this.props;

    // if(orgReducer.personalOrg){
    //   dispatch(push('/dashboard/' + orgReducer.personalOrg.id + '/settings'));
    // }
  }

  componentDidMount(){
    const { dispatch, orgReducer } = this.props;

    // if(orgReducer.personalOrg){
    //   dispatch(push('/dashboard/' + orgReducer.personalOrg.id + '/settings'));
    // }
  }


  render(){
    const { dispatch, userReducer } = this.props;


    if(!userReducer.myData.id) return <span/>;

    return <div className="workbench-component">
    <div className="flex-split flex-split-stretch flex-grow">
      <div className="workbench-kind-left-menu" style={{minWidth: '225px', width: '225px'}}>
        <div className="workbench-kind-left-menu-inner">
          <div className="flex-grow">
            
            <div className="margin-left-1rem margin-top-1rem margin-bottom-1rem">
              <small className="text-900 text-uppercase">Profile</small>
            </div>
            <TabNav
              onChange={(v)=>{
                if(v){
                  dispatch(push('/workbench/my-account/settings/' + v));
                } else {
                  dispatch(push('/workbench/my-account/settings'));
                }
              }}
              value={this.props.page}
              vertical={true}
              items={menu.filter(m => m.section === "profile")}
              />

            <div className="margin-left-1rem margin-bottom-05rem margin-top-2rem">
              <small className="text-900 text-uppercase">
                Access
              </small>
            </div>

            <TabNav
              onChange={(v)=>{
                if(v){
                  dispatch(push('/workbench/my-account/settings/' + v));
                } else {
                  dispatch(push('/workbench/my-account/settings'));
                }
              }}
              value={this.props.page}
              vertical={true}
              items={menu.filter(m => m.section === "access")}
              />


            <div className="margin-left-1rem margin-bottom-05rem margin-top-2rem">
              <small className="text-900 text-uppercase">
                Organizations
              </small>
            </div>

            <TabNav
              onChange={(v)=>{
                if(v){
                  dispatch(push('/workbench/my-account/settings/' + v));
                } else {
                  dispatch(push('/workbench/my-account/settings'));
                }
              }}
              value={this.props.page}
              vertical={true}
              items={menu.filter(m => m.section === "organizations")}
              />

            
            <div className="margin-left-1rem margin-bottom-05rem margin-top-2rem">
              <small className="text-900 text-uppercase text-danger">
                Danger Zone
              </small>
            </div>

            <TabNav
              onChange={(v)=>{
                if(v){
                  dispatch(push('/workbench/my-account/settings/' + v));
                } else {
                  dispatch(push('/workbench/my-account/settings'));
                }
              }}
              value={this.props.page}
              vertical={true}
              items={menu.filter(m => m.section === "danger-zone")}
              />

            
            <div className="padding-left-1rem padding-right-1rem text-center">
              <hr/>
              <div className="margin-bottom-1rem thin-line-height text-semi-muted">
                <small>All set?</small>
              </div>
              <CustomButton
                display="Back to the Workbench"
                size="xs"
                block={true}
                color="grey"
                to="/workbench"
                />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-grow workbench-kind-right-half scroll-parent">
        <div className="scroll-child workbench-body-padding flex-column-stretch">
          <div className="row flex-grow">
            <div className="col-md-9">
              {
                !this.props.page &&
                <EditUserProfileForm/>
              }
              {
                this.props.page === 'avatar' &&
                <EditUserAvatarForm/>
              }
            
              {
                this.props.page === "email" &&
                <div>
                  <div className="margin-bottom-2rem margin-top-05rem">
                    <h4 className="no-margin">Email</h4>
                    <small>
                      Manage your email address and settings.
                    </small>
                  </div>
                  <EditUserEmailAddressForm/>
                  <VerifyUserEmailAddressForm/>
                  
                  <EditUserEmailSettingsForm/>
                  <UserEmailHistory/>
                </div>
              }

              {
                this.props.page === 'password' &&
                <div>

                  <div className="margin-bottom-2rem margin-top-05rem">
                    <h4 className="no-margin">Password</h4>
                    <small>
                      Change your password and view your password history.
                    </small>
                  </div>
                  <EditUserPasswordForm/>
                  <UserPasswordHistory/>
                </div>
              }

              {
                this.props.page === 'logins' &&
                <div>
                  <UserLoginHistory/>
                </div>
              }


              {
                this.props.page === 'memberships' &&
                <UserMemberships/>
              }


              {
                this.props.page === 'notifications' &&
                <UserNotifications/>
              }


              {
                this.props.page === 'invites' &&
                <UserNotifications page={'organization-invites'}/>
              }


              {
                this.props.page === 'delete' &&
                <DeleteUserAccountForm/>
              }

            </div>
          </div>
          <Footer simple={true} padless={true}/>
        </div>
      </div>
    </div>
  
  </div>




   
  }
}

const mapStateToProps = (state) => {
  const { userReducer, orgReducer  } = state;

  return {
    userReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(UserSettingsRoute);
