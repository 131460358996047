import React, { Component } from 'react'
import { connect } from 'react-redux'

import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js'
import CustomField from 'kit/components/CustomField/CustomField'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import Callout from 'kit/components/Callout/Callout.js'
import Modal from 'kit/components/Modal/Modal.js'
import Checkbox from 'kit/components/Checkbox/Checkbox.js'

import MemberSearchSelect from 'components/MemberSearchSelect/MemberSearchSelect.js'
import Hydrate from 'components/Hydrate/Hydrate'

import {
  tryToSetKnowledgeSettings,
  tryToDeleteKnowledgeSet,
  tryToDeleteInstalledKnowledge,
  tryToAddTeamMember,
  tryToGetTeam
} from 'actions/actions.export'

import './KnowledgeSettings.scss';

class KnowledgeSettings extends Component {
  constructor(props){
    super(props);

    this.state = {
      display_name: "",
      description: "",
      visibility: "org",
      editability: "team",
      demoability: "public",
      team: [],
      other_projects: false,
      retypeNameDelete: ""
    }
  }

  componentWillMount(){
    const { dispatch, knowledgeReducer } = this.props;

    const knowledge = knowledgeReducer.cache[this.props.id];
    if(knowledge){
      
      this.setState({
        display_name: knowledge.display_name,
        description: knowledge.description,
        visibility: knowledge.visibility || 'org',
        editability: knowledge.editability || 'team',
        demoability: knowledge.demoability || 'public',
        other_projects: knowledge.other_projects || false,
        initialLoad: true
      })


      dispatch(tryToGetTeam({
        kind: 'knowledge',
        id: this.props.id
      }))
    } 
  }

  componentWillReceiveProps(newprops){
    const knowledge = newprops.knowledgeReducer.cache[newprops.id];
    if(knowledge && !this.state.initialLoad){
      if(!newprops.knowledgeReducer.tryingToSetKnowledgeSettings){
        this.setState({
          display_name: knowledge.display_name,
          description: knowledge.description,
          visibility: knowledge.visibility || 'org',
          editability: knowledge.editability || 'team',
          demoability: knowledge.demoability || 'public',
          other_projects: knowledge.other_projects || false,
          initialLoad: true
        })
      }
    } 

    // do we have this team?
    if(newprops.memberReducer.cache['knowledge'][newprops.id]){
      if(this.state.team.length === 0 && !newprops.memberReducer.tryingToGetTeam){

        let loadedTeam = newprops.memberReducer.cache['knowledge'][newprops.id];

        // our old team method used individual "roles" names for each tool. now we are shifting to a single role name field, so we need a safe parser/translator
        
        loadedTeam.forEach((t)=>{
          if(!t.role){
            t.role = 'read';

            if(t.roles){
              if(t.roles['settings']){
                t.role = 'admin'
              }
              if(t.roles['instructions']){
                t.role = 'write'
              }
            }
          }

          delete t.roles;
        });

        this.setState({
          team: loadedTeam
        })
      }
    }
  }

  render(){

    const { dispatch, knowledgeReducer, projectReducer, memberReducer, userReducer, orgReducer, canWrite } = this.props;

    const knowledge = knowledgeReducer.cache[this.props.id];

    if(!knowledge) return <span>Failed to load.</span>;

    const team = memberReducer.cache['knowledge'][this.props.id];

    let canSubmit = this.state.display_name && !this.state.display_name_error && !this.state.description_error;

    // figure out if we are in a project view or just a knowledge view
  
    // does the current pathname have '/projects/' in it?
    let inProject = window.location.pathname.indexOf('/projects/') !== -1;
    let project;
    if(inProject){
      // if so, we are in a project view, find the ID by grabbing the next segment of the URL
      var project_id = window.location.pathname.split('/projects/')[1].split('/')[0];
      project = projectReducer.cache[project_id];
    }

    return <div>
      <div className="box">
      <div className="">
          <div className="row">
            
            <div className="col-xl-3">
              <h5 className="no-margin">General Info</h5>
              <p className="thin-line-height margin-top-05rem padding-right-2rem">
                <small>
                  Give your Knowledge Base a name and description. This is how it will be referred to throughout ZeroWidth and can help let others know what it contains.
                </small>
              </p>
              <small className="">
                Created by <span className="text-900"><Hydrate type="user" id={knowledge.created_by}/></span><br/>
                Created at <span className="text-900">{new Date(knowledge.created_at).toLocaleString()}</span>
              </small>
            </div>
            <div className="col-xl-9">
             
                  
              <CustomField
                name="display_name"
                label="Name"
                placeholder="ex: Best Practices, Training Guides, etc."
                description="What do you call this?"
                maxLength={64}
                required={true}
                disabled={knowledgeReducer.tryingToSetKnowledgeSettings || !canWrite}
                value={this.state.display_name}
                serverError={knowledgeReducer.setKnowledgeSettingsForm.errors.display_name}
                lastSubmit={knowledgeReducer.setKnowledgeSettingsForm.lastSubmit.display_name}
                onChange={(e) => {
                  this.setState({
                    display_name: e.value,
                    display_name_error: e.error
                  })
                }}
                />


              <CustomField
                name="description"
                label="Description"
                placeholder="ex: A collection of best practices for our team."
                description="A few sentences about this Knowledge Base."
                maxLength={256}
                rows={8}
                disabled={knowledgeReducer.tryingToSetKnowledgeSettings || !canWrite}
                value={this.state.description}
                serverError={knowledgeReducer.setKnowledgeSettingsForm.errors.description}
                lastSubmit={knowledgeReducer.setKnowledgeSettingsForm.lastSubmit.description}
                onChange={(e) => {
                  this.setState({
                    description: e.value,
                    description_error: e.error
                  })
                }}
                />
            </div>
          </div>
          
          

          <div className="row">
            <div className="col-xl-12">
              <hr className=""/>
            </div>
            <div className="col-xl-3">
              <h5 className="no-margin">General Access</h5>
              <p className="thin-line-height margin-top-05rem padding-right-2rem">
                <small>
                  Manage the overall access to this Knowledge Base within this project, organization, and beyond.
                </small>
              </p>
              <p className="thin-line-height margin-top-05rem padding-right-2rem">
                <small>
                  This organization's administrators will always be able to see and manage content within this organization.
                </small>
              </p>
            </div>

            <div className="col-xl-9">

              <div className="row margin-bottom-2rem margin-top-2rem">
                <div className="col-lg-6 padding-top-1rem">
                  <span className="text-900 ">
                    Who can view this Knowledge Base and use it in projects?
                  </span>
                </div>
                <div className="col-lg-6">
                  <CustomSelect
                    inline={true}
                    required={true}
                    disabled={!canWrite || knowledgeReducer.tryingToSetKnowledgeSettings}
                    serverError={knowledgeReducer.setKnowledgeSettingsForm.errors.visibility}
                    lastSubmit={knowledgeReducer.setKnowledgeSettingsForm.lastSubmit.visibility}
                    value={this.state.visibility}
                    onChange={(v)=>{

                      let editability = this.state.editability;

                      if(v === 'team'){
                        editability = 'team';
                      }

                      this.setState({
                        visibility: v,
                        editability: editability,
                        submitted: false
                      })
                    }}
                    options={[
                      // { 
                      //   label: <span><i className="far fa-fw fa-globe icon-before-text text-success"/> <span className="text-900">Anyone on the Internet</span></span>,
                      //   value: "public"
                      // },
                      {
                        label: <span><i className="far fa-fw fa-building icon-before-text"/> <span className="text-900">Anyone in this organization</span></span>,
                        value: "org"
                      },
                      {
                        label: <span><i className="far fa-fw fa-users icon-before-text text-warning"/> <span className="text-900">Only specific people</span></span>,
                        value: "team"
                      }
                    ]}
                    />
                </div>
              </div>
              

              <div className="row margin-bottom-2rem margin-top-2rem">
                <div className="col-lg-6 padding-top-1rem">
                  <span className="text-900">
                    Who can edit this Knowledge Base?
                  </span>
                </div>
                <div className="col-lg-6">
                  <CustomSelect
                    inline={true}
                    required={true}
                    disabled={!canWrite || this.state.visibility === 'team' || knowledgeReducer.tryingToSetKnowledgeSettings || this.state.visibility === 'creator'}
                    serverError={knowledgeReducer.setKnowledgeSettingsForm.errors.editability}
                    lastSubmit={knowledgeReducer.setKnowledgeSettingsForm.lastSubmit.editability}
                    value={this.state.editability}
                    onChange={(v)=>{

                      let other_projects = this.state.other_projects;

                      if(v === 'team'){
                        other_projects = false;
                      }

                      this.setState({
                        editability: v,
                        submitted: false
                      })
                    }}
                    options={[
                      // {
                      //   label: <span><i className="far fa-fw fa-globe icon-before-text"/> <span className="text-900">Anyone on the Internet</span></span>,
                      //   value: "public"
                      // },
                      {
                        label: <span><i className="far fa-fw fa-building icon-before-text"/> <span className="text-900">Anyone in this organization</span></span>,
                        value: "org"
                      },
                      {
                        label: <span><i className="far fa-fw fa-users icon-before-text text-warning"/> <span className="text-900">Only specific people</span></span>,
                        value: "team"
                      }

                    ]}
                    />
                </div>
              </div>

              {/* <div className="row margin-bottom-2rem margin-top-2rem">
                <div className="col-lg-6 padding-top-1rem">
                  <span className="text-900">
                    Can this Knowledge Base be added to projects?
                  </span>
                </div>
                <div className="col-lg-6">
                  <CustomSelect
                    inline={true}
                    required={true}
                    disabled={!canWrite || this.state.visibility === 'team' || this.state.editability === 'team' || knowledgeReducer.tryingToSetKnowledgeSettings}
                    serverError={knowledgeReducer.setKnowledgeSettingsForm.errors.other_projects}
                    lastSubmit={knowledgeReducer.setKnowledgeSettingsForm.lastSubmit.other_projects}
                    value={this.state.other_projects}
                    onChange={(v)=>{
                      this.setState({
                        other_projects: v,
                        submitted: false
                      })
                    }}
                    options={[
                      {
                        label: <span><i className="far fa-fw fa-check icon-before-text text-success"/> <span className="text-900">Yes</span></span>,
                        value: true
                      },
                      {
                        label: <span><i className="far fa-fw fa-times icon-before-text text-danger"/> <span className="text-900">No</span></span>,
                        value: false
                      }
                    ]}
                    />
                </div>
              </div> */}
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <hr className=""/>
            </div>
            <div className="col-xl-3">
              <h5 className="no-margin">Specific Access & Administrators</h5>
              <p className="thin-line-height margin-top-05rem padding-right-2rem">
                <small>
                  Manage the specific access to this within your organization and who can manage it.
                </small>
              </p>
              <p className="thin-line-height margin-top-05rem padding-right-2rem">
                <small>
                  This organization's administrators will always be able to see and manage content within this organization, but here you can add specific people with different roles.
                </small>
              </p>
            </div>

            <div className="col-xl-9">
              <div className="flex-split margin-bottom-2rem">
                <div className="flex-grow" key={this.state.team.length}>
                  <MemberSearchSelect 
                    id={knowledge.scope}
                    placeholder="Find members in your organization by their name or email address..."
                    inline={true}
                    removeList={this.state.team}
                    value={this.state.searchedMember}
                    disabled={!canWrite}
                    onChange={(member)=>{
                        
                      let newTeam = this.state.team;
                      newTeam.push({
                        user_id: member.user_id,
                        role: 'read'
                      });

                      this.setState({
                        searchedMember: undefined,
                        team: newTeam
                      })
                    }}
                    onClear={()=>{
                      this.setState({
                        searchedMember: undefined
                      })
                    }}
                    />
                </div>
              </div>
              <div>
                <div className="row ">
                  <div className="col-sm-6">Team Member</div>
                  <div className="col-sm-6">Role</div>
                </div>
                <hr className="hr-mini"/>
                {
                  (this.state.team || []).map((m, i)=>{
                    
                    return <div key={i} className="row margin-bottom-1rem">
                      <div className="col-sm-6">
                        <Hydrate type="user" mode="lockup" id={m.user_id}/>
                      </div>
                      <div className="col-sm-6">
                        <CustomSelect
                          inline={true}
                          disabled={!canWrite || knowledgeReducer.tryingToSetKnowledgeSettings}
                          value={m.role}
                          options={[
                            {
                              label: <span><i className="far fa-fw fa-eye icon-before-text"/> <span className="text-900">Viewer</span></span>,
                              value: 'read'
                            },
                            {
                              label: <span><i className="far fa-fw fa-pencil icon-before-text"/> <span className="text-900">Editor</span></span>,
                              value: 'write'
                            },
                            {
                              label: <span><i className="far fa-fw fa-cog icon-before-text"/> <span className="text-900">Admin</span></span>,
                              value: 'admin'
                            },
                            {
                              label: <span><i className="far fa-fw fa-trash icon-before-text text-danger"/> <span className="text-900">Remove</span></span>,
                              value: 'remove'
                            }
                          ]}
                          onChange={(v)=>{
                            let updatedTeam = this.state.team;
                            if(v === 'remove'){
                              updatedTeam = updatedTeam.filter((t)=>{
                                return t.user_id !== m.user_id
                              })
                            } else {
                              updatedTeam = updatedTeam.map((t)=>{
                                if(t.user_id === m.user_id){
                                  t.role = v;
                                }
                                return t;
                              })
                            }

                            this.setState({
                              team: updatedTeam
                            })
                          }}
                          />
                      </div>
                    </div>
                  })
                }
              </div>
            </div>
          </div>
          

          <div className="row margin-top-3rem">
            <div className="flex-split">
              <div/>
              <div className="list-right">
              <CustomButton 
                display={"Save Changes"}
                thinking={knowledgeReducer.tryingToSetKnowledgeSettings}
                success={knowledgeReducer.setKnowledgeSettingsSuccess}
                fail={knowledgeReducer.setKnowledgeSettingsFail}
                disabled={!canWrite || !canSubmit || !canWrite}
                color="success"
                size=""
                onClick={()=>{
                  dispatch(tryToSetKnowledgeSettings({
                    id: knowledge.id,
                    display_name: this.state.display_name,
                    description: this.state.description,
                    visibility: this.state.visibility,
                    editability: this.state.editability,
                    other_projects: this.state.other_projects,
                    team: this.state.team
                  }))

                  this.setState({
                    submitted: true
                  })
                }}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
      
      {
        project &&
        
        <div className="box box-warning">
          <div className="row">
            <div className="col-xl-12">
              <h4 className="no-margin">Detach Knowledge Base</h4>
              <hr className="hr-mini"/>
            </div>
            <div className="col-xl-3">
              <p className="thin-line-height">
                <small>
                  Unlink this Knowledge Base from this project.
                </small>
              </p>
              
            </div>
            <div className="col-xl-9">

              <p>
                This will detach this Knowledge Base from this project and immediately disable its use via this project's API endpoints. It can be re-added and will still be available for use in other projects if it's visibility settings allow. Removing from this project will not delete it from the organization or other projects.
              </p>

              <div className="flex-split margin-top-3rem">
                <Checkbox
                  value={this.state.confirmProjectRemoval}
                  style="align-left"
                  description="I understand what happens when I remove this Knowledge Base from this project."
                  onToggle={(e)=>{
                    this.setState({
                      confirmProjectRemoval: e
                    })
                  }}
                />
                <CustomButton 
                  display="Detach from Project"
                  disabled={!canWrite || !canSubmit || !this.state.confirmProjectRemoval}
                  color="danger"
                  size="small"
                  thinking={projectReducer.tryingToDeleteInstalledKnowledge}
                  success={projectReducer.deleteInstalledKnowledgeSuccess}
                  fail={projectReducer.deleteInstalledKnowledgeFail}
                  onClick={()=>{
                    dispatch(tryToDeleteInstalledKnowledge({
                      id: project.id,
                      knowledge_id: this.props.id,
                      installed: false
                    }))
                  }}
                  />
              </div>
            </div>
          </div>
        </div>
      }

      <div className="box box-danger">
        <div className="row">
          <div className="col-xl-12">
            <h4 className="no-margin">Deletion</h4>
            <hr className="hr-mini"/>
          </div>
          <div className="col-xl-3">
            <p className="thin-line-height">
              <small>
                Remove this Knowledge Base from your organization completely.
              </small>
            </p>
          </div>
          <div className="col-xl-9">
            <p>
              This will remove all content and disable its use <span className="text-900">for all projects</span> that reference it. This cannot be undone.
            </p>
            {
                (knowledge.projects && knowledge.projects.length > 1 &&
                  <p className="text-danger text-900">
                    {
                      inProject ? 
                      <span>This Knowledge Base is used by {knowledge.projects.length - 1} other project{knowledge.projects.length - 1 > 1 && "s"}.</span>
                      :
                      <span>This Knowledge Base is used by {knowledge.projects.length} project{knowledge.projects.length !== 1 && "s"}.</span>
                    }
                  </p>
                )
              }
            <div className="flex-split margin-top-3rem">
              <Checkbox
                value={this.state.confirmDelete}
                style="align-left"
                description="I understand the consequences of deleting."
                onToggle={(e)=>{
                  this.setState({
                    confirmDelete: e
                  })
                }}
              />
              <CustomButton 
                display="Delete Completely"
                disabled={!canWrite || !canSubmit || !this.state.confirmDelete}
                color="danger"
                size="small"
                onClick={()=>{
                  this.setState({
                    deleteModal: true,
                    retypeNameDelete: ""
                  })
                }}
                />
            </div>
          </div>
        </div>            
      </div>

      <Modal
        show={this.state.deleteModal}
        exitable={true}
        onExit={e => this.setState({deleteModal: false})}
        cancelable={true}
        onCancel={e => this.setState({deleteModal: false})}
        acceptButtonLabel="Permenantly Delete"
        acceptButtonColor="danger"
        acceptButtonThinking={knowledgeReducer.tryingToDeleteKnowledge}
        acceptButtonFail={knowledgeReducer.deleteKnowledgeFail}
        acceptButtonSuccess={knowledgeReducer.deleteKnowledgeSuccess}
        acceptButtonDisabled={this.state.retypeNameDelete !== knowledge.display_name}
        acceptable={true}
        onAccept={e => {
          dispatch(tryToDeleteKnowledgeSet({
            id: knowledge.id,
            scope: knowledge.scope
          }))
        }}
        content={<div>
          <h4 className="no-margin">Are you sure?</h4>
          <p>Deleting cannot be undone and may break things.</p>

          <CustomField
            label={<span>Type the name of this Knowledge Base to confirm.</span>}
            value={this.state.retypeNameDelete}
            description={<span>The name is: <strong>{knowledge.display_name}</strong></span>}
            onChange={e => {
              this.setState({
                retypeNameDelete: e.value
              })
            }}
            />
        </div>}
        /> 
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, memberReducer, knowledgeReducer, orgReducer, projectReducer } = state;

  return {
    userReducer, 
    memberReducer,
    knowledgeReducer,
    orgReducer,
    projectReducer
  }
}

export default connect(mapStateToProps)(KnowledgeSettings);

  