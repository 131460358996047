export * from './projects/a.projects.POST';
export * from './projects/a.projects.GET.id';
export * from './projects/a.projects.GET.by-organization';

export * from './projects/a.projects.GET.api-log';

export * from './projects/a.projects.PUT.settings';


export * from './projects/a.projects.POST.install-component';
export * from './projects/a.projects.PUT.installed-component';
export * from './projects/a.projects.DELETE.installed-component';
export * from './projects/a.projects.POST.run-scheduled-component';

export * from './projects/a.projects.POST.install-knowledge';
export * from './projects/a.projects.PUT.installed-knowledge';
export * from './projects/a.projects.DELETE.installed-knowledge';

export * from './projects/a.projects.POST.api-key';
export * from './projects/a.projects.PUT.api-key';
export * from './projects/a.projects.DELETE.api-key';
export * from './projects/a.projects.GET.api-keys';

export * from './projects/a.projects.POST.query';

export * from './projects/a.projects.DELETE.id';


export const setCurrentProject = data => ({
  type: 'SET_CURRENT_PROJECT',
  data
})

export const unsetCurrentProject = data => ({
  type: 'UNSET_CURRENT_PROJECT',
  data
})

export const passProfileProjectsToCache = data => ({
  type: 'PASS_PROFILE_PROJECTS_TO_CACHE',
  data
})