var Cookies = require('js-cookie');

export const featuresReducer = (state = {
  ready: false,
  cache: {}
}, action) => {

  switch(action.type){
    case 'UPDATE_LATEST_FEATURES':
      
      return {
        ...state,
        cache: action.data,
        ready: true
      }
      break;


    case 'REQUEST_LOGGED_OUT_FEATURE_SWITCHES':
      return {
        ...state,
        tryingToGetLoggedOutFeatureSwitches: true,
      }

    case 'RECEIVE_LOGGED_OUT_FEATURE_SWITCHES_FAIL':
       return {
        ...state,
        tryingToGetLoggedOutFeatureSwitches: false,
        getLoggedOutFeatureSwitchesFail: new Date().getTime()
      }

    case 'RECEIVE_LOGGED_OUT_FEATURE_SWITCHES_SUCCESS':
       return {
        ...state,
        tryingToGetLoggedOutFeatureSwitches: false,
        getLoggedOutFeatureSwitchesSuccess: new Date().getTime()
      }




    case 'REQUEST_LOAD_FEATURES_TOKEN':
      return {
        ...state,
        tryingToLoadFeaturesToken: true
      }

    case 'RECEIVE_LOAD_FEATURES_TOKEN_FAIL':
      return {
        ...state,
        ready: true,
        tryingToLoadFeaturesToken: false
      }

    case 'RECEIVE_LOAD_FEATURES_TOKEN_SUCCESS':

      return {
        ...state,
        cache: action.data,
        ready: true,
        tryingToLoadFeaturesToken: false
      }


    // case 'LOAD_FS_TOKEN':
    //   var token = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);;
      
    //   if(token){
    //     var parsed_token = JSON.parse(window.atob(token.split('.')[1]));
      
    //     return {
    //       ...state,
    //       cache: parsed_token,
    //       ready: true
    //     }
    //   } 

    //   return {
    //     ...state,
    //     ready: true
    //   }

    default:
      return state
  }
}
