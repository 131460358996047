import React, { Component } from 'react'
import { connect } from 'react-redux'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import LoginForm from '../components/LoginForm/LoginForm.js'
import ASCIIBackground from '../components/ASCIIBackground/ASCIIBackground.js'

class LoginRoute extends Component {
  componentDidMount(){
    window.document.title = "zerowidth.ai - Sign In";
  }

  render(){
    return <div className="hf-stretched">
      <div className="fixed-big-image">
        <ASCIIBackground 
          imagePath={'/home-img/sign-in.png'} 
          foreground={'#C0C7D3'} 
          background={'#fff'} 
          autoTween={1000} 
          fontSize={16}
          placementMode={'contain-bottom'}
          />
      </div>
      <Header borderless={true}/>
      <div className="body">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-4">
              <div className="spacer-3rem"/><div className="spacer-3rem"/>
              <h1>
                Sign In
              </h1>
              <LoginForm/>
            </div>
          </div>
        </div>
      </div>
      <Footer simple={true}/>
    </div>
  }
}

const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(LoginRoute);
