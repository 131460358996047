import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux';
import moment from 'moment'
import ReactJson from 'react-json-view'

import {
  
} from 'actions/actions.export'

import Hydrate from 'components/Hydrate/Hydrate.js'

import tools from 'configs/config.component-tools.js'

import './ComponentContentThumbnail.scss';

class ComponentContentThumbnail extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      showDetails: props.showDetails || false
    }
  }


  render(){

    const { dispatch, componentReducer } = this.props;

    let content = this.props.content;
    let tool = tools.find(t => t.name === this.props.type);

    if(!content || !tool){
      return <span/>
    }

    
    let details = tool.getThumbnailDetails(content, this.props);
    let renderedDetails = tool.getRenderedDetails ? tool.getRenderedDetails(content, this.props) : null;
    let renderedName = tool.getRenderedName ? tool.getRenderedName(content, this.props) : content.display_name || "Untitled";
    let renderedIcon = tool.getRenderedThumbnailIcon ? tool.getRenderedThumbnailIcon(content, this.props) : <i className={"far fa-fw fa-" + tool.display_icon + (this.props.style ? " text-" + this.props.style : " text-muted")}/>

    const thumb = <div className="">
        <div className="flex-grow no-margin">
          <div className="flex-split flex-split-align-start">
            <div>
              <div className="text-900">
                {renderedName}
              </div>

            </div>
            <div className="list-right">
              <small className="text-muted">{moment(content.last_updated || content.created_at).fromNow()}</small>
              {
                renderedIcon
              }
            </div>
          </div>

          <div className="component-content-thumbnail-rendered-details">
            {
              renderedDetails ? renderedDetails : <span/>
            }
          </div>
          
          {
            this.props.offerDetails && 
            <div className="list-right">
              <small className="component-content-show-details-switch" onClick={()=>{ this.setState({ showDetails: !this.state.showDetails }) }}>
                { 
                  this.state.showDetails ? 
                  <span>Hide details <i className="fas fa-caret-up fa-fw"/></span>
                  :
                  <span>Show details <i className="fas fa-caret-down fa-fw"/></span>
                }
              </small>
            </div>
          }
        </div>
      </div>
    


    if(this.props.linkedComponent){
      let url = "";
      // split at agents
      let parts = window.location.pathname.split("agents");
      // console.log(parts);
      url = parts[0] + "agents/" + this.props.linkedComponent.id + "/" + this.props.type + "/" + content.id;

      return <Link to={url} className={"box box-clickable box-half-pad link-no-decoration component-content-link margin-bottom-05rem component-content-thumbnail " +  (this.props.style ? "component-content-thumbnail-" + this.props.style : "")}>
        {thumb}
      </Link>
    }

    return <div className={"component-content-thumbnail box box-half-pad " + (this.props.style ? "component-content-thumbnail-" + this.props.style : "")}>
      {thumb}
      {
        (this.state.showDetails) &&
        <div className="padding-1rem">
          <ReactJson 
            src={details} 
            collapsed={2}
            displayDataTypes={false}
            sortKeys={true}
            iconStyle="triangle"
            name={false}
            enableClipboard={false}
            />
        </div>
      }
    </div>

  }
}


const mapStateToProps = (state) => {
  const { componentReducer, knowledgeReducer, intelReducer } = state;

  return {
    componentReducer,
    knowledgeReducer,
    intelReducer
  }
}

export default connect(mapStateToProps)(ComponentContentThumbnail);

  