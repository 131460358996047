import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import addCookieWithConsent from '../../utilities/addCookieWithConsent';


import {
  tryToValidateSession,
  dismissToast
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// REGISTER
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestRegister = credentials => {
  return {
    type: 'REQUEST_REGISTER',
    data: {
      lastSubmit: credentials
    }
  }
}

export const receiveRegisterSuccess = data => ({
  type: 'RECEIVE_REGISTER_SUCCESS',
  data
})

export const receiveRegisterFail = data => ({
  type: 'RECEIVE_REGISTER_FAIL',
  data
})

export const tryToRegister = credentials => (dispatch, getState) => {

  dispatch(requestRegister(credentials));

  let headers = {
    "Content-Type": "application/json",
  }

  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/users/register', {
      method: 'post',
      body: JSON.stringify(credentials),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveRegisterSuccess(json.data));
      dispatch(tryToValidateSession());
      dispatch(dismissToast({id: 'cookieConsent'}));
      
    })
    .catch(e => {
      dispatch(receiveRegisterFail({errors:e, lastSubmit: credentials}));
    })
}
