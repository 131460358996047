import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import shortid from 'shortid'
import * as d3 from 'd3'
import moment from 'moment'

import './SimpleChart.scss';

import CustomButton from '../CustomButton/CustomButton'

import {
  showTooltip,
  hideTooltip
} from '../../actions/actions.tooltip'

let width, height;



class SimpleChart extends Component {
  constructor(props){
    super(props);

    this.state = {
      id: shortid.generate()
    }
    
    this.update = this.update.bind(this);
  }

  componentDidMount(){

    var el = document.getElementById('simple-chart-svg-' + this.state.id);
    if(!el) return;

    var positionInfo = el.getBoundingClientRect();
    width = positionInfo.width;
    height = this.props.height || positionInfo.height;

    var svg = d3.select('#simple-chart-svg-' + this.state.id)
      .attr('width', width)
      .attr('height', height)
      ;

    setTimeout(()=>{
      this.update();  
    }, 10)

    window.addEventListener('resize', this.update);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update);
  }

  update(){
    const { dispatch } = this.props;

    let data = this.props.data;
    if(!data) return;

    if(data[0].value === undefined){
      data = data.map(d => {
        return {
          value: d
        }
      })
    }


    
    let el = document.getElementById('simple-chart-svg-' + this.state.id);
    if(!el) return;

    let positionInfo = el.getBoundingClientRect();
    width = positionInfo.width;
    height = this.props.height || positionInfo.height;

    let svg = d3.select('#simple-chart-svg-' + this.state.id)
      .attr('width', width)
      .attr('height', height)
      ;

    // burn it all down
    svg.selectAll('g').remove();

    let min = data.reduce((prev, curr) => prev.value < curr.value ? prev : curr).value;
    let max = data.reduce((prev, curr) => prev.value > curr.value ? prev : curr).value;

    let noData = false;
    if(min == 0 && max == 0){
      max = 1;
      noData = true;
    }

    let padding = {
      top: 3,
      bottom: 3,
      left: 1,
      right: 1
    }

    var y_scale = d3.scaleLinear()
      .domain([0, this.props.yMax || max])
      .range([height - padding.top, padding.bottom])
      ;

    let x_scale = d3.scaleLinear()
      .domain([0, data.length])
      .range([padding.left, width - padding.right])
      ;

    let drawGroup = svg.append('g');


    let barSVG = drawGroup.selectAll('bar')
      .data(data)
      .enter()
      ;

    let that = this;
    let barSpacing = 2;
    let barW = (width - padding.left - padding.right - barSpacing * (this.props.data.length - 1)) / this.props.data.length;
    
    if(barW < barSpacing){
      barSpacing--;
      barW = (width - padding.left - padding.right - barSpacing * (this.props.data.length - 1)) / this.props.data.length;
    }
    if(barW < 1) barW = 1;

    barSVG.append('rect')
      .attr('x', function(d, i){ return x_scale(i); })
      .attr('y', function(d, i){ 
        let retval = y_scale(d.value); 
        if(that.props.hintAtNonZeroBars){
          if(d.value !== 0 && (height - padding.bottom) - retval < 3) retval -= 3;
        } 
        if(retval < 0){
          retval = 0;
        }
        return retval;
      })
      .attr('width', barW)
      .attr('height', function(d, i){ 
        let retval = y_scale(d.value); 
        if(that.props.hintAtNonZeroBars){
          if(d.value !== 0 && (height - padding.bottom) - retval < 3) retval -= 3;
        } 
        if(retval < 0){
          retval = 0;
        }

        return height - padding.bottom - retval; 
      })
      .attr('rx', 2)
      .attr('ry', 2)
      .attr('opacity', function(d, i){ 
        if(y_scale(d.value) < padding.top){
          return .5;
        }
        return 1;
      })
      .attr('class', 'simple-chart-fill')
      .attr('index', function(d,i){ return i; })
      .attr('id', function(d,i){ return 'bar_' + that.state.id + '_' + i; })
      .on('mouseover', function(e, d){
        if(!d.tooltipContent) return;

        let index = parseInt(d3.select(this).attr('index'));

        dispatch(showTooltip({
          el: document.getElementById('bar_' + that.state.id + '_' + index),
          nobr: false,
          position: 'top',
          content: <div className="text-center">
            {d.tooltipContent}
          </div>
        }))
        
      })
      .on('mouseout', function(e,d){
        if(!d.tooltipContent) return;

        dispatch(hideTooltip());
      })
      ;

    drawGroup.append('rect')
      .attr('x', 0)
      .attr('y', height - padding.bottom)
      .attr('width', width)
      .attr('height', padding.bottom)
      .attr('fill', '#fff')
      ;


    drawGroup.append('line')
      .attr('x1', padding.left)
      .attr('y1', y_scale(0))
      .attr('x2', width - padding.right)
      .attr('y2', y_scale(0))
      .attr('stroke', '#000')
      .attr('stroke-width', 2)
      ;

    if(noData){
      drawGroup.append('text')
        .text('No data available')
        .attr('text-anchor', 'middle')
        .attr('class', 'simple-chart-no-data')
        .attr('x', width / 2)
        .attr('y', height / 2)
        ;
    }

  }

  componentDidUpdate(){
    this.update()
  }


  render(){

    return <div id={"simple-chart-" + this.state.id} className={"simple-chart"}>
        {
          this.props.title && <h5 className="simple-chart-title">{this.props.title}</h5>
        }
        <svg id={"simple-chart-svg-" + this.state.id}/>
        {
          this.props.description && <div className="simple-chart-description text-muted"><small>{this.props.description}</small></div>
        }
      </div>
  }
}

const mapStateToProps = (state) => {
  // const {  } = state;

  return {
    // styleReducer
  }
}

export default connect(mapStateToProps)(SimpleChart);
