import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect.js'
import Checkbox from '../../kit/components/Checkbox/Checkbox.js'
import Callout from '../../kit/components/Callout/Callout.js'

import Hydrate from '../../components/Hydrate/Hydrate.js'

import {
  tryToGetOrganizationInvitesLog
} from '../../actions/actions.export'

class OrganizationInvitesLog extends Component {
  constructor(props){
    super(props);

    this.state = {
      
    }
  }


  componentDidMount(){
    const { dispatch, orgReducer } = this.props;

    const organization = orgReducer.cache[this.props.id];
    if(!organization.invitesLog){
      dispatch(tryToGetOrganizationInvitesLog({id: this.props.id}));
    }
  }

  render(){

    const { dispatch, orgReducer } = this.props;

    let canManageMembers = false;
    for(var i in orgReducer.memberships){
      if(orgReducer.memberships[i].organization.id === this.props.id){
        if(orgReducer.memberships[i].membership.manage_members){
          canManageMembers = true;
        }
      }
    }

    if(!canManageMembers) return <span/>;


    const organization = orgReducer.cache[this.props.id];

    return <div className={"box"}>
      
      <div className="flex-split margin-bottom-2rem">
        <h4 className="no-margin-top no-margin-bottom">
          Invite History
        </h4>
        <div className="list-right">
          {
            (orgReducer.tryingToGetOrganizationInvitesLog) ?
            <i className="far fa-spinner-third fa-spin text-muted fa-fw"/>
            :
             <i className="far fa-sync-alt clickable icon-muted-until-hover fa-fw" onClick={()=>{ 
              dispatch(tryToGetOrganizationInvitesLog({id: this.props.id}))
            }}/>
          }
        </div>
      </div>

      {
        (organization.invitesLog !== undefined) ? 
          <div>
            {
              organization.invitesLog.length === 0 ? 
              <p className="no-margin-bottom">You haven't invited anyone to join yet.</p>
              :
              organization.invitesLog.map((e, i)=>{
                return <div className="subtle-section" key={i}>
                  <div className="margin-bottom-05rem">
                    {
                      e.event === "user invited" &&
                      <span>
                        <i className="far fa-user-plus text-semi-muted icon-before-text"/> New invite sent to <span className="text-700">{e.email}</span>
                      </span>
                    }
                    {
                      e.event === "invite retracted" &&
                      <span>
                        <i className="far fa-user-times text-danger text-semi-muted icon-before-text"/> Invite to <span className="text-700">{e.email}</span> was retracted
                      </span>
                    }
                    {
                      e.event === "invite accepted" &&
                      <span>
                        <i className="far fa-user-check text-success text-semi-muted icon-before-text"/> Invite to <span className="text-700">{e.email}</span> was accepted
                      </span>
                    }
                  </div>
                  <div className="list-left no-margin-top text-smaller">
                    <span>{moment(e.timestamp).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
                    <span className="text-very-muted">|</span>
                    {
                      e.by === 'zerowidth' ?
                      <span>by a ZeroWidth admin</span>
                      :
                      <span className="">{moment(e.timestamp).fromNow()} by <Hydrate type="user" id={e.by} field="display_name" tooltip={true}/></span>
                    }
                    
                  </div>
                </div>
              })
            }
            {
              organization.invitesLogEmpty ? 
              <p className="text-center text-muted text-smaller">
                You've reached the end of the log.
              </p>
              :
              <div className="flex-column-center margin-top-2rem">
                <CustomButton
                  display="Load More"
                  thinking={orgReducer.tryingToGetOrganizationInvitesLog}
                  success={orgReducer.getOrganizationInvitesLogSuccess}
                  fail={orgReducer.getOrganizationInvitesLogFail}
                  onClick={()=>{
                    dispatch(tryToGetOrganizationInvitesLog({
                      id: organization.id,
                      startAfter: organization.invitesLog[organization.invitesLog.length - 1].id
                    }))
                  }}
                  />
              </div>
            }
          </div>
          :
          <p className="no-margin-bottom">You haven't invited anyone to join yet.</p>
      }
    </div>
  }
}


const mapStateToProps = (state) => {
  const { orgReducer } = state;

  return {
    orgReducer
  }
}

export default connect(mapStateToProps)(OrganizationInvitesLog);
