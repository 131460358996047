import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import Checkbox from '../../kit/components/Checkbox/Checkbox.js'
import Modal from '../../kit/components/Modal/Modal.js'
import Callout from '../../kit/components/Callout/Callout.js'

import Hydrate from '../../components/Hydrate/Hydrate.js'


import {
  tryToDeleteUserAccount
} from '../../actions/actions.export'

class DeleteUserAccountForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      password: "" 
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToDeleteUserAccount({
      password: this.state.password,
    }))
  }

  render(){

    const { dispatch, userReducer, orgReducer } = this.props;

    if(!orgReducer.membershipsReady) return <span/>;
    const filteredOrgs = orgReducer.memberships;
    const isInOrgs = filteredOrgs.length > 0;

    const canSubmit = this.state.password && !this.state.password_error && this.state.agreeCheckbox && !isInOrgs;


    return <div className="">
      
      <div className="margin-bottom-2rem margin-top-05rem">
        <h4 className="no-margin">Delete Account</h4>
        <small>
          Permanently delete your ZeroWidth account.
        </small>
      </div>
      <div className="box">

        {
          isInOrgs ? 
          <div>
            <p className="no-margin-top">You're currently a member of {filteredOrgs.length > 1 ? filteredOrgs.length : "an"} organization{filteredOrgs.length > 1 ? "s" : ""}.</p>
            <p>In order to delete your account, you'll need to <span className="text-700">leave organizations</span> where you are a member and <span className="text-700">transfer ownership or delete</span> organizations where you are the owner.</p>
            <p>
              This includes deleting your Personal Workspace if you have one.
            </p>
          </div>
          :
          <div>
            <h5 className="no-margin-top">
              Account deletion is permanent.
            </h5>
            <p>
              You will immediately lose access to all your data and content. Your personal organization and all its content, agents, knowledge, and data will be deleted. After deleting, you will be logged out and redirected to the homepage.
            </p>
          </div>
        }

        <div className="spacer-2rem"/>

        <div className="list-right">
          {
            isInOrgs ?
            <CustomButton
              display="Manage Memberships"
              size="small"
              color="black"
              to={"/workbench/my-account/settings/memberships"}
              />
            :
            <CustomButton
              color="danger"
              disabled={isInOrgs}
              size="small"
              display="Delete My Account"
              onClick={()=>{ this.setState({deleteAccountModalOpen:true}) }}
              />
          }
        </div>

        <Modal
          maxWidth={500}
          show={this.state.deleteAccountModalOpen}
          content={<div>
              <h4 className="no-margin">Confirm Account Deletion</h4>
              <p>Are you sure you want to permanently delete your account?</p>
              <p>Please enter your password to confirm. This cannot be undone.</p>

              <CustomField
                name="field_password"
                type="password"
                label="Your Password"
                placeholder="********"
                required={true}
                thinking={false}
                disabled={userReducer.tryingToDeleteUserAccount}
                value={this.state.password}
                serverError={userReducer.deleteUserAccountForm.errors.password}
                lastSubmit={userReducer.deleteUserAccountForm.lastSubmit.password}
                onChange={(e) => {
                  this.setState({
                    password: e.value,
                    password_error: e.error
                  })
                }}
                />

              
              <Checkbox 
                value={this.state.agreeCheckbox} 
                onToggle={(v)=>{ this.setState({ agreeCheckbox: v }) }}
                description="I understand this cannot be undone."
                style="align-left"
                />

              {
                userReducer.deleteUserAccountForm.errors.error &&
                <Callout
                  style="danger"
                  dismissable={true}
                  content={userReducer.deleteUserAccountForm.errors.error}
                />
              }

              <div className="modal-inner-button-row">
                <CustomButton
                  display="Cancel"
                  size="xs"
                  color="grey"
                  disabled={userReducer.tryingToDeleteUserAccount}
                  onClick={()=>{ 
                    this.setState({ deleteAccountModalOpen: false }) 
                  }}
                  />
                <CustomButton
                  display={"Delete Account"}
                  size="xs"
                  color="danger"
                  thinking={userReducer.tryingToDeleteUserAccount}
                  success={userReducer.deleteUserAccountSuccess}
                  fail={userReducer.deleteUserAccountFail}
                  disabled={!canSubmit}
                  onClick={()=>{
                    if(canSubmit) this.handleSubmit();
                  }}
                  />
              </div>
            </div>}
          exitable={true}
          onExit={()=>{ 
            this.setState({ deleteAccountModalOpen: false }) 
          }}
          />
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, orgReducer } = state;

  return {
    userReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(DeleteUserAccountForm);
