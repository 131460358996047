import shortid from 'shortid';

export const addToast = data => dispatch => {

  if(!data.id) data.id = shortid.generate();

  dispatch(addToastToReducer(data));

  if(data.autoDismiss){
    setTimeout((e)=>{
      dispatch(dismissToast({id: data.id }));
    }, data.autoDismiss);
  }
}

export const addToastToReducer = data => ({
  type: 'ADD_TOAST',
  data
})


export const dismissToast = data => ({
  type: 'DISMISS_TOAST',
  data
})
