import React, { useRef, useEffect } from 'react';
import p5 from 'p5';

function ZeroHeader({ speed = 1, sliceCount = 10, text = 'ZeroWidth', color = '#000'}) {
  const canvasRef = useRef();
      let resizeTimeout;

  useEffect(() => {
    const sketch = (p) => {
      let p5Instance;
      let yNoiseOffsets = [];
      let ySlices = [];
      let font;
      let N = sliceCount;
      let fontSize;
      let sliceHeight;
      
      


      const resizeCanvasToParent = () => {
        if(!canvasRef.current) return;
        const parentWidth = canvasRef.current.offsetWidth;
        let computedHeight = canvasRef.current.offsetHeight; 
        console.log(p.canvas);
        if (p.canvas) {
          p.resizeCanvas(parentWidth, computedHeight);
        } else {
          p.createCanvas(parentWidth, computedHeight);
        }
      };

      p.preload = () => {
        font = p.loadFont('/resources/webfonts/Anuphan-VariableFont_wght.ttf'); // Replace with the actual path to your font
      };

      const setFontSize = (width) => {
        
        // Start with a rough estimate and then refine
        fontSize = p.width / (text.length * 0.5); // more aggressive starting point
        p.textSize(fontSize);
        sliceHeight = fontSize * .8;

        // // Iteratively adjust the font size until the text fits both width and height
        // while (p.textWidth(text) > p.width + 20) {
        //   fontSize -= 5;
        //   p.textSize(fontSize);  
        // }
      }

      const reset = () => {
        console.log('reset');
        resizeCanvasToParent(); 
        yNoiseOffsets = [];
        ySlices = [];

        p.clear();
        p.textFont(font);
        p.textAlign(p.CENTER, p.CENTER); // set text alignment to center
        p.textStyle(p.BOLD); // set text style to bold        
        p.stroke(color);  
        p.fill(color); 
        
        setFontSize(p.width);

        p.strokeWeight(fontSize / 14); 

        for(var y = 0; y < p.height; y += sliceHeight){

          // Draw the text
          p.text(text, p.width / 2, y + fontSize * .23);

          let slices = [];
          let noiseOffsets = [];

          const sliceWidth = p.width / N;
          for (let i = 0; i < N; i++) {
            const xOffset = i * sliceWidth;
            const sliceGraphics = p.createGraphics(sliceWidth, p.height);
            sliceGraphics.copy(p, xOffset, y, sliceWidth, sliceHeight, 0, 0, sliceWidth, sliceHeight);
            slices.push(sliceGraphics);
          }


          for (let i = 0; i < N; i++) {
            noiseOffsets.push(p.random(0, 1000)); // Random starting offsets for varied Perlin noise results
          }

          yNoiseOffsets.push(noiseOffsets);
          ySlices.push(slices);
        }
      }

      p.setup = () => {
        p.createCanvas(2000, 2000);
      
        reset(); // Adjust canvas size on setup
      };

      p.draw = () => {
        p.clear();

        for(var y = 0; y < ySlices.length; y++ ){
          let slices = ySlices[y];
          let noiseOffsets = yNoiseOffsets[y];

          const originalSliceWidth = p.width / N;
          let dynamicWidths = [];
          let totalDynamicWidth = 0;

          // 1. Compute dynamic widths for each slice
          for (let i = 0; i < N; i++) {
            const dynamicWidth = p.map(p.noise(noiseOffsets[i]), 0, 1, 0.001 * originalSliceWidth, 50 * originalSliceWidth);
            dynamicWidths.push(dynamicWidth);
            totalDynamicWidth += dynamicWidth;
          }

          // 2. & 3. Normalize each slice's width
          let xOffset = 0;
          for (let i = 0; i < N; i++) {
            const normalizedWidth = dynamicWidths[i] * (p.width / totalDynamicWidth);
            p.image(slices[i], xOffset - 1,  y * sliceHeight, normalizedWidth+1, p.height);
            xOffset += normalizedWidth;

            // Increase the noise offset more slowly for slower animation
            noiseOffsets[i] += .002 * speed;
          }
        }
      };

      p.windowResized = () => {
        // console.log('windowResized');
        // reset();
        clearTimeout(resizeTimeout)
        resizeTimeout = setTimeout(reset, 1000);
      };
    };

    let p5Instance = new p5(sketch, canvasRef.current);

    // // Handle window resize
    // const handleResize = () => {
    //   // console.log(handleResize);
    // };

    // window.addEventListener('resize', handleResize);

    return () => {
      p5Instance.remove();
      // window.removeEventListener('resize', handleResize);
    };
  }, [speed, sliceCount, text, color]);

  return <div ref={canvasRef} style={{ width: '100%' }} className="pointer-events-none"></div>;
}

export default ZeroHeader;