import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import Callout from '../../kit/components/Callout/Callout.js'
import Hydrate from '../../components/Hydrate/Hydrate.js'

import './Notification.scss';

import {
  tryToMarkUserNotificationAsRead,
  tryToGetOrganizationProfile,
  tryToGetOrganizationMembers,
  tryToGetOrganizationMembersLog,
  tryToAcceptOrganizationInvite,
  tryToGetUserProfile
} from '../../actions/actions.export'

import names from 'configs/config.names.js'

class Notification extends Component {

  constructor(props){
    super(props);

    this.state = {
      ready: true
    }
  }

  componentDidMount(){
    const { dispatch } = this.props;
    const notification = this.props.notification;

    if(!notification.read){
      setTimeout(()=>{
        dispatch(tryToMarkUserNotificationAsRead({
          id: notification.id
        }))
      }, 2000);
    }
  }
  
  render(){

    const { dispatch, userReducer, orgReducer, guiReducer } = this.props;

    const notification = this.props.notification;
    

    return <div className={"notification " + (notification.read ? "notification-read" : "notification-unread")}>
      <div className="box">
        <div className="notification-timestamp">{ moment(notification.timestamp).fromNow() }</div>

          {
            notification.type === "organization_invite" && 
            <div>
              <div className="flex-split margin-bottom-2rem margin-top-1rem">
                <i className="fal fa-users fa-fw fa-lg"/>
                {
                  this.state.ready ?
                  <div className="flex-grow margin-left-2rem">
                    
                      You've been invited to join <Hydrate  type="organization" tooltip={true} id={notification.data.organization_id} field="display_name" placeholder="an organization"/>
                  </div>
                  :
                  <div className="flex-grow margin-left-2rem">
                    <i className="fad fa-spinner-third fa-spin text-muted"/>
                  </div>
                }
              </div>

              {
                orgReducer.acceptOrganizationInviteForm.errors.error &&
                <Callout 
                  style="danger"
                  dismissable={true}
                  content={orgReducer.acceptOrganizationInviteForm.errors.error}
                  />
              }
              <div className="list-right">
                {
                  notification.completed ?
                  <CustomButton
                    display={<span><i className="fas fa-check text-success icon-before-text"/> Invite Accepted</span>}
                    color="grey"
                    size="small"
                    disabled={true}  
                    />
                  :
                  <CustomButton
                    display="Accept Invite"
                    thinking={orgReducer.tryingToAcceptOrganizationInvite}
                    success={orgReducer.acceptOrganizationInviteSuccess}
                    fail={orgReducer.acceptOrganizationInviteFail}
                    color="grey"
                    size="small"
                    onClick={()=>{
                      dispatch(tryToAcceptOrganizationInvite({
                        id: notification.data.organization_id, 
                        notification_id: notification.id,
                        invite_id: notification.data.invite_id,
                      }))
                    }}
                  />
                }
              </div>
            </div>
          }




          {
            notification.type === "organization_invite_accepted" && 
            <div>
              <div className="flex-split margin-bottom-1rem margin-top-1rem">
                <i className="fal fa-users fa-fw fa-lg"/>
                {
                  this.state.ready ?
                  <div className="flex-grow margin-left-2rem">
                    <Hydrate mode="basic" tooltip={true} type="user" id={notification.data.user_id} field="display_name" placeholder="a user"/> accepted your invitation to join <Hydrate  type="organization" tooltip={true} placeholder="an organization" id={notification.data.organization_id} field="display_name"/>
                  </div>
                  :
                  <div className="flex-grow margin-left-2rem">
                    <i className="fad fa-spinner-third fa-spin text-muted"/>
                  </div>
                }
              </div>

              <div className="list-right">
                <CustomButton
                  display="Manage Members"
                  color="grey"
                  size="small"
                  to={"/workbench/" + notification.data.organization_id + "/settings/members"}
                  onClick={()=>{
                    dispatch(tryToGetOrganizationMembers({id: notification.data.organization_id}));
                    dispatch(tryToGetOrganizationMembersLog({id: notification.data.organization_id}));
                  }}
                />
              </div>
            </div>
          }

          {
            notification.type === "kind_invite" &&
            <div>
              <div className="flex-split margin-bottom-1rem margin-top-1rem">
                <i className="fal fa-users fa-fw fa-lg"/>
                {
                  this.state.ready ?
                  <div className="flex-grow margin-left-2rem">
                    You've been added to the {notification.data.kind_display_name} {names[notification.data.kind].singular} team.
                  </div>
                  :
                  <div className="flex-grow margin-left-2rem">
                    <i className="fad fa-spinner-third fa-spin text-muted"/>
                  </div>
                }
              </div>
            </div>
          }

          {
            notification.type === "organization_joined" && 
            <div>
              <div className="flex-split margin-bottom-1rem margin-top-1rem">
                <i className="fal fa-users fa-fw fa-lg"/>
                {
                  this.state.ready ?
                  <div className="flex-grow margin-left-2rem">
                    You joined <Hydrate  type="organization" tooltip={true} id={notification.data.organization_id} field="display_name" placeholder="an organization"/>
                  </div>
                  :
                  <div className="flex-grow margin-left-2rem">
                    <i className="fad fa-spinner-third fa-spin text-muted"/>
                  </div>
                }
              </div>
            </div>
          }




          {
            notification.type === "organization_ownership_transfer" && 
            <div>
              <div className="flex-split margin-bottom-1rem margin-top-1rem">
                <i className="fal fa-users fa-fw fa-lg"/>
              {
                this.state.ready ?
                <div className="flex-grow margin-left-2rem">
                  
                  <Hydrate  
                    type="user" 
                    tooltip={true}
                    link={true}
                    id={notification.data.old_owner} 
                    field="display_name" 
                    placeholder="a user"
                    /> transfered ownership of <Hydrate  
                    type="organization" 
                    tooltip={true}
                    link={true}
                    id={notification.data.organization_id} 
                    field="display_name" 
                    placeholder="an organization"/> to you.
                </div>
                :
                <div className="flex-grow margin-left-2rem">
                  <i className="fad fa-spinner-third fa-spin text-muted"/>
                </div>
              }
              </div>
            </div>
          }

          {
            notification.type === "org_delete_confirmation" &&
            <div>
              <div className="flex-split margin-bottom-1rem margin-top-1rem">
                <i className="fal fa-users fa-fw fa-lg"/>
                {
                  this.state.ready ?
                  <div className="flex-grow margin-left-2rem">
                    You deleted the {notification.data.org_name} organization.
                  </div>
                  :
                  <div className="flex-grow margin-left-2rem">
                    <i className="fad fa-spinner-third fa-spin text-muted"/>
                  </div>
                }
              </div>
            </div>
          }

          {
            notification.type === "org_delete" &&
            <div>
              <div className="flex-split margin-bottom-1rem margin-top-1rem">
                <i className="fal fa-users fa-fw fa-lg"/>
                {
                  this.state.ready ?
                  <div className="flex-grow margin-left-2rem">
                    The {notification.data.org_name} organization that you we're a member of has been deleted.
                  </div>
                  :
                  <div className="flex-grow margin-left-2rem">
                    <i className="fad fa-spinner-third fa-spin text-muted"/>
                  </div>
                }
              </div>
            </div>
          }
      </div>
    </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, orgReducer, guiReducer, router } = state;

  return {
    userReducer, orgReducer, guiReducer, router
  }
}

export default connect(mapStateToProps)(Notification);
