const layoutNodes = (nodes, links, nodeWidth, nodeHeight, minSpacing, maxIterations = 10) => {
      // Initialize node map with layer and empty predecessors array
      const nodeMap = new Map(nodes.map(node => [node.id, { ...node, layer: 0, predecessors: [] }]));

      // Assign layers and record predecessors
      links.forEach(link => {
          const sourceNode = nodeMap.get(link.source);
          const targetNode = nodeMap.get(link.target);
          targetNode.layer = Math.max(targetNode.layer, sourceNode.layer + 1);
          targetNode.predecessors.push(sourceNode);
      });
  
      // Calculate initial average Y positions based on predecessors
      const calculateAvgYPositions = () => {
          nodeMap.forEach(node => {
              if (node.predecessors.length > 0) {
                  node.avgY = node.predecessors.reduce((acc, pred) => acc + pred.y, 0) / node.predecessors.length;
              } else {
                  node.avgY = 0;
              }
          });
      };
  
      // Assign x and y positions iteratively to stabilize layout
      for (let iter = 0; iter < maxIterations; iter++) {
          calculateAvgYPositions();
  
          let maxLayer = Math.max(...Array.from(nodeMap.values()).map(node => node.layer));
          for (let layer = 0; layer <= maxLayer; layer++) {
              let xPos = layer * (nodeWidth + minSpacing);
              let yPos = 0;
  
              // Sort nodes by average Y position within the layer
              const layerNodes = Array.from(nodeMap.values()).filter(node => node.layer === layer);
              layerNodes.sort((a, b) => a.avgY - b.avgY);
  
              // Set x and y positions based on sorted order and ensure no overlap
              layerNodes.forEach(node => {
                  node.x = xPos;
                  node.y = Math.max(node.avgY, yPos);
                  yPos = node.y + nodeHeight + minSpacing;
              });
          }
      }
  
      return Array.from(nodeMap.values());
}

export default layoutNodes;