import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import shortid from 'shortid';

import VisualProgrammingCanvas from 'components/VisualProgrammingCanvas/VisualProgrammingCanvas';
import CustomButton from 'kit/components/CustomButton/CustomButton';
import CustomField from 'kit/components/CustomField/CustomField';
import CustomSelect from 'kit/components/CustomSelect/CustomSelect';
import { tryToUpdateInquiryOpportunityMap } from 'actions/actions.export';
import layoutNodes from 'utilities/layoutNodes';
import './InquiryOpportunityMap.scss';

import categories from 'configs/config.opportunity-map';


const InquiryOpportunityMap = ({ id, canWrite }) => {
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedLink, setSelectedLink] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [links, setLinks] = useState([]);
  const [currentTool, setCurrentTool] = useState('cursor');
  const [needsSave, setNeedsSave] = useState(false);
  const [editNodeMode, setEditNodeMode] = useState(false);
  const [waitingForGenerate, setWaitingForGenerate] = useState(false);
  const [bbox, setBbox] = useState(null);

  const dispatch = useDispatch();
  const inquiryReducer = useSelector(state => state.inquiryReducer);

  // Load initial data
  useEffect(() => {
    const inquiryData = inquiryReducer.cache[id];
    if (inquiryData) {
      setLinks(inquiryData.opportunity_map_links || []);
      setNodes(inquiryData.opportunity_map_nodes || []);
    }
  }, [id, inquiryReducer.cache]);

  const saveChanges = () => {
    dispatch(tryToUpdateInquiryOpportunityMap({
      inquiry_id: id,
      opportunity_map_nodes: nodes,
      opportunity_map_links: links
    }));
    setNeedsSave(false);
  };

  // Debounced save logic
  useEffect(() => {

    let timeoutId = needsSave ? setTimeout(saveChanges, 10000) : null;

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [needsSave, nodes, links, dispatch, id]);

  const removeNode = useCallback(() => {
    if (!selectedNode) return;

    const newLinks = links.filter(link => link.source !== selectedNode && link.target !== selectedNode);
    const newNodes = nodes.filter(node => node.id !== selectedNode);

    setLinks(newLinks);
    setNodes(newNodes);
    setSelectedNode(null);
    setSelectedLink(null);
    saveChanges();
  }, [nodes, links, selectedNode]);

  const removeLink = useCallback(() => {
    if (!selectedLink) return;

    const newLinks = links.filter(link => link.source !== selectedLink.source || link.target !== selectedLink.target);

    setLinks(newLinks);
    setSelectedLink(null);
    saveChanges();
  }, [links, selectedLink]);

  const addNode = useCallback(() => {
    const newNode = {
      id: shortid.generate()
    };

    const updatedNodes = [...nodes, newNode];
    setNodes(updatedNodes);
    setNeedsSave(true);
  }, [nodes]);

  // Handlers for node and link changes in VisualProgrammingCanvas
  const handleNodeChange = useCallback((newNodes) => {
    setNodes(newNodes);
    setNeedsSave(true);
  }, []);

  const handleLinkChange = useCallback((newLinks) => {
    setLinks(newLinks);
    setNeedsSave(true);
  }, []);

  // Render the component
  if (!inquiryReducer.cache[id]) {
    return <span>Failed to load.</span>;
  }

  const foundNode = nodes.find(node => node.id === selectedNode);
  const foundLink = links.find(link => link.source === selectedLink?.source && link.target === selectedLink?.target);
  let foundLinkSource = foundLink ? nodes.find(n => n.id === foundLink.source) : null;
  let foundLinkTarget = foundLink ? nodes.find(n => n.id === foundLink.target) : null;

  const tools = [
    {
      name: 'plus',
      icon: 'fa-plus',
      onClick: addNode
    },
    {
      name: 'layout',
      icon: 'fa-magic',
      onClick: () => {
        const newNodes = layoutNodes(nodes, links, 200, 100, 100);
        newNodes.forEach(node => {
          node.autoLayout = true;
        })
        setNodes(newNodes);
        setNeedsSave(true);
      }
    },
    // {
    //   name: 'node',
    //   icon: 'fa-circle'
    // },
    // {
    //   name: 'link',
    //   icon: 'fa-arrows-h'
    // },
    {
      name: 'zoom-fit',
      icon: 'fa-expand',
      onClick: () => {
        // find bounding box of nodes
        let minX = 0;
        let minY = 0;
        let maxX = 0;
        let maxY = 0;

        nodes.forEach(node => {
          if(node.x < minX) minX = node.x;
          if(node.y < minY) minY = node.y;
          if(node.x > maxX) maxX = node.x;
          if(node.y > maxY) maxY = node.y;
        });

        setBbox({
          x: minX,
          y: minY,
          width: maxX - minX,
          height: maxY - minY
        });
      }
    }
  ]


  return (
    <div className="inquiry-opportunity-map flex-column-stretch  flex-grow">
      
      <div className="padding-2rem flex-column-stretch flex-grow">
        <div className="margin-bottom-2rem">
          <div className="box box-half-pad inquiry-opportunity-map-header">  
            <div className="flex-split">
              <div>
                <h4 className="no-margin-top no-margin-bottom">Workflow Mapping</h4>
                <small>
                  Map out your existing or planned process and identify where AI can help. 
                </small>
              </div>
              <div className="list-right">
                <CustomButton
                  display={<span><i className="far fa-fw fa-plus icon-before-text"/>Add Step</span>}
                  size="xs"
                  color="grey"
                  thinking={inquiryReducer.tryingToUpdateInquiryOpportunityMap}
                  onClick={() => {
                    
                    setNodes([...nodes, {
                      id: shortid.generate(),
                    }]);
                    setNeedsSave(true);

                    // dispatch(tryToUpdateInquiryOpportunityMap({
                    //   inquiry_id: this.props.id,
                    //   opportunity_map_nodes: nodes,
                    //   opportunity_map_links: inquiryReducer.cache[this.props.id].opportunity_map_links
                    // }));
                  }}
                  />
                <CustomButton
                  display={<span><i className="far fa-fw fa-sparkles icon-before-text"/>Generate Map</span>}
                  size="xs"
                  color="inquiry"
                  thinking={inquiryReducer.tryingToUpdateInquiryOpportunityMap}
                  onClick={() => {
                    dispatch(tryToUpdateInquiryOpportunityMap({
                      inquiry_id: id,
                      opportunity_map_nodes: nodes,
                      opportunity_map_links: inquiryReducer.cache[id].opportunity_map_links,
                      generate: true
                    }));

                    setWaitingForGenerate(true);
                  }}
                  />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-split flex-split-align-start flex-grow">
          <div className="inquiry-opportunity-map-tools">
            <div className="box box-no-pad">
              {
                tools.map((tool,index) => {

                  if(tool === 'divider') return <hr key={index}/>;
                  
                  return <div className={"inquiry-opportunity-map-tool"} key={index} onClick={tool.onClick}>
                    <i className={'far fa-fw ' + tool.icon}/>
                  </div>
                })
              }
            </div>
          </div>

          {
            (foundNode && selectedNode) && <div className="inquiry-opportunity-map-node-info " key={selectedNode}>
              <div className="box flex-grow scroll-parent">
                <div className="scroll-child padding-2rem">
                  <div className="flex-split margin-bottom-1rem">
                    <div className="flex-grow">
                      <h4 className="no-margin">
                        {foundNode.name || "Untitled..."}
                      </h4>
                    </div>
                    <div>
                      <CustomButton 
                        display={<i className="fal fa-times"></i>}
                        onClick={() => {
                          setSelectedNode(null);
                          setEditNodeMode(false);
                        }}
                        color="transparent"
                        size="xs"
                        />
                    </div>
                  </div>
                  {
                    editNodeMode ?
                  
                    <div>
                      <CustomSelect
                        label="Category"
                        value={foundNode.category}
                        options={categories.map(c => {
                          return {
                            value: c.name,
                            label: c.display_name
                          }
                        })}
                        onChange={(e) => {
                          let newNodes = [...nodes];
                          let foundNodeIndex = newNodes.findIndex(node => node.id === selectedNode);
                          newNodes[foundNodeIndex].category = e;
                          
                          setNodes(newNodes);
                          setNeedsSave(true);
                        }}  
                        />

                      <CustomField
                        label="Name"
                        value={foundNode.name}
                        placeholder="What's this called?"
                        onChange={(e) => {
                          let newNodes = [...nodes];
                          let foundNodeIndex = newNodes.findIndex(node => node.id === selectedNode);
                          newNodes[foundNodeIndex].name = e.value;

                          setNodes(newNodes);
                          setNeedsSave(true);
                        }}
                        />

                      
                      <CustomField
                        label="Description"
                        rows={4}
                        value={foundNode.description}
                        placeholder="Describe this step..."
                        onChange={(e) => {
                          let newNodes = [...nodes];
                          let foundNodeIndex = newNodes.findIndex(node => node.id === selectedNode);
                          newNodes[foundNodeIndex].description = e.value;

                          setNodes(newNodes);
                          setNeedsSave(true);
                        }}
                        />
                      <hr/>
                      <div className="margin-top-2rem">
                        <CustomButton
                          display={<span>Save Changes</span>}
                          size="xs"
                          block={true}
                          color="success"
                          onClick={e => {
                            setEditNodeMode(false);
                            setNeedsSave(true);
                          }}  
                          />
                      </div>
                      <div className="margin-top-1rem">
                        <CustomButton
                          display={<span><i className="far fa-fw fa-trash icon-before-text"/>Delete</span>}
                          size="xs"
                          block={true}
                          color="transparent-danger"
                          onClick={removeNode}  
                          disabled={!canWrite || inquiryReducer.tryingToUpdateInquiryOpportunityMap}
                          fail={inquiryReducer.updateOpportunityMapFail}
                            />
                      </div>
                  </div>
                  :
                  <div>
                    <p>
                      {foundNode.description}
                    </p>
                    <hr/>
                    <div className="margin-top-2rem">
                      <CustomButton
                        display={<span><i className="far fa-fw fa-pencil icon-before-text"/>Edit Step</span>}
                        size="xs"
                        block={true}
                        color="grey"
                        onClick={e => setEditNodeMode(true)}  
                        />
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
          }


          {
            (selectedLink && foundLinkSource && foundLinkTarget) && <div className="inquiry-opportunity-map-node-info">
              <div className="box flex-grow scroll-parent">
                <div className="scroll-child padding-2rem">
                  <div className="flex-split margin-bottom-1rem">
                    <div className="flex-grow">
                      <h4 className="no-margin-top no-margin-bottom">
                        {
                          foundLinkSource.name || "Untitled..."
                        }
                        <i className="far fa-fw fa-arrows-h margin-left-1rem margin-right-1rem"/>
                        {
                          foundLinkTarget.name || "Untitled..."
                        }
                      </h4>
                    </div>
                    <div>
                      <CustomButton 
                        display={<i className="fal fa-times"></i>}
                        onClick={() => setSelectedLink(null)}
                        color="transparent"
                        size="xs"
                        />
                    </div>
                  </div>
                 
                  <div className="margin-top-2rem">
                    <CustomButton
                      display={<span><i className="far fa-fw fa-unlink icon-before-text"/>Unlink</span>}
                      size="xs"
                      block={true}
                      color="transparent-danger"
                      onClick={removeLink}  
                      disabled={!canWrite || inquiryReducer.tryingToUpdateInquiryOpportunityMap}
                      fail={inquiryReducer.updateOpportunityMapFail}
                        />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <div className="inquiry-opportunity-map-graph">
      <VisualProgrammingCanvas
        initialNodes={nodes}
        initialLinks={links}
        categories={categories}
        selectedNodeId={selectedNode}
        selectedLinkId={selectedLink ? (selectedLink.source + '_' + selectedLink.target) : null}
        bbox={bbox}
        onNodeClick={(id) => {
          setSelectedNode(id);
          setSelectedLink(null);
          if(id){
            setEditNodeMode(false);
          }
        }}
        onLinkClick={(link) => {
          setEditNodeMode(false);
          setSelectedNode(null);
          setSelectedLink(link ? { source: link.source.id, target: link.target.id } : null);
        }}
        onNodeChange={handleNodeChange}
        onLinkChange={handleLinkChange}
      />
      {/* Additional component content, including buttons and handlers */}
      </div>
    </div>
  );
};

export default InquiryOpportunityMap;


