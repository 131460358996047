import React, { Component } from 'react'
import { connect } from 'react-redux'

import './Heart.scss';

import {
  tryToHeartbeat
} from '../../actions/actions.export'


class Heart extends Component {
  heartBeatInterval = false;

  constructor(props){
    super(props);

    this.state = {
      visible: true
    }

    this.beat = this.beat.bind(this);
    this.setVisibility = this.setVisibility.bind(this);
  }

  beat(){
    const { dispatch, userReducer } = this.props;

    if(this.state.visible && userReducer.isLoggedIn && !userReducer.tryingToLogoutEverywhere){
      dispatch(tryToHeartbeat());
    }
  }

  setVisibility(){
    if(document.visibilityState === "visible"){
      this.setState({
        visible: true
      })
    } else {
      this.setState({
        visible: false
      })
    }
  }

  componentDidMount(){
    this.beat();

    document.addEventListener("visibilitychange", this.setVisibility);

    this.heartBeatInterval = setInterval(this.beat, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.heartBeatInterval);
    document.removeEventListener("visibilitychange", this.setVisibility);
  }
  
  render(){
    return <span className="heart"/>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(Heart);
