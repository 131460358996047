import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Callout from '../../kit/components/Callout/Callout.js'

import EmailVerifyForm from '../EmailVerifyForm/EmailVerifyForm.js'

// import {
// } from '../../actions/actions.export'

class EditUserEmailAddressForm extends Component {
  constructor(props){
    super(props);
  }

  render(){

    const { dispatch, userReducer } = this.props;


    return <div className="box">
    

        <h5 className="no-margin-top margin-bottom-2rem">
          Verify your email address
        </h5>

        {
          userReducer.myData.email_verified ?
          <div>
            <p className="text-success text-700">
              <i className="fas fa-check icon-before-text"/> Your email address has already been verified.
            </p>
            <p className="no-margin-bottom">
              If you change it, you'll need to reverify in order to keep using your account.
            </p>
          </div>
          :
          <div>
            <Callout
              style="danger"
              title={<span>Your email isn't been verified.</span>}
              content="Until you verify your email address, you'll be unable to use ZeroWidth."
              />

            <p className="text-center">
              A 6 digit code was sent to <strong>{userReducer.myData.email}</strong>.
            </p>
            <p className="text-center">
              Please enter it here:
            </p>
            <EmailVerifyForm hideEmailChangeLink={true}/>
          </div>
        }

    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(EditUserEmailAddressForm);
