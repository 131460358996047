import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Prompt } from 'react-router-dom'
import { push } from 'react-router-redux';
import moment from 'moment'
import ReactJson from 'react-json-view'

import {
  tryToGetPlugins
} from '../../../../actions/actions.export'

import CustomField from 'kit/components/CustomField/CustomField.js'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js'
import CustomButton from 'kit/components/CustomButton/CustomButton.js'
import Checkbox from 'kit/components/Checkbox/Checkbox.js'
import Callout from 'kit/components/Callout/Callout.js'

import './ComponentPluginsTool.scss';


// load the plugin option tools we'll use 
import FunctionalRag from './ComponentPluginsToolFunctionalRag.js';
import Datetime from './ComponentPluginsToolDatetime.js';
import InaccuracyReporter from './ComponentPluginsToolInaccuracyReporter';
import WebSearch from './ComponentPluginsToolWebSearch';
import WebScraper from './ComponentPluginsToolWebScraper';
import RememberKnowledge from './ComponentPluginsToolRememberKnowledge';
import GenerateImage from './ComponentPluginsToolGenerateImage';

const pluginOptionTools = {
  'functional_rag': FunctionalRag,
  'datetime': Datetime,
  'inaccuracy_reporter': InaccuracyReporter,
  'web_search': WebSearch,
  'web_scraper': WebScraper,
  'remember_knowledge': RememberKnowledge,
  'generate_image': GenerateImage
}

// Load the default initialized state for this content, might include parsing or some bonus arrays or something
export const createInitPacket = (content) => {
  return {
    id: content.id,
    plugin_id: content.plugin_id,
    plugin_options: content.plugin_options,
    enabled: content.enabled
  }
}


// Parse relevant data from the content to show any unsaved changes
export const createStructuredPacket = (content) => {
  return {
    id: content.id,
    plugin_id: content.plugin_id,
    plugin_options: content.plugin_options,
    enabled: content.enabled
  }
}

// Append relevant info to download zip
const createDownloadPacket = (content, zip) => {
  zip.file(content.display_name + ".json", JSON.stringify({
    plugin_id: content.plugin_id,
    plugin_options: content.plugin_options,
    enabled: content.enabled
  }, null, 2))

  return zip;
}


// Don't change anything here, this little guy just helps us run our menu updates up the tree to the manager
const Observer = ({ value, didUpdate }) => {
  useEffect(() => {
    didUpdate(value)
  }, [value])
  return null // component does not render anything
}

class CustomComponent extends Component {
  constructor(props){
    super(props);

    this.state = {
      showCode: false
    }

    this.updateMenu = this.updateMenu.bind(this);
    this.useDefaultOptions = this.useDefaultOptions.bind(this);
  }

  componentWillMount(){
    const { dispatch, intelReducer } = this.props;
    if(intelReducer.plugins.length === 0 && !intelReducer.tryingToGetPlugins){
      dispatch(tryToGetPlugins());
    }
  }

  updateMenu(){

    this.props.menuMerge([
      // {
      //   display_name: "Actions",
      //   items: [
      //     {
      //       display_name: "test",
      //     }
      //   ]
      // }
    ])
  }

  useDefaultOptions(){
    const { intelReducer } = this.props;

    let selectedPlugin = intelReducer.plugins.find(p => p.id === this.props.data.plugin_id);

    // build a new object with the default settings for this selected plugin
    let defaultOptions = {
    }

    for(var i in selectedPlugin.options){
      let o = selectedPlugin.options[i];
      if(o.default){
        defaultOptions[o.name] = o.default;
      }
    }

    this.props.onChange({
      plugin_options: defaultOptions
    })
  }

  render(){

    const { draftComponent, content, canWrite } = this.props;

    let selectedPlugin = this.props.intelReducer.plugins.find(p => p.id === this.props.data.plugin_id);

    // for each required element of the plugin's options, check if it exists in the content, if one is missing then we show the warning
    let missingOptions = [];

    let Tool = null;
    if(selectedPlugin){
      Tool = pluginOptionTools[selectedPlugin.id];

      if(selectedPlugin.options){
        selectedPlugin.options.forEach(o => {
          if(o.mode === 'required'){
            if(!content.plugin_options || !content.plugin_options[o.name]){
              missingOptions.push(o.name);
            }
          }
        })
      }
    }

    let installedPlugins = draftComponent.plugins || [];


    return <div className="component-plugins-tool">
      {/*invisible component to tuck the hook within this class component, don't edit*/}
      <Observer value={this.state} didUpdate={this.updateMenu} />
      {/*invisible component to tuck the hook within this class component, don't edit*/}
      <Callout
        style="info"
        title="Plugins are experimental and support may vary"
        dismissable={true}
        content={<div>
          <div>These features are unique to some LLMs and will not be leveraged if another provider's model is chosen for this agent.</div>
        </div>}
        />
        
        {
          selectedPlugin ?
          <div className="margin-bottom-2rem box">

            <div className="flex-split flex-split-align-start">
              <div>
                <div className="list-left">
                  <img src={"/img/plugin_" + selectedPlugin.id + ".png"} className="margin-right-1rem" style={{maxHeight: '3rem'}}/>
                  <h4 className="no-margin">
                    {selectedPlugin.display_name}
                  </h4>
                </div>
              </div>
              <div className="list-right">
                {
                  missingOptions.length > 0 ?
                  <strong className="text-warning margin-right-1rem"><i className="far fa-exclamation-triangle icon-before-text"/>Finish configuring!</strong> :
                  <div>
                    {
                      this.props.data.enabled ?
                      <strong className="text-success margin-right-1rem"> <i className="far fa-check icon-before-text"/>Plugin currently enabled</strong> :
                      <strong className="text-danger margin-right-1rem"><i className="far fa-times icon-before-text"/>Plugin currently disabled</strong>
                    }
                  </div>
                }
                
                <CustomButton
                  displayOptions={[
                    <span>
                    Disable <i className="fas fa-fw fa-toggle-on icon-after-text"/>
                  </span>,
                  <span>
                    Enable <i className="fas fa-fw fa-toggle-off icon-after-text"/>
                  </span>
                  ]}
                  display={(this.props.data.enabled && missingOptions.length === 0) ? <span>
                    Disable <i className="fas fa-fw fa-toggle-on icon-after-text"/>
                  </span> : 
                  <span>
                    Enable <i className="fas fa-fw fa-toggle-off icon-after-text"/>
                  </span>
                  } 
                  color={this.props.data.enabled ? "black" : ""}
                  size="xs"
                  disabled={missingOptions.length > 0}
                  onClick={()=>{
                    this.props.onChange({
                      enabled: !this.props.data.enabled
                    })
                  }}
                  />
              </div>
            </div>

            <p className="thin-line-height">
              {selectedPlugin.description}
            </p>
            <hr/>
            <div className="flex-split">
              <div className="list-left">
                <h5 className="no-margin">Customize this plugin's options:</h5>
              </div>
              <div className="list-right">
                <CustomButton
                  display="Use Defaults"
                  size="xs"
                  color="grey"
                  onClick={this.useDefaultOptions}
                  />
              </div>
            </div>
            <Tool
              org_id={this.props.org_id}
              canWrite={canWrite}
              options={this.props.data.plugin_options}
              onChange={(v)=>{
                this.props.onChange({
                  plugin_options: {
                    ...this.props.data.plugin_options,
                    ...v
                  }
                });
              }}
              />
          </div>
          :
          <div className="">
            <div className="row row-eq-height">
              {
                this.props.intelReducer.plugins.map(plugin => {

                  // do you already have one of these installed?
                  let alreadyInstalled = installedPlugins.find(p => p.plugin_id === plugin.id);

                  return <div className="col-md-4 flex-column-split margin-bottom-2rem">
                    <div className="box margin-bottom-1rem flex-grow flex-column-split">
                      <div className="flex-grow">
                        <h5 className="no-margin">
                          <i className={"fal fa-fw icon-before-text fa-" + plugin.icon} />
                          {plugin.display_name}
                        </h5>
                        <p className="margin-top-1rem thin-line-height margin-bottom-3rem">
                          <small>{plugin.description}</small>
                        </p>
                      </div>
                      <CustomButton
                        display={
                          (alreadyInstalled && !plugin.can_have_multiple) ?
                          "Already Installed"
                          :
                          "Add to this Agent"
                        }
                        size="xs"
                        disabled={alreadyInstalled && !plugin.can_have_multiple}
                        color="black"
                        block={true}
                        onClick={()=>{
                          this.props.onChange({
                            plugin_id: plugin.id
                          })
                          setTimeout(()=>{
                            this.props.onSave();
                          }, 100)
                        }}
                        />
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        }

        {
          (this.props.data.plugin_id && !selectedPlugin) &&
          <div>
            <Callout
              style="warning"
              title="Plugin not found"
              content={<div>
                <div>It looks like the plugin you selected is not available anymore.</div>
              </div>}
              />
          </div>
        }
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, componentReducer, orgReducer, guiReducer, intelReducer } = state;

  return {
    userReducer, 
    componentReducer,
    intelReducer,
    orgReducer,
    guiReducer
  }
}

const Tool = connect(mapStateToProps)(CustomComponent);

export default {
  createInitPacket,
  createStructuredPacket,
  createDownloadPacket,
  Tool
}
