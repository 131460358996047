const faq = [
  {
    question: 'Do I need to know how to code?',
    answer: 'No, you do not need to know how to code. Our goal is to democratize the design of AI and make it a collaborative process like any other design process. We believe that the best AI is created by teams of people with diverse backgrounds and perspectives.'
  },
  {
    question: "Can't I just use use ChatGPT?",
    answer: "ChatGPT is a product that leverages a foundational LLM to deliver a broad-based conversational virtual assistant. ZeroWidth is a platform that enables you to select those same foundational models and customize them for purposes within your own products & services."
  },
  {
    question: "So this helps me build a chatbot?",
    answer: "Sure, it can - but conversational interfaces are just one of many ways large language models can provide value. ZeroWidth is designed to help you build AI agents that can be used in a variety of ways, from chatbots to content generation, to custom processing tasks, to data analysis and more."
  },
  {
    question: "What large language models can I use?",
    answer: "We connect you to models from OpenAI, Anthropic, Google, & Meta - with more on the way. Typically when a new model is announced by one of the big foundational providers, we add support for it in the same week."
  },
  {
    question: "I'm a developer, can I bring my own API key?",
    answer: "Yep! Or you can use ours via our credits system that measures usage per model down to the token."
  },
  {
    question: "Do I have to use your API?",
    answer: "Currently, agents built in ZeroWidth can be deployed via our API and called from anywhere that can make a POST request. We currently offer Node.js SDKs to make that extremely easy. Soon, you'll be able to export the designed agent as a configuration you can load directly into our SDK and execute without our API being involved at all."
  },
  {
    question: "Do you train models with my data?",
    answer: "No. Your data is your data, we don't build LLMs or train them. You manage how your information is stored and used at all times."
  }
]


export default faq;