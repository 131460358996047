import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Add a project team member
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestInstallComponent = packet => {
  return {
    type: 'REQUEST_INSTALL_COMPONENT',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveInstallComponentSuccess = data => ({
  type: 'RECEIVE_INSTALL_COMPONENT_SUCCESS',
  data
})

export const receiveInstallComponentFail = data => ({
  type: 'RECEIVE_INSTALL_COMPONENT_FAIL',
  data
})

export const tryToInstallComponent = packet => (dispatch, getState) => {
  const projectReducer = getState().projectReducer;
  const project = projectReducer.cache[packet.id];

  dispatch(requestInstallComponent(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/projects/install-component/' + packet.id, {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveInstallComponentSuccess(json));
      dispatch(push('/workbench/' + project.scope + '/projects/' + project.id + '/agents/' + json.data.component_id));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveInstallComponentFail({errors:e, lastSubmit: packet}));
    })
}
