import React, { Component, useEffect, useState, useRef} from 'react'
import { connect, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'

import CustomButton from '../kit/components/CustomButton/CustomButton.js'

import Carousel from '../components/Carousel/Carousel.js'
import ASCIIBackground from 'components/ASCIIBackground/ASCIIBackground.js'

import faq from '../configs/config.faq.js'
import use_cases from '../configs/config.use-cases.js'

import isFeatureSwitchOn from 'utilities/isFeatureSwitchOn.js'
import { use } from 'matter-js'

function checkMobileDeviceAgent() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

const LandingRoute = () => {
  const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);
  const isMobileDevice = checkMobileDeviceAgent(); // Assuming this is a function you have defined
  const [isSticky, setIsSticky] = useState(false);
  const [currentNode, setCurrentNode] = useState(0);
  const [asciiPath, setAsciiPath] = useState("/home-img/zebra-print.png");
  const [bgColor, setBGColor] = useState('#fff');
  const [fgColor, setFGColor] = useState('#000');
  const [placementMode, setPlacementMode] = useState('cover');
  const [openedFAQ, setOpenedFAQ] = useState(null)

  const userReducer = useSelector(state => state.userReducer);
  const featuresReducer = useSelector(state => state.featuresReducer);

  const [selectedUseCases, setSelectedUseCases] = useState(null);
  

  useEffect(() => {
    
    const asciiElements = document.querySelectorAll("[data-ascii]");


    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Find the closest ascii element
      let closestAsciiElement = null;
      let closestAsciiElementDistance = Infinity;
      asciiElements.forEach(element => {
        let distance = Math.abs(element.offsetTop - scrollPosition - window.innerHeight / 6);
        if(distance < closestAsciiElementDistance){
          closestAsciiElement = element;
          closestAsciiElementDistance = distance;
        }
      });

      setAsciiPath(closestAsciiElement.dataset.ascii);
      if(closestAsciiElement.dataset.bgcolor) setBGColor(closestAsciiElement.dataset.bgcolor);
      if(closestAsciiElement.dataset.fgcolor) setFGColor(closestAsciiElement.dataset.fgcolor);
      if(closestAsciiElement.dataset.placementmode){
        console.log(closestAsciiElement.dataset.placementmode);
        setPlacementMode(closestAsciiElement.dataset.placementmode);
      } else {
        setPlacementMode('cover');
      }


      updateFaders();

    };

    const updateFaders = () => {
      const elements = document.querySelectorAll('.fade-in-up');

      for (const element of elements) {
          const elementTop = element.getBoundingClientRect().top;
          const isVisible = elementTop < window.innerHeight - window.innerHeight / 8; // Adjust as needed

          if (isVisible) {
              element.classList.add('visible');
          }
      }
    }


    window.addEventListener('scroll', handleScroll);

    setTimeout(()=>{
      setAsciiPath('/home-img/center-top-focus.png');
      // setBGColor('#C9E6F5');
      setFGColor('#ddd')
    }, 1300)

    setTimeout(()=>{
      updateFaders();
    }, 2200)
    
    window.document.title = "ZeroWidth - define, design, develop AI together";

    // create a local copy of use cases
    let useCasesCopy = JSON.parse(JSON.stringify(use_cases));

    // shuffle it
    useCasesCopy.sort(() => Math.random() - 0.5);

    // store it
    setSelectedUseCases(useCasesCopy);
    


    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    
  }, []);
  


  const nodeData = [
    { id: 1, title: 'Define', bg_img: '/home-img/blobs.png' },
    { id: 2, title: 'Design', bg_img: '/home-img/blobs.png' },
    { id: 3, title: 'Develop', bg_img: '/home-img/left-right-gradient.png' }
  ];

  const assets = [
    { jsx: <img src="/home-img/carousel-openai.png"  alt="Asset 1" height="500px"/>, width: '351px', border: true },
    { 
      jsx: <video autoPlay loop muted playsInline className="workbench-video video-full-width" width="856px" height="500px">
        <source src="/home-img/carousel-flow-nav.mp4" type="video/mp4"/>
        Your browser does not support the video tag.
      </video>, 
      width: '856px', 
      border: true 
    },

    { jsx: <img src="/home-img/carousel-api-response.png" alt="Asset 3" height="500px"/>, width: '408px', border: true  },
    { jsx: <img src="/home-img/designers.png" alt="Asset 3" height="500px"/>, width: '500px' },
    // { jsx: <img src="/home-img/carousel-angles.png" alt="Asset 3" height="500px"/>, width: '100px' },
    { 
      jsx: <video autoPlay loop muted playsInline className="workbench-video video-full-width" width="264px" height="500px">
        <source src="/home-img/carousel-agent-chain.mp4" type="video/mp4"/>
        Your browser does not support the video tag.
      </video>, 
      width: '264px', 
      border: true 
    },
    { 
      jsx: <video autoPlay loop muted playsInline className="workbench-video video-full-width" width="631px" height="500px">
        <source src="/home-img/carousel-chat.mp4" type="video/mp4"/>
        Your browser does not support the video tag.
      </video>, 
      width: '631px', 
      border: true 
    },

    { jsx: <img src="/home-img/carousel-flow-rag.png" alt="Asset 3" height="500px"/>, width: '897px', border: true  },
    { 
      jsx: <video autoPlay loop muted playsInline className="workbench-video video-full-width" width="895px" height="500px">
        <source src="/home-img/carousel-report-charts.mp4" type="video/mp4"/>
        Your browser does not support the video tag.
      </video>, 
      width: '895px', 
      border: true 
    },
    { jsx: <img src="/home-img/carousel-strategists.png" alt="Asset 3" height="500px" width="750px"/>, width: '750px' },
    { jsx: <img src="/home-img/carousel-semantic-search.png" alt="Asset 3" height="500px"/>, width: '1046px', border: true  },
    { jsx: <img src="/home-img/carousel-zebra-03.png" alt="Asset 3" height="500px"/>, width: '500px' },
    // { jsx: <img src="/home-img/carousel-angles-blue.png" alt="Asset 3" height="500px"/>, width: '300px' },
    
  ];
  

  return <div className="hf-stretched overflow-hidden-x" data-ascii="/home-img/center-top-focus.png" data-fgcolor="#ddd" data-bgcolor="#fff">
    <div className="fixed-big-image" key={placementMode}>
      <ASCIIBackground 
        imagePath={asciiPath} 
        foreground={fgColor} 
        background={bgColor} 
        autoTween={1000} 
        fontSize={16}
        placementMode={placementMode}
        />
    </div> 

    <div className="screen-height-container">
      <div className="sch-content ">
        <Header blackBackground={isLoggedIn} borderless={true}/>
        
        <div className="margin-top-3rem fade-in-up">
          <div className="container">
            <div className="row">
              <div className="col-md-10">
                <div className="padding-2rem">
                  <div className="spacer-3rem"></div><div className="spacer-3rem"></div>
                  <span className="text-larger text-muted text-uppercase">
                    Build the right AI, together.
                  </span>
                  <h1 className="no-margin-top">
                    The collaborative platform empowering teams to <nobr className="text-highlighter text-highlighter-knowledge text-knowledge">define <i className="fal text-smaller-percentage text-knowledge fa-user-check"/></nobr>, <nobr className="text-highlighter text-components text-highlighter-components">design <i className="fal text-smaller-percentage text-component fa-project-diagram"/></nobr>, and <nobr className="text-highlighter text-projects text-highlighter-projects">develop <i className="fal text-project text-smaller-percentage fa-code"/></nobr> with large language models.
                  </h1>
                  <CustomButton
                    display={<span>Join the Waitlist<i className="fas fa-arrow-right icon-after-text"/></span>}
                    to="/waitlist"
                    color="black"
                    />
                </div>
              </div>
            </div>
          </div>
          <div className="spacer-3rem"/>
          <div className="spacer-3rem"/>
          <div className="fade-in-up">
            <Carousel
              assets={assets}
              assetHeight="500px"
              margin="20px"
              />
              <div className="spacer-3rem"/>
              <div className="spacer-3rem"/>
          </div>
          <div className="mega-container margin-top-3rem"  data-ascii="/home-img/center-middle-focus.png" data-fgcolor="#ddd" data-bgcolor="#fff">
            <div className="padding-3rem">
              <div className="container">
                <div className="row justify-content-center row-mega-pad">
                  <div className="col-md-12">
                    <div className="text-larger text-center text-muted text-uppercase">
                      Used by instructors & design students at
                    </div>
                  </div>
                  <div className="col-md-3 padding-3rem">
                    <div className="">
                      <img src="/img/berkeley-logo.png" className="img-full-width"/>
                    </div>
                  </div>
                  <div className="col-md-3 padding-3rem">
                    <div className="">
                      <img src="/img/id-logo.png" className="img-full-width"/>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h2 className="text-400">
                      <i className="fas fa-quote-left icon-before-text text-primary"/><br/>
                      More than the other technologies we engaged with this semester, <strong>ZeroWidth stands apart as essential.</strong>
                    </h2>
                    <div className="list-left">
                      <img src="/img/ksteinfeld.jpg" width="50" className="img-circle img-circle-shadow margin-right-2rem"/>
                      <p className="text-900 no-margin-top no-margin-bottom">Professor Kyle Steinfeld<br/><small className="text-400">UC Berkeley</small></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container" data-ascii="/home-img/center-top-focus.png" data-fgcolor="#ddd" data-bgcolor="#fff">
              <div className="row justify-content-center margin-top-3rem margin-bottom-3rem">
                <div className="col-lg-5 text-center">
                  <h2 className="">
                    What is ZeroWidth for?
                  </h2>
                  <p>
                    Internal processes, customer facing, or both - ZeroWidth helps you identify, design, and deploy AI agents that drive value for your business.
                  </p>
                </div>
              </div>
            </div>
            <div className="use-case-parent">
              <div className="use-case-wrapper">
                {
                  selectedUseCases && selectedUseCases.map((useCase, i) => {
                    return <div key={i} className="use-case">
                      {useCase}
                    </div>
                  })
                }
              </div>
            </div>
            <div className="padding-3rem" data-ascii="/home-img/center-middle-focus.png" data-fgcolor="#ddd" data-bgcolor="#fff">
              <div className="container">
                <div className="row row-mega-pad" id="for-who">
                  <div className="col-lg-12 text-center margin-bottom-3rem">
                    <h2>
                      Who is ZeroWidth for?
                    </h2>
                    <p>
                      More people than you might think and probably you.<br/>Designing AI is a team sport.
                    </p>
                  </div>
                  <div className="col-lg-4">
                    <div className="box box-shadow-knowledge margin-bottom-2rem">
                      <div className="flex-split margin-bottom-3rem">
                        <h3 className="no-margin text-knowledge">
                          For Strategists
                        </h3>
                        <i className="fal fa-user-check fa-2x text-knowledge"/>
                      </div>
                      {/* <img src="/home-img/strategists.png" className="img-full-width img-rounded-rect-wrapper"/> */}
                      <div className="spacer-1rem"/>
                      <p className="text-larger margin-bottom-3rem margin-top-2rem">
                        Discover the best place to start driving value with AI.
                      </p>
                      <div className="spacer-1rem"/>
                      <ul className="ul-iconed margin-bottom-3rem">
                        <li className="margin-bottom-1rem"><i className="fal fa-long-arrow-right text-knowledge icon-before-text"/> Validate your idea against expert advice</li>
                        <li className="margin-bottom-1rem"><i className="fal fa-long-arrow-right text-knowledge icon-before-text"/> Identify the right AI strategy for your business</li>
                        <li className="margin-bottom-1rem"><i className="fal fa-long-arrow-right text-knowledge icon-before-text"/> Rank & qualify use cases against your readiness</li>
                        <li className="margin-bottom-1rem"><i className="fal fa-long-arrow-right text-knowledge icon-before-text"/> Manage proprietary knowledge with ease</li>
                      </ul>
                      <div className="spacer-2rem"/>
                      <CustomButton
                        display={<span>Get started<i className="fas fa-arrow-right icon-after-text"/></span>}
                        to="/sign-up"
                        color="knowledge"
                        block={true}
                        />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="box box-shadow-components margin-bottom-2rem">
                      <div className="flex-split margin-bottom-3rem">
                        <h3 className="no-margin text-components">
                          For Designers
                        </h3>
                        <i className="fal fa-project-diagram fa-2x text-component"/>
                      </div>
                      {/* <img src="/home-img/designers.png" className="img-full-width img-rounded-rect-wrapper"/> */}
                      <div className="spacer-1rem"/>
                      <p className="text-larger margin-bottom-3rem margin-top-2rem">
                        Craft AI agents and refine the UX behind your AI powered products.
                      </p>
                      <div className="spacer-1rem"/>
                      <ul className="ul-iconed margin-bottom-3rem">
                        <li className="margin-bottom-1rem"><i className="fal fa-long-arrow-right text-components icon-before-text"/> Design custom AI agents without coding</li>
                        <li className="margin-bottom-1rem"><i className="fal fa-long-arrow-right text-components icon-before-text"/> Prototype AI powered products in minutes</li>
                        <li className="margin-bottom-1rem"><i className="fal fa-long-arrow-right text-components icon-before-text"/> Collaborate with your team in real-time</li>
                        <li className="margin-bottom-1rem"><i className="fal fa-long-arrow-right text-components icon-before-text"/> Test & share your AI with a simple URL.</li>
                      </ul>
                      <div className="spacer-2rem"/>
                      <CustomButton
                        display={<span>Get started<i className="fas fa-arrow-right icon-after-text"/></span>}
                        to="/sign-up"
                        color="components"
                        block={true}
                        />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="box box-shadow-projects margin-bottom-2rem">
                      <div className="flex-split margin-bottom-3rem">
                        <h3 className="no-margin text-projects">
                          For Developers
                        </h3>
                        <i className="fal fa-code fa-2x text-project"/>
                      </div>
                      <div className="spacer-1rem"/>
                      <p className="text-larger margin-bottom-3rem margin-top-2rem">
                        Deploy, monitor, and scale customized AI with ease.
                      </p>
                      <div className="spacer-1rem"/>
                      <ul className="ul-iconed margin-bottom-3rem">
                        <li className="margin-bottom-1rem"><i className="fal fa-long-arrow-right text-projects icon-before-text"/> Deploy AI agents to an API in seconds</li>
                        <li className="margin-bottom-1rem"><i className="fal fa-long-arrow-right text-projects icon-before-text"/> Monitor agent performance & usage</li>
                        <li className="margin-bottom-1rem"><i className="fal fa-long-arrow-right text-projects icon-before-text"/> Easily connect your applications to AI</li>
                        <li className="margin-bottom-1rem"><i className="fal fa-long-arrow-right text-projects icon-before-text"/> Version controlled deployments & rollbacks</li>
                      </ul>
                      <div className="spacer-2rem"/>
                      <CustomButton
                        display={<span>Get started<i className="fas fa-arrow-right icon-after-text"/></span>}
                        to="/sign-up"
                        color="projects"
                        block={true}
                        />
                    </div>
                  </div>
                </div>
                <div className="row row-mega-pad" data-ascii="/home-img/right-left-gradient.png" data-fgcolor="#ddd" data-bgcolor="#fff">
                  <div className="col-lg-5">
                    <h2 className="no-margin-top no-margin-bottom">Frequently Asked Questions</h2>
                    <p className="text-larger">
                      There's a lot of hype out there, hopefully these answers can help cut through some of the noise. 
                    </p>
                    <p className="text-larger">
                      Have a question that isn't listed?
                    </p>
                    <CustomButton
                      display={<span>Get in touch<i className="fas fa-arrow-right icon-after-text"/></span>}
                      to="/contact"
                      color="black"
                      />
                  </div>
                  <div className="col-lg-7">
                    {
                      faq.map((f, i)=> {
                        
                        return <div key={i} className={"box box-light-border box-clickable faq " + (openedFAQ === i ? "faq-open" : "faq-closed")} onClick={e => {
                          if(openedFAQ === i){
                            setOpenedFAQ(null)
                          } else {
                            setOpenedFAQ(i);
                          }
                        }}>
                          <h4 className="no-margin">
                            {
                              openedFAQ === i ?
                              <i className="fal fa-times fa-fw icon-before-text text-muted"/>
                              :
                              <i className="fal fa-plus fa-fw icon-before-text"/>
                            }
                            {f.question}
                          </h4>
                          {
                            openedFAQ === i &&
                            <p className="text-larger no-margin-bottom">
                              {f.answer}
                            </p>
                          }
                        </div>
                      })
                    }
                  </div>
                </div>
                <div className="row justify-content-center row-mega-pad" data-ascii="/home-img/center-middle-focus.png" data-fgcolor="#ddd" data-bgcolor="#fff">
                  <div className="col-md-5">
                      <div className="spacer-3rem"/>
                      <h2 className="text-center">
                        Want early access?
                      </h2>
                      <p className="text-larger text-center">
                        ZeroWidth is currently in a private beta with select teams.
                      </p>
                      <p className="text-larger text-center">
                        Sign up to join the waitlist and be the first to know when we launch, or reach out to us and we might be able to get you in sooner.
                      </p>
                      <div className="spacer-3rem"/>
                      <div className="relative">
                        {
                          (isFeatureSwitchOn('waitlist', userReducer, featuresReducer)) &&
                            <CustomButton
                              display={<span>I want access<i className="far fa-long-arrow-right icon-after-text"/></span>}
                              block={true}
                              color="black"
                              size="large"
                              to="/waitlist"
                            />
                        }
                        {
                          isFeatureSwitchOn('registration', userReducer, featuresReducer) &&
                          <CustomButton
                            display={<span>I've got a registration code<i className="far fa-arrow-right icon-after-text"/></span>}
                            size="large"
                            block={true}
                            
                            to="/sign-up"
                          />
                        }
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Footer inverted={true}/>
  </div>
}

export default LandingRoute;