let templates = [
  {
    "display_name": "Strict Knowledge Base Connection",
    "role": "system",
    "content": "Use ONLY the following information to answer the user's questions:\n\n${KNOWLEDGE}"
  },
  {
    "display_name": "Open Knowledge Base Connection",
    "role": "system",
    "content": "Use the following information to help answer the user's questions:\n\n${KNOWLEDGE}"
  },
  {
    "display_name": "Contextual User Data Setup",
    "role": "system",
    "content": "You are talking to someone named ${USER_NAME}, who is a ${USER_ROLE} at ${USER_COMPANY}."
  },
  {
    "display_name": "JSON Data Generation",
    "role": "system",
    "content": `You must generate a VALID JSON object that adheres to the following structure:
{
  "key": "value",
}`
  },
  {
    "display_name": "Thoughtful Response Generation",
    "role": "system",
    "content": `When presented with a question or task, begin your process by explicitly stating your thinking process within '<thinking></thinking>' tags.

For example:
<thinking>Considering the factors that contribute to the issue, evaluating possible outcomes...</thinking>

Then, proceed to articulate your response based on the thought process. This approach helps to make the reasoning behind your conclusions transparent. Format your final response clearly, separating the thought process from the conclusion.`,
  },
  {
    "display_name": "Mental Note Taking",
    "role": "system",
    "content": `When asked to remember or think of something, use the '<thinking></thinking>' tags to indicate that you are taking a mental note.

For example:
<thinking>The user asked me to pick a number between 1 and 10, but so I am picking 6 because it's my lucky number.</thinking>
Then, proceed to articulate your response based on the thought process. This approach helps to make the reasoning behind your conclusions transparent. Format your final response clearly, separating the thought process from the conclusion.
`
  },
  {
      "display_name": "Role-based Task Execution",
      "content": `You are a [role], specialized in [specialization].
      
It's your job to [task description].

Here is the information you need: [information].

Use this information to [specific action].

Format your results as follows: [result formatting instructions].`,
      "role": "system"
  },
  {
      "display_name": "Creative Content Generation",
      "content": `Imagine you are a [role], tasked with creating [type of content].
The theme is [theme].

Incorporate elements such as [elements to include].

Ensure your creation reflects [specific requirements or tone].

Present your content in the following format: [formatting instructions].`,
      "role": "system"
  },
  {
      "display_name": "Information Analysis and Reporting",
      "content": `You are an analyst in the field of [field of analysis].
      
Your task is to analyze the provided data on [topic/data description] and report your findings.

Utilize [tools/methods for analysis] in your evaluation.

Structure your report to include [sections of the report], and conclude with [conclusion requirements].`,
      "role": "system"
  },
  {
    "display_name": "Problem-Solving with Constraints",
    "content": `As a problem solver, you are faced with the challenge of [challenge description].

Your goal is to find a solution that meets the following constraints: [list of constraints].

Take into account the resources available: [resources].

Develop a step-by-step plan to address the challenge, ensuring that each step respects the outlined constraints.

Present your solution plan in the format: [solution plan formatting instructions].`,
    "role": "system"
  },
  {
    "display_name": "Interactive Tutorial Creation",
    "content": `You are tasked with creating an interactive tutorial as a [role], focusing on teaching [skill/topic].

The target audience is [target audience description].

Incorporate interactive elements such as [interactive elements to include] to enhance learning.

The tutorial should progress through [number] stages, each designed to build on the previous one.

Conclude with a quiz or practical task that allows learners to apply what they have learned.

Format the tutorial outline as follows: [outline formatting instructions].`,
    "role": "system"
  },
  {
    "display_name": "Scenario-Based Decision Making",
    "content": `Imagine you are in a scenario where [scenario description].

As a [role], you must make decisions that will [desired outcome].

Consider the following factors before making your decision: [factors to consider].

Outline the decision-making process, including any potential consequences of your decisions.

Conclude with your final decision and justify it based on the scenario and factors considered.

Format your decision and justification as follows: [decision formatting instructions].`,
    "role": "system"
  },
]

export const instructionReducer = (state = {
  templates: templates,
  cache: {},
  cacheRequests: [],
  myInstructions: [],
  newInstructionForm:              {errors: {}, lastSubmit: {}},
  deleteInstructionForm:    {errors: {}, lastSubmit: {}},
}, action) => {

  switch(action.type){


    
    case 'REQUEST_CREATE_NEW_INSTRUCTION':
      return {
        ...state,
        tryingToCreateNewInstruction: true,
        newInstructionForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_NEW_INSTRUCTION_FAIL':
       return {
        ...state,
        tryingToCreateNewInstruction: false,
        newInstructionFail: new Date().getTime(),
        newInstructionForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_NEW_INSTRUCTION_SUCCESS':
      var cache = state.cache;
      cache[action.data.id] = action.data;

      var myInstructions = state.myInstructions;
      if(!myInstructions[action.data.scope]) myInstructions[action.data.scope] = [];
      myInstructions[action.data.scope].push(action.data);

      return {
        ...state,
        cache: cache,
        myInstructions: myInstructions,
        tryingToCreateNewInstruction: false,
        newInstructionSuccess: new Date().getTime(),
        newInstructionForm: {errors: {}, lastSubmit: {}}
      }




      case 'REQUEST_DELETE_INSTRUCTION':

      return {
        ...state,
        tryingToDeleteInstruction: true,
        deleteInstructionForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }
  
    case 'RECEIVE_DELETE_INSTRUCTION_FAIL':
       return {
        ...state,
        tryingToDeleteInstruction: false,
        deleteInstructionFail: new Date().getTime(),
        deleteInstructionForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }
  
    case 'RECEIVE_DELETE_INSTRUCTION_SUCCESS':
      
      var cache = state.cache;
      delete cache[action.data.id];
      
      return {
        ...state,
        cache: cache,
        tryingToDeleteInstruction: false,
        deleteInstructionSuccess: new Date().getTime(),
        deleteInstructionForm: {errors: {}, lastSubmit: {}}
      }
  
  
  



    case 'REQUEST_GET_INSTRUCTIONS_BY_ORGANIZATION':
      return {
        ...state,
        tryingToGetInstructionsByOrganization: true
      }

    case 'RECEIVE_GET_INSTRUCTIONS_BY_ORGANIZATION_FAIL':
       return {
        ...state,
        tryingToGetInstructionsByOrganization: false,
        getInstructionsByOrganizationFail: new Date().getTime()
      }

    case 'RECEIVE_GET_INSTRUCTIONS_BY_ORGANIZATION_SUCCESS':
      
      var cache = state.cache;
      for(var i in action.data){
        let p = action.data[i];
        cache[p.id] = {
          ...cache[p.id],
          ...p
        };
      }

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToGetInstructionsByOrganization: false,
        getInstructionsByOrganizationSuccess: new Date().getTime()
      }


    case 'REQUEST_GET_INSTRUCTION':

      var cacheRequests = state.cacheRequests;
      cacheRequests.push(action.data.lastSubmit.id);

      return {
        ...state,
        cacheRequests: cacheRequests,
        tryingToGetInstruction: true
      }

    case 'RECEIVE_GET_INSTRUCTION_FAIL':
       return {
        ...state,
        tryingToGetInstruction: false,
        getInstructionFail: new Date().getTime()
      }

    case 'RECEIVE_GET_INSTRUCTION_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id] = {
        ...cache[action.data.id],
        ...action.data
      }

      return {
        ...state,
        cache: cache,
        tryingToGetInstruction: false,
        getInstructionSuccess: new Date().getTime()
      }



    
    default:
      return state
  }
}
