import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Set a inquiries's settings metadata
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestSetInquirySettingsMetadata = packet => {
  return {
    type: 'REQUEST_SET_INQUIRY_SETTINGS_METADATA',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveSetInquirySettingsMetadataSuccess = data => ({
  type: 'RECEIVE_SET_INQUIRY_SETTINGS_METADATA_SUCCESS',
  data
})

export const receiveSetInquirySettingsMetadataFail = data => ({
  type: 'RECEIVE_SET_INQUIRY_SETTINGS_METADATA_FAIL',
  data
})

export const tryToSetInquirySettingsMetadata = (packet, next) => (dispatch, getState) => {

  dispatch(requestSetInquirySettingsMetadata(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/inquiries/settings/metadata/' + packet.id, {
      method: 'put',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveSetInquirySettingsMetadataSuccess(json));
      if(next) next(null, json);
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveSetInquirySettingsMetadataFail({errors:e, lastSubmit: packet}));
      if(next) next(e, null);
    })
}
