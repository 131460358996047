
// import addCookieWithConsent from '../../utilities/addCookieWithConsent';

export const sharedReducer = (state = {
  cache: {},
  rateLimitCache: {},
  getPaginationRequests: {},
  getPaginationFail: {},
  getPaginationSuccess: {},
  searchRequests: {},
  tryingToSearch: false,
  cursorCache: {} // organized by room then user id
}, action) => {

  switch(action.type){
    case 'UPDATE_RATE_LIMIT':

      var rateLimitCache = state.rateLimitCache;
      var rateLimitData = JSON.parse(JSON.stringify(action.data));
      delete rateLimitData.path;

      rateLimitCache[action.data.path] = rateLimitData;
      
      // update the cookie
      // addCookieWithConsent('rateLimitCache', window.btoa(JSON.stringify(rateLimitCache)), 24);

      return {
        ...state,
        rateLimitCache: rateLimitCache
      }



    case 'UPDATE_CURSOR_CACHE':
      var cursorCache = state.cursorCache;

      if(!cursorCache[action.data.room]) cursorCache[action.data.room] = {};

      // determine whether the action.data.cursor.timestamp or action.data.timestamp is newer
      let timestampToUse = action.data.timestamp;

      if(cursorCache[action.data.room][action.data.user_id]){
        if(timestampToUse > cursorCache[action.data.room][action.data.user_id].timestamp || !cursorCache[action.data.room][action.data.user_id].timestamp){
          if(action.data.cursor){
            cursorCache[action.data.room][action.data.user_id] = {
              ...action.data.cursor,
              timestamp: timestampToUse
            }

          } else {
            delete cursorCache[action.data.room][action.data.user_id];
          }
        }
      } else {
        cursorCache[action.data.room][action.data.user_id] = {
          ...action.data.cursor,
          timestamp: timestampToUse
        }
      }

      return {
        ...state,
        cursorCache: cursorCache
      }
    
    case 'ADD_TO_SHARED_CACHE':
      var cache = state.cache;
      if(!cache[action.data.path]) cache[action.data.path] = { empty: false, data: []}
      cache[action.data.path].data.push(action.data.item);
      
      return {
        ...state,
        cache: cache
      }

    case 'REQUEST_GET_PAGINATED':
      var getPaginationRequests = state.getPaginationRequests;
      getPaginationRequests[action.data.lastSubmit.kind + "/" + action.data.lastSubmit.id + "/" + action.data.lastSubmit.collection] = true;
      return {
        ...state,
        getPaginationRequests: getPaginationRequests,
      }

    case 'RECEIVE_GET_PAGINATED_FAIL':
      var path = action.data.lastSubmit.kind + "/" + action.data.lastSubmit.id + "/" + action.data.lastSubmit.collection;

      var getPaginationRequests = state.getPaginationRequests;
      getPaginationRequests[path] = true;

      var getPaginationFail = state.getPaginationFail;
      getPaginationFail[path] = new Date().getTime();

      return {
        ...state,
        getPaginationRequests: getPaginationRequests,
        getPaginationFail: getPaginationFail
      }

    case 'RECEIVE_GET_PAGINATED_SUCCESS':
      var path = action.data.request.kind + "/" + action.data.request.id + "/" + action.data.request.collection;

      var getPaginationRequests = state.getPaginationRequests;
      getPaginationRequests[path] = false;

      var getPaginationSuccess = state.getPaginationSuccess;
      getPaginationSuccess[path] = new Date().getTime();

      var cache = state.cache;

      if(!cache[path]) cache[path] = { empty: false, data: []}

      if(action.data.response.data.length > 0){
        cache[path].empty = false;
        if(!cache[path].data || cache[path].data.length === 0 || !action.data.request.startAfter){
          cache[path].data = action.data.response.data;
        } else {
          if(cache[path].data[0].id !== action.data.response.data[0].id){
            cache[path].data = cache[path].data.concat(action.data.response.data);
          }
        }
      } else {
        cache[path].empty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.response.data.length < 100){
        cache[path].empty = true; 
      }

      return {
        ...state,
        getPaginationRequests: getPaginationRequests,
        getPaginationSuccess: getPaginationSuccess,
        cache: cache
      }


    case 'REQUEST_GET_SEARCH':

      var searchRequests = state.searchRequests;
      var searchString = new URLSearchParams(action.data.lastSubmit).toString();
      searchRequests[searchString] = {
        request: action.data.lastSubmit,
        trying: true,
      };

      return {
        ...state,
        searchRequests: searchRequests,
        tryingToSearch: true
      }

    case 'RECEIVE_GET_SEARCH_FAIL':

      var searchRequests = state.searchRequests;
      var searchString = new URLSearchParams(action.data.lastSubmit).toString();
      searchRequests[searchString] = {
        request: action.data.lastSubmit,
        trying: false,
        fail: new Date().getTime()
      };

      return {
        ...state,
        searchRequests: searchRequests,
        tryingToSearch: false
      }

    case 'RECEIVE_GET_SEARCH_SUCCESS':

      var searchRequests = state.searchRequests;
      var searchString = new URLSearchParams(action.data.request).toString();
      searchRequests[searchString] = {
        request: action.data.request,
        trying: false,
        success: new Date().getTime(),
        results: action.data.response
      };

      return {
        ...state,
        searchRequests: searchRequests,
        tryingToSearch: false
      }
      

    default:

      
      return {
        ...state
      }
  }
}
