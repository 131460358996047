import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js';
import CustomField from 'kit/components/CustomField/CustomField.js';
import Hydrate from 'components/Hydrate/Hydrate.js';

import toCamelCaseFunctionName from 'utilities/toCamelCaseFunctionName';

function RememberKnowledge({ options = {}, onChange, enabled, org_id, canWrite}) {
  const [selectedOption, setSelectedOption] = useState('');

  // Access the Redux dispatch function
  const dispatch = useDispatch();
  const knowledgeReducer = useSelector((state) => state.knowledgeReducer);

  let knowledgeCacheList = Object.keys(knowledgeReducer.cache).map(id => knowledgeReducer.cache[id]);
    
  let knowledge = [];
  if(org_id){
    knowledge = knowledgeCacheList.filter(p => p.scope === org_id);
  }

  return (
    <div>
      <p>
        This plugin allows the AI to contribute to a Knowledge Base. This can be used to help build up a Knowledge Base from proprietary information, store data that should be remembered, or any other information that should be stored for later use.
      </p>
      <p>
        It's important to note that the AI will only be able to contribute to the Knowledge Base, not read from it unless you specifically build that functionality into the AI's logic via a RAG variable or Retrieve Knowledge plugin.
      </p>
      <p>
        The information stored in the Knowledge Base will be available to any user in your organization and agent that is making use of the Knowledge Base. This plugin does not isolate or version-control the data stored in the Knowledge Base, so be sure to monitor and only keep information that should be shared across your organization and this Knowledge Base's use.
      </p>
      <p>
        Consider only enabling this plugin during an initial training or setup phase, and then disabling it once the Knowledge Base is populated with the necessary information. This will help prevent accidental or unwanted additions to the Knowledge Base.
      </p>
      <hr/>
      <CustomSelect
        placeholder="Select one of your organization's Knowledge Bases"
        disabled={!canWrite}
        label="Which Knowledge Base should this agent contribute to?"
        value={options.knowledge_id}
        options={(knowledge).map((k, i)=>{
          return {
            value: k.id,
            label: <div>
              <span className="text-900">{k.display_name}</span> <br/>
              <div className="text-ellipsis-2-lines">
                <small className="text-muted">by <Hydrate id={k.created_by} type="user"/></small>
              </div>
            </div>
          }
        })}
        onChange={e => {
          onChange({
            knowledge_id: e,
            knowledge_function_name: toCamelCaseFunctionName(knowledge.find(k => k.id === e).display_name, 'addTo'),
          })
        }}
        />

      <CustomField
        label="Function Name"
        value={options.knowledge_function_name}
        maxLength={32}
        onChange={e => {
          onChange({
            knowledge_function_name: e.value
          })
        }}
        disabled={!canWrite}
        placeholder="ex: addToMyKnowledgeBase"
        description="Automatically set based on Knowledge Base's name, but can be changed if necessary. This is the name of the function that will be called in the AI and should be unique across all enabled Remember Knowledge plugins on this agent."
        />

      <CustomField
        label="When and what should it remember in this Knowledge Base?"
        value={options.knowledge_function_description}
        maxLength={512}
        rows={10}
        onChange={e => {
          onChange({
            knowledge_function_description: e.value
          })
        }}
        disabled={!canWrite}
        placeholder="ex: Use this to save any preferences or data that should be remembered."
        description="The agent will see this text as instructions for what to remember in the Knowledge Base. This should be a clear and concise description of what kind of information the AI should aim to remember and why. This will be visible to the AI and can be used to help guide the AI's behavior."
        />

    </div>
  );
}

export default RememberKnowledge;
