import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect.js'
import Checkbox from '../../kit/components/Checkbox/Checkbox.js'
import Callout from '../../kit/components/Callout/Callout.js'


import {
  tryToSendOrganizationInvites
} from '../../actions/actions.export'

class SendOrganizationInvitesForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      email: ""
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToSendOrganizationInvites({
      id: this.props.id,
      emails: this.state.email.trim().split(/\s*,\s*|\s+/)
    }))
  }

  render(){

    const { orgReducer } = this.props;

    let canSubmit = this.state.email && !this.state.email_error;

    let canManageMembers = false;
    for(var i in orgReducer.memberships){
      if(orgReducer.memberships[i].organization.id === this.props.id){
        if(orgReducer.memberships[i].membership.manage_members){
          canManageMembers = true;
        }
      }
    }

    if(!canManageMembers) return <span/>;

    let emailsDetected = this.state.email.split(/\s*,\s*|\s+/).filter(e => e.length > 5).length;

    let organization = orgReducer.cache[this.props.id];
    if(!organization) return <span/>;

    let membership = orgReducer.memberships.find(m => m.organization.id === this.props.id);
    let membersQuota = membership.organization.quotas.members;
    let totalCurrentMembers = organization.member_count + (organization.invites || []).length;
    let canAddMembers = totalCurrentMembers < membersQuota;
    let invitesRemaining = membersQuota - totalCurrentMembers;

    return <div className={"box"}>
      <form onSubmit={()=>{
        if(canSubmit) this.handleSubmit()
      }}>

        <h4 className="no-margin-top margin-bottom-2rem">
          Invite New Members
        </h4>
        {
          canAddMembers ?
          <p>
            This organization can have up to {membersQuota} members. You have {invitesRemaining} invite{invitesRemaining === 1 ? "" : "s"} remaining.
          </p>
          :
          <p>
            You have reached the maximum number of members for this organization.
          </p>
        }
        <p>
          If you need more members, get in touch with your organization's administrator or ZeroWidth rep.
        </p>
        <CustomField
          name="email"
          label="Email Addresses"
          regex="list of emails"
          placeholder="ex: jane@example.com"
          description="Single email addresses or a comma/space separated list."
          maxLength={500}
          rows={2}
          required={true}
          disabled={orgReducer.tryingToSendOrganizationInvites || !canAddMembers}
          value={this.state.email}
          serverError={orgReducer.sendOrganizationInvitesForm.errors.email}
          lastSubmit={orgReducer.sendOrganizationInvitesForm.lastSubmit.email}
          tooltipDirection={'bottom'}
          onChange={(e) => {
            this.setState({
              email: e.value,
              email_error: e.error
            })
          }}
          />

        <div className="spacer-2rem"/>

        {
          orgReducer.sendOrganizationInvitesForm.errors.error &&
          <Callout
            style="danger"
            dismissable={true}
            content={orgReducer.sendOrganizationInvitesForm.errors.error}
          />
        }

        {
          organization.inviteResults !== undefined &&
          organization.inviteResults.fail.length > 0 &&
          <Callout
            style=""
            dismissable={true}
            title={<span><i className="far fa-exclamation-triangle text-warning icon-before-text"/> There were issues sending {organization.inviteResults.fail.length} of your invites</span>}
            content={<div>
                <table className="table-basic">
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Issue</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      organization.inviteResults.fail.map((f, i)=>{
                        return <tr key={i}>
                          <td className="text-700">
                            {f.email}
                          </td>
                          <td>
                            {f.issue === "already invited" && <span>A user with this email was already invited.</span>}
                            {f.issue === "already member" && <span>A user with this email is already a member.</span>}
                          </td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
                {
                  organization.inviteResults.success.length === 1 &&
                  <p className="no-margin-bottom">
                    <i className="far fa-check text-success icon-before-text"/>{organization.inviteResults.success.length} invite was sent successfully.
                  </p>
                }
                {
                  organization.inviteResults.success.length > 1 &&
                  <p className="no-margin-bottom">
                    <i className="far fa-check text-success icon-before-text"/>{organization.inviteResults.success.length} invites were sent successfully.
                  </p>
                }
              </div>
            }
            />
        }
        
        <div className="list-right">
          <CustomButton
            display={"Send " + (emailsDetected > 1 ? emailsDetected : "") + " Invite" + (emailsDetected > 1 ? "s" : "")}            
            color="success"
            thinking={orgReducer.tryingToSendOrganizationInvites}
            fail={orgReducer.sendOrganizationInvitesFail}
            success={orgReducer.sendOrganizationInvitesSuccess}
            disabled={!canSubmit || !canAddMembers}
            onClick={()=>{
              if(canSubmit) this.handleSubmit()
            }}
            />
        </div>
      </form>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { orgReducer } = state;

  return {
    orgReducer
  }
}

export default connect(mapStateToProps)(SendOrganizationInvitesForm);
