import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import './ProjectThumbnail.scss';

import Dropdown from '../../kit/components/Dropdown/Dropdown'
import Hydrate from '../../components/Hydrate/Hydrate'

import isFeatureSwitchOn from '../../utilities/isFeatureSwitchOn'

import {
  showTooltip,
  hideTooltip
} from '../../actions/actions.export'


class ProjectThumbnail extends Component {
  constructor(props){
    super(props);

  }
  
  render(){

    const { projectReducer, userReducer, orgReducer } = this.props;

    let project = projectReducer.cache[this.props.id];

    if(!project) return <span/>

    // first determine if you have access to this project or not, then adjust which link this thumbnail points you to
    let url = "/p/" + project.id;

    if(!this.props.public){
      if(userReducer.isLoggedIn){
        if(orgReducer.membershipsReady){
          if(orgReducer.memberships.find(m => m.organization.id === project.scope)){
            url = "/project/" + project.id;
          }
        }
      }
    }

    return <Link className={"project-thumbnail "} to={url}>
      <div className="project-thumbnail-img-wrapper">
      </div>
      <div className="project-thumbnail-details">
        <div className="flex-split">
          <div className="project-thumbnail-name text-ellipsis">
            {project.display_name}
          </div>
          <div>
            <small>
              {project.visibility === "org" && <i className="far fa-lock-alt text-warning"/>}
              {project.visibility === "team" && <i className="far fa-lock-alt text-danger"/>}
            </small>
          </div>
        </div>
      </div>
    </Link>
  }
}
  
const mapStateToProps = (state) => {
  const {projectReducer, userReducer, orgReducer } = state;

  return {
    projectReducer, userReducer, orgReducer
  }
}

export default connect(mapStateToProps)(ProjectThumbnail);
