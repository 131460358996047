import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux';
import moment from 'moment'
import nanoid from 'nanoid'

import {
  encode,
  decode
} from 'gpt-tokenizer'


import Hydrate from 'components/Hydrate/Hydrate'
import CustomField from 'kit/components/CustomField/CustomField'
import Checkbox from 'kit/components/Checkbox/Checkbox'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import Callout from 'kit/components/Callout/Callout.js'
import Modal from 'kit/components/Modal/Modal.js'

import {
  tryToGetPaginated,
  tryToCreateInformation,
  tryToCreateFile,
  tryToCrawlURL,
  showTooltip,
  hideTooltip
} from 'actions/actions.export'

import { downloadArrayOfStringsAsTXTZip } from 'utilities/downloadArrayOfStringsAsTXTZip.js'

let recalcTimeout;

class KnowledgeInfo extends Component {
  constructor(props){
    super(props);

    this.state = {
      lastDelete: 0,
      crawl_url: "",
      info_custom_search: false,
      showTip: false,
      searchText: "",
      info_display_name: "",
      info_content: "",
      info_embedded_content: "",
      info_source_type: "manual",
      info_source_name: "",
      info_source_url: "",
      info_source_page: "",
      info_source_files: [],
      multiMode: false,
      id: "",
      info_multi_content: [],
      chunking_strategy: "token",
      chunking_step: 0,
      chunk_size: 300,
      chunk_overlap: 0,
      chunk_delimiter: ".",
      overlapBetweenFiles: false,
      use_page_break_delimiter: true,
      uploadMode: 'files',
      chunks: [],
      review_chunk: 0,
      review_view: 'gallery',
      review_gallery_sort: 'source',
      allChunksStartPage: 0,
      allChunksPageSize: 24,
      multiFileUploadSuccess: 0,
      multiUploadSuccessCounter: 0
    }

    // this.catchCrawledURL = this.catchCrawledURL.bind(this);

    this.createEverything = this.createEverything.bind(this);
    this.createFiles = this.createFiles.bind(this);
    this.createInformation = this.createInformation.bind(this);
  }

  async createFiles(retry) {
    this.setState({
      tryingToCreateFiles: true
    });
  
    let successCounter = this.state.info_source_files.filter(file => file.success).length;
    let errorCounter = 0;
  
    let filesThatNeedCreating = this.state.info_source_files.filter(file => !file.success);
  
    for (let file of filesThatNeedCreating) {
      try {
        const response = await new Promise((resolve, reject) => {
          this.props.dispatch(tryToCreateFile({
            knowledge_id: this.props.id,
            display_name: file.name,
            pages: file.pages,
            type: file.type,
            original_content: file.upload_original ? file.original_content : "",
            total_tokens: encode(file.original_content).length,
            total_chunks: this.state.chunks.filter(c => c.file_index === file.temp_i).length,
            chunking_strategy: this.state.chunking_strategy,
            chunk_size: this.state.chunk_size,
            chunk_overlap: this.state.chunk_overlap,
            chunk_delimiter: this.state.chunk_delimiter,
            use_page_break_delimiter: this.state.use_page_break_delimiter,
          }, (e, json) => {
            if (e) reject(e);
            else resolve(json);
          }));
        });
  
        // Handle success case
        file.id = response.data.id;
        file.success = true;
  
        // Update chunks
        let chunks = this.state.chunks;
        for (let chunk of chunks) {
          if (chunk.file_index === file.temp_i) {
            chunk.file_id = response.data.id;
          }
        }
  
        successCounter++;
      } catch (error) {
        // Handle error case
        file.error = error.errors;
        errorCounter++;
      }
  
      this.setState({
        info_source_files: this.state.info_source_files,
        multiFileCreating: successCounter + errorCounter !== this.state.info_source_files.length,
        multiFileUploadErrors: errorCounter
      });
  
      if (successCounter === this.state.info_source_files.length) {
        setTimeout(() => {
          this.createInformation();
        }, 250);
      }
  
      if (successCounter + errorCounter === this.state.info_source_files.length) {
        this.setState({
          tryingToCreateFiles: false
        });
      }
    }
  }
  

  async createInformation() {
    const { dispatch, knowledgeReducer } = this.props;
  
    let knowledge = knowledgeReducer.cache[this.props.id];
  
    let successCounter = this.state.chunks.filter(chunk => chunk.success).length;
    let errorCounter = 0;
  
    this.setState({
      tryingToCreateInformation: true
    });
  
    const chunksThatNeedCreating = this.state.chunks.filter(chunk => !chunk.success);
  
    for (let chunk of chunksThatNeedCreating) {
      try {
        const response = await new Promise((resolve, reject) => {
          dispatch(tryToCreateInformation({
            knowledge_id: this.props.id,
            file_id: chunk.file_id,
            display_name: chunk.content.slice(0, 32),
            content: chunk.content,
            start_overlap: chunk.start_overlap,
            end_overlap: chunk.end_overlap,
            embedded_content: (chunk.start_overlap  || "") + chunk.content + (chunk.end_overlap || ""),
            source_type: this.state.uploadMode === "files" ? 'file' : 'manual',
            source_name: chunk.file || "",
            source_page: "",
            scope: knowledge.scope,
            noPush: true
          }, (e, json) => {
            if (e) reject(e);
            else resolve(json);
          }));
        });
  
        // Handle success case
        chunk.id = response.data.id;
        chunk.success = true;
        successCounter++;
      } catch (error) {
        // Handle error case
        chunk.error = error.errors;
        errorCounter++;
      }
  
      this.setState({
        multiCreating: successCounter + errorCounter !== chunksThatNeedCreating.length,
        multiUploadErrorCounter: errorCounter,
        multiUploadSuccessCounter: successCounter
      });
  
      if (successCounter === this.state.chunks.length) {
        
        // are we nested? does the pathname contain "projects"
        if (window.location.pathname.indexOf('projects') > -1) {
          // split by /knowledge/ then add it back
          let pathParts = window.location.pathname.split('/knowledge/');
          dispatch(push(pathParts[0] + '/knowledge/' + this.props.id));
        } else {
          dispatch(push('/workbench/' + knowledge.scope + '/knowledge/' + this.props.id));
        }
        
        dispatch(tryToGetPaginated({
          kind: 'knowledge',
          id: this.props.id,
          collection: 'info',
          orderBy: 'created_at'
        }));
      }
  
      if (successCounter + errorCounter === this.state.chunks.length) {
        this.setState({
          tryingToCreateInformation: false
        });
      }
    }
  }
  
  async createEverything() {
    this.setState({ processing: true }); // Optional: manage UI state to indicate processing
  
    try {
      if (this.state.uploadMode === "files") {
        await this.createFiles();
      } else {
        await this.createInformation();
      }
      console.log("Operation successful");
    } catch (error) {
      console.error("An error occurred:", error);
      // Optionally handle errors, such as updating state to show an error message
    } finally {
      this.setState({ processing: false }); // Reset processing state regardless of outcome
    }
  }
  

  handleFileUpload = async event => {
    let info_multi_content = [];

    this.setState({
      tryingToUploadFiles: true
    });

    const processPDF = async file => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onload = async () => {
                const pdfData = new Uint8Array(reader.result);
                const pdfDoc = await window.pdfjsLib.getDocument({ data: pdfData }).promise;
                let textContentArray = [];
                for (let i = 0; i < pdfDoc.numPages; i++) {
                    const page = await pdfDoc.getPage(i + 1);
                    const textContent = await page.getTextContent();
                    textContentArray.push(
                        textContent.items.map(item => item.str).join('')
                    );
                }
                const pdfContent = textContentArray.map((t, i) => {
                    return {
                        display_name: file.name + ' Page ' + (i + 1),
                        source_page: 'Page ' + (i + 1),
                        content: t,
                        info_source_type: 'pdf',
                        info_source_name: file.name,
                        embedded_content: t
                    }
                });
                resolve(pdfContent);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
    };

    const processText = file => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onload = () => {
                const textContent = reader.result;
                const textFileContent = {
                    display_name: file.name,
                    source_page: 'Page 1',
                    info_source_type: 'txt',
                    content: textContent,
                    info_source_name: file.name,
                    embedded_content: textContent
                };
                resolve(textFileContent);
            };
            reader.onerror = reject;
            reader.readAsText(file);
        });
    };

    const promises = [];
    for (let f = 0; f < event.target.files.length; f++) {
        const file = event.target.files[f];
        if (file) {
            if (file.type === 'application/pdf') {
                promises.push(processPDF(file));
            } else if (file.type === 'text/plain') {
                promises.push(processText(file));
            }
        }
    }

    Promise.all(promises).then(results => {
        results.forEach(result => {
            if (Array.isArray(result)) {
                info_multi_content = info_multi_content.concat(result);
            } else {
                info_multi_content.push(result);
            }
        });

        // loop through info_multi_content to identify the source files and page count for each
        let uploadedFiles = [];

        info_multi_content.forEach((c, i) => {
            let index = uploadedFiles.findIndex(f => f.name === c.info_source_name);
            if (index === -1) {
              uploadedFiles.push({
                original_name: c.info_source_name,
                original_content: c.content,
                name: c.info_source_name,
                type: c.info_source_type,
                pages: 1,
                upload_original: true,
                share_with_agent: true
              });
            } else {
              uploadedFiles[index].pages++;
              uploadedFiles[index].original_content += c.content;
            }
        });
        

        this.setState({
            // multiMode: true,
            info_source_type: 'file',
            info_multi_content: info_multi_content,
            info_content: info_multi_content.map(c => c.content).join('\n'),
            info_source_files: uploadedFiles,
            tryingToUploadFiles: false
        });
    });
  }

  // catchCrawledURL(packet){
  //   let paragraphs = packet.textContent.trim().split(/\n\s*\n/).filter(paragraph => paragraph.trim().length > 0);

  //   function mergeShortStrings(arr, maxLength) {
  //     let result = [];
  //     let i = 0;

  //     while (i < arr.length) {
  //       let mergedString = arr[i];
  //       i++;

  //       while (i < arr.length && (mergedString.length + arr[i].length) < maxLength) {
  //         mergedString += arr[i];
  //         i++;
  //       }

  //       result.push(mergedString);
  //     }

  //     return result;
  //   }


  //   let adjustedParagraphs = mergeShortStrings(paragraphs, 1000);


  //   let info_multi_content = adjustedParagraphs.map((t,i) => {
  //     return {
  //       display_name: packet.title,
  //       source_page: '',
  //       content: t,
  //       embedded_content: t
  //     }
  //   })

  //   this.setState({
  //     multiMode: true,
  //     info_source_name: packet.title,
  //     info_source_type: 'url',
  //     info_source_url: packet.url,
  //     info_multi_content: info_multi_content
  //   })
  // }


  debouncedRecalculateChunks = () => {
    clearTimeout(recalcTimeout);

    recalcTimeout = setTimeout(() => {
      this.recalculateChunks();
    }, 250);
  }

  recalculateChunks = () => {

    let chunks = [];


    function adjustChunksForOverlap(textChunks, overlap) {
      overlap = parseInt(overlap);
      let retval = textChunks.map((c, i) => {
        return {
          content: c,
          start_overlap_tokens: [],
          tokens: encode(c),
          end_overlap_tokens: []
        }
      })

      if(overlap === 0) return retval;
      
      for (let i = 0; i < retval.length; i++) {
        let backtrackIndex = i - 1;
        let forwardIndex = i + 1;

        while (backtrackIndex >= 0 && retval[i].start_overlap_tokens.length < overlap) {
          let remainingOverlap = overlap - retval[i].start_overlap_tokens.length;
          retval[i].start_overlap_tokens = retval[backtrackIndex].tokens.slice(-remainingOverlap).concat(retval[i].start_overlap_tokens);
          backtrackIndex--;
        }

        while (forwardIndex < retval.length && retval[i].end_overlap_tokens.length < overlap) {
          let remainingOverlap = overlap - retval[i].end_overlap_tokens.length; 
          retval[i].end_overlap_tokens = retval[i].end_overlap_tokens.concat(retval[forwardIndex].tokens.slice(0, remainingOverlap));
          forwardIndex++;
        }
      }

      retval = retval.map(c => {
        return {
          ...c,
          total_tokens: c.start_overlap_tokens.length + c.tokens.length + c.end_overlap_tokens.length,
          start_overlap: decode(c.start_overlap_tokens),
          end_overlap: decode(c.end_overlap_tokens),
        }
      })

      return retval;
    }


    function splitTextIntoTokens(text, chunkSize, overlap) {
      chunkSize = parseInt(chunkSize);
      overlap = parseInt(overlap);

      if(chunkSize === 0) return [text];
      
      let tokens = encode(text);
      let splitChunks = [];

      for (let i = 0; i < tokens.length; i += chunkSize) {
        let start = i;
        let end = i + chunkSize;
        if (start < 0) start = 0;
        if (end > tokens.length) end = tokens.length;
        let chunk = tokens.slice(start, end);
        splitChunks.push(chunk);
      }
      let chunkedText = splitChunks.map(c => decode(c));
      
      return adjustChunksForOverlap(chunkedText, overlap);
    }


    if(this.state.chunking_strategy === "single"){
      if(this.state.uploadMode === "files"){
        for(let i in this.state.info_source_files){
          let file = this.state.info_source_files[i];
          let full_full_text = this.state.info_multi_content.filter(c => c.info_source_name === file.name).map(c => c.content).join('');
          chunks.push({
            content: full_full_text,
            tokens: encode(full_full_text),
            file_index: i,
            file: file.name
          })
        }
      } else {
        chunks = [{
          content: this.state.info_content,
          tokens: encode(this.state.info_content)
        }]
      }
    } else if(this.state.chunking_strategy === "token"){
      if(this.state.uploadMode === "files" && !this.state.overlapBetweenFiles){
        for(let i in this.state.info_source_files){
          let file = this.state.info_source_files[i];
          let full_full_text = this.state.info_multi_content.filter(c => c.info_source_name === file.name).map(c => c.content).join('');
          let file_chunks = splitTextIntoTokens(full_full_text, this.state.chunk_size, this.state.chunk_overlap);
          chunks = chunks.concat(file_chunks.map((c, index) => {
            return {
              ...c,
              file_index: i,
              file: file.name,
            }
          }));
        }
      } else {
        chunks = splitTextIntoTokens(this.state.info_content, this.state.chunk_size, this.state.chunk_overlap);        
      }
    } else if(this.state.chunking_strategy === "delimiter" && !this.state.use_page_break_delimiter){
      
      if(this.state.uploadMode === "files" && !this.state.overlapBetweenFiles){
        for(let i in this.state.info_source_files){
          let file = this.state.info_source_files[i];
          let full_file_text = this.state.info_multi_content.filter(c => c.info_source_name === file.name).map(c => c.content).join('');
          let file_chunks = adjustChunksForOverlap(full_file_text.split(this.state.chunk_delimiter || ' '), this.state.chunk_overlap);
          chunks = chunks.concat(file_chunks.map((c) => {
            return {
              ...c,
              file_index: i,
              file: file.name
            }
          }));
        }
      } else {
        let text_chunks = this.state.info_content.split(this.state.chunk_delimiter || ' ');

        chunks = adjustChunksForOverlap(text_chunks, this.state.chunk_overlap);
      }

    } else if(this.state.chunking_strategy === "delimiter" && this.state.use_page_break_delimiter){

      if(this.state.uploadMode === "files" && !this.state.overlapBetweenFiles){
        for(var i in this.state.info_source_files){
          let file = this.state.info_source_files[i];
          let pages = this.state.info_multi_content.filter(c => c.info_source_name === file.name).map(c => c.content);
          chunks = chunks.concat(adjustChunksForOverlap(pages, this.state.chunk_overlap).map((c,j) => {
            return {
              ...c,
              file_index: i,
              file: file.name,
              page: j + 1
            }
          }));
        }
      } else {

        let text_chunks = this.state.info_multi_content.map(c => c.content);

        chunks = adjustChunksForOverlap(text_chunks, this.state.chunk_overlap).map((c,i) => {

          return {
            ...c,
            page: i + 1,
          }
        }); 
      }
    }

    // remove empty chunks
    chunks = chunks.filter(c => c.content.trim().length > 0);
    
    // assign a nanoid and a relative chunk index
    chunks = chunks.map((c,index) => {
      return {
        ...c,
        relative_index: parseInt(index),
        id: nanoid()
      }
    })

    this.setState({
      chunks: chunks
    });
  }


  render(){
    const { dispatch, knowledgeReducer, userReducer, orgReducer, sharedReducer, item, canWrite } = this.props;

    const knowledge = knowledgeReducer.cache[this.props.id];

    if(!knowledge) return <span>Failed to load.</span>;

    let fromSearch = false;

    const urlParams = new URLSearchParams(window.location.search);
    const searched = urlParams.get('searched');

    if (searched) {
      // Do something if the "searched" parameter is set
      fromSearch = true;
    } else {
      // Do something else if the "searched" parameter is not set
      fromSearch = false;
    }

    let steps = [
      {
        title: "Enter Raw Information",
        description: "Enter the full text you'd like to add to this Knowledge Base by typing, pasting, or uploading PDF or TXT files.",
      },
      {
        title: "Choose Chunking Method",
        description: "Choose a method to split the information into smaller chunks to be used by the agent in either variable or functional RAG."
      },
      // {
      //   title: "Metadata",
      //   description: "Add metadata to the information, such as the source, page details, and how these are shared with the agent.",
      // },
      // {
      //   title: "Review",
      //   description: "Review the information and metadata before creating it in the Knowledge Base and enabling agent access."
      // },
      // {
      //   title: "Creating",
      //   description: "Creating the information and metadata in the Knowledge Base."
      // }
    ]

    if(this.state.uploadMode === "files"){
      steps.push({
        title: "Create",
        description: "Load the files and information into the Knowledge Base."
      })
    } else {
      steps.push({
        title: "Review",
        description: "Review the information and metadata before creating it in the Knowledge Base and enabling agent access."
      })

      steps.push({
        title: "Create",
        description: "Load, embed, and index this information in the Knowledge Base so it can be used by agents you design."
      })
    }

    let current_total_tokens = 0;
    current_total_tokens = this.state.info_content ? encode(this.state.info_content).length : 0;


    let sortedChunks = [...this.state.chunks];
    if(this.state.review_gallery_sort === 'source'){
      sortedChunks = sortedChunks.sort((a, b) => {
        if(a.file < b.file) return -1;
        if(a.file > b.file) return 1;
        return 0;
      })
    } else if(this.state.review_gallery_sort === 'tokens_low'){
      sortedChunks = sortedChunks.sort((a, b) => {
        if(a.tokens.length < b.tokens.length) return -1;
        if(a.tokens.length > b.tokens.length) return 1;
        return 0;
      })
    } else if(this.state.review_gallery_sort === 'tokens_high'){
      sortedChunks = sortedChunks.sort((a, b) => {
        if(a.tokens.length > b.tokens.length) return -1;
        if(a.tokens.length < b.tokens.length) return 1;
        return 0;
      })
    }


      return <div className="">

        <Modal
          show={this.state.crawlModalOpen}
          maxWidth={400}
          exitable={true}
          onExit={e => this.setState({crawlModalOpen: false})}
          cancelable={true}
          onCancel={e => this.setState({crawlModalOpen: false})}
          acceptable={true}
          acceptButtonThinking={knowledgeReducer.tryingToCrawlURL}
          acceptButtonFail={knowledgeReducer.crawlURLFail}
          acceptButtonSuccess={knowledgeReducer.crawlURLSuccess}
          acceptButtonLabel="Go"
          acceptButtonDisabled={!this.state.crawl_url || this.state.crawl_url_error}
          acceptButtonColor="black"
          onAccept={e => {
            dispatch(tryToCrawlURL({
              url: this.state.crawl_url
            }, this.catchCrawledURL))
          }}
          content={<div>
            <h4 className="no-margin-top">From URL</h4>
            <p>
              To easily create a Knowledge Base based on the content of a public website, enter a URL below to grab its text content.
            </p>
            <CustomField
              name="url"
              label="URL"
              placeholder="http://example.com"
              regex="url"
              value={this.state.crawl_url}
              onChange={e => {
                
                this.setState({
                  crawl_url: e.value,
                  crawl_url_error: e.error
                })
              }}
              />
          </div>}
          />

        <Modal
          show={this.state.showDeleteChunkModal}
          maxWidth={400}
          exitable={true}
          onExit={e => this.setState({showDeleteChunkModal: false})}
          cancelable={true}
          onCancel={e => this.setState({showDeleteChunkModal: false})}
          acceptable={true}
          acceptButtonColor="danger"
          acceptButtonLabel="Delete Chunk"
          onAccept={e => {
            let chunks = this.state.chunks;
            chunks.splice(this.state.review_chunk, 1);
            this.setState({
              chunks: chunks,
              showDeleteChunkModal: false
            })
          }}
          content={<div>
            <h4 className="no-margin-top">Delete Chunk</h4>
            <p>
              Are you sure you want to delete this chunk of information?
            </p>
          </div>}
          />
    
        <div className="row">
          <div className="col-md-3">
            <div className="box">
              
              <CustomButton
                size="small"
                color="grey"
                block={true}
                noMargin={true}
                disabled={this.state.chunking_step === 0}
                display={<span><i className="far fa-angle-left icon-before-text"/>Back</span>}
                onClick={e => {
                  this.setState({
                    chunking_step: this.state.chunking_step - 1
                  })
                }}
                />
              <div className="spacer-1rem"/>
              <small className="text-900 text-uppercase text-knowledge">Step {this.state.chunking_step + 1}:</small>
              <div className="flex-split margin-bottom-1rem">
                <h4 className="no-margin">{steps[this.state.chunking_step].title}</h4>
              </div>
              <p className="margin-bottom-3rem">
                <small>{steps[this.state.chunking_step].description}</small>
              </p>
              {
                this.state.chunking_step === steps.length - 1 ?
                <div>
                  <CustomButton
                    size="small"
                    color="grey"
                    block={true}
                    noMargin={true}
                    display={<span><i className="far fa-arrow-down icon-before-text"/>Download Chunks as .TXT</span>}
                    onClick={() => {
                      downloadArrayOfStringsAsTXTZip(this.state.chunks.map(chunk => chunk.content));
                    }}
                    />
                  <div className="spacer-1rem"/>
                  <CustomButton
                    size="small"
                    color="knowledge"
                    block={true}
                    noMargin={true}
                    disabled={!canWrite}
                    thinking={this.state.tryingToCreateInformation}
                    display={<span><i className="far fa-book-spells icon-before-text"/>Add to Knowledge Base</span>}
                    onClick={() => {
                      // this.setState({
                      //   chunking_step: this.state.chunking_step + 1
                      // })
                      this.createEverything()
                    }}
                    />
                </div>
                :
                <CustomButton
                  size="small"
                  color="black"
                  block={true}
                  noMargin={true}
                  disabled={
                    this.state.chunking_step === steps.length - 1 || 
                    (this.state.chunking_step === 0 && this.state.info_content == "") ||
                    (this.state.chunking_step === 0 && this.state.uploadMode === "files" && this.state.info_source_files.length === 0)
                  }
                  display={<span>Next Step<i className="far fa-angle-right icon-after-text"/></span>}
                  onClick={e => {
                    if(this.state.chunking_step === 0){
                      this.debouncedRecalculateChunks();
                      if(current_total_tokens < 500){
                        this.setState({
                          chunking_strategy: "single"
                        })
                      }
                    }

                    let uploadMode = this.state.uploadMode;
                    if(this.state.chunking_step === 1){
                      uploadMode = "raw";
                    }

                    this.setState({
                      chunking_step: this.state.chunking_step + 1,
                      review_chunk: 0,
                      uploadMode: uploadMode
                    })
                    
                  }}
                  />
                }
            </div>
          </div>
          {
            this.state.chunking_step === 0 &&
            <div className="col-md-9">
              <div className="box">
                <div className="row margin-bottom-2rem">
                  <div className="col-md-12">
                    <h4 className="no-margin">Information Source</h4>
                    <p className="">
                      What kind of information would you like to add?
                    </p>
                  </div>
                  <div className="col-md-6 flex-column-stretch">
                    <div className={"box flex-grow no-margin-bottom " + (this.state.uploadMode === 'files' ? 'box-border-black box-clickable ' : ' box-clickable box-placeholder box-muted')} onClick={e => {
                      this.setState({ 
                        uploadMode: 'files',
                        use_page_break_delimiter: true
                      })
                    }}>
                      <i className="fad fa-file-pdf fa-3x text-knowledge"/>
                      <h5 className="no-margin-bottom">Text From Files</h5>
                      <p>
                        For uploading PDF or TXT files to be scraped for text content.
                      </p>
                      <small className="text-semi-muted">Recommended for extracting information from documents and files that already exist.</small>
                    </div>
                  </div>
                  <div className="col-md-6 flex-column-stretch">
                    <div className={"box flex-grow no-margin-bottom " + (this.state.uploadMode === 'raw' ? 'box-border-black box-clickable ' : ' box-clickable box-placeholder box-muted')} onClick={e => {
                      this.setState({ 
                        uploadMode: 'raw',
                        use_page_break_delimiter: false
                      })
                    }}>
                      <i className="fad fa-text fa-3x text-knowledge"/>
                      <h5 className="no-margin-bottom">Raw Text</h5>
                      <p>
                        For simply pasting in a large amount of plain text.
                      </p>
                      <small className="text-semi-muted">Recommended for manually designing a Knowledge Base for a very bespoke purpose.</small>
                    </div>
                  </div>
                </div>
                <hr/>
                {
                  this.state.uploadMode === 'files' &&
                  <div className="">
                    <div className="flex-split margin-bottom-1rem">
                      <div className="list-left">
                        <div>
                          <input 
                            type="file" 
                            accept=".pdf, .txt" 
                            onChange={this.handleFileUpload} 
                            id="knowledge-pdf-uploader" 
                            style={{display: "none"}}
                            multiple
                          />
                          <CustomButton
                            size="small"
                            color="black"
                            thinking={this.state.tryingToUploadFiles}
                            disabled={!canWrite}
                            display={<span><i className="far fa-arrow-up icon-before-text"/>Choose PDF or TXT Files</span>}
                            onClick={e => {
                              document.getElementById('knowledge-pdf-uploader').click()
                            }}
                          />
                        
                        </div>
                        <small>You can select multiple files at once.</small>
                      </div>
                      <div>
                        <CustomButton
                          display={<span><i className="far fa-bomb icon-before-text"/>Explode Files</span>}
                          size="small"
                          onMouseEnter={e => {
                            dispatch(showTooltip({
                              el: e.target,
                              nobr: false,
                              position: 'left',
                              content: <div style={{maxWidth: 250}} className="text-400">
                                <span className="text-900 no-margin">What does this do?</span>
                                <p className="thin-line-height">
                                  <small>
                                    This decouples file contents from their metadata, treating the text as raw content.
                                  </small>
                                </p>
                              </div>
                            }))
                          }}
                          onMouseLeave={e => {
                            dispatch(hideTooltip())
                          }}
                          onClick={e => {
                            this.setState({
                              uploadMode: 'raw',
                              info_source_files: [],
                              info_multi_content: []
                            })
                          }}
                          />
                      </div>
                    </div>

                    {
                      this.state.uploadMode === "files" ?
                      <div className="">
                        <hr/>
                        <p>
                          The following files have been selected:
                        </p>
                      </div>
                      :
                      <div className="box box-placeholder text-muted">
                        No files have been uploaded yet.
                      </div>
                    }


                    {
                      this.state.info_source_files.map((f, i) => {

                      return <div className="box margin-top-1rem box-knowledge-left box-light-border" key={i}>
                        <div className="flex-split">
                          {
                            f.type === "pdf" ?
                            <i className="fad fa-3x fa-file-pdf text-knowledge icon-before-text"/>
                            :
                            <i className="fad fa-3x fa-file-alt text-knowledge icon-before-text"/>
                          }
                        
                          <div className="flex-grow margin-left-1rem">
                            <h5 className="no-margin">{f.name}</h5>
                            {
                              f.type === "pdf" &&
                              <p className="no-margin">
                                <small>Text from {f.pages} pages {f.pages === 1 ? "was" : "were"} collected.</small>
                              </p>
                            }
                            {
                              f.type === "txt" &&
                              <p className="no-margin">
                                <small>Text content parsed.</small>
                              </p>
                            }
                          </div>
                          <div>
                            <CustomButton
                              size="small"
                              color="transparent"
                              display={<i className="far fa-times text-muted"/>}
                              onClick={e => {
                                let files = this.state.info_source_files;
                                files.splice(i, 1);

                                let info_multi_content = this.state.info_multi_content.filter(c => c.info_source_name !== f.name);
                                let info_content = info_multi_content.map(c => c.content).join('\n');

                                this.setState({
                                  info_source_files: files,
                                  info_multi_content: info_multi_content,
                                  info_content: info_content
                                })
                              }}
                              />
                          </div>
                        </div>
                      </div>
                      })
                    }
                  </div>
                }
                {
                  this.state.uploadMode === 'raw' &&
                  <div className="">
                    <div className="flex-split margin-bottom-1rem">
                      <h4 className="no-margin">Raw Information</h4>
                    </div>
                    <CustomField
                      name="content"
                      inline={true}
                      placeholder="Enter the full text you'd like to add to this Knowledge Base here..."
                      value={this.state.info_content}
                      rows={5}
                      description={<span>{current_total_tokens} tokens</span>}
                      onChange={e => {
                        this.setState({
                          info_content: e.value
                        })
                      }}
                      />
                  </div>
                }
              </div>
            </div>
          }

          {
            this.state.chunking_step === 1 &&
            <div className="col-md-9">
              <div className="box">
                <div className="flex-split margin-bottom-1rem">
                  <h4 className="no-margin">Chunking Strategy</h4>
                  <div className="list-right">
                    
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="no-margin">Your raw information contains {current_total_tokens} tokens.</h5>
                    <p>
                      Choose a method to split the information into smaller chunks to be used by the agent.
                    </p>
                  </div>
                  <div className="col-md-4 flex-column-stretch">
                    <div className={"box flex-grow no-margin-bottom " +  (current_total_tokens > 8000 ? " box-danger box-muted no-pointer-events " : (this.state.chunking_strategy === 'single' ? 'box-border-black box-clickable ' : ' box-clickable box-placeholder box-muted'))} onClick={e => {
                        let tokens = current_total_tokens;
                        if(tokens > 8000) return;
                        this.setState({ chunking_strategy: 'single' })
                        this.debouncedRecalculateChunks();
                      }}>
                      <h5 className="no-margin">Single Chunk</h5>
                      <p>
                        Put this entire text block into a single chunk.
                      </p>
                      {
                        current_total_tokens > 8000 ?
                        <small className="text-danger">This text is too large to be used as a single chunk.</small>
                        :
                        <small className="text-semi-muted">Best suited for manually creating chunks of known data of a limited size, typically 300 - 500 tokens.</small>
                      }
                    </div>
                  </div>

                  <div className="col-md-4 flex-column-stretch">
                    <div className={"box flex-grow no-margin-bottom box-clickable " + (this.state.chunking_strategy === 'token' ? 'box-border-black' : 'box-placeholder box-muted')} onClick={e => {
                      this.setState({ chunking_strategy: 'token' })
                      this.debouncedRecalculateChunks();
                      }}>
                      <h5 className="no-margin">Token-based Chunking</h5>
                      <p>
                        Break this text into standard chunks of a set token size.
                      </p>
                      <small className="text-semi-muted">Best suited for information that has a loose or unknown structure.</small>
                    </div>
                  </div>

                  <div className="col-md-4 flex-column-stretch">
                    <div className={"box flex-grow no-margin-bottom box-clickable " + (this.state.chunking_strategy === 'delimiter' ? 'box-border-black' : 'box-placeholder box-muted')} onClick={e => {
                      this.setState({ chunking_strategy: 'delimiter' })
                      this.debouncedRecalculateChunks();
                      }}>
                      <h5 className="no-margin">Chunk by Delimiter</h5>
                      <p>
                        Split up this text based on a delimiter, such as a period, question mark, new line, or page in its source PDF.
                      </p>
                      <small className="text-semi-muted">Recommended for situations where the text is organized in a way that aligns to its intended use case.</small>
                    </div>
                  </div>
                </div>
                <h4/>
                {
                  this.state.chunking_strategy === 'token' &&
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="no-margin">Your raw information contains {current_total_tokens.toLocaleString()} tokens.</h5>
                      <p>
                        Set the size of each chunk and how much they overlap. If you're unsure, a common best practice is to set the chunksize to about 300 tokens and the overlap to about 50 tokens.
                      </p>
                    </div>
                    <div className="col-md-6">
                      <CustomField
                        name="chunk_size"
                        label="Initial Chunk Size"
                        placeholder="300"
                        min={10}
                        max={Math.min(8000, current_total_tokens)}
                        type="range"
                        value={this.state.chunk_size}
                        onChange={e => {
                          this.setState({
                            chunk_size: parseInt(e.value)
                          })
                          this.debouncedRecalculateChunks();
                        }}
                        />
                    </div>
                    <div className="col-md-6">
                      <CustomField
                        name="chunk_overlap"
                        label="+ Chunk Overlap"
                        placeholder="50"
                        min={0}
                        max={8000 - this.state.chunk_size}
                        type="range"
                        value={this.state.chunk_overlap}
                        onChange={e => {
                          this.setState({
                            chunk_overlap: parseInt(e.value)
                          })
                          this.debouncedRecalculateChunks();
                        }}
                        />
                      <Checkbox
                        style="align-left"
                        description={<span
                          onMouseEnter={e => {
                            dispatch(showTooltip({
                              el: e.target,
                              nobr: false,
                              position: 'bottom',
                              content: <div style={{maxWidth: 250}} className="text-400">
                                <span className="text-900 no-margin">What does this do?</span>
                                <p className="thin-line-height">
                                  <small>Controls whether the text of the overlapped content (shown in grey below) is included in the embedded content of the chunk. Typically left off so to make the search vectors more specific while still providing more context to the agent when it is generating.</small>
                                </p>
                                <p className="thin-line-height">
                                  <small>
                                    If left off (default), the overlapped text will not impact how this chunk appears in search, but will be made available to the agent for augmenting its generation.
                                  </small>
                                </p>
                                <p className="thin-line-height no-margin-bottom">
                                  <small>
                                    If turned on, the overlapped text will be included in the embedded content, and will impact how each chunk is retrieved in the search step.
                                  </small>
                                </p>
                              </div>
                            }))
                          }}
                          onMouseLeave={e => {
                            dispatch(hideTooltip())
                          }}
                          >
                            Include overlap in the embedded content.<i className='far fa-question-circle text-muted icon-after-text'/>
                        </span>}
                        value={this.state.embed_overlap}
                        onToggle={e => {
                          this.setState({
                            embed_overlap: e
                          })
                        }}
                        />
                      {/* {
                        this.state.info_multi_content.length > 0 &&
                      
                        <Checkbox
                          style="align-left"
                          disabled={this.state.info_source_files.length === 1}
                          description="Overlap chunks between uploaded files."
                          value={this.state.overlapBetweenFiles}
                          onToggle={e => {
                            this.setState({
                              overlapBetweenFiles: e
                            })
                            this.debouncedRecalculateChunks();

                          }}
                          />
                      } */}
                    </div>
                  </div>
                }
                {
                  this.state.chunking_strategy === 'delimiter' &&
                  <div className="row">
                    
                    <div className="col-md-6">
                      <CustomField
                        name="chunk_delimiter"
                        label="Delimiter"
                        placeholder="A delimiter, such as a period, question mark, or new line"
                        description="For new lines, use the Enter key to create a new line in this field."
                        rows={2}
                        value={this.state.chunk_delimiter}
                        disabled={this.state.use_page_break_delimiter}
                        onChange={e => {
                          this.setState({
                            chunk_delimiter: e.value,
                            use_page_break_delimiter: false
                          })
                          this.debouncedRecalculateChunks();
                        }}
                        />
                      
                      <Checkbox
                        style="align-left"
                        description="Use page breaks and file separations to split the text."
                        disabled={this.state.uploadMode !== "files"}
                        value={this.state.use_page_break_delimiter}
                        onToggle={e => {
                          this.setState({
                            use_page_break_delimiter: e
                          })
                          this.debouncedRecalculateChunks();
                        }}
                        />
                    </div>
                    <div className="col-md-6">
                      <CustomField
                        name="chunk_overlap"
                        label="Chunk Overlap"
                        placeholder="50"
                        min={0}
                        max={parseInt(this.state.chunk_size / 2)}
                        type="range"
                        value={this.state.chunk_overlap}
                        onChange={e => {
                          this.setState({
                            chunk_overlap: e.value
                          })
                          this.debouncedRecalculateChunks();
                        }}
                        />
                      
                      <Checkbox
                        style="align-left"
                        description={<span
                          onMouseEnter={e => {
                            dispatch(showTooltip({
                              el: e.target,
                              nobr: false,
                              position: 'bottom',
                              content: <div style={{maxWidth: 250}} className="text-400">
                                <span className="text-900 no-margin">What does this do?</span>
                                <p className="thin-line-height">
                                  <small>Toggles whether the text of the overlapped content (shown in grey below) is included in the embedded content of the chunk.</small>
                                </p>
                                <p className="thin-line-height">
                                  <small>
                                    If left off, the overlapped text will not be used when searching for this chunk, but will be made available to the agent for augmenting its generation.
                                  </small>
                                </p>
                                <p className="thin-line-height no-margin-bottom">
                                  <small>
                                    If turned on, the overlapped text will be included in the embedded content, and will impact how each chunk is retrieved in the search step.
                                  </small>
                                </p>
                              </div>
                            }))
                          }}
                          onMouseLeave={e => {
                            dispatch(hideTooltip())
                          }}
                          >
                            Include overlap in the embedded content.<i className='far fa-question-circle text-muted icon-after-text'/>
                        </span>}
                        value={this.state.embed_overlap}
                        onToggle={e => {
                          this.setState({
                            embed_overlap: e
                          })
                        }}
                        />
                      {/* <Checkbox
                        style="align-left"
                        disabled={this.state.info_source_files.length === 1}
                        description="Overlap chunks between uploaded files."
                        value={this.state.overlapBetweenFiles}
                        onToggle={e => {
                          this.setState({
                            overlapBetweenFiles: e
                          })
                          this.debouncedRecalculateChunks();
                        }}
                        /> */}
                    </div>
                    
                    
                  </div>
                }
                <hr/>
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="no-margin">These settings will create {this.state.chunks.length} chunk{this.state.chunks.length !== 1 && "s"}.</h5>
                    <p>
                      Below is a preview of a few chunks that will be created based on the settings you've chosen.
                    </p>
                  </div>
                  <div className="col-md-12">
                    {
                      this.state.chunks.map((chunk, i) => {
                        // show the first 4 and last 1 chunk, with a single ... in the middle
                        if(this.state.chunks.length > 5){
                          if(i >= 4 && i < this.state.chunks.length - 1){
                            if(i === 4){
                              return <div className="box box-no-shadow box-transparent margin-bottom-1rem" key={i}>
                                <p className="no-margin">
                                  <small><i>{(this.state.chunks.length - 5).toLocaleString()} chunks not shown...</i></small>
                                </p>
                              </div>
                            }
                            return null;
                          }
                        }

                        return <div className="box box-grey-border margin-bottom-1rem" key={i}>
                          <div className="margin-bottom-1rem">
                            <small className="text-900 text-knowledge text-uppercase">{chunk.tokens.length} tokens{this.state.chunk_overlap > 0 && <span>, {chunk.total_tokens} including overlaps.</span>}</small>
                          </div>

                          <pre className="thin-line-height readable-pre">
                            <small className="text-muted">{chunk.start_overlap}</small>
                            <small>{chunk.content}</small>
                            <small className="text-muted">{chunk.end_overlap}</small>
                          </pre>
                        </div>
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          }

          {
            steps[this.state.chunking_step].title === "Metadata" &&
            <div className="col-md-9">
              {
                this.state.uploadMode === "files" ?
                <div className="">

                  {
                    this.state.info_source_files.map((file, i) => {
                      return <div className="box margin-bottom-1rem" key={i}>
                        <div className="flex-split">
                          <div>
                            <small>Setting Metadata for:</small>
                            <h4 className="no-margin">{file.original_name}</h4>
                          </div>
                        </div>
                        <CustomField
                          label="Source Name"
                          placeholder="Name of the source of this information"
                          maxLength={256}
                          description={<span>This name will be associated with {this.state.chunks.filter(c => c.file === file.name).length} chunks.</span>}
                          value={file.name}
                          onChange={e => {
                            let files = this.state.info_source_files;
                            files[i].name = e.value;
                            this.setState({
                              info_source_files: files
                            })
                          }}
                          />
                        <div>
                          <Checkbox
                            style="align-left"
                            description="Share this file name with the agent."
                            value={file.share_with_agent}
                            onToggle={e => {
                              let files = this.state.info_source_files;
                              files[i].share_with_agent = e;
                              this.setState({
                                info_source_files: files
                              })
                            }}
                            />
                          <Checkbox
                            style="align-left"
                            description={<span
                              onMouseEnter={e => {
                                dispatch(showTooltip({
                                  el: e.target,
                                  nobr: false,
                                  position: 'bottom',
                                  content: <div style={{maxWidth: 250}} className="text-400">
                                    <span className="text-900 no-margin">What does this do?</span>
                                    <p className="thin-line-height">
                                      <small>Controls whether the original file's full text content is uploaded and stored for reference, updating, and easy re-chunking.</small>
                                    </p>
                                    <p className="thin-line-height no-margin-bottom">
                                      <small>
                                        By enabling this option, the original file's contents will be uploaded and stored and tagged with the chunking strategy you've chosen. This will allow you to easily replace the file and have your chosen chunking strategy apply to the new contents.
                                      </small>
                                    </p>
                                  </div>
                                }));
                              }}
                              onMouseLeave={e => {
                                dispatch(hideTooltip())
                              }}
                              
                              >Upload original file and its full text content for reference, updating, and easy re-chunking.<i className="far fa-question-circle text-muted icon-after-text"/></span>}
                            value={file.upload_original}
                            onToggle={e => {
                              let files = this.state.info_source_files;
                              files[i].upload_original = e;
                              this.setState({
                                info_source_files: files
                              })
                            }}
                            />
                        </div>
                      </div>
                    })
                  }

                </div>
                :
                <div className="box">
                  <div className="flex-split">
                    <div>
                      <small>Setting Metadata for:</small>
                      <h5 className="no-margin">Raw Text</h5>
                    </div>
                  </div>
                  <CustomField
                    label="Source Name"
                    placeholder="Name of the source of this information"
                    value={this.state.info_source_name}
                    onChange={e => {
                      this.setState({
                        info_source_name: e.value
                      })
                    }}
                    />
                  <div>
                    <p>
                      This name will be associated with {this.state.chunks.length} chunks.
                    </p>
                  </div>
                </div>
              }
            </div>
          }

          {
            steps[this.state.chunking_step].title === "Review" &&
            <div className="col-md-9">

              {
                this.state.review_view === "gallery" ?
                <div>
                  <div className="flex-split margin-bottom-1rem">
                    <h4 className="no-margin">All Chunks</h4>
                    <div className="list-right">
                      <small className="margin-right-1rem">
                        {this.state.allChunksStartPage + 1} - {Math.min(this.state.allChunksStartPage + this.state.allChunksPageSize, sortedChunks.length)} of {sortedChunks.length}
                      </small>
                      <CustomSelect
                        minWidth={300}
                        inline={true}
                        value={this.state.review_gallery_sort}
                        options={[
                          {value: "source", label: "Sort by Source"},
                          {value: "tokens_low", label: "Sort by Tokens: Low to High"},
                          {value: "tokens_high", label: "Sort by Tokens: Low to High"},
                        ]}
                        onChange={e => {
                          this.setState({
                            review_gallery_sort: e
                          })
                        }}
                        />
                      <CustomButton
                        size="small"
                        color="grey"
                        display={<span><i className="far fa-angle-left icon-before-text"/>Pref</span>}
                        disabled={this.state.allChunksStartPage === 0}
                        onClick={e => {
                          this.setState({
                            allChunksStartPage: this.state.allChunksStartPage - 1
                          })
                        }}
                        />
                      <CustomButton
                        size="small"
                        color="grey"
                        display={<span>Next<i className="far fa-angle-right icon-after-text"/></span>}
                        disabled={this.state.allChunksStartPage  * this.state.allChunksPageSize + this.state.allChunksPageSize >= sortedChunks.length}
                        onClick={e => {
                          this.setState({
                            allChunksStartPage: this.state.allChunksStartPage + 1
                          })
                        }}
                        />
                    </div>
                  </div>
                  <div className="row row-eq-height gx-3 gy-3">
                    {
                      sortedChunks.slice(this.state.allChunksStartPage * this.state.allChunksPageSize, this.state.allChunksStartPage * this.state.allChunksPageSize + this.state.allChunksPageSize).map((chunk, i) => {
                        return <div className="col-md-3" key={i}>
                          <div className="box box-half-pad box-grey-border box-square scroll-parent box-clickable"
                            onClick={e => {
                              // find original index of this chunk in the unsorted list of chunks by id
                              let originalIndex = this.state.chunks.findIndex(c => c.id === chunk.id);

                              this.setState({
                                review_view: "chunk",
                                review_chunk: originalIndex
                              })
                            }}
                            >
                            <div className="thin-line-height text-super-small scroll-child padding-05rem">
                              <span className="text-muted">{chunk.start_overlap}</span>
                              <span>{chunk.content}</span>
                              <span className="text-muted">{chunk.end_overlap}</span>
                            </div>
                          </div>
                        </div>
                      })
                    }
                  </div>
                </div>
                :
                <div>
                  <div className="flex-split margin-bottom-1rem">
                    <div className="list-left">
                      <CustomButton
                        size="small"
                        color="grey"
                        display={<span>View All</span>}
                        onClick={e => {
                          this.setState({
                            review_view: "gallery",
                            review_edit_mode: false
                          })
                        }}
                        />
                      <span className="">Chunk {this.state.review_chunk + 1} of {this.state.chunks.length}</span>
                    </div>
                    <div className="list-right">
                      
                      <CustomButton
                        size="small"
                        color="grey"
                        display={<span><i className="far fa-angle-left icon-before-text"/>Prev</span>}
                        disabled={this.state.review_chunk === 0}
                        onClick={e => {
                          this.setState({
                            review_chunk: this.state.review_chunk - 1,
                            review_edit_mode: false
                          })
                        }}
                        />
                      <CustomButton
                        size="small"
                        color="grey"
                        display={<span>Next<i className="far fa-angle-right icon-after-text"/></span>}
                        disabled={this.state.review_chunk === this.state.chunks.length - 1}
                        onClick={e => {
                          this.setState({
                            review_chunk: this.state.review_chunk + 1,
                            review_edit_mode: false
                          })
                        }}
                        />
                    </div>
                  </div>

                  <div className="box box-grey-border">
                    <div className="margin-bottom-1rem">
                      <div className="flex-split margin-bottom-1rem">
                      <div className="margin-bottom-1rem">
                        <small className="text-900 text-knowledge text-uppercase">{this.state.chunks[this.state.review_chunk].tokens.length} tokens{this.state.chunk_overlap > 0 && <span>, {this.state.chunks[this.state.review_chunk].total_tokens} including overlaps.</span>}</small>
                      </div>
                      <div className="list-right">
                        <CustomButton
                          size="icon"
                          color="black"
                          disabled={this.state.review_edit_mode}
                          display={<span><i className="far fa-pencil"/></span>}
                          onClick={e => {

                            // create an edited copy of the chunk text
                            let chunks = this.state.chunks;
                            chunks[this.state.review_chunk].draftContent = chunks[this.state.review_chunk].content;
                            chunks[this.state.review_chunk].draftStartOverlap = chunks[this.state.review_chunk].start_overlap;
                            chunks[this.state.review_chunk].draftEndOverlap = chunks[this.state.review_chunk].end_overlap;

                            this.setState({
                              review_edit_mode: true,
                              chunks: chunks
                            })
                          }}
                          />
                        <CustomButton
                          size="small"
                          color="danger"
                          display={<span><i className="far fa-trash-alt"/></span>}
                          onClick={e => {
                            this.setState({
                              showDeleteChunkModal: true  
                            })
                          }}
                          />
                      </div>
                    </div>
                    {
                      this.state.review_edit_mode ?
                      <div>
                        {
                          this.state.chunk_overlap > 0 &&
                          <CustomField
                            name="start_overlap"
                            label="Start Overlap"
                            placeholder="Start Overlap"
                            value={this.state.chunks[this.state.review_chunk].draftStartOverlap}
                            rows={7}
                            onChange={e => {
                              let chunks = this.state.chunks;
                              chunks[this.state.review_chunk].draftStartOverlap = e.value;
                              this.setState({
                                chunks: chunks
                              })
                            }}
                            />
                        }
                        <CustomField
                          name="content"
                          label="Chunk Content"
                          placeholder="Enter the text you'd like to add to this Knowledge Base here..."
                          description={<span>{encode(this.state.chunks[this.state.review_chunk].draftContent).length} tokens</span>}
                          value={this.state.chunks[this.state.review_chunk].draftContent}
                          rows={15}
                          onChange={e => {
                            let chunks = this.state.chunks;
                            chunks[this.state.review_chunk].draftContent = e.value;
                            this.setState({
                              chunks: chunks
                            })
                          }}
                          />
                        {
                          this.state.chunk_overlap > 0 &&
                          <CustomField
                            name="end_overlap"
                            label="End Overlap"
                            placeholder="End Overlap"
                            rows={7}
                            value={this.state.chunks[this.state.review_chunk].draftEndOverlap}
                            onChange={e => {
                              let chunks = this.state.chunks;
                              chunks[this.state.review_chunk].draftEndOverlap = e.value;
                              this.setState({
                                chunks: chunks
                              })
                            }}
                            />
                        }
                        <div className="list-right margin-top-1rem no-margin-bottom">
                          <CustomButton
                            size="small"
                            color="grey"
                            display={<span>Cancel</span>}
                            onClick={e => {
                              let chunks = this.state.chunks;
                              chunks[this.state.review_chunk].draftContent = chunks[this.state.review_chunk].content;

                              this.setState({
                                review_edit_mode: false,
                                chunks: chunks
                              })
                            }}
                            />
                          <CustomButton
                            size="small"
                            color="success"
                            display={<span>Save Changes</span>}
                            onClick={e => {
                              let chunks = this.state.chunks;
                              chunks[this.state.review_chunk].content = chunks[this.state.review_chunk].draftContent;
                              chunks[this.state.review_chunk].start_overlap = chunks[this.state.review_chunk].draftStartOverlap;
                              chunks[this.state.review_chunk].end_overlap = chunks[this.state.review_chunk].draftEndOverlap;

                              // re tokenize the chunk
                              chunks[this.state.review_chunk].tokens = encode(chunks[this.state.review_chunk].content);
                              chunks[this.state.review_chunk].start_overlap_tokens = encode(chunks[this.state.review_chunk].start_overlap);
                              chunks[this.state.review_chunk].end_overlap_tokens = encode(chunks[this.state.review_chunk].end_overlap);
                              chunks[this.state.review_chunk].total_tokens = chunks[this.state.review_chunk].tokens.length + chunks[this.state.review_chunk].start_overlap_tokens.length + chunks[this.state.review_chunk].end_overlap_tokens.length;

                              this.setState({
                                review_edit_mode: false,
                                chunks: chunks
                              })
                            }}
                            />
                        </div>
                      </div>
                      :                     
                      <pre className="readable-pre bg-gs10">
                        <span className="text-muted">{this.state.chunks[this.state.review_chunk].start_overlap}</span>
                        <span>{this.state.chunks[this.state.review_chunk].content}</span>
                        <span className="text-muted">{this.state.chunks[this.state.review_chunk].end_overlap}</span>
                      </pre>
                    }
                    </div>
                    
                  </div>
                </div>
              }
            </div>
          }
          {
            steps[this.state.chunking_step].title === "Create" && 
            <div className="col-md-9">
              {
                this.state.uploadMode === "files" &&
                <div>
                  {/* <div className="box margin-bottom-2rem">
                    <div className="flex-split margin-bottom-2rem">
                      <h5 className="no-margin">
                        {
                          this.state.multiFileCreating ?
                          <span>Creating files...</span>
                          :
                          <span>{this.state.info_source_files.length} file{this.state.info_source_files.length !== 1 && "s"} ready to be uploaded</span>
                        }
                      </h5>
                      <span>{parseInt(this.state.multiUploadSuccessCounter / this.state.chunks.length * 100)}% completed</span>
                    </div>
                    <div className="progress-bar-wrapper">
                      <div className="progress-bar-inner progress-bar-inner-knowledge" style={{width: parseInt(100 * this.state.multiUploadSuccessCounter / this.state.chunks.length) + '%'}}>
                      </div>
                    </div>
                    
                  </div> */}
            

                {
                  this.state.info_source_files.map((f, i) => {
                    return <div className={"box box-knowledge-left box-light-border margin-top-1rem "} key={i}>
                      <div className="flex-split">
                        {
                          f.type === "pdf" ?
                          <i className="fad fa-3x fa-file-pdf text-knowledge icon-before-text"/>
                          :
                          <i className="fad fa-3x fa-file-alt text-knowledge icon-before-text"/>
                        }
                      
                        <div className="flex-grow margin-left-1rem">
                          <h5 className="no-margin">{f.name}</h5>
                          <span>{this.state.chunks.filter(c => c.file === f.name).length} chunks</span>
                        </div>
                      
                        {
                          f.error &&
                          <div className="list-right">
                            <CustomButton
                              size="small"
                              color="black"
                              display={<span>Retry</span>}
                              onClick={e => {
                                this.createFile(f, i + "");
                              }}
                              />
                          </div>
                        }
                        {
                          f.success &&
                          <div className="list-right">
                            <i className="fas fa-check-circle text-success fa-2x"/>
                          </div>
                        }
                        {
                          (!f.error && !f.success) && 
                          <span className="text-muted">
                            Queued
                          </span>
                        }
                      </div>
                    </div>
                  })
                }
                <hr/>
                </div>
              }
              
              <div className="box margin-bottom-1rem">
                <div className="flex-split margin-bottom-2rem">
                  <h5 className="no-margin">
                    {
                      this.state.tryingToCreateInformation ?
                      "Creating and embedding chunks..."
                      :
                      (this.state.multiUploadSuccessCounter > 0 || this.state.multiUploadErrorCounter > 0) ?
                      <span>
                        {this.state.multiUploadSuccessCounter} chunk{this.state.multiUploadSuccessCounter !== 1 && "s"} successfully added with {this.state.multiUploadErrorCounter} error{this.state.multiUploadErrorCounter !== 1 && "s"}
                      </span>
                      :
                      <span>{this.state.chunks.length} chunk{this.state.chunks.length !== 1 && "s"} ready to be created</span>
                    }
                  </h5>
                  <span>{parseInt(this.state.multiUploadSuccessCounter / this.state.chunks.length * 100)}% completed</span>
                </div>
                <div className="progress-bar-wrapper">
                  <div className="progress-bar-inner progress-bar-inner-knowledge" style={{width: parseInt(100 * this.state.multiUploadSuccessCounter / this.state.chunks.length) + '%'}}>
                  </div>
                </div>
                
              </div>
              
              {
                this.state.chunks.filter(chunk => chunk.error).map((chunk, i) => {
                  return <div className="box box-danger margin-top-1rem" key={i}>
                    <div className="flex-split">
                      <div>
                        <h5 className="no-margin text-danger">Chunk failed to upload:</h5>
                        <small>{chunk.error.message}</small>
                      </div>
                    </div>
                    <pre className="readable-pre bg-gs10 thin-line-height">
                      <small className="text-muted">{chunk.start_overlap}</small>
                      <small>{chunk.content}</small>
                      <small className="text-muted">{chunk.end_overlap}</small>
                    </pre>
                  </div>
                })
              }
            </div>
          }
        </div>    
      </div>
    

  }
}


const mapStateToProps = (state) => {
  const { userReducer, knowledgeReducer, sharedReducer, orgReducer } = state;

  return {
    userReducer, 
    knowledgeReducer,
    sharedReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(KnowledgeInfo);

  