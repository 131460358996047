const flow_templates = [
  {
    name: 'blank',
    display_name: 'Blank',
    description: 'Start from scratch with a blank canvas.',
    flow_links: [],
    flow_nodes: []
  },
  {
    name: 'vanilla',
    display_name: 'Vanilla LLM',
    description: 'Essentially a pass-through use of an LLM.',
    flow_links: [
      {
          "from": {
              "node_id": "9Bv5DkI4o",
              "output": "content"
          },
          "to": {
              "node_id": "d6Iu1RCXY",
              "input": "input"
          }
      },
      {
          "from": {
              "node_id": "3RkDHdFxN",
              "output": "request_messages"
          },
          "to": {
              "node_id": "9Bv5DkI4o",
              "input": "messages"
          }
      }
    ],
    flow_nodes: [
      {
          "id": "3RkDHdFxN",
          "type": "request",
          "x": -426.9209569818007,
          "y": 274.8884696960449,
          "settings": {}
      },
      {
          "id": "d6Iu1RCXY",
          "type": "response",
          "x": 169.93377842823213,
          "y": 274.8884696960449,
          "settings": {}
      },
      {
          "id": "9Bv5DkI4o",
          "type": "openai_chat",
          "x": -128.4935892767843,
          "y": 274.8884696960449,
          "settings": {}
      }
  ]
  },
  {
    name: 'contextual_chat',
    display_name: 'Contextual Chat',
    description: 'Create a chatbot that can be connected to the context of its conversation.',
    flow_links: [
      {
          "from": {
              "node_id": "9Bv5DkI4o",
              "output": "content"
          },
          "to": {
              "node_id": "d6Iu1RCXY",
              "input": "input"
          }
      },
      {
          "from": {
              "node_id": "PWM3nOONu",
              "output": "text"
          },
          "to": {
              "node_id": "wfENty5He",
              "input": "text"
          }
      },
      {
          "from": {
              "node_id": "3RkDHdFxN",
              "output": "request_variables"
          },
          "to": {
              "node_id": "wfENty5He",
              "input": "variables"
          }
      },
      {
          "from": {
              "node_id": "ygc77cwJF",
              "output": "messages"
          },
          "to": {
              "node_id": "9Bv5DkI4o",
              "input": "messages"
          }
      },
      {
          "from": {
              "node_id": "wfENty5He",
              "output": "text"
          },
          "to": {
              "node_id": "jE7VT-6kL",
              "input": "text"
          }
      },
      {
          "from": {
              "node_id": "jE7VT-6kL",
              "output": "prompt"
          },
          "to": {
              "node_id": "ygc77cwJF",
              "input": "m1"
          }
      },
      {
          "from": {
              "node_id": "3RkDHdFxN",
              "output": "request_messages"
          },
          "to": {
              "node_id": "ygc77cwJF",
              "input": "m8"
          }
      }
  ],
    flow_nodes: [
      {
          "id": "3RkDHdFxN",
          "type": "request",
          "x": -545.8089381612884,
          "y": 256.0822563171387,
          "settings": {}
      },
      {
          "id": "d6Iu1RCXY",
          "type": "response",
          "x": 684.0216995708103,
          "y": 163.16253280639648,
          "settings": {}
      },
      {
          "id": "9Bv5DkI4o",
          "type": "openai_chat",
          "x": 392.7223521523539,
          "y": 163.16253280639648,
          "settings": {}
      },
      {
          "id": "wfENty5He",
          "type": "text_replacer",
          "x": -184.02646028158028,
          "y": -116.07334778312597,
          "settings": {}
      },
      {
          "id": "PWM3nOONu",
          "type": "text",
          "x": -545.8089381612884,
          "y": -116.07334778312597,
          "settings": {
              "text": "You are a helpful assistant, currently speaking with ${NAME}"
          }
      },
      {
          "id": "ygc77cwJF",
          "type": "messages",
          "x": 392.7223521523539,
          "y": -116.07334778312597,
          "settings": {}
      },
      {
          "id": "jE7VT-6kL",
          "type": "prompt_role_setter",
          "x": 104.3479459353868,
          "y": -116.07334778312597,
          "settings": {}
      }
    ]
  },
  {
    name: 'Few-shot RAG',
    display_name: 'Few-shot RAG',
    description: 'Use a few-shot RAG to quickly generate responses to prompts powered by your knowledge.',
    flow_links: [
      {
          "from": {
              "node_id": "9Bv5DkI4o",
              "output": "content"
          },
          "to": {
              "node_id": "d6Iu1RCXY",
              "input": "input"
          }
      },
      {
          "from": {
              "node_id": "PWM3nOONu",
              "output": "text"
          },
          "to": {
              "node_id": "wfENty5He",
              "input": "text"
          }
      },
      {
          "from": {
              "node_id": "ygc77cwJF",
              "output": "messages"
          },
          "to": {
              "node_id": "9Bv5DkI4o",
              "input": "messages"
          }
      },
      {
          "from": {
              "node_id": "wfENty5He",
              "output": "text"
          },
          "to": {
              "node_id": "jE7VT-6kL",
              "input": "text"
          }
      },
      {
          "from": {
              "node_id": "jE7VT-6kL",
              "output": "prompt"
          },
          "to": {
              "node_id": "ygc77cwJF",
              "input": "m1"
          }
      },
      {
          "from": {
              "node_id": "3RkDHdFxN",
              "output": "request_messages"
          },
          "to": {
              "node_id": "T-WqUuS3D",
              "input": "messages"
          }
      },
      {
          "from": {
              "node_id": "b6T-N6fJK",
              "output": "value"
          },
          "to": {
              "node_id": "T-WqUuS3D",
              "input": "most_recent"
          }
      },
      {
          "from": {
              "node_id": "T-WqUuS3D",
              "output": "content"
          },
          "to": {
              "node_id": "lzWm056Ga",
              "input": "text"
          }
      },
      {
          "from": {
              "node_id": "d9jbJsDwY",
              "output": "value"
          },
          "to": {
              "node_id": "lzWm056Ga",
              "input": "chunks_to_return"
          }
      },
      {
          "from": {
              "node_id": "gVec43pNI",
              "output": "value"
          },
          "to": {
              "node_id": "lzWm056Ga",
              "input": "embedding_similarity_threshold"
          }
      },
      {
          "from": {
              "node_id": "lzWm056Ga",
              "output": "chunks"
          },
          "to": {
              "node_id": "L2oe2BE7X",
              "input": "value"
          }
      },
      {
          "from": {
              "node_id": "L2oe2BE7X",
              "output": "object"
          },
          "to": {
              "node_id": "wfENty5He",
              "input": "variables"
          }
      },
      {
          "from": {
              "node_id": "3RkDHdFxN",
              "output": "request_messages"
          },
          "to": {
              "node_id": "ygc77cwJF",
              "input": "m8"
          }
      }
  ],
    flow_nodes: [
      {
          "id": "3RkDHdFxN",
          "type": "request",
          "x": -558.0375758566009,
          "y": 254.7235221862793,
          "settings": {}
      },
      {
          "id": "d6Iu1RCXY",
          "type": "response",
          "x": 990.5603987208958,
          "y": 310.814640045166,
          "settings": {}
      },
      {
          "id": "9Bv5DkI4o",
          "type": "openai_chat",
          "x": 702.4708291344706,
          "y": 310.814640045166,
          "settings": {}
      },
      {
          "id": "wfENty5He",
          "type": "text_replacer",
          "x": 64.73532236459903,
          "y": 44.41808440681544,
          "settings": {}
      },
      {
          "id": "PWM3nOONu",
          "type": "text",
          "x": -229.40479793943814,
          "y": -170.64043304923925,
          "settings": {
              "text": "Here is some information that can help you answer the user's query:\n\n${INFO}"
          }
      },
      {
          "id": "ygc77cwJF",
          "type": "messages",
          "x": 702.4708291344706,
          "y": 44.41808440681544,
          "settings": {}
      },
      {
          "id": "jE7VT-6kL",
          "type": "prompt_role_setter",
          "x": 351.51282585644867,
          "y": 44.41808440681544,
          "settings": {}
      },
      {
          "id": "lzWm056Ga",
          "type": "semantic_search",
          "x": 75.80968722901889,
          "y": 458.3306057239573,
          "settings": {}
      },
      {
          "id": "T-WqUuS3D",
          "type": "extract_recent_message_content",
          "x": -229.40479793943814,
          "y": 388.3150722766917,
          "settings": {}
      },
      {
          "id": "b6T-N6fJK",
          "type": "slider",
          "x": -558.0375758566009,
          "y": 388.3150722766917,
          "settings": {
              "value": 1
          }
      },
      {
          "id": "d9jbJsDwY",
          "type": "slider",
          "x": -558.0375758566009,
          "y": 530.6680919910473,
          "settings": {
              "value": 5
          }
      },
      {
          "id": "gVec43pNI",
          "type": "slider",
          "x": -558.0375758566009,
          "y": 673.0211117054029,
          "settings": {
              "value": 0.35
          }
      },
      {
          "id": "L2oe2BE7X",
          "type": "key_value_maker",
          "x": 351.51282585644867,
          "y": 458.3306057239573,
          "settings": {
              "key": "INFO"
          }
      }
    ]
  },
  {
    name: 'Functional RAG',
    display_name: 'Functional RAG',
    description: 'Enable an LLM to decide when to use a RAG to generate responses to prompts.',
    flow_links: [
      {
          "from": {
              "node_id": "Tf-z9pyq6",
              "output": "request_messages"
          },
          "to": {
              "node_id": "pGKVsI637",
              "input": "m2"
          }
      },
      {
          "from": {
              "node_id": "pGKVsI637",
              "output": "messages"
          },
          "to": {
              "node_id": "J5u_AHyV3",
              "input": "messages"
          }
      },
      {
          "from": {
              "node_id": "d7r1RwAs7",
              "output": "text"
          },
          "to": {
              "node_id": "kT62A5tq2",
              "input": "jsonString"
          }
      },
      {
          "from": {
              "node_id": "kT62A5tq2",
              "output": "object"
          },
          "to": {
              "node_id": "-_eDZpLC_",
              "input": "object"
          }
      },
      {
          "from": {
              "node_id": "d4XC7mqJJ",
              "output": "text"
          },
          "to": {
              "node_id": "mzBteT-y4",
              "input": "name"
          }
      },
      {
          "from": {
              "node_id": "wgFJf6BSS",
              "output": "text"
          },
          "to": {
              "node_id": "mzBteT-y4",
              "input": "description"
          }
      },
      {
          "from": {
              "node_id": "-_eDZpLC_",
              "output": "jsonSchema"
          },
          "to": {
              "node_id": "mzBteT-y4",
              "input": "parameters"
          }
      },
      {
          "from": {
              "node_id": "mzBteT-y4",
              "output": "function_declaration"
          },
          "to": {
              "node_id": "J5u_AHyV3",
              "input": "tools"
          }
      },
      {
          "from": {
              "node_id": "mzBteT-y4",
              "output": "function_declaration"
          },
          "to": {
              "node_id": "89UlHKJsf",
              "input": "toolDeclaration"
          }
      },
      {
          "from": {
              "node_id": "J5u_AHyV3",
              "output": "message"
          },
          "to": {
              "node_id": "89UlHKJsf",
              "input": "message"
          }
      },
      {
          "from": {
              "node_id": "PajIbIo5J",
              "output": "value"
          },
          "to": {
              "node_id": "J1hxLpRJ0",
              "input": "text"
          }
      },
      {
          "from": {
              "node_id": "89UlHKJsf",
              "output": "tool_call_id"
          },
          "to": {
              "node_id": "V3T4dEtRB",
              "input": "toolCallId"
          }
      },
      {
          "from": {
              "node_id": "J1hxLpRJ0",
              "output": "text"
          },
          "to": {
              "node_id": "V3T4dEtRB",
              "input": "content"
          }
      },
      {
          "from": {
              "node_id": "J5u_AHyV3",
              "output": "message"
          },
          "to": {
              "node_id": "2brKSzlbI",
              "input": "m1"
          }
      },
      {
          "from": {
              "node_id": "V3T4dEtRB",
              "output": "toolResponse"
          },
          "to": {
              "node_id": "2brKSzlbI",
              "input": "m2"
          }
      },
      {
          "from": {
              "node_id": "89UlHKJsf",
              "output": "match"
          },
          "to": {
              "node_id": "QVmU8v4ev",
              "input": "condition"
          }
      },
      {
          "from": {
              "node_id": "2brKSzlbI",
              "output": "messages"
          },
          "to": {
              "node_id": "QVmU8v4ev",
              "input": "data"
          }
      },
      {
          "from": {
              "node_id": "QVmU8v4ev",
              "output": "output"
          },
          "to": {
              "node_id": "pGKVsI637",
              "input": "m7"
          }
      },
      {
          "from": {
              "node_id": "89UlHKJsf",
              "output": "match"
          },
          "to": {
              "node_id": "KwG7Zp_Ao",
              "input": "input"
          }
      },
      {
          "from": {
              "node_id": "KwG7Zp_Ao",
              "output": "output"
          },
          "to": {
              "node_id": "xgl-0BMqD",
              "input": "condition"
          }
      },
      {
          "from": {
              "node_id": "xgl-0BMqD",
              "output": "output"
          },
          "to": {
              "node_id": "peEgi3-fE",
              "input": "input"
          }
      },
      {
          "from": {
              "node_id": "J5u_AHyV3",
              "output": "content"
          },
          "to": {
              "node_id": "xgl-0BMqD",
              "input": "data"
          }
      },
      {
          "from": {
              "node_id": "2MxQSI-0D",
              "output": "text"
          },
          "to": {
              "node_id": "uGPzpxQme",
              "input": "data"
          }
      },
      {
          "from": {
              "node_id": "89UlHKJsf",
              "output": "match"
          },
          "to": {
              "node_id": "uGPzpxQme",
              "input": "condition"
          }
      },
      {
          "from": {
              "node_id": "uGPzpxQme",
              "output": "output"
          },
          "to": {
              "node_id": "PajIbIo5J",
              "input": "key"
          }
      },
      {
          "from": {
              "node_id": "89UlHKJsf",
              "output": "arguments"
          },
          "to": {
              "node_id": "PajIbIo5J",
              "input": "object"
          }
      },
      {
          "from": {
              "node_id": "X3VCdZvY8",
              "output": "prompt"
          },
          "to": {
              "node_id": "pGKVsI637",
              "input": "m1"
          }
      },
      {
          "from": {
              "node_id": "89UlHKJsf",
              "output": "match"
          },
          "to": {
              "node_id": "oZR3rFvlw",
              "input": "value"
          }
      },
      {
          "from": {
              "node_id": "oZR3rFvlw",
              "output": "object"
          },
          "to": {
              "node_id": "peEgi3-fE",
              "input": "metadata"
          }
      }
  ],
    flow_nodes: [
      {
          "id": "peEgi3-fE",
          "type": "response",
          "x": 2336.080265013721,
          "y": -420.5969299189697,
          "settings": {}
      },
      {
          "id": "Tf-z9pyq6",
          "type": "request",
          "x": -395,
          "y": -276.2246563561269,
          "settings": {}
      },
      {
          "id": "J5u_AHyV3",
          "type": "openai_chat",
          "x": 666.9410416553303,
          "y": -215.33513433098133,
          "settings": {
              "model": "gpt-4o",
              "parameters": {
                  "top_p": 1,
                  "response_format": false,
                  "frequency_penalty": 0,
                  "presence_penalty": 0,
                  "temperature": 0.8
              }
          }
      },
      {
          "id": "pGKVsI637",
          "type": "messages",
          "x": 84.00600218381797,
          "y": -427.6747818591487,
          "settings": {}
      },
      {
          "id": "d4XC7mqJJ",
          "type": "text",
          "x": -395,
          "y": 115.04853288111545,
          "settings": {
              "text": "searchKnowledge"
          }
      },
      {
          "id": "wgFJf6BSS",
          "type": "text",
          "x": -395,
          "y": 426.04031613989287,
          "settings": {
              "text": "TODO: Describe the when and why the LLM should choose to query this Knowledge Base"
          }
      },
      {
          "id": "d7r1RwAs7",
          "type": "text",
          "x": -395,
          "y": 725.637171477812,
          "settings": {
              "text": "{ \"search_text\": \"test\"}"
          }
      },
      {
          "id": "kT62A5tq2",
          "type": "json_parse",
          "x": -88.02474916615618,
          "y": 923.420942916491,
          "settings": {}
      },
      {
          "id": "-_eDZpLC_",
          "type": "json_schema_generator",
          "x": 215.43035112941016,
          "y": 923.420942916491,
          "settings": {}
      },
      {
          "id": "mzBteT-y4",
          "type": "tool_function",
          "x": 215.43035112941016,
          "y": 373.7264005286,
          "settings": {}
      },
      {
          "id": "89UlHKJsf",
          "type": "was_tool_called",
          "x": 666.9410416553303,
          "y": 373.7264005286,
          "settings": {}
      },
      {
          "id": "X3VCdZvY8",
          "type": "prompt",
          "x": -395,
          "y": -649.0218341389162,
          "settings": {
              "role": "system",
              "text": "TODO: Instruct the LLM to leverage the knowledge base if needed to answer the user's questions."
          }
      },
      {
          "id": "PajIbIo5J",
          "type": "extract_value",
          "x": 1337.6488245704968,
          "y": 789.0930187185857,
          "settings": {}
      },
      {
          "id": "2MxQSI-0D",
          "type": "text",
          "x": 666.9410416553303,
          "y": 717.9177095960526,
          "settings": {
              "text": "search_text"
          }
      },
      {
          "id": "J1hxLpRJ0",
          "type": "semantic_search",
          "x": 1675.2276421813026,
          "y": 789.0930187185857,
          "settings": {
          }
      },
      {
          "id": "V3T4dEtRB",
          "type": "tool_response",
          "x": 2013.079979091272,
          "y": 685.2120938537685,
          "settings": {}
      },
      {
          "id": "2brKSzlbI",
          "type": "messages",
          "x": 2368.7555951593176,
          "y": 619.2120938537685,
          "settings": {}
      },
      {
          "id": "QVmU8v4ev",
          "type": "gate",
          "x": 2803.8179564760485,
          "y": 473.51082562466456,
          "settings": {}
      },
      {
          "id": "xgl-0BMqD",
          "type": "gate",
          "x": 1877.4383408608783,
          "y": -420.5969299189697,
          "settings": {}
      },
      {
          "id": "KwG7Zp_Ao",
          "type": "boolean_inverter",
          "x": 1497.4443360835305,
          "y": -420.5969299189697,
          "settings": {}
      },
      {
          "id": "uGPzpxQme",
          "type": "gate",
          "x": 1017.850108404669,
          "y": 895.6412824475678,
          "settings": {}
      },
      {
          "id": "oZR3rFvlw",
          "type": "key_value_maker",
          "x": 1877.4383408608783,
          "y": -293.529138490924,
          "settings": {
              "key": "did_tool_get_used"
          }
      }
  ]
  },
  {
    name: "Token-capped Chat",
    display_name: "Token-capped Chat",
    description: "Limit the number of tokens in a user's message to prevent abuse.",
    flow_links: [
      {
          "from": {
              "node_id": "gjrqNgIL4",
              "output": "request_messages"
          },
          "to": {
              "node_id": "982rktnQZ",
              "input": "messages"
          }
      },
      {
          "from": {
              "node_id": "VVdE8KT7f",
              "output": "value"
          },
          "to": {
              "node_id": "982rktnQZ",
              "input": "tokenLimit"
          }
      },
      {
          "from": {
              "node_id": "982rktnQZ",
              "output": "keptMessages"
          },
          "to": {
              "node_id": "Mkyy4ktSy",
              "input": "array"
          }
      },
      {
          "from": {
              "node_id": "Mkyy4ktSy",
              "output": "length"
          },
          "to": {
              "node_id": "h-peZ5Fah",
              "input": "value1"
          }
      },
      {
          "from": {
              "node_id": "j-hiBBPY-",
              "output": "value"
          },
          "to": {
              "node_id": "h-peZ5Fah",
              "input": "value2"
          }
      },
      {
          "from": {
              "node_id": "h-peZ5Fah",
              "output": "result"
          },
          "to": {
              "node_id": "PDz2E7RQG",
              "input": "input"
          }
      },
      {
          "from": {
              "node_id": "PDz2E7RQG",
              "output": "output"
          },
          "to": {
              "node_id": "5AtU6uLrJ",
              "input": "condition"
          }
      },
      {
          "from": {
              "node_id": "982rktnQZ",
              "output": "keptMessages"
          },
          "to": {
              "node_id": "5AtU6uLrJ",
              "input": "data"
          }
      },
      {
          "from": {
              "node_id": "5AtU6uLrJ",
              "output": "output"
          },
          "to": {
              "node_id": "rhRlPnqMG",
              "input": "messages"
          }
      },
      {
          "from": {
              "node_id": "h-peZ5Fah",
              "output": "result"
          },
          "to": {
              "node_id": "V4Gy5sJVk",
              "input": "condition"
          }
      },
      {
          "from": {
              "node_id": "V4Gy5sJVk",
              "output": "output"
          },
          "to": {
              "node_id": "Kf57coiqJ",
              "input": "input"
          }
      },
      {
          "from": {
              "node_id": "PzKY2TFxW",
              "output": "text"
          },
          "to": {
              "node_id": "V4Gy5sJVk",
              "input": "trueValue"
          }
      },
      {
          "from": {
              "node_id": "PDz2E7RQG",
              "output": "output"
          },
          "to": {
              "node_id": "y-NYWb4M6",
              "input": "condition"
          }
      },
      {
          "from": {
              "node_id": "rhRlPnqMG",
              "output": "content"
          },
          "to": {
              "node_id": "y-NYWb4M6",
              "input": "data"
          }
      },
      {
          "from": {
              "node_id": "y-NYWb4M6",
              "output": "output"
          },
          "to": {
              "node_id": "V4Gy5sJVk",
              "input": "falseValue"
          }
      }
  ],
    flow_nodes: [
      {
          "id": "gjrqNgIL4",
          "type": "request",
          "x": -828.5242042541504,
          "y": -267.4097013473511,
          "settings": {}
      },
      {
          "id": "982rktnQZ",
          "type": "context_cap",
          "x": -381.2602924741051,
          "y": -232.9563532197364,
          "settings": {}
      },
      {
          "id": "VVdE8KT7f",
          "type": "slider",
          "x": -828.5242042541504,
          "y": -131.22687176145647,
          "settings": {
              "min": 0,
              "max": 1000,
              "step": 10,
              "value": 500
          }
      },
      {
          "id": "rhRlPnqMG",
          "type": "openai_chat",
          "x": 1096.5940638470754,
          "y": -267.4097013473511,
          "settings": {
              "model": "gpt-4o",
              "parameters": {
                  "top_p": 1,
                  "response_format": false,
                  "frequency_penalty": 0,
                  "presence_penalty": 0,
                  "temperature": 0.8
              }
          }
      },
      {
          "id": "Kf57coiqJ",
          "type": "response",
          "x": 2110.010667733684,
          "y": 62.411208237488395,
          "settings": {}
      },
      {
          "id": "Mkyy4ktSy",
          "type": "array_length",
          "x": -154.3132731162935,
          "y": -81.02797898755861,
          "settings": {}
      },
      {
          "id": "h-peZ5Fah",
          "type": "equal",
          "x": 181.99091920138454,
          "y": 46.35387333758605,
          "settings": {}
      },
      {
          "id": "j-hiBBPY-",
          "type": "slider",
          "x": -154.3132731162935,
          "y": 46.35387333758605,
          "settings": {
              "value": 0
          }
      },
      {
          "id": "PDz2E7RQG",
          "type": "boolean_inverter",
          "x": 479.19652399369465,
          "y": -31.450927203121324,
          "settings": {}
      },
      {
          "id": "5AtU6uLrJ",
          "type": "gate",
          "x": 783.737992613995,
          "y": -267.4097013473511,
          "settings": {}
      },
      {
          "id": "PzKY2TFxW",
          "type": "text",
          "x": 1096.5940638470754,
          "y": 159.3717948699143,
          "settings": {
              "text": "Your message was too long. Try again."
          }
      },
      {
          "id": "V4Gy5sJVk",
          "type": "condition",
          "x": 1807.769153821706,
          "y": 62.411208237488395,
          "settings": {}
      },
      {
          "id": "y-NYWb4M6",
          "type": "gate",
          "x": 1426.09607337737,
          "y": -31.450927203121324,
          "settings": {}
      }
  ]
  }
  // {
  //   name: 'conductor',
  //   display_name: 'Conductor',
  //   description: 'Create a flow that can manage multiple agents and their interactions.'
  // },
  // {
  //   name: 'web',
  //   display_name: 'Web Connected',
  //   description: 'Enable an LLM to search the internet to find its answers.'
  // }
]

export default flow_templates;