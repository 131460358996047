import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect.js'
import Callout from '../../kit/components/Callout/Callout.js'


import {
  tryToLogin
} from '../../actions/actions.export'

class LoginForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      email: "",
      password: ""
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToLogin({
      email: this.state.email,
      password: this.state.password
    }))
  }


  render(){

    const { userReducer } = this.props;

    let canSubmit = this.state.email && !this.state.email_error && this.state.password && !this.state.password_error;

    if(userReducer.loginForm.errors.error){
      if(userReducer.loginForm.errors.error.indexOf('suspend') > -1) canSubmit = false;
      if(userReducer.loginForm.errors.error.indexOf('maintenance') > -1) canSubmit = false;
    }

    return <div className="margin-top-1rem">
      <form onSubmit={()=>{
        if(canSubmit) this.handleSubmit()
      }}>

        <CustomField
          name="field_email"
          regex="email"
          label="Email"
          placeholder="email"
          required={true}
          thinking={false}
          disabled={userReducer.tryingToLogin}
          value={this.state.email}
          serverError={userReducer.loginForm.errors.email}
          lastSubmit={userReducer.loginForm.lastSubmit.email}
          onChange={(e) => {
            this.setState({
              email: e.value,
              email_error: e.error
            })
          }}
          />

        <CustomField
          name="field_password"
          type="password"
          label="Password"
          placeholder="********"
          description={<Link to="/forgot">
            I forgot.
          </Link>}
          required={true}
          thinking={false}
          disabled={userReducer.tryingToLogin}
          value={this.state.password}
          serverError={userReducer.loginForm.errors.password}
          lastSubmit={userReducer.loginForm.lastSubmit.password}
          onChange={(e) => {
            this.setState({
              password: e.value,
              password_error: e.error
            })
          }}
          />

        <div className="spacer-2rem"/>

        {
          userReducer.loginForm.errors.message &&
          <Callout
            style="danger"
            dismissable={true}
            content={userReducer.loginForm.errors.message}
          />
        }
        
        <div className="list-right">
          <CustomButton
            display="Sign In"
            color="success"
            thinking={userReducer.tryingToLogin}
            fail={userReducer.loginFail}
            success={userReducer.loginSuccess}
            disabled={!canSubmit}
            onClick={()=>{
              if(canSubmit) this.handleSubmit()
            }}
            />
        </div>
      </form>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(LoginForm);
