const tips = [
  {
    text: "The same knowledge base can be leveraged by multiple agents."
  },
  {
    text: "Many projects require more than one agent working together to provide a complete solution."
  },
  {
    text: "All agents in a project can share the same API keys"
  },
  {
    text: "An agent might actually be composed of many LLMs, each playing a different role."
  },
  {
    text: "Agents are version controlled, so you can always roll back to a previous version or edit the draft version without impacting production use."
  },
  {
    text: "You can be a member of multiple organizations, and each organization can have multiple projects."
  },
  {
    text: "Agent demo pages can be reconfigured, protected with a passcode, and shared with anyone."
  },
  {
    text: "You can link the same agent in multiple projects, offering a way to easily scale a common solution you've developed."
  },
  {
    text: "You can create a new agent from a template, or clone an existing agent."
  },
  {
    text: "The ZeroWidth API has dedicated SDK packages for Node.js and React, making integration easy and fast.",
    link: "https://npmjs.com/package/@zerowidth/api"
  },
  {
    text: "You can use ZeroWidth credits to access any of the available foundational language models, or bring your own API key for each provider you intend to use."
  },
  {
    text: "Any agent can be deployed as an API endpoint, allowing you to integrate it into your own applications."
  }
]


export default tips;