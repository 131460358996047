import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import Modal from '../../kit/components/Modal/Modal.js'

import Hydrate from '../../components/Hydrate/Hydrate.js'

import stringToColor from '../../utilities/stringToColor';

import {
  tryToUploadUserAvatar
} from '../../actions/actions.export'

class EditUserAvatarForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      src: null,
      crop: {
        unit: '%',
        width: 50,
        height: 50,
        x: 25,
        y: 25
      },
      croppedImageUrl: null,
    }

    this.handleFile = this.handleFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.onInitialLoad = this.onInitialLoad.bind(this);
  }


  componentWillReceiveProps(newprops){
    if(newprops.userReducer.uploadUserAvatarSuccess !== this.state.lastSuccess){
      this.setState({
        lastSuccess: newprops.userReducer.uploadUserAvatarSuccess,
        imageCropModalOpen: false
      })
    }
  }

  handleFile(e){

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.setState({
        src: fileReader.result,
        imageCropModalOpen: true
      });
    }   
    fileReader.readAsDataURL(e.target.files[0]);
  }

  handleSubmit(e){
    const { dispatch } = this.props;
    
    dispatch(tryToUploadUserAvatar(this.state.croppedImage));
  }

  onInitialLoad(e){
    this.imageRef = e.target;
    let width = e.target.width;
    let height = e.target.height;

    let sw = width * .9;
    let sh = height * .9;

    let crop = {};

    if(width > height){
      // landscape
      crop = {
        unit: "px",
        width: sh,
        height: sh,
        x: width / 2 - (sh / 2),
        y: (height - sh) / 2
      }

    } else if(height > width){
      // portrait
      crop = {
        unit: "px",
        width: sw,
        height: sw,
        x: (width - sw) / 2,
        y: height / 2 - sw / 2
      }

    } else if(height === width){
      // square
      crop = {
        unit: "%",
        width: 100,
        height: 100,
        x: 0,
        y: 0
      }
    }

    this.setState({
      crop: crop
    })

    this.onCropComplete(crop);
  }


  onCropChange(crop){
    this.setState({ crop });
  }

  onCropComplete(crop){
    if(this.imageRef && crop.width && crop.height){
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop)
      this.setState({ 
        croppedImageUrl: croppedImageUrl 
      })
    }
  }

  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    
    let scale;
    if(image.width < image.height){
      scale = image.naturalWidth / image.width;
    } else {
      scale = image.naturalHeight / image.height;  
    }

    const dim = 500;

    canvas.width = dim; 
    canvas.height = dim;
    const ctx = canvas.getContext("2d");
    
    ctx.drawImage(
      image,
      crop.x * scale,
      crop.y * scale,
      crop.width * scale,
      crop.height * scale,
      0,
      0,
      dim,
      dim
    );

    setTimeout(()=>{
      const reader = new FileReader();
      canvas.toBlob(blob => {
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.dataURLtoFile(reader.result, 'avatar.jpg');
        }
      })
    }, 100)
  }

  dataURLtoFile(dataurl, filename){
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length; 
    let u8arr = new Uint8Array(n);
            
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, {type:mime});

    this.setState({ 
      croppedImage: croppedImage 
    }) 
  }

  render(){

    const { userReducer } = this.props; 

    return <div className="">

      <div className="margin-bottom-2rem margin-top-05rem">
        <h4 className="no-margin">Avatar</h4>
        <small>
          Change your profile picture.
        </small>
      </div>
      <div className="box">
        <div className="list-left">
          <div className="margin-right-3rem margin-left-1rem margin-bottom-1rem">
            <Hydrate
              mode="avatar"
              type="me"
              size={200}
              />
          </div>
          <div className="margin-left-3rem">
            <p className="no-margin-top margin-bottom-1rem">Before uploading...</p>
            <ul className="ul-iconed no-margin-top margin-bottom-2rem text-smaller">
              <li><i className="fas fa-angle-right li-icon"></i>Your profile picture will be visible by anyone.</li>
              <li><i className="fas fa-angle-right li-icon"></i>You can crop and preview it before saving.</li>
              <li><i className="fas fa-angle-right li-icon"></i>You can change it at anytime.</li>
            </ul>
            <CustomButton
              display="Upload New Picture"
              color="black"
              size="small"
              onClick={()=>{
                document.getElementById('input_avatar').click();
              }}
              />
          </div>
        </div>
        <input 
          type="file" 
          accept="image/*"
          id="input_avatar" 
          value={this.state.avatar} 
          onChange={this.handleFile} 
          style={{display: "none"}}
          />

        <Modal
          maxWidth={500}
          show={this.state.imageCropModalOpen}
          exitable={true}
          onExit={()=>{ this.setState({imageCropModalOpen: false })}}
          content={<div>
            <h3 className="no-margin margin-bottom-2rem">Crop New Profile Picture</h3>
            {
              this.state.src && 
              <ReactCrop
                src={this.state.src}
                aspect={1}
                crop={this.state.crop}
                circularCrop={true}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              >
                <img src={this.state.src} onLoad={this.onInitialLoad}/>
              </ReactCrop> 
            }
            <div className="modal-inner-button-row">
              <CustomButton
                display="Cancel"
                disabled={userReducer.tryingToUploadUserAvatar}
                onClick={()=>{ 
                  this.setState({ imageCropModalOpen: false }) 
                }}
                />
              <CustomButton
                display={"Save"}
                color="success"
                thinking={userReducer.tryingToUploadUserAvatar}
                success={userReducer.uploadUserAvatarSuccess}
                fail={userReducer.uploadUserAvatarFail}
                onClick={this.handleSubmit}
                />
            </div>
          </div>}
          />
      </div>
      
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(EditUserAvatarForm);
