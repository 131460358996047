const nodeCategories = [
    {
      name: 'i/o',
      display_name: 'I/O',
      icon: 'arrow-to-right',
      nodes: []
    },
    {
      name: 'messages',
      display_name: 'Messages',
      icon: 'comments-alt',
      nodes: []
    },
    {
      name: 'llm',
      display_name: 'LLM',
      icon: 'brain',
      nodes: []
    },
    {
      name: 'knowledge',
      display_name: 'Knowledge',
      icon: 'book-spells',
      nodes: []
    },
    {
      name: 'external',
      display_name: 'Web',
      icon: 'globe',
      nodes: []
    },
    {
      name: 'logic',
      display_name: 'Logic',
      icon: 'question',
      nodes: []
    },
    {
      name: 'math',
      display_name: 'Math',
      icon: 'calculator-alt',
      nodes: []
    },
    {
      name: 'string',
      display_name: 'String',
      icon: 'text-size',
      nodes: []
    },
    {
      name: 'time',
      display_name: 'Time',
      icon: 'clock',
      nodes: []
    },
    {
      name: 'data',
      display_name: 'Data',
      icon: 'stream',
      nodes: []
    }
  ]

export default nodeCategories;