import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { push } from 'connected-react-router'

import {
  tryToGetPaginated
} from 'actions/actions.export'

import Hydrate from 'components/Hydrate/Hydrate.js'
import ActivityFeed from 'components/ActivityFeed/ActivityFeed'

import tools from 'configs/config.inquiry-tools'

import './InquiryHome.scss';


class InquiryHome extends Component {
  constructor(props){
    super(props);


    this.state = {

    }
  }

  componentWillMount(){
    const { dispatch, inquiryReducer } = this.props;

    dispatch(tryToGetPaginated({
      kind: 'inquiries',
      id: this.props.id,
      collection: 'activity-log'
    }))
  }

  render(){

    const { dispatch, inquiryReducer, canWrite } = this.props;
    
    const inquiry = inquiryReducer.cache[this.props.id];
    if(!inquiry) return <span>Inquiry failed to load.</span>;


    let contextNodes = 0;

    // walk the context map and count nodes
    let treeWalk = (map) => {
      for(let i=0; i<map.length; i++){
        if(map[i].details){
          treeWalk(map[i].details);
        } else {
          contextNodes++;
        }
      }
    }

    if(inquiry.context_map && inquiry.context_map.length > 0){
      treeWalk(inquiry.context_map);
    }
    
    
    return <div className="inquiry-home">
      <div className="box box-transparent box-no-shadow box-near-no-pad">
        <h5 className="no-margin text-uppercase text-inquiries">Project</h5>
        <h2 className="no-margin">{inquiry.display_name}</h2>
        <p className="margin-bottom-05rem margin-top-05rem">
          {
            inquiry.description ? 
            inquiry.description 
            : 
            <span 
              className="text-gs5 text-hover-black clickable"
              onClick={e => {
                dispatch(push("/workbench/" + inquiry.scope + "/inquiries/" + inquiry.id + "/settings"));
              }}>
                <small className="fal fa-pencil icon-before-text"/>Add a detailed description... 
            </span>
          }
        </p>
        <small className="text-muted">Created {moment(inquiry.created_at).fromNow()} by <Hydrate type="user" id={inquiry.created_by}/></small>
      </div>
      <div className="row row-eq-height margin-top-1rem">
        
      </div>
      <div className="row row-eq-height">

        <div className="col-md-4 flex-column-stretch">
          <Link to={'/workbench/' + inquiry.scope + '/inquiries/' + inquiry.id + '/foundations' } className="text-400 link-no-decoration box flex-grow box-half-pad">
            <div className="flex-split margin-bottom-05rem flex-split-align-start">
              <h5 className="no-margin ">Project Foundations</h5>
              <small className="text-muted"><i className="fal fa-cubes"/></small>
            </div>
            
          </Link>
        </div>

        <div className="col-md-4 flex-column-stretch">
          <Link to={'/workbench/' + inquiry.scope + '/inquiries/' + inquiry.id + '/context' } className="text-400 link-no-decoration box flex-grow box-half-pad">
            <div className="flex-split margin-bottom-05rem flex-split-align-start">
              <h5 className="no-margin ">Context Exploration</h5>
              <small className="text-muted"><i className="fal fa-chart-network"/></small>
            </div>
            <div>
              <h1 className="no-margin-top no-margin-bottom text-400">{contextNodes}</h1>
              <small>Map Elements</small>
            </div>
          </Link>
        </div>

        <div className="col-md-4 flex-column-stretch">
          <Link to={'/workbench/' + inquiry.scope + '/inquiries/' + inquiry.id + '/workflow' } className="text-400 link-no-decoration box flex-grow box-half-pad">
            <div className="flex-split margin-bottom-05rem flex-split-align-start">
              <h5 className="no-margin ">Workflow Mapping</h5>
              <small className="text-muted"><i className="fal fa-project-diagram"/></small>
            </div>
            
          </Link>
        </div>

      </div>
      <hr/>
      <div className="row">
        <ActivityFeed id={inquiry.id} kind="inquiries"/>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { inquiryReducer } = state;

  return {
    inquiryReducer,
  }
}

export default connect(mapStateToProps)(InquiryHome);

  