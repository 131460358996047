import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CustomButton from 'kit/components/CustomButton/CustomButton';
import CustomField from 'kit/components/CustomField/CustomField';
import Modal from 'kit/components/Modal/Modal';
import CustomSelect from 'kit/components/CustomSelect/CustomSelect';
import MegaMarkdown from 'components/MegaMarkdown/MegaMarkdown';
import BetterChat from 'components/BetterChat/BetterChat';

import socketClientManager from 'sockets/socketClientManager';
import FlowRenderer from './FlowRenderer';
import './Flow.scss';

import nodeCategories from 'configs/config.node-categories';

import { NodeSettings_openai_chat } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_openai_chat';
import { NodeSettings_openai_embedding } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_openai_embedding';
import { NodeSettings_text } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_text';
import { NodeSettings_prompt_role_setter } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_prompt_role_setter';
import { NodeSettings_prompt } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_prompt';
import { NodeSettings_key_value_maker } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_key_value_maker';
import { NodeSettings_slider } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_slider';
import { NodeSettings_semantic_search } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_semantic_search';
import { NodeSettings_boolean } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_boolean';
import { NodeSettings_subflow } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_subflow';
import { NodeSettings_request } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_request';
import { NodeSettings_simple_llm } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_simple_llm';

import {
  bulkUpdateComponent,
  tryToDemoComponent,
  clearLinkCache,
  tryToExplainFlowNodes,
  tryToGenerateFlow,
  clearTempFlow
} from 'actions/actions.export';

import {
  formatLinkId
} from './FlowBuilderUtils';

import isFeatureSwitchOn from 'utilities/isFeatureSwitchOn';
import BetterPrompt from 'components/BetterPrompt/BetterPrompt';
import BetterContextOnly from 'components/BetterContextOnly/BetterContextOnly';


let cursorTimers = {
  debouncedFinalUpdate: null,
  lastThrottleTime: 0
}

let changeTimers = {
  debouncedFinalUpdate: null,
  lastThrottleTime: 0,
  localTimeout: null
}

const updateSpeed = 100;

const FlowInteractionLayer = React.memo(({ 
  id, 
  initialNodes, 
  initialLinks, 
  onChange, 
  onSelectNodes, 
  org_id 
}) => {
  const [showTryIt, setShowTryIt] = useState(false);

  const [nodes, setNodes] = useState(initialNodes);
  const [links, setLinks] = useState(initialLinks);

  const nodesRef = useRef(nodes);
  const linksRef = useRef(links);

  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedLinks, setSelectedLinks] = useState([]);

  const canvasRef = useRef(null);
  const [generatorOpen, setGeneratorOpen] = useState(false);
  const [generatorPrompt, setGeneratorPrompt] = useState('');

  const [showContextModal, setShowContextModal] = useState(false);
  const [testingVariables, setTestingVariables] = useState({});

  const userReducer = useSelector(state => state.userReducer);
  const featuresReducer = useSelector(state => state.featuresReducer);
  const componentReducer = useSelector(state => state.componentReducer);
  const sharedReducer = useSelector(state => state.sharedReducer);
  const intelReducer = useSelector(state => state.intelReducer);

  const dispatch = useDispatch();

  const eventsQueue = useRef([]);
  const localEventsQueue = useRef([]);

  const [showErrors, setShowErrors] = useState(false);

  let guiToShow = 'madlib';

  const nodeSettings = {
    openai_chat: NodeSettings_openai_chat,
    openai_embedding: NodeSettings_openai_embedding,
    text: NodeSettings_text,
    prompt: NodeSettings_prompt,
    prompt_role_setter: NodeSettings_prompt_role_setter,
    key_value_maker: NodeSettings_key_value_maker,
    slider: NodeSettings_slider,
    semantic_search: NodeSettings_semantic_search,
    boolean: NodeSettings_boolean,
    subflow: NodeSettings_subflow,
    request: NodeSettings_request,
    simple_llm: NodeSettings_simple_llm
  }

  let cursorDirectory = sharedReducer.cursorCache['components-' + id] || {};

  // convert to list
  let cursors = Object.keys(cursorDirectory).map(key => {
    return {
      user_id: key,
      ...cursorDirectory[key]
    }
  });

  // filter yourself out of the cursors list
  cursors = cursors.filter(cursor => cursor.user_id !== userReducer.myData.id);

  // filter cursors that have null x or y
  cursors = cursors.filter(cursor => cursor.x !== null && cursor.y !== null);

  const sendCursorUpdate = (x, y) => {
    const timestamp = Date.now();
    socketClientManager.emitEvent('updateCursor', {
      type: 'components',
      id: id,
      cursor: {
        kind: 'components',
        kind_id: id,
        page: undefined,
        x: x,
        y: y,
        timestamp: timestamp
      }
    });
  };


  // Figure out which nodes need to be highlighted based on the last packets from any demoing
  let highlightedNodeIds = [];
  // grab the last updated node
  if(componentReducer.demoResponseProgress.length > 0){
    // if we aren't all the way complete, then we want to highlight the last node
    if(!componentReducer.demoResponseProgress[componentReducer.demoResponseProgress.length - 1].end_time){

      let filteredChain = componentReducer.demoResponseProgress.filter(c => c.node_id !== undefined);
      if(filteredChain.length > 0){
        highlightedNodeIds.push(filteredChain[filteredChain.length - 1].node_id);
      }
    }
  }

  const handleDemoComponent = (only_these_nodes) => {
    dispatch(tryToDemoComponent({
      id: id,
      version: 'draft',
      data: {
        data: {
          messages: [
            {
              role: 'user',
              content: 'Hello'
            }
          ],
        },
        only_these_nodes: only_these_nodes,
        existing_link_cache: componentReducer.linkCache[id]
      }
    }, true))
  }

  const handleMouseLeave = (e) => {
    clearTimeout(cursorTimers.debouncedFinalUpdate);
    sendCursorUpdate(null, null);
  }

  const handleMouseMove = (x, y) => {
    let now = Date.now();
    if (now - cursorTimers.lastThrottleTime > updateSpeed) {
      sendCursorUpdate(x, y);
      cursorTimers.lastThrottleTime = now;
    } else {

      clearTimeout(cursorTimers.debouncedFinalUpdate);
      cursorTimers.debouncedFinalUpdate = setTimeout(() => {
        sendCursorUpdate(x, y);
      }, updateSpeed * 2);
    }
  };

  const sendUpdate = () => {
    if(eventsQueue.current.length === 0) return;
    
    socketClientManager.emitEvent('updateRoom', {
      type: 'components',
      id: id,
      events: eventsQueue.current
    });

    eventsQueue.current = [];
  };

  const handleUpdate = (event, instant) => {
    // add this event to the queue
    eventsQueue.current.push(event);
    localEventsQueue.current.push(event);

    let now = Date.now();
    if (now - changeTimers.lastThrottleTime > updateSpeed) {
      sendUpdate();
      changeTimers.lastThrottleTime = now;
    } else {

      clearTimeout(changeTimers.debouncedFinalUpdate);
      changeTimers.debouncedFinalUpdate = setTimeout(() => {
        sendUpdate();
      }, updateSpeed * 2);
    }

    if(changeTimers.localTimeout){
      clearTimeout(changeTimers.localTimeout);
    }
    changeTimers.localTimeout = setTimeout(() => {
      // update the state locally via the reducer
      dispatch(bulkUpdateComponent({
        id: id,
        events: localEventsQueue.current
      }));
      localEventsQueue.current = [];
    }, instant ? 0 : 500);
  };

  useEffect(() => {
    setNodes(initialNodes);
    nodesRef.current = initialNodes;
  }, [initialNodes]);

  useEffect(() => {
    setLinks(initialLinks);
    linksRef.current = initialLinks;
  }, [initialLinks]);



  let hydratedSelectedNodes = selectedNodes.map(selectedNode => {
    let node = nodes.find(node => node.id === selectedNode);
    if(!node) return null;

    return {
      node: nodes.find(node => node.id === selectedNode),
      type: intelReducer.flow_nodes.find(n => n.name === nodes.find(node => node.id === selectedNode).type),
      category: nodeCategories.find(c => c.name === intelReducer.flow_nodes.find(n => n.name === nodes.find(node => node.id === selectedNode).type).category)
    }
  });

  let NodeSettings;

  if(hydratedSelectedNodes.length === 1){
    NodeSettings = nodeSettings[hydratedSelectedNodes[0].node.type];
  }

  let errors = [];

  // check for errors

  // make sure there is only one node that is a request node
  let requestNodes = nodes.filter(node => node.type.startsWith('request'));
  if(requestNodes.length > 1){
    errors.push({
      message: 'You can only have one input node in your flow.',
    });
  }


  // Must have a response node
  let responseNode = nodes.find(node => node.type === 'response');
  if(!responseNode){
    errors.push({
      message: 'You must have a response node in your flow.',
    });
  }

  nodes.forEach(node => {
    let nodeType = intelReducer.flow_nodes.find(n => n.name === node.type);

    // for all nodes, check for any missing required inputs
    if(nodeType.inputs){
      nodeType.inputs.forEach(input => {
        
        if(!input.optional){

          let foundLinkToIt = links.find(link => {
            return link.to.node_id === node.id && link.to.input === input.name;
          });

          if(!foundLinkToIt){
            errors.push({
              message: <span><strong>{nodeType.display_name}</strong> is missing a connection into its <strong>{input.display_name}</strong> input.</span>,
            });
          }
        }
      });
    }

    // for all nodes, check for any missing required settings
    if(nodeType.settings){
      nodeType.settings.forEach(setting => {
        if(setting.required){
          if(!node.settings || !node.settings[setting.name]){
            errors.push({
              message: <span><strong>{nodeType.display_name}</strong> is missing a required setting: <strong>{setting.display_name}</strong>.</span>,
              onClick: () => {
                // select this node
                setSelectedNodes([node.id]);
              }
            });
          }
        }
      });
    }
  });

  
  // are selected nodes ready to process?
  let selectedNodesReady = false;
  let explanation;
  if(selectedNodes.length > 0){

    selectedNodesReady = selectedNodes.every(node_id => {
      let node = nodes.find(n => n.id === node_id);
      let nodeType = intelReducer.flow_nodes.find(n => n.name === node.type);
      let filteredToLinks = links.filter(l => l.to.node_id === node.id);

      if(nodeType.inputs.length === 0){
        return true;
      } else {
        let hasAllRequiredInputs = nodeType.inputs.filter(i => i.required).every(i => {
          return filteredToLinks.find(l => l.to.input === i.name) !== undefined;
        });

        if(componentReducer.linkCache[id] && hasAllRequiredInputs){
          // check if each filteredToLinks has a value in the linkCache
          return filteredToLinks.every(l => {
            return componentReducer.linkCache[id][formatLinkId(l)] !== undefined;
          });
        }
      } 
    });

    let key = selectedNodes.join('-');
    explanation = componentReducer.cache[id] && componentReducer.cache[id].explanations && componentReducer.cache[id].explanations[key] ? componentReducer.cache[id].explanations[key] : null;
  }



  let component = componentReducer.cache[id];
  let loadedVersion;
  let contextVariableDiv = null;
  if(component){
    if(component.type){
      // guiToShow = component.type;
    }

    // find draft version
    loadedVersion = component.versions.find(v => v.id === 'draft');

    if(loadedVersion && loadedVersion.variables){
      contextVariableDiv = loadedVersion.variables.map((variable, i) => {
        return <div key={i} className={""}>
          {
            variable.type === 'text' &&
            <CustomField
              name={variable.key}
              value={testingVariables[variable.key]}
              label={variable.display_name || '${' + variable.key + '}'}
              description={variable.description || " "}
              placeholder={variable.placeholder || " "} 
              minLength={variable.min_length}
              maxLength={variable.max_length}
              disabled={componentReducer.tryingToDemoComponent}
              onChange={e => {
                let variables = testingVariables;
                variables[variable.key] = e.value;
                setTestingVariables(variables);
              }}
              />
          }

          {
            variable.type === 'paragraph' &&
            <CustomField
              name={variable.key}
              value={testingVariables[variable.key]}
              label={variable.display_name || '${' + variable.key + '}'}
              description={variable.description || " "}
              placeholder={variable.placeholder || " "} 
              minLength={variable.min_length}
              maxLength={variable.max_length}
              disabled={componentReducer.tryingToDemoComponent}
              rows={5}
              onChange={e => {
                let variables = testingVariables;
                variables[variable.key] = e.value;
                setTestingVariables(variables);
              }}
              />
          }

          {
            variable.type === 'number' &&
            <CustomField
              name={variable.key}
              type="number"
              value={testingVariables[variable.key]}
              label={variable.display_name || '${' + variable.key + '}'}
              description={variable.description || " "}
              placeholder={variable.placeholder || " "} 
              min={variable.min}
              max={variable.max}
              disabled={componentReducer.tryingToDemoComponent}
              onChange={e => {
                let variables = testingVariables;
                variables[variable.key] = e.value;
                setTestingVariables(variables);
              }}
              />
          }

          {
            variable.type === 'select' &&
            <CustomSelect
              name={variable.key}
              value={testingVariables[variable.key]}
              label={variable.display_name || '${' + variable.key + '}'}
              description={variable.description || " "}
              placeholder={variable.placeholder || " "} 
              options={variable.options || []}
              disabled={componentReducer.tryingToDemoComponent}
              onChange={e => {
                let variables = testingVariables;
                variables[variable.key] = e;
                setTestingVariables(variables);
              }}
              />
          }
        </div>
      })
    }

    // check to see which kind of request node is being used
    let requestNode = nodes.find(node => node.type.startsWith('request'));
    if(requestNode){
      switch(requestNode.type){
        case 'request':
          guiToShow = "chat";
          break;
        case 'request_single_prompt':
          guiToShow = "single-prompt";
          break;
        case 'request_context_only':
          guiToShow = "madlib";
          break;
      }
    } else {
      if(!guiToShow){
        guiToShow = "madlib";
      }
    }
  }

  

  return (
    <div className="flow-interaction-layer" ref={canvasRef} onMouseLeave={handleMouseLeave}>
      <Modal
        show={showContextModal}
        exitable={true}
        onExit={e => setShowContextModal(false)}
        acceptable={true}
        onAccept={e => setShowContextModal(false)}
        acceptButtonLabel="Use these values"
        content={<div>
          <h4 className="no-margin-top">
            Context Variables
          </h4>
          {
            loadedVersion && loadedVersion.variables.length > 0 ?
            <div>
              <p>
                Edit the values of the context variables to test your flow.
              </p>
            </div>
            :
            <div>
              <p>
                This flow does not have any context variables.
              </p>
            </div>
          }
          {contextVariableDiv}
        </div>}
        />
      <FlowRenderer 
        component_id={id}
        nodes={nodes} 
        links={links}
        cursors={cursors} 
        selectedNodes={selectedNodes}
        selectedLinks={selectedLinks}
        highlightedNodeIds={highlightedNodeIds}
        onCursorMove={handleMouseMove}
        onOpenGenerator={e => {
          if(isFeatureSwitchOn('flow_generator', userReducer, featuresReducer)){
            dispatch(clearTempFlow(id));
            setGeneratorOpen(true);
            setGeneratorPrompt('');
            setShowTryIt(false);
            setSelectedNodes([]);
          }
        }}
        
        onSelectNodes={newSelectedNodes => {
          setSelectedNodes(newSelectedNodes);
        }}

        onSelectLinks={newSelectedLinks => {
          setSelectedLinks(newSelectedLinks);
        }}

        onNodePlay={(node_id) => {
          handleDemoComponent([node_id]);
        }}

        onNodeChange={({node_id, changes, operation = 'update', instant}) => {

          if(operation === 'delete'){
            // find any links that are connected to this node and delete them first
            
            let existingLinks = linksRef.current.filter(l => {
              return l.from.node_id === node_id || l.to.node_id === node_id;
            });

            existingLinks.forEach(l => {
              // whats the index of this link
              let linkIndex = linksRef.current.findIndex(link => link === l);

              handleUpdate({
                // jsonpath format to flow_links with this id
                path: `$.flow_links[${linkIndex}]`,
                operation: 'delete'
              }, true);

              // dispatch(clearLinkCache({
              //   component_id: id,
              //   link_id: formatLinkId(linksRef.current[linkIndex])
              // }))

            });
          }


          handleUpdate({
            // jsonpath format to flow_nodes with this id
            path: `$.flow_nodes[?(@.id=='${node_id}')]`,
            changes: changes,
            operation: operation
          }, instant === true || operation === 'delete' ? true : false);

          

          onChange();
        }}

        onNewNode={(newNode) => {

          handleUpdate({
            // jsonpath format to flow_nodes array
            path: `$.flow_nodes`,
            changes: newNode,
            operation: 'push'
          }, true);

          onChange();
        }}

        onNewLink={(newLink) => {

          // does this input allow multiple connections?
          let node = nodesRef.current.find(n => n.id === newLink.to.node_id);
          if(node){
            let nodeType = intelReducer.flow_nodes.find(n => n.name === node.type);

            if(nodeType.inputs.find(i => i.name === newLink.to.input).allow_multiple){
              // do nothing lets just add this one
            } else {
              // remove any existing links to this input

              let existingLinks = linksRef.current.filter(l => {
                return l.to.node_id === newLink.to.node_id && l.to.input === newLink.to.input;
              });

              existingLinks.forEach(l => {
                // whats the index of this link
                let linkIndex = linksRef.current.findIndex(link => link === l);

                handleUpdate({
                  // jsonpath format to flow_links with this id
                  path: `$.flow_links[${linkIndex}]`,
                  changes: {},
                  operation: 'delete'
                }, true);

                // dispatch(clearLinkCache({
                //   component_id: id,
                //   link_id: formatLinkId(linksRef.current[linkIndex])
                // }))
              });
            }
          }

          handleUpdate({
            // jsonpath format to flow_links array 
            path: `$.flow_links`,
            changes: newLink,
            operation: 'push'
          }, true)

          onChange();
        }}

        onLinkChange={({link_id, changes, operation}) => {
         
          // figure out what index this is in the array
          let linkIndex = linksRef.current.findIndex(l => {
            console.log(formatLinkId(l), link_id);
            return formatLinkId(l) === link_id;
          });

          handleUpdate({
            // jsonpath format to flow_links with this id
            path: `$.flow_links[${linkIndex}]`,
            changes: changes,
            operation: operation
          }, true);


          onChange();
        }}
        />
      

        
      <div className="flow-interaction-layer-selected-node-box">
        <div className=" margin-bottom-05rem">
          
          {
            errors.map((error, index) => {
              return <div className={"flow-interaction-layer-error " + (error.onClick ? "clickable" : "")} key={index} onClick={e => {
                if(error.onClick){
                  error.onClick();
                }
              }}>
                <small>
                  {error.message}
                </small>      
              </div>
            })
          }
        

        {/* Selected node interactions here */}
        {
          selectedNodes.length === 1 &&
          <div className="box box-half-pad box-light-border flex-grow-0">
             <div className="flex-split margin-bottom-05rem">
              <strong>
                <i className={'far fa-fw fa-' + hydratedSelectedNodes[0].category.icon + ' icon-before-text'}/>
                {hydratedSelectedNodes[0].type.display_name}
              </strong>
              <div className="list-right">
                <CustomButton
                  display={<span className="far fa-trash fa-fw"></span>}
                  size="xs-icon"
                  color="transparent-danger"
                  onClick={e => {
                    if(window.confirm('Are you sure you want to delete the currently selected nodes?')){
                      
                      let node_id = hydratedSelectedNodes[0].node.id;

                      setSelectedNodes([]);

                      handleUpdate({
                        // jsonpath format to flow_nodes with this id
                        path: `$.flow_nodes[?(@.id=='${node_id}')]`,
                        changes: {},
                        operation: 'delete'
                      }, true);
                      
                      onChange();
                    }
                  }}  
                  />
                  <CustomButton
                    display={<span className="far fa-times fa-fw"></span>}
                    color="grey"
                    size="xs-icon"
                    onClick={() => {
                      setSelectedNodes([]);
                    }}
                  />
              </div>
            </div>
            
            <p className="thin-line-height no-margin-top margin-bottom-1rem">
              <small>
                {hydratedSelectedNodes[0].type.description}
              </small>
            </p>
            {
              nodeSettings[hydratedSelectedNodes[0].node.type] ?
              <div>
                <hr className="hr-mini"/>
                <NodeSettings 
                  canWrite={true} 
                  org_id={org_id}
                  node={hydratedSelectedNodes[0].node} 
                  setNodeSettings={(newNodeSettings) => {

                    handleUpdate({
                      // jsonpath format to flow_nodes with this id
                      path: `$.flow_nodes[?(@.id=='${hydratedSelectedNodes[0].node.id}')]`,
                      changes: {
                        settings: {
                          ...hydratedSelectedNodes[0].node.settings,
                          ...newNodeSettings
                        }
                      },
                      operation: 'update',
                    }, true);

                    onChange();
                  }}/>
                <div className="spacer-2rem"/>
                <CustomButton
                  display={<span>Save & Close</span>}
                  size="small"
                  noMargin={true}
                  block={true}
                  color="success"
                  onClick={e => {
                    // handleDemoComponent(hydratedSelectedNodes.map(node => node.node.id));
                    setSelectedNodes([]);
                  }}
                  />
              </div>
              :
              <div>
                <hr className=""/>
                <small className="text-muted">
                  This node does not have any settings to edit.
                </small>
              </div>
            }
            <hr className=""/>
            <div className="flex-split">
              <div className="margin-right-05rem flex-50">
                <CustomButton
                  display={<span><i className="fas fa-play icon-before-text"/>Process Node</span>}
                  size="small"
                  noMargin={true}
                  block={true}
                  color="component"
                  disabled={!selectedNodesReady}
                  onClick={e => {
                    handleDemoComponent(hydratedSelectedNodes.map(node => node.node.id));
                  }}
                  />
              </div>
              <div className="margin-left-05rem flex-50">
                <CustomButton
                  display={<span><i className="fas fa-sparkles icon-before-text"/>Explain This Node</span>}
                  size="small"
                  noMargin={true}
                  block={true}
                  color="black"
                  thinking={componentReducer.tryingToExplainFlowNodes}
                  success={componentReducer.explainFlowNodesSuccess}
                  fail={componentReducer.explainFlowNodesFail}
                  onClick={e => {
                    dispatch(tryToExplainFlowNodes({
                      id: id,
                      nodes_to_explain: hydratedSelectedNodes.map(node => node.node),
                      name: componentReducer.cache[id].display_name,
                      description: componentReducer.cache[id].description,
                      flow: {
                        nodes: nodes,
                        links: links
                      }
                    }))
                  }}
                  />
              </div>
            </div>
            {
              explanation && <div>
                <hr className=""/>
                <div className="flex-split margin-bottom-05rem">
                  <strong>
                    Generated Explanation:
                  </strong>
                  <Link toBlank="/contact" className="text-muted text-hover-black link-no-decoration text-400"><small>Report</small></Link>
                </div>
                <div className="flow-interaction-layer-generated-explanation">
                  <MegaMarkdown text={explanation.content}/>
                </div>
              </div>
            }
          </div>
        }
        {
          selectedNodes.length > 1 &&
          <div className="box box-half-pad box-light-border flex-grow-0">
            <div className="flex-split margin-bottom-05rem">
              <strong>
                {selectedNodes.length} of {nodes.length} nodes selected
              </strong>
              <div className="list-right">
                <CustomButton
                  display={<span className="far fa-trash fa-fw"></span>}
                  size="xs-icon"
                  color="transparent-danger"
                  onClick={e => {
                    if(window.confirm('Are you sure you want to delete the currently selected nodes?')){
                      let nodesToDelete = hydratedSelectedNodes.map(node => node.node.id);

                      setSelectedNodes([]);

                      // for each selected node, delete it
                      nodesToDelete.forEach(node_id => {
                        handleUpdate({
                          // jsonpath format to flow_nodes with this id
                          path: `$.flow_nodes[?(@.id=='${node_id}')]`,
                          changes: {},
                          operation: 'delete'
                        }, true);
                      });


                      onChange();
                    }
                  }}  
                  />
                  <CustomButton
                    display={<span className="far fa-times fa-fw"></span>}
                    color="grey"
                    size="xs-icon"
                    onClick={() => {
                      setSelectedNodes([]);
                    }}
                  />
              </div>
            </div>
            {
              // hydratedSelectedNodes.map((selectedNode, index) => {
              //   if(!selectedNode) return null;
              //   return (
              //     <div key={index} className="box box-no-shadow box-light-border margin-bottom-05rem box-no-pad">
              //       <div className="flex-split">
              //         <h6 className="no-margin padding-05rem">
              //           <i className={'far fa-fw fa-' + selectedNode.category.icon + ' icon-before-text icon-after-text'}/>
              //           {selectedNode.type.display_name}
              //         </h6>
              //         <CustomButton
              //           display={<i className="fal fa-pencil text-muted"/>}
              //           color="transparent"
              //           size="xs"
              //           onClick={() => {
              //             setSelectedNodes([selectedNode.node.id]);
              //           }}
              //         />
              //       </div>
              //     </div>
              //   )
              // })
            }
            <hr className=""/>
{/*           
            <CustomButton
              display={<span><i className="fas fa-play icon-before-text"/>Process Selected</span>}
              size="small"
              noMargin={true}
              block={true}
              color="component"
              disabled={!selectedNodesReady}
              onClick={e => {
                handleDemoComponent(hydratedSelectedNodes.map(node => node.node.id));
              }}
              /> */}

            <div className="flex-split">
              <div className="margin-right-05rem flex-50">
                <CustomButton
                  display={<span><i className="fas fa-play icon-before-text"/>Process Selected</span>}
                  size="small"
                  noMargin={true}
                  block={true}
                  color="component"
                  disabled={!selectedNodesReady}
                  onClick={e => {
                    handleDemoComponent(hydratedSelectedNodes.map(node => node.node.id));
                  }}
                  />
              </div>
              <div className="margin-left-05rem flex-50">
                <CustomButton
                  display={<span><i className="fas fa-sparkles icon-before-text"/>Explain These Nodes</span>}
                  size="small"
                  noMargin={true}
                  block={true}
                  thinking={componentReducer.tryingToExplainFlowNodes}
                  success={componentReducer.explainFlowNodesSuccess}
                  fail={componentReducer.explainFlowNodesFail}
                  color="black"
                  onClick={e => {
                    dispatch(tryToExplainFlowNodes({
                      id: id,
                      nodes_to_explain: hydratedSelectedNodes.map(node => node.node),
                      name: componentReducer.cache[id].display_name,
                      description: componentReducer.cache[id].description,
                      flow: {
                        nodes: nodes,
                        links: links
                      }
                    }))
                  }}
                  />
              </div>
            </div>
            {
              explanation && <div>
                <hr className=""/>
                {
                  explanation.success && 
                  <div className="flex-split margin-bottom-05rem">
                    <strong>
                      Generated Explanation:
                    </strong>
                    <Link toBlank="/contact" className="text-muted text-hover-black link-no-decoration text-400"><small>Report</small></Link>
                  </div>
                }
                <div className="flow-interaction-layer-generated-explanation">
                  <MegaMarkdown text={explanation.content}/>
                </div>
              </div>
            }
          </div>
        }
      </div>
        {
          showTryIt ? <div className="flow-interaction-layer-try-it flow-interaction-layer-try-it__maximized box box-no-pad box-light-border">
            <div className="flow-interaction-layer-try-it-header flex-split">

              <span>
                Test Agent Flow
              </span>
              <div className="list-right">
                <i className="fal fa-brackets-curly text-muted clickable text-hover-black"
                  onClick={e => {
                    e.stopPropagation();
                    setShowContextModal(true);
                  }}
                  />
                <i className="fal fa-chevron-down text-muted clickable text-hover-black" onClick={e => {
                  setShowTryIt(false);
                }}/>
              </div>
            </div>
            <div className="flow-interaction-layer-try-it-body">
              {
                guiToShow === 'chat' &&
                <BetterChat
                  demoCache={componentReducer.demoCache[id + '_draft']}
                  variables={testingVariables}
                  component={componentReducer.cache[id]}
                  component_id={id}
                  version={'draft'}
                  publicDemo={false}
                  />
              }
              {
                guiToShow === 'single-prompt' && <div className="scroll-child padding-1rem">
                  <BetterPrompt
                    demoCache={componentReducer.demoCache[id + '_draft']}
                    variables={testingVariables}
                    component={componentReducer.cache[id]}
                    component_id={id}
                    version={'draft'}
                    publicDemo={false}
                    />
                </div>
              }
              {
                guiToShow === 'madlib' && <div className="scroll-child padding-1rem">
                  <BetterContextOnly
                    demoCache={componentReducer.demoCache[id + '_draft']}
                    variables={testingVariables}
                    component={componentReducer.cache[id]}
                    component_id={id}
                    version={'draft'}
                    publicDemo={false}
                    />
                </div>
              }

            </div>
          </div>
          :
          
          <div className={"flow-interaction-layer-try-it flow-interaction-layer-try-it__minimized box box-half-pad box-light-border box-clickable flex-split"} onClick={e => {
            setShowTryIt(true);
          }}>
            <span>
              Test Agent Flow
            </span>
            <i className="fal fa-chevron-up text-muted"/>
          </div> 
        }
      </div>
      
      {
        generatorOpen && <div className="flow-interaction-layer-generator">
          <div className="flow-interaction-layer-generator-backdrop" onClick={e => {
            setGeneratorOpen(false);
            
          }}>
          </div>
          {
            componentReducer.cache[id].tempFlow ? 
            <div className="flow-interaction-layer-generator-inner-large box box-half-pad flex-grow-0">
              <div style={{height: 400}}>
                <FlowRenderer
                  nodes={componentReducer.cache[id].tempFlow.flow_nodes}
                  links={componentReducer.cache[id].tempFlow.flow_links}
                  interactive={false}
                  simplified={true}
                  />
              </div>
              <p>
                {componentReducer.cache[id].tempFlow.explanation}
              </p>
              <div className="list-right">
                <CustomButton
                  display="Cancel"
                  size="xs"
                  color="grey"
                  onClick={e => {
                    setGeneratorOpen(false);
                    dispatch(clearTempFlow(id));
                  }}
                  />
                <CustomButton
                  display="Accept Flow"
                  size="xs"
                  color="success"
                  onClick={e => {
                    
                    // delete all current flow nodes and links
                    nodesRef.current.forEach(node => {
                      handleUpdate({
                        // jsonpath format to flow_nodes with this id
                        path: `$.flow_nodes[?(@.id=='${node.id}')]`,
                        changes: {},
                        operation: 'delete'
                      }, true);
                    });

                    linksRef.current.forEach(link => {
                      handleUpdate({
                        // jsonpath format to flow_links with this id
                        path: `$.flow_links[?(@.id=='${link.id}')]`,
                        changes: {},
                        operation: 'delete'
                      }, true);
                    });

                    // add the new flow nodes and links
                    componentReducer.cache[id].tempFlow.flow_nodes.forEach(node => {
                      handleUpdate({
                        // jsonpath format to flow_nodes array
                        path: `$.flow_nodes`,
                        changes: node,
                        operation: 'push'
                      }, true);
                    });

                    // add the new flow nodes and links
                    componentReducer.cache[id].tempFlow.flow_links.forEach(link => {
                      handleUpdate({
                        // jsonpath format to flow_links array
                        path: `$.flow_links`,
                        changes: link,
                        operation: 'push'
                      }, true);
                    });

                    onChange();

                    setGeneratorOpen(false);
                    dispatch(clearTempFlow(id));

                  }}
                  />
              </div>
            </div>
            :
            <div className="flow-interaction-layer-generator-inner box box-no-pad flex-grow-0">
              <textarea
                className="flow-interaction-layer-generator-textarea"
                value={generatorPrompt}
                placeholder="Describe the scenario, feature, or logic to add to your flow..."
                autoFocus={true}
                onChange={e => {
                  setGeneratorPrompt(e.target.value);
                }}
                />
              <div className="flow-interaction-layer-generator-footer list-right no-margin-bottom">
                <CustomButton
                  display="Cancel"
                  size="xs"
                  color="grey"
                  onClick={e => {
                    setGeneratorOpen(false);
                  }}
                  />
                <CustomButton
                  display="Generate"
                  size="xs"
                  color="success"
                  disabled={generatorPrompt.length === 0}
                  thinking={componentReducer.tryingToGenerateFlow}
                  success={componentReducer.generateFlowSuccess}
                  fail={componentReducer.generateFlowFail}
                  onClick={e => {
                    dispatch(tryToGenerateFlow({
                      id: id,
                      prompt: generatorPrompt,
                      name: componentReducer.cache[id].display_name || "Untitled",
                      description: componentReducer.cache[id].description || "No description",
                      flow: {
                        flow_nodes: nodes || [],
                        flow_links: links || []
                      }
                    }))
                  }}
                  />
              </div>
            </div>
          }
        </div>
      }
      
    </div>
  );
});

export default FlowInteractionLayer;
