import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomField from 'kit/components/CustomField/CustomField'
import MegaMarkdown from 'components/MegaMarkdown/MegaMarkdown';

export const NodeSettings_text = ({ node, setNodeSettings, canWrite }) => {

  const [settings, setSettings] = useState(node.settings || {});

  return (
    <div>
      <CustomField
        label="Text"
        value={settings.text}
        rows={10}
        canWrite={canWrite}
        placeholder="Enter text here..."
        description="This node will output the configured text."
        onChange={(e) => {
          setSettings({
            text: e.value,
          })
        }}
        onBlur={() => {
          setNodeSettings(settings);
        }}
        />
    </div>
  );
}


export const NodeRenderer_text = ({ node }) => {
  
  return (
    <div className="scroll-parent" style={{width: 250, height: 200}}>
      <div className="scroll-child padding-1rem thin-line-height">
        {/* <small>{node.settings.text}</small> */}
        <MegaMarkdown text={node.settings.text} />
      </div>
    </div>
  );
}