const compareComponentVersions = (c1, c2) => {
  let counters = {
    add: 0,
    edit: 0,
    remove: 0,
    none: 0
  }

  let changes = [];

  // if(!c1 || !c2) return {
  //   changes: changes,
  //   counters: counters
  // };


  if(!c1 || c1.id === 'draft') c1 = {};

  // changes.push({
  //   category: 'Metadata'
  // });

  // start with metadata stuff
  // changes.push({
  //   field: 'Name',
  //   // type: "text",
  //   old: c1.display_name,
  //   new: c2.display_name,
  //   kind: c1.display_name ? (c1.display_name === c2.display_name ? "none" : "edit") : "add",
  //   customInspectLink: '/workbench/:scope/agents/:id/settings'
  // });

  // if(!c1.description) c1.description = "";
  // if(!c2.description) c2.description = "";

  // changes.push({
  //   field: 'Description',
  //   // type: "text",
  //   old: c1.description,
  //   new: c2.description,
  //   kind: (c1.description === c2.description ? "none" : ((c2.description && !c1.description) ? "add" : (c2.description ? "edit" : "remove"))),
  //   customInspectLink: '/workbench/:scope/agents/:id/settings'
  // });


  const types = ['few_shots', 'variables', 'functions', 'plugins'];

  for(const contentType of types){
    if(!c1[contentType]) c1[contentType] = [];
    if(!c2[contentType]) c2[contentType] = [];
    for(const content of c1[contentType]){
      let change = {
        type: contentType,
        old: content,
        kind: 'none'
      }
      let new_content = c2[contentType].find(d => d.id === content.id);
      if(new_content){
        change.new = new_content;
        if(new_content.deleted){
          change.kind = 'remove';
        } else if(new_content.hash !== content.hash){
          change.kind = 'edit';
        }
      } else {
        change.kind = 'remove'
      }

      changes.push(change);
    }


    for(const content of c2[contentType]){
      if(changes.find((c) => {
        if(c.old){
          if(c.old.id === content.id) return true
        }
      })) continue;

      let change = {
        type: contentType,
        new: content
      }
      let old_content = c1[contentType].find(d => d.id === content.id);
      if(old_content){
        change.old = old_content;

        if(old_content.hash !== content.hash){
          change.kind = 'edit';
        }
      } else if(!content.deleted){
        change.kind = 'add'
      }

      // this helps prevents unchanged duplicates appearing twice
      if(change.kind){
        changes.push(change);
      } else {
        change.kind = 'none';
      }
    }
  }


  changes.push({
    field: 'Model',
    old: c1.model,
    new: c2.model,
    kind: (c1.model === c2.model ? "none" : ((c2.model && !c1.model) ? "add" : (c2.model ? "edit" : "remove"))),
    customInspectLink: '/workbench/:scope/agents/:id/model'
  });



  for(const c of changes){
    counters[c.kind]++;
  }

  // loop through content types and add up the changes here too
  let totalChanges = counters.add + counters.remove + counters.edit + counters.none;


  return {
    changes: changes,
    counts: counters
  }
}

export default compareComponentVersions;