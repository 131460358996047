import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

// import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

// import {
  
// } from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Get organization invites
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestGetOrganizationInvites = packet => {
  return {
    type: 'REQUEST_GET_ORGANIZATION_INVITES',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveGetOrganizationInvitesSuccess = data => ({
  type: 'RECEIVE_GET_ORGANIZATION_INVITES_SUCCESS',
  data
})

export const receiveGetOrganizationInvitesFail = data => ({
  type: 'RECEIVE_GET_ORGANIZATION_INVITES_FAIL',
  data
})

export const tryToGetOrganizationInvites = packet => (dispatch, getState) => {

  dispatch(requestGetOrganizationInvites(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  let params = {};
  if(packet.startAfter){
    params.startAfter = packet.startAfter;
  }

  return fetch('/api/organizations/invites/' + packet.id  + '?' + new URLSearchParams(params), {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveGetOrganizationInvitesSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveGetOrganizationInvitesFail({errors:e, lastSubmit: packet}));
    })
}
