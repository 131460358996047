import Cookies from 'js-cookie';
import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from './a.users.forceLogoutHandler';

import {
  tryToLogout
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Delete User Account
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestDeleteUserAccount = packet => {
  return {
    type: 'REQUEST_DELETE_USER_ACCOUNT',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveDeleteUserAccountSuccess = data => ({
  type: 'RECEIVE_DELETE_USER_ACCOUNT_SUCCESS',
  data
})

export const receiveDeleteUserAccountFail = data => ({
  type: 'RECEIVE_DELETE_USER_ACCOUNT_FAIL',
  data
})

export const tryToDeleteUserAccount = packet => (dispatch, getState) => {

  dispatch(requestDeleteUserAccount(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/users', {
      method: 'delete',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(tryToLogout());
      // dispatch(receiveDeleteUserAccountSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveDeleteUserAccountFail({errors:e, lastSubmit: packet}));
    })
}
