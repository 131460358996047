
export const memberReducer = (state = {
  cache: {
    projects: {},
    components: {},
    knowledge: {},
    inquiries: {}
  },
  addTeamMemberForm:    {errors: {}, lastSubmit: {}}
}, action) => {

  switch(action.type){

    

    case 'REQUEST_GET_TEAM':
      return {
        ...state,
        tryingToGetTeam: true
      }

    case 'RECEIVE_GET_TEAM_FAIL':
       return {
        ...state,
        tryingToGetTeam: false,
        getTeamFail: new Date().getTime()
      }

    case 'RECEIVE_GET_TEAM_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.kind][action.data.id] = action.data.data;

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToGetTeam: false,
        getTeamSuccess: new Date().getTime()
      }




    case 'REQUEST_ADD_TEAM_MEMBER':
      return {
        ...state,
        tryingToAddTeamMember: true,
        addTeamMemberForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_ADD_TEAM_MEMBER_FAIL':
       return {
        ...state,
        tryingToAddTeamMember: false,
        addTeamMemberFail: new Date().getTime(),
        addTeamMemberForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_ADD_TEAM_MEMBER_SUCCESS':
      
      var cache = state.cache;
      if(!cache[action.data.kind][action.data.id]) cache[action.data.kind][action.data.id] = [];
      cache[action.data.kind][action.data.id].unshift(action.data.data);

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToAddTeamMember: false,
        addTeamMemberSuccess: new Date().getTime(),
        addTeamMemberForm: {errors: {}, lastSubmit: {}}
      }


    case 'REQUEST_EDIT_TEAM_MEMBER':
      return {
        ...state,
        tryingToEditTeamMember: true,
        editTeamMemberForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_EDIT_TEAM_MEMBER_FAIL':
       return {
        ...state,
        tryingToEditTeamMember: false,
        editTeamMemberFail: new Date().getTime(),
        editTeamMemberForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_EDIT_TEAM_MEMBER_SUCCESS':
      
      var cache = state.cache;
      var index = cache[action.data.kind][action.data.id].findIndex(t => t.id === action.data.data.id);
      
      if(index > -1){
        cache[action.data.kind][action.data.id][index] = action.data.data;
      }

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToEditTeamMember: false,
        editTeamMemberSuccess: new Date().getTime(),
        editTeamMemberForm: {errors: {}, lastSubmit: {}}
      }



    case 'REQUEST_REMOVE_TEAM_MEMBER':
      return {
        ...state,
        tryingToRemoveTeamMember: true,
        removeTeamMemberForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_REMOVE_TEAM_MEMBER_FAIL':
       return {
        ...state,
        tryingToRemoveTeamMember: false,
        removeTeamMemberFail: new Date().getTime(),
        removeTeamMemberForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_REMOVE_TEAM_MEMBER_SUCCESS':
      
      var cache = state.cache;
      var index = cache[action.data.kind][action.data.id].findIndex(t => t.id === action.data.member_id);
      
      if(index > -1){
        cache[action.data.kind][action.data.id][index].splice(index, 1);
      }

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToRemoveTeamMember: false,
        removeTeamMemberSuccess: new Date().getTime(),
        removeTeamMemberForm: {errors: {}, lastSubmit: {}}
      }





    default:
      return state
  }
}
