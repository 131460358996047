import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import ZeroHeader from '../../sketches/ZeroHeader.js'

import './Footer.scss';
import version  from '../../version.js';

class Footer extends Component {
  render(){
    return <div className={"footer relative " + (this.props.inverted ? " footer-inverted " : "") + (this.props.simple ? " footer-simple " : "") + (this.props.noMargin ? " no-margin " : "") + (this.props.padless ? " footer-padless" : "")}>
      
      {
        this.props.simple ?
        <div className="flex-split">
          {this.props.customLeft || <span/>}
          <div className="list-right list-extra">
            <small>v{version}</small>
            <small><Link to="/contact" className="link-no-decoration text-400">Contact</Link></small>
            <small><Link to="/docs/policies/terms-of-service" className="link-no-decoration text-400">Terms</Link></small>
            <small><Link to="/docs/policies/privacy" className="link-no-decoration text-400">Privacy</Link></small>
            <small><a href="https://zerowidth.statuspage.io" target="_blank" className="link-no-decoration text-400">Status</a></small>
            <small><Link to="/blog" className="link-no-decoration text-400">Blog</Link></small>
            <small className="">
              © ZeroWidth {new Date().getFullYear()}
            </small>
          </div>
        </div>
        :
        <div className="container footer-big">
          <img src="/img/zebra-black.webp" className="footer-zebra"/>
          <div className="row justify-content-center margin-top-3rem margin-bottom-3rem">
            <div className="col-md-6">
              <div className="spacer-3rem"/>
              <div className="list-right">
                <img src={this.props.inverted ? "/img/cascading-z-white.png" : "/img/cascading-z.png"} width="50px"/>
                <h1 className="text-brand no-margin-top no-margin-bottom margin-left-1rem">
                  ZeroWidth
                </h1>
              </div>
              <div className="spacer-1rem"/>
              <div className="list-right">
                <a href="https://discord.gg/ypcwTZeWxX" target="_blank"><i className="fab fa-discord fa-2x fa-fw"/></a>
                <a href="https://www.linkedin.com/company/zerowidth" target="_blank"><i className="fab fa-linkedin fa-2x fa-fw"/></a>
                <a href="https://www.youtube.com/channel/UCAFB_ciSIi3q0rAssPhBwTA" target="_blank"><i className="fab fa-youtube fa-2x fa-fw"/></a>
                <a href="https://twitter.com/zerowidth_ai" target="_blank"><i className="fab fa-twitter fa-2x fa-fw"/></a>
              </div>
            </div>
            <div className="col-md-3 text-right">
              <h3>Legal</h3>
              <p>
                <Link to="/docs/policies/terms-of-service">Terms</Link>
              </p>
              <p>
                <Link to="/docs/policies/privacy">Privacy</Link>
              </p>
              <p>
                <Link to="/docs/policies/data-usage-policy">Data Usage</Link>
              </p>
              <p>
                <Link to="/docs/policies/data-subprocessors">Subprocessors</Link>
              </p>
            </div>
            <div className="col-md-3 text-right">
              <h3>Links</h3>
              <p>
                <Link to="/blog">Blog</Link>
              </p>
              <p>
                <Link to="/contact">Contact</Link>
              </p>
              <p>
                <Link to="/waitlist">Waitlist</Link>
              </p>
              <p>
                <a href="https://zerowidth.statuspage.io" target="_blank" className="link-no-decoration text-400">Status</a>
              </p>
            </div>
          </div>
          <div className="flex-split ">
            <span/>
            <div className="list-right list-extra">
              <small className="text-muted">
                © ZeroWidth, LLC {new Date().getFullYear()}
              </small>
            </div>
          </div>
        </div>
      }
      
    </div>
  }
}

export default Footer
  