import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'


////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Process follow-up feedback for a project description
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestInquiryFoundationsFeedbackFollowUp = packet => {
  return {
    type: 'REQUEST_INQUIRY_FOUNDATIONS_FEEDBACK_FOLLOW_UP',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveInquiryFoundationsFeedbackFollowUpSuccess = data => ({
  type: 'RECEIVE_INQUIRY_FOUNDATIONS_FEEDBACK_FOLLOW_UP_SUCCESS',
  data
})

export const receiveInquiryFoundationsFeedbackFollowUpFail = data => ({
  type: 'RECEIVE_INQUIRY_FOUNDATIONS_FEEDBACK_FOLLOW_UP_FAIL',
  data
})

export const tryToInquiryFoundationsFeedbackFollowUp = packet => (dispatch, getState) => {

  dispatch(requestInquiryFoundationsFeedbackFollowUp(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = Cookies.get(process.env.REACT_APP_COOKIE_FEATURE_SWITCH)
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/inquiries/foundations-feedback/' + packet.inquiry_id + '/' + packet.pillar + '/follow-up', {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveInquiryFoundationsFeedbackFollowUpSuccess({
        request: packet,
        response: json
      }));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveInquiryFoundationsFeedbackFollowUpFail({errors:e, lastSubmit: packet}));
    })
}
