import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {
  tryToGetProjectsByOrganization,
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Create a project
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestCreateNewProject = packet => {
  return {
    type: 'REQUEST_CREATE_NEW_PROJECT',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveCreateNewProjectSuccess = data => ({
  type: 'RECEIVE_CREATE_NEW_PROJECT_SUCCESS',
  data
})

export const receiveCreateNewProjectFail = data => ({
  type: 'RECEIVE_CREATE_NEW_PROJECT_FAIL',
  data
})

export const tryToCreateNewProject = packet => (dispatch, getState) => {

  dispatch(requestCreateNewProject(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/projects/organization/' + packet.scope, {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveCreateNewProjectSuccess(json));
      dispatch(tryToGetProjectsByOrganization({org_id: json.scope}));
      dispatch(push('/workbench/' + json.scope + '/projects/' + json.id));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveCreateNewProjectFail({errors:e, lastSubmit: packet}));
    })
}
