import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './ForceGraph.scss';

const ForceGraph = ({ nodes, links, onNodeClick, selectedNodeId }) => {
    const svgRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 1000, height: 1000 });
    const [currentZoom, setCurrentZoom] = useState(d3.zoomIdentity);
    const simulationRef = useRef(null);
    const containerRef = useRef(null);  // Ref for the container g element
    const overlayRef = useRef(null);  // Ref for the container g element
    const compassArrowRef = useRef(null);  // Ref for the container g element
    const nodeContainerRef = useRef(null);  // Ref for the container g element
    const linkContainerRef = useRef(null);  // Ref for the container g element
    const [currentNodes, setCurrentNodes] = useState([]);
    const [currentLinks, setCurrentLinks] = useState([]);
    const [updateCounter, setUpdateCounter] = useState(0);
    const canvasSize = 10000;
    const compassSize = 5;
    const arrowSize = 10;

    const wrap = (text, text_width) => {
        
      text.each(function() {
          var text = d3.select(this),
              words = text.text().split(/\s+/).reverse(),
              word,
              line = [],
              lineNumber = 0,
              y = text.attr("y"),
              dy = parseFloat(text.attr("dy")) || 0,
              tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em"),
              level = parseFloat(text.attr("level"));

          var lineHeightPx = 1.2 * (4 + 12 / (1 + level)); // Assuming font-size is 12px

          text_width = text_width - 4 * (level + 1);
          if(text_width < 10) text_width = 10;
  
          while (word = words.pop()) {
              line.push(word);
              tspan.text(line.join(" "));
              if (tspan.node().getComputedTextLength() > text_width) {
                  line.pop();
                  tspan.text(line.join(" "));
                  line = [word];
                  lineNumber++;
                  tspan = text.append("tspan").attr("x", 0).attr("y", y)
                              .attr("dy", (lineHeightPx) + "px").text(word);
              }
          }
  
          // Adjust the initial y position based on the number of lines
          const lines = text.selectAll("tspan").size();
          text.attr('transform', `translate(0, ${-(lines) * lineHeightPx - (6 + 25 / (level + 1))})`);
      });
    }

    // Define the drag behavior
    const drag = simulation => {
        function dragstarted(event) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            event.subject.fx = event.subject.x;
            event.subject.fy = event.subject.y;
        }

        function dragged(event) {
            event.subject.fx = event.x;
            event.subject.fy = event.y;
        }

        function dragended(event, d) {
            if (!event.active) simulation.alphaTarget(0);
            event.subject.fx = null;
            event.subject.fy = null;

            onNodeClick(d);
        }

        return d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);
    };

    useEffect(() => {
        // Resize observer to update dimensions
        const observeTarget = svgRef.current;
        const resizeObserver = new ResizeObserver(entries => {
            if (!entries || entries.length === 0) {
                return;
            }
            const entry = entries[0];
            setDimensions({
                width: entry.contentRect.width,
                height: entry.contentRect.height
            });
            overlayRef.current.attr('transform', `translate(${-entry.contentRect.width / 2 + compassSize + 20 }, ${entry.contentRect.height / 2 - compassSize - 20})`);
        });

        // Start observing
        if (observeTarget) {
            resizeObserver.observe(observeTarget);
        }

        // Cleanup
        return () => {
            if (observeTarget) {
                resizeObserver.unobserve(observeTarget);
            }
        };
    }, [svgRef]);

    useEffect(() => {
      const { width, height } = dimensions;

      // Check for valid dimensions
      if (width === 0 || height === 0) return;

      // Update the viewBox of the SVG to match the new dimensions
      const svg = d3.select(svgRef.current)
          .attr("viewBox", [-width / 2, -height / 2, width, height]);
  
      // Update the positions or sizes of elements if necessary
      // For example, repositioning the center force:
      if (simulationRef.current) {
        simulationRef.current.alpha(0.3).restart(); // Reheat and restart the simulation
      }

  
    }, [dimensions]); 

    useEffect(() => {
      if(!simulationRef.current) return;

      const { width, height } = dimensions;

      // Update positions on tick
      simulationRef.current.on("tick", () => {
      
        linkContainerRef.current.selectAll(".link")
            .attr("x1", d => d.source.x)
            .attr("y1", d => d.source.y)
            .attr("x2", d => d.target.x)
            .attr("y2", d => d.target.y);

        nodeContainerRef.current.selectAll(".node-group")
            .attr("transform", d => {
            return `translate(${d.x}, ${d.y})`});

    
        compassArrowRef.current.selectAll('.vpc-node-arrow')
          .attr('x1', 0) // Start x-coordinate at the group's origin
          .attr('y1', 0) // Start y-coordinate at the group's origin
          .attr('x2', d => {
              let compass_angle = calculateAngle(
                  -width / 2 + compassSize + 20, height / 2 - compassSize - 20,
                  d.x, d.y, currentZoom
              );
      
              return (arrowSize + compassSize) * Math.cos(compass_angle); // End x-coordinate
          })
          .attr('y2', d => {
              let compass_angle = calculateAngle(
                  -width / 2 + compassSize + 20, height / 2 - compassSize - 20,
                  d.x, d.y, currentZoom
              );
      
              return (arrowSize + compassSize) * Math.sin(compass_angle); // End y-coordinate
          });
      });

    }, [currentZoom, dimensions])

    useEffect(() => {
      const { width, height } = dimensions;
  
      if (width === 0 || height === 0) return;
  
      // Remove any previous SVG contents
      d3.select(svgRef.current).selectAll("*").remove();
  
      // Create SVG and container elements
      const svg = d3.select(svgRef.current)
          .attr("viewBox", [-width / 2, -height / 2, width, height])
          .on('click', () => onNodeClick(null));
  
      containerRef.current = svg.append("g");
      
      overlayRef.current = svg.append('g')
        .attr('transform', `translate(${-width / 2 + compassSize + 20 }, ${height / 2 - compassSize - 20})`);

      compassArrowRef.current = overlayRef.current.append('g');

      overlayRef.current.append('circle')
        .attr('r', compassSize)
        .attr('class', 'vpc-compass-circle')
        ;

      const defs = svg.append('defs')
    
      defs.append('marker')
        .attr('id', 'arrowhead')
        .attr('markerWidth', 10)
        .attr('markerHeight', 7)
        .attr('refX', 1) // Adjust refX to move the tip a bit back
        .attr('refY', 3.5)
        .attr('orient', 'auto')
        .append('polygon')
            .attr('points', '0 0, 8 3.5, 0 7'); // Adjust tip point to make less pointy

      defs.append('pattern')
        .attr('id', 'dotGrid')
        .attr('width', 20)
        .attr('height', 20)
        .attr('patternUnits', 'userSpaceOnUse')
        .append('circle')
        .attr('cx', 10)
        .attr('cy', 10)
        .attr('r', 1)
        .attr('fill', 'black')
        .attr('opacity', .25)
        ;

      containerRef.current.append('rect')
        .attr('class', 'force-graph-background')
        .attr('x', -canvasSize/2)
        .attr('y', -canvasSize/2)
        .attr('width', canvasSize)
        .attr('height', canvasSize)
        .attr('fill', 'url(#dotGrid)')
        ;
      
      linkContainerRef.current = containerRef.current.append("g");
      nodeContainerRef.current = containerRef.current.append("g");

  
      
      // Initialize the force simulation
      simulationRef.current = d3.forceSimulation(nodes)
        .force("link", d3.forceLink(links).id(d => d.id).distance(100).strength(1.0))
        .force("charge", d3.forceManyBody().strength(-350))
        .force("center", d3.forceCenter(0, 0).strength(0.2))
        .force("collide", d3.forceCollide().radius(30));

      
    
      return () => {
        if (simulationRef.current) {
          simulationRef.current.stop();
        }
      };      
    }, [])

    useEffect(() => {
      const { width, height } = dimensions;

      // Define and apply zoom behavior
      const zoom = d3.zoom()
          .scaleExtent([0.25, 5])
          .translateExtent([[-canvasSize/2, -canvasSize/2], [canvasSize/2, canvasSize/2]]) // Restricts panning to the 5000x5000 area      
          .on("zoom", (event) => {
            containerRef.current.attr('transform', event.transform);
            setCurrentZoom(event.transform);

            updateArrows(event.transform); // Update arrows based on the latest positions
          });
  
          d3.select(svgRef.current).call(zoom)
         .call(zoom.transform, currentZoom);
  
    }, [nodes, links]);

    const updateArrows = (zoomToUse) => {
      const { width, height } = dimensions;
      
      compassArrowRef.current.selectAll('.vpc-node-arrow')
          .attr('x2', d => {
              const node = containerRef.current.selectAll('.force-graph-node').data().find(n => n.id === d.id);
              if (node) {
                let compass_angle = calculateAngle(-width / 2 + compassSize + 20, height / 2 - compassSize - 20, node.x, node.y, zoomToUse);
                return (arrowSize + compassSize) * Math.cos(compass_angle);
              }
              return 0;
          })
          .attr('y2', d => {
              const node = containerRef.current.selectAll('.force-graph-node').data().find(n => n.id === d.id);
              if (node) {
                  let compass_angle = calculateAngle(-width / 2 + compassSize + 20, height / 2 - compassSize - 20, node.x, node.y, zoomToUse);
                  return (arrowSize + compassSize) * Math.sin(compass_angle);
              }
              return 0;
          });
    };

    
    useEffect(() => {

      const { width, height } = dimensions;

      var localNodes = [...currentNodes];
      var localLinks = [...currentLinks];


      if (!simulationRef.current || !containerRef.current) return;
  
      // Update the simulation's nodes
      simulationRef.current.nodes(localNodes);

      // Update the simulation's link force
      simulationRef.current.force("link").links(localLinks);

      // Reheat and restart the simulation
      // simulationRef.current.alpha(0.4).restart();
      // Softly heat the simulation for a smooth start
      simulationRef.current.alphaTarget(.1).restart();
    
      // Gradually reduce the alpha decay for a smoother transition
      simulationRef.current.alphaDecay(0.05);
      
      // Update SVG elements for links
      const linkSelection = linkContainerRef.current.selectAll(".link")
        .data(localLinks, d => `${d.source.id}-${d.target.id}`);

      // Enter + update for links
      const linkEnter = linkSelection.enter()
        .append("line")
        .attr("class", "link");

      linkEnter.merge(linkSelection)
        .attr("stroke-width", d => Math.sqrt(d.value));

      // Exit for links
      linkSelection.exit().remove();

      // Update SVG elements for nodes
      const nodeGroupSelection = nodeContainerRef.current.selectAll(".node-group")
        .data(localNodes, d => d.id);

      // Enter for node groups
      const nodeEnter = nodeGroupSelection.enter()
        .append("g")
        .attr("class", "node-group")
        .call(drag(simulationRef.current));

      // Append and update circles for nodes
      const circle = nodeEnter.append("circle")
        .merge(nodeGroupSelection.select("force-graph-node"))
        .attr("r", d => d.level ? 10+ 30 / (d.level + 1) : 40)
        .attr('class', d => {
          let retval = 'force-graph-node ';
          if(selectedNodeId === d.id) retval += 'force-graph-node--selected';
          if(d.level === 0) retval += ' force-graph-node--root';
          if(d.priority) retval += ' force-graph-node--' + d.priority;

          return retval;
        })
        .on('click', (e, d) => {
            e.stopPropagation();  // Stop the event from propagating up to the SVG
            onNodeClick(d);
        });

      // Append and update text for nodes
      const text = nodeEnter.append("text")
        .merge(nodeGroupSelection.select("text"))
        .text(d => d.name)
        .attr('class', d => 'force-graph-node-label ' + (selectedNodeId === d.id ? 'force-graph-node-label--selected' : ''))
        .style("text-anchor", "middle")
        .attr('font-size', d => (4 + 12 / (d.level + 1)))
        .attr("level", d => d.level)
        .call(wrap, 120);

      // Exit for nodes
      nodeGroupSelection.exit()
        .transition().duration(500)
        .style("opacity", 0)
        .remove();

      // var arrowGroup = compassArrowRef.current.selectAll(".vpc-node-arrows-group")
      //   .data(localNodes, d => d.id)
      //   .enter();

      // arrowGroup.append('g')
      //   .attr('class', d => {
      //     let retval = 'vpc-node-arrows-group ';
      //     if(selectedNodeId === d.id) retval += 'vpc-node-group--selected';
      //     return retval;
      //   })
      //   ;
              
      // arrowGroup.append('line')
      //   .attr('class', 'vpc-node-arrow')
      //   .attr('x1', 0)
      //   .attr('y1', 0)
      //   .attr('marker-end', 'url(#arrowhead)')
      //   .attr('x2', d => {
      //     let compass_angle = calculateAngle(-width / 2 + compassSize + 20, height / 2 - compassSize - 20, d.x, d.y, currentZoom);
      //     return (arrowSize + compassSize) * Math.cos(compass_angle)
      //   })
      //   .attr('y2', d => {
      //     let compass_angle = calculateAngle(-width / 2 + compassSize + 20, height / 2 - compassSize - 20, d.x, d.y, currentZoom);
      //     return (arrowSize + compassSize) * Math.sin(compass_angle)
      //   })
      //   ;

      // arrowGroup.exit().remove();

      var arrowGroup = compassArrowRef.current.selectAll(".vpc-node-arrows-group")
        .data(localNodes, d => d.id);

      arrowGroup.join(
          enter => {
              const group = enter.append('g')
                  .attr('class', d => {
                      let retval = 'vpc-node-arrows-group ';
                      if (selectedNodeId === d.id) retval += 'vpc-node-arrows-group--selected';
                      return retval;
                  });

              group.append('line')
                  .attr('class', 'vpc-node-arrow')
                  .attr('x1', 0)
                  .attr('y1', 0)
                  .attr('marker-end', 'url(#arrowhead)')
                  .attr('x2', d => (arrowSize + compassSize) * Math.cos(calculateAngle(-width / 2 + compassSize + 20, height / 2 - compassSize - 20, d.x, d.y, currentZoom)))
                  .attr('y2', d => (arrowSize + compassSize) * Math.sin(calculateAngle(-width / 2 + compassSize + 20, height / 2 - compassSize - 20, d.x, d.y, currentZoom)));

              return group;
          },
          update => {
              // Update existing elements as needed
              return update
                  .select('.vpc-node-arrow')
                  .attr('x2', d => (arrowSize + compassSize) * Math.cos(calculateAngle(-width / 2 + compassSize + 20, height / 2 - compassSize - 20, d.x, d.y, currentZoom)))
                  .attr('y2', d => (arrowSize + compassSize) * Math.sin(calculateAngle(-width / 2 + compassSize + 20, height / 2 - compassSize - 20, d.x, d.y, currentZoom)));
          },
          exit => exit.remove()
      );


      // if this is basically the first time we're running this, run it a bunch of times to get it to settle
      if(currentNodes.length > 1 && updateCounter === 0){
        const numTicks = 150; // You can adjust this number based on your graph's complexity
        for (let i = 0; i < numTicks; i++) {
          simulationRef.current.tick();
        }
        setUpdateCounter(updateCounter + 1);
      }

    }, [currentNodes, currentLinks]); // Dependencies array includes nodes, links, selectedNodeId, and onNodeClick


    useEffect(() => {

      let nodesChanged = false;
      let linksChanged = false;

      var newNodes = [];

      for(let i in nodes){
        // find currentNode by id
        let currentNode = currentNodes.find(n => n.id === nodes[i].id);
        if(!currentNode){
          nodesChanged = true;

          newNodes.push(nodes[i]);
        } else {
          if(nodes[i].name !== currentNode.name || nodes[i].description !== currentNode.description || nodes[i].priority !== currentNode.priority){
            nodesChanged = true;
          
            let newNode = {
              ...currentNode,
              ...nodes[i]
            }

            newNodes.push(newNode);
            
          } else {
            newNodes.push(currentNode);
          }
        }
      }

      if(nodesChanged || newNodes.length !== currentNodes.length){
        setCurrentNodes(newNodes);
        setCurrentLinks(links);
      }

    }, [nodes, links])


    useEffect(() => {

      const nodes = d3.select(svgRef.current)
        .selectAll('.force-graph-node')
        .attr('class', d => {
          let retval = 'force-graph-node ';
          if(selectedNodeId === d.id) retval += 'force-graph-node--selected';
          if(d.level === 0) retval += ' force-graph-node--root';
          if(d.priority) retval += ' force-graph-node--' + d.priority;

          return retval;
        })
        ;

      const labels = d3.select(svgRef.current)
        .selectAll('.force-graph-node-label')
        .attr('class', d => 'force-graph-node-label ' + (selectedNodeId === d.id ? 'force-graph-node-label--selected' : ''))
        ;

    }, [selectedNodeId])
    
    const calculateAngle = (overlayX, overlayY, targetX, targetY, zoom) => {
      // Reverse the zoom and pan transformations

      const translatedX = (targetX ) * zoom.k + zoom.x;
      const translatedY = (targetY ) * zoom.k + zoom.y;

      // Calculate the angle in radians
      const dx = translatedX - overlayX;
      const dy = translatedY - overlayY;
      const angle = Math.atan2(dy, dx);

      return angle;
    };

    return <svg className="force-graph" ref={svgRef} style={{ width: '100%', height: '100%' }}>
      <defs>
      <pattern id="grid" width="50" height="50" patternUnits="userSpaceOnUse">
          <path d="M 50 0 L 0 0 0 50" fill="none" stroke="#000" strokeWidth="1"/>
          <path d="M 0 50 L 50 50 50 0" fill="none" stroke="#000" strokeWidth="1"/>
      </pattern>

      </defs>
    </svg>;
};

export default ForceGraph;
