import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import shortid from 'shortid';

import FlowNodeThumbnail from 'components/FlowBuilder/FlowNodeThumbnail';
import FlowInteractionLayer from 'components/FlowBuilder/FlowInteractionLayer';

import Dropdown from 'kit/components/Dropdown/Dropdown';

import { 
  tryToSetComponentFlow,
  flowZoomToFit,
  setComponentNeedsSave,
  bulkUpdateComponent
 } from 'actions/actions.export';

import './ComponentFlow.scss';

import CustomSelect from 'kit/components/CustomSelect/CustomSelect';

import nodeCategories from 'configs/config.node-categories';

let saveTimeout;

const ComponentFlow = ({ id, version = 'draft', canWrite }) => {
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedLinks, setSelectedLinks] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [links, setLinks] = useState([]);
  const nodesRef = useRef([]);
  const linksRef = useRef([]);
  const [needsSave, setNeedsSave] = useState(false);
  
  const hasDoneInitialZoomRef = useRef(false);

  const dispatch = useDispatch();
  const componentReducer = useSelector(state => state.componentReducer);
  const intelReducer = useSelector(state => state.intelReducer);
  const guiReducer = useSelector(state => state.guiReducer);

  const componentSelector = useSelector(state => state.componentReducer.cache[id].socketUpdated);

  const [toolNavMode, setToolNavMode] = useState('buttons');


  // Load initial data
  useEffect(() => {
    
    const component = componentReducer.cache[id];
    if (component) {

      // find version
      const versionData = component.versions.find(v => v.id === version);
      if (versionData) {
        setLinks(versionData.flow_links || []);
        setNodes((versionData.flow_nodes || []).filter(n => n.type));

        if(!hasDoneInitialZoomRef.current){
          setTimeout(()=>{
            dispatch(flowZoomToFit());
            hasDoneInitialZoomRef.current = true;
          }, 100);
        }
        
      }
    }
  }, [id, componentReducer.cache, componentSelector]);

  const saveChanges = () => {
    
    dispatch(tryToSetComponentFlow({
      id: id,
      flow_nodes: nodesRef.current,
      flow_links: linksRef.current
    }));
    setNeedsSave(false);
  };

  useEffect(() => {
    nodesRef.current = nodes;
    linksRef.current = links;
  }, [nodes, links]);

  // Debounced save logic
  useEffect(() => {
    clearTimeout(saveTimeout);
    if(needsSave){
      saveTimeout = setTimeout(saveChanges, 1000);
      dispatch(setComponentNeedsSave({
        id: id,
        needsSave: true
      }));
    }
  }, [needsSave, nodes, links, dispatch, id]);

  // Render the component
  if (!componentReducer.cache[id]) {
    return <span>Failed to load.</span>;
  }

  let foundNode;
  let foundNodeType;
  let foundLink;
  
  if(selectedNodes.length > 0){
    foundNode = nodes.find(node => node.id === selectedNodes[0]);
    foundNodeType = intelReducer.flow_nodes.find(node => node.name === foundNode?.type);  
  }

  if(selectedLinks.length > 0){
    foundLink = links.find(link => link.source === selectedLinks[0].source && link.target === selectedLinks[0].target);
  }
  
  let categories = JSON.parse(JSON.stringify(nodeCategories));


  // for each flow node, add to the categories array that it fits into
  if (intelReducer.flow_nodes) {
    intelReducer.flow_nodes.forEach(flowNode => {
      let category = categories.find(cat => cat.name === flowNode.category);
      if (!category) {
        category = {
          name: flowNode.category,
          display_name: flowNode.category
        };
        categories.push(category);
      }
      if (!category.nodes) {
        category.nodes = [];
      }
      if(category){
        category.nodes.push(flowNode);
      }
    });
  }


  const tools = [];

  // for each category, add a tool, we'll then dropdown the nodes in that category
  categories.forEach(category => {
    tools.push({
      icon: 'fa-' + category.icon,
      items: category.nodes,
      onClick: () => {}
    });
  })



  return (
    <div className="component-flow flex-column-stretch  flex-grow">
      
      <div className="padding-1rem flex-column-stretch flex-grow">
     
        <div className="workbench-nested-bookmark-nav-bumper"/>
        <div className={"list-left list-left-align-flex-start flex-grow " + (guiReducer.draggingGUIState ? "text-muted no-pointer-events" : "")}>
          {
            canWrite &&
              <div className="component-flow-tools no-margin">

                <div className="box box-no-pad box-light-border">
                  <div className={"component-flow-tool"} onClick={e=>{
                    if(toolNavMode === 'buttons'){
                      setToolNavMode('search');
                    } 
                  }}>
                    <div className="component-flow-tool-icon" onClick={e=>{
                      e.stopPropagation();
                      if(toolNavMode === 'buttons'){
                        setToolNavMode('search');
                      }  else {
                        setToolNavMode('buttons');
                      }
                    }}>
                      {
                        toolNavMode === 'search' ?
                        <i className={'far fa-fw fa-times'}/>
                        :
                        <i className={'far fa-fw fa-search'}/>
                      }
                    </div>
                  </div>
                  <hr className="hr-mini"/>
                  {
                    tools.map((tool,index) => {

                      if(tool === 'divider') return <hr key={index}/>;
                      
                      return <Dropdown 
                        offsetX={35}
                        offsetY={-6}
                        minWidth={400}
                        maxHeight={400}
                        key={index}
                        items={tool.items.map((node, index2) => {
                          return <div className="list-left list-left-no-wrap padding-05rem  " key={index2}
                            onClick={() => {
                              const newNode = {
                                id: shortid.generate(),
                                type: node.name,
                                settings: {}
                              };
                              
                              dispatch(bulkUpdateComponent({
                                id: id,
                                events: [{
                                  // jsonpath format to flow_links array 
                                  path: `$.flow_nodes`,
                                  changes: newNode,
                                  operation: 'push'
                                }]
                              }));
                            }}>
                              <FlowNodeThumbnail nodeType={node} size={65} category={categories.find(cat => cat.name === node.category)}/>
                              <div className="text-ellipsis-3-lines thin-line-height">
                                <div className="text-900">
                                  {node.display_name}
                                </div>
                                <small className="thin-line-height text-muted">{node.description}</small>
                              </div>
                            </div>
                        })}
                        target={<div className={"component-flow-tool"} key={index} onClick={tool.onClick}>
                          <i className={'far fa-fw ' + tool.icon}/>
                        </div>}
                        />
                    })
                  }
                </div>
              
          
            </div>
          } 
          {
            (canWrite && toolNavMode === 'search') &&
            <div className="component-flow-tool-search-bar">
              <div key={nodes.length}>
                <CustomSelect
                  inline={true}
                  autoFocus={true}
                  minWidth={300}
                  width={300}
                  value={undefined}
                  placeholder={`Search all ${intelReducer.flow_nodes.length} available nodes...`}
                  options={intelReducer.flow_nodes.map((node, index) => {
                    return {
                      label: <div className="list-left list-left-no-wrap padding-05rem " key={index}>
                        <FlowNodeThumbnail nodeType={node} size={65} category={categories.find(cat => cat.name === node.category)}/>
                        <div className="text-ellipsis-3-lines thin-line-height">
                          <div className="text-900">
                            {node.display_name}
                          </div>
                          <small className="thin-line-height text-muted">{node.description}</small>
                        </div>
                      </div>,
                      value: node.name + ' / ' + node.display_name + ' / ' + node.description
                    }
                  })}
                  onChange={(e) => {
                    const newNode = {
                      id: shortid.generate(),
                      type: e.split(' / ')[0],
                      settings: {}
                    };

                    dispatch(bulkUpdateComponent({
                      id: id,
                      events: [{
                        // jsonpath format to flow_links array 
                        path: `$.flow_nodes`,
                        changes: newNode,
                        operation: 'push'
                      }]
                    }));

                  }}
                  />
              </div>
            </div>
          }

        </div>
      </div>

      <div className="component-flow-graph">

        <FlowInteractionLayer
          id={id}
          org_id={componentReducer.cache[id].scope}
          initialNodes={intelReducer.flow_nodes.length > 0 ? nodes : []}
          initialLinks={intelReducer.flow_nodes.length > 0 ? links : []}
          onChange={() => {
            clearTimeout(saveTimeout);
            setNeedsSave(true);
          }}
          onSelectNodes={(selectedNodes) => {
            setSelectedNodes(selectedNodes);
          }}
          />
        
      </div>
    </div>
  );
};

export default ComponentFlow;


