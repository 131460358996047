import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Edit file
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestUpdateFile = packet => {
  return {
    type: 'REQUEST_UPDATE_FILE',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveUpdateFileSuccess = data => ({
  type: 'RECEIVE_UPDATE_FILE_SUCCESS',
  data
})

export const receiveUpdateFileFail = data => ({
  type: 'RECEIVE_UPDATE_FILE_FAIL',
  data
})

export const tryToUpdateFile = packet => (dispatch, getState) => {

  dispatch(requestUpdateFile(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/knowledge/' + packet.knowledge_id + '/files/' + packet.id, {
      method: 'put',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveUpdateFileSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveUpdateFileFail({errors:e, lastSubmit: packet}));
    })
}
