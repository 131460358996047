import Cookies from 'js-cookie';

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// SEND RESET PASSWORD LINK
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestSendPasswordResetLink = packet => {
  return {
    type: 'REQUEST_SEND_PASSWORD_RESET_LINK',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveSendPasswordResetLinkSuccess = data => ({
  type: 'RECEIVE_SEND_PASSWORD_RESET_LINK_SUCCESS',
  data
})

export const receiveSendPasswordResetLinkFail = data => ({
  type: 'RECEIVE_SEND_PASSWORD_RESET_LINK_FAIL',
  data
})

export const tryToSendPasswordResetLink = packet => (dispatch, getState) => {

  dispatch(requestSendPasswordResetLink(packet));

  let headers = {
    "Content-Type": "application/json",
  }

  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/users/send-password-reset-link', {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveSendPasswordResetLinkSuccess(json.data));
    })
    .catch(e => {
      dispatch(receiveSendPasswordResetLinkFail({errors:e, lastSubmit: packet}));
    })
}
