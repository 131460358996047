
export const intelReducer = (state = {
  llms: [],
  plugins: [],
  embedding_models: [],
  flow_nodes: [],
  generateEmbeddingForm: {errors: {}, lastSubmit: {}},
  getLLmsForm: {errors: {}, lastSubmit: {}},
  mostRecentEmbedding: {}
}, action) => {

  switch(action.type){
    

    case 'REQUEST_GENERATE_EMBEDDING':
      return {
        ...state,
        tryingToGenerateEmbedding: true,
        generateEmbeddingForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GENERATE_EMBEDDING_FAIL':
       return {
        ...state,
        tryingToGenerateEmbedding: false,
        generateEmbeddingFail: new Date().getTime(),
        generateEmbeddingForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GENERATE_EMBEDDING_SUCCESS':

      return {
        ...state,
        mostRecentEmbedding: action.data,
        tryingToGenerateEmbedding: false,
        generateEmbeddingSuccess: new Date().getTime(),
        generateEmbeddingForm: {errors: {}, lastSubmit: {}}
      }



    case 'REQUEST_GET_MODELS':
      return {
        ...state,
        tryingToGetModels: true,
        getModelsForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GET_MODELS_FAIL':
       return {
        ...state,
        tryingToGetModels: false,
        getModelsFail: new Date().getTime(),
        getModelsForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GET_MODELS_SUCCESS':

      return {
        ...state,
        llms: action.data.llms,
        image_models: action.data.image_models,
        embedding_models: action.data.embedding_models,
        tryingToGetModels: false,
        getModelsSuccess: new Date().getTime(),
        getModelsForm: {errors: {}, lastSubmit: {}}
      }


    

    case 'REQUEST_GET_PLUGINS':
      return {
        ...state,
        tryingToGetPlugins: true,
        getPluginsForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GET_PLUGINS_FAIL':
        return {
        ...state,
        tryingToGetPlugins: false,
        getPluginsFail: new Date().getTime(),
        getPluginsForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GET_PLUGINS_SUCCESS':

      return {
        ...state,
        plugins: action.data.plugins,
        tryingToGetPlugins: false,
        getPluginsSuccess: new Date().getTime(),
        getPluginsForm: {errors: {}, lastSubmit: {}}
      }

  
    case 'REQUEST_GET_FLOW_NODES':
      return {
        ...state,
        tryingToGetFlowNodes: true,
        getFlowNodesForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GET_FLOW_NODES_FAIL':
        return {
        ...state,
        tryingToGetFlowNodes: false,
        getFlowNodesFail: new Date().getTime(),
        getFlowNodesForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GET_FLOW_NODES_SUCCESS':

      return {
        ...state,
        flow_nodes: action.data.flow_nodes,
        tryingToGetFlowNodes: false,
        getFlowNodesSuccess: new Date().getTime(),
        getFlowNodesForm: {errors: {}, lastSubmit: {}}
      }
  

    default:
      return state
  }
}
