import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux';
import moment from 'moment'
import ReactJson from 'react-json-view'

import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'

import 'react-reflex/styles.css'

import CustomButton from '../kit/components/CustomButton/CustomButton.js'
import CustomField from '../kit/components/CustomField/CustomField.js'
import CustomSelect from '../kit/components/CustomSelect/CustomSelect.js'

import ComponentChat from '../components/ComponentChat/ComponentChat.js'
import ComponentMadlib from '../components/ComponentMadlib/ComponentMadlib.js'

import BetterChat from 'components/BetterChat/BetterChat.js';
import BetterPrompt from 'components/BetterPrompt/BetterPrompt.js';
import BetterContextOnly from 'components/BetterContextOnly/BetterContextOnly.js';

import Modal from '../kit/components/Modal/Modal.js'

import Hydrate from '../components/Hydrate/Hydrate.js'
import Footer from '../components/Footer/Footer.js'
import CodeHighlighter from 'components/CodeHighlighter/CodeHighlighter.js';
import ASCIIBackground from 'components/ASCIIBackground/ASCIIBackground.js';

import FlowRenderer from 'components/FlowBuilder/FlowRenderer.js';

import nodeCategories from 'configs/config.node-categories.js';


import {
  setCurrentComponent,
  unsetCurrentComponent,
  tryToGetComponentVersionToDemo,
  tryToDemoComponent,
  tryToAuthenticateDemo,
  showTooltip,
  hideTooltip,
  tryToGetFlowNodes,
  flowZoomToFit
} from '../actions/actions.export'


const mobileBreakpoint = 768;

class DemoComponentRoute extends Component {
  constructor(props){
    super(props)

    this.state = {
      mode: 'agent',
      showSubpageMenu: true,
      readOnly: true,
      demo_code: "",
      expandedCallStack: [],
      windowWidth: window.innerWidth,
      showNoticeModal: true,
      showBackOfHouse: false,
      streamMode: true,
      expandedChainItems: {},
      recenterCounter: 0
    }

    this.processData = this.processData.bind(this);
  }

  handleResize = (e) => {
    this.setState({
      windowWidth: window.innerWidth,
      recenterCounter: this.state.recenterCounter + 1
    })
  }

  componentWillMount(){
    const { dispatch, componentReducer } = this.props;

    dispatch(tryToGetComponentVersionToDemo({
      id: this.props.id, 
      version: this.props.version
    }));

    dispatch(tryToGetFlowNodes());

    // add resize listener
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentDidMount(){
    window.document.title = "Agent Demo - zerowidth";

    const { dispatch, componentReducer } = this.props;
    if(componentReducer.current !== this.props.id){
      dispatch(setCurrentComponent(this.props.id));
    }

  }


  componentDidUpdate(){
    const { dispatch, componentReducer } = this.props;
    if(componentReducer.current !== this.props.id){
      dispatch(setCurrentComponent(this.props.id));
    }
  }


  componentWillReceiveProps(newprops){
    const { dispatch, componentReducer } = this.props;

    // if the last request was streamed, and we have more events now in the verbose chain than before, and if we are near the bottom of the chain div, scroll to the bottom to keep it in view
    if(componentReducer.wasMostRecentDemoStreamed && newprops.componentReducer.demoResponseProgress.length > componentReducer.demoResponseProgress.length){
      let chainDiv = document.getElementById('agent-chain-container');
      if(chainDiv){
        // let scrollBottom = chainDiv.scrollHeight - chainDiv.clientHeight - chainDiv.scrollTop;
        // if(scrollBottom < 100){
          chainDiv.scrollTop = chainDiv.scrollHeight;
        // }
      }
    }


    if(newprops.componentReducer.ready && newprops.componentReducer.cache[this.props.id] === undefined && !componentReducer.tryingToGetComponentVersion){
      dispatch(push('/oops'));
    }

    if(newprops.componentReducer.cache[this.props.id]){
      window.document.title = (newprops.componentReducer.cache[this.props.id].display_name || "Agent") + " Demo - zerowidth";

      if(!this.state.variables){


        const component = newprops.componentReducer.cache[this.props.id];
        let welcomeMessages = [];
        let variables = {};
        
        if(component && component.versions){
          let loadedVersion = (component.versions || []).find(v => v.id === this.props.version);

          if(loadedVersion.few_shots){
          
            loadedVersion.few_shots.sort((a,b)=>{
              if(a.created_at < b.created_at) return -1;
              if(a.created_at > b.created_at) return 1;
            })

            loadedVersion.few_shots.sort((a,b)=>{
              if(a.order < b.order) return -1;
              if(a.order > b.order) return 1;
            })
          

          for(var i in loadedVersion.few_shots){
            if(loadedVersion.few_shots[i].deleted) continue;
            for(var j in loadedVersion.few_shots[i].messages){
              if(loadedVersion.few_shots[i].messages[j].welcome_message){
                welcomeMessages.push(loadedVersion.few_shots[i].messages[j]);
              }
            }
          }
        }
        if(loadedVersion.variables){
            loadedVersion.variables = loadedVersion.variables.filter(v => !v.deleted);

            loadedVersion.variables.sort((a,b)=>{
              if(a.created_at < b.created_at) return -1;
              if(a.created_at > b.created_at) return 1;
            })

            loadedVersion.variables.sort((a,b)=>{
              if(a.order < b.order) return -1;
              if(a.order > b.order) return 1;
            })

            for(var i in loadedVersion.variables){
              if(loadedVersion.variables[i].deleted) continue;
              variables[loadedVersion.variables[i].key] = loadedVersion.variables[i].default || "";
            }

        }
        this.setState({
          variables: variables,
          messages: welcomeMessages,
        });

          setTimeout(() => {
            this.setState({
              recenterCounter: this.state.recenterCounter + 1
            })
            dispatch(flowZoomToFit());
          }, 1);
        }
      }
    }
  }

  componentWillUnmount(){
    const { dispatch } = this.props;

    dispatch(unsetCurrentComponent());

    // remove resize listener
    window.removeEventListener('resize', this.handleResize);
  }

  processData(data){
    const { dispatch } = this.props;
    
    dispatch(tryToDemoComponent({
      id: this.props.id,
      version: this.props.version,
      data: data
    }, this.state.streamMode))
  }

  render(){
    const { dispatch, guiReducer, userReducer, orgReducer, componentReducer, intelReducer } = this.props;

    let hfStretchedClassList = "hf-stretched";

    let component = componentReducer.cache[componentReducer.current];
    let loadedVersion;
    if(component){
      loadedVersion = (component.versions || []).find(v => v.id === this.props.version);
    } 
    
    let variables = []
    let guiToShow = 'madlib';

    if(loadedVersion && loadedVersion.variables){
      loadedVersion.variables.sort((a,b)=>{
        if(a.order < b.order) return -1;
        if(a.order > b.order) return 1;
      })
  
      variables = loadedVersion.variables.filter(v => v.source !== 'rag');

      if(component && component.type){
        guiToShow = component.type;
      }

      // look for a request flow node
      let requestNode = (loadedVersion.flow_nodes || []).find(n => n.type.startsWith('request'));
      
      if(requestNode){
        switch(requestNode.type){
          case 'request':
            guiToShow = "chat";
            break;
          case 'request_single_prompt':
            guiToShow = "single-prompt";
            break;
          case 'request_context_only':
            guiToShow = "madlib";
            break;
        }
      } else {
        guiToShow = "madlib";
      }

    }

    let highlightedNodeIds = [];

    let filteredChain = componentReducer.demoResponseProgress.filter(c => c.node_id !== undefined);
    
    // grab any nodes from the filtered chain that are within 1 second of the last node
    // if(filteredChain.length > 0){
    //   let lastNode = filteredChain[filteredChain.length - 1];
    //   let lastNodeTime = moment(lastNode.timestamp);
    //   let closeNodes = filteredChain.filter(c => {
    //     let cTime = moment(c.timestamp);
    //     return cTime.diff(lastNodeTime, 'seconds') < 1;
    //   });

    //   highlightedNodeIds = highlightedNodeIds.concat(closeNodes.map(c => c.node_id));
      
    // grab the last updated node
    if(componentReducer.demoResponseProgress.length > 0){
      // if we aren't all the way complete, then we want to highlight the last node
      if(!componentReducer.demoResponseProgress[componentReducer.demoResponseProgress.length - 1].end_time){

        let filteredChain = componentReducer.demoResponseProgress.filter(c => c.node_id !== undefined);
        if(filteredChain.length > 0){
          highlightedNodeIds.push(filteredChain[filteredChain.length - 1].node_id);
        }
      }
    }

    if(this.state.highlightedNodeId){
      highlightedNodeIds = [this.state.highlightedNodeId];
    }
    
    if(component && component.missing_demo_code){
      return <div className={hfStretchedClassList}>
        {/* <Header/> */}
        <div className="body flex-column-center-center bg-gs95">
          <div>
            <div className="box padding-2rem relative">
              <h4 className="no-margin-top text-center">
                <i className="fal fa-lock-alt"/>
              </h4>
              <form onSubmit={e => {
                  if(this.state.demo_code){
                    dispatch(tryToAuthenticateDemo({
                      id: component.id,
                      version: this.props.version,
                      demo_code: this.state.demo_code
                    }))
                  }
                }}>
                <CustomField
                  placeholder="******"
                  type="password"
                  value={this.state.demo_code}
                  onChange={e => this.setState({demo_code: e.value})}
                  serverError={componentReducer.authenticateDemoForm.errors.demo_code}
                  lastSubmit={componentReducer.authenticateDemoForm.lastSubmit.demo_code}
                  />
                <CustomButton
                  display="Submit"
                  block={true}
                  color="success"
                  thinking={componentReducer.tryingToAuthenticateDemo}
                  fail={componentReducer.authenticateDemoFail}
                  success={componentReducer.authenticateDemoSuccess}
                  disabled={!this.state.demo_code}
                  onClick={e => {
                    dispatch(tryToAuthenticateDemo({
                      id: component.id,
                      version: this.props.version,
                      demo_code: this.state.demo_code
                    }))
                  }}
                  />
              </form>
            </div>
          </div>
          <div className="spacer-2rem"/>
        </div>
      </div>
    }

    const drawChainItem = (c, i, fullChain) => {
      if(component.flow_mode){
        if(c.start_time){
          // this is a start time event so it gets a special box
          

          // find the end time event with this same node_id
          let end_time_event = fullChain.find((e, j) => e.node_id === c.node_id && e.end_time && j > i);

          let duration = 0; 
          if(end_time_event){
            duration = moment(end_time_event.end_time).diff(moment(c.start_time), 'milliseconds');
          }

          let nodeType = intelReducer.flow_nodes.find(n => n.name === c.node_type);
          if(!nodeType) nodeType = {};

          let category = nodeCategories.find(cat => cat.name === nodeType.category);
          
          return <div key={i} className={"box box-half-pad margin-bottom-1rem thin-line-height box-overflow-auto " + (!this.state.expandedChainItems[c.node_id + '___' + i] ? "box-clickable" : "")} 
              onClick={e => {
                let expandedChainItems = this.state.expandedChainItems;
                expandedChainItems[c.node_id + '___' + i] = true;
                this.setState({
                  expandedChainItems: expandedChainItems
                })
              }}
              onMouseEnter={e => {
                // set highlight node id
                this.setState({
                  highlightedNodeId: c.node_id
                })
              }}
              onMouseLeave={e => {
                // remove highlight node id
                this.setState({
                  highlightedNodeId: null
                })
              }}
            >
            <div className="flex-split flex-split-align-start">
              <div className="list-left">
                <span style={{position: 'relative', left: '-5px'}} className="no-margin">
                  {
                    this.state.expandedChainItems[c.node_id + '___' + i] ?
                    <i className="far fa-angle-down text-muted fa-fw clickable no-margin" onClick={e => {
                      e.stopPropagation();
                      let expandedChainItems = this.state.expandedChainItems;
                      expandedChainItems[c.node_id + '___' + i] = false;
                      this.setState({
                        expandedChainItems: expandedChainItems
                      })
                    }}/>
                    :
                    <i className="far fa-angle-right text-muted fa-fw clickable" />
                  }
                </span>
                <i className={"far text-semi-muted fa-" + category.icon}/><small className="text-900 text-uppercase">{nodeType ? nodeType.display_name : c.node_type}</small>
              </div>
              <div className="list-right">
                {
                  (end_time_event && end_time_event.attempt > 1) && <small className="text-muted">
                    Attempt {end_time_event.attempt}
                  </small>
                }
                {
                  end_time_event ?
                  <small className="text-muted">{duration}ms</small>
                  :
                  <small className="text-muted">
                    <i className="far fa-spinner-third fa-spin"/>
                  </small>
                }
              </div>
            </div>
            {
              this.state.expandedChainItems[c.node_id + '___' + i] &&
              <div className=" margin-top-1rem" onClick={e => {
                e.stopPropagation();
              }}>
                <CodeHighlighter
                  language="json"
                  code={JSON.stringify({
                    inputs: c.inputs,
                    settings: c.settings,
                    outputs: end_time_event ? end_time_event.outputs : null
                  }, null, 2)}
                  collapsedJSONDepth={1}
                  />
              </div>
            }
          </div>
        } else {
          return null;
        }
      }

      
      // check if c has a partial_content property
      if(c.partial_content !== undefined) return <span/>;

      // check if c is an empty object
      if(Object.keys(c).length === 0 && c.constructor === Object) return <span/>;
      

      let rootCallStackID;
      let rootChainItem;
      let previousChainItem;
      let stack_ids = [];
      if(componentReducer.demoResponse && !componentReducer.wasMostRecentDemoStreamed){
        rootCallStackID = componentReducer.demoResponse.verbose.chain[0].stack_id;
        rootChainItem = componentReducer.demoResponse.verbose.chain[0];
        previousChainItem = componentReducer.demoResponse.verbose.chain[i - 1];
        stack_ids = componentReducer.demoResponse.verbose.chain.map(c => c.stack_id);
      } else if(componentReducer.wasMostRecentDemoStreamed && componentReducer.demoResponseProgress.length > 0){
        rootCallStackID = componentReducer.demoResponseProgress[0].stack_id;
        rootChainItem = componentReducer.demoResponseProgress[0];
        if(i > 0){
          previousChainItem = componentReducer.demoResponseProgress[i - 1];
        }

        stack_ids = componentReducer.demoResponseProgress.filter(c => c !== null && c !== undefined).map(c => c.stack_id);
      }
      let timeSincePrevious;
      if(i > 0){
        timeSincePrevious = moment(c.timestamp).diff(moment(previousChainItem.timestamp), 'seconds');  
      } else {
        timeSincePrevious = moment(c.timestamp).diff(moment(componentReducer.demoStartedAt), 'seconds');
      }

      // loop through the call stack so far and figure out what level of unique stack_id we are at and then map that to a class from this list of colors
      let colors = [
        'primary',  'secondary', 'success', 'orange', 'purple', 'pink', 'dark'
      ]

      // create flattend array of stack_ids;
      let unique_stack_ids = [...new Set(stack_ids)];
      let stack_id_index = unique_stack_ids.indexOf(c.stack_id);
      let color = colors[stack_id_index % colors.length];


      return <div 
        key={i} 
        className={"box box-half-pad margin-bottom-1rem thin-line-height box-overflow-auto " + (c.stack_id === rootCallStackID ? "" : "")}
       
        >
        <div className="flex-split flex-split-align-start">
          <div>
            <div className="margin-bottom-05rem">
            <small className="text-uppercase text-muted">
              {c.category === 'plugin' && <i className="far text-muted fa-puzzle-piece icon-before-text"/> }
              {c.category === 'generate_output' && <i className="far text-muted fa-brain icon-before-text"/> }
              {c.category === 'rag' && <i className="far text-muted fa-books icon-before-text"/> }
              {c.category === 'quality_control' && <i className="far text-muted fa-check-circle icon-before-text"/> }
              {c.category}
            </small>
            </div>
          </div>
          <div className="list-right">
            {
              timeSincePrevious !== undefined &&
              <small className="text-muted">{timeSincePrevious}s</small>
            }
          </div>
        </div>
        <div className="flex-split flex-split-align-start">
          
          <div className="flex-grow">
            <div className="margin-bottom-05rem">
              <div className="text-900">  
                {c.event }
              </div>
              <small>{c.purpose}</small>
            </div>
            {
              c.data && <CodeHighlighter
                language="json"
                code={JSON.stringify(c.data, null, 2)}
                collapsedJSONDepth={1}
                />
            }
            {
              // c.data &&
              // <div className="react-json-wrapper">
              //   <ReactJson 
              //     src={c.data} 
              //     collapsed={0}
              //     displayDataTypes={false}
              //     sortKeys={true}
              //     iconStyle="triangle"
              //     name={false}
              //     enableClipboard={false}/>
              // </div>
            }
          </div>
        </div>
      
      </div>
    }

    const dynamicVariableContent = <div className="box box-transparent box-no-shadow box-no-pad flex-grow no-margin-bottom scroll-parent align-self-stretch margin-left-2rem">
          <div className="scroll-child padding-right-05rem">
          {
            ((variables || []).length > 0 && this.state.variables) ? variables.map((variable, i) => {
              
              return <div key={i} className={"box box-half-pad margin-bottom-1rem"}>
                {
                  variable.type === 'text' &&
                  <CustomField
                    inline={true}
                    name={variable.key}
                    value={this.state.variables[variable.key]}
                    label={variable.display_name || '${' + variable.key + '}'}
                    description={variable.description || " "}
                    placeholder={variable.placeholder || " "} 
                    minLength={variable.min_length}
                    maxLength={variable.max_length}
                    disabled={componentReducer.tryingToDemoComponent}
                    onChange={e => {
                      let variables = this.state.variables;
                      variables[variable.key] = e.value;
                      this.setState({
                        variables: variables
                      })
                    }}
                    />
                }

                {
                  variable.type === 'paragraph' &&
                  <CustomField
                    inline={true}
                    name={variable.key}
                    value={this.state.variables[variable.key]}
                    label={variable.display_name || '${' + variable.key + '}'}
                    description={variable.description || " "}
                    placeholder={variable.placeholder || " "} 
                    minLength={variable.min_length}
                    maxLength={variable.max_length}
                    disabled={componentReducer.tryingToDemoComponent}
                    rows={5}
                    onChange={e => {
                      let variables = this.state.variables;
                      variables[variable.key] = e.value;
                      this.setState({
                        variables: variables
                      })
                    }}
                    />
                }

                {
                  variable.type === 'number' &&
                  <CustomField
                    inline={true}
                    name={variable.key}
                    type="number"
                    value={this.state.variables[variable.key]}
                    label={variable.display_name || '${' + variable.key + '}'}
                    description={variable.description || " "}
                    placeholder={variable.placeholder || " "} 
                    min={variable.min}
                    max={variable.max}
                    disabled={componentReducer.tryingToDemoComponent}
                    onChange={e => {
                      let variables = this.state.variables;
                      variables[variable.key] = e.value;
                      this.setState({
                        variables: variables
                      })
                    }}
                    />
                }

                {
                  variable.type === 'select' &&
                  <CustomSelect
                    inline={true}
                    name={variable.key}
                    value={this.state.variables[variable.key]}
                    label={variable.display_name || '${' + variable.key + '}'}
                    description={variable.description || " "}
                    placeholder={variable.placeholder || " "} 
                    options={variable.options || []}
                    disabled={componentReducer.tryingToDemoComponent}
                    onChange={e => {
                      let variables = this.state.variables;
                      variables[variable.key] = e;
                      this.setState({
                        variables: variables
                      })
                    }}
                    />
                }
              </div>
            })
            :
            <div className="box box-half-pad box-placeholder margin-bottom-1rem thin-line-height pointer-events-none">
              <div className="flex-split">
                <div>
                  <div className="margin-bottom-05rem"><small className="text-uppercase text-muted">No Variables</small></div>
                </div>
              </div>
              <small className="text-muted">This component has no dynamic variables.</small>
            </div>
          }
        </div>
      </div>

    const agentChainContent = <div className="box flex-grow no-margin-bottom box-transparent box-no-shadow box-no-pad flex-column-stretch align-self-stretch margin-right-2rem">
      {
        component && component.flow_mode && <div className="box box-no-pad" style={{height: '200px', maxHeight: '200px'}}>
          <FlowRenderer
            nodes={intelReducer.flow_nodes.length > 0 ? loadedVersion.flow_nodes : []}
            links={intelReducer.flow_nodes.length > 0 ? loadedVersion.flow_links : []}
            interactive={false}
            showMinimap={false}
            highlightedNodeIds={highlightedNodeIds}
            />
        </div>
      }
      {/* <div className="flex-split">
        <small>
          {componentReducer.demoResponseProgress.length} Events
        </small>
      </div> */}
      <div className="scroll-parent flex-grow">
        {
          componentReducer.wasMostRecentDemoStreamed ?
          <div className="scroll-child padding-right-05rem" id="agent-chain-container">
            {
              componentReducer.demoResponseProgress.map((d, i)=>{
                return drawChainItem(d, i, componentReducer.demoResponseProgress);
              })
            }
            {
              componentReducer.tryingToDemoComponent &&
              <div className="box box-half-pad box-placeholder margin-bottom-1rem thin-line-height pointer-events-none ">
                <div className="flex-split">
                  <div>
                    <div className="margin-bottom-05rem"><small className="text-uppercase text-muted"><i className="far text-muted fa-spinner-third fa-spin icon-before-text"/></small></div>
                  </div>
                </div>
                <small className="text-muted">Processing your request...</small>
              </div>
            }
            {
              componentReducer.demoResponseProgress.length === 0 && !componentReducer.tryingToDemoComponent &&
              <div className="box box-half-pad box-placeholder margin-bottom-1rem thin-line-height pointer-events-none">
                <div className="flex-split">
                  <div>
                    <div className="margin-bottom-05rem"><small className="text-uppercase text-muted">Waiting</small></div>
                  </div>
                </div>
                <small className="text-muted">No responses yet.</small>
              </div>
            }

          </div>
          :
          <div className="scroll-child padding-right-05rem">
            {
              componentReducer.tryingToDemoComponent ?
              <div className="box box-half-pad box-placeholder margin-bottom-1rem thin-line-height pointer-events-none ">
                <div className="flex-split">
                  <div>
                    <div className="margin-bottom-05rem"><small className="text-uppercase text-muted"><i className="far text-muted fa-spinner-third fa-spin icon-before-text"/></small></div>
                  </div>
                </div>
                <small className="text-muted">Processing your request...</small>
              </div>
              :
              componentReducer.demoResponse ? componentReducer.demoResponse.verbose.chain.map((c, i)=> {
                return drawChainItem(c, i, componentReducer.demoResponse.verbose.chain);
              })
              :
              <div className="box box-half-pad box-placeholder margin-bottom-1rem thin-line-height pointer-events-none">
                <div className="flex-split">
                  <div>
                    <div className="margin-bottom-05rem"><small className="text-uppercase text-muted">Waiting</small></div>
                  </div>
                </div>
                <small className="text-muted">No responses yet.</small>
              </div>
            }
          </div>
        }
      </div>
    </div>

    const agentContent = <div className="box relative flex-grow no-margin-bottom box-half-pad align-self-stretch">
      {
        component && guiToShow === 'chat' &&
        <BetterChat
          demoCache={componentReducer.demoCache[component.id + '_' + this.props.version]}
          variables={this.state.variables}
          component={component}
          component_id={component.id}
          version={this.props.version}
          publicDemo={false}
          />


        // <ComponentChat
        //   demo={true}
        //   debugView={true}
        //   component={component}
        //   loadedVersion={loadedVersion}
        //   processing={componentReducer.tryingToDemoComponent}
        //   processData={this.processData}
        //   demoResponseProgress={componentReducer.demoResponseProgress}
        //   demoResponseConcat={componentReducer.demoResponseConcat}
        //   processResponse={componentReducer.demoResponse}
        //   variables={this.state.variables}
        //   componentReducer={componentReducer}
        //   onInspectBackOfHouse={e => this.setState({ showBackOfHouse: true, showBackOfHouseData: e })}
        //   />
      }

      {
        component && guiToShow === 'madlib' &&
        <BetterContextOnly
          demoCache={componentReducer.demoCache[component.id + '_' + this.props.version]}
          variables={this.state.variables}
          component={component}
          component_id={component.id}
          version={this.props.version}
          publicDemo={false}
          />

        // <ComponentMadlib
        //   demo={true}
        //   debugView={true}
        //   component={component}
        //   loadedVersion={loadedVersion}
        //   processing={componentReducer.tryingToDemoComponent}
        //   processData={this.processData}
        //   demoResponseProgress={componentReducer.demoResponseProgress}
        //   processResponse={componentReducer.demoResponse}
        //   variables={this.state.variables}
        //   componentReducer={componentReducer}
        //   onInspectBackOfHouse={e => this.setState({ showBackOfHouse: true, showBackOfHouseData: e })}
        //   />
      }
      {
        component && guiToShow === 'single-prompt' &&
        <BetterPrompt
          demoCache={componentReducer.demoCache[component.id + '_' + this.props.version]}
          variables={this.state.variables}
          component={component}
          component_id={component.id}
          version={this.props.version}
          publicDemo={false}
          />
      }
    </div>

    return <div className={hfStretchedClassList}>
      {/* <Header/> */}
      <Modal
        show={this.state.showNoticeModal && !userReducer.isLoggedIn}
        acceptable={true}
        acceptButtonLabel={"I understand, let's go."}
        onAccept={() => this.setState({showNoticeModal: false})}
        maxWidth={400}
        content={<div>
          <h4 className="no-margin">Welcome to ZeroWidth</h4>
          <p className="thin-line-height">
            <small>This is a demo of an agent created using ZeroWidth's toolkit. The creator of this demo may be able to monitor and inspect its usage. Do not enter private information.</small>
          </p>
          <p className="thin-line-height">
            <small>Large language models may produce inaccurate information about people, places, or facts. Your use of this demo is subject to <Link to="/docs">ZeroWidth's policies</Link>, including <Link to="/docs/policies/terms-of-service">Terms</Link> and <Link to="/docs/policies/privacy">Privacy</Link>.</small>
          </p>
        </div>}
        />

      <Modal
        show={this.state.showBackOfHouse}
        maxWidth={600}
        exitable={true}
        hideExitButton={true}
        onExit={() => this.setState({showBackOfHouse: false})}
        content={<div>
          <CodeHighlighter
            language="json"
            code={JSON.stringify(this.state.showBackOfHouseData, null, 2)}
            collapsedJSONDepth={2}
            />
{/* 
          <ReactJson 
            src={this.state.showBackOfHouse}
            collapsed={2}
            displayDataTypes={false}
            sortKeys={true}
            iconStyle="triangle"
            name={false}
            enableClipboard={false}
            /> */}
        </div>}
        />
      
      {
        (component && loadedVersion) ?
        <div className="flex-grow relative bg-gs95">
          <ReflexContainer orientation="vertical">
            {
              this.state.windowWidth > mobileBreakpoint && 
              <ReflexElement 
                className="left-pane flex-column-stretch" 
                minSize={250}
                size={300}
                > 
                  <div className="flex-split margin-left-2rem margin-top-2rem margin-bottom-1rem">
                    <small className="no-margin text-gs5 text-900 text-uppercase">
                      Dynamic Variables
                      <i className="fal fa-info-circle  icon-after-text" 
                        onMouseEnter={(e) => {
                          dispatch(showTooltip({
                            el: e.target,
                            nobr: false,
                            position: 'bottom',
                            content: <div style={{maxWidth: 250}} className="text-400">
                              <span className="text-900 no-margin">What are these for?</span>
                              <p className="no-margin-top thin-line-height">
                                <small>
                                  These variables are used to inject dynamic data into the instructions of an agent, decoupling static instructions from changing context. They enable the agent to customize its decision and behavior based on the unique context of its use.
                                </small>
                              </p>
                              <p className="thin-line-height">
                                <small>
                                  In production applications beyond this demo page, these values are typically set invisibly by the application's backend or by the user interacting with the application.
                                </small>
                              </p>
                              <p className="no-margin thin-line-height">
                                <small>
                                  Some examples might be automatically setting the user's name, the current time, or the user's location.
                                </small>
                              </p>
                            </div>
                          }))
                        }}
                        onMouseLeave={(e) => {
                          dispatch(hideTooltip())
                        }}
                        />
                    </small>
                  </div>
                  {dynamicVariableContent}
                </ReflexElement>
              }
              {
                this.state.windowWidth > mobileBreakpoint &&
                <ReflexSplitter/>
              }
              <ReflexElement
                className={"middle-pane "  + (this.state.windowWidth < mobileBreakpoint ? " padding-left-1rem padding-right-1rem" : "")}
                minSize={250}
                >
                <div className="flex-split margin-top-2rem margin-bottom-1rem">
                  <small className="no-margin text-gs5  text-900 text-uppercase">
                    {
                      component.type === 'chat' ? "Conversational Interface" : "Generation Output"
                    }
                    <i className="fal fa-info-circle icon-after-text" 
                      onMouseEnter={(e) => {
                        dispatch(showTooltip({
                          el: e.target,
                          nobr: false,
                          position: 'bottom',
                          content: <div style={{maxWidth: 250}} className="text-400">
                            <span className="no-margin text-900">What's this?</span>
                            <p className="no-margin thin-line-height">
                              <small>
                                This is the testing interface for interacting with the agent. It is where you can see the agent's responses and interact with it.
                              </small>
                            </p>
                          </div>
                        }))
                      }}
                      onMouseLeave={(e) => {
                        dispatch(hideTooltip())
                      }}
                      />
                  </small>
                </div>
                {agentContent}
                <div className="list-right margin-top-05rem">
                  <small className="text-muted">
                    All LLMs can make mistakes. Verify important information.
                  </small>
                </div>
              </ReflexElement>
              {
                this.state.windowWidth > mobileBreakpoint &&
                <ReflexSplitter/>
              }
              {
                this.state.windowWidth > mobileBreakpoint &&
                <ReflexElement
                  className={"right-pane flex-column-stretch "}
                  minSize={250}
                  size={400}
                  onResize={() => {
                    this.setState({
                      recenterCounter: this.state.recenterCounter + 1
                    })
                  }}
                  >
                  <div className="flex-split margin-right-2rem margin-top-2rem margin-bottom-1rem">
                    <small className="no-margin text-gs5  text-900 text-uppercase">
                      {
                        component.flow_mode ? "AI Flow Chain" : "Agent Chain"
                      }
                      <i className="fal fa-info-circle icon-after-text" 
                        onMouseEnter={(e) => {
                          dispatch(showTooltip({
                            el: e.target,
                            nobr: false,
                            position: 'bottom',
                            content: <div style={{maxWidth: 250}} className="text-400">
                              <span className="no-margin text-900">What's this?</span>
                              <p className="no-margin thin-line-height">
                                <small>
                                  Applying LLMs to a real problem involves an ecosystem of configured technologies and even other supporting LLMs. This is a visual representation of the chain of processes that are being used to process your request, which change upon each request.
                                </small>
                              </p>
                            </div>
                          }))
                        }}
                        onMouseLeave={(e) => {
                          dispatch(hideTooltip())
                        }}
                        />
                    </small>
                    <small 
                      className="clickable text-gs5 text-hover-primary margin-right-05rem"
                      onClick={e => {
                      this.setState({
                        streamMode: !this.state.streamMode
                      })
                    }}>
                      Stream events{
                        this.state.streamMode ?
                        <i className='fas fa-fw fa-check-square text-success icon-after-text'/>
                        :
                        <i className='fal fa-fw fa-square icon-after-text'/>
                      }
                    </small>
                  </div>
                  {agentChainContent}
                </ReflexElement>
              }
          </ReflexContainer>
        </div>
        :
        <div className="flex-grow">
          <div className="fixed-big-image">
            <ASCIIBackground 
              imagePath={'/home-img/0W-logo.png'} 
              foreground={'#C0C7D3'} 
              background={'#f0f1f5'} 
              autoTween={1000} 
              fontSize={16}
              placementMode={'contain'}
              />
          </div> 
        </div>
      }
      <div className="bg-gs95">
        <Footer 
          simple={true}
          noMargin={true}
          customLeft={
            <div>
              <small className="d-none d-lg-flex">
                {
                  (component && loadedVersion) &&
                  <div className="list-left">
                    <span>This is a demo of the <strong>{loadedVersion.display_name}</strong> Agent Flow created by</span>
                    <div className="no-margin"><Hydrate id={component.created_by} type="user" mode="lockup" size={25}/></div>
                    <span>&nbsp;for</span>
                    <Hydrate id={component.scope} type="organization" mode="lockup" size={25}/>
                  </div>
                }
              </small>



              <small className="d-lg-none">
                {
                  (component && loadedVersion) &&
                    <div className="list-left">
                      <span>
                        This is a demo of the <strong>{loadedVersion.display_name}</strong> Agent Flow created by <Hydrate id={component.created_by} type="user"/> for <Hydrate id={component.scope} type="organization"/>
                      </span>
                    </div>
                }
              </small>
            </div>
          }/>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, componentReducer, guiReducer, intelReducer, orgReducer } = state;

  return {
    userReducer,
    componentReducer,
    guiReducer,
    intelReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(DemoComponentRoute);

  