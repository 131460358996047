import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import DocsManager from '../components/DocsManager/DocsManager.js'

class DocsRoute extends Component {
  componentDidMount(){
    window.document.title = "zerowidth.ai";
  }

  render(){
    const { guiReducer, userReducer, page, subpage } = this.props;

    return <div className={"hf-stretched hf-stretched-noscroll"}>
      <Header/>
      <DocsManager
        page={page}
        subpage={subpage}
        />
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, guiReducer } = state;

  return {
    userReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(DocsRoute);

  