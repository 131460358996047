import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MegaMarkdown from 'components/MegaMarkdown/MegaMarkdown';

export const NodeRenderer_monitor = ({ node, component_id, to_links }) => {

  let value = undefined;

  let hasLink = to_links.length > 0;

  if(component_id){
    // figure out the value based on the linkCacheRef
    const componentReducer = useSelector(state => state.componentReducer);
    
    const linkCache = componentReducer.linkCache[component_id];
    const nodeOutputCache = componentReducer.nodeOutputCache[component_id];


    if(linkCache && nodeOutputCache){
      
      // figure out the link that is connected to this node's value input
      let linkToValue = to_links.find(l => l.to.input === 'value');
      let key = linkToValue ? linkToValue.from.node_id + ':' + linkToValue.from.output : undefined;

      if(key){
        value = nodeOutputCache[key];

        if(typeof value === 'object' || Array.isArray(value)){
          value = JSON.stringify(value, null, 2);
        }
      }
    }
  }
  return (
    <div className="scroll-parent" style={{width: 250, height: 200}}>
      {
        hasLink ?
        (
          value ?
          <div className="scroll-child padding-1rem thin-line-height">
            <MegaMarkdown text={value} />
          </div>
          :
          <div className="scroll-child flex-column-center-center text-center padding-1rem">
            <small className="text-muted">Waiting for output to be generated...</small>
          </div>
        )
        :
        <div className="scroll-child flex-column-center-center text-center padding-1rem">
          <small className="text-muted">Connect a node to display its current output.</small>
        </div>
      }
    </div>
  );
}