import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Prompt } from 'react-router-dom'
import { push } from 'react-router-redux';
import moment from 'moment'

// import {

// } from '../../actions/actions.export'

import CustomField from 'kit/components/CustomField/CustomField.js'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js'
import CustomButton from 'kit/components/CustomButton/CustomButton.js'
import Callout from 'kit/components/Callout/Callout.js'
import Hydrate from 'components/Hydrate/Hydrate';

import './ComponentVariableTool.scss';

// Load the default initialized state for this content, might include parsing or some bonus arrays or something
export const createInitPacket = (content) => {
  return {
    id: content.id,
    source: content.source || 'external',
    display_name: content.display_name,
    description: content.description,
    default: content.default,
    placeholder: content.placeholder,
    order: content.order,
    key: content.key,
    type: content.type,
    min_length: content.min_length,
    max_length: content.max_length,
    min: content.min,
    max: content.max,
    options: content.options,
    knowledge_id: content.knowledge_id,
    knowledge_link_method: content.knowledge_link_method,
    max_information_returned: content.max_information_returned || 1,
    embedding_similarity_threshold: content.embedding_similarity_threshold || 0,
    rag_manual_search_text: content.rag_manual_search_text || "",
    rag_agentic_prompt: content.rag_agentic_prompt || "",
    messages_to_use: content.messages_to_use || [],
  }
}


// Parse relevant data from the content to show any unsaved changes
export const createStructuredPacket = (content) => {
  return {
    id: content.id,
    source: content.source || 'external',
    display_name: content.display_name,
    description: content.description,
    default: content.default,
    placeholder: content.placeholder,
    order: content.order,
    key: content.key,
    type: content.type,
    min_length: content.min_length,
    max_length: content.max_length,
    min: content.min,
    max: content.max,
    options: content.options,
    knowledge_id: content.knowledge_id,
    knowledge_link_method: content.knowledge_link_method,
    max_information_returned: content.max_information_returned || 1,
    embedding_similarity_threshold: content.embedding_similarity_threshold || 0,
    rag_manual_search_text: content.rag_manual_search_text || "",
    rag_agentic_prompt: content.rag_agentic_prompt || "",
    messages_to_use: content.messages_to_use || [],
  }
}

// Append relevant info to download zip
const createDownloadPacket = (content, zip) => {
  zip.file(content.display_name + ".json", JSON.stringify({
    display_name: content.display_name,
    source: content.source || 'external',
    description: content.description,
    default: content.default,
    placeholder: content.placeholder,
    order: content.order,
    key: content.key,
    type: content.type,
    min_length: content.min_length,
    max_length: content.max_length,
    min: content.min,
    max: content.max,
    options: content.options,
    knowledge_id: content.knowledge_id,
    knowledge_link_method: content.knowledge_link_method,
    max_information_returned: content.max_information_returned || 1,
    embedding_similarity_threshold: content.embedding_similarity_threshold || 0,
    rag_manual_search_text: content.rag_manual_search_text || "",
    rag_agentic_prompt: content.rag_agentic_prompt || "",
    messages_to_use: content.messages_to_use || [],
  }, null, 2))

  return zip;
}


// Don't change anything here, this little guy just helps us run our menu updates up the tree to the manager
const Observer = ({ value, didUpdate }) => {
  useEffect(() => {
    didUpdate(value)
  }, [value])
  return null // component does not render anything
}

class CustomComponent extends Component {
  constructor(props){
    super(props);


    this.state = {
      new_option: "",
      showGUIOptions: false
    }

    this.updateMenu = this.updateMenu.bind(this);
  }

  componentWillMount(){
    
  }

  updateMenu(){
    this.props.menuMerge([])
  }

  render(){

    const { component, draftComponent, knowledgeReducer, content, canWrite } = this.props;

    let isFoundInInstructions = (draftComponent.few_shots || []).filter(i => {
      return (i.messages || []).filter(m => m.content.indexOf('${' + this.props.data.key + '}') > -1).length > 0
    }).length;

    let knowledgeCacheList = Object.keys(knowledgeReducer.cache).map(id => knowledgeReducer.cache[id]);
    
    let knowledge = [];
    if(this.props.org_id){
      knowledge = knowledgeCacheList.filter(p => p.scope === this.props.org_id);
    }

    
    return <div className="component-template-tool">
      {/*invisible component to tuck the hook within this class component, don't edit*/}
      <Observer value={this.state} didUpdate={this.updateMenu} />
      {/*invisible component to tuck the hook within this class component, don't edit*/}

      <div className="margin-bottom-2rem box">
        <div className="row">
          <div className="col">
            <p>
              Variables are placeholders that can be used by your AI to contextualize its behavior, information, or approach. Typically they're used to dynamically insert information into your instructions or responses.
            </p>
            <CustomField
              value={this.props.data.key}
              label="Key"
              placeholder="NAME_OF_VARIABLE"
              description={"Capital letters, numbers, and underscores"}
              regex="alphanumeric-underscore"
              required={true}
              disabled={!canWrite}
              maxLength={64}
              onBlur={e => {
                this.props.onChange({
                  key: (this.props.data.key || "").toUpperCase()
                })
              }}
              onChange={(v)=>{
                this.props.onChange({
                  key: v.value
                })
              }}
              />
          </div>
        </div>
        {
          !component.flow_mode &&
          <div className="row margin-bottom-2rem">
            <div className="col">
              {
                (isFoundInInstructions) ? 
                <Callout
                  style="success"
                  title="This variable has correctly been used in your instructions."
                  dismissable={true}
                  content={<div>
                      You can edit it's placement there by typing <span className="text-900 text-monospace">${"{" + (this.props.data.key || "NAME_OF_VARIABLE") + "}"}</span> into the content of any instruction.
                    </div>}
                  />
                :
                <Callout
                  style="warning"
                  title="You still need to add this variable to your instructions."
                  dismissable={true}
                  content={<div>You can do this by typing <span className="text-900 text-monospace">${"{" + (this.props.data.key || "NAME_OF_VARIABLE") + "}"}</span> into the content of any instruction.</div>}
                  />
              }
            </div>
          </div>
        }
        
        {
          !component.flow_mode && 
          <div>
            <div className="row">
              <div className="col">
                <h5 className="no-margin-top">
                  How should this variable's value be determined?
                </h5>
              </div>
            </div>
            <div className="row row-eq-height margin-bottom-2rem">
              <div className="col-md-6 flex-column-stretch">
                <div className={"box flex-grow no-margin-bottom box-clickable " + (this.props.data.source === 'external' ? 'box-primary' : 'box-muted box-placeholder')} onClick={e => this.props.onChange({ source: 'external' })}>
                  <h5 className="no-margin">External</h5>
                  <p>
                    This variable's value is expected to come from the API call or input form on the demo interface.
                  </p>
                  <small className="text-semi-muted">Best suited for creating dynamic elements within an instruction that change based on the situation or task this agent is working on.</small>
                </div>
              </div>
              <div className="col-md-6 flex-column-stretch">
                <div className={"box flex-grow no-margin-bottom box-clickable " + (this.props.data.source === 'rag' ? 'box-primary' : 'box-muted box-placeholder')} onClick={e => this.props.onChange({ source: 'rag' })}>
                  <h5 className="no-margin">RAG Based</h5>
                  <p>
                    This variable's value will be automatically retrieved from a connected Knowledge Base.
                  </p>
                  <small className="text-semi-muted">Best suited for connecting an agent to a domain specific or proprietary text-based information that you've configured within a ZeroWidth Knowledge Base.</small>
                </div>
              </div>
            </div>
            
            <hr className="hr-mega-pad"/>
          </div>
        }

        {
          this.props.data.source === 'external' &&
          <div>
            <div className="row ">
              {
                !component.flow_mode &&
                <div className="col-md-12 margin-top-1rem">
                  <h4 className="no-margin-top">External Configuration Settings</h4>
                </div>
              }
              <div className="col-md-12">
                <CustomField
                  value={this.props.data.default}
                  label="Default Value"
                  rows={3}
                  placeholder="ex: 123, 'Hello World', 'Option 1'"
                  description="An optional default value to set for this variable."
                  disabled={!canWrite}
                  onChange={(v)=>{
                    this.props.onChange({
                      default: v.value
                    })
                  }}
                  />
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-12">
                <h5 className="no-margin-bottom">How should this variable's input appear on the demo page?</h5>
              </div>
              <div className="col-md-6">
                <CustomField
                  value={this.props.data.display_name}
                  label="Label"
                  placeholder="Give this variable a name"
                  description="A short descriptive name shown on the demo page"
                  disabled={!canWrite}
                  maxLength={64}
                  onBlur={e => {
                    if(this.props.data.key === undefined || this.props.data.key === null || this.props.data.key === ""){
                      this.props.onChange({
                        key: (this.props.data.display_name || "").trim().toUpperCase().replace(/[^A-Z0-9_]/g, "_")
                      })
                    }
                  }}
                  onChange={(v)=>{
                    this.props.onChange({
                      display_name: v.value
                    })
                  }}
                  />
              </div>
              <div className="col-md-6">
                <CustomSelect
                  value={this.props.data.type}
                  label="Input Type"
                  disabled={!canWrite}
                  description="What kind of input element should be used to collect this variable's value?"
                  placeholder="Text, Paragraph, Number, Select..."
                  options={[
                    {
                      label: 'Text: a short word or phrase',
                      value: 'text' 
                    },
                    {
                      label: 'Paragraph: a longer bit of text',
                      value: 'paragraph' 
                    },
                    {
                      label: 'Number: a numeric value',
                      value: 'number' 
                    },
                    {
                      label: 'Select: choosing from preset options',
                      value: 'select' 
                    }
                  ]}
                  onChange={(v)=>{

                    this.props.onChange({
                      type: v
                    })
                  }}
                  />
              </div>
              <div className="col-md-6">
                <CustomField
                  value={this.props.data.placeholder}
                  label="Placeholder Value"
                  placeholder="This grey text is a placeholder"
                  disabled={!canWrite}
                  description="Placeholder text shows examples of valid variable uses, without being defaults"
                  onChange={(v)=>{
                    this.props.onChange({
                      placeholder: v.value
                    })
                  }}
                  />
              </div>
              <div className="col-md-6">
                <CustomField
                  value={this.props.data.description}
                  disabled={!canWrite}
                  label="Detailed Description"
                  placeholder="What should be considered when choosing this value?"
                  description="An optional longer-form description of the variable's purpose"
                  
                  maxLength={256}
                  onChange={(v)=>{
                    this.props.onChange({
                      description: v.value
                    })
                  }}
                  />
              </div>
              
            </div>
            {
              (this.props.data.type === 'select') &&
              <div>
                <div className="spacer-1rem"/>
                <div className="row">
                  <div className="col">
                    <CustomField
                      name="new_option"
                      value={this.state.new_option}
                      disabled={!canWrite}
                      label="New Option"
                      placeholder="Enter an option here for the demo page's select dropdown."
                      onChange={e => {
                        this.setState({
                          new_option: e.value
                        })
                      }}
                      />
                  </div>
                  <div className="col">
                    <div className="inline-button-vertical-shift"/>
                    <CustomButton
                      display="Add Option"
                      color="black"
                      disabled={!canWrite}
                      block={true}
                      onClick={e => {
                        let options = this.props.data.options || [];
                        options.push(this.state.new_option);

                        this.props.onChange({
                          options: options
                        })
                        this.setState({
                          new_option: ""
                        })
                      }}
                      />
                  </div>
                </div>
                <div className="spacer-1rem"/>
                <p>Dropdown Options:</p>
                {
                  (this.props.data.options || []).length === 0 &&
                  <div className="text-muted">
                    No options have been added yet.
                  </div>
                }
                {
                  (this.props.data.options || []).map((option, i) => {

                    return <div className="row" key={i}>
                      
                      <div className="col-md-11">
                        <pre>
                        {
                          option
                        }
                        </pre>
                      </div>
                      
                      <div className="col-md-1">
                        <CustomButton
                          display={<i className="far fa-times"/>}
                          color="transparent"
                          block={true}
                          disabled={!canWrite}
                          onClick={e => {
                            let options = this.props.data.options || [];
                            options.splice(i, 1);

                            this.props.onChange({
                              options: options
                            })
                          }}
                          />
                      </div>
                    </div>
                  })
                }
              </div>
            }
          </div>
        }

        {
          this.props.data.source === 'rag' &&
          <div className="">
            <div className="flex-split margin-bottom-1rem">
              <h4 className="no-margin">Retrieval Augmentation Options</h4>
              
            </div>
            <p>
              Retrieval Augmentation is a technique that allows the LLM to retrieve additional relevant information to help it understand the context or answer of a request. This is done by having a configuring a variable to be automatically replaced with the most relevant information from a connected Knowledge Base.
            </p>
            
            <div className="row">
              <div className="col-md-12">
                <CustomSelect
                  placeholder="Select one of your organization's Knowledge Bases"
                  disabled={!canWrite}
                  large={true}
                  label="Which Knowledge Base should be searched?"
                  value={this.props.data.knowledge_id}
                  options={(knowledge).map((k, i)=>{
                    return {
                      value: k.id,
                      label: <div>
                        <span className="text-900">{k.display_name}</span> <br/>
                        <div className="text-ellipsis-2-lines">
                          <small className="text-muted">by <Hydrate id={k.created_by} type="user"/></small>
                        </div>
                      </div>
                    }
                  })}
                  onChange={e => {
                    this.props.onChange({
                      knowledge_id: e
                    })
                  }}
                  />

                <div className="spacer-1rem"/>
                <CustomField
                  name={'max_information_returned'}
                  label={<span>How many pieces of information should be inserted into this instructions content?<br/><small className="text-muted">(defaults to 1)</small></span>}
                  description={""}
                  type="range"
                  min={1}
                  max={32}
                  step={1}
                  placeholder={1}
                  disabled={!canWrite}
                  value={this.props.data.max_information_returned}
                  onChange={e => {
                    this.props.onChange({
                      max_information_returned: e.value
                    })
                  }}
                  />

                <div className="spacer-1rem"/>
                <CustomField
                  name={'embedding_similarity_threshold'}
                  label={<span>How similar should the inserted information be to the search text?<br/><small className="text-muted">0 will pull the nearest information no matter how far, while a value of 1 requires an exact match (default: 0)</small></span>}
                  description={""}
                  type="range"
                  min={0}
                  max={1}
                  step={.01}
                  placeholder={0}
                  disabled={!canWrite}
                  value={this.props.data.embedding_similarity_threshold}
                  onChange={e => {
                    this.props.onChange({
                      embedding_similarity_threshold: e.value
                    })
                  }}
                  />
              </div>
              
              <div className="col-md-12 margin-bottom-2rem">
                <h5 className="no-margin-bottom">Which method should be used to determine the RAG search text?</h5>
              </div>
              <div className="col-md-12 margin-bottom-2rem">
                <div className="row row-eq-height">
                  <div className="col-md-4 flex-column-stretch">
                    <div className={"box flex-grow no-margin-bottom box-clickable " + (this.props.data.knowledge_link_method === 'naive' ? 'box-primary' : 'box-placeholder box-muted')} onClick={e => this.props.onChange({ knowledge_link_method: 'naive' })}>
                      <h5 className="no-margin">Naive</h5>
                      <p>
                        The simplest and fastest method, this will search based on only the most recent user message.
                      </p>
                      <small className="text-semi-muted">Best suited for quick one-off lookups in a conversational interface.</small>
                    </div>
                  </div>

                  <div className="col-md-4 flex-column-stretch">
                    <div className={"box flex-grow no-margin-bottom box-clickable " + (this.props.data.knowledge_link_method === 'manual' ? 'box-primary' : 'box-placeholder box-muted')} onClick={e => this.props.onChange({ knowledge_link_method: 'manual' })}>
                      <h5 className="no-margin">Manual</h5>
                      <p>
                        Manually write the search text, with the ability to leverage any available variable from this agent.
                      </p>
                      <small className="text-semi-muted">Best suited for pulling known information based on passed variable values.</small>
                    </div>
                  </div>

                  <div className="col-md-4 flex-column-stretch">
                    <div className={"box flex-grow no-margin-bottom box-clickable " + (this.props.data.knowledge_link_method === 'agentic_basic' ? 'box-primary' : 'box-placeholder box-muted')} onClick={e => this.props.onChange({ knowledge_link_method: 'agentic_basic' })}>
                      <h5 className="no-margin">Agentic</h5>
                      <p>
                        Slower but more focused, tasks another LLM to determine the best search string to use for knowledge look up.
                      </p>
                      <small className="text-semi-muted">Best suited for complex information retrieval in a conversational interface, choose from a standard method or customize your own.</small>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="spacer-2rem"/>

              {
                this.props.data.knowledge_link_method === 'manual' &&
                <div className="col-md-12">
                  <h5 className="no-margin">Customize Manual Search</h5>
                  <CustomField
                    value={this.props.data.rag_manual_search_text}
                    label="Search Text"
                    placeholder="Enter the search text here, using ${VARIABLE} syntax to leverage any available variables."
                    rows={5}
                    disabled={!canWrite}
                    onChange={(v)=>{
                      this.props.onChange({
                        rag_manual_search_text: v.value
                      })
                    }}
                    />
                </div>
              }

              {
                this.props.data.knowledge_link_method === 'agentic_basic' &&
                <div className="col-md-12">
                  <h5 className="no-margin">Customize Agent</h5>
                  <CustomSelect
                    label="Optionally, choose an initial prompt to customize"
                    placeholder="Select a preset"
                    disabled={!canWrite}
                    value={this.props.data.rag_agentic_prompt}
                    options={[
                      {
                        label: <div className="list-left list-left-no-wrap">
                          <div>
                            <i className="far fa-file-search fa-lg fa-fw"/>
                          </div>
                          <div className="thin-line-height">
                            <strong>HyDE</strong><br/>
                            <small>Creates a hypothetical document to use the search text, based on recent messages</small>
                          </div>
                        </div>,
                        value: 'Analyze the current topic and user request to create a short hypothetical document to use as the search text. Respond with the hypothetical document, with no quotes or other descriptive information around it.',
                      },
                      {
                        label: <div className="list-left list-left-no-wrap">
                          <div>
                            <i className="far fa-robot fa-lg fa-fw"/>
                          </div>
                          <div className="thin-line-height">
                            <strong>Succinct Topic Search</strong><br/>
                            <small>Creates succinct search text based on recent messages</small>
                          </div>
                        </div>,
                        value: 'Analyze the current topic and user request from the following conversation to create an effective search string to use when looking up information. Respond with the exact best search string, with no quotes or other descriptive information around it.',
                      }
                    ]}
                    onChange={(v)=>{
                      this.props.onChange({
                        rag_agentic_prompt: v
                      })
                    }}
                    />

                  <CustomField
                    value={this.props.data.rag_agentic_prompt}
                    label="Prompt"
                    placeholder="Write a prompt here"
                    rows={5}
                    disabled={!canWrite}
                    onChange={(v)=>{
                      this.props.onChange({
                        rag_agentic_prompt: v.value
                      })
                    }}
                    />

                  <CustomField
                    name={'messages_to_use'}
                    label={<span>How many of the recent User & Agent messages should be used to analyze the current conversation?<br/><small className="text-muted">(defaults to 5)</small></span>}
                    description={""}
                    type="range"
                    min={1}
                    max={32}
                    step={1}
                    placeholder={1}
                    disabled={!canWrite}
                    value={this.props.data.messages_to_use}
                    onChange={e => {
                      this.props.onChange({
                        messages_to_use: e.value
                      })
                    }}
                    />
                  <p><small>Basic search agents run on GPT-4 Turbo.</small></p>
                </div>
              }
            </div>
              
            
          </div>
        }
      </div>
      

    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, componentReducer, knowledgeReducer, orgReducer, guiReducer } = state;

  return {
    userReducer, 
    knowledgeReducer,
    componentReducer,
    orgReducer,
    guiReducer
  }
}

const Tool = connect(mapStateToProps)(CustomComponent);

export default {
  createInitPacket,
  createStructuredPacket,
  createDownloadPacket,
  Tool
}
