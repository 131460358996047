import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import { push } from 'react-router-redux';

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect.js'
import Checkbox from '../../kit/components/Checkbox/Checkbox.js'
import Callout from '../../kit/components/Callout/Callout.js'

import isFeatureSwitchOn from '../../utilities/isFeatureSwitchOn'

import {
  tryToRegister
} from '../../actions/actions.export'

class RegisterForm extends Component {
  constructor(props){
    super(props);

    // check params on the url for "o" and "i"
    let urlParams = new URLSearchParams(window.location.search);
    let o = urlParams.get('o');
    let i = urlParams.get('i');

    this.state = {
      name: "",
      email: "",
      password: "",
      howd_you_find_us_other: "",
      code: "",
      org_id: o,
      invite_id: i,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(){
    const { dispatch } = this.props;


    dispatch(tryToRegister({
      email: this.state.email,
      password: this.state.password,
      howd_you_find_us: this.state.howd_you_find_us,
      howd_you_find_us_other: this.state.howd_you_find_us_other,
      code: this.state.code,
      invite_org_id: this.state.org_id,
      invite_id: this.state.invite_id
    }))
  }


  render(){

    const { userReducer, featuresReducer } = this.props;

    if(!isFeatureSwitchOn('registration', userReducer, featuresReducer)) return <span/>

    let canSubmit = this.state.email && !this.state.email_error && this.state.password && !this.state.password_error && this.state.agree;

    return <div className="margin-top-1rem">
      <form onSubmit={()=>{
        if(canSubmit) this.handleSubmit()
      }}>

        {
          (!isFeatureSwitchOn('open_registrations', userReducer, featuresReducer) && (!this.state.org_id || !this.state.invite_id)) &&
          <div>
            <CustomField
              name="field_code"
              label="Registration Code"
              placeholder="XXXXXXXX"
              description={
                isFeatureSwitchOn('waitlist', userReducer, featuresReducer) ?
                <span className="margin-bottom-3rem text-center"><Link to="/waitlist">Join our waitlist.</Link></span>
                :
                <span>Currently, sign ups are by invite only.</span>
              }
              maxLength={32}
              required={true}
              disabled={userReducer.tryingToRegister}
              serverError={userReducer.registerForm.errors.code}
              lastSubmit={userReducer.registerForm.lastSubmit.code}
              value={this.state.code}
              onChange={(e) => {
                this.setState({
                  code: e.value
                })
              }}
              />
          </div>
        }
        
        <CustomField
          name="field_email"
          regex="email"
          label="Email"
          placeholder="email@example.com"
          description={<span>&nbsp;</span>}
          required={true}
          disabled={userReducer.tryingToRegister}
          value={this.state.email}
          serverError={userReducer.registerForm.errors.email}
          lastSubmit={userReducer.registerForm.lastSubmit.email}
          onChange={(e) => {
            this.setState({
              email: e.value,
              email_error: e.error
            })
          }}
          />

        <CustomField
          name="field_password"
          type="password"
          label="Password"
          placeholder="********"
          minLength={8}
          description={<span>&nbsp;</span>}
          required={true}
          disabled={userReducer.tryingToRegister}
          value={this.state.password}
          serverError={userReducer.registerForm.errors.password}
          lastSubmit={userReducer.registerForm.lastSubmit.password}
          onChange={(e) => {
            this.setState({
              password: e.value,
              password_error: e.error
            })
          }}
          />

        <CustomSelect
          value={this.state.howd_you_find_us}
          label="How'd you hear about us?"
          placeholder="Choose one..."
          description="Optional, but it does help us quite a bit."
          disabled={userReducer.tryingToRegister}
          serverError={userReducer.registerForm.errors.howd_you_find_us}
          lastSubmit={userReducer.registerForm.lastSubmit.howd_you_find_us}
          onChange={(v) => {
            this.setState({ howd_you_find_us: v })
          }}
          options={
            [
              'Friend', 'Colleague', 'Teacher/Professor', 'Searching for AI tools', 'Other...'
            ]
          }
          />

        {
          this.state.howd_you_find_us === "Other..." &&
          <CustomField
            name="field_other"
            label="Other..."
            placeholder="If you'd like to share more about where you heard about us..."
            rows={2}
            maxLength={256}
            autoResizeRows={true}
            description="Optional."
            thinking={false}
            disabled={userReducer.tryingToRegister}
            serverError={userReducer.registerForm.errors.howd_you_find_us_other}
            lastSubmit={userReducer.registerForm.lastSubmit.howd_you_find_us_other}
            value={this.state.howd_you_find_us_other}
            onChange={(e) => {
              this.setState({
                howd_you_find_us_other: e.value
              })
            }}
            />
        }

        <div className="spacer-2rem"/>

        <Checkbox 
          value={this.state.agree} 
          onToggle={(v)=>{ this.setState({ agree: v }) }}
          description={<span>
            I have read and accept the <Link to="/docs/policies/terms-of-service" target="_blank" rel="noopener noreferrer">Terms</Link> and <Link to="/docs/policies/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>.
          </span>}
          style="align-left"
          disabled={userReducer.tryingToRegister}
          />

        <div className="spacer-2rem"/>

        {
          userReducer.registerForm.errors.message &&
          <Callout
            style="danger"
            dismissable={true}
            content={userReducer.registerForm.errors.message}
          />
        }
        
        <div className="list-right">
          <CustomButton
            display="Sign Up"
            color="success"
            thinking={userReducer.tryingToRegister}
            success={userReducer.registerSuccess}
            fail={userReducer.registerFail}
            disabled={!canSubmit}
            onClick={()=>{
              if(canSubmit) this.handleSubmit()
            }}
            />
        </div>
      </form>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, featuresReducer } = state;

  return {
    userReducer, featuresReducer
  }
}

export default connect(mapStateToProps)(RegisterForm);
