import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import ASCIIBackground from '../components/ASCIIBackground/ASCIIBackground.js';
import Modal from '../kit/components/Modal/Modal.js';
import CustomField from 'kit/components/CustomField/CustomField.js';
import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js';

const AnimatorRoute = () => {
  const { userReducer, guiReducer } = useSelector(state => ({
    userReducer: state.userReducer,
    guiReducer: state.guiReducer
  }));

  const [imagePath, setImagePath] = useState('/home-img/0W-logo.png'); // Initial/default image path
  const [showModal, setShowModal] = useState(false);
  const [foregroundColor, setForegroundColor] = useState('#000000');
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [charStep, setCharStep] = useState(2);
  const [placementMode, setPlacementMode] = useState('contain');
  const [playbackSpeed, setPlaybackSpeed] = useState(1); // Playback speed state
  const videoRef = useRef(null);

  useEffect(() => {
    window.document.title = "zerowidth.ai - animator";
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setImagePath(event.target.result); // Handle image file
          resetVideoHandling(); // Reset video handling when an image is dropped
        };
        reader.readAsDataURL(file);
      } else if (file.type.startsWith('video/mp4')) {
        setupVideo(file); // Setup video handling
      }
    }
  };

  const setupVideo = (file) => {
    const url = URL.createObjectURL(file);
    videoRef.current.src = url;
    videoRef.current.load();
    videoRef.current.onloadeddata = () => {
      videoRef.current.playbackRate = playbackSpeed; // Set playback rate
      extractFrames(); // Ensure video is ready to play then extract frames
    };
  };

  const resetVideoHandling = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      video.src = "";
      video.load(); // This effectively clears the previous video data
    }
  };

  const extractFrames = () => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext('2d');

    video.play(); // Ensure video is played

    const step = () => {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      setImagePath(canvas.toDataURL());
      if (!video.paused && !video.ended) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  };

  const handleDoubleClick = () => {
    setShowModal(true);
  };

  const hfStretchedClassList = "hf-stretched";

  return (
    <div 
      className={hfStretchedClassList}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDoubleClick={handleDoubleClick}
    >
      <video ref={videoRef} hidden style={{ display: 'none' }} loop />

      <Modal
        exitable={true}
        show={showModal}
        onExit={() => setShowModal(false)}
        acceptButtonLabel="Close"
        acceptable={true}
        onAccept={() => setShowModal(false)}
        content={<div>
          <CustomField
            label="Text Color"
            name="textColor"
            type="text"
            value={foregroundColor}
            onChange={(e) => setForegroundColor(e.value)}
          />
          <CustomField
            label="Background Color"
            name="backgroundColor"
            type="text"
            value={backgroundColor}
            onChange={(e) => setBackgroundColor(e.value)}
          />
          <CustomField
            label="Character Step"
            name="charStep"
            type="number"
            value={charStep}
            min={1}
            max={65}
            onChange={(e) => setCharStep(parseInt(e.value))}
          />
          <CustomSelect
            label="Placement Mode"
            name="placementMode"
            value={placementMode}
            onChange={(e) => setPlacementMode(e)}
            options={[
              { label: 'Cover', value: 'cover' },
              { label: 'Contain', value: 'contain' }
            ]}
          />
          <CustomSelect
            label="Playback Speed"
            name="playbackSpeed"
            value={playbackSpeed}
            onChange={(e) => setPlaybackSpeed(parseFloat(e))}
            description="Must be set prior to dropping a video file."
            options={[
              { label: '0.1x', value: 0.1},
              { label: '0.25x', value: 0.25},
              { label: '0.5x', value: 0.5 },
              { label: '1x', value: 1 },
            ]}
          />
        </div>}
      />
      <div className="fixed-big-image">
        <ASCIIBackground 
          imagePath={imagePath}
          foreground={foregroundColor}
          background={backgroundColor}
          charStep={charStep}
          fontSize={16}
          placementMode={placementMode}
        />
      </div>
    </div>
  );
};

export default AnimatorRoute;
