import moment from 'moment'

import CustomButton from 'kit/components/CustomButton/CustomButton.js'
import CustomField from 'kit/components/CustomField/CustomField.js'


import ComponentHome from '../tools/component/pages/ComponentHome/ComponentHome.js'
import ComponentFlow from '../tools/component/pages/ComponentFlow/ComponentFlow.js'

import ComponentReview from '../tools/component/pages/ComponentReview/ComponentReview.js'
import ComponentVersion from '../tools/component/pages/ComponentVersion/ComponentVersion.js'
import ComponentVersionFlow from '../tools/component/pages/ComponentVersion/ComponentVersionFlow.js'

import ComponentDynamicToolManager from '../tools/component/dynamic/ComponentDynamicToolManager.js'
import Team from '../tools/shared/Team/Team.js'

import ComponentFewShotGPTTool from '../tools/component/dynamic/ComponentFewShotGPTTool/ComponentFewShotGPTTool.js'
import ComponentFunctionTool from '../tools/component/dynamic/ComponentFunctionTool/ComponentFunctionTool.js'
import ComponentVariableTool from '../tools/component/dynamic/ComponentVariableTool/ComponentVariableTool.js'
import ComponentPluginsTool from '../tools/component/dynamic/ComponentPluginsTool/ComponentPluginsTool.js'
import ComponentTestTool from '../tools/component/dynamic/ComponentTestTool/ComponentTestTool.js'

import ComponentModel from '../tools/component/pages/ComponentModel/ComponentModel.js'
import ComponentMemoryOptions from '../tools/component/pages/ComponentMemoryOptions/ComponentMemoryOptions.js'
import ComponentContextOptions from '../tools/component/pages/ComponentContextOptions/ComponentContextOptions.js'
import ComponentQualityControl from '../tools/component/pages/ComponentQualityControl/ComponentQualityControl.js'


//Admin
import ComponentSettings from '../tools/component/pages/ComponentSettings/ComponentSettings.js'
// import ComponentDemoOptions from '../tools/component/pages/ComponentDemoOptions/ComponentDemoOptions.js'

import compareComponentVersions from '../utilities/compareComponentVersions'

import { tryToTestComponent } from 'actions/actions.export.js'

const tool_config = [
  { 
    name: undefined,
    display_name: "Overview",
    display_icon: "robot",
    roleless: true,
    component: (id, canWrite) => <ComponentHome id={id} canWrite={canWrite}/>,
    description: "The home dashboard for your agent.",
    keywords: "home dashboard summary",
    hideIf: component => {
      return component.flow_mode
    }
  },
  { 
    name: undefined,
    display_name: "Flow Builder",
    display_icon: "project-diagram",
    description: "Design the flow of information and interactions between your agent, LLMs, and users.",
    component: (id, canWrite) => <ComponentFlow id={id} canWrite={canWrite}/>,
    keywords: "flow chain agent user interaction",
    roleless: true,
    fullWidth: true,
    noScroll: true,
    noFooter: true,
    hideIf: component => {
      return !component.flow_mode
    }
  },  
  { 
    name: "few_shots",
    display_name: "Instructions",
    display_name_singular: "Instruction",
    display_name_plural: "Instructions",
    display_icon: "comment-alt-smile",
    description: "Adapt the model to respond and behave in a desired way.",
    tool: ComponentFewShotGPTTool,
    maxInstancesPerComponent: 25,
    hideIf: component => {
      return component.flow_mode
    },
    sortable: true,
    getThumbnailDetails: (content) => {
      return {
        display_name: content.display_name,
        order: content.order,
        messages: content.messages
      };
    },
    getRenderedDetails: (content) => {
      return <div>
        {
          (content.messages || []).map((m, i) => {
            return <div key={i} className="margin-top-1rem">
              <div className="list-left">
                
                {
                  m.role === 'user' &&  <small className="text-components"><i className="far fa-user fa-fw icon-before-text"/>From the User</small>
                }
                {
                  (m.role === 'assistant' || m.role === 'agent') && <small className="text-components"><i className="far fa-robot fa-fw icon-before-text"/>From the Agent</small>
                }
                {
                  m.role === 'system' &&  <small className="text-components"><i className="far fa-chalkboard-teacher fa-fw icon-before-text"/>Instructional Message</small> 
                }
                {content.enabled === false && <small className="text-danger text-900"><i className="far fa-toggle-off fa-fw icon-before-text"/>Disabled</small>}
              </div>
              
              <pre className={"padding-1rem bg-gs9 margin-bottom-05rem margin-top-05rem text-readable " + (content.enabled === false ? "text-muted": "")}><small className="text-ellipsis-4-lines ">{m.content}</small></pre>
            </div>
          })
        }
        
      </div>
    },
    dynamicComponent: (id, subpage, item, canWrite) => <ComponentDynamicToolManager 
      id={id} 
      subpage={subpage} 
      item={item} 
      canWrite={canWrite} 
      type="few_shots" 
      typeSingular="Instruction"
      tool={ComponentFewShotGPTTool}
      key={id + '_few_shots'}
      />,
    getCounter: (component) => {
      let currentVersion = (component.versions || []).find(v => v.id === 'draft');
      if(!currentVersion) return;
      // return currentVersion.total_few_shots;
      return (currentVersion.few_shots || []).filter(c => !c.deleted).length;
    },
    getSubpageItemData: (component) => {

      let currentVersion = (component.versions || []).find(v => v.id === 'draft');
      if(!currentVersion) return [];

      return (currentVersion.few_shots || []).filter(c => !c.deleted).map((c, i) => {
        return {
          key: i,
          id: c.id,
          display_icon: 'comments',
          display_name: c.display_name || 'Untitled',
          timestamp: c.created_at
        }
      })
    },
  },
  { 
    name: "variables",
    display_name: "Variables",
    display_name_singular: "Variable",
    display_name_plural: "Variables",
    display_icon: "brackets-curly",
    description: "Configure dynamic context to leverage throughout your AI.",
    tool: ComponentVariableTool,
    maxInstancesPerComponent: 25,
    sortable: true,
    getThumbnailDetails: (content) => {
      return {
        display_name: content.display_name,
        key: content.key,
        description: content.description,
        default: content.default,
        placeholder: content.placeholder,
        order: content.order,
        type: content.type,
        min_length: content.min_length,
        max_length: content.max_length,
        min: content.min,
        max: content.max,
        options: content.options
      };
    },
    getRenderedName: (content) => {
      return <span>
        ${'{'}{content.key}{'}'}
      </span>
    },
    getRenderedDetails: (content) => {
      return <div>
        <div className="margin-top-1rem">
          Source: <span className="text-900">{content.source === 'external' && "External"}{content.source === 'rag' && "RAG Based"}</span><br/>
        </div>
      </div>
    },
    dynamicComponent: (id, subpage, item, canWrite) => <ComponentDynamicToolManager 
      id={id} 
      subpage={subpage} 
      item={item} 
      canWrite={canWrite} 
      type="variables" 
      typeSingular="Variable"
      tool={ComponentVariableTool}
      key={id + '_variables'}
      />,
    getCounter: (component) => {
      let currentVersion = (component.versions || []).find(v => v.id === 'draft');
      if(!currentVersion) return;
      // return currentVersion.total_variables;
      return (currentVersion.variables || []).filter(c => !c.deleted).length;
    },
    getSubpageItemData: (component) => {

      let currentVersion = (component.versions || []).find(v => v.id === 'draft');
      if(!currentVersion) return [];

      return (currentVersion.variables || []).filter(c => !c.deleted).map((c, i) => {
        return {
          key: i,
          id: c.id,
          display_icon: 'code',
          // custom_icon: <div className="component-dynamic-menu-custom-icon">TBD</div>,
          display_name: c.display_name || 'Untitled',
          timestamp: c.created_at
        }
      })
    },
    // hideIf: component => {
    //   // return component.flow_mode
    // }
  },
  { 
    name: "functions",
    display_name: "Functions",
    display_name_singular: "Function",
    display_name_plural: "Functions",
    display_icon: "code",
    description: "Expose aspects of your code for the agent to interact with.",
    tool: ComponentFunctionTool,
    maxInstancesPerComponent: 25,
    hideIf: component => {
      return component.flow_mode
    },
    getThumbnailDetails: (content) => {
      return {
        display_name: content.display_name,
        description: content.description,
        parameters: content.parameters
      };
    },

    getRenderedDetails: (content) => {
      return <div>
      <div className="margin-top-1rem">
        <small className="text-ellipsis-4-lines margin-bottom-1rem margin-top-05rem">{content.description}</small>
      </div>
    </div>
    },
    dynamicComponent: (id, subpage, item, canWrite) => <ComponentDynamicToolManager 
      id={id} 
      subpage={subpage} 
      item={item} 
      canWrite={canWrite} 
      type="functions" 
      typeSingular="Function"
      tool={ComponentFunctionTool}
      key={id + '_functions'}
      />,
    getCounter: (component) => {
      let currentVersion = (component.versions || []).find(v => v.id === 'draft');
      if(!currentVersion) return;

      return (currentVersion.functions || []).filter(c => !c.deleted).length;
    },
    getSubpageItemData: (component) => {

      let currentVersion = (component.versions || []).find(v => v.id === 'draft');
      if(!currentVersion) return [];

      return (currentVersion.functions || []).filter(c => !c.deleted).map((c, i) => {
        return {
          key: i,
          id: c.id,
          display_icon: 'brackets-curly',
          display_name: c.display_name || 'Untitled',
          timestamp: c.created_at
        }
      })
    },
  },
  { 
    name: "plugins",
    display_name: "Plugins",
    display_name_singular: "Plugin",
    display_name_plural: "Plugins",
    display_icon: "puzzle-piece",
    description: "Extend the capabilities of your agent with customizable ZeroWidth managed plugins.",
    tool: ComponentPluginsTool,
    maxInstancesPerComponent: 25,
    disableCopy: true,
    hideIf: component => {
      return component.flow_mode
    },
    getRenderedGallery: (contentList, props, onSelect) => { 
      return <div className="row">
        {
          props.intelReducer.plugins.map((plugin, i) => {

            // do you already have one of these installed?
            let alreadyInstalledCount = contentList.filter(p => p.plugin_id === plugin.id).length;

            return <div className="col-md-12 col-lg-6 col-xl-4 flex-column-split margin-bottom-2rem" key={i}>
              <div className="box margin-bottom-1rem flex-grow flex-column-split">
                <img src={"/img/plugin_" + plugin.id + ".png"} alt={plugin.display_name} className="img-fluid margin-bottom-2rem"/>
                <div className="flex-grow">
                  <h5 className="no-margin">
                    {/* <i className={"fal fa-fw icon-before-text fa-" + plugin.icon} /> */}
                    {plugin.display_name}
                  </h5>
                  <p className="margin-top-1rem thin-line-height margin-bottom-3rem">
                    <small>{plugin.description}</small>
                  </p>
                </div>
                <div className="flex-split">
                  <small className="text-muted">
                    {
                      alreadyInstalledCount > 0 &&
                      <span>{ alreadyInstalledCount } added</span>
                    }
                  </small>
                  <CustomButton
                    display={
                      (alreadyInstalledCount && !plugin.can_have_multiple) ?
                      "Limit Reached"
                      :
                      <span><i className="far fa-plus icon-before-text"/>Add & Configure</span>
                    }
                    size="xs"
                    disabled={alreadyInstalledCount && !plugin.can_have_multiple}
                    color="black"
                    onClick={()=>{
                      onSelect({
                        plugin_id: plugin.id
                      })
                    }}
                    />
                </div>
              </div>
            </div>
          })
        }
      </div>
    },
    getRenderedThumbnailIcon: (content, props) => {
      // get plugin name from intelReducer
      const { intelReducer } = props;
      if(!content.plugin_id) return <i className="far fa-puzzle-piece text-muted"/>;
      const plugin = intelReducer.plugins.find(p => p.id === content.plugin_id);
      return plugin ? <div>{(plugin.icons || []).map((icon, i) => <i className={"fa-fw " + icon} key={i}/> )}</div> : <i className="far fa-puzzle-piece text-muted"/>
    },
    getRenderedName: (content, props) => {
      // get plugin name from intelReducer
      const { intelReducer } = props;
      if(!content.plugin_id) return <span className="text-400 text-muted">Pending Plugin</span>
      const plugin = intelReducer.plugins.find(p => p.id === content.plugin_id);
      return plugin ? plugin.display_name : content.plugin_id;
    },
    getThumbnailDetails: (content) => {
      return {
        plugin_id: content.plugin_id,
        plugin_options: content.plugin_options
      };
    },

    getRenderedDetails: (content, props) => {
      // get plugin name from intelReducer
      const { intelReducer } = props;
      if(!content.plugin_id) return <span/>
      const plugin = intelReducer.plugins.find(p => p.id === content.plugin_id);

      // for each required element of the plugin's options, check if it exists in the content, if one is missing then we show the warning
      let missingOptions = [];
      if(plugin){
        if(plugin.options){
          plugin.options.forEach(o => {
            if(o.mode === 'required'){
              if(!content.plugin_options || !content.plugin_options[o.name]){
                missingOptions.push(o.name);
              }
            }
          })
        }
      }

      return <div>
      <div className="margin-top-1rem">
        {
          (content.plugin_id && missingOptions.length === 0) ? 
        
          <small className="text-ellipsis-4-lines margin-top-05rem">
            {
              (content.enabled) ? 
              <strong className="text-success"><i className="far fa-check icon-before-text"/>Enabled</strong> 
              :
              <strong className="text-danger"><i className="far fa-times icon-before-text"/>Disabled</strong>
            }
            {
              content.plugin_id === 'functional_rag' && 
              <span> - {(content.plugin_options || {}).knowledge_function_name}()</span>
            }
          </small>
          :
          <small className="text-ellipsis-4-lines margin-top-05rem">
            <strong className="text-warning"><i className="far fa-exclamation-triangle icon-before-text"/>Click to finish setting up</strong> 
          </small>
        }
      </div>
    </div>
    },
    dynamicComponent: (id, subpage, item, canWrite) => <ComponentDynamicToolManager 
      id={id} 
      subpage={subpage} 
      item={item} 
      canWrite={canWrite} 
      type="plugins" 
      typeSingular="Plugin"
      tool={ComponentPluginsTool}
      key={id + '_plugins'}
      />,
    getCounter: (component) => {
      let currentVersion = (component.versions || []).find(v => v.id === 'draft');
      if(!currentVersion) return;

      return (currentVersion.plugins || []).filter(c => !c.deleted).length;
    },
    getSubpageItemData: (component) => {

      let currentVersion = (component.versions || []).find(v => v.id === 'draft');
      if(!currentVersion) return [];

      return (currentVersion.plugins || []).filter(c => !c.deleted).map((c, i) => {
        return {
          key: i,
          id: c.id,
          display_icon: 'puzzle-piece',
          timestamp: c.created_at
        }
      })
    },
    hideIf: component => {
      return component.flow_mode
    }
  }, 
  { 
    name: "tests",
    display_name: "Tests",
    display_name_singular: "Test",
    display_name_plural: "Tests",
    display_icon: "spell-check",
    featureSwitch: "component_tool_tests",
    fullWidth: true,
    description: "Test your AI's responses to ensure they meet your expectations.",
    tool: ComponentTestTool,
    maxInstancesPerComponent: 25,
    bulkActions: [
      {
        display_name: "Run All Tests",
        icon: "spell-check",
        action: "run_all_tests",
        color: "grey",
        confirm: "Are you sure you want to run all tests for this agent?",
        tooltip: "Run all tests for this agent.",
        requiresWrite: true,
        onClick: (component, props) => {
          const { dispatch } = props;

          // loop through all tests of the draft version and run them
          let currentVersion = (component.versions || []).find(v => v.id === 'draft');
          if(!currentVersion) return;

          (currentVersion.tests || []).filter(c => !c.deleted).forEach(test => {
            dispatch(tryToTestComponent({
              id: component.id,
              version: 'draft',
              test_id: test.id,
              data: {
                data: {
                  messages: test.test_data.messages,
                  variables: test.test_data.variables
                }
              }
            }));
          })
        }
      }
    ],
    getThumbnailDetails: (content) => {
      return {
        test_data: content.test_data,
        expected_response: content.expected_response,
        // last_test_response: content.last_test_response
      };
    },

    getRenderedDetails: (content, props) => {

      // is this test currently running?
      let isRunning = false;

      if((props.componentReducer.activeTestRequests[content.id] || {}).trying){
        return <div>
          <small className="text-muted">Running...</small>
        </div>
      }


      if(content.last_test_result){
        // return the last date it was ran
        return <div>
          <small className="text-muted">Last tested {moment(content.last_test_result.output_data.timestamp).fromNow()}</small>
        </div>
      } else {
        return <div>
          <small className="text-muted">Not yet tested.</small>
        </div>
      }


      // let analysisTraits = [
      //   {
      //     "name": "accuracy",
      //     "label": "Accuracy",
      //     "tooltip": "Evaluates the agent's response for factual correctness compared to the expected or goal response, ensuring alignment with the desired outcome."
      //   },
      //   {
      //     "name": "format",
      //     "label": "Format",
      //     "tooltip": "Evaluates the agent's response for alignment with the expected format, including length, structure, and organization, ensuring it meets the specific presentation and detail level of the goal response."
      //   },
      //   {
      //     "name": "coherence",
      //     "label": "Coherence",
      //     "tooltip": "Measures how well the agent's response maintains a logical structure and clarity, compared to the expected logical flow and coherence of the goal response."
      //   },
      //   {
      //     "name": "groundedness",
      //     "label": "Grounding",
      //     "tooltip": "Checks the extent to which the agent's response is supported by evidence and aligns with established knowledge, compared to the evidence and grounding expected in the goal response."
      //   },
      //   {
      //     "name": "tone",
      //     "label": "Tone",
      //     "tooltip": "Evaluates the appropriateness of the agent's tone, sentiment, and character, ensuring it aligns with the tone, sentiment, and character expected in the goal response."
      //   }
      // ]
      // ;
      // return <div>
      // <div className="margin-top-05rem">
      //   {
      //     content.last_test_analysis ? 
      //     <div className="row">
      //       {
      //         analysisTraits.map((t, i) => {
      //           if(!content.last_test_analysis[t.name]) return;
      //           return <div className="col" key={i}>
      //             <small>{t.label}</small>
      //             <div className="progress-bar-wrapper  flex-grow">
      //               <div className={
      //                 "progress-bar-inner " + 
      //                 (content.last_test_analysis[t.name].grade < 4 ? "bg-danger" : "") +
      //                 (content.last_test_analysis[t.name].grade >= 4 && content.last_test_analysis[t.name].grade < 7 ? "bg-warning" : "") +
      //                 (content.last_test_analysis[t.name].grade >= 7 ? "bg-success" : "")
      //               } 
      //                 style={{width: (content.last_test_analysis[t.name].grade / 10) * 100 + '%'}}/>
      //             </div>
      //           </div>
      //         })
      //       }
            
      //     </div>
      //     :
      //     <div>
      //       <small className="text-muted">Not yet tested.</small>
      //     </div>
      //   }
      // </div>
    // </div>
    },
    thumbnailColSize: 12,
    dynamicComponent: (id, subpage, item, canWrite) => <ComponentDynamicToolManager 
      id={id} 
      subpage={subpage} 
      item={item} 
      canWrite={canWrite} 
      type="tests" 
      typeSingular="Test"
      tool={ComponentTestTool}
      key={id + '_tests'}
      />,
    getCounter: (component) => {
      let currentVersion = (component.versions || []).find(v => v.id === 'draft');
      if(!currentVersion) return;

      return (currentVersion.tests || []).filter(c => !c.deleted).length;
    },
    getSubpageItemData: (component) => {

      let currentVersion = (component.versions || []).find(v => v.id === 'draft');
      if(!currentVersion) return [];

      return (currentVersion.tests || []).filter(c => !c.deleted).map((c, i) => {
        return {
          key: i,
          id: c.id,
          display_icon: 'spell-check',
          timestamp: c.created_at
        }
      })
    },
    hideIf: component => {
      return component.flow_mode
    }
  },
  { 
    name: "model",
    display_name: "Model",
    display_icon: "brain",
    description: "Choose a foundational LLM to build your agent upon.",
    component: (id, canWrite) => <ComponentModel id={id} canWrite={canWrite}/>,
    keywords: "model openai llm gpt gpt-3 gpt-4 palm2 palm models",
    hideIf: component => {
      return component.flow_mode
    }
  },  
  { 
    name: "context-options",
    display_name: "Context",
    display_icon: "cut",
    description: "Configure how your agent handles reaching its context window limit.",
    component: (id, canWrite) => <ComponentContextOptions id={id} canWrite={canWrite}/>,
    keywords: "knowledge size options vector embed embeddings",
    hideIf: component => {
      return component.flow_mode
    }
  },
  // { 
  //   name: "quality-control",
  //   display_name: "QC",
  //   display_icon: "check-circle",
  //   fullWidth: true,
  //   description: "Track and measure your agent's performance and accuracy.",
  //   component: (id, canWrite) => <ComponentQualityControl id={id} canWrite={canWrite}/>,
  //   keywords: "inaccuracies quality control flag report",
  //   hideIf: component => {
  //     return component.flow_mode
  //   }
  // },
  { 
    name: "memory-options",
    display_name: "Knowledge Options",
    display_icon: "database",
    description: "(Deprecated) Configure how your agent connects to a knowledge base.",
    hideIf: component => {
      // Convert component.created_at to a Date object
      const componentCreatedAt = new Date(component.created_at);
      
      // Define the comparison dates
      const janFirst2024 = new Date('2024-01-01');
      const marFirst2024 = new Date('2024-03-01');
      
      // Get the current date
      const now = new Date();
      
      // Check if the component was created after Jan 1, 2024, or if the current date is before Mar 1, 2024
      return componentCreatedAt > janFirst2024 || now < marFirst2024;
    },    
    component: (id, canWrite) => <ComponentMemoryOptions id={id} canWrite={canWrite}/>,
    keywords: "knowledge size options vector embed embeddings"
  },
  // { 
  //   name: "demo",
  //   display_name: "Demo GUI",
  //   fullWidth: true,
  //   description: "Configure the ZeroWidth demo interface appearance for your agent.",
  //   display_icon: "play",
  //   component: (id, canWrite) => <ComponentDemoOptions id={id} canWrite={canWrite}/>,
  //   keywords: "demo code play try test"
  // },
  // { 
  //   name: "review",
  //   display_name: "History",
  //   display_icon: "history",
  //   description: "Compare and review changes to your AI.",
  //   manual_content: true,
  //   showVersionBar: true,
  //   fullWidth: true,
  //   getCounter: (component) => {
  //     if(component.versions){
  //       let c1 = component.versions.find(v => v.id === component.current_version);
  //       let c2 = component.versions.find(v => v.id === 'draft');

  //       if(component.current_version === 'draft'){
  //         c1 = {};
  //       }
  //       if(!c2) return;

  //       let comparison = compareComponentVersions(c1, c2);
  //       let counter = comparison.changes.filter(c => c.kind !== 'none').length;
  //       if(counter > 0){
  //         return <span style={{position: 'relative', left: 8}} className="text-circled-danger">{counter}</span>
  //       }
  //     }
  //   },
   
  //   component: (id, canWrite) => <ComponentReview id={id} canWrite={canWrite}/>,
  //   keywords: "publish compare edits",
  //   hideIf: component => {
  //     return component.flow_mode
  //   }
  // },
  
  { 
    name: "versions",
    display_name: "Versions",
    display_icon: "history",
    description: "View and compare your agent's past versions.",
    fullWidth: true,
    component: (id, canWrite, item) => <ComponentVersion id={id} canWrite={canWrite} item={item}/>,
    keywords: "history versions past current edit",
    hideIf: component => {
      return component.flow_mode
    }
  },
  { 
    name: "history",
    display_name: "History",
    display_icon: "history",
    description: "View and compare your AI's past versions.",
    fullWidth: true,
    component: (id, canWrite, item) => <ComponentVersionFlow id={id} canWrite={canWrite} item={item}/>,
    keywords: "history versions past current edit publish",
    hideIf: component => {
      return !component.flow_mode
    }
  },
  { 
    name: "settings",
    display_name: "Settings",
    display_icon: "cog",
    description: "Configure the settings like visibility and metadata for your agent.",
    component: (id, canWrite, item) => <ComponentSettings id={id} canWrite={canWrite}  item={item}/>,
    keywords: "name description meta info information metadata naming title settings",
    hideIf: component => {
      if(!component.myRole) return true;
      if(component.myRole.role === 'admin'){
        return false;
      }
      if(component.myRole.roles){
        if(component.myRole.roles['team'] === 'write'){
          return false;
        }
        if(component.myRole.roles['settings'] === 'write'){
          return false;
        }
        if(component.myRole.roles['admin'] === 'write'){
          return false;
        }
      }
      return true;
    },
    roleRequired: 'admin'
  },
  // { 
  //   name: "team",
  //   display_name: "Team & Permissions",
  //   display_icon: "users",
  //   description: "Invite others from your organization to collaborate on your agent.",
  //   component: (id, canWrite, item) => <Team id={id} kind={'components'} canWrite={canWrite} item={item}/>,
  //   keywords: "users user members member roles privilege editor share team",
  //   hideIf: component => {
  //     return component.flow_mode
  //   }
  // },
]


export default tool_config