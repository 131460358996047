import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomField from 'kit/components/CustomField/CustomField'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import Hydrate from 'components/Hydrate/Hydrate';

export const NodeSettings_semantic_search = ({org_id, node, setNodeSettings, canWrite }) => {

  const [settings, setSettings] = useState(node.settings || {});

  const knowledgeReducer = useSelector(state => state.knowledgeReducer);

  let knowledgeCacheList = Object.keys(knowledgeReducer.cache).map(id => knowledgeReducer.cache[id]);

    
  let knowledge = [];
  if(org_id){
    knowledge = knowledgeCacheList.filter(p => p.scope === org_id);
  }

  if (!node.settings) {
    return null;
  }

  return (
    <div>
      <CustomSelect
        placeholder="Select one of your organization's Knowledge Bases"
        disabled={!canWrite}
        large={true}
        label="Which Knowledge Base should be searched?"
        value={node.settings.knowledge_id}
        options={(knowledge).map((k, i)=>{
          return {
            value: k.id,
            label: <div>
              <span className="text-900">{k.display_name}</span> <br/>
              <div className="text-ellipsis-2-lines">
                <small className="text-muted">by <Hydrate id={k.created_by} type="user"/></small>
              </div>
            </div>
          }
        })}
        onChange={e => {
          setNodeSettings({
            knowledge_id: e
          })
        }}
        />
      
    </div>
  );
}


export const NodeRenderer_semantic_search = ({ node, type, interactive, setNodeSettings }) => {
  const [value, setValue] = useState(node.settings.value || 0);

  return (
    <div className="padding-1rem thin-line-height flex-split">
      
    </div>
  );
}