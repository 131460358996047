import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import Modal from '../../kit/components/Modal/Modal.js'

import {
  tryToGetUserLog,
  clearUserLog,
  tryToLogoutEverywhere
} from '../../actions/actions.export'

class UserLoginHistory extends Component {
  constructor(props){
    super(props);

    this.state = {
      logoutEverywhereModalOpen: false
    }
  }

  componentDidMount(){
    const { dispatch, userReducer } = this.props;

    dispatch(tryToGetUserLog({
      type: 'login'
    }))
  }

  render(){

    const { dispatch, userReducer } = this.props;


    return <div>
      <div className="margin-bottom-2rem margin-top-05rem flex-split flex-split-align-start">
        <div>
          <h4 className="no-margin">Login History</h4>
          <small>
            View a history of your logins across all devices.
          </small>
        </div>
        <div className="list-right">
          <div className="">
          {
            (userReducer.tryingToGetUserLog === "login") ?
            <i className="far fa-spinner-third fa-spin text-muted fa-fw"/>
            :
              <i className="far fa-sync-alt clickable icon-muted-until-hover fa-fw" onClick={()=>{ 
              dispatch(tryToGetUserLog({type: 'login'}))
            }}/>
          }
        </div>
          <CustomButton
            display="Logout Everywhere"
            size="small"
            color="black"
            onClick={()=>{ this.setState({logoutEverywhereModalOpen: true})}}
            />
        </div>
      </div>
      <div className="box">
        <Modal
          maxWidth={500}
          show={this.state.logoutEverywhereModalOpen}
          content={<div>
            <h3 className="no-margin"><i className="fal fa-door-open"/>&nbsp; Logout Everywhere</h3>
            <p className="margin-bottom-2rem">
              Not all logins shown under <span className="text-900">Login History</span> may still be logged-in, but you can force-logout of all active sessions using the button below. 
            </p>
            <div className="list-right">  
              <CustomButton
                display="Cancel"
                onClick={()=>{ this.setState({logoutEverywhereModalOpen: false}) }}
                />
              <CustomButton
                display="Logout Everywhere"
                color="black"
                thinking={userReducer.tryingToLogoutEverywhere}
                success={userReducer.logoutEverywhereSuccess}
                fail={userReducer.logoutEverywhereFail}
                onClick={()=>{ 
                  dispatch(tryToLogoutEverywhere());
                }}
                />
            </div>
          </div>}
          exitable={true}
          onExit={()=>{ this.setState({logoutEverywhereModalOpen: false}) }}
          />

        {
          (userReducer.log.login) &&
          <div>
            {
              userReducer.log.login.map((e, i)=>{
                return <div className="subtle-section" key={i}>
                  <div className="text-700 margin-bottom-05rem">
                    <i className="far fa-door-open text-semi-muted icon-before-text"/>{e.event}
                  </div>
                  <div className="list-left margin-bottom-05rem no-margin-top text-smaller">
                    <span>{moment(e.timestamp).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
                    <span className="text-very-muted">|</span>
                    <span className="text-muted">{moment(e.timestamp).fromNow()}</span>
                  </div>
                  <p className="no-margin-top no-margin-bottom text-smaller">
                    IP Address: <span className="text-700">{e.req_ip}</span>
                  </p>
                </div>
              })
            }
          </div>
        }

        {
          (userReducer.log.login && !userReducer.logEmpty.login) && 
          <div className="flex-column-center margin-top-2rem">
            <CustomButton
              display="Load More"
              thinking={userReducer.tryingToGetUserLog === "login"}
              success={userReducer.getUserLogSuccess}
              fail={userReducer.getUserlogFail}
              onClick={()=>{
                dispatch(tryToGetUserLog({
                  type: 'login',
                  startAfter: userReducer.log.login[userReducer.log.login.length - 1].id
                }))
              }}
              />
          </div>
        }

        {
          userReducer.logEmpty.login &&
          <p className="text-center text-muted text-smaller">
            You've reached the end of the log.
          </p>
        }

      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(UserLoginHistory);
