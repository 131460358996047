var Cookies = require('js-cookie');

export const userReducer = (state = {
  ready: false,
  isLoggedIn: false,
  cache: {},
  cacheRequests: [],
  log: {},
  logEmpty: {},
  myData: {},
  notifications: [],
  notificationsEmpty: false,
  joinWaitlistForm:             {errors: {}, lastSubmit: {}},
  registerForm:                 {errors: {}, lastSubmit: {}},
  waitlistForm:                 {errors: {}, lastSubmit: {}},
  verifyEmailForm:              {errors: {}, lastSubmit: {}},
  loginForm:                    {errors: {}, lastSubmit: {}},
  forgotForm:                   {errors: {}, lastSubmit: {}},
  resetPasswordForm:            {errors: {}, lastSubmit: {}},
  editUserProfileForm:          {errors: {}, lastSubmit: {}},
  editUserEmailAddressForm:     {errors: {}, lastSubmit: {}},
  editUserEmailSettingsForm:    {errors: {}, lastSubmit: {}},
  editUserPasswordForm:         {errors: {}, lastSubmit: {}},
  deleteUserAccountForm:        {errors: {}, lastSubmit: {}},
  editUserProfileVisibilityForm:        {errors: {}, lastSubmit: {}},
}, action) => {

  switch(action.type){
    case 'REQUEST_LOGOUT':
      return {
        ...state,
        tryingToLogout: true,
      }

    case 'RECEIVE_LOGOUT_FAIL':
      return {
        ...state,
        tryingToLogout: false,
        logoutFail: new Date().getTime()
      }

    case 'RECEIVE_LOGOUT_SUCCESS':
      Cookies.remove(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
      Cookies.remove(process.env.REACT_APP_COOKIE_AUTH);
      window.location.replace('/sign-in');
      window.location.reload();
      return {
        ...state
      }


    // case 'LOGOUT':
    //   Cookies.remove(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
    //   Cookies.remove(process.env.REACT_APP_COOKIE_AUTH);
    //   window.location.replace('/sign-in');
    //   return {
    //     ...state
    //   }
    
    


    case 'REQUEST_JOIN_WAITLIST':
      return {
        ...state,
        tryingToJoinWaitlist: true,
        joinWaitlistForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_JOIN_WAITLIST_FAIL':
       return {
        ...state,
        tryingToJoinWaitlist: false,
        joinWaitlistFail: new Date().getTime(),
        joinWaitlistForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_JOIN_WAITLIST_SUCCESS':
       return {
        ...state,
        tryingToJoinWaitlist: false,
        joinWaitlistSuccess: new Date().getTime(),
        joinWaitlistForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_DELETE_USER_ACCOUNT':
      return {
        ...state,
        tryingToDeleteUserAccount: true,
        deleteUserAccountForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DELETE_USER_ACCOUNT_FAIL':
       return {
        ...state,
        tryingToDeleteUserAccount: false,
        deleteUserAccountFail: new Date().getTime(),
        deleteUserAccountForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DELETE_USER_ACCOUNT_SUCCESS':
       return {
        ...state,
        tryingToDeleteUserAccount: false,
        deleteUserAccountSuccess: new Date().getTime(),
        deleteUserAccountForm: {errors: {}, lastSubmit: {}}
      }





    case 'REQUEST_REGISTER':
      return {
        ...state,
        tryingToRegister: true,
        registerForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_REGISTER_FAIL':
       return {
        ...state,
        tryingToRegister: false,
        registerFail: new Date().getTime(),
        registerForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_REGISTER_SUCCESS':
       return {
        ...state,
        tryingToRegister: false,
        registerSuccess: new Date().getTime(),
        registerForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_RESEND_EMAIL_VERIFICATION_CODE':
      return {
        ...state,
        tryingToResendEmailVerificationCode: true,
      }

    case 'RECEIVE_RESEND_EMAIL_VERIFICATION_CODE_FAIL':
       return {
        ...state,
        tryingToResendEmailVerificationCode: false,
        resendEmailVerificationCodeFail: new Date().getTime(),
      }

    case 'RECEIVE_RESEND_EMAIL_VERIFICATION_CODE_SUCCESS':
       return {
        ...state,
        tryingToResendEmailVerificationCode: false,
        resendEmailVerificationCodeSuccess: new Date().getTime()
      }


    case 'REQUEST_SEND_PASSWORD_RESET_LINK':
      return {
        ...state,
        tryingToSendPasswordResetLink: true,
      }

    case 'RECEIVE_SEND_PASSWORD_RESET_LINK_FAIL':
       return {
        ...state,
        tryingToSendPasswordResetLink: false,
        sendPasswordResetLinkFail: new Date().getTime(),
      }

    case 'RECEIVE_SEND_PASSWORD_RESET_LINK_SUCCESS':
       return {
        ...state,
        tryingToSendPasswordResetLink: false,
        sendPasswordResetLinkSuccess: new Date().getTime()
      }




    case 'REQUEST_RESET_PASSWORD':
      return {
        ...state,
        tryingToResetPassword: true,
        resetPasswordForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_RESET_PASSWORD_FAIL':
       return {
        ...state,
        tryingToResetPassword: false,
        resetPasswordFail: new Date().getTime(),
        resetPasswordForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_RESET_PASSWORD_SUCCESS':
       return {
        ...state,
        tryingToResetPassword: false,
        resetPasswordSuccess: new Date().getTime(),
        resetPasswordForm: {errors: {}, lastSubmit: {}}
      }



    case 'REQUEST_HEARTBEAT':
      return {
        ...state,
        tryingToHeartBeat: true,
        heartbeatForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_HEARTBEAT_FAIL':
       return {
        ...state,
        tryingToHeartBeat: false,
        heartbeatFail: new Date().getTime(),
        heartbeatForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_HEARTBEAT_SUCCESS':
       return {
        ...state,
        tryingToHeartBeat: false,
        heartbeatSuccess: new Date().getTime(),
        heartbeatForm: {errors: {}, lastSubmit: {}},
        myData: action.data,
        bumped: action.data.bumped,
        suspended: action.data.suspended,
        maintenance: action.data.maintenance
      }



    case 'REQUEST_VERIFY_EMAIL':
      return {
        ...state,
        tryingToVerifyEmail: true,
        verifyEmailForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_VERIFY_EMAIL_FAIL':
       return {
        ...state,
        tryingToVerifyEmail: false,
        verifyEmailFail: new Date().getTime(),
        verifyEmailForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_VERIFY_EMAIL_SUCCESS':
      return {
        ...state,
        isLoggedIn: true,
        tryingToVerifyEmail: false,
        verifyEmailSuccess: new Date().getTime(),
        bumped: false,
        email_verified: true,
        myData: action.data,
        verifyEmailForm: {errors: {}, lastSubmit: {}}
      }


    case 'REQUEST_LOGIN':
      return {
        ...state,
        tryingToLogin: true,
        loginForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_LOGIN_FAIL':
       return {
        ...state,
        tryingToLogin: false,
        loginFail: new Date().getTime(),
        loginForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_LOGIN_SUCCESS':

       return {
        ...state,
        tryingToLogin: false,
        loginSuccess: new Date().getTime(),
        isLoggedIn: true,
        loginForm: {errors: {}, lastSubmit: {}},
        myData: action.data
      }



    case 'REQUEST_GET_USER_PROFILE':

      var cacheRequests = state.cacheRequests;
      cacheRequests.push(action.data.lastSubmit.id);

      return {
        ...state,
        cacheRequests: cacheRequests,
        tryingToGetUserProfile: true,
      }

    case 'RECEIVE_GET_USER_PROFILE_FAIL':
      return {
        ...state,
        tryingToGetUserProfile: false
      }

    case 'RECEIVE_GET_USER_PROFILE_SUCCESS':

      var cache = state.cache;
      cache[action.data.id] = action.data;

      return {
        ...state,
        tryingToGetUserProfile: false,
        cache: cache
      }


    case 'REQUEST_EDIT_USER_PROFILE':
      return {
        ...state,
        tryingToEditUserProfile: true,
      }

    case 'RECEIVE_EDIT_USER_PROFILE_FAIL':
      return {
        ...state,
        tryingToEditUserProfile: false,
        editUserProfileFail: new Date().getTime()
      }

    case 'RECEIVE_EDIT_USER_PROFILE_SUCCESS':

      return {
        ...state,
        tryingToEditUserProfile: false,
        editUserProfileSuccess: new Date().getTime(),
        myData: action.data,
        display_name: action.data.display_name
      }






    case 'REQUEST_EDIT_USER_EMAIL_ADDRESS':
      return {
        ...state,
        tryingToEditUserEmailAddress: true,
        editUserEmailAddressForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_EDIT_USER_EMAIL_ADDRESS_FAIL':
      return {
        ...state,
        tryingToEditUserEmailAddress: false,
        editUserEmailAddressFail: new Date().getTime(),
        editUserEmailAddressForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_EDIT_USER_EMAIL_ADDRESS_SUCCESS':

      return {
        ...state,
        tryingToEditUserEmailAddress: false,
        editUserEmailAddressSuccess: new Date().getTime(),
        myData: action.data,
        email: action.data.email,
        email_verified: action.data.email_verified,
        editUserEmailAddressForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_EDIT_USER_EMAIL_SETTINGS':
      return {
        ...state,
        tryingToEditUserEmailSettings: true,
        editUserEmailSettingsForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_EDIT_USER_EMAIL_SETTINGS_FAIL':
      return {
        ...state,
        tryingToEditUserEmailSettings: false,
        editUserEmailSettingsFail: new Date().getTime(),
        editUserEmailSettingsForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_EDIT_USER_EMAIL_SETTINGS_SUCCESS':

      return {
        ...state,
        tryingToEditUserEmailSettings: false,
        editUserEmailSettingsSuccess: new Date().getTime(),
        myData: action.data,
        editUserEmailSettingsForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_EDIT_USER_PROFILE_VISIBILITY':
      return {
        ...state,
        tryingToEditUserProfileVisibility: true,
        editUserProfileVisibilityForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_EDIT_USER_PROFILE_VISIBILITY_FAIL':
      return {
        ...state,
        tryingToEditUserProfileVisibility: false,
        editUserProfileVisibilityFail: new Date().getTime(),
        editUserProfileVisibilityForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_EDIT_USER_PROFILE_VISIBILITY_SUCCESS':

      return {
        ...state,
        tryingToEditUserProfileVisibility: false,
        editUserProfileVisibilitySuccess: new Date().getTime(),
        myData: action.data,
        editUserProfileVisibilityForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_EDIT_USER_PASSWORD':
      return {
        ...state,
        tryingToEditUserPassword: true,
        editUserPasswordForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_EDIT_USER_PASSWORD_FAIL':
      return {
        ...state,
        tryingToEditUserPassword: false,
        editUserPasswordFail: new Date().getTime(),
        editUserPasswordForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_EDIT_USER_PASSWORD_SUCCESS':

      return {
        ...state,
        tryingToEditUserPassword: false,
        editUserPasswordSuccess: new Date().getTime(),
        editUserPasswordForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_LOGOUT_EVERYWHERE':
      return {
        ...state,
        tryingToLogoutEverywhere: true,
        logoutEverywhereForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_LOGOUT_EVERYWHERE_FAIL':
      return {
        ...state,
        tryingToLogoutEverywhere: false,
        logoutEverywhereFail: new Date().getTime(),
        logoutEverywhereForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_LOGOUT_EVERYWHERE_SUCCESS':

      return {
        ...state,
        tryingToLogoutEverywhere: false,
        logoutEverywhereSuccess: new Date().getTime(),
        logoutEverywhereForm: {errors: {}, lastSubmit: {}}
      }



    case 'REQUEST_MY_DATA':
      return {
        ...state,
        tryingToGetMyData: true,
      }

    case 'RECEIVE_MY_DATA_FAIL':
      return {
        ...state,
        tryingToGetMyData: false,
        getMyDataFail: new Date().getTime()
      }

    case 'RECEIVE_MY_DATA_SUCCESS':

      return {
        ...state,
        tryingToGetMyData: false,
        getMyDataSuccess: new Date().getTime(),
        myData: action.data
      }



    case 'REQUEST_GET_USER_LOG':

      return {
        ...state,
        tryingToGetUserLog: action.data.lastSubmit.type,
      }

    case 'RECEIVE_GET_USER_LOG_FAIL':
      return {
        ...state,
        tryingToGetUserLog: false,
        getUserLogFail: new Date().getTime()
      }

    case 'RECEIVE_GET_USER_LOG_SUCCESS':

      var log = state.log;
      var logEmpty = state.logEmpty;

      if(action.data.data.length > 0){
        logEmpty[action.data.type] = false;
        if(!log[action.data.type] || log[action.data.type].length === 0 || !action.data.startedAfter){
          log[action.data.type] = action.data.data;
        } else {
          if(log[action.data.type][0].id !== action.data.data[0].id){
            log[action.data.type] = log[action.data.type].concat(action.data.data);
          }
        }
      } else {
        logEmpty[action.data.type] = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        logEmpty[action.data.type] = true; 
      }

      return {
        ...state,
        tryingToGetUserLog: false,
        getUserLogSuccess: new Date().getTime(),
        log: log,
        logEmpty: logEmpty
      }

    case 'CLEAR_USER_LOG':

      var log = state.log;
      var logEmpty = state.logEmpty;

      log[action.data.type] = [];
      logEmpty[action.data.type] = false;

      return {
        ...state,
        log: log,
        logEmpty: logEmpty
      }






    case 'REQUEST_GET_USER_NOTIFICATIONS':
      return {
        ...state,
        tryingToGetUserNotifications: true,
      }

    case 'RECEIVE_GET_USER_NOTIFICATIONS_FAIL':
      return {
        ...state,
        tryingToGetUserNotifications: false,
        getUserNotificationsFail: new Date().getTime()
      }

    case 'RECEIVE_GET_USER_NOTIFICATIONS_SUCCESS':

      var notifications = state.notifications;
      var notificationsEmpty = state.notificationsEmpty;

      if(action.data.data.length > 0){
        notificationsEmpty = false;
        if(!notifications || notifications.length === 0 || !action.data.startedAfter){
          notifications = action.data.data;
        } else {
          if(notifications[0].id !== action.data.data[0].id){
            notifications = notifications.concat(action.data.data);
          }
        }
      } else {
        notificationsEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        notificationsEmpty = true; 
      }

      return {
        ...state,
        tryingToGetUserNotifications: false,
        getUserNotificationsSuccess: new Date().getTime(),
        notifications: notifications,
        notificationsEmpty: notificationsEmpty
      }




    case 'REQUEST_MARK_USER_NOTIFICATION_AS_READ':
      return {
        ...state,
        tryingToMarkUserNotificationAsRead: true,
      }

    case 'RECEIVE_MARK_USER_NOTIFICATION_AS_READ_FAIL':
      return {
        ...state,
        tryingToMarkUserNotificationAsRead: false,
        markUserNotificationAsReadFail: new Date().getTime()
      }

    case 'RECEIVE_MARK_USER_NOTIFICATION_AS_READ_SUCCESS':

      var notifications = state.notifications;
      
      var index = notifications.findIndex(n => n.id === action.data.id);
      if(index !== -1){
        notifications[index].read = true;
      }

      return {
        ...state,
        tryingToMarkUserNotificationAsRead: false,
        markUserNotificationAsReadSuccess: new Date().getTime(),
        notifications: notifications,
      }




    case 'REQUEST_UPLOAD_USER_AVATAR':
      return {
        ...state,
        tryingToUploadUserAvatar: true,
      }

    case 'RECEIVE_UPLOAD_USER_AVATAR_FAIL':
      return {
        ...state,
        tryingToUploadUserAvatar: false,
        uploadUserAvatarFail: new Date().getTime()
      }

    case 'RECEIVE_UPLOAD_USER_AVATAR_SUCCESS':

      var myData = state.myData;
      myData.avatar_url = action.data.avatar_url;

      return {
        ...state,
        tryingToUploadUserAvatar: false,
        uploadUserAvatarSuccess: new Date().getTime(),
        myData: myData
      }



    // case 'REQUEST_LOAD_AUTH_TOKEN':
    //   return {
    //     ...state,
    //     tryingToLoadAuthToken: true
    //   }

    // case 'RECEIVE_LOAD_AUTH_TOKEN_FAIL':
    //   return {
    //     ...state,
    //     ready: true,
    //     tryingToLoadAuthToken: false
    //   }

    // case 'RECEIVE_LOAD_AUTH_TOKEN_SUCCESS':

    //   return {
    //     ...state,
    //     ...action.data,
    //     ready: true,
    //     tryingToLoadAuthToken: false
    //   }

    case 'REQUEST_VALIDATE_SESSION':
      return {
        ...state,
        tryingToValidateSession: true
      }

    case 'RECEIVE_VALIDATE_SESSION_FAIL':
      return {
        ...state,
        ready: true,
        tryingToValidateSession: false
      }
    
    case 'RECEIVE_VALIDATE_SESSION_SUCCESS':

      return {
        ...state,
        isLoggedIn: action.data.isLoggedIn,
        myData: action.data.myData,
        csrf_token: action.data.csrf_token,
        ready: true,
        tryingToValidateSession: false
      }
    


    case 'SOCKET_CATCH_USER_DATA':
       return {
        ...state,
        socketCatchUserDataSuccess: new Date().getTime(),
        myData: action.data,
        bumped: action.data.bumped,
        suspended: action.data.suspended,
        maintenance: action.data.maintenance
      }




    case 'SOCKET_CATCH_USER_NOTIFICATION':
      var notifications = state.notifications;
      if(!notifications) notifications = [];

      notifications.unshift(action.data);

      return {
        ...state,
        notifications: notifications,
        socketCatchUserNotificationSuccess: new Date().getTime(),
      }


    case 'SOCKET_CONNECTED':
       return {
        ...state,
        socketConnected: true
      }

    case 'SOCKET_DISCONNECTED':
       return {
        ...state,
        socketConnected: false
      }

    
    case 'REQUEST_SEND_CONTACT_MESSAGE':
      return {
        ...state,
        tryingToSendContactMessage: true,
      }

    case 'RECEIVE_SEND_CONTACT_MESSAGE_FAIL':
       return {
        ...state,
        tryingToSendContactMessage: false,
        sendContactMessageFail: new Date().getTime()
      }

    case 'RECEIVE_SEND_CONTACT_MESSAGE_SUCCESS':

      return {
        ...state,
        tryingToSendContactMessage: false,
        sendContactMessageSuccess: new Date().getTime()
      }


    default:
      return state
  }
}
