import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import MegaMarkdown from 'components/MegaMarkdown/MegaMarkdown'

import CustomField from 'kit/components/CustomField/CustomField'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import Callout from 'kit/components/Callout/Callout.js'
import Modal from 'kit/components/Modal/Modal.js'
import TabNav from 'kit/components/TabNav/TabNav'

import InquiryFoundationsStack from './InquiryFoundationsStack'

import { 
  hideTooltip, 
  showTooltip,  
  tryToSetInquirySettingsMetadata,
  tryToInquiryFoundationsFeedback,
  tryToInquiryFoundationsFeedbackFollowUp,
  tryToGenerateInquiryFoundationQuestions,
  tryToGenerateRefinedProjectDescription
} from 'actions/actions.export'

import './InquiryFoundations.scss'


class InquiryFoundations extends Component {
  constructor(props){
    super(props);

    this.state = {
      stackCounter: 0,
      description: "",
      tab: 'structure',
      editHunt: false,
      expandedHighlights: [],
      enabledPerspectives: [],
      conversationWith: [],
      lastUpdatePerspectivesSuccess: 0,
      selectedRationale: [],
      foundations_feedback_structure: [],
      foundations_feedback_scope: [],
      foundations_feedback_feasibility: [],
      foundations_feedback_impact: [],
      hovering: null,
      expandedDictionary: {},
      draftAnswer: '', 
      lastFoundationsFeedback: null,
      lastFoundationsFeedbackFollowUp: null,
      refinedView: 'side-by-side',
    }
  }

  componentWillMount(){
    const { dispatch, inquiryReducer } = this.props;

    const inquiry = inquiryReducer.cache[this.props.id];
    if(inquiry){
      this.setState({
        description: inquiry.description,
      })
    } 
  }

  componentWillReceiveProps(newProps){
    const { inquiryReducer } = newProps;

    if(inquiryReducer.getInquiryFoundationsFeedbackSuccess && inquiryReducer.getInquiryFoundationsFeedbackSuccess !== this.state.lastFoundationsFeedback){
      this.setState({
        lastFoundationsFeedback: inquiryReducer.getInquiryFoundationsFeedbackSuccess,
        stackCounter: this.state.stackCounter + 1
      })
    }

    if(inquiryReducer.getInquiryFoundationsFeedbackFollowUpSuccess && inquiryReducer.getInquiryFoundationsFeedbackFollowUpSuccess !== this.state.lastFoundationsFeedbackFollowUp){
      this.setState({
        lastFoundationsFeedbackFollowUp: inquiryReducer.getInquiryFoundationsFeedbackFollowUpSuccess,
        stackCounter: this.state.stackCounter + 1
      })
    }
    
  }

  render(){

    const { dispatch, inquiryReducer, canWrite } = this.props;

    const inquiry = inquiryReducer.cache[this.props.id];

    if(!inquiry) return <span>Failed to load.</span>;

    let pillars = [
      {
        name: 'structure',
        display_name: 'Project Overview',
        tagline: 'Refine your description\'s narrative structure and presentation',
        dimensions: [
          {
            name: 'clarity',
            display_name: 'Clarity',
          },
          {
            name: 'logical_structure',
            display_name: 'Logical Structure',
          },
          {
            name: 'articulation',
            display_name: 'Articulation',
          },
          {
            name: 'formality_and_tone',
            display_name: 'Formality and Tone',
          },
          {
            name: 'coherence_and_consistency',
            display_name: 'Coherence and Consistency',
          }
        ]
      },
      {
        name: 'scope',
        display_name: 'Objectives',
        tagline: 'Refine your project\'s scope, goals, and potential',
        dimensions: [
          {
            name: 'overall_scope',
            display_name: 'Overall Scope',
          },
          {
            name: 'required_resources',
            display_name: 'Required Resources',
          },
          {
            name: 'innovation_level',
            display_name: 'Innovation Level',
          },
          {
            name: 'stakeholder_engagement',
            display_name: 'Stakeholder Engagement',
          },
          {
            name: 'impact_potential',
            display_name: 'Impact Potential',
          }
        ]
      },
      {
        name: 'feasibility',
        display_name: 'Technical Viability',
        tagline: 'Refine your project\'s technical feasibility and practicality',
        dimensions: [
          {
            name: 'application_of_ai',
            display_name: 'Application of AI',
          },
          {
            name: 'scalability',
            display_name: 'Scalability',
          },
          {
            name: 'integration_complexity',
            display_name: 'Integration Complexity',
          },
          {
            name: 'compliance',
            display_name: 'Compliance',
          },
          {
            name: 'strategic_fit',
            display_name: 'Strategic Fit',
          }
        ]
      },
      {
        name: 'impact',
        display_name: 'Social & Ethical',
        tagline: 'Refine your project\'s audience & impact',
        dimensions: [
          {
            name: 'user_experience_and_accessibility',
            display_name: 'User Experience and Accessibility',
          },
          {
            name: 'bias_and_fairness',
            display_name: 'Bias and Fairness',
          },
          {
            name: 'social_impact',
            display_name: 'Social Impact',
          },
          {
            name: 'ethical_considerations',
            display_name: 'Ethical Considerations',
          },
          {
            name: 'community_engagement',
            display_name: 'Community Engagement',
          }
        ]
      }
    ]

    // lets check this inquiry's foundations feedback and merge it onto dimensions where we have some
    for(var i in pillars){
      let pillar = pillars[i];
      let pillarFeedback = inquiry['foundations_feedback_' + pillar.name];
      if(pillarFeedback){
        pillar.initial_assessment = pillarFeedback.initial_assessment;

        for(var j in pillar.dimensions){
          let dimension = pillar.dimensions[j];
          let dimensionFeedback = (pillarFeedback.dimensions || {})[dimension.name];
          if(dimensionFeedback){
            dimension.feedback = dimensionFeedback.feedback;
            dimension.score = dimensionFeedback.score;
            dimension.questions = dimensionFeedback.questions;
          }
        }
      }
    }

    // randomly create a blocks array for testing, between 5 and 23 blocks
    let blocks = [];

    // flatten the dimensions feedback into blocks
    for(var i in pillars){
      let pillar = pillars[i];
      let dimensions = pillar.dimensions || [];

      // in reverse order so the first dimensions is at the top
      for(var j = dimensions.length - 1; j >= 0; j--){
        let f = dimensions[j];
        if(f.score){
          blocks.push({
            id: i + '-' + j,
            name: f.name,
            score: f.score
          })
        }
      }
    }


    for(var i in blocks){
      
      let color = '#faaa35'
      if(blocks[i].score >= 7) color = '#53b882';
      if(blocks[i].score < 3) color = '#D24128';

      blocks[i].color = color;
      blocks[i].widthPercent = blocks[i].score * 10;
    }

    // make a simple hash of blocks to use as a key for the renderer
    let blockHash = window.btoa(JSON.stringify(blocks)).slice(0, 10);


    let blocksPerLineSection = [
      pillars[0].dimensions.length,
      pillars[1].dimensions.length,
      pillars[2].dimensions.length,
      pillars[3].dimensions.length
    ];
    let sectionLabels = [
      pillars[0].display_name,
      pillars[1].display_name,
      pillars[2].display_name,
      pillars[3].display_name
    ]

    let totalBlocks = blocksPerLineSection.reduce((a, b) => a + b, 0);
    
    const drawFeedbackTab  = (pillar, i) => {
      return <div key={i}>
        <div className="flex-split margin-bottom-2rem">
          <div>
            <div className="no-margin text-uppercase text-inquiry text-900"><small>STEP {parseInt(i) + 1}</small></div>
            <h5 className="no-margin">
              {pillar.tagline}
            </h5>
          </div>
          {
            inquiry['foundations_feedback_' + pillar.name] ?
          
            <div className="list-right">
              <CustomButton
                display={<span><i className="far fa-redo icon-before-text"/>Regenerate</span>}
                color="grey"
                size="xs"
                thinking={inquiryReducer.tryingToGetInquiryFoundationsFeedback || inquiryReducer.tryingToGetInquiryFoundationsFeedbackFollowUp}
                disabled={inquiryReducer.tryingToSetInquirySettingsMetadata}
                onClick={()=>{
                  this.setState({
                    showRegenerateModal: pillar.name
                  })
                }}
                />

              <CustomButton
                display={<span>Next Step<i className="far fa-angle-double-right icon-after-text"/></span>}
                size="xs"
                color="black"
                onClick={()=>{
                  
                  if(i === pillars.length - 1){
                    this.setState({
                      tab: 'refined'
                    })
                  } else {
                    this.setState({
                      tab: pillars[i + 1].name
                    })
                  }
                }}
                />
            </div>
            :
            <div className="list-right">
              <CustomButton
                display={<span>Generate Feedback<i className="far fa-sparkles icon-after-text"/></span>}
                size="small"
                color="black"
                thinking={inquiryReducer.tryingToGetInquiryFoundationsFeedback}
                disabled={inquiryReducer.tryingToSetInquirySettingsMetadata}
                onClick={()=>{
                  dispatch(tryToInquiryFoundationsFeedback({
                    inquiry_id: inquiry.id,
                    pillar: pillar.name
                  }))
                }}
                />
            </div>
          }
        </div>
        <hr/>
        {
          pillar.initial_assessment ?
          <div className="margin-bottom-2rem">
            {pillar.initial_assessment}
            <br/>
            <br/>
            The following dimensions will help you refine your project. Each dimension has a score out of 10, feedback, and questions to help you improve.
          </div>
          :
          <div className="margin-bottom-2rem">
            The following dimensions will help you refine your project. Click <strong>Generate Feedback</strong> to get started.
          </div>
        }
        {
          (pillar.dimensions || []).map((dimension, j) => {

            let isHovering = false;
            if(this.state.hovering){
              if(this.state.hovering.pillar.name === pillar.name && this.state.hovering.dimension.name === dimension.name){
                isHovering = true;
              }
            }

            let barClass = 'progress-bar-inner-warning';
            if(dimension.score >= 7) barClass = 'progress-bar-inner-success';
            if(dimension.score < 3) barClass = 'progress-bar-inner-danger';

            let answeredQuestions = (dimension.questions || []).filter(q => typeof q === 'object').length;
            let totalQuestions = (dimension.questions || []).length;
            let unansweredQuestions = totalQuestions - answeredQuestions;

            return <div 
              key={j} 
              className={"box box-light-border box-no-shadow " + (isHovering ? 'box-black-border ' : ' ') + (this.state.hovering === null || isHovering ? '' : 'box-muted' ) + (this.state.expandedDictionary[pillar.name + '-' + dimension.name] ? '' : ' box-clickable')} 
              style={{marginBottom: 10, padding: '1rem'}}
              onClick={e => {
                let expandedDictionary = this.state.expandedDictionary;
                expandedDictionary[pillar.name + '-' + dimension.name] = true;
                this.setState({
                  expandedDictionary
                })

                // if totalQuestions is 0, and if we have initial feedback and if we arent already trying to generate questions, then go generate them 
                if(totalQuestions === 0 && pillar.initial_assessment && !inquiryReducer.tryingToGetInquiryFoundationsFeedback && !(inquiryReducer.generateFoundationQuestionRequests[inquiry.id + '-' + pillar.name + '-' + dimension.name] || {}).trying){
                  dispatch(tryToGenerateInquiryFoundationQuestions({
                    inquiry_id: inquiry.id,
                    pillar: pillar.name,
                    dimension: dimension.name
                  }))
                }
              }}
              >
              <div className="flex-split flex-split-align-start">
                <div>
                  {
                    this.state.expandedDictionary[pillar.name + '-' + dimension.name] ? <i className="far clickable fa-angle-down fa-fw icon-before-text" onClick={()=>{
                      setTimeout(()=>{
                        let expandedDictionary = this.state.expandedDictionary;
                        expandedDictionary[pillar.name + '-' + dimension.name] = false;
                        this.setState({
                          expandedDictionary
                        })
                      }, 10);
                    }}/>
                    :
                    <i className="far fa-angle-right fa-fw icon-before-text"/>
                  }
                </div>
                <div className="flex-grow">
                  <div className="flex-split margin-bottom-05rem">
                    <div className="list-left">
                      <h5 className="no-margin-top no-margin-bottom">{dimension.display_name}</h5>
                      {
                        pillar.initial_assessment ?
                        (
                          totalQuestions > 0 ?
                          <small className={ unansweredQuestions > 0 ? "text-inquiry" : "text-muted"}>
                            {unansweredQuestions} question{unansweredQuestions !== 1 && 's'} to answer
                          </small>
                          :
                          <small className="text-inquiry">
                            
                          </small>
                        )
                        :
                        <small className="text-muted">
                          {
                            inquiryReducer.tryingToGetInquiryFoundationsFeedback ?
                            <i className="fal fa-spinner-third fa-spin icon-before-text"/>
                            :
                            'awaiting generation'
                          }
                        </small>
                      }
                    </div>
                    <div className="list-right">
                      <div className="progress-bar-wrapper" style={{width: 200}}>
                        <div className={"progress-bar-inner " + barClass} style={{width: dimension.score * 10 + '%'}}></div>
                      </div>
                    
                      <span className="text-900">{dimension.score === undefined ? <span className="text-muted">?</span> : dimension.score}/10</span>
                    </div>
                  </div>
                  

                  <div style={{marginTop: '1.1rem'}} className={" " + (this.state.expandedDictionary[pillar.name + '-' + dimension.name] ? "margin-bottom-1rem" : "text-ellipsis-1-lines text-semi-muted")}>
                    {dimension.feedback}
                  </div>
                  {
                    this.state.expandedDictionary[pillar.name + '-' + dimension.name] && <div>
                      <hr/>
                      {
                        pillar.initial_assessment ? 
                        (dimension.questions || []).length > 0 ?
                        <div className="margin-bottom-1rem flex-split padding-right-2rem">
                          {
                            unansweredQuestions > 0 ?
                            
                            <small className="text-900 text-uppercase text-inquiry">
                              Questions to help refine your project:
                            </small>
                            :
                            <small className="text-muted">
                              You've answered all questions for this dimension, generate more to refine your project further.
                            </small>
                          }
                          <CustomButton
                            color="grey"
                            display={"Generate More Questions"}
                            size="xs"
                            thinking={(inquiryReducer.generateFoundationQuestionRequests[inquiry.id + '-' + pillar.name + '-' + dimension.name] || {}).trying}
                            fail={(inquiryReducer.generateFoundationQuestionRequests[inquiry.id + '-' + pillar.name + '-' + dimension.name] || {}).fail}
                            success={(inquiryReducer.generateFoundationQuestionRequests[inquiry.id + '-' + pillar.name + '-' + dimension.name] || {}).success}
                            onClick={()=>{
                              dispatch(tryToGenerateInquiryFoundationQuestions({
                                inquiry_id: inquiry.id,
                                pillar: pillar.name,
                                dimension: dimension.name
                              }))
                            }}
                            />
                        </div>
                        :
                        <div className="margin-bottom-1rem flex-split padding-right-2rem">
                          {
                            (inquiryReducer.generateFoundationQuestionRequests[inquiry.id + '-' + pillar.name + '-' + dimension.name] || {}).trying ?
                            <span>
                              Generating questions...
                            </span>
                            :
                            <span>
                              Click generate questions to help you refine this aspect of your project.
                            </span>
                          }
                          <CustomButton
                            color="inquiry"
                            display={"Generate Questions"}
                            size="xs"
                            thinking={(inquiryReducer.generateFoundationQuestionRequests[inquiry.id + '-' + pillar.name + '-' + dimension.name] || {}).trying}
                            fail={(inquiryReducer.generateFoundationQuestionRequests[inquiry.id + '-' + pillar.name + '-' + dimension.name] || {}).fail}
                            success={(inquiryReducer.generateFoundationQuestionRequests[inquiry.id + '-' + pillar.name + '-' + dimension.name] || {}).success}
                            onClick={()=>{
                              dispatch(tryToGenerateInquiryFoundationQuestions({
                                inquiry_id: inquiry.id,
                                pillar: pillar.name,
                                dimension: dimension.name
                              }))
                            }}
                            />
                        </div>
                        :
                        <div className="margin-bottom-1rem">
                          After generating feedback, it'll appear here, along with questions to help you refine your project.
                        </div>
                      }
                      <div className="padding-right-2rem padding-bottom-2rem">
                        {
                          (dimension.questions || []).map((question, k) => {
                            if(typeof question === 'object') return;
                            return <div key={k} className="box box-half-pad box-grey margin-bottom-1rem box-no-shadow">
                              <div className="flex-split">
                                <div className="margin-right-2rem">
                                  {question}
                                </div>
                                <CustomButton
                                  display="Answer Question"
                                  size="xs"
                                  color="black"
                                  thinking={(inquiryReducer.answeringFoundationQuestionRequests[question] || {}).trying}
                                  fail={(inquiryReducer.answeringFoundationQuestionRequests[question] || {}).fail}
                                  success={(inquiryReducer.answeringFoundationQuestionRequests[question] || {}).success}
                                  disabled={inquiryReducer.tryingGetToInquiryFoundationsFeedback}
                                  onClick={()=>{
                                    this.setState({
                                      showAnswerModal: {
                                        pillar: pillar,
                                        dimension: dimension,
                                        question: question,
                                        index_list: [i, j, k],
                                      },
                                      draftAnswer: ''
                                    })
                                  }}
                                  />
                              </div>
                            </div>
                          })
                        }
                        {
                          (dimension.questions || []).filter(q => typeof q === 'object').length > 0 && <div className="margin-bottom-1rem margin-top-2rem">
                            <small className="text-900 text-uppercase text-inquiry">
                              Previously answered questions:
                            </small>
                          </div>
                        }
                        {
                          (dimension.questions || []).map((question, k) => {
                            if(typeof question !== 'object') return;
                            return <div key={k} className="box box-half-pad box-grey margin-bottom-1rem box-no-shadow thin-line-height">
                              <small className="text-900">{question.q}</small><br/><br/>
                              <small>{question.a}</small>
                            </div>
                          })
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          })
        }
      </div>
    }

    let hasGeneratedAnyFeedback = false;
    for(var i in pillars){
      let pillar = pillars[i];
      if(pillar.initial_assessment){
        hasGeneratedAnyFeedback = true;
        break;
      }
    }

    return <div className="inquiry-foundations flex-grow flex-column-stretch " style={{padding: '1.5rem'}}>
      {

        this.state.showAnswerModal && 
        <Modal
          show={true}
          exitable={true}
          onExit={()=>{ this.setState({ showAnswerModal: false, draftAnswer: '' }) }}
          cancelable={true}
          onCancel={()=>{ this.setState({ showAnswerModal: false, draftAnswer: '' }) }}
          acceptable={true}
          acceptButtonLabel="Submit Answer"
          acceptButtonDisabled={this.state.draftAnswer.length === 0}
          minWidth={500}
          onAccept={()=>{
            dispatch(tryToInquiryFoundationsFeedbackFollowUp({
              inquiry_id: inquiry.id,
              pillar: this.state.showAnswerModal.pillar.name,
              dimension: this.state.showAnswerModal.dimension.name,
              question: this.state.showAnswerModal.question,
              answer: this.state.draftAnswer
            }))

            this.setState({
              showAnswerModal: false
            })
          }}
          content={<div>
            <p>
              <strong>{this.state.showAnswerModal.question}</strong>
            </p>
            <CustomField
              rows={5}
              inline={true}
              required={true}
              placeholder="Type your answer here..."
              maxLength={500}
              description="Maximum 500 characters."
              value={this.state.draftAnswer}
              onChange={(e)=>{
                this.setState({
                  draftAnswer: e.value
                })
              }}
              />
            </div>
            }
          />
      }

      <Modal  
        show={this.state.showRegenerateModal}
        exitable={true}
        onExit={()=>{ this.setState({ showRegenerateModal: false, draftAnswer: '' }) }}
        cancelable={true}
        onCancel={()=>{ this.setState({ showRegenerateModal: false, draftAnswer: '' }) }}
        acceptable={true}
        acceptButtonLabel="Regenerate Feedback"
        minWidth={500}
        onAccept={()=>{
          dispatch(tryToInquiryFoundationsFeedback({
            inquiry_id: inquiry.id,
            pillar: this.state.showRegenerateModal
          }))

          this.setState({
            showRegenerateModal: false
          })

        }}
        content={<div>
          <h5 className="no-margin">Regenerate Feedback</h5>
          <p>
            Are you sure you want to regenerate feedback for this dimension? All existing feedback and answered questions will be replaced.
          </p>
        </div>
        }
        />

      <Modal
        show={this.state.showOriginalDescription}
        exitable={true}
        onExit={()=>{ this.setState({ showOriginalDescription: false }) }}
        acceptable={true}
        acceptButtonLabel="Close"
        onAccept={()=>{ this.setState({ showOriginalDescription: false }) }}
        minWidth={500}
        content={<div>
          <CustomField
            label="Original Project Description"
            inline={true}
            value={inquiry.description}
            rows={8}
            disabled={true}
            />
        </div>
        }
        />


      
      <div className="margin-top-05rem margin-bottom-2rem">
        <div className="flex-split">
          <div>
            <h4 className="no-margin-top no-margin-bottom">Project Foundations</h4>
            <small>
              Assess and refine your project's description across multiple dimensions.
            </small>
          </div>
          <div className="list-right">
            {
              hasGeneratedAnyFeedback &&
              <CustomButton
                display={<span>View Original Description</span>}
                size="small"
                color="grey"
                onClick={()=>{
                  this.setState({
                    showOriginalDescription: true
                  })
                }}
                />
            }
            <CustomButton
              display={
                <span>
                  <i className="far fa-align-left icon-before-text"/>Restack
                </span>
              }
              size="small"
              color="grey"
              onClick={()=>{
                this.setState({
                  stackCounter: this.state.stackCounter + 1
                })
              }}
              />
          </div>
        </div>
      </div>
      
      <div className="flex-split flex-split-stretch flex-grow">
        <div className="flex-2-3 box box-no-pad no-margin-bottom margin-right-2rem flex-column-stretch">
          <TabNav
            theme="inquiries"
            items={[
              // {
              //   display: 'Description',
              //   value: 'description',
              // },
              {
                display: '1. ' + pillars[0].display_name,
                value: 'structure'
              },
              {
                display: '2. ' + pillars[1].display_name,
                value: 'scope',
                disabled: !inquiry.foundations_feedback_structure
              },
              {
                display: '3. ' + pillars[2].display_name,
                value: 'feasibility',
                disabled: !inquiry.foundations_feedback_scope
              },
              {
                display: '4. ' + pillars[3].display_name,
                value: 'impact',
                disabled: !inquiry.foundations_feedback_feasibility
              },
              {
                display: '5. Refined Description',
                value: 'refined',
                disabled: !inquiry.foundations_feedback_impact
              }
            ]}
            value={this.state.tab}
            onChange={tab => {
              this.setState({
                tab
              })
            }}
            />
          
          <div className="flex-grow scroll-parent">
            <div className="scroll-child padding-2rem">

              {
                hasGeneratedAnyFeedback ?
                <div>

                  {
                    this.state.tab === 'structure' && <div className="">
                    
                      {
                        drawFeedbackTab(pillars[0], 0)
                      }
                    </div>
                  }

                  {
                    this.state.tab === 'scope' && <div className="">
                      {
                        drawFeedbackTab(pillars[1], 1)
                      }
                    </div>
                  }

                  {
                    this.state.tab === 'feasibility' && <div className="">
                      {
                        drawFeedbackTab(pillars[2], 2)
                      }
                    </div>
                  }

                  {
                    this.state.tab === 'impact' && <div className="">
                      {
                        drawFeedbackTab(pillars[3], 3)
                      }
                    </div>
                  }

                  {
                    this.state.tab === 'refined' && <div className="">
                      <div className="flex-split">
                        <div>
                          <div className="no-margin text-uppercase text-inquiry text-900"><small>STEP 5</small></div>
                          <h5 className="no-margin margin-bottom-1rem">
                            Refine Your Project Description
                          </h5>
                        </div>
                        <div className="list-right">
                          <div>
                            <CustomButton
                              display={<span><i className="fas fa-arrow-to-right"/></span>}
                              size="xs"
                              color={this.state.refinedView === 'left' ? 'black' : 'grey'}
                              noRadiusRight={true}
                              onClick={()=>{
                                this.setState({
                                  refinedView: 'left'
                                })
                              }}
                              />
                            <CustomButton
                              display={<span><i className="fas fa-columns"/></span>}
                              size="xs"
                              color={this.state.refinedView === 'side-by-side' ? 'black' : 'grey'}
                              noRadiusLeft={true}
                              noRadiusRight={true}
                              onClick={()=>{
                                this.setState({
                                  refinedView: 'side-by-side'
                                })
                              }}
                              />
                            <CustomButton
                              display={<span><i className="fas fa-arrow-from-left"/></span>}
                              size="xs"
                              color={this.state.refinedView === 'right' ? 'black' : 'grey'}
                              noRadiusLeft={true}
                              onClick={()=>{
                                this.setState({
                                  refinedView: 'right'
                                })
                              }}
                              />
                          </div>

                          <CustomButton
                             display={<span>Refine Description<i className="fas fa-sparkles icon-after-text"/></span>}
                             size={inquiry.description_refined ? "xs" : "small"}
                             color="black"
                             thinking={inquiryReducer.tryingToGenerateRefinedProjectDescription}
                             fail={inquiryReducer.tryingToGenerateRefinedProjectDescriptionFail}
                            success={inquiryReducer.tryingToGenerateRefinedProjectDescriptionSuccess}
                             onClick={()=>{
                              dispatch(tryToGenerateRefinedProjectDescription({
                                inquiry_id: inquiry.id
                              }))
                             }}
                            />
                        </div>
                      </div>
                      <hr/>
                      {
                        this.state.refinedView === 'left' && <div>
                          <CustomField
                            value={inquiry.description}
                            rows={18}
                            inline={true}
                            disabled={true}
                            label="Original"
                            description="Your original project description, where you started."
                            />
                        </div>
                      }
                      {
                        this.state.refinedView === 'right' && <div>
                          <CustomField
                            value={inquiry.description_refined || "Click 'Refine Description' above to generate an improved project description based on your feedback and the answers you've provided."}
                            rows={18}
                            inline={true}
                            disabled={true}
                            label="Generated Results"
                            description="An AI-generated refined project description based on the development of the project's foundations."
                            />
                        </div>
                      }
                      {
                        this.state.refinedView === 'side-by-side' && 
                        <div className="row">
                          <div className="col-lg-6">
                            <CustomField
                              value={inquiry.description}
                              rows={18}
                              inline={true}
                              disabled={true}
                              label="Original"
                              description="Your original project description, where you started."
                              />
                          </div>
                          <div className="col-lg-6">
                            
                            <CustomField
                              value={inquiry.description_refined || "Click 'Refine Description' above to generate an improved project description based on your feedback and the answers you've provided."}
                              rows={18}
                              inline={true}
                              disabled={true}
                              label="Generated Results"
                              description="An AI-generated refined project description based on the development of the project's foundations."
                              />
                        
                          </div>
                        </div>
                      }
                    </div>
                  }


                </div>
                :
                <div className="">
                  <div>
                    <Callout
                      style="grey"
                      title="What's this tool for?"
                      dismissable={true}
                      dismissID="inquiry-foundations-tool-info"
                      content={<div>
                          Your project description is the foundation of your project. It should be clear, concise, and engaging. This tool will help you refine your project description across multiple dimensions, helping you communicate and define your project more effectively.
                        </div>}
                      />
                    <div className="flex-split margin-bottom-1rem">
                      <div>
                        <div className="no-margin text-uppercase text-inquiry text-900"><small>Before you begin</small></div>
                        <h5 className="no-margin margin-bottom-1rem">
                          Check your project description
                        </h5>
                      </div>
                      <div className="list-right">
                        <CustomButton
                          display={<span>Get Started<i className="far fa-angle-double-right icon-after-text"/></span>}
                          size="xs"
                          color="black"
                          onClick={()=>{
                            dispatch(tryToSetInquirySettingsMetadata({
                              id: inquiry.id,
                              description: this.state.description
                            }));
                            this.setState({
                              tab: 'structure'
                            })
                          }}
                          />
                      </div>
                    </div>
                    <hr/>
                    <div className="margin-bottom-2rem">
                      Before you begin, take a moment to review your project description. Once you're ready, head to the next step to begin refining your description's structure and presentation.
                    </div>
                    <CustomField
                      value={this.state.description}
                      rows={13}
                      inline={true}
                      label="Project Description"
                      maxLength={10000}
                      placeholder="Describe your project in as much detail as possible..."
                      disabled={inquiryReducer.tryingToSetInquirySettingsMetadata}
                      onChange={e => {
                        this.setState({
                          description: e.value
                        })
                      }}
                      />
                  </div>
                  
                </div>
              }

            </div>
          </div>
        </div>
        <div className="flex-1-3 box relative box-no-pad" style={{minWidth: 250}} key={this.state.stackCounter + '-' + blockHash} >
          <InquiryFoundationsStack
            sectionLabels={sectionLabels}
            blocksPerLineSection={blocksPerLineSection}
            onBlockClick={(block) => {
              // split block.label into dimension and feedback index
              let parts = block.label.split('-');
              let pillar = pillars[parts[0]];
              let dimension = pillar.dimensions[parts[1]];

              let expandedDictionary = this.state.expandedDictionary;
              expandedDictionary[pillar.name + '-' + dimension.name] = true;  

              this.setState({
                tab: pillar.name,
                expandedDictionary
              })
            }}
            onBlockHover={(block) => {

              if(block === null){

                this.setState({
                  hovering: null
                })
                } else {
                // split block.label into dimension and feedback index
                let parts = block.label.split('-');
                let pillar = pillars[parts[0]];
                let dimension = pillar.dimensions[parts[1]];

                this.setState({
                  hovering: {
                    pillar: pillar,
                    dimension: dimension
                  }
                })
              }
            }}
            totalBlocks={totalBlocks + 1}
            blocks={blocks}
            />
        </div>
      </div>
      
    </div>
  }
}


const mapStateToProps = (state) => {
  const { inquiryReducer, orgReducer } = state;

  return {
    inquiryReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(InquiryFoundations);

  