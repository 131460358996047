import Cookies from 'js-cookie';
import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from './a.users.forceLogoutHandler';

import {
  tryToGetUserLog
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// EditUserEmailAddress
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestEditUserEmailAddress = packet => {
  return {
    type: 'REQUEST_EDIT_USER_EMAIL_ADDRESS',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveEditUserEmailAddressSuccess = data => ({
  type: 'RECEIVE_EDIT_USER_EMAIL_ADDRESS_SUCCESS',
  data
})

export const receiveEditUserEmailAddressFail = data => ({
  type: 'RECEIVE_EDIT_USER_EMAIL_ADDRESS_FAIL',
  data
})

export const tryToEditUserEmailAddress = packet => (dispatch, getState) => {

  dispatch(requestEditUserEmailAddress(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/users/email-address', {
      method: 'put',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveEditUserEmailAddressSuccess(json.data));
      dispatch(tryToGetUserLog({type: 'email'}));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveEditUserEmailAddressFail({errors:e, lastSubmit: packet}));
    })
}
