import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js';
import CustomField from 'kit/components/CustomField/CustomField.js';
import Hydrate from 'components/Hydrate/Hydrate.js';

import toCamelCaseFunctionName from 'utilities/toCamelCaseFunctionName';

function WebSearch({ options = {}, onChange, enabled, org_id, canWrite}) {
  const [selectedOption, setSelectedOption] = useState('');

  // Access the Redux dispatch function
  const dispatch = useDispatch();
  const knowledgeReducer = useSelector((state) => state.knowledgeReducer);

  let knowledgeCacheList = Object.keys(knowledgeReducer.cache).map(id => knowledgeReducer.cache[id]);
    
  let knowledge = [];
  if(org_id){
    knowledge = knowledgeCacheList.filter(p => p.scope === org_id);
  }


  return (
    <div>

      <CustomField
        label="Search Description"
        value={options.search_description}
        maxLength={512}
        rows={10}
        onChange={e => {
          onChange({
            search_description: e.value
          })
        }}
        disabled={!canWrite}
        placeholder="ex: Search the web for information using..."
        />

      <div className="spacer-1rem"/>
      <CustomField
        name={'results_returned'}
        label={<span>How many search results should be returned?<br/><small className="text-muted">(defaults to 10)</small></span>}
        description={""}
        type="range"
        min={0}
        max={10}
        step={1}
        placeholder={'10'}
        disabled={!canWrite}
        value={options.results_returned}
        onChange={e => {
          onChange({
            results_returned: e.value
          })
        }}
        />

    </div>
  );
}

export default WebSearch;
