import React, { Component } from 'react'
import { connect } from 'react-redux'

import ForceGraph from 'components/ForceGraph/ForceGraph'

import CustomButton from 'kit/components/CustomButton/CustomButton'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import Modal from 'kit/components/Modal/Modal'

import {
  tryToGenerateInquiryContext,
  tryToUpdateInquiryContext
} from 'actions/actions.export'

import convertTreeToGraph from 'utilities/convertTreeToGraph'

import './InquiryContextMap.scss'

class InquiryContextMap extends Component {
  constructor(props){
    super(props);

    this.state = {
      selectedNode: null
    }

    this.removeNode = this.removeNode.bind(this);
  }

  componentWillMount(){
    const { dispatch, inquiryReducer } = this.props;

    const inquiry = inquiryReducer.cache[this.props.id];
    if(inquiry){

    } 
  }

  componentWillReceiveProps(newprops){
    const inquiry = newprops.inquiryReducer.cache[newprops.id];
    if(inquiry){
      
    } 

    if(newprops.inquiryReducer.updateInquiryContextSuccess && newprops.inquiryReducer.updateInquiryContextSuccess !== this.props.inquiryReducer.updateInquiryContextSuccess){
      this.setState({
        showStartOverModal: false,
      })
    }
  }

  generateAdditionalContext(node){
    const { dispatch, inquiryReducer } = this.props;

    dispatch(tryToGenerateInquiryContext({
      inquiry_id: this.props.id, 
      node: node
    }));
  }

  removeNode(){
    if(!this.state.selectedNode) return;

    const { dispatch, inquiryReducer } = this.props;

    // find this node in the current inquiry.context_map and remove it and any child details

    function deleteNode(jsonArray, targetName, targetDescription) {
      return jsonArray.reduce((acc, node) => {
          // If the node matches the criteria, don't add it to the accumulator
          if (node.name === targetName && node.description === targetDescription) {
              return acc;
          }
  
          // If the node has details, recursively process them
          if (Array.isArray(node.details)) {
              node.details = deleteNode(node.details, targetName, targetDescription);
          }
  
          // Add the processed node to the accumulator
          acc.push(node);
          return acc;
      }, []);
    }

    let mapToEdit = [...inquiryReducer.cache[this.props.id].context_map]

    let newmap = deleteNode(mapToEdit, this.state.selectedNode.name, this.state.selectedNode.description);

    dispatch(tryToUpdateInquiryContext({
      inquiry_id: this.props.id,
      context_map: newmap
    }));
  }

  render(){

    const { dispatch, inquiryReducer, userReducer, orgReducer, canWrite } = this.props;

    const inquiry = inquiryReducer.cache[this.props.id];

    if(!inquiry) return <span>Failed to load.</span>;

    let map = inquiry.context_map
    if(!map || map.length === 0){
      map = [{
          name: inquiry.display_name,
          description: inquiry.description_refined || inquiry.description
        }];
    }

    const { nodes, links } = convertTreeToGraph(map);
    

    // recursively find selectedNode in our original treeGraph
    const findNode = (node, selectedNode) => {
      if(!selectedNode) return null;
      if(node.name === selectedNode.name && node.description === selectedNode.description) return node;
      if(node.details){
        for(let i = 0; i < node.details.length; i++){
          const found = findNode(node.details[i], selectedNode);
          if(found) return found;
        }
      }
      return null;
    }

    const foundNode = findNode(map[0], this.state.selectedNode);
    if(foundNode && !foundNode.details) foundNode.details = [];

    


    return <div className="inquiry-context-map flex-column-stretch  flex-grow">

      <Modal
        show={this.state.showStartOverModal}
        exitable={true}
        onExit={() => this.setState({showStartOverModal: false})}
        cancelable={true}
        onCancel={() => this.setState({showStartOverModal: false})}
        acceptable={true}
        acceptButtonThinking={inquiryReducer.tryingToUpdateInquiryContext}
        onAccept={() => {
          dispatch(tryToUpdateInquiryContext({
            inquiry_id: this.props.id,
            context_map: []
          }));
        }}
        acceptButtonLabel="Yes, Start Over"
        acceptButtonColor="danger"
        content={<div>
          <h5 className="no-margin">Are you sure you want to start over?</h5>
          <p>
            This will remove all of your nodes and generated details from your map.
          </p>
          </div>
        }/>
      
      <div className="padding-2rem flex-column-stretch flex-grow">
        <div className="margin-bottom-2rem">
          <div className="box box-half-pad inquiry-context-map-header">  
            <div className="flex-split">
              <div>
                <h4 className="no-margin-top no-margin-bottom">Context Mapping</h4>
                <small>
                  Investigate space, context, and category of the your idea in a broader cultural frame of reference.
                </small>
              </div>
              <div className="list-right">
                <CustomButton
                  display={<span><i className="far fa-fw fa-redo icon-before-text"/>Start Over</span>}
                  size="small"
                  color="grey"
                  onClick={() => {
                    this.setState({showStartOverModal: true});
                  }}
                />
              </div>
            </div>
          </div>
        </div>

          {
            (foundNode && this.state.selectedNode) && <div className="inquiry-context-map-node-info flex-grow">
              <div className="box flex-grow scroll-parent">
                <div className="scroll-child padding-2rem">
                  <div className="flex-split margin-bottom-1rem">
                    <div className="flex-grow">
                      <h4 className="no-margin-top no-margin-bottom">
                        {
                          this.state.selectedNode.name
                        }
                      </h4>
                    </div>
                    <div>
                      <CustomButton 
                        display={<i className="fal fa-times"></i>}
                        onClick={() => this.setState({selectedNode: null})}
                        color="transparent"
                        size="xs"
                        />
                    </div>
                  </div>
                  <small className={"text-uppercase text-900 text-primary"} >Consideration:</small><br/>
                  <p className="margin-top-1rem">
                    {
                      this.state.selectedNode.description
                    }
                  </p>
                  {
                    foundNode.details ? <div>
                      <hr/>
                      {
                        foundNode.details.length > 0 &&   
                        <div className="margin-bottom-1rem">
                          <small className={"text-uppercase text-900 text-primary"} >Deeper context:</small>
                        </div>
                      }
                      {
                        foundNode.details && foundNode.details.map((detail, i) => {
                          return <div key={i} className="margin-bottom-2rem thin-line-height">
                            <div className="text-900 margin-bottom-05rem">{detail.name}</div>
                            <small>{detail.description}</small>
                          </div>
                        })
                      }

                      <div className="margin-top-2rem">
                        <CustomButton
                          display={<span><i className="far fa-fw fa-chart-network icon-before-text"/>Generate Additional Context</span>}
                          size="small"
                          block={true}
                          color="primary"
                          thinking={inquiryReducer.tryingToGenerateInquiryContext}
                          fail={inquiryReducer.generateInquiryContextFail}
                          success={inquiryReducer.generateInquiryContextSuccess}
                          onClick={() => {
                            this.generateAdditionalContext(foundNode);
                          }}

                          />
                      </div>
                    </div>
                    :
                    <div className="margin-top-2rem">
                      <CustomButton
                        display={<span><i className="far fa-fw fa-chart-network icon-before-text"/>Explore Deeper Context</span>}
                        size="small"
                        block={true}
                        color="primary"
                        thinking={inquiryReducer.tryingToGenerateInquiryContext}
                        fail={inquiryReducer.generateInquiryContextFail}
                        success={inquiryReducer.generateInquiryContextSuccess}
                        onClick={() => {
                          this.generateAdditionalContext(foundNode);
                        }}
                        />
                    </div>
                  }
                  
                  {
                    this.state.selectedNode.level > 0 &&
                    <div>
                      <hr/>
                      <CustomSelect
                        value={foundNode.priority}
                        options={[
                          {
                            label: <span><i className={"fas icon-before-text fa-fw fa-angle-double-up"}/>Highest</span>,
                            value: 'highest'
                          },
                          {
                            label: <span><i className={"fas icon-before-text fa-fw fa-angle-up"}/>High</span>,
                            value: 'high'
                          },
                          {
                            label: <span><i className={"fas icon-before-text fa-fw fa-minus-circle"}/>Normal</span>,
                            value: 'normal'
                          },
                          {
                            label: <span><i className={"fas icon-before-text fa-fw fa-angle-down"}/>Low</span>,
                            value: 'low'
                          },
                          {
                            label: <span><i className={"fas icon-before-text fa-fw fa-angle-double-down"}/>Lowest</span>,
                            value: 'lowest'
                          }
                        ]}
                        description="Set the priority of this element."
                        thinking={inquiryReducer.tryingToUpdateInquiryContext}
                        onChange={e => {
                          foundNode.priority = e;
                          dispatch(tryToUpdateInquiryContext({
                            inquiry_id: this.props.id,
                            context_map: map
                          }));
                        }}
                        />
                      <div className="margin-top-2rem">
                        <CustomButton
                          display={<span><i className="far fa-fw fa-trash icon-before-text"/>Remove This Node</span>}
                          size="xs"
                          block={true}
                          color="transparent-danger"
                          onClick={this.removeNode}  
                          disabled={!canWrite || inquiryReducer.tryingToUpdateInquiryContext}
                          fail={inquiryReducer.updateInquiryContextFail}
                          />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
      </div>
      <div className="inquiry-context-map-graph">
        <ForceGraph nodes={nodes} links={links} onNodeClick={e => this.setState({selectedNode: e})} selectedNodeId={(foundNode ? this.state.selectedNode || {} : {}).id}/>
      </div>

    
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, inquiryReducer, orgReducer } = state;

  return {
    userReducer, 
    inquiryReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(InquiryContextMap);

  