import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect.js'
import Checkbox from '../../kit/components/Checkbox/Checkbox.js'
import Callout from '../../kit/components/Callout/Callout.js'

import {
  tryToGetOrganizationInvites,
  tryToRetractOrganizationInvite
} from '../../actions/actions.export'

class OrganizationInvitesList extends Component {
  constructor(props){
    super(props);

    this.state = {
      
    }
  }


  componentDidMount(){
    const { dispatch, orgReducer } = this.props;

    const organization = orgReducer.cache[this.props.id];
    if(!organization.invites){
      dispatch(tryToGetOrganizationInvites({id: this.props.id}));
    }
  }

  render(){

    const { dispatch, orgReducer } = this.props;

    let canManageMembers = false;
    for(var i in orgReducer.memberships){
      if(orgReducer.memberships[i].organization.id === this.props.id){
        if(orgReducer.memberships[i].membership.manage_members){
          canManageMembers = true;
        }
      }
    }

    if(!canManageMembers) return <span/>;

    const organization = orgReducer.cache[this.props.id];

    return <div className={"box"}>
      
      <div className="flex-split margin-bottom-2rem">
        <h4 className="no-margin-top no-margin-bottom">
          Invites Awaiting Response
        </h4>
        <div className="list-right">
          {
            (orgReducer.tryingToGetOrganizationInvites) ?
            <i className="far fa-spinner-third fa-spin text-muted fa-fw"/>
            :
             <i className="far fa-sync-alt clickable icon-muted-until-hover fa-fw" onClick={()=>{ 
              dispatch(tryToGetOrganizationInvites({id: this.props.id}))
            }}/>
          }
        </div>
      </div>

      {
        (organization.invites !== undefined) ? 
          <div>
            {
              organization.invites.length === 0 ? 
              <p className="no-margin-bottom">You have no invites that are awaiting response.</p>
              :
              <table className="table-basic">
                <thead>
                  <tr>
                    <th>Invited At</th>
                    <th>Invited Email</th>
                    <th>Accepted At</th>
                    <th>Retract Invite</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    organization.invites.map((invite, i) => {
                      return <tr key={i}>
                        <td>{ moment(invite.invited_at).format('MMM Do, YYYY h:mm a')}</td>
                        <td>{invite.invited_email}</td>
                        <td>
                          {
                            invite.accepted_at ? 
                            moment(invite.accepted_at).format('MMM Do, YYYY h:mm a') 
                            :
                            <span className="text-muted">not yet accepted</span>
                          }
                        </td>
                        <td>
                          {
                            !invite.accepted_at ?
                            <CustomButton
                              display="Retract Invite"
                              size="small"
                              thinking={orgReducer.tryingToRetractOrganizationInvite && orgReducer.retractOrganizationInviteForm.lastSubmit.invite_id === invite.id}
                              onClick={()=>{
                                dispatch(tryToRetractOrganizationInvite({
                                  id: organization.id,
                                  invite_id: invite.id
                                }))
                              }}
                              />
                            :
                            <span className="text-muted">already accepted</span>
                          }
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            }
            {
              !organization.invitesEmpty && 
              <div className="flex-column-center margin-top-2rem">
                <CustomButton
                  display="Load More"
                  thinking={orgReducer.tryingToGetOrganizationInvites}
                  success={orgReducer.getOrganizationInvitesSuccess}
                  fail={orgReducer.getOrganizationInvitesFail}
                  onClick={()=>{
                    dispatch(tryToGetOrganizationInvites({
                      id: organization.id,
                      startAfter: organization.invites[organization.invites.length - 1].id
                    }))
                  }}
                  />
              </div>
            }
          </div>
          :
          <p className="no-margin-bottom">You have no invites that are awaiting response.</p>
      }
    </div>
  }
}


const mapStateToProps = (state) => {
  const { orgReducer } = state;

  return {
    orgReducer
  }
}

export default connect(mapStateToProps)(OrganizationInvitesList);
