import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import {
  tryToGetUserProfile,
  tryToGetOrganizationProfile,
  tryToGetProject,
  tryToGetComponent,
  tryToGetKnowledge,
  tryToGetInquiry,
  showTooltip,
  hideTooltip
} from '../../actions/actions.export'

import stringToColor from '../../utilities/stringToColor'
import FlowRenderer from 'components/FlowBuilder/FlowRenderer'
import Histogram from 'components/Histogram/Histogram'

import names from 'configs/config.names.js'

import './Hydrate.scss';

class Hydrate extends Component {

  constructor(props){
    super(props);

    this.state = {
      requestSent: false,
      recenterCounter: 0
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {

    const { userReducer, orgReducer, projectReducer, knowledgeReducer, componentReducer, inquiryReducer, dispatch} = nextProps;

    let type = nextProps.type;
    let id = nextProps.id;

    if(!id) return {...prevState};
    
    if(type === 'user'){
      if(!userReducer.cache[id] && !prevState.requestSent && userReducer.cacheRequests.indexOf(id) === -1){
        dispatch(tryToGetUserProfile({id: id}));
        return {
          ...prevState,
          requestSent: true
        }
      }
    } else if(type === 'organization'){
      if(!orgReducer.cache[id] && !prevState.requestSent && orgReducer.cacheRequests.indexOf(id) === -1){
        dispatch(tryToGetOrganizationProfile({id: id}));
        return {
          ...prevState,
          requestSent: true
        }
      }
    } else if(type === 'projects'){
      if(!projectReducer.cache[id] && !prevState.requestSent && projectReducer.cacheRequests.indexOf(id) === -1){
        dispatch(tryToGetProject({id: id}));
        return {
          ...prevState,
          requestSent: true
        }
      }
    } else if(type === 'components'){
      if(!componentReducer.cache[id] && !prevState.requestSent && componentReducer.cacheRequests.indexOf(id) === -1){
        dispatch(tryToGetComponent({id: id}));
        return {
          ...prevState,
          requestSent: true,
          recenterCounter: prevState.recenterCounter + 1
        }
      }
    } else if(type === 'knowledge'){
      if(!knowledgeReducer.cache[id] && !prevState.requestSent && knowledgeReducer.cacheRequests.indexOf(id) === -1){
        dispatch(tryToGetKnowledge({id: id}));
        return {
          ...prevState,
          requestSent: true
        }
      }
    } else if(type === 'inquiries'){
      if(!inquiryReducer.cache[id] && !prevState.requestSent && inquiryReducer.cacheRequests.indexOf(id) === -1){
        dispatch(tryToGetInquiry({id: id}));
        return {
          ...prevState,
          requestSent: true
        }
      }
    }


    return {
      prevState,
      recenterCounter: prevState.recenterCounter + 1
    }
  }

  // componentDidMount(){
  //   if(this.props.type === 'components'){
  //     setTimeout(()=>{
  //       this.setState({
  //         recenterCounter: this.state.recenterCounter + 1
  //       })
  //     }, 4000);
  //   }    
  // }


  render(){

    const { dispatch, userReducer, orgReducer, projectReducer, knowledgeReducer, componentReducer, inquiryReducer, intelReducer } = this.props;

    let type = this.props.type;
    let id = this.props.id;
    let mode = this.props.mode || "basic";
    let placeholder = this.props.placeholder;
    let field = this.props.field || "display_name";
    let content;
    let obj = {};

    const renderAvatar = (obj, size) => {
      if(!obj) obj = {};
      if(size === "small") size = 18;
      if(!size) size = 30;

      let backupColor = stringToColor(obj.id || "");
      
      let avatar_url = "/img/default_user_avatar.png"
      let usingDefault = true;
      if(type === "organization") avatar_url = "/img/default_organization_avatar.png";
      if(type === "components") avatar_url = "/img/avatar.png";

      if(obj.avatar_url){
        avatar_url = obj.avatar_url;
        usingDefault = false;
      }

      if(obj.suspended){
        backupColor = '#eeeff2';
        if(type === "organization"){
          avatar_url = '/img/suspended_organization_avatar.png';
        } else {
          avatar_url = '/img/suspended_user_avatar.png';
        }
        usingDefault = true;
      }

      if(avatar_url) avatar_url = 'url(' + avatar_url + ')';

      if(type === "components") backupColor = '#fff';

      // if size is small, we want to add a border
      
      return <span 
        key={obj.id + '_' + obj.avatar_url + '_' + obj.suspended} 
        className={type === "organization" ? "hydrate-avatar-rounded-square-wrapper" : "hydrate-avatar-circle-wrapper" }
        style={{
          width: size, 
          height: size,
          minWidth: size, 
          background: obj.id ? backupColor : '#fff',
          backgroundImage: avatar_url,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          border: !usingDefault && size < 20 ? '1px solid #444' : 'none',
          top: 2
        }}/>
    }

    let kindURL = "";

    if(type === "me"){
      
      obj = userReducer.myData;
      if(!placeholder) placeholder = "you";

    } else if(type === "user"){

      obj = userReducer.cache[id];
      if(!placeholder) placeholder = "a user";

    } else if(type === "agent"){

      obj = componentReducer.cache[id];
      if(!placeholder) placeholder = "an agent";

    } else if(type === "organization"){

      obj = orgReducer.cache[id];
      
      if(!placeholder) placeholder = "an organization";

    } else if(type === "projects"){
      kindURL = "projects";
      obj = projectReducer.cache[id];
      if(!placeholder) placeholder = "a project";      

    } else if(type === "components"){
      kindURL = "agents";
      obj = componentReducer.cache[id];
      if(!placeholder) placeholder = "a flow";      

    } else if(type === "knowledge"){
      kindURL = "knowledge";
      obj = knowledgeReducer.cache[id];
      if(!placeholder) placeholder = "a knowledge base";      
    } else if(type === "inquiries"){
      kindURL = "inquiries";
      obj = inquiryReducer.cache[id];
      if(!placeholder) placeholder = "a project";      
    } 

    if(!obj) obj = {};

    const detail = <div className="list-left hydrate-detail">
      <div className="margin-right-1rem">{renderAvatar(obj, 75)}</div>
      <div className="text-ellipsis-wrapper">
        <h4 className="text-900 no-margin text-ellipsis">{obj.display_name}</h4>
        <div className="text-400 text-ellipsis">
          {obj.bio && <div className="margin-bottom-05rem text-ellipsis"><small>{obj.bio}</small></div>}
          { type === "organization" && <nobr className="margin-right-1rem"><small><i className="far fa-users icon-before-text text-muted"/>{ obj.member_count } member{obj.member_count > 1 && "s"}</small></nobr>}
          {obj.location && <nobr className="margin-right-1rem"><small><i className="far fa-map-marker-alt icon-before-text text-muted"/>{ obj.location } </small></nobr>}
          { (type === "user" && obj.company) && <nobr className="margin-right-1rem"><small><i className="far fa-briefcase icon-before-text text-muted"/>{ obj.company } </small></nobr>}

        </div>
      </div>
    </div>

    switch(mode){
      
      case 'basic':
        content = obj[field] || placeholder;
        break;

      case 'avatar':
        content = renderAvatar(obj, this.props.size);
        break;

      case 'lockup':

        content = <div className="hydrate-lockup-wrapper">
          <div className="hydrate-lockup-inner">
            <span className="hydrate-lockup-avatar">{renderAvatar(obj, this.props.size)}</span> <span>{obj[field] || placeholder}</span>
          </div>
        </div>
        break;

      case 'detail':
        content = <div className="box">{detail}</div>;
        break;

      case 'thumbnail':
        if(names[type]){
          let linkTo = "/workbench/" + obj.scope + "/" + names[type].url + "/" + obj.id;

          if(this.props.project){
            linkTo= "/workbench/" + obj.scope + "/projects/" + this.props.project + "/" + names[type].url + "/" + obj.id;
          }

          let loadedVersion;
          if(type === "components" && obj.versions){
            loadedVersion = obj.versions.find(v => v.id === 'draft');
          }

          content = 
            <div className={"box box-clickable flex-grow box-half-pad box-shadow-" + type}>
              <div className="flex-split margin-bottom-05rem">
                <div className="margin-right-05rem flex-column-stretch">
                  <h5 className="no-margin text-900 text-ellipsis-1-lines ">
                    <small><i className={"fad fa-lg fa-fw fa-" + names[type].icon + " icon-before-text text-" + type}/></small>
                    <span className={""}>{obj.display_name || <span className="text-muted">loading...</span>}</span>
                  </h5>
                </div>
                <div className="list-right list-right-no-wrap ">
                  {
                    obj.visibility === "team" && <div 
                    className="text-tag text-tag-tiny text-tag-warning"
                    onMouseEnter={(e)=>{
                      dispatch(showTooltip({
                        el: e.currentTarget,
                        lag: 750,
                        position: 'top',
                        nobr: false,
                        content: <div className="tooltip-no-style" style={{maxWidth: 250}}>Only visible to invited team members and certain organization administrators.</div>
                      }))
                    }}
                    onMouseLeave={(e)=>{
                      dispatch(hideTooltip());
                    }}
                    >
                     Team<i className="fas fa-lock icon-after-text"/>
                    </div>
                  }
                  <span className="">
                    <ExportedHydrate type="user" id={obj.created_by} mode="avatar" size={22}/>
                  </span>
                </div>
              </div>
              
              {
                type === 'components' && <div className="hydrate-component-flow-wrapper">
                  {
                    obj ? (
                      (obj.flow_mode && loadedVersion) ? <div className="hydrate-component-flow-inner">
                        {/* <FlowBuilder
                          initialNodes={intelReducer.flow_nodes.length > 0 ? loadedVersion.flow_nodes : []}
                          initialLinks={intelReducer.flow_nodes.length > 0 ? loadedVersion.flow_links : []}
                          highlightedNodeIds={[]}
                          nodeTypes={intelReducer.flow_nodes}
                          interactive={false}
                          simpleMode={true}
                          recenterCounter={this.state.recenterCounter}
                          /> */}
                        <FlowRenderer
                          nodes={intelReducer.flow_nodes.length > 0 ? loadedVersion.flow_nodes : []}
                          links={intelReducer.flow_nodes.length > 0 ? loadedVersion.flow_links : []}
                          showMinimap={false}
                          simpleMode={true}
                          interactive={false}
                          />
                      </div>
                      :
                      
                      <div className="hydrate-component-flow-inner hydrate-component-flow-inner--empty">
                        <small className="text-gs5">Legacy Agent</small>
                      </div>
                    ) :
                    <div className="hydrate-component-flow-inner hydrate-component-flow-inner--empty">
                      <i className="far fa-spinner-third fa-spin"/>
                    </div>
                  }
                </div>
              }
              {
                type === 'knowledge' && <div className="sixteen-by-nine-parent">
                  {
                    obj.content_histogram ?
                  
                    <div className="sixteen-by-nine-child overflow-hidden">
                      <Histogram objData={obj.content_histogram} maxBucket={8192} bucketSize={100} yMax={1000} logScale={true}/>
                    </div>
                    :
                    <div className="sixteen-by-nine-child flex-column-center-center">
                      <small className="text-gs5">Legacy Knowledge Base</small>
                    </div>
                  }
                </div>
              }
              {
                type === 'projects' && <div className="sixteen-by-nine-parent">
                  <div className="sixteen-by-nine-child flex-column-center-center">
                    <i className="fad fa-3x fa-browser text-muted"/>
                  </div>
                </div>
              }
              {/* <p className="text-ellipsis-2-lines thin-line-height no-margin-top margin-bottom-05rem text-gs3" style={{height: 33}}>
                <small>{obj.description}</small>
              </p> */}
              <small className="text-muted">
                {
                  obj.created_at &&
                  <span>updated {moment(obj.last_updated || obj.created_at).fromNow()} by <ExportedHydrate type="user" id={obj.created_by} field="display_name"/></span>
                }
              </small>
            </div>

          if(!this.props.noLink){
            content = <Link to={linkTo} className="margin-bottom-2rem link-no-decoration text-400 flex-column-stretch">
              {content}
            </Link>
          } else {
            content = <div className="margin-bottom-2rem text-400 flex-column-stretch">
              {content}
            </div>
          }
        }
        break;

      default: 
        content = <span/>;
        break;
    }


    if(this.props.link && !obj.hide_profile){
      return <Link 
        to={type === "organization" ? "/oid/" + obj.id : "/uid/" + obj.id}
        className={"hydrate " + (this.props.linkNoDecoration || mode === 'detail' ? "link-no-decoration" : "")}
        
        onMouseEnter={(e)=>{
          if(this.props.tooltip && obj.id){
            dispatch(showTooltip({
                el: e.currentTarget,
                nobr: false,
                lag: 750,
                position: 'top',
                content: <div style={{maxWidth: 500, minWidth: 300}} className="tooltip-no-style">
                  {detail}
                </div>
              }))
          }
        }}
        onMouseLeave={()=>{
          if(this.props.tooltip){
            dispatch(hideTooltip());
          }
        }}
        >
        {content}
      </Link>
    }

    if(this.props.textOnly && this.props.mode === 'basic'){
      return content;
    }

    return <span className="hydrate"
      onClick={() => {
        if(this.props.onClick) this.props.onClick(obj)
      }}
      onMouseEnter={(e)=>{
        if(this.props.tooltip && obj.id){
          dispatch(showTooltip({
              el: e.currentTarget,
              nobr: false,
              lag: 750,
              position: 'top',
              content: <div style={{maxWidth: 500, minWidth: 300}} className="tooltip-no-style">
                {detail}
              </div>
            }))
        }
      }}
      onMouseLeave={()=>{
        if(this.props.tooltip){
          dispatch(hideTooltip());
        }
      }}
      >
      {content}
    </span>
  }
}

  
const mapStateToProps = (state) => {
  const { userReducer, orgReducer, projectReducer, knowledgeReducer, componentReducer, inquiryReducer, intelReducer } = state;

  return {
    userReducer, 
    orgReducer,
    projectReducer,
    knowledgeReducer,
    componentReducer,
    inquiryReducer,
    intelReducer
  }
}

const ExportedHydrate = connect(mapStateToProps)(Hydrate );

export default ExportedHydrate
  