import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import {
  passProfileProjectsToCache
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// GET USER PROFILE
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestGetUserProfile = packet => {
  return {
    type: 'REQUEST_GET_USER_PROFILE',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveGetUserProfileSuccess = data => ({
  type: 'RECEIVE_GET_USER_PROFILE_SUCCESS',
  data
})

export const receiveGetUserProfileFail = data => ({
  type: 'RECEIVE_GET_USER_PROFILE_FAIL',
  data
})

export const tryToGetUserProfile = packet => (dispatch, getState) => {

  dispatch(requestGetUserProfile(packet));


  let headers = {
    "Content-Type": "application/json",
  }

  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/users/profile?id=' + packet.id, {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveGetUserProfileSuccess(json));
      // dispatch(passProfileProjectsToCache(json.projects));
    })
    .catch(e => {
      dispatch(receiveGetUserProfileFail({errors:e, lastSubmit: packet}));
      if(window.location.pathname.indexOf('/uid/' + packet.id) === 0){
        dispatch(push('/oops'));
      }
    })
}
