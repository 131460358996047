import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Matter, { use } from 'matter-js';
import * as d3 from 'd3';

import {
  showTooltip,
  hideTooltip,
} from 'actions/actions.export';

import { darkenHex } from 'utilities/colorUtilities';

const MatterStack = ({ blocks, blocksPerLineSection = [], sectionLabels = [], totalBlocks, onBlockHover, onBlockClick }) => {
  

  const sceneRef = useRef(null);
  const engineRef = useRef(null);
  const renderRef = useRef(null);
  const worldRef = useRef(null);
  const svgRef = useRef(null);
  const hoveredBlockRef = useRef(null);
  const dispatch = useDispatch();

  const getBlockSettings = (block) => {

    let t = {
      // inertia: 1,
      friction: 0.1,
      restitution: .8,
      frictionAir: 0.01,
      density: .0001,
      slop: 0
    }

    if(block.score < 6){
      t = {
        density: 0.001, // Lower density to make blocks lighter
        restitution: .8, // Increase restitution to make blocks bouncier
        friction: 0.00, // Lower friction to make blocks slide off each other easily
        frictionAir: 0.001, // Keep air friction low to not counteract the other effects
        // inertia: 5, // Allow some rotation but resist excessive rotation
        slop: 0.8, // Increase slop to make stacking more difficult
      }
    }

    return t
  }

  useEffect(() => {
    const Engine = Matter.Engine,
          Render = Matter.Render,
          Runner = Matter.Runner,
          MouseConstraint = Matter.MouseConstraint,
          Mouse = Matter.Mouse,
          Composite = Matter.Composite,
          Events = Matter.Events,
          Bodies = Matter.Bodies;

    // Create engine
    const engine = Engine.create();
    engineRef.current = engine;
    engine.gravity.y = 0.1; // Decrease gravity's effect
    const world = engine.world;
    worldRef.current = world;

    d3.select(svgRef.current).selectAll().remove();

    // Create renderer
    const createRenderer = () => {
      if (renderRef.current) {
        Matter.Render.stop(renderRef.current);
        renderRef.current.canvas.remove();
        renderRef.current.canvas = null;
        renderRef.current.context = null;
        renderRef.current.textures = {};
      }

      const render = Render.create({
        element: sceneRef.current,
        engine: engine,
        options: {
          width: sceneRef.current.clientWidth,
          height: sceneRef.current.clientHeight,
          background: "transparent",
          showAngleIndicator: false,
          wireframes: false,
        }
      });

      Render.run(render);
      renderRef.current = render;

      return render;
    };

    const render = createRenderer();

    // Create runner
    const runner = Runner.create();
    Runner.run(runner, engine);

    // Initial block creation
    const rectangleHeight = render.options.height / totalBlocks;
    const initialStack = blocks.map((block, index) => {
      const rectangleWidth = render.options.width * 0.5 * (block.widthPercent / 100);
      const body = Bodies.rectangle(
        render.options.width * .4 + rectangleWidth / 2 + Math.random() * 4 * (10 - block.score),
        render.options.height - index * rectangleHeight + rectangleHeight / 2 - rectangleHeight,
        rectangleWidth,
        rectangleHeight,
        {
          ...getBlockSettings(block),
          render: {
            fillStyle: block.color,
            strokeStyle: darkenHex(block.color, 20),
            lineWidth: 1
          }
        }
      );
      body.label = block.id;
      return body;
    });
    
    Composite.add(world, initialStack);

    // Add walls
    Composite.add(world, [
      Bodies.rectangle(render.options.width, render.options.height / 2, 2, render.options.height, { label: 'edge-wall', isStatic: true, restitution: 0, render: { fillStyle: '#ffffff' } }),
      Bodies.rectangle(0, render.options.height / 2, 2, render.options.height, { label: 'edge-wall', isStatic: true,restitution: 0, render: { fillStyle: '#ffffff' } }),
      Bodies.rectangle(render.options.width / 2, render.options.height, render.options.width, 10, { label: 'edge-wall', isStatic: true, restitution: 0, render: { fillStyle: '#000000' } }),
      Bodies.rectangle(render.options.width /2, 0, render.options.width, 2, { label: 'edge-wall', label:'edge-wall', isStatic: true, restitution: 0, render: { fillStyle: '#ffffff' } })
    ]);

    // Add mouse control
    const mouse = Mouse.create(render.canvas);
    const mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.05, // Lower stiffness to reduce the force applied
        damping: 0.2, // Increase damping to reduce the energy transfer
        render: {
          visible: false
        }
      }
    });
    Composite.add(world, mouseConstraint);

    // Keep the mouse in sync with rendering
    render.mouse = mouse;

    // Fit the render viewport to the scene
    Render.lookAt(render, {
      min: { x: 0, y: 0 },
      max: { x: render.options.width, y: render.options.height }
    });

        
    // Mouse events for Matter.js blocks
    Events.on(mouseConstraint, 'mousemove', function(event) {
      const mousePosition = event.mouse.position;
      const foundBodies = Matter.Query.point(Composite.allBodies(world), mousePosition);

      if (foundBodies.length > 0) {
        const hoveredBlock = foundBodies.find(body => body.label && !body.label.startsWith('edge-wall'));
        if (hoveredBlock && hoveredBlock !== hoveredBlockRef.current) {
          // Reset the previously hovered block's style
          if (hoveredBlockRef.current) {
            hoveredBlockRef.current.render.lineWidth = 1;
            hoveredBlockRef.current.render.strokeStyle = hoveredBlockRef.current.render.fillStyle;
          }

          // Bring the new hovered block to the front by removing and adding it back
          Composite.remove(world, hoveredBlock);
          Composite.add(world, hoveredBlock);

          // Set the new hovered block's style
          hoveredBlock.render.lineWidth = 5;
          hoveredBlock.render.strokeStyle = '#000000';
          hoveredBlockRef.current = hoveredBlock;
          render.canvas.style.cursor = 'pointer'; // Change cursor to pointer

          if (onBlockHover) {
            onBlockHover(hoveredBlock);
          }
        }
      } else {
        // Reset cursor and border if no block is hovered
        if (hoveredBlockRef.current) {
          hoveredBlockRef.current.render.lineWidth = 1;
          hoveredBlockRef.current.render.strokeStyle = darkenHex(hoveredBlockRef.current.render.fillStyle, 20);
          hoveredBlockRef.current = null;
          render.canvas.style.cursor = 'default'; // Reset cursor

          if(onBlockHover) onBlockHover(null);
        }
      }
    });
    

    Events.on(mouseConstraint, 'mousedown', function(event) {
      const mousePosition = event.mouse.position;
      const foundBodies = Matter.Query.point(Composite.allBodies(world), mousePosition);

      if (foundBodies.length > 0) {
        const clickedBlock = foundBodies[0];
        if (onBlockClick) onBlockClick(clickedBlock);
      }
    });

    let resizeTimeout = null;
    const resizeDebounce = 150;

    const handleResize = () => {
      const width = sceneRef.current.clientWidth;
      const height = sceneRef.current.clientHeight;

      // Update render dimensions
      render.bounds.max.x = width;
      render.bounds.max.y = height;
      render.options.width = width;
      render.options.height = height;
      render.canvas.width = width;
      render.canvas.height = height;

      // Clear and recreate the stack
      const rectangleHeight = render.options.height / totalBlocks;
      const newStack = blocks.map((block, index) => {
        const rectangleWidth = render.options.width * 0.5 * (block.widthPercent / 100);
        const body = Bodies.rectangle(
          render.options.width * .4 + rectangleWidth / 2 + Math.random() * 4 * (10 - block.score),
          render.options.height - index * rectangleHeight + rectangleHeight / 2 - rectangleHeight,
          rectangleWidth,
          rectangleHeight,
          {
            ...getBlockSettings(block),
            render: {
              fillStyle: block.color,
              strokeStyle: darkenHex(block.color, 20),
              lineWidth: 1
            }
          }
        );

        body.label = block.id;
        return body;
      });

      Composite.clear(world, false);
      Composite.add(world, newStack);
      Composite.add(world, [
        Bodies.rectangle(width, height / 2, 2, height, { label:'edge-wall', isStatic: true, restitution: 0, render: { fillStyle: '#ffffff' } }),
        Bodies.rectangle(0, height / 2, 2, height, { label:'edge-wall', isStatic: true, restitution: 0, render: { fillStyle: '#ffffff' } }),
        Bodies.rectangle(width / 2, height, width, 10, { label:'edge-wall', isStatic: true, restitution: 0, render: { fillStyle: '#000000' } }),
        Bodies.rectangle(width /2, 0, width, 2, { label:'edge-wall', isStatic: true, restitution: 0, render: { fillStyle: '#ffffff' } })

      ]);


      // Resize D3 SVG
      d3.select(svgRef.current)
        .attr('width', width)
        .attr('height', height)
        ;

      // shift the lines to the new positions
      let y = height;
      for (let i = 0; i < blocksPerLineSection.length; i++) {
        y -= blocksPerLineSection[i] * (rectangleHeight - 0);
        d3.select(svgRef.current).select('#stack-dashed-line-' + i)
          .attr('y1', y)
          .attr('y2', y);

        d3.select(svgRef.current).select('#stack-section-label-' + i)
          .attr('y', y + 15);
      }

      // adjust template rect
      d3.select(svgRef.current).select('.stack-template-rect')
        .attr('y', rectangleHeight)
        .attr('x', width * .4)
        .attr('width', width * .5)
        .attr('height', rectangleHeight * (totalBlocks - 1));
    };
    

    const resizeTimeoutHandler = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        handleResize();
      }, resizeDebounce);
    };

    window.addEventListener('resize', resizeTimeoutHandler);

    // Initialize D3 SVG
    const svg = d3.select(svgRef.current)
      .attr('width', render.options.width)
      .attr('height', render.options.height)
      .style('background', 'transparent');

    // add 4 svg lines spaced vertically using blocksPerLineSection and rectangleHeight starting at the bottom of the screen
    let y = render.options.height;
    for (let i = 0; i < blocksPerLineSection.length; i++) {
      y -= blocksPerLineSection[i] * (rectangleHeight - 0);
      svg.append('line')
        .attr('id', 'stack-dashed-line-' + i)
        .attr('x1', 0)
        .attr('y1', y)
        .attr('x2', render.options.width)
        .attr('y2', y)
        .attr('class', 'stack-dashed-line');

      // add section labels
      svg.append('text')
        .attr('id', 'stack-section-label-' + i)
        .attr('x', 10)
        .attr('y', y + 15)
        .attr('class', 'stack-section-label')
        .text(sectionLabels[i]);
    }

    // add a template rectangle that we hope the blocks will fill in
    svg.append('rect')
      .attr('x', render.options.width * .4)
      .attr('y', rectangleHeight)
      .attr('width', render.options.width * .5)
      .attr('height', rectangleHeight * (totalBlocks - 1))
      .attr('class', 'stack-template-rect');
    


    // Cleanup on component unmount
    return () => {
      Matter.Render.stop(render);
      Matter.Runner.stop(runner);
      Composite.clear(world, false);
      Matter.World.clear(engine.world, false);
      Matter.Engine.clear(engine);
      render.canvas.remove();
      window.removeEventListener('resize', resizeTimeoutHandler);
    };
  }, []);

  return <div 
    ref={sceneRef} style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
    onMouseLeave={e => {
      // ensure no block is hovered when the mouse leaves the scene
      if (hoveredBlockRef.current) {
        hoveredBlockRef.current.render.lineWidth = 1;
        hoveredBlockRef.current.render.strokeStyle = darkenHex(hoveredBlockRef.current.render.fillStyle, 20);
        hoveredBlockRef.current = null;
      }
      if(onBlockHover) onBlockHover(null);

      dispatch(hideTooltip());
    }}
    >
    <svg className="stack-overlay" ref={svgRef} />
    <div className="stack-text-overlay">
      <div className="list-right">
        <i className="far fa-info-circle fa-fw text-muted" 
          onMouseEnter={e => {
            
            dispatch(showTooltip({
              el: e.target,
              position: 'left',
              lag: 500,
              nobr: false,
              content: <div style={{width: 450}}>
                <strong>What is this?</strong>
                <div className="text-400">
                  This is a visual representation of the strength of your project's foundation. The blocks represent different aspects of your project, and the height of the stack represents the overall strength of your project.
                  <br/>
                  <br/>
                  <span className="text-success text-900">Green</span>, wider blocks are dimensions where your project description is strong. Narrower <span className="text-warning text-900">orange-yellow</span> blocks could use improvement. <span className="text-danger text-900">Red</span> thin blocks need attention before proceeding.
                  <br/><br/>
                  Click on a block to see feedback on that aspect of your project.
                  <br/>
                  Click <strong>Restack</strong> above to reset the visualization.
                </div>
              </div>
            }))
          }}
          onMouseLeave={e => {
            dispatch(hideTooltip())
          }}
        />
      </div>
    </div>
  </div>;
};

export default MatterStack;
