import React, { useState, useEffect, useRef } from 'react';

import './ASCIIBackground.scss';

const ASCIIBackground = ({ imagePath, fontSize, background = '#fff', foreground = '#000', placementMode = 'cover', charStep = 1}) => {
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
    const [updateCounter, setUpdateCounter] = useState(0);
    const renderedAsciiArtRef = useRef([]); // Ref to store current ASCII art
    const targetAsciiArtRef = useRef([]);
    const containerRef = useRef(null);
    const canvasRef = useRef(null);
    const animationRef = useRef(null);
    const chars = ['@', 'M', 'W', '#', 'N', 'G', 'H', 'Q', 'R', 'O', 'B', '8', '&', '%', 'Z', 'X', 'Y', 'U', 'J', 'C', 'L', 'K', 'V', 'P', 'D', 'A', 'E', 'F', '4', '5', '7', 'S', '2', '3', '6', '9', '$', 'T', '+', '<', '>', '?', '!', '[', ']', '{', '}', '(', ')', '/', '\\', '|', '^', '_', '-', '=', '~', ':', ';', ',', '.', '´', '`', ' '];
    const mousePosition = useRef({ x: 0, y: 0 });
    const localCharStep = useRef(charStep);

    useEffect(() => {
      if (imagePath && containerSize.width && containerSize.height) {
          processImage(imagePath, containerSize.width, containerSize.height, fontSize, newAsciiArt => {
              targetAsciiArtRef.current = newAsciiArt;
                if (!animationRef.current) {
                    startTweening(charStep);
                }
            });
        }
    }, [imagePath, fontSize, containerSize, mousePosition.current.x, mousePosition.current.y, charStep]);

    const startTweening = (charStepTween) => {
      if (renderedAsciiArtRef.current.length === 0 && targetAsciiArtRef.current.length > 0) {
          const blankGrid = targetAsciiArtRef.current.map(row => row.map(() => ' '));
          renderedAsciiArtRef.current = blankGrid;
      }

      const step = () => {
          renderedAsciiArtRef.current = tweenTowards(renderedAsciiArtRef.current, targetAsciiArtRef.current, charStepTween);
          setUpdateCounter(prevCounter => prevCounter + 1); // Trigger re-render
          animationRef.current = requestAnimationFrame(step);
      };

      animationRef.current = requestAnimationFrame(step);
    };


    // update localCharStep
    useEffect(() => {
      localCharStep.current = charStep;
    }, [charStep]);

    useEffect(() => {
        // set up listener for mouse move
        // const handleMouseMove = (event) => {
        //     const mouseX = event.clientX;
        //     const mouseY = event.clientY;
        //     const containerRect = containerRef.current.getBoundingClientRect();
        //     const containerX = containerRect.left;
        //     const containerY = containerRect.top;
        //     const containerWidth = containerRect.width;
        //     const containerHeight = containerRect.height;

        //     const relativeX = mouseX - containerX;
        //     const relativeY = mouseY - containerY;
            
        //     mousePosition.current = {
        //       x: relativeX,
        //       y: relativeY,
        //     }
        // };

        // document.addEventListener('mousemove', handleMouseMove);
        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }

            // document.removeEventListener('mousemove', handleMouseMove);
        };
        
    }, []);

    const tweenTowards = (currentArt, targetArt) => {
      if (!currentArt.length) return targetArt;
      if (!targetArt.length) return currentArt;

      let tweenStep = parseInt(localCharStep.current);
    
      // Adjust tweenStep if necessary to ensure a smooth transition
      // It should be a small fraction to slow down the transition
    
      let resultArt = [];
      const maxRows = Math.max(currentArt.length, targetArt.length);
    
      for (let rowIndex = 0; rowIndex < maxRows; rowIndex++) {
        const currentRow = currentArt[rowIndex] || [];
        const targetRow = targetArt[rowIndex] || [];
        let newRow = [];
    
        const maxCols = Math.max(currentRow.length, targetRow.length);
        for (let colIndex = 0; colIndex < maxCols; colIndex++) {
          const currentChar = currentRow[colIndex] || ' '; // Assuming ' ' as fill character
          const targetChar = targetRow[colIndex] || ' '; // Assuming ' ' as fill character
          const currentCharIndex = chars.indexOf(currentChar);
          const targetCharIndex = chars.indexOf(targetChar);
    
          // Apply the tweening step correctly to ensure gradual change
          let newIndex = currentCharIndex;
          if (currentCharIndex < targetCharIndex) {
            newIndex = Math.min(currentCharIndex + tweenStep, targetCharIndex);
          } else if (currentCharIndex > targetCharIndex) {
            newIndex = Math.max(currentCharIndex - tweenStep, targetCharIndex);
          }
    
          newRow.push(chars[Math.max(0, Math.min(newIndex, chars.length - 1))]);
        }
    
        resultArt.push(newRow);
      }
    
      return resultArt;
    };
    
    
  
    const processImage = (imageSrc, containerWidth, containerHeight, fontSize, callback) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = imageSrc;

      img.onload = () => {
        let scale, offsetX, offsetY, drawWidth, drawHeight;

        switch (placementMode) {
            case 'cover':
                scale = Math.max(containerWidth / img.width, containerHeight / img.height);
                break;
            case 'contain':
                scale = Math.min(containerWidth / img.width, containerHeight / img.height);
                break;
            case 'contain-bottom':
            case 'contain-top':
                scale = Math.min(containerWidth / img.width, containerHeight / img.height);
                break;
            default:
                scale = Math.max(containerWidth / img.width, containerHeight / img.height);
        }

        const scaledWidth = img.width * scale;
        const scaledHeight = img.height * scale;

        const canvas = canvasRef.current;
        if (!canvas) return;
        canvas.width = containerWidth;
        canvas.height = containerHeight;
        const ctx = canvas.getContext('2d');

        // Common calculations for offsetX and offsetY
        offsetX = (containerWidth - scaledWidth) / 2;
        offsetY = (containerHeight - scaledHeight) / 2;

        // Adjustments for "contain-bottom" and "contain-top"
        if (placementMode === 'contain-bottom' && scaledHeight < containerHeight) {
            offsetY = containerHeight - scaledHeight; // Dock to bottom
        } else if (placementMode === 'contain-top' && scaledHeight < containerHeight) {
            offsetY = 0; // Dock to top, but offsetY is already 0 by default
        }

        drawWidth = scaledWidth;
        drawHeight = scaledHeight;

        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, containerWidth, containerHeight);

        // Draw the image with calculated dimensions and offsets
        ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);

        // draw a black circle where the mouse is
        // ctx.globalAlpha = 1;
        // ctx.beginPath();
        // console.log(mousePosition.current.x, mousePosition.current.y);
        // ctx.arc(mousePosition.current.x, mousePosition.current.y, 200, 0, 2 * Math.PI);
        // ctx.fillStyle = background;
        // ctx.fill();
        // ctx.globalAlpha = 1;


        

        const cols = Math.floor(containerWidth / (fontSize * 0.52)); // Adjusted for typical monospace font width
        const rows = Math.floor(containerHeight / (fontSize + .25));
        
        const imageData = ctx.getImageData(0, 0, containerWidth, containerHeight);
        const data = imageData.data;
        let textGrid = [];
        const noiseIntensity = 0.001; // Adjust the intensity of the noise

        for (let y = 0; y < rows; y++) {
          let row = [];
          for (let x = 0; x < cols; x++) {
            const pixelX = Math.floor((x + 0.5) * (containerWidth / cols));
            const pixelY = Math.floor((y + 0.5) * (containerHeight / rows));
            const i = (pixelY * containerWidth + pixelX) * 4;

            const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;

            // Introduce noise
            let noise = (Math.random() * 2 - 1) * noiseIntensity * 255;
            let adjustedAvg = avg + noise;
            adjustedAvg = Math.max(0, Math.min(adjustedAvg, 255)); // Clamp the value to be between 0 and 255

            const charIndex = Math.round(adjustedAvg / 255 * (chars.length - 1));
            row.push(chars[charIndex]);
          }
          textGrid.push(row);
        }

        callback(textGrid);
      };

      img.onerror = () => {
          console.error('Image loading failed');
      };
    };

    // Function to update container size
    const updateContainerSize = () => {
      if (containerRef.current) {
          setContainerSize({
              width: containerRef.current.offsetWidth,
              height: containerRef.current.offsetHeight
          });
      }
  };

  useEffect(() => {
      updateContainerSize(); // Initial size update
      window.addEventListener('resize', updateContainerSize);
      return () => {
          window.removeEventListener('resize', updateContainerSize);
      };
  }, []);

    return (
      <div ref={containerRef} style={{ position: 'relative', whiteSpace: 'pre', backgroundColor: background }} className="ascii-background">
          <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, opacity: 0 }} />
          {renderedAsciiArtRef.current.map((row, i) => (
              <div key={i} className="ascii-background-row" style={{color: foreground}}>{row.join('')}</div>
          ))}
      </div>
  );
};

export default ASCIIBackground;