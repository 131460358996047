import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

// import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

// import {
  
// } from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Get organization keys
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestGetOrganizationKeys = packet => {
  return {
    type: 'REQUEST_GET_ORGANIZATION_KEYS',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveGetOrganizationKeysSuccess = data => ({
  type: 'RECEIVE_GET_ORGANIZATION_KEYS_SUCCESS',
  data
})

export const receiveGetOrganizationKeysFail = data => ({
  type: 'RECEIVE_GET_ORGANIZATION_KEYS_FAIL',
  data
})

export const tryToGetOrganizationKeys = packet => (dispatch, getState) => {

  dispatch(requestGetOrganizationKeys(packet));

  let headers = {
    "Content-Type": "application/json"
  }

  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }

  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/organizations/keys/' + packet.id, {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveGetOrganizationKeysSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveGetOrganizationKeysFail({errors:e, lastSubmit: packet}));
    })
}
