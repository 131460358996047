import React from 'react';
import { useSelector } from 'react-redux';

import Hydrate from 'components/Hydrate/Hydrate';

const FlowCursor = ({ cursor }) => {

  // get the display name of this user based on their ID
  let user = useSelector(state => state.userReducer.cache[cursor.user_id]);
  if(user){
    cursor.display_name = user.display_name;
  }
  if(!cursor.display_name){
    cursor.display_name = '';
  }

  // figure out the pixel length of the display name and adjust the cursor accordingly
  let length = cursor.display_name.length * 8 + 14;

  return <g transform={`translate(${cursor.x}, ${cursor.y})`} className="custom-cursor">
    <polygon points="0,0 4,19 15,5"  />
    {
      cursor.display_name !== '' && <g>
         <rect x="15" y="5" width={length} height="22" fill="#ddd" rx={4} ry={4}/>
         <text x={length / 2 + 21} y="20" className="cursor-text" fill="#000" textAnchor="middle">{cursor.display_name}</text>
       </g>
    }

    {/* foreign object for the avatar */}
    <foreignObject x="3" y="3" width="25" height="25">
      <Hydrate mode="avatar" type="user" id={cursor.user_id} size={22}/>
    </foreignObject>
  </g>
}

export default FlowCursor;