import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'

import CustomButton from 'kit/components/CustomButton/CustomButton.js'

import MegaMarkdown from 'components/MegaMarkdown/MegaMarkdown';

import './ComponentMadlib.scss';
import CodeHighlighter from 'components/CodeHighlighter/CodeHighlighter';


class ComponentMadlib extends Component {
  constructor(props){
    super(props);

    this.state = {
      showBackOfHouse: false
    }
  }

  componentWillReceiveProps(newprops){
    if(newprops.processResponse){
      if(!this.state.lastPacketReceived) {
        this.setState({
          lastPacketReceived: newprops.processResponse,
          streamingPartialContent: undefined
        })
      } else if(newprops.processResponse.verbose && this.state.lastPacketReceived.verbose){
        if(newprops.processResponse.verbose.timestamp !== this.state.lastPacketReceived.verbose.timestamp){
          this.setState({
            lastPacketReceived: newprops.processResponse,
            streamingPartialContent: undefined
          }) 
        }
      }
    }

    // we need to monitor demoResponseProgress to see if we need to update the lastPacketReceived
    if(newprops.demoResponseProgress && newprops.demoResponseProgress.length > 0){
      // if the most recent message contains partial_content, we should put this component into a loading state and display the partial_content
      if(newprops.demoResponseProgress[newprops.demoResponseProgress.length - 1].partial_content){
        this.setState({
          lastPacketReceived: undefined,
          streamingPartialContent: newprops.demoResponseProgress.filter(m => m.partial_content).map(m => m.partial_content).join('')
        })
      }

      if(newprops.demoResponseProgress[newprops.demoResponseProgress.length - 1].event){
        this.setState({
          lastPacketReceived: undefined,
          streamingPartialContent: newprops.demoResponseProgress.filter(m => m.event).reverse()[0].event
        })
      }
    }
  }


  render(){

    const { content } = this.props;

    function isJSON(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }

    let useJSON;

    let lastPacketReceived = this.state.lastPacketReceived;
    
    if(lastPacketReceived && !lastPacketReceived.output_data){
      lastPacketReceived = undefined;
    }

    if(lastPacketReceived){
      if(lastPacketReceived.output_data){
        useJSON = isJSON(lastPacketReceived.output_data.content);
        if(useJSON){
          let data = JSON.parse(lastPacketReceived.output_data.content);
          
          if(typeof data !== 'object' && !Array.isArray(data)){
            useJSON = false;
          }
        }
      }
    }

    return <div className="component-madlib">
      <div className="component-madlib-inner">
        
        <div className="component-madlib-content">
         
          <div className="flex-column-stretch flex-grow component-madlib-results-parent">
            <CustomButton
              display={<span><i className="fas fa-project-diagram icon-before-text"/>Execute Flow</span>}
              color="primary"
              block={true}
              thinking={this.props.processing}
              onClick={()=>{
                this.props.processData({
                  data: {
                    variables: this.props.variables
                  }
                })
              }}
              />
            <div className="flex-split margin-bottom-05rem">
              <div className="list-left">
                
              </div>
              <div className="list-right">
              {
                  lastPacketReceived ? <small className="text-hover-primary clickable"
                    onClick={e => {
                      if(this.props.onInspectBackOfHouse){
                        this.props.onInspectBackOfHouse(lastPacketReceived);
                      }
                    }}>
                    <i className="far fa-brackets-curly"/> Inspect API Response
                  </small>
                  :
                  this.state.streamingPartialContent ?
                  <small className="text-muted">
                    <i className="fas fa-spinner-third fa-spin"/> Generating...
                  </small>
                  :
                  <small className="text-muted">
                    Waiting to generate...
                  </small>
                }
              </div>
            </div>
            <div className={"component-madlib-result flex-grow box " + (lastPacketReceived || this.state.streamingPartialContent ? 'box-light-border' : 'box-placeholder')}>
              
              {
                lastPacketReceived ? (
                  useJSON ?
                  <CodeHighlighter
                    code={lastPacketReceived.output_data.content} 
                    collapsedJSONDepth={2}
                    />
                  :
                  <div className="">
                    <MegaMarkdown text={lastPacketReceived.output_data.content} />
                  </div>
                )
                :
                this.state.streamingPartialContent ?
                <div className="">
                  <MegaMarkdown text={this.state.streamingPartialContent} />
                </div>
                :
                <div className="text-muted">
                  <span>Click the button above to generate a response.</span>
                </div>
              }
            </div>
          </div>
        </div>
      </div>      
    </div>
  }
}


const mapStateToProps = (state) => {
  const {  } = state;

  return {
    
  }
}

export default connect(mapStateToProps)(ComponentMadlib);
