export * from './shared/a.shared.GET.paginated';
export * from './shared/a.shared.GET.search';



export const addToSharedCache = (path, item) => ({
  type: 'ADD_TO_SHARED_CACHE',
  data: {
    path: path,
    item: item
  }
})

export const updateCursorCache = (data) => ({
  type: 'UPDATE_CURSOR_CACHE',
  data: data
})


export const updateRateLimit = (data) => ({
  type: 'UPDATE_RATE_LIMIT',
  data: data
})