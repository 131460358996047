import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {
  tryToGetOrganizationMembers,
  tryToGetOrganizationMembersLog
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Remove a member from an organization
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestRemoveOrganizationMember = packet => {
  return {
    type: 'REQUEST_REMOVE_ORGANIZATION_MEMBER',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveRemoveOrganizationMemberSuccess = data => ({
  type: 'RECEIVE_REMOVE_ORGANIZATION_MEMBER_SUCCESS',
  data
})

export const receiveRemoveOrganizationMemberFail = data => ({
  type: 'RECEIVE_REMOVE_ORGANIZATION_MEMBER_FAIL',
  data
})

export const tryToRemoveOrganizationMember = packet => (dispatch, getState) => {

  dispatch(requestRemoveOrganizationMember(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/organizations/members/' + packet.id, {
      method: 'delete',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveRemoveOrganizationMemberSuccess(json));
      dispatch(tryToGetOrganizationMembers({id: packet.id}));
      dispatch(tryToGetOrganizationMembersLog({id: packet.id}));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveRemoveOrganizationMemberFail({errors:e, lastSubmit: packet}));
    })
}
