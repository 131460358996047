import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

export const requestRevokeAPIKey = packet => {
  return {
    type: 'REQUEST_REVOKE_API_KEY',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveRevokeAPIKeySuccess = data => ({
  type: 'RECEIVE_REVOKE_API_KEY_SUCCESS',
  data
})

export const receiveRevokeAPIKeyFail = data => ({
  type: 'RECEIVE_REVOKE_API_KEY_FAIL',
  data
})

export const tryToRevokeAPIKey = packet => (dispatch, getState) => {

  dispatch(requestRevokeAPIKey(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/projects/api-key/' + packet.id + '/' + packet.key_id, {
      method: 'delete',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveRevokeAPIKeySuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveRevokeAPIKeyFail({errors:e, lastSubmit: packet}));
    })
}
