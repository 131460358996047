const convertTreeToGraph = (rootArray) => {
    let nodes = [];
    let links = [];
  
    const generateHash = (name, description) => {
      // Simple hash function
      let hash = 0;
      const string = name + description;
      for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash |= 0; // Convert to 32bit integer
      }
      return 'node-' + hash.toString();
    };
  
    const traverse = (obj, parentId = null, level = 0) => {
      const nodeId = generateHash(obj.name, obj.description);
  
      nodes.push({
        id: nodeId,
        name: obj.name,
        description: obj.description,
        priority: obj.priority,
        level: level
      });
  
      if (parentId !== null) {
        links.push({ source: parentId, target: nodeId });
      }
  
      if (obj.details && Array.isArray(obj.details)) {
        obj.details.forEach(detail => traverse(detail, nodeId, level + 1));
      }
    };
  
    rootArray.forEach(rootObj => traverse(rootObj));
    
    return { nodes, links };
  };
  
  export default convertTreeToGraph;
  