import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import {
  tryToPublishComponent,
  tryToRestoreComponentContent,
  showTooltip,
  hideTooltip
} from 'actions/actions.export'

import Hydrate from 'components/Hydrate/Hydrate.js'
import ComponentVersionBar from 'components/ComponentVersionBar/ComponentVersionBar.js'

import ComponentContentThumbnail from 'components/ComponentContentThumbnail/ComponentContentThumbnail.js'

import CustomButton from 'kit/components/CustomButton/CustomButton.js'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js'

import tools from 'configs/config.component-tools'

import compareComponentVersions from 'utilities/compareComponentVersions'

import './ComponentReview.scss';


class ComponentReview extends Component {
  constructor(props){
    super(props);


    this.state = {
      filters: []
    }
  }

  componentWillMount(){

    const { dispatch, orgReducer, componentReducer, userReducer, canWrite } = this.props;

    const component = componentReducer.cache[this.props.id];
    if(!component || !orgReducer.membershipsReady || !component.versions) return <span>component failed to load.</span>;
    
    let currentVersion = component.versions.find(v => v.id === component.current_version);
    let draftVersion = component.versions.find(v => v.id === 'draft');

    if(component.current_version === 'draft') currentVersion = {};

    let comparison = compareComponentVersions(currentVersion, draftVersion);

    
    let filters = [];
    if(comparison.counts.add) filters.push('add');
    if(comparison.counts.edit) filters.push('edit');
    if(comparison.counts.remove) filters.push('remove');

    this.setState({
      comparison: comparison,
      filters: filters
    })

  }

  componentDidMount(){

  }

  render(){

    const { dispatch, orgReducer, componentReducer, userReducer, canWrite } = this.props;

    const component = componentReducer.cache[this.props.id];
    if(!component || !orgReducer.membershipsReady || !component.versions) return <span>component failed to load.</span>;
    

    let membership = orgReducer.memberships.find(m => m.organization.id === component.scope);
    // lets just grab the actual role data within the membership object
    if(membership) membership = membership.membership

    let currentVersion = component.versions.find(v => v.id === component.current_version);
    let draftVersion = component.versions.find(v => v.id === 'draft');

    let comparison = compareComponentVersions(currentVersion, draftVersion);
    
    return <div className="component-review">
      {
        (currentVersion && draftVersion) ?
        <div className="row">
          <div className="col-md-12 margin-top-05rem margin-bottom-2rem">
            <div className="flex-split">
              <div>
                <h4 className="no-margin">Review Updates</h4>
                <small>
                  Check changes to this agent prior to publishing a new version to any projects.
                </small>
              </div>
              <CustomButton
                display="Publish New Version"
                color="success"
                thinking={componentReducer.tryingToPublishComponent}
                success={componentReducer.publishComponentSuccess}
                fail={componentReducer.publishComponentFail}
                onClick={()=>{
                  dispatch(tryToPublishComponent({
                    id: component.id
                  }))
                }}
                />
            </div>
          </div>
          <div className="col-md-6">
            <CustomSelect
              value={this.state.filters}
              placeholder="Filter updates"
              inline={true}
              multiSelect={true}
              options={
                [
                  {
                    label: "Added",
                    value: "add"   
                  },
                  {
                    label: "Edited",
                    value: "edit"   
                  },
                  {
                    label: "Removed",
                    value: "remove"   
                  },
                  {
                    label: "No Change",
                    value: "none"   
                  }
                ]
              }
              onChange={(v)=>{
                this.setState({
                  filters: v
                })
              }}
              />
          </div>
          <div className="col-md-12">
            <div className="component-review-bar" onMouseLeave={()=>{ dispatch(hideTooltip()) }}>
              {
                comparison.counts.none > 0 &&
                <div 
                  className="component-review-bar-section component-review-bar-section-none" 
                  style={{width: 100*(comparison.counts.none / comparison.changes.length) + "%"}}
                  onMouseEnter={(e)=>{
                    dispatch(showTooltip({
                      el: e.currentTarget,
                      nobr: false,
                      position: 'top',
                      content: <div>
                        {comparison.counts.none} item{comparison.counts.none !== 1 ? "s" : ""} unchanged <small className="text-muted">({parseInt(100*(comparison.counts.none/comparison.changes.length))}%)</small>
                      </div>
                    }))
                  }}
                  />
              }
              {
                comparison.counts.add > 0 &&
                <div 
                  className="component-review-bar-section component-review-bar-section-add" 
                  style={{width: 100*(comparison.counts.add / comparison.changes.length) + "%"}}
                  onMouseEnter={(e)=>{
                    dispatch(showTooltip({
                      el: e.currentTarget,
                      nobr: false,
                      position: 'top',
                      content: <div>
                        {comparison.counts.add} item{comparison.counts.add !== 1 ? "s" : ""} added <small className="text-muted">({parseInt(100*(comparison.counts.add/comparison.changes.length))}%)</small>
                      </div>
                    }))
                  }}
                  />
              }
              {
                comparison.counts.edit > 0 &&
                <div 
                  className="component-review-bar-section component-review-bar-section-edit" 
                  style={{width: 100*(comparison.counts.edit / comparison.changes.length) + "%"}}
                  onMouseEnter={(e)=>{
                    dispatch(showTooltip({
                      el: e.currentTarget,
                      nobr: false,
                      position: 'top',
                      content: <div>
                        {comparison.counts.edit} item{comparison.counts.edit !== 1 ? "s" : ""} edited <small className="text-muted">({parseInt(100*(comparison.counts.edit/comparison.changes.length))}%)</small>
                      </div>
                    }))
                  }}
                  />
              }
              {
                comparison.counts.remove > 0 &&
                <div 
                  className="component-review-bar-section component-review-bar-section-remove" 
                  style={{width: 100*(comparison.counts.remove / comparison.changes.length) + "%"}}
                  onMouseEnter={(e)=>{
                    dispatch(showTooltip({
                      el: e.currentTarget,
                      nobr: false,
                      position: 'top',
                      content: <div>
                        {comparison.counts.remove} item{comparison.counts.remove !== 1 ? "s" : ""} removed <small className="text-muted">({parseInt(100*(comparison.counts.remove/comparison.changes.length))}%)</small>
                      </div>
                    }))
                  }}
                  />
              }
            </div>
            <div className="component-review-counter list-left">
              <span className="text-muted">{comparison.counts.none} unchanged</span>
              <span className="text-success">{comparison.counts.add} added</span>
              <span className="text-warning">{comparison.counts.edit} edited</span>
              <span className="text-danger">{comparison.counts.remove} removed</span>
            </div>
          </div>
          <div className="col-md-12 margin-top-2rem">
            <table className="component-review-table">
              <thead>
                <tr>
                  <th width="48%">
                    {
                      component.current_version !== "draft" ? 
                      <span className="text-400">Current Version (v{component.current_version})</span>
                      :
                      <span className="text-400">Current Version</span>
                    }
                  </th>
                  <th width="4%"><i className="fal fa-long-arrow-right"/></th>
                  <th width="48%">
                    Draft Changes
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  comparison.changes.map((d, i) => {

                    if(this.state.filters.length > 0){
                      if(this.state.filters.indexOf(d.kind) === -1) return;
                    }

                    if(d.category){
                      return <tr className="component-review-category-row" key={i}>
                        <td>{d.category}</td>
                        <td className="component-review-category-row-spacer"/>
                        <td>{d.category}</td>
                      </tr>
                    }

                    return <tr key={i}>
                      <td className="component-review-item">
                        {
                          d.old &&                          
                          <div className="flex-split flex-split-align-end">
                            <div className="component-review-item-key">{d.field ? d.field : ""}</div>
                             {
                                d.kind !== "none" &&
                                <div className="list-right margin-top-2rem">
                                  <div>
                                    &nbsp;
                                  </div>
                                </div>
                              }
                          </div>
                        }
                        {
                          d.old && 
                          <div className="component-review-item-value">
                            { 
                              d.type ? 
                              <ComponentContentThumbnail type={d.type} content={d.old} showDetails={d.kind === 'edit'} offerDetails={true}/> 
                              :
                              <div className="padding-1rem">{d.old}</div>
                            }
                          </div>
                        }
                      </td>



                      
                      <td className="component-review-arrow-wrapper">
                        { d.old && <i className="fas fa-arrow-right text-very-muted"/> }
                      </td>


                      {
                        true &&
                        <td className="component-review-item">
                          <div className="flex-split flex-split-align-end">
                            <div className="component-review-item-key">{d.field ? d.field : ""}</div>
                            {
                              d.kind !== "none" &&
                              <div className="list-right margin-top-2rem">
                                <div>
                                  {d.kind === 'add' && <small className="text-success"><i className="fal fa-plus"/> added</small>}
                                  {d.kind === 'remove' && <small className="text-danger"><i className="fal fa-times"/> removed</small>}
                                  {d.kind === 'edit' && <small className="text-warning"><i className="fal fa-exclamation-triangle"/> edited</small>}
                                  {
                                    (d.new && d.kind !== 'none') && <small className=""> by <Hydrate id={d.new.last_updated_by} type="user"/> {moment(d.new.last_updated).fromNow()}</small>
                                  }
                                </div>
                                { (d.new && d.kind !== 'none' && d.kind !== 'remove') && <span className="text-very-muted">|</span> }
                                { (d.new && d.kind !== 'none' && d.kind !== 'remove') && <small><Link to={d.customInspectLink ? d.customInspectLink.replace(':id', component.id).replace(':scope', component.scope) : ("/workbench/" + component.scope + "/agents/" + component.id + "/" + d.type + "/" + d.new.id)} className="">Inspect</Link></small> }
                              </div>
                            }
                          </div>

                          {
                            d.kind === 'remove' ?
                            <div className={"flex-column-center component-review-item-value component-review-item-value-change-" + d.kind}>
                              <span className="text-danger padding-2rem">This content has been removed from the saved draft.</span>
                            </div>
                            :
                            <div className={"component-review-item-value aacomponent-review-item-value-change-" + d.kind}>
                              { d.type ? 
                                <ComponentContentThumbnail 
                                  type={d.type} 
                                  content={d.new} 
                                  showDetails={d.kind === 'edit'} 
                                  offerDetails={true}
                                  style={d.kind === "add" ? "success" : (d.kind === "remove" ? "danger" : (d.kind === "edit" ? "warning" : ""))}
                                  /> 
                                :
                                <div className="padding-1rem">{d.new}</div> 
                              }
                            </div>
                          }
                          {
                            (d.kind === 'remove' && currentVersion.id !== 'draft' && d.new) &&
                            <div className="list-right margin-top-1rem margin-bottom-1rem">
                              <CustomButton
                                display={<span>Restore from v{currentVersion.id}</span>}
                                size="small"
                                color="danger"
                                thinking={componentReducer.tryingToRestoreComponentContent[d.new.id]}
                                success={componentReducer.restoreComponentContentSuccess[d.new.id]}
                                fail={componentReducer.restoreComponentContentFail[d.new.id]}
                                disabled={!canWrite}
                                onClick={()=>{
                                  dispatch(tryToRestoreComponentContent({
                                    component_id: component.id,
                                    content_id: d.new.id,
                                    type: d.type,
                                  }))
                                }}
                                />
                            </div>
                          }
                          {
                            (d.kind === 'edit' && currentVersion.id !== 'draft') &&
                            <div className="list-right margin-top-1rem margin-bottom-1rem">
                              <CustomButton
                                display={<span>Revert change to v{currentVersion.id}</span>}
                                size="small"
                                color="warning"
                                thinking={componentReducer.tryingToRestoreComponentContent[d.new.id]}
                                success={componentReducer.restoreComponentContentSuccess[d.new.id]}
                                fail={componentReducer.restoreComponentContentFail[d.new.id]}
                                disabled={!canWrite}
                                onClick={()=>{
                                  dispatch(tryToRestoreComponentContent({
                                    component_id: component.id,
                                    content_id: d.new.id,
                                    type: d.type,
                                  }))
                                }}
                                />
                            </div>
                          }
                        </td>
                      }
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        :
        <div className="row">
          <div className="col-md-12">
            <span>Failed to load changes.</span>
          </div>
        </div>
      }
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, componentReducer, orgReducer } = state;

  return {
    userReducer, 
    componentReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(ComponentReview);

  