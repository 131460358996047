
import ProjectHome from '../tools/project/ProjectHome/ProjectHome.js'

//Admin
import ProjectSettings from '../tools/project/ProjectSettings/ProjectSettings.js'
// import ProjectVisibility from '../tools/project/ProjectVisibility/ProjectVisibility.js'
import Team from '../tools/shared/Team/Team.js'

// Components
// import ProjectInstallComponent from '../tools/project/Components/ProjectInstallComponent/ProjectInstallComponent.js'
// import ProjectManageComponents from '../tools/project/Components/ProjectManageComponents/ProjectManageComponents.js'

// API
import ProjectAPISetup from '../tools/project/ProjectAPISetup/ProjectAPISetup.js'
import ProjectScheduledRunsManager from '../tools/project/ProjectScheduledRunsManager/ProjectScheduledRunsManager.js'
import ProjectAPIKeys from '../tools/project/ProjectAPIKeys/ProjectAPIKeys.js'
import ProjectAPIDocumentation from '../tools/project/ProjectAPIDocumentation/ProjectAPIDocumentation.js'
import ProjectAPILogs from '../tools/project/ProjectAPILogs/ProjectAPILogs.js'
import ProjectComponents from 'tools/project/ProjectComponents/ProjectComponents.js'
import ProjectKnowledge from 'tools/project/ProjectKnowledge/ProjectKnowledge.js'

const tool_config = [
  { 
    name: undefined,
    display_name: "Project Home",
    display_icon: "home",
    category: "overview",
    roleless: true,
    description: "The home dashboard for this project.",
    component: (id, canWrite) => <ProjectHome id={id} canWrite={canWrite}/>,
    keywords: "home dashboard"
  },
  {
    name: 'feedback',
    display_name: 'Outline Generator',
    category: '1. define (coming soon)',
    display_icon: 'user-check',
    disabled: true
  },
  {
    name: 'feedback',
    display_name: 'Informed Feedback',
    category: '1. define (coming soon)',
    display_icon: 'user-check',
    disabled: true
  },
  // {
  //   name: 'foundations',
  //   display_name: 'Project Foundations',
  //   category: '1. define (coming soon)',
  //   display_icon: 'cubes',
  // },
  // {
  //   name: 'context',
  //   display_name: 'Context Mapping',
  //   category: '1. define (coming soon)',
  //   display_icon: 'chart-network',
  // },
  {
    name: 'starting-points',
    display_name: 'Starting Points',
    category: '1. define (coming soon)',
    display_icon: 'rocket-launch',
    disabled: true
  },
  {
    name: 'agents',
    display_name: 'Agent Flows',
    category: '2. design',
    display_icon: 'project-diagram',
    description: "Create and design the AI that makes up your project.",
    component: (id, canWrite, item, pages) => <ProjectComponents id={id} canWrite={canWrite} item={item} pages={pages}/>,
    tab_nav_theme: 'components',
    noScroll: true,
    getCounter: (project) => {
      return (project.components || []).length;
    },
  },
  {
    name: 'knowledge',
    display_name: 'Knowledge',
    category: '2. design',
    description: "Create and manage the knowledge bases for your project.",
    display_icon: 'book-spells',
    component: (id, canWrite, item, pages) => <ProjectKnowledge id={id} canWrite={canWrite} item={item} pages={pages}/>,
    tab_nav_theme: 'knowledge',
    noScroll: true,
    getCounter: (project) => {
      return (project.knowledge || []).length;
    },
  },
  {
    name: 'prototype',
    display_name: 'Prototype (soon)',
    category: '2. design',
    display_icon: 'hand-pointer',
    disabled: true
  },
  { 
    name: "api",
    display_name: "API Setup",
    display_icon: "code",
    category: "3. develop",
    fullWidth: true,
    description: "View API docs and manage the versions used via API.",
    component: (id, canWrite, item) => <ProjectAPISetup id={id} canWrite={canWrite} item={item}/>,
    keywords: "install component add agents add edit"
  },
  { 
    name: "api_keys",
    display_name: "Secret Keys",
    display_icon: "key",
    category: "3. develop",
    description: "View and manage the API keys for this project.",
    component: (id, canWrite, item) => <ProjectAPIKeys id={id} canWrite={canWrite} item={item}/>,
    keywords: "api keys key post get put call"
  },
  { 
    name: "api_logs",
    display_name: "Reporting & Logs",
    display_icon: "chart-bar",
    category: "3. develop",
    fullWidth: true,
    description: "View logs of API usage for this project.",
    component: (id, canWrite) => <ProjectAPILogs id={id} canWrite={canWrite}/>,
    keywords: "api logs user_id session_id sessions users post get put call log use usage"
  },
  // { 
  //   name: "scheduler",
  //   display_name: "Scheduler",
  //   display_icon: "calendar-alt",
  //   category: "3. develop",
  //   fullWidth: true,
  //   description: "Schedule flows to run at specific times.",
  //   component: (id, canWrite, item) => <ProjectScheduledRunsManager id={id} canWrite={canWrite} item={item}/>,
  //   keywords: "schedule time run flow flows"
  // },
  { 
    name: "team",
    display_name: "Team",
    display_icon: "users",
    category: "overview",
    description: "Invite others to collaborate on this project.",
    component: (id, canWrite, item) => <Team id={id} kind={'projects'} canWrite={canWrite} item={item}/>,
    keywords: "users user members member roles privilege editor share team"
  },
  { 
    name: "settings",
    display_name: "Settings",
    display_icon: "cog",
    category: "overview",
    description: "Change the name, description, and other metadata about this project.",
    component: (id, canWrite) => <ProjectSettings id={id} canWrite={canWrite}/>,
    keywords: "name description meta info information metadata naming title thumbnail tags"
  },
  {
    name: "integrations",
    display_name: "Integrations (soon)",
    display_icon: "browser",
    category: "3. develop",
    disabled: true
  }

]


export default tool_config