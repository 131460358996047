import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {
  tryToGetOrganizationInvites,
  tryToGetOrganizationInvitesLog
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Delete an organization completely
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestDeleteOrganization = packet => {
  return {
    type: 'REQUEST_DELETE_ORGANIZATION',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveDeleteOrganizationSuccess = data => ({
  type: 'RECEIVE_DELETE_ORGANIZATION_SUCCESS',
  data
})

export const receiveDeleteOrganizationFail = data => ({
  type: 'RECEIVE_DELETE_ORGANIZATION_FAIL',
  data
})

export const tryToDeleteOrganization = packet => (dispatch, getState) => {

  dispatch(requestDeleteOrganization(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/organizations/' + packet.id, {
      method: 'delete',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveDeleteOrganizationSuccess(json));
      
      // hard load to /workbench and force a refresh
      dispatch(push('/workbench'));
      window.location.reload();
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveDeleteOrganizationFail({errors:e, lastSubmit: packet}));
    })
}
