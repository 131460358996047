import { push } from 'react-router-redux';

import {
  updateCursorCache
} from '../actions/actions.export'

const handleUserObject = (dispatch, getState) => {
  return (data) => {

    if(data.user_id === getState().userReducer.myData.id){
      return;
    }
    
    dispatch(updateCursorCache(data));
  }
}

export default handleUserObject;