import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import Checkbox from '../../kit/components/Checkbox/Checkbox.js'
import Modal from '../../kit/components/Modal/Modal.js'

import Hydrate from '../../components/Hydrate/Hydrate.js'
import MemberSearchSelect from '../../components/MemberSearchSelect/MemberSearchSelect.js'


import {
  tryToGetOrganizationMembers,
  tryToEditOrganizationMemberPrivilege,
  tryToRemoveOrganizationMember,
  showTooltip,
  hideTooltip
} from '../../actions/actions.export'


const privilege_list = [
  {
    name: "create_components",
    display_name: "Create Flows",
    display_icon: "project-diagram",
    description: <span>
      Create flows within this organization, edit them, manage access to them, and delete the projects they create.<br/>
    </span>
  },
  {
    name: "create_projects",
    display_name: "Create Projects",
    display_icon: "globe",
    description: "Create projects within this organization, manage access to them, and delete the ones they create."
  },
  {
    name: "create_knowledge",
    display_name: "Create Knowledge Bases",
    display_icon: "books",
    description: "Create new Knowledge Bases within this organization, manage access to them, and delete the sets they create."
  },
  {
    name: "manage_content",
    display_name: "Manage All Content",
    display_icon: "user-crown",
    description: "View, manage access to, and delete anything in the organization."
  },
  {
    name: "manage_info",
    display_name: "Manage Public Profile",
    display_icon: "address-card",
    description: "Customize the public facing info about this organization, including its name, avatar, bio, and more."
  },
  {
    name: "manage_members",
    display_name: "Add, Edit, and Remove Members",
    display_icon: "user-plus",
    description: "Invite new members, edit admin privileges for existing members, remove existing memebrs and view the history of invites and admin privilege changes."
  },
  {
    name: "manage_billing",
    display_name: "Manage Billing",
    display_icon: "credit-card",
    description: "View and manage the billing information and API usage for this organization."
  }
]

class OrganizationInvitesList extends Component {
  constructor(props){
    super(props);

    this.state = {
      email: ""
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }



  handleSubmit(){
    const { dispatch } = this.props;
  }

  componentDidMount(){
    const { dispatch, orgReducer } = this.props;

    const organization = orgReducer.cache[this.props.id];
    if(!organization.invites){
      dispatch(tryToGetOrganizationMembers({id: this.props.id}));
    }

    const urlParams = new URLSearchParams(window.location.search);
    let member_id = urlParams.get('member');
    if(member_id){
      this.setState({
        editingMemberID: member_id
      })
    }
  }

  componentWillReceiveProps(newprops){
    if(new Date().getTime() - newprops.orgReducer.removeOrganizationMemberSuccess < 500 && this.state.removeMemberModalOpen){
      this.setState({
        removeMemberModalOpen: false,
        removingMemberIndex: undefined
      })
    }
  }

  render(){

    const { dispatch, orgReducer, userReducer } = this.props;

    let canSubmit = this.state.email && !this.state.email_error;

    let canManageMembers = false;
    for(var i in orgReducer.memberships){
      if(orgReducer.memberships[i].organization.id === this.props.id){
        if(orgReducer.memberships[i].membership.manage_members){
          canManageMembers = true;
        }
      }
    }

    if(!canManageMembers) return <span/>;


    const organization = orgReducer.cache[this.props.id];

    let editingMember;
    if(this.state.editingMemberID && organization.members){
      editingMember = organization.members.find(m => m.id === this.state.editingMemberID);
    }



    const renderRow = (member, i) => {

      let privilege_count = privilege_list.filter(p => member[p.name]).length;


      return <tr key={i}>
        <td>
          <Hydrate
            mode="lockup"
            type="user"
            field="display_name"
            id={member.user_id}
            tooltip={true}
            size={20}
            />
        </td>
        <td>
          {
            moment(member.joined_on).format('MMM D, YYYY')
          }
        </td>
        <td>
          {privilege_count} of {privilege_list.length} Privileges
        </td>
        <td className="text-center">
          {
           (member.user_id === organization.owner && userReducer.myData.id !== member.user_id) ?
           <span 
             className="text-tag"
             onMouseEnter={(e)=>{
               dispatch(showTooltip({
                 el: e.target,
                 nobr: false,
                 position: 'left',
                 content: <div style={{width: 250}} className="text-center">
                   You cannot remove privileges from the organization's current owner.
                 </div>
               }))
             }}
             onMouseLeave={(e)=>{
               dispatch(hideTooltip());
             }}
             >
             Organization Owner
           </span>
           :
           (member.user_id !== organization.owner && member.user_id === userReducer.myData.id) ?
            <CustomButton 
             display="Leave Organization"
             size="xs"
             color="grey"
             to="/workbench/my-account/settings/memberships"
             />
           :
           <CustomButton 
             disabled={(orgReducer.tryingToRemoveOrganizationMember !== member.id && orgReducer.tryingToRemoveOrganizationMember)}
             display="Edit Membership"
             to={"/workbench/" + organization.id + "/settings/members?member=" + member.id} 
             size="xs"
             color="grey"
             onClick={()=>{
               this.setState({
                  editingMemberID: member.id
               })
             }}
             />
         }
        </td>
      </tr>
    }


    return <div className={"box"}>      
      <div className="flex-split margin-bottom-2rem">
        <div className="list-left">
          <h4 className="no-margin-top no-margin-bottom">
            Member Access & Admin Privileges
          </h4>
          {
            organization.members !== undefined && 
            (organization.members.length === organization.member_count ?
            <small className="text-semi-muted">
              Showing all {organization.member_count} current members
            </small>
            :
            <small className="text-semi-muted">
              Showing {organization.members.length} of {organization.member_count} current members
            </small>
            )
          }
        </div>
        <div className="list-right">
          <small className="text-semi-muted">
            Changes saved automatically.
          </small>
          {
            (orgReducer.tryingToGetOrganizationMembers || orgReducer.tryingToEditOrganizationMemberPrivilege) ?
            <i className="far fa-spinner-third fa-spin text-muted fa-fw"/>
            :
             <i className="far fa-sync-alt clickable icon-muted-until-hover fa-fw" onClick={()=>{ 
              dispatch(tryToGetOrganizationMembers({id: this.props.id}))
            }}/>
          }
        </div>
      </div>
      

      {
        editingMember ? 
        <div>
          <div className="list-left margin-bottom-2rem">

            <CustomButton
              display={<span><i className="far fa-angle-left icon-before-text"/>Back to all members</span>}
               to={"/workbench/" + organization.id + "/settings/members"} 
               size="small"
               onClick={()=>{
                 this.setState({
                    editingMemberID: false
                 })
               }}
              />
          </div>
          <div className="flex-split margin-bottom-1rem">
            <h4 className="no-margin">
              <Hydrate id={editingMember.user_id} type="user" mode="lockup" tooltip={true} link={true} size={50}/>
            </h4>
            <CustomButton 
              disabled={(orgReducer.tryingToRemoveOrganizationMember !== editingMember.id && orgReducer.tryingToRemoveOrganizationMember)}
              display="Remove Member" 
              color="danger"
              size="small"
              onClick={()=>{
                this.setState({
                  removeMemberModalOpen: true
                })
              }}
              />
          </div>
          <table className="table-basic">
            <thead>
              <tr>
                <th/>
                <th>
                  Privilege Name
                </th>
                <th width="100" className="text-center">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {
                privilege_list.map((p, i)=>{
                  return <tr key={i}>
                    <td>
                      <i className={"fal fa-fw fa-lg text-muted fa-" + p.display_icon}/>
                    </td>
                    <td>
                      <div className="text-700">
                        {p.display_name}
                      </div>
                      <small className="text-semi-muted">{p.description}</small>
                    </td>
                    <td className="text-center">
                      <Checkbox 
                        value={editingMember[p.name]} 
                        disabled={(editingMember.user_id === organization.owner && editingMember.user_id !== userReducer.myData.id) || orgReducer.tryingToEditOrganizationMemberPrivilege}
                        onToggle={(v)=>{
                          dispatch(tryToEditOrganizationMemberPrivilege({
                            id: organization.id,
                            member_id: editingMember.id,
                            privilege_name: p.name,
                            privilege_value: v
                          }))
                        }}
                        />
                    </td>
                  </tr>
                })
              }
            </tbody>
          </table>


          {
            (this.state.removeMemberModalOpen) &&
            <Modal
              maxWidth={500}
              show={this.state.removeMemberModalOpen}
              content={<div>
                  <h3 className="no-margin">Remove Member</h3>
                  <p>Are you sure you want to remove <Hydrate type="user" link={true} tooltip={true} id={editingMember.user_id} field="display_name"/>?</p>
                  <p>They will need to be reinvited in order to rejoin.</p>
                  <div className="modal-inner-button-row">
                    <CustomButton
                      display="Cancel"
                      disabled={orgReducer.tryingToRemoveOrganizationMember}
                      onClick={()=>{ 
                        this.setState({ removeMemberModalOpen: false }) 
                      }}
                      />
                    <CustomButton
                      display={"Remove Member"}
                      color="danger"
                      thinking={orgReducer.tryingToRemoveOrganizationMember}
                      success={orgReducer.removeOrganizationMemberSuccess}
                      fail={orgReducer.removeOrganizationMemberFail}
                      onClick={()=>{
                        dispatch(tryToRemoveOrganizationMember({
                          id: organization.id,
                          member_id: editingMember.id
                        }))
                      }}
                      />
                  </div>
                </div>}
              exitable={true}
              onExit={()=>{ 
                this.setState({ removeMemberModalOpen: false }) 
              }}
              />
            }

        </div>
        :
        <div>
        <div className="list-left">
          <MemberSearchSelect 
            id={this.props.id}
            manager={true}
            onChange={(member)=>{
              this.setState({
                searchedMember: member
              })
            }}
            onClear={()=>{
              this.setState({
                searchedMember: undefined
              })
            }}
            />
        </div>
        {
          (organization.members !== undefined) ? 
            <div>
            {
              organization.members.length === 0 ? 
              <p>You haven't invited anyone to join yet.</p>
              :
              <table className="table-basic">
                <thead>
                  
                  <tr>
                    <th>Name</th>
                    <th>Joined On</th>
                    <th>Privileges</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.searchedMember ? 
                    renderRow(this.state.searchedMember)
                   :
                    organization.members.map((member, i) => {
                      return renderRow(member, i);
                    })
                  }
                </tbody>
              </table>
            }
            {
              !organization.membersEmpty && 
              <div className="flex-column-center margin-top-2rem">
                <CustomButton
                  display="Load More"
                  thinking={orgReducer.tryingToGetOrganizationMembers}
                  success={orgReducer.getOrganizationMembersSuccess}
                  fail={orgReducer.getOrganizationMembersFail}
                  onClick={()=>{
                    dispatch(tryToGetOrganizationMembers({
                      id: organization.id,
                      startAfter: organization.members[organization.members.length - 1].id
                    }))
                  }}
                  />
              </div>
            }
          </div>
          :
          <p>You haven't invited anyone to join yet.</p>
        }
        </div>
      }
    </div>


  }
}


const mapStateToProps = (state) => {
  const { orgReducer, userReducer } = state;

  return {
    orgReducer,
    userReducer
  }
}

export default connect(mapStateToProps)(OrganizationInvitesList);
