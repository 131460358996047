import React, { Component } from 'react'
import { connect } from 'react-redux'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'

import CustomField from '../kit/components/CustomField/CustomField.js'
import CustomButton from '../kit/components/CustomButton/CustomButton.js'
import Callout from '../kit/components/Callout/Callout.js'

import {
  tryToCreateNewOrganization 
} from '../actions/actions.export'


import isFeatureSwitchOn from '../utilities/isFeatureSwitchOn'

class NewOrganization extends Component {
  constructor(props){
    super(props);

    this.state = {
      display_name: "",
      bio: ""
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToCreateNewOrganization({
      display_name: this.state.display_name,
      bio: this.state.bio,
      type: this.state.type
    }))
  }


  componentDidMount(){
    window.document.title = "zerowidth.ai";
  }

  render(){
    const { guiReducer, userReducer, orgReducer, featuresReducer } = this.props;

    let hfStretchedClassList = "hf-stretched";

    let featureSwitchEnabled = isFeatureSwitchOn('create_organization', userReducer, featuresReducer);
    
    let canSubmit = this.state.display_name && !this.state.display_name_error && !this.state.bio_error && featureSwitchEnabled;
                  
    return <div className={hfStretchedClassList}>
      <Header blackBackground={true}/>
      <div className="body">
        <div className="container margin-top-2rem">
          <div className="row justify-content-center margin-bottom-3rem">
            <div className="col-md-12 text-center">
              <h1 className="text-center">New Organization</h1>
              <p className="lead">
                Whether a solo designer or a large enterprise, we have a plan for you.
              </p>
            </div>
          </div>
          {
            this.state.type ? 
            <div className="row justify-content-center margin-bottom-3rem">
              <div className="col-lg-4 col-md-6">
                <form onSubmit={()=>{
                  if(canSubmit) this.handleSubmit()
                }}>

                  <CustomField
                    name="field_new_org_name"
                    label="Name"
                    maxLength={64}
                    placeholder="New Co."
                    description="This can be changed later."
                    required={true}
                    disabled={orgReducer.tryingToCreateNewOrganization || !featureSwitchEnabled}
                    value={this.state.display_name}
                    serverError={orgReducer.newOrganizationForm.errors.display_name}
                    lastSubmit={orgReducer.newOrganizationForm.lastSubmit.display_name}
                    onChange={(e) => {
                      this.setState({
                        display_name: e.value,
                        display_name_error: e.error
                      })
                    }}
                    />


                  <div className="spacer-2rem"/>

                  {
                    orgReducer.newOrganizationForm.errors.error &&
                    <Callout
                      style="danger"
                      dismissable={true}
                      content={orgReducer.newOrganizationForm.errors.error}
                    />
                  }

                  {
                    !featureSwitchEnabled && 
                    <Callout
                      title="You can't create organizations yet."
                      style="danger"
                      content="This feature is not yet available for your account. Please check back later or contact a ZeroWidth admin."
                      />
                  }
                  
                  <div className="text-center">
                    <CustomButton
                      display="Create Organization"
                      color="success"
                      block={true}
                      thinking={orgReducer.tryingToCreateNewOrganization}
                      fail={orgReducer.newOrganizationFail}
                      success={orgReducer.newOrganizationSuccess}
                      disabled={!canSubmit}
                      onClick={()=>{
                        if(canSubmit) this.handleSubmit()
                      }}
                      />
                  </div>
                </form>
              </div>
            </div>
            :
            <div className="row justify-content-center row-eq-height">
              <div className="col-lg-3 col-md-6 margin-bottom-2rem flex-column-stretch">
                <div className="box box-light-border flex-grow">
                  <h4 className="no-margin">Personal</h4>
                  <p>For your personal projects and experiments.</p>
                  <h2 className="text-400 ">
                    Free
                  </h2>
                  <ul className="ul-iconed">
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Private workspace just for you</span>
                    </li>
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Up to 5 Agents</span>
                    </li>
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Up to 5 Knowledge Bases</span>
                    </li>
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Up to 5 API Endpoints</span>
                    </li>
                    {/* <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Runs models using your own API keys</span>
                    </li> */}
                  </ul>
                  <div className="spacer-3rem"/>
                  <CustomButton
                    display="Get Started"
                    color="success"
                    block={true}
                    onClick={e => this.setState({type: 'personal'})}
                    />
                </div>
              </div>


              <div className="col-lg-3 col-md-6 margin-bottom-2rem flex-column-stretch">
                <div className="box box-light-border flex-grow">
                  <h4 className="no-margin">Business</h4>
                  <p>For collaborating across your team, billed per user.</p>
                  <h2 className="text-400 ">
                    $45/mo
                  </h2>
                  <ul className="ul-iconed">
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Collaborate across your organization</span>
                    </li>
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Unlimited Agents</span>
                    </li>
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Unlimited Knowledge Bases</span>
                    </li>
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Unlimited API Endpoints</span>
                    </li>
                    {/* <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Runs models using your own API keys</span>
                    </li> */}
                  </ul>
                  <div className="spacer-3rem"/>
                  <CustomButton
                    display="Coming Soon"
                    color="grey"
                    block={true}
                    disabled={true}
                    />
                </div>
              </div>


              <div className="col-lg-3 col-md-6 margin-bottom-2rem flex-column-stretch">
                <div className="box box-light-border flex-grow">
                  <h4 className="no-margin">Education</h4>
                  <p>For students and educators looking to learn and teach.</p>
                  <h2 className="text-400 ">
                    Free
                  </h2>
                  <ul className="ul-iconed">
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Up to 100 members</span>
                    </li>
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Increased limits across ZeroWidth</span>
                    </li>
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Opportunity for free API credits</span>
                    </li>
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Limited to verified .edu emails</span>
                    </li>
                  </ul>
                  <div className="spacer-3rem"/>
                  <CustomButton
                    display="Contact Us"
                    color="black"
                    block={true}
                    to="/contact"
                    />
                </div>
              </div>

              <div className="col-lg-3 col-md-6 margin-bottom-2rem flex-column-stretch">
                <div className="box box-light-border flex-grow">
                  <h4 className="no-margin">Enterprise</h4>
                  <p>For large organizations with complex needs looking for strategic guidance.</p>
                  <h2 className="text-400 ">
                    Custom
                  </h2>
                  <ul className="ul-iconed">
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Everything from the Business level</span>
                    </li>
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Hands-on support and training</span>
                    </li>
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>API credits for rapid testing & setup</span>
                    </li>
                    <li className="margin-bottom-1rem">
                      <i className="fal fa-check icon-before-text"></i>
                      <span>Prototyping and strategic guidance</span>
                    </li>
                  </ul>
                  <div className="spacer-3rem"/>
                  <CustomButton
                    display="Contact Us"
                    color="black"
                    block={true}
                    to="/contact"
                    />
                </div>
              </div>

            </div>
            }
        </div>
      </div>
      <Footer simple={true}/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, guiReducer, orgReducer, featuresReducer } = state;

  return {
    userReducer,
    guiReducer,
    orgReducer, 
    featuresReducer
  }
}

export default connect(mapStateToProps)(NewOrganization);

  