import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import Hydrate from 'components/Hydrate/Hydrate'
import CustomField from 'kit/components/CustomField/CustomField'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import Callout from 'kit/components/Callout/Callout.js'
import Modal from 'kit/components/Modal/Modal.js'
import Checkbox from 'kit/components/Checkbox/Checkbox.js'
import CustomTable from 'kit/components/CustomTable/CustomTable.js'


import {
  
} from 'actions/actions.export'

import './ProjectAPIDocumentation.scss';

class ProjectAPIDocumentation extends Component {
  constructor(props){
    super(props);


    this.state = {

    }
  }

  render(){

    const { dispatch, projectReducer, userReducer, orgReducer } = this.props;

    const project = projectReducer.cache[this.props.id];
    if(!orgReducer.membershipsReady) return <span/>;
    if(!project) return <span>Failed to load project.</span>;

    return <div className="project-api-documentation">
      <div className="row">
        <div className="col-xl-12">
          <h3 className="no-margin-top margin-bottom-1rem">API Documentation</h3>
          <p>
            To use the API of any installed component in this project, send a <code>POST</code> request using the following format:
          </p>
          <pre>https://api.zerowidth.ai/process/<span className="text-900 text-primary">{project.id}</span>/<span className="text-900 text-secondary">COMPONENT_ID_HERE</span></pre>
          <p>
            The <code className="text-900 text-primary">{project.id}</code> value is the project's unique ID.
          </p>
          <hr/>
          <h4><i className="far fa-long-arrow-up icon-before-text text-success"/>POST Request</h4>
          <h5 className="no-margin-bottom">Required Request Body Elements</h5>
          <p>
            <span className="text-monospace text-900">user_id</span><br/>
            A string parameter for tracking requests made by the same end user of a particular application.
          </p>
          <p>
            <span className="text-monospace text-900">session_id</span><br/>
            A string parameter for tracking requests within the same session of an end application, solving for where one user might be leveraging multiple instances of an application.
          </p>
          <p>
            <span className="text-monospace text-900">data</span><br/>
            An object containing the actual data for each request, uniquely formatted based on which component is being called.
          </p>
          <div className="spacer-1rem"/>
          <h5 className="no-margin-bottom margin-top-3rem">Optional Request Parameters</h5>
          <p>
            <span className="text-monospace text-900">verbose=true</span><br/>
            Requests with this appended at the end of the URL will receive an additional <span className="text-monospace text-900">verbose</span> field in the response, described below.
          </p>

          <hr/>
          <h4><i className="far fa-long-arrow-down icon-before-text text-blue"/>POST Response</h4>
          <p>
            For tracking purposes, <span className="text-monospace text-900">project_id</span>, <span className="text-monospace text-900">component_id</span>, <span className="text-monospace text-900">user_id</span>, <span className="text-monospace text-900">session_id</span>, and <span className="text-monospace text-900">input_data</span> from the original request are included in the response.
          </p>
          <p>
            <span className="text-monospace text-900">output_data</span><br/>
            An object containing the response information for each request, uniquely formatted based on which component is being called.
          </p>
          <p>
            <span className="text-monospace text-900">processing_duration</span><br/>
            How long it took to process the information, a subset of the total response time focused just on the AI element, measured in milliseconds.
          </p>
          <p>
            <span className="text-monospace text-900">verbose</span><br/>
            An optional detailed object including additional information that is unique to the component type used. This may contain specifics about what vector memory was pulled into reference, the system instruction messges used, and other details about the request.
          </p>
        </div>
      </div>

    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, projectReducer, orgReducer } = state;

  return {
    userReducer, 
    projectReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(ProjectAPIDocumentation);

  