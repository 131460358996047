import React, { Component } from 'react'
import shortid from 'shortid'
import { Link } from 'react-router-dom'

import './Dropdown.scss';

class Dropdown extends Component {

  constructor(props){
    super(props);

    this.state = {
      id: shortid.generate(),
      // lastOpen: u,
      open: false
    }

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillReceiveProps(newprops){
    if(newprops.manualShow !== this.state.lastShow){
      this.setState({
        lastShow: newprops.manualShow,
        open: true
      })
    }

    if(newprops.manualHide !== this.state.lastHide && !this.state.hoveringMenu){
      this.setState({
        lastHide: newprops.manualHide,
        open: false
      })
    }
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside);
  }

  handleClickOutside(event) {
    
    if(this.wrapperRef) {
      if(this.wrapperRef.current){
        if(!this.wrapperRef.current.contains(event.target)){
          // purposeful race conditions are ok right?
          setTimeout(()=>{
            this.setState({
              open: false
            })  
          })
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState){
    // this.state.open, figure out if the shown menu expands below the viewport
    if(this.state.open){
      let menu = document.getElementById("dropdown-menu-" + this.state.id);
      if(menu){
        let rect = menu.getBoundingClientRect();
        if(rect.bottom > window.innerHeight){
          // move it up just enough to fit
          let newTop = window.innerHeight - rect.bottom - 10;
          menu.style.top = newTop + "px";
        }
      }
    }
  }

  render(){

    let items = this.props.items.flat();

    items = items.filter(i => i !== undefined && i !== null && i !== "");

    let classList = "dropdown-menu";
    if(this.props.align) classList += " dropdown-menu-align-" + this.props.align;
    if(this.props.position) classList += " dropdown-menu-position-" + this.props.position;

    return <div className={"dropdown " + (this.props.disabled ? "dropdown-disabled" : "")}>
      <div 
        className={(this.props.disabled ? "" : "dropdown-toggle")}
        id={this.props.id}
        onClick={()=> { 
          if(!this.props.disabled){
            if(!this.props.hover) this.setState({open: !this.state.open})
          }
        }}
        onMouseEnter={()=> { 
          if(!this.props.disabled){
            if(this.props.hover) this.setState({open: true})
          }
        }}
        onMouseLeave={()=> { 
          if(this.props.hover) this.setState({open: false})
        }}
        >
        {
          this.props.target ? 
          this.props.target
          :
          <i className="fas fa-angle-down dropdown-toggle dropdown-toggle-default"/>
        }
      </div>
      {
        (this.state.open && items.length > 0 && !this.props.disabled) &&
        <div 
          className={classList}
          id={"dropdown-menu-" + this.state.id} 
          ref={this.wrapperRef}
          style={{
            minWidth: this.props.minWidth,
            left: this.props.offsetX,
            top: this.props.offsetY,
            maxHeight: this.props.maxHeight,
          }}
          onMouseLeave={()=>{
            if(this.props.hover) this.setState({open: false, hoveringMenu: false})
          }}
          onMouseEnter={()=>{
            if(this.props.hover) this.setState({open: true, hoveringMenu: true})
          }}
          >
          {
            items.map((item, i)=>{
              let content = item;
              if(item.content){
                content = item.content;
              }
              let disabled = false;
              if(item.disabled){
                disabled = true;
              }

              if(item === "divider"){
                return <div key={i} className="dropdown-menu-item-divider"/>
              } else {
                return <div key={i} className={"dropdown-menu-item " + (i < items.length - 1 && items[parseInt(i) + 1] === "divider" ? "dropdown-menu-item-before-divider" : "") + (disabled ? " dropdown-menu-item-disabled" : "")} onClick={()=>{this.setState({open: false})}}>
                  {content}
                </div>
              }
            })
          }
        </div>
      }
    </div>
  }
}

export default Dropdown
  