import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomPinCodeField from '../../kit/components/CustomPinCodeField/CustomPinCodeField.js'
import Callout from '../../kit/components/Callout/Callout.js'



import {
  tryToResendEmailVerificationCode,
  tryToVerifyEmail
} from '../../actions/actions.export'

class VerifyEmailForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      code: ""
    }

  }


  render(){

    const { dispatch, userReducer } = this.props;

    return <div className="flex-column-center">
        
        {
          this.props.needSend && 
          <CustomButton
            display="Send Code"
            thinking={userReducer.tryingToResendEmailVerificationCode}
            success={userReducer.resendEmailVerificationCodeSuccess}
            fail={userReducer.resendEmailVerificationCodeFail}
            onClick={()=>{
              dispatch(tryToResendEmailVerificationCode())
            }}
            />
        }

        {
          this.props.needSend && 
          <p className="text-center">
            And then enter it here:
          </p>
        }
        
        <CustomPinCodeField
          value={this.state.code}
          onChange={(e) => {
            this.setState({
              code: e.value
            })
          }}
          onComplete={(e) => {
            dispatch(tryToVerifyEmail({
              code: e
            }))
          }}
          inline={true}
          thinking={userReducer.tryingToVerifyEmail}
          success={userReducer.verifyEmailSuccess}
          fail={userReducer.verifyEmailFail}
          />

        <div className="spacer-2rem"/>

        {
          userReducer.verifyEmailForm.errors.error &&
          <Callout
            style="danger"
            dismissable={true}
            content={userReducer.verifyEmailForm.errors.error}
          />
        }
        
        {
          !this.props.needSend && 
          <CustomButton
            display="Resend Code"
            thinking={userReducer.tryingToResendEmailVerificationCode}
            success={userReducer.resendEmailVerificationCodeSuccess}
            fail={userReducer.resendEmailVerificationCodeFail}
            onClick={()=>{
              dispatch(tryToResendEmailVerificationCode())
            }}
            />
        }

        <div className="spacer-2rem"/>
        {
          !this.props.hideEmailChangeLink &&
          <p>
            <Link to="/settings/email">Choose a different email address.</Link>
          </p>
        }
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(VerifyEmailForm);