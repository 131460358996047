import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import Callout from '../../kit/components/Callout/Callout.js'


import {
  tryToSendPasswordResetLink
} from '../../actions/actions.export'

class ForgotForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      email: ""
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToSendPasswordResetLink({
      email: this.state.email
    }))
  }


  render(){

    const { userReducer } = this.props;

    let canSubmit = this.state.email && !this.state.email_error;

    return <div>
      <form onSubmit={()=>{
        if(canSubmit) this.handleSubmit()
      }}>

        <CustomField
          name="field_email"
          regex="email"
          label="Email"
          placeholder="email"
          required={true}
          thinking={false}
          disabled={userReducer.tryingToSendPasswordResetLink}
          value={this.state.email}
          serverError={userReducer.forgotForm.errors.email}
          lastSubmit={userReducer.forgotForm.lastSubmit.email}
          description="Enter the email address associated with the account."
          onChange={(e) => {
            this.setState({
              email: e.value,
              email_error: e.error
            })
          }}
          />

        <div className="spacer-2rem"/>

        {
          userReducer.forgotForm.errors.error &&
          <Callout
            style="danger"
            dismissable={true}
            content={userReducer.forgotForm.errors.error}
          />
        }
        
        <div className="list-right">
          <CustomButton
            display="Get Reset Link"
            color="success"
            size="small"
            thinking={userReducer.tryingToSendPasswordResetLink}
            fail={userReducer.sendPasswordResetLinkFail}
            success={userReducer.sendPasswordResetLinkSuccess}
            disabled={!canSubmit}
            onClick={()=>{
              if(canSubmit) this.handleSubmit()
            }}
            />
        </div>

        <div className="spacer-3rem"/>

        {
          (userReducer.sendPasswordResetLinkSuccess && !userReducer.tryingToSendPasswordResetLink) && 
          <Callout
            style="success"
            content={<span>If the account exists, a link to reset its password will arrive in its email inbox shortly.</span>}
          />
        }
      </form>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(ForgotForm);
