import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomField from 'kit/components/CustomField/CustomField'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import MegaMarkdown from 'components/MegaMarkdown/MegaMarkdown';

export const NodeSettings_request = ({ node, setNodeSettings, canWrite }) => {

  if (!node.settings) {
    return null;
  }

  const [settings, setSettings] = useState(node.settings || {});

  return (
    <div>
      <CustomField
        label="Raw JSON"
        value={settings.raw}
        rows={10}
        canWrite={canWrite}
        placeholder="Enter packet here..."
        onChange={(e) => {
          setSettings({
            ...settings,
            raw: e.value,
          })
        }}
        onBlur={(e) => {
          setNodeSettings(settings)
        }}
        />
    </div>
  );
}


export const NodeRenderer_request = ({ node, type }) => {

  return (
    <div className="">
    </div>
  );
}