import { push } from 'react-router-redux';

import {
  socketCatchUserData,
  tryToLogout
} from '../actions/actions.export'

const handleUserObject = (dispatch, getState) => {
  return (data) => {
    dispatch(socketCatchUserData(data));

    if(data.suspended || data.maintenance){
      return dispatch(tryToLogout())
    }


    if(data.bumped && window.location.pathname !== "/interrupt"){
      return dispatch(push('/interrupt')) 
    }

    if(data.logout_everywhere_before){
      if(getState().userReducer.logged_in_at < data.logout_everywhere_before){
        return dispatch(tryToLogout());
      }
    }
  }
}

export default handleUserObject;