import React from 'react';
import BigNumber from 'bignumber.js';

const AbbreviateNumber = ({ value, locale = navigator.language }) => {
  const parseLocaleNumber = (stringValue) => {
    const exampleNumber = 123456.79;
    const localizedExampleString = new Intl.NumberFormat(locale).format(exampleNumber);

    const nonNumericCharacters = localizedExampleString.replace(/[0-9]/g, '');
    const decimalSeparator = nonNumericCharacters.slice(-1);

    const regex = new RegExp(`[^0-9${decimalSeparator}]`, 'g');
    const normalizedNumberString = stringValue.replace(regex, '').replace(decimalSeparator, '.');

    return normalizedNumberString;
  };

  const abbreviate = (num) => {
    const sanitizedNum = parseLocaleNumber(num);

    if (isNaN(sanitizedNum) || sanitizedNum === '') {
      return 'Invalid number';
    }

    let number = new BigNumber(sanitizedNum);
    const suffixes = ["", "K", "M", "B", "T", "P", "E"];

    let suffixNum = 0;
    while (number.abs().gte(1000) && suffixNum < suffixes.length - 1) {
      number = number.div(1000);
      suffixNum++;
    }

    // Format the number without decimals if it's an integer
    const formattedNumber = number.isInteger() ? number.toFixed(0) : number.toFixed(1);

    return `${formattedNumber}${suffixes[suffixNum]}`;
  };

  return <span>{abbreviate(value)}</span>;
};

export default AbbreviateNumber;
