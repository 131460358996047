import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment';

import Hydrate from '../../components/Hydrate/Hydrate'

import {
  tryToLaunchPrototype
} from '../../actions/actions.export'
import CustomButton from 'kit/components/CustomButton/CustomButton';

class WorkbenchAllProjects extends Component {
  constructor(props){
    super(props);

    this.state = {
    }

  }

  
  render(){

    const { dispatch, prototypeReducer, orgReducer, userReducer, postReducer, intelReducer } = this.props;

    let prototypeCacheList = Object.keys(prototypeReducer.cache).map(id => prototypeReducer.cache[id]);
    
    let prototypes = [];
    if(this.props.org_id){
      prototypes = prototypeCacheList.filter(p => p.scope === this.props.org_id);
    }

    return <div className="margin-bottom-3rem">
      <h5 className="no-margin-top margin-bottom-1rem text-uppercase text-primary">
        <i className="fal fa-fw fa-browser icon-before-text"/>
        Prototypes
        {
          prototypeReducer.tryingToGetPrototypesByOrganization && 
          <small className=""><i className="far fa-spinner-third fa-spin text-smaller icon-after-text"/></small>
        }
      </h5>

      <div className="row row-eq-height">
        {
          prototypes.map((prototype, i)=>{
            if(this.props.limit && i >= this.props.limit) return null;

            return <div className="col-md-4 margin-bottom-2rem link-no-decoration text-400 flex-column-stretch" key={i}>
              <div className="box flex-grow box-half-pad">
                <div className="flex-split">
                  <div className="margin-right-1rem">
                    <h5 className="no-margin text-900 text-ellipsis-1-lines">{prototype.display_name}</h5>
                    <small className="text-muted">
                      {moment(prototype.last_updated || prototype.created_at).fromNow()}
                    </small>
                  </div>
                </div>
                <p className="no-margin-bottom margin-top-05rem">
                  <small>
                    {prototype.description}
                  </small>
                </p>
                <div className="list-right">
                  <CustomButton
                    onClick={e => {
                      dispatch(tryToLaunchPrototype({
                        id: prototype.id
                      }))
                    }}
                    color="black"
                    size="xs"
                    thinking={prototypeReducer.tryingToLaunchPrototype === prototype.id}
                    fail={prototypeReducer.launchPrototypeFail}
                    success={prototypeReducer.launchPrototypeSuccess}
                    display="Launch"
                    />
                </div>
              </div>
            </div>
          })
        }
      </div>
    
    </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, orgReducer, prototypeReducer, guiReducer, postReducer, intelReducer  } = state;

  return {
    userReducer,
    orgReducer,
    prototypeReducer,
    guiReducer, 
    postReducer, 
    intelReducer
  }
}

export default connect(mapStateToProps)(WorkbenchAllProjects);
