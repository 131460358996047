
const kindRemapDictionary = {
  'projects': {
    singular: 'Project',
    plural: 'Projects',
    url: 'projects',
    search: 'projects',
    icon: 'robot',
    ogSingular: 'project',
  },
  'components': {
    singular: 'Agent',
    plural: 'Agents',
    url: 'agents',
    search: 'agents',
    icon: 'project-diagram',
    ogSingular: 'component',
  },
  'knowledge': {
    singular: 'Knowledge Base',
    plural: 'Knowledge Bases',
    url: 'knowledge',
    search: 'knowledge',
    icon: 'book-spells',
    ogSingular: 'knowledge',
  },
  'inquiries': {
    singular: 'Inquiry',
    plural: 'Inquiries',
    url: 'inquiries',
    search: 'Inquiries',
    icon: 'lightbulb-on',
    ogSingular: 'inquiry',
  }
}

module.exports = kindRemapDictionary;