import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux';
import moment from 'moment'
import nanoid from 'nanoid'

import {
  encode,
  decode
} from 'gpt-tokenizer'

import CustomTable from 'kit/components/CustomTable/CustomTable'
import CustomField from 'kit/components/CustomField/CustomField'
import Checkbox from 'kit/components/Checkbox/Checkbox'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import Callout from 'kit/components/Callout/Callout.js'
import Modal from 'kit/components/Modal/Modal.js'
import Hydrate from 'components/Hydrate/Hydrate';

import {
  tryToGetPaginated,
  tryToCreateInformation,
  tryToUpdateInformation,
  tryToGetInformation,
  tryToDeleteInformation,
  tryToSearchInformation
} from 'actions/actions.export'

import './KnowledgeInfo.scss';

class KnowledgeInfo extends Component {
  constructor(props){
    super(props);

    this.state = {
      searchText: "",
      lastDelete: 0,
      info_custom_search: false,
      showTip: false,
      searchText: "",
      info_display_name: "",
      info_content: "",
      info_start_overlap: undefined,
      info_end_overlap: undefined,
      info_embedded_content: "",
      info_source_type: "manual",
      info_source_name: "",
      info_source_url: "",
      info_source_page: "",
      id: "",
      view: "table",
    }
  }
  

  componentWillMount(){
    const { dispatch, knowledgeReducer, sharedReducer, item } = this.props;

    const knowledge = knowledgeReducer.cache[this.props.id];
    if(knowledge){
      dispatch(tryToGetPaginated({
        kind: 'knowledge',
        id: this.props.id,
        collection: 'info',
        orderBy: 'created_at'
      }))
    }

    if(item){
      dispatch(tryToGetInformation({
        knowledge_id: this.props.id,
        information_id: item
      }))
    }
  }

  componentWillReceiveProps(newprops){
    const { dispatch, item, sharedReducer, knowledgeReducer } = newprops;

    if(knowledgeReducer.deleteInformationSuccess && knowledgeReducer.deleteInformationSuccess !== this.state.lastDelete){
      var knowledge = knowledgeReducer.cache[newprops.id];

      this.setState({
        lastDelete: knowledgeReducer.deleteInformationSuccess,
        deleteInfoModalOpen: false
      })
      dispatch(push('/workbench/' + knowledge.scope + '/knowledge/' + knowledge.id + '/info'))
    }

    if(item && item !== 'new' && knowledgeReducer.infoCache[item] && (!this.state.id || item !== this.state.id)){

      let infoObject = knowledgeReducer.infoCache[item];
      
      if(infoObject){
        this.setState({
          loaded: true,
          id: item,
          info_display_name: infoObject.display_name,
          info_content: infoObject.content,
          info_start_overlap: infoObject.start_overlap,
          info_end_overlap: infoObject.end_overlap,
          info_embedded_content: infoObject.embedded_content,
          info_source_type: infoObject.source_type,
          info_source_name: infoObject.source_name,
          info_source_url: infoObject.source_url,
          info_source_page: infoObject.source_page
        })
      }
    }

    if(item && item !== 'new' && !knowledgeReducer.infoCache[item] && !knowledgeReducer.tryingToGetInformation){
      dispatch(tryToGetInformation({
        knowledge_id: newprops.id,
        information_id: item
      }))
    }

    if(!item){
      this.setState({
        loaded: false,
        info_display_name: "",
        info_content: "",
        info_start_overlap: undefined,
        info_end_overlap: undefined,
        info_embedded_content: "",
        info_source_type: "",
        info_source_name: "",
        info_source_url: "",
        info_source_page: ""
      })
    }

    // lets check query parameters for a search and determine if we should search
    if(window.location.search){
      const searchParams = new URLSearchParams(window.location.search);
      const searchText = searchParams.get('search');
      if(searchText && searchText !== this.state.searchText){
        this.setState({
          searchText: searchText,
          lastSearchText: searchText
        })
        dispatch(tryToSearchInformation({
          knowledge_id: newprops.id,
          searchText: searchText
        }))
      }
    }
  }

  render(){
    const { dispatch, knowledgeReducer, userReducer, orgReducer, sharedReducer, item, canWrite } = this.props;

    const knowledge = knowledgeReducer.cache[this.props.id];

    if(!knowledge) return <span>Failed to load.</span>;

    const sharedCachePath = 'knowledge/' + knowledge.id + '/info';
    let infoList = sharedReducer.cache[sharedCachePath] ? sharedReducer.cache[sharedCachePath].data : [];
    let infoListEmpty = sharedReducer.cache[sharedCachePath] ? sharedReducer.cache[sharedCachePath].empty : false;

    if(this.state.lastSearchText){

      const searchResults = knowledgeReducer.searchResults[this.props.id] || {data: []};
      infoList = searchResults.data;
      infoListEmpty = searchResults.empty;
    }

    let backLink = "/workbench/" + knowledge.scope + "/knowledge/" + knowledge.id;

    // are we currently nested, does the URL have "projects" in it? 
    if(this.props.router.location.pathname.indexOf('projects') > -1){
      
      // lets just split by /knowledge/ and trim back 
      const pathParts = this.props.router.location.pathname.split('/knowledge/');
      backLink = pathParts[0] + '/knowledge/' + knowledge.id;
    }



    if(item){
      return <div className="">
        <Modal
          show={this.state.deleteInfoModalOpen}
          exitable={true}
          onExit={e => this.setState({deleteInfoModalOpen: false})}
          cancelable={true}
          onCancel={e => this.setState({deleteInfoModalOpen: false})}
          acceptable={true}
          acceptButtonThinking={knowledgeReducer.tryingToDeleteInformation}
          acceptButtonFail={knowledgeReducer.deleteInformationFail}
          acceptButtonSuccess={knowledgeReducer.deleteInformationSuccess}
          acceptButtonLabel="Delete"
          acceptButtonColor="danger"
          onAccept={e => {
            dispatch(tryToDeleteInformation({
              knowledge_id: knowledge.id,
              id: item
            }))
          }}
          content={<div>
            <p>Are you sure you want to delete this chunk?</p>
            <p className="text-900">This cannot be undone.</p>
          </div>}
          />


        <div className="flex-split margin-bottom-2rem margin-top-05rem">
          <div className="list-left">
            <CustomButton
              size="small"
              color="grey"
              display={<span><i className="far fa-angle-left icon-before-text"/>Back</span>}
              to={backLink}
              />
            {
              knowledgeReducer.tryingToGetInformation ?
              <span>Loading...</span>
              :
              <span>Make changes to this piece of information:</span>
            }
          </div>
          <div className="list-right">
            <CustomButton
              color="knowledge"
              display="Save Changes"
              size="small"
              disabled={!canWrite}
              thinking={knowledgeReducer.tryingToUpdateInformation}
              success={knowledgeReducer.updateInformationSuccess}
              fail={knowledgeReducer.updateInformationFail}
              onClick={e => {
                dispatch(tryToUpdateInformation({
                  knowledge_id: knowledge.id,
                  id: item,
                  display_name: this.state.info_display_name,
                  content: this.state.info_content,
                  start_overlap: this.state.info_start_overlap,
                  end_overlap: this.state.info_end_overlap,
                  embedded_content: this.state.info_embedded_content,
                  source_type: "manual",
                  source_name: this.state.info_source_name,
                  source_url: this.state.info_source_url,
                  source_page: this.state.info_source_page,
                  scope: knowledge.scope
                }))
              }}
              />
          </div>
        </div>

        <div className="box">
          <div className="row">
            {/* <div className="col-md-6">
              <CustomField
                label="Name"
                required={true}
                disabled={!canWrite}
                name="info_display_name"
                placeholder="Fact #1"
                description="Not exposed to the language model."
                value={this.state.info_display_name}
                onChange={e => { this.setState({info_display_name: e.value})}}
                />
            </div>
            <div className="col-md-6">
              <CustomSelect
                label="Type"
                name="info_type"
                placeholder="This is automatically set."
                value={this.state.info_source_type}
                options={[
                  {
                    label: "Manually created", 
                    value: "manual", 
                  },
                  {
                    label: "File", 
                    value: "file", 
                  }
                  ]}
                disabled={true}
                />
            </div> */}
            <div className="col-md-12">
              {
                this.state.info_start_overlap !== undefined &&
                <CustomField
                  label="Start Overlap"
                  name="info_start_overlap"
                  disabled={!canWrite}
                  rows={5}
                  placeholder="ex: The quick brown fox"
                  description="Add text to the beginning of this info."
                  value={this.state.info_start_overlap}
                  onChange={e => { this.setState({info_start_overlap: e.value})}}
                  />
              }
              <CustomField
                label="Content"
                required={true}
                inline={true}
                disabled={!canWrite}
                name="info_content"
                placeholder="Enter the full text of what you'd like to expose to the AI"
                description={<span>{encode(this.state.info_content).length} tokens</span>}
                rows={10}
                value={this.state.info_content}
                onChange={e => { 
                  this.setState({
                    info_content: e.value,
                    info_embedded_content: this.state.info_custom_search ? this.state.info_embedded_content : e.value
                  })
                }}
                />
              {
                this.state.info_end_overlap !== undefined &&
                <CustomField
                  label="End Overlap"
                  name="info_end_overlap"
                  disabled={!canWrite}
                  rows={5}
                  placeholder="ex: jumps over the lazy dog"
                  description="Add text to the end of this info."
                  value={this.state.info_end_overlap}
                  onChange={e => { this.setState({info_end_overlap: e.value})}}
                  />
              }
            </div>

            <div className="col-md-12">
              <Checkbox
                name="info_embedded_content"
                style="align-left"
                disabled={!canWrite}
                description={<span>
                  Customize the indexed version of this content{!this.state.showTip && <span className="clickable text-hover-black" onClick={e => this.setState({showTip: true})}><i className="fal fa-question-circle icon-after-text"/></span>}
                  </span>}
                value={this.state.info_custom_search}
                onToggle={e => { 
                  let state = {info_custom_search: e};
                  if(!e){
                    state.info_embedded_content = this.state.info_content;
                  }
                  this.setState(state)
                }}
                />
              {
                this.state.showTip &&
                <Callout
                  onDismiss={e => this.setState({showTip: false})}
                  dismissable={true}
                  content={<small><span className="text-900">What does customizing the index do?</span><br/>Customizing the indexed version of this content may be useful in situations where redundant text may be present in multiple pieces of information in this Knowledge Base. Creating a simpler or more <i>essentials-only</i> version to be used for searching may yield more accurate search results. Some examples might be: removing repeated headers on multiple pages of a file, page numbers, etc.</small>}
                  />
              }
            </div>
            {
              this.state.info_custom_search &&
              <div className="col-md-12">
                <CustomField
                  label="Content to Search By"
                  name="info_embedded_content"
                  placeholder="Enter the text to be used for semantic search."
                  description="This will be used for indexing this info for semantic search."
                  disabled={!this.state.info_custom_search || !canWrite}
                  rows={6}
                  value={this.state.info_embedded_content}
                  onChange={e => { this.setState({info_embedded_content: e.value})}}
                  />
              </div>
            }
            <hr/>
            <div className="col-md-12">
              <p> 
                To help trace possible hallucinations and enable the AI to site its sources, add the source of this information below.
              </p>
            </div>
            <div className="col-md-6">
              <CustomField
                label="Source"
                name="info_source"
                placeholder="ex: file.pdf"
                disabled={!canWrite}
                description="Where in this source can this info be found?"
                value={this.state.info_source_name}
                onChange={e => { this.setState({info_source_name: e.value})}}
                />
            </div>
            <div className="col-md-6">
              <CustomField
                label="Source Page / Section"
                name="info_source_page"
                disabled={!canWrite}
                placeholder="ex: page 3, FAQ section, etc"
                description="Where in this source can this info be found?"
                value={this.state.info_source_page}
                onChange={e => { this.setState({info_source_page: e.value})}}
                />
            </div>
            <div className="col-md-12">
              <CustomField
                label="Source URL"
                name="info_source_url"
                disabled={!canWrite}
                placeholder="https://example.com/files/originalFileName.pdf"
                description="When siting sources, where should the AI point to?"
                regex="url"
                value={this.state.info_source_url}
                onChange={e => { this.setState({info_source_url: e.value})}}
                />
            </div>
            <div className="col-md-12">
              <div className="list-right margin-top-2rem">
                <CustomButton
                  color="transparent-danger"
                  display="Delete Chunk"
                  disabled={!canWrite}
                  size="small"
                  onClick={e => {
                    this.setState({deleteInfoModalOpen: true})
                  }}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    }


    // grid view
    return <div className="">
      <div className="row">
        {
          this.state.lastSearchText && <div className="col-md-12">
            <div className="flex-split margin-bottom-1rem">
              <div className="list-left list-left-no-wrap">
                <CustomButton
                  display="Clear Search"
                  color="grey"
                  size="small"
                  onClick={e => {
                    this.setState({lastSearchText: ""})
                    // clear the search query if it exists
                    dispatch(push(window.location.pathname))
                  }}
                  />
                  <div className="test-ellipsis-1-lines">
                    {
                      knowledgeReducer.tryingToSearchInformation ?
                      <span>Searching for {this.state.lastSearchText}...</span>
                      :
                      <span>
                        Showing search results for <strong>{this.state.lastSearchText}</strong>
                      </span>
                    }
                  </div>
              </div>
              <div className="text-right">
                <small>
                  Use <strong>Score</strong> to help determine the relevance of search results.<br/>Score is also used for the search threshold within agents you connect it to.
                </small>
              </div>
            </div>
          </div>
        }
        {
          // (infoList.length === 0 && !sharedReducer.tryingToGetPaginated && !knowledgeReducer.tryingToSearchInformation) &&
          // <Callout
          //   title="Creating your first piece of information"
          //   content={<div>
          //     <p>Knowledge Bases are made up of bits of information, dynamically given to the AI based on the current request or topic of conversation. This information can be updated at anytime, giving the AI an up-to-date view into the proprietary and unique Knowledge Base you'd like to arm it with.</p>
          //     <p>Information can be added one piece at a time, or in bulk by uploading PDF or TXT files.</p>
          //     <div className="spacer-2rem"/>
          //     <CustomButton
          //       size="small"
          //       color="knowledge"
          //       display={<span><i className="far fa-plus icon-before-text"/>Add Information</span>}
          //       to={"/workbench/" + knowledge.scope + "/knowledge/" + knowledge.id + "/add"}
          //       />
          //   </div>}
          //   />
        }
        {
          this.state.view === 'grid' && infoList.map((info, i)=>{
            return <div className="col-md-3 flex-column-stretch margin-bottom-1rem" key={i}>
              <Link className="box box-clickable box-half-pad link-no-decoration" to={"/workbench/" + knowledge.scope + "/knowledge/" + knowledge.id + "/info/" + info.id}>
                
                <p className="text-ellipsis-4-lines text-400 no-margin-top text-gs0 thin-line-height">
                  <small>
                    {info.content}
                  </small>
                </p>
                <div className="list-left">
                  <small className="text-400 text-gs5">
                    {info.source_type === "file" && <i className="far fa-file"/>}
                    {info.source_type === "manual" && <i className="far fa-i-cursor"/>}
                  </small>
                  <small className="text-400 text-gs5">
                    {
                      info.last_updated ? 
                      <span>last updated { moment(info.last_updated).fromNow() }</span>
                      :
                      <span>created { moment(info.created_at).fromNow() }</span>
                    }
                  </small>
                  
                </div>
              </Link>
            </div>
          })
        }
        {
          this.state.view === 'table' && <div className="col-md-12">
            {
            (infoList.length === 0 && !sharedReducer.tryingToGetPaginated && !knowledgeReducer.tryingToSearchInformation) ?
              <div className="box">
                <div className="flex-column-center-center">
                  <h5 className="text-muted">You haven't added any information to this Knowledge Base yet.</h5>
                  <small>
                    Click <i>Add Information</i> to get started.
                  </small>
                </div>
              </div>
              :
              <div className="box">
                <CustomTable
                  rawData={infoList}
                  hidePagination={false}
                  pageSize={15}
                  rowLink={row => {
                    return window.location.pathname + "/_/" + row.id;
                    // "/workbench/" + knowledge.scope + "/knowledge/" + knowledge.id + "/_/" + row.id
                  }}
                  headers={[
                    {
                      display: "",
                      name: "source_type",
                      getter: r => {
                        if(r.source_type === "file") return <i className="far fa-file fa-fw text-knowledge"/>;
                        if(r.source_type === "manual") return <i className="far fa-text fa-fw text-knowledge"/>;
                        if(r.source_type === "url") return <i className="far fa-link fa-fw text-knowledge"/>;
                        if(r.source_type === "agent") return <i className="far fa-robot fa-fw text-knowledge"/>;
                      },
                      sortGetter: r => r.source_type
                    },
                    {
                      display: "Content",
                      name: "content",
                      getter: r => <p className="no-margin text-ellipsis-2-lines thin-line-height"><small>{r.content}</small></p>,
                      sortGetter: r => r.content
                    },
                    {
                      display: "Last Updated",
                      name: "last_updated",
                      getter: r => <nobr><span className="text-muted">{r.last_updated ? moment(r.last_updated).fromNow() : moment(r.created_at).fromNow()}</span></nobr>,
                      sortGetter: r => r.last_updated ? r.last_updated : r.created_at
                    },
                    {
                      display: "By",
                      name: "by",
                      getter: r => <Hydrate type="user" mode="avatar" id={r.last_updated_by || r.created_by} tooltip={true}/>,
                      sortGetter: r => <Hydrate type="user" field="display_name" id={r.last_updated_by || r.created_by}/>
                    },
                    this.state.lastSearchText && {
                      display: "Score",
                      width: "110px",
                      name: "score",
                      getter: r => r.score,
                      sortGetter: r => r.score
                    }
                  ]}
                />
              </div>
            }
          </div>
        }
        {
          infoList.length > 0 &&
          <div className="col-md-12 flex-column-center-center">
            <CustomButton
              display="Load More Chunks"
              color="transparent"
              disabled={infoListEmpty}
              size="small"
              onClick={e => {
                dispatch(tryToGetPaginated({
                  kind: 'knowledge',
                  id: this.props.id,
                  collection: 'info',
                  orderBy: 'created_at',
                  startAfter: infoList[infoList.length - 1].id
                }))
              }}
              />
          </div>
        }
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, knowledgeReducer, sharedReducer, orgReducer, router } = state;

  return {
    userReducer, 
    knowledgeReducer,
    sharedReducer,
    orgReducer,
    router
  }
}

export default connect(mapStateToProps)(KnowledgeInfo);

  