import React, { Component } from 'react'
import { connect } from 'react-redux'

import CustomButton from 'kit/components/CustomButton/CustomButton'
import CustomField from 'kit/components/CustomField/CustomField'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import Callout from 'kit/components/Callout/Callout'
import Checkbox from 'kit/components/Checkbox/Checkbox'

import FlowRenderer from 'components/FlowBuilder/FlowRenderer'

import isFeatureSwitchOn from 'utilities/isFeatureSwitchOn'

import {
  showTooltip,
  hideTooltip,
  tryToGetModels,
  tryToCreateNewComponent,
  tryToGetOrganizationKeys
} from 'actions/actions.export'

import flow_templates from 'configs/config.flow-templates'


class NewComponentForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      display_name: "",
      type: "chat",
      flow_mode: true,
      visibility: "org",
      editability: "team",
      model: "gpt-4-turbo",
      flow_template: 'blank',
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount(){
    const { dispatch } = this.props;

    // dispatch(tryToGetModels());
    // dispatch(tryToGetOrganizationKeys({
    //   id: this.props.org_id
    // }));
  }

  handleSubmit(){
    const { dispatch } = this.props;

    let flow_template = flow_templates.find(t => t.name === this.state.flow_template);
    

    dispatch(tryToCreateNewComponent({
      display_name: this.state.display_name,
      scope: this.props.org_id,
      origin_project: this.props.project_id || this.state.project_id || undefined,
      visibility: this.state.visibility,
      editability: this.state.editability,
      model: this.state.model,
      type: this.state.type,
      flow_mode: this.state.flow_mode,
      flow_links: flow_template ? flow_template.flow_links : [],
      flow_nodes: flow_template ? flow_template.flow_nodes : []
    }))
  }

  
  render(){

    const { dispatch, componentReducer, orgReducer, projectReducer, intelReducer } = this.props;

    let membership;
    if(orgReducer.memberships.length > 0){
      membership = orgReducer.memberships.find(m => m.organization.id === this.props.org_id).membership;
    }

    let organization = orgReducer.cache[this.props.org_id];

    if(!organization || !membership) return <span/>;

    let projects = []
    for(let project_id in projectReducer.cache){
      let project = projectReducer.cache[project_id];
      if(project.scope === this.props.org_id){
        projects.push(project);
      }
    }

    let canSubmit = this.state.display_name && !this.state.display_name_error;

    let selectableModels = intelReducer.llms.filter(m => m.showForTypes.includes(this.state.type));

    return <div className="box box-components-left">
        <form onSubmit={()=>{
          if(canSubmit) this.handleSubmit()
        }}>
          <div className="flex-split margin-bottom-2rem">
            <div className="list-left">
              <i className="fas fa-project-diagram text-components fa-2x"/>
              <h4 className="no-margin-top no-margin-bottom">
                New Agent Flow
              </h4>
            </div>
            <div>
              <Checkbox
                value={!this.state.flow_mode}
                description="Use the old legacy agent builder interface."
                onToggle={(v)=>{
                  this.setState({
                    flow_mode: !v
                  })
                }}
                />
            </div>
          </div>

          <hr/>
          
          <div className="row">
            <div className="col-md-12">
              <CustomField
                name="field_new_thing_name"
                label="Name"
                maxLength={64}
                placeholder="My Chatbot, Idea Generator, etc"
                required={true}
                disabled={componentReducer.tryingToCreateNewComponent}
                value={this.state.display_name}
                serverError={componentReducer.newComponentForm.errors.display_name}
                lastSubmit={componentReducer.newComponentForm.lastSubmit.display_name}
                onChange={(e) => {
                  this.setState({
                    display_name: e.value,
                    display_name_error: e.error
                  })
                }}
                />
            </div>
            <div className="col-md-12">
              <CustomSelect
                label="Which project does this belong to?"
                value={this.props.project_id || this.state.project_id}
                disabled={this.props.project_id}
                description="You can add this to projects at anytime."
                onChange={(v)=>{
                  this.setState({
                    project_id: v
                  })
                }}
                options={projects.map((project, i)=>{
                  return {
                    label: project.display_name,
                    value: project.id
                  }
                })}
                />
            </div>
            <div className="col-md-6">
              <CustomSelect
                label="Who should be able to see this?"
                required={true}
                value={this.state.visibility}
                onChange={(v)=>{
                  this.setState({
                    visibility: v
                  })
                }}
                options={[
                  // {
                  //   label: <span><i className="far fa-fw fa-globe icon-before-text text-success"/> <span className="text-900">Anyone on the Internet</span></span>,
                  //   value: "public"
                  // },
                  {
                    label: <span><i className="far fa-fw fa-building icon-before-text"/> <span className="text-900">Anyone in this organization</span></span>,
                    value: "org"
                  },
                  {
                    label: <span><i className="far fa-fw fa-users icon-before-text text-warning"/> <span className="text-900">Only specific people</span></span>,
                    value: "team"
                  },
                ]}
                />
            </div>

            <div className="col-md-6">
              <CustomSelect
                label="Who should be able to edit this?"
                required={true}
                value={this.state.editability}
                onChange={(v)=>{
                  this.setState({
                    editability: v
                  })
                }}
                options={[
                  {
                    label: <span><i className="far fa-fw fa-building icon-before-text"/> <span className="text-900">Anyone in this organization</span></span>,
                    value: "org"
                  },
                  {
                    label: <span><i className="far fa-fw fa-users icon-before-text text-warning"/> <span className="text-900">Only specific people</span></span>,
                    value: "team"
                  }
                ]}
                />
            </div>
          </div>
        <hr/>
        {
          this.state.flow_mode ?
  
          <div>
            <div className="row">
              <div className="col-12">
                <p className="text-900">
                  Choose a template to start with:
                </p>
              </div>
            </div>
            <div>
              <div className="row row-eq-height">
                {
                  flow_templates.map((template, i)=>{

                    return <div className="col-md-4 flex-column-stretch margin-bottom-2rem" key={i}>
                      <div className={"box box-clickable box-medium-shadow box-half-pad flex-column-stretch flex-grow " + (this.state.flow_template === template.name ? 'box-black-border' : 'box-grey-border box-placeholder text-semi-muted box-no-shadow')} onClick={()=>{
                        this.setState({
                          flow_template: template.name
                        })
                      }}>
                        <div className="flex-grow">
                          <h5 className="no-margin">
                            {template.display_name}
                          </h5>
                          <p className="thin-line-height margin-top-05rem">
                            <small>
                              {template.description}
                            </small>
                          </p>
                        </div>
                        <div className="sixteen-by-nine-parent">
                          <div className="sixteen-by-nine-child">
                            {/* <FlowBuilder
                              initialNodes={intelReducer.flow_nodes.length > 0 ? template.flow_nodes : []}
                              initialLinks={intelReducer.flow_nodes.length > 0 ? template.flow_links : []}
                              highlightedNodeIds={[]}
                              nodeTypes={intelReducer.flow_nodes}
                              interactive={false}
                              simpleMode={true}
                              recenterCounter={1}
                              /> */}
                            <FlowRenderer
                              nodes={template.flow_nodes}
                              links={template.flow_links}
                              interactive={false}
                              showMinimap={false}
                              />
                          </div>
                        </div>
                      </div>
                    </div>
                  })
                }
              </div>
            </div>
          </div>
          :
          <div>

            <div className="row">
              <div className="col-12">
                <p className="text-900">
                  Which kind of agent do you want to create?
                </p>
                <p className="p-small margin-bottom-2rem">
                  This setting will determine which kind of interface is generated for you to interact with your agent within ZeroWidth and may limit which foundational models can be chosen to build upon. It can be changed at anytime and the API call format for either is identical.
                </p>
              </div>
              <div className="col-md-6 flex-column-stretch">
                <div className={"box flex-grow no-margin-bottom " + (this.state.type === 'chat' ? 'box-border-black box-clickable ' : ' box-clickable box-placeholder box-muted')} onClick={e => {
                  this.setState({ 
                    type: 'chat'
                  })
                }}>
                  <h5 className="no-margin">
                    <i className="fal fa-comments icon-before-text"/>Conversational
                  </h5>
                  <p>
                    These agents are designed to interact with users in a conversational manner.
                  </p>
                  <p className="thin-line-height no-margin-bottom">
                    <small className="text-semi-muted">
                      Recommended for creating a chatbot or interaction where follow-up messages or requests are expected.
                    </small>
                  </p>
                </div>
              </div>
              <div className="col-md-6 flex-column-stretch">
                <div className={"box flex-grow no-margin-bottom " + (this.state.type === 'madlib' ? 'box-border-black box-clickable ' : ' box-clickable box-placeholder box-muted')} onClick={e => {
                  this.setState({ 
                    type: 'madlib'
                  })
                }}>
                  <h5 className="no-margin">
                    <i className="fal fa-bolt icon-before-text"/>Single Response
                  </h5>
                  <p>
                    These agents generate a single response based on their instructions.
                  </p>
                  <p className="thin-line-height no-margin-bottom">
                    <small className="text-semi-muted">
                      Recommended for data processing tasks, report generation, or driving an element of an interface, product, or service.
                    </small>
                  </p>
                </div>
              </div>

            </div>

            <div className="spacer-2rem"/>

            <CustomSelect
                value={this.state.model}
                label="Which foundational LLM would you like to build upon?"
                placeholder="GPT-4, PaLM2, etc"
                description={<span>ZeroWidth recommends <strong>gpt-4-turbo</strong> for its features, workbench compatibility, and overall performance.</span>}
                large={true}
                required={true}
                serverError={componentReducer.newComponentForm.errors.model}
                lastSubmit={componentReducer.newComponentForm.lastSubmit.model}
                options={selectableModels.map((model, i)=>{
                  return {
                    label: <div className="thin-line-height list-left">
                      {model.provider === 'OpenAI' && <img src="/img/openai_logo_small.png" width="30"/>}
                      {model.provider === 'Google PaLM2' && <img src="/img/palm2_logo_small.png" width="30"/>}
                      {model.provider === 'Google Gemini' && <img src="/img/gemini_logo_small.png" width="30"/>}
                      {model.provider === "Anthropic" && <img src="/img/anthropic_logo_small.png" width="30"/>}
                      {model.provider === "Meta" && <img src="/img/meta_logo_small.png" width="30"/>}
                      <div>
                        <strong>{model.name}</strong><br/>
                        <div className="text-ellipsis-1-lines"><small>by {model.provider}  {model.legacy ? <span className="margin-left-1rem text-tag text-tag-tiny text-tag-danger">LEGACY</span> : model.preview ? <span className="margin-left-1rem text-tag text-tag-tiny text-tag-warning">PREVIEW</span> : ""}</small></div>
                      </div>
                    </div>,
                    value: model.name
                  }
                })}
                onChange={(e) => {
                  this.setState({
                    model: e,
                  })
                }}
                />
            </div>
          }

          <div className="spacer-3rem"/>

          {
            componentReducer.newComponentForm.errors.error &&
            <Callout
              style="danger"
              dismissable={true}
              content={componentReducer.newComponentForm.errors.error}
            />
          }
          <div className="flex-split">
            <div className="list-left">
              <small>
                This flow will automatically be associated with this project, but can also be leveraged by other projects within your organization.
              </small>
            </div>
            <div className="list-right list-right-no-wrap">

              <CustomButton
                display="Cancel"
                color="transparent"
                size="small"
                to={"/workbench/" + this.props.org_id + '/agents'}
                />

              <CustomButton
                display="Create"
                color="black"
                thinking={componentReducer.tryingToCreateNewComponent}
                fail={componentReducer.newComponentFail}
                success={componentReducer.newComponentSuccess}
                disabled={!canSubmit || !membership.create_components}
                onClick={()=>{
                  if(canSubmit) this.handleSubmit()
                }}
                />
            </div>
          </div>
        </form>          
      </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, orgReducer, componentReducer, guiReducer, projectReducer, intelReducer  } = state;

  return {
    userReducer,
    orgReducer,
    componentReducer,
    guiReducer, 
    projectReducer, 
    intelReducer
  }
}

export default connect(mapStateToProps)(NewComponentForm);
