import jsonpath from 'jsonpath';

/**
 * Updates an object based on JSONPath.
 * @param {Object} params - The parameters for the update.
 * @param {Object} params.obj - The original object to be updated.
 * @param {String} params.jsonPath - The JSONPath to the property to be updated.
 * @param {Object} [params.changes] - The changes to apply (for update and add).
 * @param {String} [params.operation='update'] - The type of operation: 'update', 'add', or 'delete'.
 * @returns {Object} - The updated object.
 */
function updateObjectByPath({ obj, path, changes, operation = 'update' }) {

    const deepClone = JSON.parse(JSON.stringify(obj));
    const nodes = jsonpath.nodes(deepClone, path);


    nodes.forEach(node => {
        const parentPath = jsonpath.stringify(node.path.slice(0, -1));
        const parentNode = jsonpath.value(deepClone, parentPath);
        const key = node.path[node.path.length - 1];


        switch (operation) {
            case 'update':
                Object.assign(node.value, changes);
                parentNode[key] = node.value;
                break;
            case 'add':
                if (Array.isArray(parentNode)) {
                    parentNode.splice(key, 0, changes);
                } else {
                    parentNode[key] = changes;
                }
                break;
            case 'push':
                if(parentNode[key] === undefined || !Array.isArray(parentNode[key])) {
                    parentNode[key] = [];
                }
                parentNode[key].push(changes);
                break;
            case 'delete':
                if (Array.isArray(parentNode)) {
                    parentNode.splice(key, 1);
                } else {
                    delete parentNode[key];
                }
                break;
            default:
                throw new Error(`Unsupported operation: ${operation}`);
        }
    });

    return deepClone;
}

export default updateObjectByPath;
