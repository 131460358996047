import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export function downloadArrayOfStringsAsTXTZip(arrayOfStrings, zipFilename = 'download.zip') {
  const zip = new JSZip();

  // Create a .txt file for each string in the array
  arrayOfStrings.forEach((content, index) => {
    zip.file(`chunk-${index + 1}.txt`, content);
  });

  // Generate the zip file and trigger download
  zip.generateAsync({ type: 'blob' })
    .then(function (content) {
      saveAs(content, zipFilename);
    })
    .catch(err => console.error('Error zipping files:', err));
}
