import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Hydrate from 'components/Hydrate/Hydrate'
import CustomField from 'kit/components/CustomField/CustomField'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import Callout from 'kit/components/Callout/Callout.js'
import Modal from 'kit/components/Modal/Modal.js'
import Checkbox from 'kit/components/Checkbox/Checkbox.js'

import {
  tryToSetInquirySettingsMetadata,
  tryToSetInquirySettingsVisibility,
  tryToDeleteInquiry
} from 'actions/actions.export'


class InquirySettings extends Component {
  constructor(props){
    super(props);

    this.state = {
      display_name: "",
      description: "",
      retypeNameDelete: ""
    }
  }

  componentWillMount(){
    const { dispatch, inquiryReducer } = this.props;

    const inquiry = inquiryReducer.cache[this.props.id];
    if(inquiry){
      
      this.setState({
        display_name: inquiry.display_name,
        description: inquiry.description,
        visibility: inquiry.visibility || "org"
      })
    } 
  }

  componentWillReceiveProps(newprops){
    const inquiry = newprops.inquiryReducer.cache[newprops.id];
    if(inquiry){
      if(!newprops.inquiryReducer.tryingToSetInquirySettingsMetadata && this.state.submittedMetadata){
        this.setState({
          display_name: inquiry.display_name,
          description: inquiry.description
        })
      }

      if(!newprops.inquiryReducer.tryingToSetInquirySettingsVisibility && this.state.submittedVisibility){
        this.setState({
          visibility: inquiry.visibility
        })
      }
    } 
  }

  render(){

    const { dispatch, inquiryReducer, userReducer, orgReducer, canWrite } = this.props;

    const inquiry = inquiryReducer.cache[this.props.id];

    if(!inquiry) return <span>Failed to load.</span>;

    let canSubmit = this.state.display_name && !this.state.display_name_error && !this.state.description_error;


    const visibilityOptions = [
      {
        label: <span><i className="far fa-fw fa-lock icon-before-text text-warning"/> <span className="text-900 text-warning">All Organization Members</span></span>,
        value: "org"
      },
      {
        label: <span><i className="far fa-fw fa-lock icon-before-text text-danger"/> <span className="text-900 text-danger">Team Only</span></span>,
        value: "team"
      }
    ]

    return <div className="inquiry-settings">
      <div className="row">
        <div className="col-xl-12 margin-top-05rem margin-bottom-2rem">
          <div className="flex-split">
            <div>
              <h4 className="no-margin-top no-margin-bottom">Settings</h4>
              <small>
                Edit aspects like the name, description, and visibility of this inquiry.
              </small>
            </div>
          </div>

          
        </div>

        <div className="col-md-12 margin-bottom-2rem">
          <div className="box">
            <div className="flex-split">
              <h4 className="no-margin">Metadata</h4>
              <CustomButton 
                display="Save Changes"
                thinking={inquiryReducer.tryingToSetInquirySettingsMetadata}
                success={inquiryReducer.setInquirySettingsMetadataSuccess}
                fail={inquiryReducer.setInquirySettingsMetadataFail}
                disabled={!canWrite || !canSubmit}
                color="success"
                size="small"
                onClick={()=>{
                  dispatch(tryToSetInquirySettingsMetadata({
                    id: inquiry.id,
                    display_name: this.state.display_name,
                    description: this.state.description
                  }))

                  this.setState({
                    submittedMetadata: true
                  })
                }}
                />
            </div>

            {
              inquiryReducer.setInquirySettingsMetadataForm.errors.error && <Callout style="danger" content={inquiryReducer.setInquirySettingsMetadataForm.errors.error}/>
            }
            <CustomField
              name="display_name"
              label="Project Name"
              placeholder="ex: Mobile App"
              description="What is this collection of endpoints for?"
              maxLength={64}
              required={true}
              disabled={inquiryReducer.tryingToSetInquirySettingsMetadata || !canWrite}
              value={this.state.display_name}
              serverError={inquiryReducer.setInquirySettingsMetadataForm.errors.display_name}
              lastSubmit={inquiryReducer.setInquirySettingsMetadataForm.lastSubmit.display_name}
              onChange={(e) => {
                this.setState({
                  display_name: e.value,
                  display_name_error: e.error,
                  submittedMetadata: false
                })
              }}
              />


            <CustomField
              name="description"
              label="Description"
              placeholder=" "
              description="A few sentences the intended use."
              maxLength={256}
              rows={3}
              disabled={inquiryReducer.tryingToSetInquirySettingsMetadata || !canWrite}
              value={this.state.description}
              serverError={inquiryReducer.setInquirySettingsMetadataForm.errors.description}
              lastSubmit={inquiryReducer.setInquirySettingsMetadataForm.lastSubmit.description}
              onChange={(e) => {
                this.setState({
                  description: e.value,
                  description_error: e.error,
                  submittedMetadata: false
                })
              }}
              />
          </div>
        </div>

        <div className="col-md-12 margin-bottom-2rem">
          <div className="box">
            <div className="flex-split">
              <h4 className="no-margin">Visibility</h4>
              <CustomButton 
                display="Save Changes"
                thinking={inquiryReducer.tryingToSetInquirySettingsVisibility}
                success={inquiryReducer.setInquirySettingsVisibilitySuccess}
                fail={inquiryReducer.setInquirySettingsVisibilityFail}
                disabled={!canWrite || !canSubmit}
                color="success"
                size="small"
                onClick={()=>{
                  dispatch(tryToSetInquirySettingsVisibility({
                    id: inquiry.id,
                    visibility: this.state.visibility
                  }))

                  this.setState({
                    submittedVisibility: true
                  })
                }}
                />
            </div>

            {
              inquiryReducer.setInquirySettingsVisibilityForm.errors.error && <Callout style="danger" content={inquiryReducer.setInquirySettingsVisibilityForm.errors.error}/>
            }

            {
              inquiry.visibility === 'org' &&
              <div>
                <p>
                  {inquiry.display_name} is <span className="text-900">private</span>.
                </p>
                <p>
                  It can only be seen by members of <Hydrate id={inquiry.scope} field="display_name" type="organization"/>.
                </p>
              </div>
            }


            {
              inquiry.visibility === 'team' &&
              <div>
                <p>
                  {inquiry.display_name} is <span className="text-900">private</span> and can only be seen by the invited team.
                </p>
                <p>
                  It does not appear to members of this organization that haven't been added to its team.
                </p>
              </div>
            }


            <div className="spacer-1rem"/>
            <CustomSelect
              label="Set Visibility"
              required={true}
              disabled={!canWrite}
              description="Adjust who can see this."
              serverError={inquiryReducer.setInquirySettingsVisibilityForm.errors.visibility}
              lastSubmit={inquiryReducer.setInquirySettingsVisibilityForm.lastSubmit.visibility}
              value={this.state.visibility}
              onChange={(v)=>{
                this.setState({
                  visibility: v,
                  submitted: false
                })
              }}
              options={visibilityOptions}
              />
          </div>
        </div>

        {
          canWrite &&
          <div className="col-md-12">
            <div className="box box-danger">
              <div className="flex-split">
                <h4 className="no-margin">Delete</h4>
                <CustomButton 
                  display="Delete"
                  disabled={!canWrite || !canSubmit || !this.state.confirmDelete}
                  color="danger"
                  size="small"
                  onClick={()=>{
                    this.setState({
                      deleteModal: true,
                      retypeNameDelete: ""
                    })
                  }}
                  />
              </div>
              <p>
                Deleting will immediately remove this project from your organization and cannot be undone.
              </p>
              <Checkbox
                value={this.state.confirmDelete}
                style="align-left"
                description="I understand the consequences of deleting."
                onToggle={(e)=>{
                  this.setState({
                    confirmDelete: e
                  })
                }}
              />
            </div>
          </div>
        }
        <Modal
          show={this.state.deleteModal}
          exitable={true}
          onExit={e => this.setState({deleteModal: false})}
          cancelable={true}
          onCancel={e => this.setState({deleteModal: false})}
          acceptButtonLabel="Permenantly Delete"
          acceptButtonColor="danger"
          acceptButtonThinking={inquiryReducer.tryingToDeleteInquiry}
          acceptButtonFail={inquiryReducer.deleteInquiryFail}
          acceptButtonSuccess={inquiryReducer.deleteInquirySuccess}
          acceptButtonDisabled={this.state.retypeNameDelete !== inquiry.display_name}
          acceptable={true}
          onAccept={e => {
            dispatch(tryToDeleteInquiry({
              id: inquiry.id,
              scope: inquiry.scope
            }))
          }}
          content={<div>
            <h4 className="no-margin">Are you sure?</h4>
            <p>Deleting cannot be undone.</p>

            <CustomField
              label={<span>Type the name of this project to confirm.</span>}
              value={this.state.retypeNameDelete}
              description={<span>The name is: <strong>{inquiry.display_name}</strong></span>}
              onChange={e => {
                this.setState({
                  retypeNameDelete: e.value
                })
              }}
              />
          </div>}
          /> 
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, inquiryReducer, orgReducer } = state;

  return {
    userReducer, 
    inquiryReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(InquirySettings);

  