import React, { Component } from 'react'
import { connect } from 'react-redux'
import CustomButton from '../kit/components/CustomButton/CustomButton.js'


import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import ASCIIBackground from '../components/ASCIIBackground/ASCIIBackground.js'

class ErrorRoute extends Component {
  componentDidMount(){
    window.document.title = "zerowidth.ai - oops";
  }

  render(){

    let hfStretchedClassList = "hf-stretched";
    
    return <div className={hfStretchedClassList}>     
    <div className="fixed-big-image">
      <ASCIIBackground 
        imagePath={'/home-img/zebra-print.png'} 
        foreground={'#C0C7D3'} 
        background={'#fff'} 
        autoTween={1000} 
        fontSize={16}
        placementMode={'cover'}
        />
    </div> 
      <Header borderless={true}/>
      <div className="body flex-column-center-center">
        <h1 className="no-margin"></h1>
        <h4 className="text-center">Something's not right if you ended up here.</h4>
        <div className="spacer-2rem"/>
        <CustomButton
          to="/"
          color="black"
          display="Go Home"
          />
      </div>
      <Footer simple={true}/>
    </div>
  }
}

const mapStateToProps = (state) => {
  const { userReducer, guiReducer } = state;

  return {
    userReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(ErrorRoute);

  