import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Update the opportunity map, like for deleting or reprioritizing nodes
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestUpdateInquiryOpportunityMap = packet => {
  return {
    type: 'REQUEST_UPDATE_INQUIRY_OPPORTUNITY_MAP',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveUpdateInquiryOpportunityMapSuccess = data => ({
  type: 'RECEIVE_UPDATE_INQUIRY_OPPORTUNITY_MAP_SUCCESS',
  data
})

export const receiveUpdateInquiryOpportunityMapFail = data => ({
  type: 'RECEIVE_UPDATE_INQUIRY_OPPORTUNITY_MAP_FAIL',
  data
})

export const tryToUpdateInquiryOpportunityMap = packet => (dispatch, getState) => {

  dispatch(requestUpdateInquiryOpportunityMap(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/inquiries/opportunity-map/' + packet.inquiry_id + '?generate=' + packet.generate, {
      method: 'put',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveUpdateInquiryOpportunityMapSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveUpdateInquiryOpportunityMapFail({errors:e, lastSubmit: packet}));
    })
}
