import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import Callout from '../../kit/components/Callout/Callout.js'

import {
  tryToEditUserPassword
} from '../../actions/actions.export'

class EditUserPasswordForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToEditUserPassword({
      password_old: this.state.password_old,
      password_new: this.state.password_new,
      password_new_again: this.state.password_new_again
    }))
  }

  componentDidUpdate(){
    if(this.props.userReducer.editUserPasswordSuccess !== this.state.lastSuccess){
      this.setState({
        lastSuccess: this.props.userReducer.editUserPasswordSuccess,
        password_old: "",
        password_new: "",
        password_new_again: ""
      })
    }
  }

  render(){

    const { dispatch, userReducer } = this.props;

    let canSubmit = this.state.password_old && !this.state.password_old_error 
                 && this.state.password_new && !this.state.password_new_error
                 && this.state.password_new_again && !this.state.password_new_again_error;

    return <div className="box">
      <form onSubmit={()=>{
        if(canSubmit) this.handleSubmit()
      }}>

        <h5 className="no-margin-top margin-bottom-3rem">
          Change your account's password
        </h5>

        <div key={this.state.lastSuccess}>
          <CustomField
            name="old_field_password"
            type="password"
            label="Current Password"
            placeholder="********"
            required={true}
            disabled={userReducer.tryingToEditUserPassword}
            value={this.state.password_old}
            serverError={userReducer.editUserPasswordForm.errors.password_old}
            lastSubmit={userReducer.editUserPasswordForm.lastSubmit.password_old}
            onChange={(e) => {
              this.setState({
                password_old: e.value,
                password_old_error: e.error
              })
            }}
            />


          <CustomField
            name="new_field_password"
            type="password"
            label="New Password"
            placeholder="********"
            minLength={8}
            required={true}
            disabled={userReducer.tryingToEditUserPassword}
            value={this.state.password_new}
            serverError={userReducer.editUserPasswordForm.errors.password_new}
            lastSubmit={userReducer.editUserPasswordForm.lastSubmit.password_new}
            onChange={(e) => {
              this.setState({
                password_new: e.value,
                password_new_error: e.error
              })
            }}
            />


          <CustomField
            name="new_field_password_verify"
            type="password"
            label="New Password Again"
            placeholder="********"
            minLength={8}
            required={true}
            disabled={userReducer.tryingToEditUserPassword}
            value={this.state.password_new_again}
            clientError={(v) => {return (this.state.password_new !== v && v.length > 0) ? "Passwords must match." : false}}
            serverError={userReducer.editUserPasswordForm.errors.password_new_again}
            lastSubmit={userReducer.editUserPasswordForm.lastSubmit.password_new_again}
            onChange={(e) => {
              this.setState({
                password_new_again: e.value,
                password_new_again_error: e.error
              })
            }}
            />
        </div>

        <div className="spacer-2rem"/>

        {
          userReducer.editUserPasswordForm.errors.error &&
          <Callout
            style="danger"
            dismissable={true}
            content={userReducer.editUserPasswordForm.errors.error}
          />
        }
        
        <div className="list-right">
          <CustomButton
            display="Save"
            color="success"
            thinking={userReducer.tryingToEditUserPassword}
            fail={userReducer.editUserPasswordFail}
            success={userReducer.editUserPasswordSuccess}
            disabled={!canSubmit}
            onClick={()=>{
              if(canSubmit) this.handleSubmit()
            }}
            />
        </div>
      </form>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(EditUserPasswordForm);
