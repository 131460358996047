import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from './a.users.forceLogoutHandler';

import {
  tryToHeartbeat,
  tryToGetUserLog
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// VerifyEmail
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestVerifyEmail = packet => {
  return {
    type: 'REQUEST_VERIFY_EMAIL',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveVerifyEmailSuccess = data => ({
  type: 'RECEIVE_VERIFY_EMAIL_SUCCESS',
  data
})

export const receiveVerifyEmailFail = data => ({
  type: 'RECEIVE_VERIFY_EMAIL_FAIL',
  data
})

export const tryToVerifyEmail = packet => (dispatch, getState) => {

  dispatch(requestVerifyEmail(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/users/verify-email', {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveVerifyEmailSuccess(json.data));
      if(window.location.pathname === "/almost-signed-up" || window.location.pathname === "/bump"){
        dispatch(push('/'))
        dispatch(tryToHeartbeat({verbose: true}));
      } else { 
        dispatch(tryToGetUserLog({type: 'email'}));
      }
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveVerifyEmailFail({errors:e, lastSubmit: packet}));
    })
}
