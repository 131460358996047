import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import * as d3 from 'd3'
import moment from 'moment'
import shortid from 'shortid'


import {
  showTooltip,
  hideTooltip
} from '../../actions/actions.export'

import './ComponentVersionBar.scss';

let width, height;

class ComponentVersionBar extends Component {
  constructor(props){
    super(props);

    this.state = {
      id: shortid.generate()
    }

    this.updateViz = this.updateViz.bind(this);
  }

  updateViz(forceUpdate){

    const { dispatch, componentReducer } = this.props;

    var el = document.getElementById('component-version-bar-viz-svg-' + this.state.id);
    if(!el) return;

    var that = this;
    var positionInfo = el.getBoundingClientRect();
    let newWidth = positionInfo.width;
    let newHeight = positionInfo.height;

    // if(newWidth === width && newHeight === height && !forceUpdate) return;


    const component = componentReducer.cache[this.props.id];
    
    if(!component) return;
    if(!component.versions) return;


    width = positionInfo.width;
    height = positionInfo.height;

    var svg = d3.select('#component-version-bar-viz-svg-' + this.state.id)
      .attr('width', width)
      .attr('height', height)
      ;

    svg.selectAll('g').remove();

    let drawGroup = svg.append('g');

    const r = 5;
    let horizontalPadding = 5;
    let verticalPadding = 9;

    const publishedVersions = component.versions.filter(v => v.id !== 'draft');

    publishedVersions.sort((a,b)=>{
      if(a.created_at < b.created_at) return -1;
      if(a.created_at > b.created_at) return 1;
    })


    drawGroup.append('line')
      .attr('x1', r + horizontalPadding)
      .attr('y1', height - r - verticalPadding)
      .attr('x2', width - r - horizontalPadding)
      .attr('y2', height - r - verticalPadding)
      .attr('class', 'component-version-bar-line')
      ;


    if(publishedVersions.length === 0){

      drawGroup.append('text')
        .attr('x', horizontalPadding)
        .attr('y', height - verticalPadding - r * 2 - 7)
        .attr('text-anchor', 'start')
        .attr('class', 'component-version-bar-text-label')
        .text("Currently working on draft of v1.0")
        ;


      drawGroup.append('circle')
        .attr('cx', r + horizontalPadding)
        .attr('cy', height - r - verticalPadding)
        .attr('class', 'component-version-bar-dot')
        .attr('r', r)
        .attr('index', function(d,i){ return i; })
        .attr('id', function(d,i){ return 'component-version-var-dot-' + that.state.id + '_' + i; })
        .on('mouseover', function(e, d){

          let index = parseInt(d3.select(this).attr('index'));

          dispatch(showTooltip({
            el: document.getElementById('component-version-var-dot-' + that.state.id + '_' + index),
            nobr: false,
            position: 'top',
            content: <div className="text-center">
              Unpublished Draft<br/>
            </div>
          }))
          
        })
        .on('mouseout', function(e,d){
          dispatch(hideTooltip());
        })
        ;


      return;
    }


    let x_scale = d3.scaleLinear()
      .domain([moment(publishedVersions[0].created_at).valueOf(), moment().valueOf()])
      .range([r + horizontalPadding, width - r - horizontalPadding])
      ;


    drawGroup.append('line')
      .attr('x1', x_scale(moment(publishedVersions[0].created_at).valueOf()))
      .attr('y1', height - r - verticalPadding)
      .attr('x2', x_scale(moment(publishedVersions[publishedVersions.length - 1].created_at).valueOf()))
      .attr('y2', height - r - verticalPadding)
      .attr('class', 'component-version-bar-line component-version-bar-line-success')
      ;

    let versionDots = drawGroup.selectAll('versionDots')
      .data(publishedVersions)
      .enter();

    versionDots.append('circle')
      .attr('cx', function(d,i){ return x_scale(moment(d.created_at).valueOf()); })
      .attr('cy', height - r - verticalPadding)
      .attr('class', 'component-version-bar-dot')
      .attr('r', r)
      .attr('index', function(d,i){ return i; })
      .attr('id', function(d,i){ return 'component-version-var-dot-' + that.state.id + '_' + i; })
      .on('mouseover', function(e, d){

        let index = parseInt(d3.select(this).attr('index'));

        dispatch(showTooltip({
          el: document.getElementById('component-version-var-dot-' + that.state.id + '_' + index),
          nobr: false,
          position: 'top',
          content: <div className="text-center">
            v{d.id}<br/>
            <small className="text-muted">{moment(d.created_at).format('MM/DD/YYYY')}</small>
          </div>
        }))
        
      })
      .on('mouseout', function(e,d){
        dispatch(hideTooltip());
      })
      ;

    drawGroup.append('text')
      .attr('x', horizontalPadding)
      .attr('y', height - verticalPadding - r * 2 - 7)
      .attr('text-anchor', 'start')
      .attr('class', 'component-version-bar-text-label')
      .text("v1.0 published " + moment(publishedVersions[0].created_at).format('MMM D YYYY'))
      ;


    drawGroup.append('text')
      .attr('x', width - horizontalPadding - r + 2)
      .attr('y', height - verticalPadding - r * 2 - 7)
      .attr('text-anchor', 'end')
      .attr('class', 'component-version-bar-text-label')
      .text("v" + publishedVersions[publishedVersions.length - 1].id + " was published " + moment(publishedVersions[publishedVersions.length - 1].created_at).fromNow())
      ;

  }

  componentDidMount(){
    this.updateViz(true);  

    window.addEventListener('resize', this.updateViz);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateViz);
  }
  
  render(){
    const { componentReducer, orgReducer } = this.props;

    const component = componentReducer.cache[this.props.id];

    if(!component || !orgReducer.membershipsReady) return <div className="component-version-bar"/>;

    let membership = orgReducer.memberships.find(m => m.organization.id === component.scope);
    // lets just grab the actual role data within the membership object
    if(membership){
      membership = membership.membership;
    } else {
      membership = {}
    }


    return <div className="component-version-bar">
      <span/>
      
        <div className="component-version-bar-viz">
          <svg id={"component-version-bar-viz-svg-" + this.state.id} className="component-version-bar-viz-svg"/>
        </div>
    </div>
  }
}

const mapStateToProps = (state) => {
  const { userReducer, componentReducer, orgReducer, guiReducer } = state;

  return {
    userReducer,
    componentReducer,
    orgReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(ComponentVersionBar);

  