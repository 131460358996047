import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import {
  tryToGetComponentVersion,
  toggleContentPane
} from 'actions/actions.export'

import Hydrate from 'components/Hydrate/Hydrate.js'
import ComponentVersionBar from 'components/ComponentVersionBar/ComponentVersionBar.js'
import WorkbenchRecentComponentDynamic from 'components/Workbench/WorkbenchRecentComponentDynamic.js'

import CustomButton from 'kit/components/CustomButton/CustomButton.js'

import tools from 'configs/config.component-tools'

import './ComponentVersion.scss';
import Callout from 'kit/components/Callout/Callout'
import FlowRenderer from 'components/FlowBuilder/FlowRenderer'


class ComponentVersion extends Component {
  constructor(props){
    super(props);


    // this.state = {
    //   version: this.props.subpage
    // }
  }

  // componentWillMount(){
  //   const { dispatch, componentReducer } = this.props;

  //   dispatch(toggleContentPane(true));

  //   if(this.props.subpage){

  //     let now = new Date().getTime();
  //     let loadedVersion = componentReducer.cache[this.props.id].versions.find(v => v.id === this.props.subpage);
  //     // only refire this if we havent pulled the full data yet or if we are grabbing draft and its been at least a minute
  //     if(!loadedVersion || !loadedVersion.lastGetSuccess || (now - loadedVersion.lastGetSuccess > 60*1000 && loadedVersion.id === 'draft')){
  //       dispatch(tryToGetComponentVersion({
  //         id: this.props.id,
  //         version: this.props.subpage 
  //       }))
  //     }
  //   }
  // }

  // componentWillReceiveProps(newprops){
  //   const { dispatch, componentReducer } = newprops;

  //   if(newprops.subpage !== this.state.version){
  //     this.setState({
  //       version: newprops.subpage
  //     });

  //     if(newprops.subpage){
  //       let now = new Date().getTime();
  //       let loadedVersion = componentReducer.cache[newprops.id].versions.find(v => v.id === newprops.subpage);
  //       // only refire this if we havent pulled the full data yet or if we are grabbing draft and its been at least a minute
  //       if(!loadedVersion || !loadedVersion.lastGetSuccess || (now - loadedVersion.lastGetSuccess > 60*1000 && loadedVersion.id === 'draft')){
  //         dispatch(tryToGetComponentVersion({
  //           id: newprops.id,
  //           version: newprops.subpage 
  //         }))
  //       }
  //     }
  //   }
  // }

  render(){

    const { dispatch, orgReducer, componentReducer, userReducer, intelReducer } = this.props;

    const component = componentReducer.cache[this.props.id];
    if(!component) return <span>component failed to load.</span>;

    return <div className="row">
      <div className="col-md-12">
        <ComponentVersionBar
          id={component.id}
          />
      </div>
      {
        component.current_version === 'draft' &&
        <div className="col-md-12">
          <Callout
            style="warning"
            title="This flow hasn't been published yet."
            content="You can still work on it, but it won't be visible beyond your organization or available via the API until you publish it."
            />
        </div>
      }
      {
        component.versions.filter(v => v.id !== 'draft').map((v, i) => {
          return <div className="col-md-12 margin-bottom-2rem" key={i}>
            <div className="box">
              <div className="row">
                <div className="col-md-8">
                  <div className="flex-split flex-split-align-start">
                    <div>
                      <div className="list-left">
                        <h4 className="no-margin">{v.id}</h4>
                      </div>
                      <small className="text-muted">
                          Created {moment(v.created_at).format('MMMM Do YYYY, h:mm a')}
                      </small>
                    </div>
                    <div className="list-right">
                      <CustomButton
                        display={<span><small className="fas fa-play icon-before-text"/>Open Demo Page</span>}
                        color="component"
                        size="xs"
                        toBlank={"/c/demo/" + component.id + "/" + v.id}
                        />
                    </div>
                  </div>

                </div>
                <div className="col-md-4">
                  <div className="sixteen-by-nine-parent">
                    
                    <div className="sixteen-by-nine-child">
                      <FlowRenderer
                        nodes={intelReducer.flow_nodes.length > 0 ? v.flow_nodes : []}
                        links={intelReducer.flow_nodes.length > 0 ? v.flow_links : []}
                        interactive={false}
                        simpleMode={true}
                        showMinimap={false}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        })
      }

    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, componentReducer, orgReducer, intelReducer } = state;

  return {
    userReducer, 
    componentReducer,
    orgReducer,
    intelReducer
  }
}

export default connect(mapStateToProps)(ComponentVersion);

  