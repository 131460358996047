import React, { Component } from 'react'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton'
import CustomField from '../../kit/components/CustomField/CustomField'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect'
import Callout from '../../kit/components/Callout/Callout'

import isFeatureSwitchOn from '../../utilities/isFeatureSwitchOn'

import {
  showTooltip,
  hideTooltip,
  tryToCreateNewKnowledge
} from '../../actions/actions.export'


class NewKnowledgeForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      display_name: "",
      description: "",
      visibility: "org",
      editability: "team",
      embeddings_model: "text-embedding-3-small",
      dimensions: 256,
      supportsDimensionCropping: true,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToCreateNewKnowledge({
      display_name: this.state.display_name,
      description: this.state.description,
      scope: this.props.org_id,
      visibility: this.state.visibility,
      editability: this.state.editability,
      origin_project: this.props.project_id,
      embeddings_model: this.state.embeddings_model,
      dimensions: this.state.dimensions
    }))
  }

  
  render(){

    const { dispatch, knowledgeReducer, orgReducer, projectReducer, intelReducer } = this.props;

    let membership;
    if(orgReducer.memberships.length > 0){
      membership = orgReducer.memberships.find(m => m.organization.id === this.props.org_id).membership;
    }

    if(!membership) return <span/>;


    let projects = []
    for(let project_id in projectReducer.cache){
      let project = projectReducer.cache[project_id];
      if(project.scope === this.props.org_id){
        projects.push(project);
      }
    }

    let canSubmit = this.state.display_name && !this.state.display_name_error;


    let selectableModels = intelReducer.embedding_models;

    return <div className="box box-knowledge-left">
        <form onSubmit={()=>{
          if(canSubmit) this.handleSubmit()
        }}>
          <div className="flex-split margin-bottom-2rem">
            <h3 className="no-margin">
              New Knowledge Base
            </h3>
            <i className="fas fa-book-spells text-knowledge fa-2x text-muted"/>
          </div>
          <p className="no-margin-top ">
            Each Knowledge Base is a personalized repository of custom information, enabling you to empower your LLMs to access proprietary information. Harvested from various formats like PDFs, web content, or text files, these sets act as an organized, accessible source of truth that can be seamlessly integrated into your agents.
          </p>

          <hr/>

          <div className="row">
            <div className="col-md-12">
              <CustomField
                name="field_new_thing_name"
                label="Name"
                maxLength={64}
                placeholder="ex: Best Practices, Sales Playbook, User Manual"
                description="This can be changed later."
                required={true}
                disabled={knowledgeReducer.tryingToCreateNewKnowledge}
                value={this.state.display_name}
                serverError={knowledgeReducer.newKnowledgeForm.errors.display_name}
                lastSubmit={knowledgeReducer.newKnowledgeForm.lastSubmit.display_name}
                onChange={(e) => {
                  this.setState({
                    display_name: e.value,
                    display_name_error: e.error
                  })
                }}
                />
            </div>
            <div className="col-md-12">
              <CustomSelect
                label="Which project does this belong to?"
                value={this.props.project_id || this.state.project_id}
                disabled={this.props.project_id}
                description="You can add this to projects at anytime."
                onChange={(v)=>{
                  this.setState({
                    project_id: v
                  })
                }}
                options={projects.map((project, i)=>{
                  return {
                    label: project.display_name,
                    value: project.id
                  }
                })}
                />
            </div>
            <div className="col-md-6">
              <CustomSelect
                label="Who should be able to see this?"
                required={true}
                value={this.state.visibility}
                onChange={(v)=>{
                  this.setState({
                    visibility: v
                  })
                }}
                options={[
                  // {
                  //   label: <span><i className="far fa-fw fa-globe icon-before-text text-success"/> <span className="text-900">Anyone on the Internet</span></span>,
                  //   value: "public"
                  // },
                  {
                    label: <span><i className="far fa-fw fa-building icon-before-text"/> <span className="text-900">Anyone in this organization</span></span>,
                    value: "org"
                  },
                  {
                    label: <span><i className="far fa-fw fa-users icon-before-text text-warning"/> <span className="text-900">Only specific people</span></span>,
                    value: "team"
                  },
                ]}
                />
            </div>

            <div className="col-md-6">
              <CustomSelect
                label="Who should be able to edit this?"
                required={true}
                value={this.state.editability}
                onChange={(v)=>{
                  this.setState({
                    editability: v
                  })
                }}
                options={[
                  {
                    label: <span><i className="far fa-fw fa-building icon-before-text"/> <span className="text-900">Anyone in this organization</span></span>,
                    value: "org"
                  },
                  {
                    label: <span><i className="far fa-fw fa-users icon-before-text text-warning"/> <span className="text-900">Only specific people</span></span>,
                    value: "team"
                  }
                ]}
                />
            </div>
            <div className="col-md-12">
              <p className="text-900">
                What will this Knowledge Base contain information about?
              </p>
              <p className="p-small">
                This will help both you, and your agents, understand what this knowledge base is for when it is time to leverage it in a retrieval augmented generation systems. Since a single Knowledge Base can be used by multiple agents, being clear about what it contains can set your teammates within your organization up for success with their agents too.
              </p>
              <CustomField
                name="field_new_thing_description"
                label="Description"
                maxLength={256}
                rows={3}
                placeholder="ex: A collection of best practices for sales and customer service."
                description="This can be changed later."
                value={this.state.description}
                serverError={knowledgeReducer.newKnowledgeForm.errors.description}
                lastSubmit={knowledgeReducer.newKnowledgeForm.lastSubmit.description}
                onChange={(e) => {
                  this.setState({
                    description: e.value,
                    description_error: e.error
                  })
                }}
                />
            </div>
            <div className="col-md-6">
              <CustomSelect
                value={this.state.embeddings_model}
                label="Which embeddings model would you like to use?"
                placeholder="text-embedding-ada-002, text-embedding-3-small, etc"
                description={<span>ZeroWidth recommends <strong>text-embedding-3-small</strong> for its speed and performance.</span>}
                large={true}
                required={true}
                serverError={knowledgeReducer.newKnowledgeForm.errors.embeddings_model}
                lastSubmit={knowledgeReducer.newKnowledgeForm.lastSubmit.embeddings_model}
                options={selectableModels.map((model, i)=>{
                  return {
                    label: <div className="thin-line-height list-left">
                      {model.provider === 'OpenAI' && <img src="/img/openai_logo_small.png" width="30"/>}
                      {model.provider === 'Google PaLM2' && <img src="/img/palm2_logo_small.png" width="30"/>}
                      {model.provider === 'Google Gemini' && <img src="/img/gemini_logo_small.png" width="30"/>}
                      {model.provider === "Anthropic" && <img src="/img/anthropic_logo_small.png" width="30"/>}
                      {model.provider === "Meta" && <img src="/img/meta_logo_small.png" width="30"/>}
                      <div>
                        <strong>{model.name}</strong><br/>
                        <div className="text-ellipsis-1-lines"><small>by {model.provider}  {model.legacy ? <span className="margin-left-1rem text-tag text-tag-tiny text-tag-danger">LEGACY</span> : model.preview ? <span className="margin-left-1rem text-tag text-tag-tiny text-tag-warning">PREVIEW</span> : ""}</small></div>
                      </div>
                    </div>,
                    value: model.name
                  }
                })}
                onChange={(e) => {
                  // find this model and set the dimensions to its default
                  this.setState({
                    embeddings_model: e,
                    dimensions: selectableModels.find(m => m.name === e).dimensions,
                    supportsDimensionCropping: selectableModels.find(m => m.name === e).supportsDimensionCropping
                  })
                }}
                />
            </div>
            <div className="col-md-6">
              <CustomSelect
                value={this.state.dimensions}
                label="How many dimensions should the embeddings have?"
                placeholder="256, 512, 1024, etc"
                description="This will determine the size of the embeddings and cannot be changed later."
                required={true}
                large={true}
                serverError={knowledgeReducer.newKnowledgeForm.errors.dimensions}
                lastSubmit={knowledgeReducer.newKnowledgeForm.lastSubmit.dimensions}
                disabled={!this.state.supportsDimensionCropping}
                options={[
                  {
                    label: "64",
                    value: 64
                  },
                  {
                    label: "128",
                    value: 128
                  },
                  {
                    label: "256",
                    value: 256
                  },
                  {
                    label: "512",
                    value: 512
                  },
                  {
                    label: "1024",
                    value: 1024
                  },
                  {
                    label: "1536",
                    value: 1536
                  },
                  {
                    label: "2048",
                    value: 2048
                  },
                  // {
                  //   label: "3072",
                  //   value: 3072
                  // }
                ].filter(d => {
                  let model = selectableModels.find(m => m.name === this.state.embeddings_model);
                  if(model){
                    return d.value <= model.dimensions
                  }
                  return true;
                })}
                onChange={(e) => {
                  this.setState({
                    dimensions: e,
                  })
                }}
                />
            </div>
          </div>


          <div className="spacer-2rem"/>

          {
            knowledgeReducer.newKnowledgeForm.errors.error &&
            <Callout
              style="danger"
              dismissable={true}
              content={knowledgeReducer.newKnowledgeForm.errors.error}
            />
          }
          
          <div className="list-right">

            <CustomButton
              display="Cancel"
              color="transparent"
              size="small"
              to={"/workbench/" + this.props.org_id + '/knowledge'}
              />

            <CustomButton
              display="Create Knowledge Base"
              color="black"
              thinking={knowledgeReducer.tryingToCreateNewKnowledge}
              fail={knowledgeReducer.newKnowledgeFail}
              success={knowledgeReducer.newKnowledgeSuccess}
              disabled={!canSubmit || !membership.create_knowledge}
              onClick={()=>{
                if(canSubmit) this.handleSubmit()
              }}
              />
          </div>
        </form>          
      </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, orgReducer, knowledgeReducer, guiReducer, projectReducer, intelReducer  } = state;

  return {
    userReducer,
    orgReducer,
    knowledgeReducer,
    guiReducer, 
    projectReducer, 
    intelReducer
  }
}

export default connect(mapStateToProps)(NewKnowledgeForm);
