import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment';
import { push } from 'connected-react-router'

import CustomButton from '../../kit/components/CustomButton/CustomButton'
import CustomField from '../../kit/components/CustomField/CustomField'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect'
import Callout from '../../kit/components/Callout/Callout'

import Hydrate from '../../components/Hydrate/Hydrate'

import {
  tryToGetSearch
} from '../../actions/actions.export'

class WorkbenchSearch extends Component {
  constructor(props){
    super(props);

    this.state = {
      currentSearch: '',
      query: '',
      kinds: []
    }

    this.loadURLIntoStateAndSearch = this.loadURLIntoStateAndSearch.bind(this);
    this.searchFormSubmit = this.searchFormSubmit.bind(this);
  }

  componentDidMount(){
    if(this.state.query === ''){
      document.getElementById('field_search_wbsearch').focus();
    }
  }

  componentWillMount(){
    this.loadURLIntoStateAndSearch(this.props);
  }

  componentWillReceiveProps(nextProps){
    // if the url changes, load the new url into state
    if(window.location.search !== this.state.lastSearchURL){
      this.loadURLIntoStateAndSearch(nextProps);
    }

  }

  loadURLIntoStateAndSearch(props){
    const { dispatch } = props;
    let loadedState = {};

    // grab the url parameters and load them into state where relevant 
    const params = new URLSearchParams(window.location.search);
    const query = params.get('query');
    const kinds = (params.get('kinds') || "").split(' ').filter(k => k.length > 0);
    const page = parseInt(params.get('page')) || 0;

    if(query){
      loadedState.query = query;
    }

    if(kinds.length > 0){
      loadedState.kinds = kinds;
    }

    loadedState.page = page;

    loadedState.lastSearchURL = window.location.search;

    this.setState(loadedState);

  
    if(query){
      let queryPacket = {
        organization: props.org_id,
        query: query,
      }

      if(kinds.length > 0){
        queryPacket.kinds = kinds.join(" ");
      }

      if(page){
        queryPacket.page = page;
      }

      dispatch(tryToGetSearch(queryPacket))
    }
  }

  searchFormSubmit(e){
    if(e.preventDefault) e.preventDefault();
    let url = '/workbench/' + this.props.org_id + '/search?query=' + this.state.query;
    
    if(this.state.kinds.length > 0){
      url += '&kinds=' + this.state.kinds.join('+');
    }

    if(this.state.page){
      url += '&page=' + this.state.page;
    }

    this.props.dispatch(push(url));
  }

  render(){

    const { dispatch, sharedReducer, projectReducer, orgReducer, userReducer, knowledgeReducer, inquiryReducer, componentReducer, guiReducer, kind} = this.props;

    const params = new URLSearchParams(window.location.search);
    const searchString = 'organization=' + this.props.org_id + '&' + new URLSearchParams(params).toString();
    const query = params.get('query');
    const kinds = (params.get('kinds') || "").split('+').filter(k => k.length > 0);

    // grab the results from the sharedReducer
    let searchResults = sharedReducer.searchRequests[searchString];

    return <div className="margin-bottom-3rem">
      <form className="list-left" onSubmit={this.searchFormSubmit}>
        <div>
          <CustomField
            inline={true}
            minWidth={500}
            name="search"
            id="wbsearch"
            disabled={sharedReducer.tryingToSearch}
            placeholder="search for agents, knowledge base, projects, endpoints..."
            value={this.state.query}
            onChange={e => this.setState({query: e.value})}
            />
        </div>
        <div>
        <CustomSelect
          inline={true}
          minWidth={200}
          multiSelect={true}
          placeholder="Search only..."
          value={this.state.kinds}
          disabled={sharedReducer.tryingToSearch}
          onChange={e => {
            this.setState({kinds: e});
          }}
          options={[
            {
              value: 'projects', 
              label: <span><i className="fal fa-fw fa-telescope text-inquiries icon-before-text"/> Projects</span>
            },
            {
              value: 'agents', 
              label: <span><i className="fal fa-fw fa-robot text-components icon-before-text"/> Agents</span>
            },
            {
              value: 'knowledge', 
              label: <span><i className="fal fa-fw fa-book-spells text-knowledge icon-before-text"/> Knowledge Bases</span>
            },
            {
              value: 'endpoints', 
              label: <span><i className="fal fa-fw fa-globe text-projects icon-before-text"/> Endpoints</span>
            },
          ]}
          />
        </div>
        <CustomButton
          size="small"
          thinking={sharedReducer.tryingToSearch}
          display={<span><i className="far fa-search icon-before-text"/>Search</span>}
          color="black"
          onClick={this.searchFormSubmit}
          />
      </form>
      <hr/>
      {
        searchResults ?
        <div>
        {
          searchResults.trying ? <p>
            <i className="far fa-spinner-third fa-spin icon-before-text"/> Searching...
          </p>
          :
          searchResults.results.nbHits === 0 ?
          <p>
            No results found for <strong>{query}</strong>.
          </p>
          :          
          <div>
            <p>
              Found {searchResults.results.nbHits} results for <strong>{query}</strong>:
            </p>
            <div className="row">
            {
              searchResults.results.hits.map(hit => {
                return <div className="col-md-3" key={hit.id}>
                  <Hydrate type={hit.collectionName} id={hit.id} mode="thumbnail"/>
                </div>
              })
            }
            </div>

            <div className="list-center">
              {
                searchResults.results.nbPages > 1 ?
                searchResults.results.nbPages > searchResults.results.page ?
                <CustomButton
                  size="small"
                  display={<span>Next Page</span>}
                  color="grey"
                  thinking={sharedReducer.tryingToSearch}
                  onClick={(e) => {
                    this.setState({page: searchResults.results.page + 1});
                    setTimeout(() => {
                      this.searchFormSubmit(e);
                    }, 100);
                  }}
                  />
                :
                <CustomButton
                  size="small"
                  display={<span>Previous Page</span>}
                  color="grey"
                  thinking={sharedReducer.tryingToSearch}
                  onClick={(e) => {
                    this.setState({page: searchResults.results.page - 1});
                    setTimeout(() => {
                      this.searchFormSubmit(e);
                    }, 100);
                  }}
                  />
                :
                <p>
                  <small className="text-muted">No additional results to show.</small>
                </p>
              }
            </div>
          </div>
        }
        </div>
        :
        <p className="text-muted">
          <i>You haven't searched for anything yet.</i>
        </p>
      }
    </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, sharedReducer, orgReducer, projectReducer, guiReducer, knowledgeReducer, inquiryReducer, componentReducer  } = state;

  return {
    userReducer,
    sharedReducer,
    orgReducer,
    projectReducer,
    guiReducer, 
    knowledgeReducer, 
    inquiryReducer, 
    componentReducer
  }
}

export default connect(mapStateToProps)(WorkbenchSearch);
