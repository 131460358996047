import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import shortid from 'shortid'

import Dropdown from 'kit/components/Dropdown/Dropdown.js'

import MegaMarkdown from 'components/MegaMarkdown/MegaMarkdown';
import CodeHighlighter from 'components/CodeHighlighter/CodeHighlighter';
import Hydrate from 'components/Hydrate/Hydrate';

import './ComponentChat.scss';

class ComponentChat extends Component {
  constructor(props){
    super(props);


    this.state = {
      showBackOfHouse: false,
      input: "",
      messages: props.initialData || [],
      lastUpdate: 0
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  handleSendMessage(e){
    if(e && e.preventDefault) e.preventDefault();

    if(this.state.input.trim().length > 0){

      let messages = this.state.messages;

      if(messages.length > 1 && (messages[messages.length - 1].tool_calls || []).find(t => t.type === 'function')){
        let tool_call = (messages[messages.length - 1].tool_calls || []).find(t => t.type === 'function');
        messages.push({
          id: shortid.generate(),
          role: 'tool',
          tool_call_id: tool_call.id,
          name: tool_call.function.name,
          content: this.state.input.trim(),
          timestamp: new Date().toISOString()
        })
      } else {
        messages.push({
          id: shortid.generate(),
          role: 'user',
          content: this.state.input.trim(),
          timestamp: new Date().toISOString()
        })
      }

      this.setState({
        input: "",
        showProcessing: false,
        messages: messages,
      })


      this.props.processData({
        data: {
          messages: messages,
          variables: this.props.variables
        }
      })

      setTimeout(()=>{
        this.setState({
          showProcessing: true
        })
      }, 1000)

      setTimeout(()=>{
        var objDiv = document.getElementById("component-chat-convo");
        if(objDiv) objDiv.scrollTop = objDiv.scrollHeight;
      }, 100);
    }
  }

  handleKeyUp(e){
    const { dispatch } = this.props;

    if(e.key === "Enter" && !e.shiftKey){
      this.handleSendMessage();
    }
  }

  componentWillReceiveProps(newprops){
    
    if(newprops.processResponse && newprops.processResponse.verbose && newprops.processResponse.agent.id === this.props.component.id){
      if(!this.state.lastPacketReceived || newprops.processResponse.verbose.timestamp !== (this.state.lastPacketReceived.verbose || {}).timestamp){
        let messages = this.state.messages;

        let new_message = {...newprops.processResponse.output_data};
        if(!new_message.partial_process){
          // console.log('new_message', new_message);
          new_message.fullResponsePacket = newprops.processResponse;

          messages.push(new_message);

          this.setState({
            messages: messages,
            lastUpdate: 0,
            lastPacketReceived: newprops.processResponse
          })
          var objDiv = document.getElementById("component-chat-convo");
          if(objDiv) objDiv.scrollTop = objDiv.scrollHeight;
        }
      }
    } else if(newprops.componentReducer.demoComponentStreamError && newprops.componentReducer.demoComponentStreamError !== this.props.componentReducer.demoComponentStreamError){
      let messages = this.state.messages;
      let lastMessage = messages[messages.length - 1];
      lastMessage.failed = true;
      this.setState({
        lastUpdate: 0,
        messages: messages
      })
      var objDiv = document.getElementById("component-chat-convo");
      if(objDiv) objDiv.scrollTop = objDiv.scrollHeight;
    } else if(newprops.componentReducer.demoComponentForm.errors.message && newprops.componentReducer.demoComponentForm.errors.message !== this.props.componentReducer.demoComponentForm.errors.message){
      let messages = this.state.messages;
      let lastMessage = messages[messages.length - 1];
      lastMessage.failed = true;
      this.setState({
        messages: messages
      })
      var objDiv = document.getElementById("component-chat-convo");
      if(objDiv) objDiv.scrollTop = objDiv.scrollHeight;
    }

    // monitor props.demoResponseProgress for changes and if we are already within 100px of the bottom of the div, scroll to the bottom
    if(newprops.demoResponseProgress && newprops.demoResponseProgress.length !== this.props.demoResponseProgress.length){
      var objDiv = document.getElementById("component-chat-convo");
      if(objDiv){
        if(objDiv.scrollHeight - objDiv.scrollTop < objDiv.clientHeight + 100){
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Update state after re-render if necessary
    this.setState({ lastUpdate: Date.now() });
  }

  componentDidMount(){
    var objDiv = document.getElementById("component-chat-convo");
    if(objDiv) objDiv.scrollTop = objDiv.scrollHeight;

    const component = this.props.component;
    const loadedVersion = this.props.loadedVersion;
    let welcomeMessages = [];
    
    if(loadedVersion.few_shots){
      loadedVersion.few_shots.sort((a,b)=>{
        if(a.created_at < b.created_at) return -1;
        if(a.created_at > b.created_at) return 1;
      })

      loadedVersion.few_shots.sort((a,b)=>{
        if(a.order < b.order) return -1;
        if(a.order > b.order) return 1;
      })

      for(var i in loadedVersion.few_shots){
        if(loadedVersion.few_shots[i].deleted) continue;
        for(var j in loadedVersion.few_shots[i].messages){
          if(loadedVersion.few_shots[i].messages[j].welcome_message){
            welcomeMessages.push(loadedVersion.few_shots[i].messages[j]);
          }
        }
      }
    }

    this.setState({
      messages: welcomeMessages
    });
  }

  handleInputChange(e){
    let value = e.target.value;

    this.setState({
      input: value
    })
  }


  shouldComponentUpdate(nextProps, nextState) {
    const now = Date.now();
    
    if (this.state.input !== nextState.input || now - this.state.lastUpdate > 100) {
      return true; // Allow re-render
    }
    return false; // Prevent re-render
  }

  render(){
    // console.log('rerender');

    const { userReducer } = this.props;

    const messages = this.state.messages;

    const component = this.props.component;

    const possibleBotRoles = ['agent', 'assistant'];

    return <div className="component-chat">

        <div className="component-chat-inner">
          <div className="component-chat-convo" id="component-chat-convo">
            {
              messages.map((m, i)=>{

                let doubleSend = false;
                if(i > 0){
                  if(m.role == messages[i-1].role && m.timestamp - messages[i-1].timestamp < 1000*60){
                    doubleSend = true;
                  }
                }
                
                

                return <div key={i} className="component-chat-row-wrapper">
                  <div className={"component-chat-row " + (possibleBotRoles.includes(m.role)? "component-chat-row-bot" : "component-chat-row-user")}>
                    <div className={"flex-grow list-left"}>
                      {
                        (m.role === 'user' || m.role === 'tool') ? 
                        <Hydrate type="user" id={userReducer.myData ? userReducer.myData.id : ""} mode="avatar" size={25}/>
                        :
                        <Hydrate type="components" id={component.id} mode="avatar" size={25}/>
                      }
                      {
                        (m.role === 'user') ? <span className="text-900">{userReducer.myData ? userReducer.myData.display_name : "You"}</span>
                        :
                        (m.role === 'tool') ? <span className="text-900">Simulated Tool Response</span>
                        :
                        <span className="text-900">{component.display_name}</span>
                      }
                      {
                        (m.role === 'user' && m.failed) && <small className="text-danger"><i className="fal fa-exclamation-triangle"/> Failed to Respond</small>
                      }
                      
                      <Dropdown
                        align={"left"}
                        items={[
                            m.failed ? <span 
                              onClick={()=>{
                                // find this message and unmark it as failed by index
                                let messages = this.state.messages;
                                messages[i].failed = false;
                                this.setState({
                                  messages: messages
                                })

                                this.props.processData({
                                  data: {
                                    messages: this.state.messages,
                                    variables: this.state.variables
                                  }
                                });
                              }}
                              className="text-primary"
                              >
                              <div className="flex-split">
                                <span>Resend Message</span><i className="fal fa-paper-plane"></i>
                              </div>
                            </span> : undefined
                            ,
                            m.failed ? "divider" : undefined
                            ,
                            (m.role !== "user" && !m.welcome_message && this.props.onInspectBackOfHouse) ?
                            <span 
                              className=""
                              onClick={()=>{
                                if(this.props.onInspectBackOfHouse){
                                  this.props.onInspectBackOfHouse(m.fullResponsePacket);
                                }
                              }}>
                              <div className="flex-split">
                                <span>Inspect API Response</span><i className="fal fa-brackets-curly"></i>
                              </div>
                            </span> : undefined,
                            <span 
                              className=""
                              onClick={()=>{
                                navigator.clipboard.writeText(m.content);
                              }}>
                              <div className="flex-split">
                                <span>Copy text</span><i className="fal fa-copy"></i>
                              </div>
                            </span>,

                            (m.role === "user" || m.role === "tool") ? 
                            <span 
                              className=""
                              onClick={()=>{
                                this.setState({
                                  messages: this.state.messages.slice(0, i),
                                  input: m.content
                                })
                              }}>
                              <div className="flex-split">
                                <span>Rewind to here</span><i className="fal fa-backward"></i>
                              </div>
                            </span>
                            : undefined
                            ,


                            <span
                              className=" text-hover-danger"
                              onClick={()=>{
                                let messages = this.state.messages;
                                messages.splice(i, 1);
                                this.setState({
                                  messages: messages
                                })
                              }}
                              >
                              <div className="flex-split">
                                <span>Delete Message</span><i className="fal fa-times"></i>
                              </div>
                            </span>
                          ]}
                        target={
                            <div className={"component-chat-message-time" + (m.faded ? " component-chat-message-time-faded" : "")}>
                              <span >{moment(m.timestamp).format('h:mm a')}</span> <i className="far fa-xs fa-fw fa-angle-down"></i>
                            </div>
                          }
                        />
                      
                      {
                        (possibleBotRoles.includes(m.role) && m.failed) && <small className="text-danger"><i className="fal fa-exclamation-triangle"/> Failed to Respond</small>
                      }
                    </div>
                  </div>
                  <div className={"component-chat-row " + (possibleBotRoles.includes(m.role)? "component-chat-row-bot" : "component-chat-row-user")}>
                    <div className={"component-chat-message " + (possibleBotRoles.includes(m.role)? "component-chat-message-bot" : "component-chat-message-user")}>
                      
                      {
                        (m.content && !(m.role === 'function' || m.role === 'tool')) &&
                        <div className={"component-chat-message-body " + (doubleSend ? "component-chat-message-body-double-send" : "") + (m.faded ? " component-chat-message-body-faded" : "")}>
                          {
                            (m.content && 
                              <MegaMarkdown text={m.content.trim()} />
                            )
                          }
                        </div>
                      }


                      {
                        (m.role === 'function' || m.role === 'tool') && 
                        <div className="component-chat-message-body">
                          <div className="component-chat-function component-chat-function-user">
                            <div className="flex-split">
                              <div className="component-chat-function-name">
                                {m.content}
                              </div>
                            </div>
                          </div>
                        </div>
                      } 


                      {
                        (m.tool_calls || []).map((tool_call, j) => { 

                          let parsedArguments;
                          if(tool_call.type === 'function' && tool_call.function.arguments){
                            parsedArguments = tool_call.function.arguments;
                            try{
                              parsedArguments = JSON.parse(tool_call.function.arguments);
                            } catch(e){
                              console.error('failed to parse arguments', e);
                            }
                          }

                          if(tool_call.type === 'function'){
                            return <div className="component-chat-message-body">
                              <div className="component-chat-function component-chat-function-bot" key={j}>
                                <div className="flex-split margin-bottom-2rem">
                                  <div className="component-chat-function-name">
                                    {tool_call.function.name}()
                                  </div>
                                </div>
                                <CodeHighlighter
                                  language="json"
                                  code={tool_call.function.arguments}
                                  />
                              </div>
                            </div>
                          }
                        })
                      }

                    </div>
                    
                  </div>
                  
                </div>
              })
            }


            {
              (this.props.processing && this.state.showProcessing) && <div className="component-chat-row-wrapper">
                <div className={"component-chat-row component-chat-row-bot"}>
                  <div className={"flex-grow list-left"}>
                    <Hydrate type="components" id={component.id} mode="avatar" size={25}/>
                    <span className="text-900">{component.display_name}</span>
                  </div>
                </div>
                <div className={"component-chat-row component-chat-row-bot"}>
                  <div className={"component-chat-message component-chat-message-bot"}>
                    <div className={"component-chat-message-body"}>
                      {
                        this.props.demoResponseConcat.length > 0 ?
                        <span>
                          <MegaMarkdown text={this.props.demoResponseConcat} />
                        </span>
                        :
                        this.props.demoResponseProgress.filter(p => p.event).length > 0 ?
                        <span className="text-muted">
                          <i>{this.props.demoResponseProgress.filter(p => p.event).reverse()[0].event}</i>
                        </span>
                        :
                        <i className="fad fa-spinner-third fa-spin text-muted"/>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          <form onSubmit={this.handleSendMessage} className="component-chat-form">
            <textarea
              id="component-chat-input"
              className={"component-chat-input " + ((messages.length > 0 && (messages[messages.length - 1].tool_calls || []).find(t => t.type === 'function')) ? "text-monospaced" : "")}
              onChange={this.handleInputChange}
              value={this.state.input}
              placeholder={(messages.length > 0 && (messages[messages.length - 1].tool_calls || []).find(t => t.type === 'function')) ? "Simulate the returned data from this function..." : "Chat with this agent..."}
              onKeyUp={this.handleKeyUp} 
              autoFocus
            />
            <button 
              submit="true"
              className={("component-chat-send " + ((this.state.input.length > 0 || this.state.processing) ? "" : "component-chat-send-disabled"))}
              >
                {
                  this.props.processing ? 
                  <i className="fad fa-spinner-third fa-spin"/>
                  :
                  <i className="fas fa-arrow-up"/>
                }
            </button>
          </form>
          
        </div>   
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer  } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(ComponentChat);
