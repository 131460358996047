import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { push } from 'react-router-redux';

import TabNav from '../../kit/components/TabNav/TabNav'
import Callout from '../../kit/components/Callout/Callout.js'
import CustomButton from '../../kit/components/CustomButton/CustomButton'
import Modal from '../../kit/components/Modal/Modal.js'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect'
import CustomField from '../../kit/components/CustomField/CustomField'

import Hydrate from 'components/Hydrate/Hydrate'

import {
  tryToGetProject,
  tryToGetComponent,
  tryToGetKnowledge,
  tryToGetInquiry,
  showTooltip,
  hideTooltip,
  toggleKindTools,
  tryToPublishComponent,
  tryToSearchInformation
} from '../../actions/actions.export'

import projectTools from '../../configs/config.project-tools'
import componentTools from '../../configs/config.component-tools'
import knowledgeTools from '../../configs/config.knowledge-tools'
import inquiryTools from '../../configs/config.inquiry-tools'
import isFeatureSwitchOn from 'utilities/isFeatureSwitchOn';

import nameConfig from 'configs/config.names'

import socketClientManager from 'sockets/socketClientManager';


class WorkbenchKind extends Component {
  constructor(props){
    super(props);

    this.state = {
      requestLaunched: false,
      showProjectSwitcherModal: false,
      showPublishComponentModal: false,
      lastPublishSuccess: false,
    }
  }
  

  componentWillMount(){
    const { dispatch, componentReducer } = this.props;

    switch(this.props.kind){
      case 'projects':
        dispatch(tryToGetProject({id: this.props.kind_id}));
        break;
      case 'components':
        dispatch(tryToGetComponent({id: this.props.kind_id}));
        break;
      case 'knowledge':
        dispatch(tryToGetKnowledge({id: this.props.kind_id}));
        break;
      case 'inquiries':
        dispatch(tryToGetInquiry({id: this.props.kind_id}));
        break;
    }

    this.setState({
      requestLaunched: true
    });

    socketClientManager.joinRoom({
      type: this.props.kind,
      id: this.props.kind_id
    });
  }

  componentWillUnmount(){
    socketClientManager.leaveRoom({
      type: this.props.kind,
      id: this.props.kind_id
    });
  }

  componentDidMount(){
    window.document.title = "Workbench - zerowidth";

    socketClientManager.emitEvent('updateCursor', {
      type: this.props.kind,
      id: this.props.kind_id,
      cursor: {
        kind: this.props.kind,
        kind_id: this.props.kind_id,
        page: this.props.page,
      }
    });
  }

  componentWillReceiveProps(newprops){
    const { dispatch, projectReducer, componentReducer, knowledgeReducer, inquiryReducer } = newprops;

    if(this.state.requestLaunched){
      switch(this.props.kind){
        case 'projects':
          if(projectReducer.ready && projectReducer.cache[this.props.kind_id] === undefined && !projectReducer.tryingToGetProject  && !projectReducer.tryingToGetProjectsByOrganization) dispatch(push('/oops'));
          break;
        case 'components':
          if(componentReducer.ready && componentReducer.cache[this.props.kind_id] === undefined && !componentReducer.tryingToGetComponent  && !componentReducer.tryingToGetComponentsByOrganization) dispatch(push('/oops'));

          if(componentReducer.cache[this.props.kind_id] && (componentReducer.cache[this.props.kind_id].projects || []).length === 1 && !newprops.nestedBy){
            // if this project exists in your cache, redirect to it
            if(projectReducer.cache[componentReducer.cache[this.props.kind_id].projects[0].id]){
              let url = '/workbench/' + this.props.org_id + '/projects/' + componentReducer.cache[this.props.kind_id].projects[0].id + '/agents/' + this.props.kind_id;
              if(this.props.page) url += '/' + this.props.page;
              if(this.props.subpage) url += '/' + this.props.subpage;
              if(this.props.subpage2) url += '/' + this.props.subpage2;
              dispatch(push(url));
            }
          }
          break;
        case 'knowledge':
          if(knowledgeReducer.ready && knowledgeReducer.cache[this.props.kind_id] === undefined && !knowledgeReducer.tryingToGetKnowledge  && !knowledgeReducer.tryingToGetKnowledgeByOrganizations) dispatch(push('/oops'));

          if(knowledgeReducer.cache[this.props.kind_id] && (knowledgeReducer.cache[this.props.kind_id].projects || []).length === 1 && !newprops.nestedBy){
            // if this project exists in your cache, redirect to it
            if(projectReducer.cache[knowledgeReducer.cache[this.props.kind_id].projects[0].id]){
              let url = '/workbench/' + this.props.org_id + '/projects/' + knowledgeReducer.cache[this.props.kind_id].projects[0].id + '/knowledge/' + this.props.kind_id;
              if(this.props.page) url += '/' + this.props.page;
              if(this.props.subpage) url += '/' + this.props.subpage;
              if(this.props.subpage2) url += '/' + this.props.subpage2;
              dispatch(push(url));
            }
          }
          break;
        case 'inquries':
          if(inquiryReducer.ready && inquiryReducer.cache[this.props.kind_id] === undefined && !inquiryReducer.tryingToGetInquiry  && !inquiryReducer.tryingToGetInquiriesByOrganizations) dispatch(push('/oops'));
          break;
      }
    }

    // if kind changes or kind_id changes, update state, leave the old room and join the new
    if(this.state.kind !== newprops.kind || this.state.kind_id !== newprops.kind_id){

      socketClientManager.leaveRoom({
        type: this.props.kind,
        id: this.props.kind_id
      });

      socketClientManager.joinRoom({
        type: newprops.kind,
        id: newprops.kind_id
      });

      this.setState({
        kind: newprops.kind,
        kind_id: newprops.kind_id
      });
    }

    // if page changes, update state and emit the event
    if(this.state.page !== newprops.page){

      socketClientManager.emitEvent('updateCursor', {
        type: this.props.kind,
        id: this.props.kind_id,
        cursor: {
          kind: newprops.kind,
          kind_id: newprops.kind_id,
          page: newprops.page,
        }
      });

      this.setState({
        page: newprops.page
      });
    }

    // if theres been a new success publish, close the modal
    if(newprops.componentReducer.publishComponentSuccess && this.state.lastPublishSuccess !== newprops.componentReducer.publishComponentSuccess){
      this.setState({
        showPublishComponentModal: false,
        lastPublishSuccess: newprops.componentReducer.publishComponentSuccess
      });
    }

  }


  render(){

    const { dispatch, projectReducer, componentReducer, knowledgeReducer, inquiryReducer, orgReducer, userReducer, postReducer, intelReducer, featuresReducer, guiReducer, sharedReducer } = this.props;

    let item;
    let tools;
    let reducer;
    let kindURL;
    let kindSingular;
    let kindPlural;
    let kindIcon;

    const loadingRetval = <div className="workbench-component padding-2rem">
      <i className="fad fa-fw fa-spinner-third fa-spin fa-2x text-muted"/>
    </div>;

    let mode = 'root';

    switch(this.props.kind){
      case 'projects':
        item = projectReducer.cache[this.props.kind_id];
        tools = projectTools;
        reducer = projectReducer;
        break;
      case 'components':
        item = componentReducer.cache[this.props.kind_id];
        tools = componentTools;
        reducer = componentReducer;
        mode = 'nested';
        if(item && !item.versions) return loadingRetval;
        break;
      case 'knowledge':
        item = knowledgeReducer.cache[this.props.kind_id];
        tools = knowledgeTools;
        reducer = knowledgeReducer;
        mode = 'nested';
        break;
      case 'inquiries':
        item = inquiryReducer.cache[this.props.kind_id];
        tools = inquiryTools;
        reducer = inquiryReducer;
        break;
    }


    kindURL = nameConfig[this.props.kind].url;
    kindSingular = nameConfig[this.props.kind].singular;
    kindPlural = nameConfig[this.props.kind].plural;
    kindIcon = nameConfig[this.props.kind].icon;


    let enabledTools = tools.filter(t => !t.disabled).filter(t => !t.hideIf || !t.hideIf(item || {}));
    let tool = enabledTools[0];
    let subpageObject;

    
    let org_id;
    let membership;

    if(this.props.page && item){

      org_id = item.scope;
      membership = orgReducer.memberships.find(m => m.organization.id === org_id);
      if(membership){
        membership = membership.membership;
      }

      if(this.props.page === '_'){
        tool = enabledTools.find(t => t.name === undefined);
        if(!tool) return loadingRetval;

        subpageObject = (item[tool.list_name] || []).find(v => v.id === this.props.subpage);

      } else {
        tool = enabledTools.find(t => t.name === this.props.page);
        if(!tool) return loadingRetval;

        if(tool && this.props.subpage && tool.subpages && item){
          tool = tool.subpages.find(t => t.name === this.props.subpage);
        } else if(tool.dynamicSubpages && item){
          subpageObject = (item[tool.name] || []).find(v => v.id === this.props.subpage);
        }
      }
    }


    let canRead = tool.roleless || false;
    let canWrite = false;


    if(item){

      if(item.visibility === 'org' && tool.roleRequired !== 'admin'){
        canRead = true;
      }

      if(item.editability === 'org' && tool.roleRequired !== 'admin'){
        canRead = true;
        canWrite = true;
      }

      if(membership && membership.manage_content && this.props.page === 'settings'){
        canRead = true;
        canWrite = true;
      }

      if(item.myRole){
        // new role system
        if(item.myRole.role && this.props.kind !== 'projects'){
          if(item.myRole.role === 'admin'){
            canRead = true;
            canWrite = true;
          } else if(item.myRole.role === 'read'){
            canRead = true;
          } else if(item.myRole.role === 'write'){
            canRead = true;
            canWrite = true;
          }
        } else if(item.myRole && item.myRole.roles){
          // legacy and projects

          if(item.myRole.admin){
            canRead = true;
            canWrite = true;
          } else if(item.myRole.roles[tool.name]){
            
            if(item.myRole.roles[tool.name] === 'read'){
              canRead = true;
            } else if(item.myRole.roles[tool.name] === 'write'){
              canRead = true;
              canWrite = true;
            }
          } else {
            if(item.myRole.roles['_'] === 'write'){
              canWrite = true;
            }
          }

          if(item.visibility === 'org'){
            canRead = true;
          }

          if(tool.name === 'settings' && item.created_by === userReducer.myData.id){
            canWrite = true;
          }

        } else {
          return loadingRetval;
        }
      } else {
        return loadingRetval;
      }
    } else {
      return loadingRetval;
    }


    const readOnlyCallout = <Callout 
      key={tool.name}
      mini={true} 
      style="muted" 
      content={
        <div className="flex-split margin-left-05rem">
          <span>You have <span className="text-900">read-only</span> access to this area.</span>
        </div>
      }/>


    // detect the different categories of tools
    let categories = [];
    tools.forEach(t => {
      if(t.category !== undefined && !categories.includes(t.category)){
        categories.push(t.category);
      }
    });


    const getCursorsForTool = (t) => {
      let cursors = [];

      let user_ids = Object.keys(sharedReducer.cursorCache[this.props.kind + '-' + this.props.kind_id] || {});

      // if you are the only one in this list, return empty
      if(user_ids.length === 1 && user_ids[0] === userReducer.myData.id){
        return [];
      }

      user_ids.forEach(u => {
        let cursor = sharedReducer.cursorCache[this.props.kind + '-' + this.props.kind_id][u];
        if(cursor && cursor.page === t.name){
          cursors.push(u);
        }
      });

      return cursors;
    }

    const getCursorsForRoom = () => {
      let cursors = [];

      let user_ids = Object.keys(sharedReducer.cursorCache[this.props.kind + '-' + this.props.kind_id] || {});

      // if you are the only one in this list, return empty
      if(user_ids.length === 1 && user_ids[0] === userReducer.myData.id){
        return [];
      }

      user_ids.forEach(u => {
        let cursor = sharedReducer.cursorCache[this.props.kind + '-' + this.props.kind_id][u];
        if(cursor){
          cursors.push(u);
        }
      })

      return cursors;
    }

    // determine if we need to hide things due to width breakpoint
    // 1 = mobile, 2 = tablet, 3 = desktop
    let breakpoint = 3
    if(window.innerWidth < 1200){
      breakpoint = 2;
    }
    if(window.innerWidth < 768){
      breakpoint = 1;
    }


    return <div className="workbench-component">
      <Modal
        show={this.state.showProjectSwitcherModal}
        exitable={true}
        onExit={() => {
          this.setState({
            showProjectSwitcherModal: false
          })
        }}
        cancelable={true}
        onCancel={() => {
          this.setState({
            showProjectSwitcherModal: false,
            newNestedBy: null
          })
        }}
        acceptable={true}
        onAccept={() => {

          if(this.props.kind === 'components'){
            dispatch(push('/workbench/' + this.props.org_id + '/projects/' + this.state.newNestedBy + '/agents/' + item.id));
          } else if(this.props.kind === 'knowledge'){
            dispatch(push('/workbench/' + this.props.org_id + '/projects/' + this.state.newNestedBy + '/knowledge/' + item.id));
          } 
          window.location.reload();

        }}
        acceptButtonLabel="Switch Views"
        content={<div className="">
            <h4 className="no-margin">
              <i className="far fa-robot icon-before-text text-projects"/>Switch Project View
            </h4>
            <p>
              To view and edit this content within a particular project, select it from the list below.
            </p>
            <CustomSelect
              label="Project"
              large={true}
              value={this.state.newNestedBy || this.props.nestedBy}
              placeholder="Select a project..."
              onChange={(v) => {
                this.setState({
                  newNestedBy: v
                })
              }}
              options={(item.projects || []).map(p => {
                return {
                  value: p.id,
                  label: <div className="text-ellipsis-2-lines">
                    <strong>{p.display_name}</strong><br/>
                    <small>
                      {p.description}
                    </small>
                  </div>
                }
              })}
              />
            <p className="thin-line-height">
              <small>
                <strong>Note:</strong> Switching projects will reload the page and may cause you to lose unsaved changes.
              </small>
            </p>
          </div>
        }
        />

      <Modal
        show={this.state.showPublishComponentModal}
        exitable={true}
        onExit={() => {
          this.setState({
            showPublishComponentModal: false
          })
        }}
        cancelable={true}
        onCancel={() => {
          this.setState({
            showPublishComponentModal: false
          })
        }}
        acceptable={true}
        onAccept={() => {
          dispatch(tryToPublishComponent({
            id: item.id
          }))
        }}
        acceptButtonLabel="Publish"
        acceptButtonThinking={componentReducer.tryingToPublishComponent}
        acceptButtonFailed={componentReducer.publishComponentFail}
        acceptButtonSuccess={componentReducer.publishComponentSuccess}
        content={<div className="">
            <h4 className="no-margin">
              Publish Component
            </h4>
            <p>
              Are you sure you want to publish this agent? Once published, it will automatically be the version used via API calls.
            </p>
          </div>
        }/>



        <div className="flex-split flex-split-stretch flex-grow">
          {
            mode === 'root' ? (
            
            guiReducer.collapsedKindTools ?
            <div className="workbench-body-padding workbench-kind-left-menu no-padding flex-column-split" style={{minWidth: '40px', width: '40px'}}>
                <div className="workbench-kind-left-menu-inner">
                  <div>
                  {
                    categories.length <= 1 ?
                  
                    <TabNav
                      onChange={(v)=>{
                        if(v){
                          dispatch(push('/workbench/' + this.props.org_id + '/' + kindURL + '/' + item.id + "/" + v));
                        } else {
                          dispatch(push('/workbench/' + this.props.org_id + '/' + kindURL + '/' + item.id));
                        }
                      }}
                      value={tool.name}
                      vertical={true}
                      theme={this.props.kind}
                      items={tools.map(t => {
                        return {
                          value: t.name,
                          onMouseEnter: e => {
                            dispatch(showTooltip({
                              el: e.target,
                              nobr: false,
                              position: 'right',
                              content: <div style={{maxWidth: 250}} className="text-400 ">
                                <span className="text-900 no-margin">{t.display_name}</span>
                                <p className="thin-line-height no-margin">
                                  <small>
                                    {t.description}
                                  </small>
                                </p>
                                {
                                  cursors.length > 0 && <div className="">
                                    <hr/>
                                    <div className="margin-bottom-1rem">
                                      <small>Team members here:</small>
                                    </div>
                                    {
                                      cursors.map(c => {
                                        return <div key={c} className="margin-bottom-1rem">
                                          <Hydrate mode="lockup" type="user" id={c}  size={15}/>
                                        </div>
                                      })
                                    }
                                  </div>
                                }
                              </div>
                            }))
                          },
                          onMouseLeave: e => {
                            dispatch(hideTooltip())
                          },
                          display: <i className={"far fa-fw icon-before-text fa-" + t.display_icon}/>,
                          hideIf: t.hideIf ? t.hideIf(item) : false,
                          disabled: t.disabled || !item,
                        }
                      })}
                      />
                      :
                      (categories.map(c => {
                        return <div key={c} className="">
                          {
                            c !== "Overview" && <hr className="hr-none"/>
                          }
                          <TabNav
                            onChange={(v)=>{
                              if(v){
                                dispatch(push('/workbench/' + this.props.org_id + '/' + kindURL + '/' + item.id + "/" + v));
                              } else {
                                dispatch(push('/workbench/' + this.props.org_id + '/' + kindURL + '/' + item.id));
                              }
                            }}
                            value={tool.name}
                            vertical={true}
                            theme={tools.find(t => t.name === this.props.page).tab_nav_theme || this.props.kind}
                            items={tools.filter(t => t.category === c).map(t => {

                              let cursors = getCursorsForTool(t);

                              return {
                                value: t.name,
                                onMouseEnter: e => {
                                  dispatch(showTooltip({
                                    el: e.target,
                                    nobr: false,
                                    position: 'right',
                                    content: <div style={{maxWidth: 250}} className="text-400 ">
                                      <span className="text-900 no-margin">{t.display_name}</span>
                                      <p className="thin-line-height no-margin">
                                        <small>
                                          {t.description}
                                        </small>
                                      </p>
                                      {
                                        cursors.length > 0 && <div className="">
                                          <hr/>
                                          <div className="margin-bottom-1rem">
                                            <small>Team members here:</small>
                                          </div>
                                          {
                                            cursors.map(c => {
                                              return <div key={c} className="margin-bottom-1rem">
                                                <Hydrate mode="lockup" type="user" id={c}  size={15}/>
                                              </div>
                                            })
                                          }
                                        </div>
                                      }
                                    </div>
                                  }))
                                },
                                onMouseLeave: e => {
                                  dispatch(hideTooltip())
                                },
                                display: <i className={(this.props.page === t.name ? "fas" : "far") + " fa-fw icon-before-text fa-" + t.display_icon}/>,
                                hideIf: t.hideIf ? t.hideIf(item) : false,
                                disabled: t.disabled,
                                // autoIcon: "far fa-" + t.display_icon,
                              }
                            })}
                            /> 
                        </div>
                      }))
                  }
                </div>
                <div className="workbench-kind-collapse-button" onClick={()=>{
                  dispatch(toggleKindTools(false))
                  }}>
                  <i className="fal fa-angle-double-right"/>
                </div>
              </div>
            </div>
            :
            <div className="workbench-kind-left-menu flex-column-split" style={{minWidth: '225px', width: '225px'}}>
              <div className="workbench-kind-left-menu-inner">
                <div className="">
                  {
                    categories.length <= 1 ?
                  
                    <TabNav
                      onChange={(v)=>{
                        if(v){
                          dispatch(push('/workbench/' + this.props.org_id + '/' + kindURL + '/' + item.id + "/" + v));
                        } else {
                          dispatch(push('/workbench/' + this.props.org_id + '/' + kindURL + '/' + item.id));
                        }
                      }}
                      value={tool.name}
                      vertical={true}
                      theme={this.props.kind}
                      items={tools.map(t => {

                        let cursors = getCursorsForTool(t);

                        return {
                          value: t.name,
                          onMouseEnter: e => {
                            dispatch(showTooltip({
                              el: e.target,
                              lag: 2000,
                              nobr: false,
                              position: 'right',
                              content: <div style={{maxWidth: 250}} className="text-400">
                                <span className="text-900 no-margin">{t.display_name}</span>
                                <p className="thin-line-height no-margin">
                                  <small>
                                    {t.description}
                                  </small>
                                </p>
                                {
                                  cursors.length > 0 && <div className="">
                                    <hr/>
                                    <div className="margin-bottom-1rem">
                                      <small>Team members here:</small>
                                    </div>
                                    {
                                      cursors.map(c => {
                                        return <div key={c} className="margin-bottom-1rem">
                                          <Hydrate mode="lockup" type="user" id={c}  size={15}/>
                                        </div>
                                      })
                                    }
                                  </div>
                                }
                              </div>
                            }))
                          },
                          onMouseLeave: e => {
                            dispatch(hideTooltip())
                          },
                          display: <div className="flex-split flex-grow margin-right-1rem">
                            <span>{t.display_name}</span>
                            <div>
                              {
                                cursors.length > 0 && <div className="overlapping-avatar-list">
                                  
                                  {
                                    cursors.slice(0, 2).map(c => {
                                      return <Hydrate mode="avatar" type="user" id={c} key={c} size={20}/>
                                    })
                                  }
                                  {
                                    cursors.length > 2 && <span className="workbench-tool-menu-counter text-gs5">+{cursors.length - 2}</span>
                                  }
                                </div>
                              }
                              { t.getCounter && <span className="workbench-tool-menu-counter text-gs5">{t.getCounter(item)}</span> }
                            </div>
                          </div>,
                          hideIf: t.hideIf ? 
                          t.hideIf(item) 
                          : 
                          t.featureSwitch ?
                          !isFeatureSwitchOn(t.featureSwitch, userReducer, featuresReducer)
                          :
                          false,
                          disabled: t.disabled || !item,
                          autoIcon: "far fa-" + t.display_icon
                        }
                      })}
                      />
                    :

                    (categories.map(c => {
                      return <div key={c} className="">
                        {
                          c !== "" && <div className="workbench-kind-category-label text-ellipsis-1-line">
                            <span>
                              {c === "overview" ? item.display_name : c}
                            </span>
                          </div>
                        }
                        <TabNav
                          onChange={(v)=>{
                            if(v){
                              dispatch(push('/workbench/' + this.props.org_id + '/' + kindURL + '/' + item.id + "/" + v));
                            } else {
                              dispatch(push('/workbench/' + this.props.org_id + '/' + kindURL + '/' + item.id));
                            }
                          }}
                          value={tool.name}
                          vertical={true}
                          theme={tools.find(t => t.name === this.props.page).tab_nav_theme || this.props.kind}
                          items={tools.filter(t => t.category === c).map(t => {
                            
                            let cursors = getCursorsForTool(t);

                            return {
                              value: t.name,
                              onMouseEnter: e => {
                                dispatch(showTooltip({
                                  el: e.target,
                                  nobr: false,
                                  position: 'right',
                                  content: <div style={{maxWidth: 250}} className="text-400 ">
                                    <span className="text-900 no-margin">{t.display_name}</span>
                                    <p className="thin-line-height no-margin">
                                      <small>
                                        {t.description}
                                      </small>
                                    </p>
                                    {
                                      cursors.length > 0 && <div className="">
                                        <hr/>
                                        <div className="margin-bottom-1rem">
                                          <small>Team members here:</small>
                                        </div>
                                        {
                                          cursors.map(c => {
                                            return <div key={c} className="margin-bottom-1rem">
                                              <Hydrate mode="lockup" type="user" id={c}  size={15}/>
                                            </div>
                                          })
                                        }
                                      </div>
                                    }
                                  </div>
                                }))
                              },
                              onMouseLeave: e => {
                                dispatch(hideTooltip())
                              },
                              display: <div className="flex-split flex-grow margin-right-1rem">
                                <div className="flex-grow flex-split">
                                  <span>{t.display_name}</span>
                                  {
                                    cursors.length > 0 && <div className="overlapping-avatar-list">
                                      
                                      {
                                        cursors.slice(0, 2).map(c => {
                                          return <Hydrate mode="avatar" type="user" id={c} key={c} size={15}/>
                                        })
                                      }
                                      {
                                        cursors.length > 2 && <span className="text-gs5 overlapping-avatar-list-more">+</span>
                                      }
                                    </div>
                                  }
                                </div>
                                <nobr>
                                  
                                  { t.getCounter && <span className="workbench-tool-menu-counter text-gs5">{t.getCounter(item)}</span> }
                                </nobr>
                              </div>,
                              hideIf: t.hideIf ? t.hideIf(item) : false,
                              disabled: t.disabled || !item,
                              autoIcon: (this.props.page === t.name ? "fas" : "far") + " fa-" + t.display_icon,
                            }
                          })}
                          />
                      </div>
                    }))
                  }
                </div>
                <div className="workbench-kind-collapse-button" onClick={()=>{
                    dispatch(toggleKindTools(true))
                  }}>
                  <i className="fal fa-angle-double-left icon-before-text"/>   
                </div>
              </div>
            
            </div>
          )
          :
          <div>
          </div>
        }
          
          <div className={"flex-grow workbench-kind-right-half relative " + (tool.noScroll ? "flex-column-stretch" : "scroll-parent")}>
            {
              mode === 'nested' && 
              <div className={"box workbench-nested-bookmark-nav box-no-pad box-no-border" + (guiReducer.draggingGUIState ? " text-muted no-pointer-events" : "")} style={{background: '#ffffff'}}>
                <div className="flex-split padding-1rem">
                  <div className="list-left list-left-no-wrap">
                    <i className={"fad fa-2x fa-fw fa-" + kindIcon + " icon-before-text text-" + this.props.kind}/>
                    <div className="margin-left-05rem">
                      <div className="list-left list-left-no-wrap">
                        <h4 className="no-margin-top no-margin-bottom text-ellipsis-1-lines">
                          <span>
                            {
                              item.display_name
                            }
                          </span>
                        </h4>
                        {
                          (this.props.kind === 'components' && !item.flow_mode) && 
                          <small 
                            className="text-tag text-tag-tiny text-tag-warning"
                            onMouseEnter={e => {
                              dispatch(showTooltip({
                                el: e.target,
                                position: 'bottom',
                                nobr: false,
                                content: <div className="text-400" style={{maxWidth: 300}}>
                                  <p className="no-margin">
                                    <small>
                                      This was created prior to the new flow mode system, so it may not be compatible with the latest features.
                                    </small>
                                  </p>
                                </div>
                              }))
                            }}
                            onMouseLeave={e => {
                              dispatch(hideTooltip())
                            }}
                            >
                            LEGACY AGENT
                          </small>
                        }
                      </div>
                      <div className="text-ellipsis-1-lines">
                        <small>
                          {
                            item.description ? 
                            item.description 
                            : 
                            <span 
                              className="text-gs5 text-hover-black clickable"
                              onClick={e => {
                                // if nested
                                if(this.props.nestedBy){
                                  dispatch(push("/workbench/" + this.props.org_id + "/projects/" + this.props.nestedBy + "/" + kindURL +  "/" + item.id + "/settings"));
                                } else {
                                  dispatch(push("/workbench/" + item.scope + "/" + kindURL +  "/" + item.id + "/settings"));
                                }
                              }}>
                                <small className="fal fa-pencil icon-before-text"/>Add a detailed description... 
                            </span>
                          }
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="list-right list-right-no-wrap">
                    <nobr>
                    {
                      ((this.props.kind === 'components') && item.needsSave) && <small className="text-muted ">
                        {
                          componentReducer.tryingToSetComponentFlow || componentReducer.tryingToSaveComponent ?
                          <i><i className="fal fa-spinner-third fa-spin icon-before-text"/>Saving changes.</i>
                          :
                          <i>
                            Your changes are not yet saved.
                          </i>
                        }
                      </small>
                    }
                    </nobr>


                    {
                      (this.props.kind === 'components' || this.props.kind === 'knowledge') && <div className="list-right">
                        {
                          getCursorsForRoom().length > 3 && <small className="text-gs5">+{getCursorsForRoom().length - 3}</small>
                        }
                        {
                          getCursorsForRoom().slice(0, 3).map(c => {
                            return <div className="margin-left-05rem"><Hydrate mode="avatar" type="user" id={c} key={c} size={20}/></div>
                          })
                        }
                      </div>
                    }

                    {
                      ((this.props.kind === 'components' || this.props.kind === 'knowledge') && (item.projects || []).length > 1) && <CustomButton
                        size="xs"
                        color="project"
                        display={<span><i className="fas fa-fw fa-robot icon-before-text"/>{(item.projects || []).length}</span>}
                        onClick={e => {
                          this.setState({
                            showProjectSwitcherModal: true
                          })
                        }}
                        />
                    }


                    {
                      (this.props.kind === 'components') && <CustomButton
                        size="xs"
                        color="grey"
                        display={
                          breakpoint === 3 ?
                          <span><i className="fas fa-fw fa-arrow-up icon-before-text"/>Publish Changes</span>
                          :
                          <i className="fas fa-fw fa-arrow-up"/>
                        }
                        disabled={!canWrite}
                        thinking={componentReducer.tryingToPublishComponent}
                        success={componentReducer.publishComponentSuccess}
                        failure={componentReducer.publishComponentFail}
                        onClick={e => {
                          this.setState({
                            showPublishComponentModal: true
                          })
                        }}
                        />
                    }
                    {
                      this.props.kind === 'components' && (
                        (item.allowed_demo_versions || []).length === 0 ?
                        <CustomButton
                          size="xs"
                          color="component"
                          disabled={true}
                          display={
                            breakpoint === 3 ?
                            <span><i className="fas fa-fw fa-play icon-before-text"/>Demo Disabled</span>
                            :
                            <i className="fas fa-fw fa-play"/>
                          }
                          />
                        :
                        <CustomButton
                          size="xs"
                          color="component"
                          display={
                            breakpoint === 3 ?
                            <span><i className="fas fa-fw fa-play icon-before-text"/>Open Demo Page</span>
                            :
                            <i className="fas fa-fw fa-play"/>
                          }
                          toBlank={"/c/demo/" + item.id + "/draft"}
                          />
                      )
                    }

                    {
                      this.props.kind === 'components' && (
                        <CustomButton
                          size="xs"
                          color="black"
                          disabled={item.visibility !== 'public' || item.current_version === 'draft'}
                          display={
                            breakpoint === 3 ?
                            <span><i className="fas fa-fw fa-share icon-before-text"/>Share</span>
                            :
                            <i className="fas fa-fw fa-share"/>
                          }
                          toBlank={"/flow/" + item.id}
                          />
                      )
                    }

                    {
                      this.props.kind === 'knowledge' && <form className="list-right" onSubmit={()=>{
                          dispatch(tryToSearchInformation({
                            knowledge_id: knowledge.id,
                            searchText: this.state.searchText
                          }))
                          this.setState({lastSearchText: this.state.searchText});
                        }}>
                          <CustomField
                            value={this.state.searchText}
                            inline={true}
                            minWidth={250}
                            autoFocus={true}
                            thinking={knowledgeReducer.tryingToSearchInformation}
                            placeholder="search..."
                            onChange={e => this.setState({searchText: e.value})}
                            />
              
                          <CustomButton
                            display={<i className="far fa-fw fa-search"/>}
                            color="black"
                            size="small"
                            thinking={knowledgeReducer.tryingToSearchInformation}
                            fail={knowledgeReducer.searchInformationFail}
                            success={knowledgeReducer.searchInformationSuccess}
                            disabled={!this.state.searchText}
                            submit={true}
                            onClick={()=>{

                              // are we nested?
                              if(this.props.nestedBy){
                                // navigate to the info search page with the search text as a query param
                                dispatch(push('/workbench/' + this.props.org_id + '/projects/' + this.props.nestedBy + '/knowledge/' + item.id + '?search=' + this.state.searchText));
                              } else {
                                // navigate to the info search page with the search text as a query param
                                dispatch(push('/workbench/' + this.props.org_id + '/knowledge/' + item.id + '?search=' + this.state.searchText));
                              }
                            }}
                            />
                        </form>
                    }

                    {/* <CustomButton 
                      size="xs"
                      color="grey"
                      display={<span><i className="far fa-fw fa-cog icon-before-text"/>Settings</span>}
                      to={"/workbench/" + this.props.org_id + "/" + kindURL + "/" + item.id + "/settings"}
                      />
                    <CustomButton 
                      size="xs"
                      color="black"
                      display={<span><i className="far fa-fw fa-users icon-before-text"/>Share</span>}
                      to={"/workbench/" + this.props.org_id + "/" + kindURL + "/" + item.id + "/team"}
                      /> */}
                  </div>
                </div>
                <div className="workbench-nested-bookmark-nav-footer">
                  <TabNav
                    items={
                      tools.filter(t => !t.removeFromTabNav).map(t => {
                        return {
                          value: t.name,
                          display: <span><i className={"far fa-fw fa-" + t.display_icon + " icon-before-text"}/>
                            {t.display_name}
                            { (t.getCounter && t.getCounter(item) > 0) && <span className="text-muted margin-left-1rem">{t.getCounter(item)}</span> }
                          </span>,
                          disabled: !t.roleless && !item,
                          hideIf: t.hideIf ? t.hideIf(item) : false,
                          onMouseEnter: e => {
                            dispatch(showTooltip({
                              el: e.target,
                              nobr: false,
                              position: 'bottom',
                              content: <div style={{maxWidth: 250}} className="text-400 ">
                                <p className="thin-line-height no-margin">
                                  <small>
                                    {t.description}
                                  </small>
                                </p>
                              </div>
                            }))
                          },
                          onMouseLeave: e => {
                            dispatch(hideTooltip())
                          },
                        }
                      })
                    }
                    borderless={true}
                    value={tool.name}
                    theme={this.props.kind}
                    onChange={(v) => {

                      let rootURL = '/workbench/' + this.props.org_id + '/' + kindURL + '/' + item.id;

                      if(mode === 'nested' && this.props.nestedBy){
                        rootURL = '/workbench/' + this.props.org_id + '/projects/' + this.props.nestedBy + '/' + kindURL + '/' + item.id;
                        console.log(rootURL)
                      }

                      if(v){
                        dispatch(push(rootURL + "/" + v));
                      } else {
                        dispatch(push(rootURL));
                      }
                    }}
                    />
                </div>
              </div>
            }
            {
              (tool.component && !canRead) ? <div className="relative padding-1rem">
                { mode === 'nested' && <div className="workbench-nested-bookmark-nav-bumper"/>}
                <Callout
                  style="danger"
                  title="Access Denied"
                  content={<div>
                    Contact this organization's administrator to request access.
                  </div>}
                  />
              </div>
              :
              (
                tool.noScroll ? <div className="flex-grow flex-column-stretch">
                  { tool.component && canRead && tool.component(item.id, canWrite, this.props.subpage, this.props.pages) }
                </div>
                :
                
                  <div className="relative scroll-child padding-1rem ">
                    { mode === 'nested' && <div className="workbench-nested-bookmark-nav-bumper"/>}
                      {
                        !canWrite && !tool.roleless && readOnlyCallout
                      }
                      {
                        tool.component && canRead && tool.component(item.id, canWrite, this.props.subpage, this.props.pages)
                      }
                      {
                        tool.dynamicComponent && tool.dynamicComponent(item.id, this.props.subpage, subpageObject, canWrite, this.props.pages)
                      }
                  </div>
              )
            }
{/*             
            <div className={"flex-grow flex-column-stretch " + (tool.noScroll ? "no-padding" : "scroll-child-always  workbench-body-padding")}>
              <div className={tool.noScroll ? "flex-grow flex-column-stretch" : "row flex-grow "}>
                {
                  !tool.noScroll && <div className="workbench-nested-bookmark-nav-bumper"/>
                }
                    
                {
                  (tool.component && canRead) && 
                  <div className={" " + (tool.noScroll ? "flex-grow flex-column-stretch" : "relative")}>
                    {!canWrite && !tool.roleless && readOnlyCallout }

                    {tool.component(item.id, canWrite, this.props.subpage)}
                  </div>
                }

                {
                  (tool.component && !canRead) && <div className="relative">
                    <Callout
                      style="danger"
                      title="Access Denied"
                      content={<div>
                        Contact this organization's administrator to request access.
                      </div>}
                      />
                  </div>
                }
                    
                {
                  (tool.dynamicComponent) && <div className="relative">
                    {!canWrite && !tool.roleless && readOnlyCallout }
                    {
                      tool.dynamicComponent(item.id, this.props.subpage, subpageObject, canWrite)
                    }
                  </div>
                }

              </div>
              {
                !tool.noFooter && <Footer simple={true} padless={true}/>
              }
            </div> */}
          </div>
        </div>
      </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, orgReducer, knowledgeReducer, projectReducer, componentReducer, guiReducer, postReducer, intelReducer, featuresReducer, inquiryReducer, sharedReducer  } = state;

  return {
    userReducer,
    orgReducer,
    knowledgeReducer,
    projectReducer,
    componentReducer,
    inquiryReducer,
    guiReducer, 
    postReducer, 
    featuresReducer,
    intelReducer,
    sharedReducer
  }
}

export default connect(mapStateToProps)(WorkbenchKind);
