import { push } from 'react-router-redux';

import {
  tryToHeartbeat,
  tryToValidateSession,
  dismissToast
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// LOGIN
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestLogin = credentials => {
  return {
    type: 'REQUEST_LOGIN',
    data: {
      lastSubmit: credentials
    }
  }
}

export const receiveLoginSuccess = data => ({
  type: 'RECEIVE_LOGIN_SUCCESS',
  data
})

export const receiveLoginFail = data => ({
  type: 'RECEIVE_LOGIN_FAIL',
  data
})

export const tryToLogin = credentials => (dispatch, getState) => {

  dispatch(requestLogin(credentials));

  let headers = {
    "Content-Type": "application/json",
  }

  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/users/login', {
      method: 'post',
      body: JSON.stringify(credentials),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){

      dispatch(receiveLoginSuccess(json.data));
      dispatch(tryToValidateSession());

      if(json.data.email_verified && !json.data.bumped){
        dispatch(push('/workbench'))
      } else if(!json.data.email_verified && !json.data.bumped){
        dispatch(push('/almost-signed-up'));
      } else if(json.data.bumped){
        dispatch(push('/hold-up'));
      }
      dispatch(dismissToast({id: 'cookieConsent'}));
    })
    .catch(e => {
      if(e.message.startsWith('Unexpected token')){
        e = new Error('Please try again later.');
      }
      dispatch(receiveLoginFail({errors:e, lastSubmit: credentials}));
    })
}
