import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {
  tryToGetOrganizationMembers,
  tryToGetMemberships,
  tryToGetOrganizationMembersLog
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Remove a member from an organization
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestEditOrganizationMemberPrivilege = packet => {
  return {
    type: 'REQUEST_EDIT_ORGANIZATION_MEMBER_PRIVILEGE',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveEditOrganizationMemberPrivilegeSuccess = data => ({
  type: 'RECEIVE_EDIT_ORGANIZATION_MEMBER_PRIVILEGE_SUCCESS',
  data
})

export const receiveEditOrganizationMemberPrivilegeFail = data => ({
  type: 'RECEIVE_EDIT_ORGANIZATION_MEMBER_PRIVILEGE_FAIL',
  data
})

export const tryToEditOrganizationMemberPrivilege = packet => (dispatch, getState) => {

  dispatch(requestEditOrganizationMemberPrivilege(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/organizations/members/' + packet.id, {
      method: 'put',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveEditOrganizationMemberPrivilegeSuccess(json));
      dispatch(tryToGetOrganizationMembersLog({id: packet.id}));

      const orgReducer = getState().orgReducer;
      let membership = orgReducer.memberships.find(m => m.organization.id === packet.id);
      if(membership.membership.id === packet.member_id){
        dispatch(tryToGetMemberships());
      }
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveEditOrganizationMemberPrivilegeFail({errors:e, lastSubmit: packet}));
    })
}
