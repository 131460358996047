import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomField from 'kit/components/CustomField/CustomField'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'

export const NodeSettings_prompt_role_setter = ({ node, setNodeSettings, canWrite }) => {

  if (!node.settings) {
    return null;
  }

  return (
    <div>
      <CustomSelect
        value={node.settings.role}
        placeholder="Who is saying this?"
        description="Most of the time, Instructional message is the right choice."
        disabled={!canWrite}
        label="Role"
        large={true}
        options={[
          {
            label: <div className="list-left list-left-no-wrap">
              <div>
                <i className="far fa-chalkboard-teacher fa-lg fa-fw"/>
              </div>
              <div className="thin-line-height">
                <strong>Instructional Message</strong><br/>
                <small>For speaking directly to the LLM, giving it direct training instructions, and inserting variables.</small>
              </div>
            </div>,
            value: 'system', 
          },
          {
            label: <div className="list-left list-left-no-wrap">
              <div>
                <i className="far fa-robot fa-lg fa-fw"/>
              </div>
              <div className="thin-line-height">
                <strong>From the Agent</strong><br/>
                <small>Injected messages as the agent itself, most frequently used for welcome messages.</small>
              </div>
            </div>,
            value: 'agent', 
          },
          {
            label: <div className="list-left list-left-no-wrap">
              <div>
                <i className="far fa-user fa-lg fa-fw"/>
              </div>
              <div className="thin-line-height">
                <strong>From the User</strong><br/>
                <small>For speaking directly as the user, not common but useful for injecting additional context.</small>
              </div>
            </div>,
            value: 'user', 
          },
        ]}
        onChange={(v)=>{

          setNodeSettings({
            role: v
          })
        }}
        />
    </div>
  );
}


export const NodeRenderer_prompt_role_setter = ({ node, type }) => {

  let role = node.settings.role;
  if(!role) {
    role = type.settings.find(s => s.name === 'role').default;
  }
  
  return (
    <div className="padding-1rem thin-line-height text-center">
      {
        role === 'system' &&
        <div className="text-900">
          <i className="fas fa-chalkboard-teacher fa-fw icon-before-text"/> Instruction
        </div>
      }
      {
        role === 'agent' &&
        <div className="text-900">
          <i className="fas fa-robot fa-fw icon-before-text"/> Agent
        </div>
      }
      {
        role === 'user' &&
        <div className="text-900">
          <i className="fas fa-user fa-fw icon-before-text"/> User
        </div>
      }
      {
        role === 'assistant' &&
        <div className="text-900">
          <i className="fas fa-robot fa-fw icon-before-text"/> Assistant (legacy)
        </div>
      }
    </div>
  );
}