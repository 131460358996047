export default function toCamelCaseFunctionName(str, prefix = 'get') {
  // Step 1: Remove non-alphabetic characters and split by spaces or underscores
  let words = str.replace(/[^a-zA-Z0-9\s_]/g, '').split(/[\s_]+/);

  // Step 2: Convert to camelCase, ensuring the first word is always lower case
  let camelCaseStr = words
    .map((word, index) => {
      // Capitalize the first letter of each word except the first one
      if (index === 0) {
        return word.toLowerCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    })
    .join('');

  // Prepend the prefix, ensuring the first character of the original string is capitalized
  // unless the prefix ends with a non-letter, in which case we directly append.
  if (!prefix || /[^a-zA-Z]$/.test(prefix)) {
    camelCaseStr = prefix + camelCaseStr;
  } else {
    camelCaseStr = prefix + camelCaseStr.charAt(0).toUpperCase() + camelCaseStr.slice(1);
  }

  // Step 3: Ensure the string is no more than 31 characters, including the prefix
  if (camelCaseStr.length > 31) {
    camelCaseStr = camelCaseStr.substring(0, 31);
  }

  return camelCaseStr;
}