import React, { Component } from 'react'
import shortid from 'shortid'

import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {

  constructor(props){
    super(props);

    this.state = {
      id: shortid.generate()
    }
  }

  componentDidMount(){

    var size = 20;

    var slices = [];

    var temp_speed = Math.random() * 10;

    let textColor = '#000';

    if(this.props.data.inverted){
      textColor = '#fff';
    }

    LoopingCanvas('sketch-' + this.state.id, 
      (ctx, e) => {
        // optional initialize
        // ctx.fillRect(0, 0, e.w, e.h);
        ctx.clearRect(0, 0, e.w, e.h);
        
        let textSize = this.props.data.textSize || '15vw';
       
        ctx.font = "bold " + textSize + " sans-serif";
        ctx.lineWidth = 10;
        ctx.textAlign = "center";
        ctx.fillStyle = textColor;
        ctx.fillText(this.props.data.text, e.w / 2, e.h / 2 + 40 + (this.props.data.textOffset || 0));

        var last_i = 0;
        for(var i = 1; i < e.h; i += Math.random() * size + 1){
          slices.push({
            size: i - last_i,
            y: last_i,
            data: ctx.getImageData(0, last_i, e.w, i - last_i),
            randomOffset: Math.random() / 5
          });
          last_i = parseInt(i);
        }

      },
      (ctx, e) => {
        ctx.clearRect(0, 0, e.w, e.h);
        // ctx.fillStyle = bgColor;
        // ctx.fillText(this.props.data.text, e.w / 2, e.h / 2 + 40 + (this.props.data.textOffset || 0));

        // loop
        
        // ctx.fillStyle = bgColor;
        // ctx.fillRect(0, 0, e.w, e.h);

        for(var i in slices){
          ctx.putImageData(slices[i].data, 5 * Math.cos(slices[i].randomOffset + e.frameCounter / (30 * (this.props.data.speed || 1)) + parseInt(i)), slices[i].y);
        }
      }
     )
  }

  render(){

    return (
      <div className="sketch">
        <canvas id={"sketch-" + this.state.id} />
      </div>
    )
  }
}

export default Sketch;
