import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import CustomField from '../CustomField/CustomField.js'

import './CustomPinCodeField.css';

class CustomPinCodeField extends Component {
  _isMounted = false;

  constructor(props){
    super(props);

    this.state = {
      thinking: props.thinking || false,
      success: props.success || false,
      fail: props.fail || false,
      lastSuccess: false,
      lastFail: false,
    }
  }

  componentDidMount(){
    this._isMounted = true;
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  componentDidUpdate(){

    if(this.props.success - this.state.lastSuccess > 1000){
      this.setState({
        lastSuccess: this.props.success,
        success: true
      })

      setTimeout(() => {
        if(this._isMounted){
          this.setState({
            success: false
          })
        }
      }, 1500)
    }


    if(this.props.fail - this.state.lastFail > 1000){
      this.setState({
        lastFail: this.props.fail,
        fail: true
      })

      setTimeout(() => {
        if(this._isMounted){
          this.setState({
            fail: false
          })
        }
      }, 1500)
    }
  }


  render(){

    let classList = "custom-pin-code-field";
    if(this.state.success) classList += " custom-pin-code-field-success";
    if(this.state.fail) classList += " custom-pin-code-field-fail";
    if(this.props.thinking) classList += " custom-pin-code-field-thinking";

    return (
      <div className={classList}>
        <CustomField 
          type="text"
          name="code"
          placeholder="------"
          disabled={this.props.disabled || this.props.thinking}
          inline={true}
          value={this.props.value}
          onChange={(e) => {
            if(this.props.onChange) this.props.onChange(e);

            if(e.value.toString().length === 6){
              if(this.props.onComplete) this.props.onComplete(e.value);
            }
          }}
        />
        {
          this.props.thinking &&
          <div className="custom-pin-code-field-thinking-overlay">
            <i className="fad fa-spinner-third fa-spin"/>
          </div>
        }
      </div>
    )
  }
}

// CustomPinCodeField = connect((state, ownProps) => {
//   // const { user } = state;
  
//   return {
//     // user,
//     // initialValues
//   }
// })(CustomPinCodeField);

export default CustomPinCodeField;
