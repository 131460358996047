export function hexToRgb(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function lighten(hex, percent) {
    var rgb = hexToRgb(hex);
    rgb.r = Math.min(255, rgb.r + 255 * (percent / 100));
    rgb.g = Math.min(255, rgb.g + 255 * (percent / 100));
    rgb.b = Math.min(255, rgb.b + 255 * (percent / 100));
    return rgbToHex(Math.round(rgb.r), Math.round(rgb.g), Math.round(rgb.b));
}

export function desaturate(hex, percent) {
  
    var rgb = hexToRgb(hex);
    var avg = (rgb.r + rgb.g + rgb.b) / 3;
    rgb.r += percent / 100 * (avg - rgb.r);
    rgb.g += percent / 100 * (avg - rgb.g);
    rgb.b += percent / 100 * (avg - rgb.b);
    return rgbToHex(Math.round(rgb.r), Math.round(rgb.g), Math.round(rgb.b));
}
