import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import CustomButton from 'kit/components/CustomButton/CustomButton';
import CustomSelect from 'kit/components/CustomSelect/CustomSelect';
import CustomField from 'kit/components/CustomField/CustomField';
import CustomTable from 'kit/components/CustomTable/CustomTable';
import Modal from 'kit/components/Modal/Modal';

import CodeHighlighter from 'components/CodeHighlighter/CodeHighlighter';


import {
  tryToUpdateInstalledComponent,
  tryToGetComponent,
  tryToRunScheduledComponent
} from 'actions/actions.export';

import './ProjectScheduledRunsManager.scss';

class ProjectScheduledRunsManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingComponent: null, // Component currently being edited
      draftConfig: null, // Temporary configuration for the component being edited,
      showBackOfHouse: false,
      showBackOfHouseData: {}
    };
  }

  // Enter edit mode for a component
  startEditingComponent = (componentId, currentConfig) => {

    // Load the component if it is doesnt have its current version in the cache
    let component = this.props.componentReducer.cache[componentId];
    if(!component || !component.versions.find(v => v.id === component.current_version)){
      this.props.dispatch(tryToGetComponent({id: componentId}));
    }

    this.setState({
      editingComponent: componentId,
      draftConfig: { ...currentConfig } // Create a copy of the current configuration
    });
  };

  // Exit edit mode without saving changes
  cancelEditing = () => {
    this.setState({
      editingComponent: null,
      draftConfig: null
    });
  };

  // Update draft config (but do not save yet)
  updateDraftConfig = (key, value) => {
    this.setState((prevState) => ({
      draftConfig: {
        ...prevState.draftConfig,
        [key]: value
      }
    }));
  };

  // Save changes to the server
  saveChanges = () => {
    const { dispatch, projectReducer } = this.props;
    const { editingComponent, draftConfig } = this.state;
    const project = projectReducer.cache[this.props.id];

    dispatch(tryToUpdateInstalledComponent({
      id: project.id,
      component_id: editingComponent,
      run_frequency: draftConfig.run_frequency, // NEW: cron-like schedule
      run_packet: draftConfig.run_packet, // NEW: updated packet configuration
      run_api_key: (draftConfig.run_api_key && draftConfig.run_api_key !== true) ? draftConfig.run_api_key : undefined, // NEW: API key
      run_has_api_key:draftConfig.run_api_key ? true : false
    }));

    // // Exit edit mode after saving
    // this.setState({
    //   editingComponent: null,
    //   draftConfig: null
    // });
  };

  render() {
    const { projectReducer, componentReducer, dispatch } = this.props;
    const project = projectReducer.cache[this.props.id];

    if (!project) return <span>Failed to load project.</span>;

    const components = project.components || [];
    const { editingComponent, draftConfig } = this.state;

    let both = [];

    for(let i = 0; i < components.length; i++){
      both.push({
        data: components[i],
        type: 'component'
      });
    }

    // load both array with info from componentReducer cache and knowledgeReducer cache
    for(let i = 0; i < both.length; i++){
      if(both[i].type === 'component'){
        let component = componentReducer.cache[both[i].data.id];
        
        if(component){
          both[i].cacheData = component;
        }
      } else {
        // let knowledge = knowledgeReducer.cache[both[i].data.id];
        // if(knowledge){
        //   both[i].cacheData = knowledge;
        // }
      }
    }

    if (editingComponent) {
      // Display edit view for the selected component
      const component = both.find((c) => c.data.id === editingComponent);

      if (!component) return <span>Component not found.</span>;

      return (
        <div className="project-components">
          <div className="flex-split margin-bottom-2rem">
            <div className="list-left">
              <div>
                <h4 className="margin-top-05rem no-margin-bottom">Scheduled Runs</h4>
                <small>
                  Configure scheduled runs for your project components.
                </small>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="flex-split margin-bottom-1rem">
              <div className="list-left">
                <CustomButton
                  color="grey"
                  size="small"
                  display={<span><i className="fas fa-angle-left icon-before-text"/>Back</span>}
                  onClick={this.cancelEditing}
                />
                {/* Editing View for a Single Component */}
                <div>
                  <h5 className="no-margin">{component.cacheData.display_name}</h5>
                  <small>{component.cacheData.description}</small>
                </div>
              </div>
              <div className="list-right">
                <span><strong>Last Run: </strong> <span>{component.data.last_scheduled_run ? moment(component.data.last_scheduled_run).fromNow() : <i className="text-muted">never</i>}</span></span>
                <span>
                  <strong>Last Run Status: </strong> <span className={component.data.last_scheduled_run_status === "success" ? "text-success" : "text-danger"}>{component.data.last_scheduled_run_status}</span>
                </span>
              </div> 
            </div>

            <div className="row">
              <div className="col-6">
                <CustomSelect
                  label="Run Frequency"
                  value={draftConfig.run_frequency}
                  options={[
                    { value: '0 0 * * *', label: 'Daily' },
                    { value: '0 0 * * 0', label: 'Weekly' },
                    { value: '', label: 'Not Scheduled' }
                  ]}
                  onChange={(value) => this.updateDraftConfig('run_frequency', value)}
                />
              </div>
              <div className="col-6">
                <CustomField
                  label="Scheduled Run API Key"
                  value={component.data.run_has_api_key && draftConfig.run_api_key === true ? "API Key is set" : draftConfig.run_api_key}
                  disabled={component.data.run_has_api_key && draftConfig.run_has_api_key}
                  placeholder="Paste an API Key here..."
                  onChange={(e) => {
                    this.updateDraftConfig('run_api_key', e.value)
                  }}
                  onBlur={(e) => {
                    if(draftConfig.run_api_key){
                      this.updateDraftConfig('run_has_api_key', true);
                    }
                  }}
                  description={
                    component.data.run_has_api_key ?
                    <span className="clickable text-900 text-hover-black" onClick={e => {
                      this.updateDraftConfig('run_has_api_key', false)
                      this.updateDraftConfig('run_api_key', '')
                    }}>Edit</span>
                    :
                    "You can create an API key via this project's Secret Keys."
                  }
                  />
              </div>
            </div>

            

            <CustomField
              label="Packet Configuration"
              value={draftConfig.run_packet}
              rows={10}
              placeholder="Enter packet configuration here..."
              onChange={(e) => this.updateDraftConfig('run_packet', e.value)}
              />


            <div className="flex-split">
              <div className="list-left">
                <CustomButton
                  color="grey"
                  size="small"
                  thinking={!component.cacheData.versions.find(v => v.id === component.cacheData.current_version)}
                  display="Load Default Configuration"
                  onClick={e => {
                    // Load default configuration for the component

                    // the default config will depend on the component type
                    let retval = {};

                    // first for any variables that the current version has, lets add them
                    let comp = component.cacheData;
                    let version = comp.versions.find(v => v.id === comp.current_version);

                    if(version && version.variables){
                      retval.variables = {};
                      version.variables.forEach(v => {
                        retval.variables[v.key] = v.default !== undefined ? v.default : '';
                      })
                    }

                    // for this version, lets figure out if its conversational, single or nothing based on the presence of a request node
                    let requestNode = version.flow_nodes.find(n => n.type.startsWith('request'));
                    if(requestNode){
                      switch(requestNode.type){
                        case 'request':
                          retval.messages = [{
                            role: 'user',
                            content: 'Hello, World!'
                          }]

                          break;
                        case 'request_single_prompt':
                          retval.prompt = 'Hello, World!';
                          break;
                        default:
                          break;
                      }
                    }


                    this.updateDraftConfig('run_packet', JSON.stringify({data: retval}, null, 2));
                  }}
                />
              </div>
              <div className="list-right">
                <CustomButton
                  color="transparent"
                  size="small"
                  display="Cancel"
                  onClick={this.cancelEditing}
                />
                <CustomButton
                  color="success"
                  size="small"
                  display="Save Changes"
                  thinking={projectReducer.updateInstalledComponentRequests[editingComponent]?.trying}
                  success={projectReducer.updateInstalledComponentRequests[editingComponent]?.success}
                  fail={projectReducer.updateInstalledComponentRequests[editingComponent]?.fail}
                  onClick={this.saveChanges}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    // Default table view
    return (
      <div className="project-components">

        <Modal
          show={this.state.showBackOfHouse}
          maxWidth={600}
          exitable={true}
          hideExitButton={true}
          onExit={() => this.setState({showBackOfHouse: false})}
          content={<div>
            <CodeHighlighter
              language="json"
              code={JSON.stringify(this.state.showBackOfHouseData, null, 2)}
              collapsedJSONDepth={2}
              />
          </div>}
          />

        <div className="flex-split margin-bottom-2rem">
          <div className="list-left">
            <div>
              <h4 className="margin-top-05rem no-margin-bottom">Scheduled Runs</h4>
              <small>
                Configure scheduled runs for your project components.
              </small>
            </div>
          </div>
        </div>
        
        <div className="box margin-bottom-2rem">
          <CustomTable
            rawData={both}
            hidePagination={true}
            headers={[
              {
                name: 'type',
                display: 'Type',
                width: '50px',
                sortGetter: (obj) => obj.type,
                getter: (obj) => {
                  if(obj.type === 'knowledge'){
                    return <i className="padding-1rem far fa-book-spells text-knowledge fa-fw fa-lg"/>
                  } else {
                    return <i className="padding-1rem far fa-project-diagram text-component fa-fw fa-lg"/>
                  }
                }
              },
              {
                name: 'name',
                display: 'Name',
                width: '50%',
                sortGetter: (obj) => obj.cacheData ? obj.cacheData.display_name : '',
                getter: (obj) => {
              
                  if(obj.cacheData){
                    return <div className="text-ellipsis-2-lines">
                      <strong>{obj.cacheData.display_name}</strong><br/>
                      <span>{obj.cacheData.description}</span>
                    </div>
                  }
                  return <i className="text-muted">loading...</i>;
                }
              },
              {
                name: 'run_frequency',
                display: 'Frequency',
                width: '300px',
                getter: (obj) => {
                  if(obj.cacheData){
                    if(obj.cacheData.current_version === 'draft'){
                      return <nobr className="text-warning">
                        Not yet published<i className="far fa-clock icon-after-text"/>
                      </nobr>
                    }
                  }

                  if(obj.data.run_frequency){
                    // convert cron-like schedule to human readable format
                    let parts = obj.data.run_frequency.split(' ');

                    if(parts[0] === '0' && parts[1] === '0' && parts[2] === '0' && parts[3] === '*' && parts[4] === '*'){
                      return <nobr className="text-success">Daily</nobr>;
                    }

                    if(parts[0] === '0' && parts[1] === '0' && parts[2] === '0' && parts[3] === '*' && parts[4] === '0'){
                      return <nobr className="text-success">Weekly</nobr>;
                    }

                    return <nobr className="text-success">{obj.data.run_frequency}</nobr>;
                  }
                  return <nobr className="text-muted">Not scheduled</nobr>;
                }
              },
              {
                name: 'last_run',
                display: 'Last Run',
                width: '200px',
                getter: (obj) => {
                  if(obj.data.last_scheduled_run){
                    return <nobr>{moment(obj.data.last_scheduled_run).fromNow()}</nobr>;
                  }
                  return <i className="text-muted">never</i>;
                }
              },
              {
                name: 'last_run_status',
                display: 'Last Run Status',
                width: '100px',
                getter: (obj) => {
                  if(obj.data.last_scheduled_run_status === "success"){
                    return <CustomButton
                      display={<span>
                        <i className="fas fa-check icon-before-text"/> View Response
                        </span>
                      }
                      color="success"
                      size="xs"
                      onClick={() => {
                        this.setState({
                          showBackOfHouse: true,
                          showBackOfHouseData: obj.data.last_scheduled_run_response
                        })
                      }}
                      />
                  }
                  if(obj.data.last_scheduled_run_status === "error"){
                    return <CustomButton
                      display={<span>
                        <i className="fas fa-exclamation-triangle icon-before-text"/> View Error
                        </span>
                      }
                      color="danger"
                      size="xs"
                      onClick={() => {
                        // console.log(obj.data.last_scheduled_run_response);
                        this.setState({
                          showBackOfHouse: true,
                          showBackOfHouseData: {error: obj.data.last_scheduled_run_response}
                        })
                      }}
                      />
                  }

                  return;
                }
              },
              {
                name: 'edit_packet',
                display: 'Configure',
                width: '150px',
                getter: (obj) => (
                  <CustomButton
                    color="black"
                    size="xs"
                    disabled={!obj.cacheData || obj.cacheData.current_version === 'draft'}
                    display={<span><i className="fas fa-pencil icon-before-text"/>Edit</span>}
                    onClick={() => this.startEditingComponent(obj.data.id, {
                      run_frequency: obj.data.run_frequency,
                      run_packet: obj.data.run_packet,
                      run_api_key: obj.data.run_has_api_key,
                      run_has_api_key: obj.data.run_has_api_key
                    })}
                  />
                )
              },
              {
                name: 'manual_trigger',
                display: 'Manually Trigger',
                width: '150px',
                getter: (obj) => (
                  <CustomButton
                    color="grey"
                    size="xs"
                    display={<span><i className="fas fa-play icon-before-text"/>Run Now</span>}
                    thinking={projectReducer.runScheduledComponentRequests[obj.data.id]?.trying}
                    success={projectReducer.runScheduledComponentRequests[obj.data.id]?.success}
                    fail={projectReducer.runScheduledComponentRequests[obj.data.id]?.fail}
                    onClick={() => {
                      dispatch(tryToRunScheduledComponent({
                        id: project.id,
                        component_id: obj.data.id
                      })
                      )
                    }}
                  />
                )
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { projectReducer, componentReducer } = state;
  return {
    projectReducer,
    componentReducer
  };
};

export default connect(mapStateToProps)(ProjectScheduledRunsManager);
