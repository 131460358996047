import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Launch a specific prototype
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestLaunchPrototype = packet => {
  return {
    type: 'REQUEST_LAUNCH_PROTOTYPE',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveLaunchPrototypeSuccess = data => ({
  type: 'RECEIVE_LAUNCH_PROTOTYPE_SUCCESS',
  data
})

export const receiveLaunchPrototypeFail = data => ({
  type: 'RECEIVE_LAUNCH_PROTOTYPE_FAIL',
  data
})

export const tryToLaunchPrototype = packet => (dispatch, getState) => {

  dispatch(requestLaunchPrototype(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/prototypes/launch/' + packet.id, {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveLaunchPrototypeSuccess(json));

      // open json.url in new tab
      window.open(json.url, '_blank');
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveLaunchPrototypeFail({errors:e, lastSubmit: packet}));
    })
}
