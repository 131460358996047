import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js';
import CustomField from 'kit/components/CustomField/CustomField.js';
import Hydrate from 'components/Hydrate/Hydrate.js';

import toCamelCaseFunctionName from 'utilities/toCamelCaseFunctionName';

function FunctionalRag({ options = {}, onChange, enabled, org_id, canWrite}) {
  const [selectedOption, setSelectedOption] = useState('');

  // Access the Redux dispatch function
  const dispatch = useDispatch();
  const knowledgeReducer = useSelector((state) => state.knowledgeReducer);

  let knowledgeCacheList = Object.keys(knowledgeReducer.cache).map(id => knowledgeReducer.cache[id]);
    
  let knowledge = [];
  if(org_id){
    knowledge = knowledgeCacheList.filter(p => p.scope === org_id);
  }


  return (
    <div>
      <CustomSelect
        placeholder="Select one of your organization's Knowledge Bases"
        disabled={!canWrite}
        label="Which Knowledge Base should be searched?"
        description={"This Knowledge Base's configured description will be used to help the AI understand the context of the question."}
        value={options.knowledge_id}
        options={(knowledge).map((k, i)=>{
          return {
            value: k.id,
            label: <div>
              <span className="text-900">{k.display_name}</span> <br/>
              <div className="text-ellipsis-2-lines">
                <small className="text-muted">by <Hydrate id={k.created_by} type="user"/></small>
              </div>
            </div>
          }
        })}
        onChange={e => {
          onChange({
            knowledge_id: e,
            knowledge_function_name: toCamelCaseFunctionName(knowledge.find(k => k.id === e).display_name),
            knowledge_function_description: knowledge.find(k => k.id === e).description
          })
        }}
        />

      <CustomField
        label="Function Name"
        value={options.knowledge_function_name}
        maxLength={32}
        onChange={e => {
          onChange({
            knowledge_function_name: e.value
          })
        }}
        disabled={!canWrite}
        placeholder="ex: getKnowledge"
        description="Automatically set based on Knowledge Base's name, but can be changed if necessary. This is the name of the function that will be called in the AI and should be unique across all enabled Functional Retrieve Knowledge on this agent."
        />

      <CustomField
        label="When and why should the Agent choose to search this Knowledge Base?"
        value={options.knowledge_function_description}
        maxLength={512}
        rows={10}
        onChange={e => {
          onChange({
            knowledge_function_description: e.value
          })
        }}
        disabled={!canWrite}
        placeholder="ex: use this knowledge base when the user is asking about our best practices"
        />

      <CustomField
        name={'max_information_returned'}
        label={<span>How many pieces of information should be returned?<br/><small className="text-muted">(defaults to 1)</small></span>}
        description={""}
        type="range"
        min={1}
        max={32}
        step={1}
        placeholder={'1'}
        disabled={!canWrite}
        value={options.max_information_returned}
        onChange={e => {
          onChange({
            max_information_returned: e.value
          })
        }}
        />

      <div className="spacer-1rem"/>
      <CustomField
        name={'embedding_similarity_threshold'}
        label={<span>How similar should the inserted information be to the search text?<br/><small className="text-muted">0 will pull the nearest information no matter how far, while a value of 1 requires an exact match (default: 0)</small></span>}
        description={""}
        type="range"
        min={0}
        max={1}
        step={.01}
        placeholder={'0'}
        disabled={!canWrite}
        value={options.embedding_similarity_threshold}
        onChange={e => {
          onChange({
            embedding_similarity_threshold: e.value
          })
        }}
        />

    </div>
  );
}

export default FunctionalRag;
