import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
  
import ScrollToTop from './ScrollToTop'
import { history } from './configureStore';

import ErrorRoute from './routes/routes.error'
import ContactRoute from './routes/routes.contact'
import LandingRoute from './routes/routes.landing_draft'
import WorkbenchRoute from './routes/routes.workbench'
import DocsRoute from './routes/routes.docs'
import OrgProfileRoute from './routes/routes.profile.org'
import FlowPublicRoute from './routes/routes.public.component'

import RegisterRoute from './routes/routes.register'
import LoginRoute from './routes/routes.login'
import WaitlistRoute from './routes/routes.waitlist'
import ForgotRoute from './routes/routes.forgot'
import RecoverRoute from './routes/routes.recover'
import SetPasswordRoute from './routes/routes.setPassword'
import AlmostSignedUpRoute from './routes/routes.almostSignedUp'
import BumpRoute from './routes/routes.bump'
import InterruptRoute from './routes/routes.interrupt'


import NewOrganization from './routes/routes.new.organization'

import Tooltip from './kit/components/Tooltip/Tooltip.js'
import Toast from './kit/components/Toast/Toast.js'
import Heart from './components/Heart/Heart.js'

import DemoComponentRoute from './routes/routes.demoComponent'

import BlogRoute from './routes/routes.blog'
import AnimatorRoute from './routes/routes.animator'


import {
  tryToValidateSession,
  tryToLoadFeaturesToken,
  tryToGetModels,
  tryToListPosts,
  tryToGetPlugins,
  tryToGetFlowNodes
} from './actions/actions.export'


import projectTools from './configs/config.project-tools'
import componentTools from './configs/config.component-tools'
import isFeatureSwitchOn from './utilities/isFeatureSwitchOn'


class App extends Component {
  

  componentWillMount(){
    const { dispatch } = this.props;

    dispatch(tryToValidateSession());
    dispatch(tryToLoadFeaturesToken());
    dispatch(tryToGetModels());
    dispatch(tryToGetPlugins());
    // dispatch(tryToListPosts());
    dispatch(tryToGetFlowNodes());
  }


  render(){

    const { userReducer, featuresReducer, projectReducer } = this.props;

    if(!userReducer.ready || !featuresReducer.ready) return <span/>;

    return (
      <div className="app">
        <Tooltip/>
        <Heart/>
        <ConnectedRouter history={history}>
          <ScrollToTop>

            <Toast/>
            <Switch>
              <Route exact path="/" render={(props) => {
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn && !userReducer.myData.email_verified){
                  return <Redirect to={{pathname: '/almost-signed-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/workbench', state: {from: props.location}}}/>
                } else {
                  return <LandingRoute/>
                }
              }}/>

              <Route exact path="/flow/:component_id" render={(props) => {
                // if(!userReducer.isLoggedIn){
                //   return <Redirect to={{pathname: '/sign-in', state: {from: props.location}}} />
                // }
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn && !userReducer.myData.email_verified){
                  return <Redirect to={{pathname: '/almost-signed-up', state: {from: props.location}}} />
                } else {
                  return <FlowPublicRoute component_id={props.match.params.component_id}/>
                }
              }}/>


              <Route exact path="/flow/:component_id/:version" render={(props) => {
                // if(!userReducer.isLoggedIn){
                //   return <Redirect to={{pathname: '/sign-in', state: {from: props.location}}} />
                // }
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn && !userReducer.myData.email_verified){
                  return <Redirect to={{pathname: '/almost-signed-up', state: {from: props.location}}} />
                } else {
                  return <FlowPublicRoute component_id={props.match.params.component_id} version={props.match.params.version}/>
                }
              }}/>
{/* 
              <Route exact path="/o/:org_id" render={(props) => {
                if(!userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/sign-in', state: {from: props.location}}} />
                }
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn && !userReducer.myData.email_verified){
                  return <Redirect to={{pathname: '/almost-signed-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn){
                  return <OrgProfileRoute org_id={props.match.params.org_id}/>
                } else {
                  return <Redirect to={{pathname: '/oops', state: {from: props.location}}} />
                }
              }}/>


              <Route exact path="/o/:org_id/:subpage" render={(props) => {
                if(!userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/sign-in', state: {from: props.location}}} />
                }
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn && !userReducer.myData.email_verified){
                  return <Redirect to={{pathname: '/almost-signed-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn){
                  return <OrgProfileRoute org_id={props.match.params.org_id} subpage={props.match.params.subpage}/>
                } else {
                  return <Redirect to={{pathname: '/oops', state: {from: props.location}}} />
                }
              }}/>  */}



              <Route exact path="/workbench" render={(props) => {
                if(!userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/sign-in', state: {from: props.location}}} />
                }
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn && !userReducer.myData.email_verified){
                  return <Redirect to={{pathname: '/almost-signed-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn){
                  return <WorkbenchRoute/>
                } else {
                  return <Redirect to={{pathname: '/oops', state: {from: props.location}}} />
                }
              }}/>


              <Route exact path="/workbench/:org_id" render={(props) => {
                if(!userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/sign-in', state: {from: props.location}}} />
                }
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn && !userReducer.myData.email_verified){
                  return <Redirect to={{pathname: '/almost-signed-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn){
                  return <WorkbenchRoute org_id={props.match.params.org_id}/>
                } else {
                  return <Redirect to={{pathname: '/oops', state: {from: props.location}}} />
                }
              }}/>


              <Route exact path="/workbench/:org_id/:page" render={(props) => {
                if(!userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/sign-in', state: {from: props.location}}} />
                }
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn && !userReducer.myData.email_verified){
                  return <Redirect to={{pathname: '/almost-signed-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn){
                  return <WorkbenchRoute page={props.match.params.page} org_id={props.match.params.org_id}/>
                } else {
                  return <Redirect to={{pathname: '/oops', state: {from: props.location}}} />
                }
              }}/>



              <Route exact path="/workbench/:org_id/:page/:subpage" render={(props) => {
                if(!userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/sign-in', state: {from: props.location}}} />
                }
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn && !userReducer.myData.email_verified){
                  return <Redirect to={{pathname: '/almost-signed-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn){
                  return <WorkbenchRoute page={props.match.params.page} subpage={props.match.params.subpage} org_id={props.match.params.org_id}/>
                } else {
                  return <Redirect to={{pathname: '/oops', state: {from: props.location}}} />
                }
              }}/>



              <Route exact path="/workbench/:org_id/:page/:subpage/:subpage2" render={(props) => {
                if(!userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/sign-in', state: {from: props.location}}} />
                }
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn && !userReducer.myData.email_verified){
                  return <Redirect to={{pathname: '/almost-signed-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn){
                  return <WorkbenchRoute page={props.match.params.page} subpage={props.match.params.subpage} subpage2={props.match.params.subpage2} org_id={props.match.params.org_id}/>
                } else {
                  return <Redirect to={{pathname: '/oops', state: {from: props.location}}} />
                }
              }}/>

              <Route exact path="/workbench/:org_id/:page/:subpage/:subpage2/:subpage3" render={(props) => {
                if(!userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/sign-in', state: {from: props.location}}} />
                }
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn && !userReducer.myData.email_verified){
                  return <Redirect to={{pathname: '/almost-signed-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn){
                  return <WorkbenchRoute page={props.match.params.page} subpage={props.match.params.subpage} subpage2={props.match.params.subpage2} subpage3={props.match.params.subpage3} org_id={props.match.params.org_id}/>
                } else {
                  return <Redirect to={{pathname: '/oops', state: {from: props.location}}} />
                }
              }}/>

              <Route exact path="/workbench/:org_id/:page/:subpage/:subpage2/:subpage3/:subpage4" render={(props) => {
                if(!userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/sign-in', state: {from: props.location}}} />
                }
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn && !userReducer.myData.email_verified){
                  return <Redirect to={{pathname: '/almost-signed-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn){
                  return <WorkbenchRoute page={props.match.params.page} subpage={props.match.params.subpage} subpage2={props.match.params.subpage2} subpage3={props.match.params.subpage3} subpage4={props.match.params.subpage4} org_id={props.match.params.org_id}/>
                } else {
                  return <Redirect to={{pathname: '/oops', state: {from: props.location}}} />
                }
              }}/>


              <Route exact path="/workbench/:org_id/:page/:subpage/:subpage2/:subpage3/:subpage4/:subpage5" render={(props) => {
                console.log(5);
                if(!userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/sign-in', state: {from: props.location}}} />
                }
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn && !userReducer.myData.email_verified){
                  return <Redirect to={{pathname: '/almost-signed-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn){
                  return <WorkbenchRoute page={props.match.params.page} subpage={props.match.params.subpage} subpage2={props.match.params.subpage2} subpage3={props.match.params.subpage3} subpage4={props.match.params.subpage4} subpage5={props.match.params.subpage5} org_id={props.match.params.org_id}/>
                } else {
                  return <Redirect to={{pathname: '/oops', state: {from: props.location}}} />
                }
                }}/>



              <Route exact path="/sign-in" render={(props) => {
                if(userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/', state: {from: props.location}}} />
                } else {
                  return <LoginRoute/>
                }
              }}/>

              <Route exact path="/sign-up"  render={(props) => {
                if(userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/', state: {from: props.location}}} />
                } else {
                  return <RegisterRoute/>
                }
              }}/>


              <Route exact path="/forgot" render={(props) => {
                if(userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/', state: {from: props.location}}} />
                } else {
                  return <ForgotRoute/>
                }
              }}/>


              <Route exact path="/recover/:recover_code" render={(props) => {
                if(userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/', state: {from: props.location}}} />
                } else {
                  return <RecoverRoute recover_code={props.match.params.recover_code}/>
                }
              }}/>


              <Route exact path="/set-password/:invite_code" render={(props) => {
                if(userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/', state: {from: props.location}}} />
                } else {
                  return <SetPasswordRoute invite_code={props.match.params.invite_code}/>
                }
              }}/>


              <Route exact path="/almost-signed-up" render={(props) => {
                if(userReducer.isLoggedIn && !userReducer.myData.email_verified){
                  return <AlmostSignedUpRoute/>
                } else {
                  return <Redirect to={{pathname: '/', state: {from: props.location}}} />
                }
              }}/>

              <Route exact path="/hold-up" render={(props) => {
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <BumpRoute/>
                } else {
                  return <Redirect to={{pathname: '/', state: {from: props.location}}} />
                }
              }}/>


              <Route exact path="/interrupt" render={(props) => {
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <InterruptRoute/>
                } else {
                  return <Redirect to={{pathname: '/', state: {from: props.location}}} />
                }
              }}/>


              <Route exact path={"/c/demo/:component_id/:version"} render={(props) => {              
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else {
                  return <DemoComponentRoute 
                    id={props.match.params.component_id} 
                    version={props.match.params.version}
                    />
                }
              }}/>


              <Route exact path="/new/organization" render={(props) => {
                if(userReducer.isLoggedIn && userReducer.myData.bumped){
                  return <Redirect to={{pathname: '/hold-up', state: {from: props.location}}} />
                } else if(userReducer.isLoggedIn){
                  return <NewOrganization/>
                } else {
                  return <Redirect to={{pathname: '/oops', state: {from: props.location}}} />
                }
              }}/>


              <Route exact path="/docs/:page/:subpage" render={(props) => {
                return <DocsRoute page={props.match.params.page} subpage={props.match.params.subpage}/>
              }}/>

              <Route exact path="/docs/:page" render={(props) => {
                return <DocsRoute page={props.match.params.page}/>
              }}/>

              <Route exact path="/docs" render={(props) => {
                return <DocsRoute/>
              }}/>

{/* 
              <Route exact path="/changelog" render={(props) => {
                return <Changelog/>
              }}/> */}
{/* 
              <Route exact path="/blog/:slug" render={(props) => {

                // let canAccess = isFeatureSwitchOn('blog', userReducer, featuresReducer);
                // if(!canAccess){
                //   return <Redirect to={{pathname: '/oops', state: {from: props.location}}} />
                // }
                return <BlogRoute slug={props.match.params.slug}/>
              }}/> */}
{/* 
              <Route exact path="/blog" render={(props) => {

                // let canAccess = isFeatureSwitchOn('blog', userReducer, featuresReducer);
                // if(!canAccess && featuresReducer.ready){
                //   return <Redirect to={{pathname: '/oops', state: {from: props.location}}} />
                // }
                return <BlogRoute/>
              }}/> */}



              <Route exact path={"/waitlist"} render={(props) => {

                // let canAccess = isFeatureSwitchOn('waitlist', userReducer, featuresReducer)
                // if(!canAccess){
                //   return <Redirect to={{pathname: '/oops', state: {from: props.location}}} />
                // } else 
                if(userReducer.isLoggedIn){
                  return <Redirect to={{pathname: '/workbench', state: {from: props.location}}} />
                } else {
                  return <WaitlistRoute/>
                }
                
              }}/>


              {/* <Route exact path="/zerostretch" component={ZeroStretch}/> */}
              <Route exact path="/contact" component={ContactRoute}/>
              <Route exact path="/animator" component={AnimatorRoute}/>
              <Route exact path="/oops" component={ErrorRoute}/>
              <Redirect to="/oops"/>
            </Switch>
          </ScrollToTop>
        </ConnectedRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { 
    userReducer,
    featuresReducer
  } = state;

  return {
    userReducer,
    featuresReducer
  }
}

export default connect(mapStateToProps)(App);