import { NodeRenderer_openai_chat } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_openai_chat';
import { NodeRenderer_openai_embedding } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_openai_embedding';
import { NodeRenderer_text } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_text';
import { NodeRenderer_prompt } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_prompt';
import { NodeRenderer_prompt_role_setter } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_prompt_role_setter';
import { NodeRenderer_key_value_maker } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_key_value_maker';
import { NodeRenderer_slider } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_slider';
import { NodeRenderer_boolean } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_boolean';
import { NodeRenderer_monitor } from 'tools/component/pages/ComponentFlow/NodeSettings/NodeSettings_monitor';


const customRenderDictionary = {
  'openai_chat': NodeRenderer_openai_chat,
  'openai_embedding': NodeRenderer_openai_embedding,
  'text': NodeRenderer_text,
  'prompt': NodeRenderer_prompt,
  'prompt_role_setter': NodeRenderer_prompt_role_setter,
  'key_value_maker': NodeRenderer_key_value_maker,
  'slider': NodeRenderer_slider,
  'boolean': NodeRenderer_boolean,
  'monitor': NodeRenderer_monitor
}

const nodeHeaderHeight = 36;
const nodePaddingPerPort = 22;
const nodeWidth = 250;

const filterNodesByDragBox = ({ dragX, dragY, dragWidth, dragHeight, objects }) => {
  const isWindowSelection = dragWidth > 0; // If true, it's window selection (left to right), otherwise it's crossing selection (right to left)

  // Calculate actual bounds of the drag box based on drag direction
  const dragBoxLeft = dragWidth > 0 ? dragX : dragX + dragWidth;
  const dragBoxTop = dragHeight > 0 ? dragY : dragY + dragHeight;
  const dragBoxRight = dragWidth > 0 ? dragX + dragWidth : dragX;
  const dragBoxBottom = dragHeight > 0 ? dragY + dragHeight : dragY;

  return objects.filter(object => {
    const objectLeft = object.x;
    const objectTop = object.y;
    const objectRight = objectLeft + object.width;
    const objectBottom = objectTop + object.height;

    if (isWindowSelection) {
      // Window selection: Select objects completely within the drag box
      return (
        objectLeft >= dragBoxLeft &&
        objectRight <= dragBoxRight &&
        objectTop >= dragBoxTop &&
        objectBottom <= dragBoxBottom
      );
    } else {
      // Crossing selection: Select objects crossing or inside the drag box
      return !(
        objectRight < dragBoxLeft ||
        objectLeft > dragBoxRight ||
        objectBottom < dragBoxTop ||
        objectTop > dragBoxBottom
      );
    }
  });
}

const calculateAngle = (overlayX, overlayY, targetX, targetY, currentZoom) => {
  // Reverse the zoom and pan transformations

  const translatedX = (targetX ) * currentZoom.k + currentZoom.x;
  const translatedY = (targetY ) * currentZoom.k + currentZoom.y;

  // Calculate the angle in radians
  const dx = translatedX - overlayX;
  const dy = translatedY - overlayY;
  const angle = Math.atan2(dy, dx);

  return angle;
};

function calculateControlPoints(x1, y1, x2, y2, pixelsPerPoint = 10, bias = 0.75) {
  // Calculate the Euclidean distance between the two points
  const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

  // Calculate the number of points based on the distance and pixels per point
  let numPoints = Math.floor(distance / pixelsPerPoint);

  // Ensure the number of points is odd, and at least 3 to ensure a midpoint
  if (numPoints % 2 === 0) {
      numPoints += 1;
  }

  if (numPoints < 9) {
      numPoints = 9;
  }

  // Calculate the x-difference between the two points
  let deltaX = Math.abs(x2 - x1);
  let deltaY = y2 - y1;

  if(x2 - x1 < 0){
    deltaX *= 1.5;
  } else {
    deltaY = 0;
  }

  // Calculate control points for the Bézier curve with adjusted deltaX
  const cp1x = x1 + deltaX * bias;
  const cp1y = y1 + deltaY * bias / 2;
  
  const cp2x = x2 - deltaX * bias;
  const cp2y = y2 - deltaY * bias / 2;

  // Array to store the coordinates
  const curvePoints = [];

  // Generate the curve points
  for (let i = 0; i <= numPoints; i++) {
      const t = i / numPoints;

      // Calculate the x and y coordinates at this point on the curve
      const x = (1 - t) ** 3 * x1 +
                3 * (1 - t) ** 2 * t * cp1x +
                3 * (1 - t) * t ** 2 * cp2x +
                t ** 3 * x2;
      
      const y = (1 - t) ** 3 * y1 +
                3 * (1 - t) ** 2 * t * cp1y +
                3 * (1 - t) * t ** 2 * cp2y +
                t ** 3 * y2;

      // Add the point to the array
      curvePoints.push({ x, y });
  }

  return curvePoints;
}


const calculateNodeHeight = (node, nodeTypes = []) => {
  if(!node) return nodeHeaderHeight + nodePaddingPerPort * 2;
  if(nodeTypes.length === 0) return nodeHeaderHeight + nodePaddingPerPort * 2;

  // lets find this nodeTypes in our nodeTypes array by type and name
  let foundNodeType = nodeTypes.find(n => n.name === node.type);
  let portCount = 0;
  let customHeight = 0;
  if(foundNodeType){
    portCount = Math.max(foundNodeType.inputs.length, foundNodeType.outputs.length);

    if(foundNodeType.custom_render_height){
      customHeight = foundNodeType.custom_render_height;
    }
  }

  return nodeHeaderHeight + (1 + portCount) * nodePaddingPerPort + customHeight;
}

const calculateHandleYValue = (node, nodeTypes, handleName, handleSide) => {
  
  // figure out what index this handle is return the correct y value for it relative to the top of the node

  let foundNodeType = nodeTypes.find(n => n.name === node.type);
  let portIndex = 0;
  let customHeight = 0;

  if(foundNodeType){
    portIndex = foundNodeType[handleSide].findIndex(o => o.name === handleName);

    if(foundNodeType.custom_render_height){
      customHeight = foundNodeType.custom_render_height;
    }
  }

  return nodeHeaderHeight + nodePaddingPerPort + portIndex * nodePaddingPerPort + customHeight;
}



const formatLinkId = (link) => {
  return link.from.node_id + ':' + link.from.output + ' -> ' + link.to.node_id + ':' + link.to.input;
}

const unformatLinkId = (linkId) => {
  let parts = linkId.split(' -> ');
  let fromParts = parts[0].split(':');
  let toParts = parts[1].split(':');
  return {
    from: {
      node_id: fromParts[0],
      output: fromParts[1]
    },
    to: {
      node_id: toParts[0],
      input: toParts[1]
    }
  }
}

const findNearestNeighbors = (target, objects) => {
  const { id, x: tx, y: ty, width: tw, height: th } = target;

  let nearestLeft = null;
  let nearestRight = null;
  let nearestTop = null;
  let nearestBottom = null;

  let minDistLeft = Infinity;
  let minDistRight = Infinity;
  let minDistTop = Infinity;
  let minDistBottom = Infinity;

  const euclideanDistance = (x1, y1, x2, y2) => {
      return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
  };

  objects.forEach(obj => {
      if (obj.id === id) return;

      const { x, y, width, height } = obj;

      // Calculate Euclidean distances
      const objCenterX = x + width / 2;
      const objCenterY = y + height / 2;
      const targetCenterX = tx + tw / 2;
      const targetCenterY = ty + th / 2;
      const eucDist = euclideanDistance(objCenterX, objCenterY, targetCenterX, targetCenterY);

      // Check left
      const distLeft = tx - (x + width);
      if (distLeft >= 0 && distLeft <= minDistLeft) {
          if (distLeft < minDistLeft || (distLeft === minDistLeft && eucDist < euclideanDistance(nearestLeft?.x + nearestLeft?.width / 2, nearestLeft?.y + nearestLeft?.height / 2, targetCenterX, targetCenterY))) {
              minDistLeft = distLeft;
              nearestLeft = obj;
          }
      }

      // Check right
      const distRight = x - (tx + tw);
      if (distRight >= 0 && distRight <= minDistRight) {
          if (distRight < minDistRight || (distRight === minDistRight && eucDist < euclideanDistance(nearestRight?.x + nearestRight?.width / 2, nearestRight?.y + nearestRight?.height / 2, targetCenterX, targetCenterY))) {
              minDistRight = distRight;
              nearestRight = obj;
          }
      }

      // Check top
      const distTop = ty - (y + height);
      if (distTop >= 0 && distTop <= minDistTop) {
          if (distTop < minDistTop || (distTop === minDistTop && eucDist < euclideanDistance(nearestTop?.x + nearestTop?.width / 2, nearestTop?.y + nearestTop?.height / 2, targetCenterX, targetCenterY))) {
              minDistTop = distTop;
              nearestTop = obj;
          }
      }

      // Check bottom
      const distBottom = y - (ty + th);
      if (distBottom >= 0 && distBottom <= minDistBottom) {
          if (distBottom < minDistBottom || (distBottom === minDistBottom && eucDist < euclideanDistance(nearestBottom?.x + nearestBottom?.width / 2, nearestBottom?.y + nearestBottom?.height / 2, targetCenterX, targetCenterY))) {
              minDistBottom = distBottom;
              nearestBottom = obj;
          }
      }
  });

  // Set to null if they exceed the minimum distance limit
  let minDistanceLimit = 300;

  if (minDistLeft > minDistanceLimit) nearestLeft = null;
  if (minDistRight > minDistanceLimit) nearestRight = null;
  if (minDistTop > minDistanceLimit) nearestTop = null;
  if (minDistBottom > minDistanceLimit) nearestBottom = null;

  return {
      left: nearestLeft,
      right: nearestRight,
      top: nearestTop,
      bottom: nearestBottom
  };
}

export { 
  nodeHeaderHeight,
  nodePaddingPerPort,
  nodeWidth,
  calculateAngle, 
  calculateControlPoints,
  calculateNodeHeight,
  calculateHandleYValue,
  formatLinkId,
  unformatLinkId,
  customRenderDictionary,
  filterNodesByDragBox,
  findNearestNeighbors
};