import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomField from 'kit/components/CustomField/CustomField'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'

export const NodeSettings_boolean = ({ node, setNodeSettings, canWrite }) => {

  const [settings, setSettings] = useState(node.settings || {});

  if (!node.settings) {
    return null;
  }

  return (
    <div>
      
      
    </div>
  );
}


export const NodeRenderer_boolean = ({ node, type, interactive, setNodeSettings }) => {
  

  let value = node.settings.value;
  if(value === undefined){
    value = false;
  }

  return (
    <div className={"flex-column-center-center text-900 no-select clickable " + (value ? "bg-success" : "bg-danger")} onClick={() => {
      setNodeSettings(node.id, {
        value: !value,
      });
    }}
    style={{
      height: 42
    }}
    >
      <h4 className="no-margin">{value ? 'True' : 'False'}</h4>
    </div>
  );
}