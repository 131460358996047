import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'

import './BlogPost.css';

class BlogPost extends Component {
  _isMounted = false;

  constructor(props){
    super(props);

  }


  render(){
    let classList = "";

    let post = {
      ...{
        title: ''
      },
      ...this.props.post
    }

    if(!this.props.post) return <span/>;

    return <div className={"blog-post " + classList}>
      <div className="list-left">
        {this.props.hydrateByLine}
        {
          this.props.hydrateByLine && <span className="text-muted">|</span> 
        }
        <span className="text-400 no-text-decoration">{moment(post.created_at).format('MMM D, YYYY')}</span>
      </div>
      <h1>{post.title}</h1>
      <img src={post.thumbnail_url} className="img-rounded-square-wrapper blog-post-thumbnail-image"/>
      
      <div className="margin-bottom-3rem">
        <ReactMarkdown>{post.content}</ReactMarkdown>
        <p/>
      </div>
      <hr/>
      
    </div>
  }
};

export default BlogPost;