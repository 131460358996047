module.exports = {
  name: 'getting-started',
  display_name: 'Getting Started',
  children: [
    {
      name: 'whats-in-the-docs',
      display_name: 'What\'s in the Docs?',
      content: 
`
Welcome to the ZeroWidth. This comprehensive resource is designed to help you understand and navigate our platform, providing detailed information about our services, features, and policies. Whether you're a developer, researcher, or business user, you'll find valuable insights and guidance to help you make the most of ZeroWidth's capabilities, policies, and best practices.

## Key Sections
- [Terms and Definitions](/docs/terms-and-definitions): Get familiar with the terms and definitions that are integral to our platform and the broader field of AI, large language models, and machine learning.
- [API Guide & Reference](/docs/api): Learn how to interact with our services programmatically and explore the detailed reference for our API endpoints.
- [Privacy Policy](/docs/policies/privacy): Understand how we collect, use, share, and safeguard your personal information.
- [Terms of Service](/docs/policies/terms-of-service): Learn about the terms that govern your use of our services.
- [Data Usage](/docs/policies/data-usage): Get insights into how we use data and the measures we take to protect it.
- [Data Subprocessor List](/docs/policies/data-subprocessors): Know more about our third-party partners that may process your data on our behalf.
- [Attributions](/docs/policies/attribution): Acknowledgements of third-party tools, libraries, or components used within our services.

Should you have any questions or need further clarifications, please don't hesitate to contact our support team. We're here to help you make the most out of ZeroWidth's services.

### Your Feedback Matters

As part of our ongoing commitment to improvement, we appreciate any feedback or suggestions about our documentation or any other aspect of our service. Join our community and participate in discussions or drop us a line via our support channels.

Thank you for choosing ZeroWidth. We're dedicated to ensuring you have a seamless experience while navigating our platform.
`
    },
    {
      name: 'terms-and-definitions',
      display_name: 'Terms and Definitions',
      content: `
This ever-growing list contains a mix of industry standard and ZeroWidth-specific terms and definitions. It's designed to help you understand the concepts and features that are integral to our platform and the broader field of AI, large language models and machine learning. If you come across a term that's not listed here, feel free to reach out to our support team for assistance.

## Agent
A designed ecosystem of technology, data, and processes that can autonomously interact with users or systems to perform specific tasks or provide information. Agents can be powered by large language models (LLMs) and are often used in conversational applications, customer support, and other interactive systems to engage with users, answer questions, or execute tasks based on natural language input.

### Chain
A sequence of steps or processes that an agent follows to handle a prompt, execute functions, and generate responses. Each step in the chain can involve different actions, such as processing the prompt, retrieving information, executing functions, or generating content. The agent chain may involve multiple calls to large language models (LLMs) and other systems to perform complex tasks and provide accurate, contextually appropriate responses. This chain is displayed in detail on an agent's demo page after a response is generated and via the API if verbose mode is enabled.

## Chunk
In the context of Retrieval-Augmented Generation (RAG), a "chunk" refers to a piece of external information or data retrieved by the model to augment its response generation. For instance, when a RAG model searches for information to answer a question, it retrieves relevant documents or data snippets; each of these pieces can be considered a chunk. These chunks are then processed and integrated into the model's output, ensuring that the generated response is informed by the most relevant and current information available.

## Chunking Strategy
The method or approach used by a Retrieval-Augmented Generation (RAG) system to both segment (on the way into the database) and retrieve relevant external information to enhance its responses. This strategy determines how information is broken down into manageable pieces (chunks) and how these pieces are selected and processed to complement the model's generated content. A chunking strategy can significantly impact the efficiency of information retrieval and the relevance and coherence of the augmented content, influencing the overall performance of RAG in applications requiring dynamic, information-rich responses.

## Context Window
The limit on the amount of text (in tokens) the model can consider at once for processing or generating responses. This boundary can also impact how many tokens an LLM can generate in a single interaction or how far back in the conversation history it can remember. The context window is a key factor in determining the model's ability to understand and respond to complex or multi-turn conversations, as well as its capacity to maintain coherence and relevance in its responses.

## Demo Interface
An automatically available web-based interface for testing and interacting with an agent. The demo interface allows users to send prompts to the agent and view the responses, providing a convenient way to evaluate the agent's performance and behavior. This interface is often used for testing, debugging, and designing agents, enabling users to observe and analyze the agent's behavior in real time - separate from their application.

## Endpoint
The specific URL that can be used to send a prompt to an Agent using a POST request. Each endpoint is unique to a collection and an agent, and is used to send prompts to the agent for processing.

## Few-Shot Training
The method of teaching a machine learning model to perform a task effectively by providing it with a very small amount of example data or training examples. In the context of models like large language models (LLMs), few-shot training often involves providing the model with a handful of examples within the input itself, sometimes framed as "system prompts," "instructions," or "context." These examples guide the model in understanding the task at hand, leveraging its pre-existing foundational knowledge to generalize and make accurate predictions or responses based on this minimal input, demonstrating its ability to adapt to new tasks with limited explicit guidance.

## Foundational Model
A type of large-scale artificial intelligence model that is trained on vast amounts of data across a wide variety of tasks, enabling it to acquire a broad understanding of language, concepts, or patterns. These models serve as a base or "foundation" from which specialized models can be fine-tuned for specific tasks or applications, leveraging the general capabilities of the foundational model to achieve high performance in a wide range of domains.

## Function
This feature allows large language models to request the execution of predefined operations or external commands, based on user queries, by providing the necessary arguments. It's up to the application using the model to actually perform the task. The model simply sends what function to use and the arguments it needs, based on the conversation. For example, if a user was talking to a model that was configured with a weather function, the user could ask "What's the weather like in San Francisco?" and the model would send the function "weather" and the argument "San Francisco" to the application, which would then return the weather information. The model can then parse the response and use it in the conversation naturally.

## JSON-Schema
A standard specification for describing the structure of JSON data. It defines the format, type, and validation rules for JSON objects, helping ensure that data exchanged between systems adheres to a predefined format. Some models require JSON-Schema to be used for specifying the input data formats for functions, enabling them to understand and generate the data correctly.
      
## Knowledge Base
A ZeroWidth managed database of information that can be used to augment the responses of an Agent. This database can be used to store text information that can be leveraged to automatically fill in the value of a variable in an instructional message, or be automatically retrieved via a function call as the agent determines it needs to do so. This can be used to extend the agent's foundational model with specific, more frequently updating, or proprietary information that is relevant to the task at hand.

## Large Language Model (LLM)
A type of machine learning model that is trained on vast amounts of text data to understand and generate human language. Large language models are capable of processing and generating text based on the patterns and structures they have learned from the training data, enabling them to perform a wide range of natural language processing tasks, such as translation, summarization, and conversation. These models are often used in conversational agents, chatbots, and other interactive systems to engage with users and provide information or assistance based on natural language input.

## Plugin
An extension or add-on that can be used to enhance the functionality of an agent. Plugins can be used to add new features, integrate with external systems, or customize the behavior of the agent to suit specific use cases or applications. Plugins may involve injecting additional functions, adding steps before or after processing, or modifying the behavior of the agent in response to specific prompts or events. All plugins are elements of an agent's configuration that are managed and executed by ZeroWidth's infrastructure.

## Prompt
The input text that initiates the model's generation process. There are two main categories: system prompts, which may include a set of instructions or predefined commands to guide the model, and user prompts, which may come directly from individuals or processes interacting with the LLM-enabled application. The design and purpose of an Agent often require integrating both types of prompts to form the overall input that influences the model's response. This combination ensures the model can generate relevant, contextually appropriate outputs by leveraging both the structured guidance of system prompts and the specific request or context provided by user prompts.

## Retrieval-Augmented Generation (RAG) 
This technique enhances language models by incorporating external information retrieval into the generation process. For instance, in a few-shot learning scenario, RAG can dynamically fill in parts of instructional messages with up-to-date information fetched from external sources. Similarly, a model could use RAG to execute a function call, gathering and processing information that informs its response. This method allows for responses that are informed by and adapted to the most current data or specific external content, making interactions more relevant and accurate.

### Naive
A simple form of Retrieval-Augmented Generation (RAG) that uses the verbatim text content of the prompt to retrieve relevant information from the connected Knowledge Base. This approach is straightforward and effective for many use cases, but for conversational agents that require more nuanced or contextually appropriate responses, a more sophisticated RAG technique may be necessary.

### Agentic
A more advanced form of Retrieval-Augmented Generation (RAG) that uses an additional LLM to analyze the prompt and its context in order to determine the text to use in the semantic search of the connected Knowledge Base. This approach can be more effective for conversational agents, but is slower and more computationally expensive than the naive RAG technique.

### Agentic HyDE
Standing for Hypothetical Document Embeddings, this RAG technique uses a separate LLM to generate hypothetical documents based on the prompt and its context, which are then used to search the connected Knowledge Base. For Knowledge Bases with a known typical format or structure per chunk, this technique can be more effective than other RAG techniques, but is slower and more computationally expensive.

## Role
Identifies who is speaking in a conversation, such as "user" or "agent," guiding the model's responses. Some models strictly alternate between user and agent roles for clarity and coherence, while others offer flexibility in role assignment to accommodate varied conversational dynamics. Some models support additional role types such as "system" or "tool" to afford sending directions to the model or enabling the model to interact with outside functionality.

## Secret Key
An automatically generated, unique identifier that is used to authenticate and authorize access to the ZeroWidth API. Secret keys are used to securely send prompts to agents and retrieve responses, ensuring that only authorized applications and users can interact with the agents. Secret keys should be kept confidential and not shared publicly or in client side code to maintain the security and integrity of the ZeroWidth API. Each Secret Key is associated with a specific Project and can be used to send prompts to any agent within that collection.

## Semantic Search
A search technique that leverages the meaning and context of words and phrases to retrieve relevant information. Unlike traditional keyword-based search, semantic search considers the underlying semantics of language, enabling more nuanced understanding and retrieval of information. This approach can improve the accuracy and relevance of search results, supporting tasks like recommendation, information retrieval, and content generation by capturing the meaning and context of text data. 

## State
The current context or status of a conversation, typically comprising of a list of every message sent from each party in the conversation. The state is used to maintain the context of the conversation, enabling the agent to understand and respond based on the history of the conversation. 

### Stateless Mode
A mode in which the agent does not maintain a conversation history or context between interactions. In this mode, each prompt is processed independently, requiring the application to manage the conversation state and context. This provides more flexibility and control over the conversation flow, but may require additional effort to maintain coherence and relevance in the conversation. In this mode, each API call to an endpoint is independent of the previous calls, and the agent does not maintain any conversation history or context between interactions.

### Stateful Mode
A mode in which the agent maintains a conversation history and context between interactions, enabling it to understand and respond based on the history of the conversation. In this mode, the agent can maintain coherence and relevance in the conversation, leveraging the context of previous interactions to generate more accurate and contextually appropriate responses. This mode requires a session ID and user ID to be provided with each prompt to maintain the conversation state amongst multiple interactions.

## Text Embeddings Models
These models convert data words, phrases, or longer texts into numerical vectors, capturing semantic relationships in language. By representing text in a high-dimensional space, embeddings models can measure similarities and differences between pieces of text, understanding context and meaning beyond simple keyword matching. This enables more nuanced language understanding and processing, facilitating tasks like search, recommendation, and classification by capturing the underlying semantics of language.

## Token
The smallest unit of text that the model processes, which can be a word, part of a word (such as a syllable or subword), or punctuation. Tokens are fundamental building blocks for Large Language Models, enabling them to analyze, understand, and generate text based on the sequence and patterns of these tokens.

## Variable
A placeholder for a dynamic portion of an instructional message to an agent. Variables can be used to inject specific information or data into the message, tailoring it to the user's context or the current state of the conversation. For example, a variable might represent a user's name, a location, or a specific piece of data (like relevant knowledge chunks from vector database) that the model should process. By incorporating variables, instructional messages can be adapted to a wide range of scenarios and user-specific contexts, enhancing the relevance of the agent's responses.

### External
A variable whose value is expected to be provided by the application or system interacting with the agent, rather than being directly specified in the instructional message. External variables are placeholders for information that the agent needs to retrieve or process from external sources, such as user input, databases, or other systems.

### RAG-based
A variable that is filled in automatically by ZeroWidth using the configured RAG technique. Each time the agent is prompted, ZeroWidth's processing pipeline automatically retrieves the most relevant information from the connected Knowledge Base using the configured settings and fills in the value of the variable in the instructional message. This allows the agent to generate responses that are informed by the most current and relevant information available, ensuring that the content is up-to-date and contextually appropriate, even as the conversation changes subjects or contexts.

## Vector Database
A database that stores vector representations of text data, enabling efficient similarity searches and retrieval based on semantic relationships. By indexing text embeddings, vector databases can quickly locate and retrieve relevant information based on similarity scores, supporting tasks like recommendation, search, and information retrieval that depend on understanding the meaning and context of text.

## Verbose Mode
An optional setting that enables an additional field in the response from an agent that provides detailed information about the individual steps the agent took to generate the response. This information can be useful for debugging, understanding the agent's behavior, and analyzing its performance, providing insights into the model's decision-making process and the specific actions it took to generate the response. Verbose mode can be particularly valuable for developers and researchers who want to gain a deeper understanding of the agent's behavior and the factors that influence its responses.
`
    }
  ]
}