import React, { Component } from 'react';
import LoopingCanvas from './_.LoopingCanvas';

class Sketch extends Component {
  componentDidMount() {
    var size = 10;
    var x1 = 0, x2 = 0;
    var y1 = 0, y2 = 0;
    var lastCounter = 1, lastCounter2 = 1;
    var framesBetween = 0;

    const isSquareGreen = (ctx, x, y) => {
      const pixel = ctx.getImageData(x, y, 1, 1).data;
      return pixel[0] !== 255 || pixel[1] !== 255 && pixel[2] !== 255; // RGB value of '#53b882'
    };

    const isSquareWhite = (ctx, x, y) => {
      const pixel = ctx.getImageData(x, y, 1, 1).data;
      return pixel[0] === 255 && pixel[1] === 255 && pixel[2] === 255; 
    };

    const isWithinBounds = (x, y, e) => (
      x >= -size/4 && x <= e.w + size/4 && y >= -size/4 && y <= e.h + size /4
    );

    const findNearestSquare = (ctx, x, y, e, size, conditionFn) => {
      let nearestDistance = Infinity;
      let nearestSquare = null;

      for (let i = 0; i < e.w; i += size) {
        for (let j = 0; j < e.h; j += size) {
          if (conditionFn(ctx, i, j)) {
            const distance = Math.sqrt(Math.pow(x - i, 2) + Math.pow(y - j, 2));
            if (distance < nearestDistance) {
              nearestDistance = distance;
              nearestSquare = { x: i, y: j };
            }
          }
        }
      }
      
      return nearestSquare;
    };

    let noMore = false, noMore2 = false;

    LoopingCanvas(
      'sketch-id',
      (ctx, e) => {
        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, e.w, e.h);

        x1 = parseInt((e.w * 3) / 4 / size) * size;
        y1 = parseInt((e.h / 2) / size) * size;

        x2 = parseInt((e.w / 4) / size) * size;
        y2 = parseInt((e.h / 2) / size) * size;

      },
      (ctx, e) => {



        // Logic for green square
        if(e.frameCounter - lastCounter > framesBetween){
          lastCounter = e.frameCounter;


          ctx.fillStyle = 'rgba(255,255,255,.04)';
          ctx.fillRect(0, 0, e.w, e.h);

          // var imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
          // ctx.putImageData(imageData, 0, -size/10);


          if(noMore || noMore2) return;

          const neighbors = [
            { x: x1, y: y1 - size },
            { x: x1 + size, y: y1 },
            { x: x1, y: y1 + size },
            { x: x1 - size, y: y1 },
          ];

          const unfilledNeighbors = neighbors.filter((n) => {
            return (
              isWithinBounds(n.x, n.y, e) && 
              !isSquareGreen(ctx, n.x, n.y)
            );
          });

          if (unfilledNeighbors.length === 0) {
            const nearestUnfilled = findNearestSquare(ctx, x1, y1, e, size, (c, i, j) => !isSquareGreen(c, i, j));
            if (nearestUnfilled) {
              x1 = nearestUnfilled.x;
              y1 = nearestUnfilled.y;
            } else {
              noMore = true;
            }
          } else {
            const chosenNeighbor = unfilledNeighbors[Math.floor(Math.random() * unfilledNeighbors.length )];
            x1 = chosenNeighbor.x;
            y1 = chosenNeighbor.y;
          }

          ctx.fillStyle = '#000';
          ctx.strokeStyle = '#000';
          ctx.lineWidth = 1;
          ctx.fillRect(x1 - size / 2, y1 - size / 2, size, size);
          // ctx.strokeRect(x1 - size / 2, y1 - size / 2, size, size);
        }

        // Logic for white square
        if(!noMore2 && !noMore && e.frameCounter - lastCounter2 > framesBetween){
          lastCounter2 = e.frameCounter;

          const neighbors2 = [
            { x: x2, y: y2 - size },
            { x: x2 + size, y: y2 },
            { x: x2, y: y2 + size },
            { x: x2 - size, y: y2 },
          ];

          const greenNeighbors = neighbors2.filter((n) => {
            return (
              isWithinBounds(n.x, n.y, e) && 
              isSquareGreen(ctx, n.x, n.y)
            );
          });

          if (greenNeighbors.length === 0) {
            const nearestGreen = findNearestSquare(ctx, x2, y2, e, size, isSquareGreen);
            if (nearestGreen) {
              x2 = nearestGreen.x;
              y2 = nearestGreen.y;
            } else {
              noMore2 = true;
            }
          } else {
            const chosenNeighbor = greenNeighbors[Math.floor(Math.random() * greenNeighbors.length )];
            if(chosenNeighbor.x - x2 > size){
              x2 += size;
            } else if(chosenNeighbor.x - x2 < size){
              x2 -= size;
            } else {
              x2 = chosenNeighbor.x;
            }

            // if(chosenNeighbor.y - y2 >= size){
            //   y2 += size;
            // } else if(chosenNeighbor.y - y2 <= size){
            //   y2 -= size;
            // } else {
            //   y2 = chosenNeighbor.y;
            // }
            
          }

          ctx.fillStyle = '#fff';
          ctx.strokeStyle = '#fff';
          ctx.lineWidth = 2;
          ctx.fillRect(x2 - size / 2, y2 - size / 2, size, size);
          // ctx.strokeRect(x2 - size / 2, y2 - size / 2, size, size);
        }
      }
    );
  }

  render() {
    return (
      <div className="sketch">
        <canvas id="sketch-id" />
      </div>
    );
  }
}

export default Sketch;
