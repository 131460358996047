import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Cookies from 'js-cookie'

import './CustomTable.scss';

import CustomButton from '../CustomButton/CustomButton'
import CustomField from '../CustomField/CustomField'
import Dropdown from '../Dropdown/Dropdown'

import {
  showTooltip,
  hideTooltip,
} from '../../actions/actions.tooltip'

class CustomTable extends Component {

  constructor(props){
    super(props);

    this.state = {
      searchMode: false,
      search: '',
      pageSize: props.pageSize || 15,
      currentPage: 0,
      sortDirection: 1,
      sortBy: props.sortBy
    }

    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
  }

  handleSearchSubmit(e){
    if(e && e.preventDefault) e.preventDefault();

    const { dispatch } = this.props;

    dispatch(push('/search?text=' + this.state.search));
    
    this.setState({
      searchMode: false
    })
  }


  static getDerivedStateFromProps(props, state){


    return {
      ...state,
      currentPage: props.currentPage === undefined ? state.currentPage : props.currentPage
    }
  }


  
  render(){
    const { dispatch, userReducer } = this.props;

    let classList = "";
    if(this.props.alignTop) classList += "custom-table-align-top ";

    let filteredData = JSON.parse(JSON.stringify(this.props.rawData));
    

    if(this.state.sortBy){
      let header = this.props.headers.find(h => h.name === this.state.sortBy);
      if(header){
        filteredData.sort((a,b)=>{
          let av, bv;

          if(header.sortGetter){
            av = header.sortGetter(a);
            bv = header.sortGetter(b);
          } else {
            av = header.getter(a);
            bv = header.getter(b);
          }

          if(av > bv) return this.state.sortDirection;
          if(av < bv) return -this.state.sortDirection;
        })
      }
    }

    let thisPageData = filteredData.slice(this.state.currentPage * this.state.pageSize, (this.state.currentPage + 1) * this.state.pageSize);

    return <div className={"custom-table " + classList}>
      <div className="custom-table-filter-section">
        
      </div>
      <table className="custom-table-main table-basic">
        <thead>
          <tr>
            {
              this.props.headers.map((header, i)=>{
                if(!header) return;
                return <th 
                  key={i} 
                  width={header.width}
                  className={header.noSort ? "th-no-sort" : ""}
                  onClick={()=>{ 
                    if(header.noSort) return;
                    let newDirection = this.state.sortDirection;
                    if(this.state.sortBy === header.name) newDirection *= -1;
                    this.setState({
                      sortBy: header.name, 
                      sortDirection: newDirection
                    })
                  }}>
                  <nobr>
                  {header.display}
                  {
                    this.state.sortBy === header.name ? 
                    
                      this.state.sortDirection === 1 ?
                      <i className="fas fa-caret-up icon-after-text"/>
                      :
                      <i className="fas fa-caret-down icon-after-text"/>
                    
                    :
                    ""
                  }
                  </nobr>
                </th>
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            thisPageData.map((row, i)=>{
              return <tr 
                key={i} 
                className={
                  (this.props.rowLink ? "tr-linked" : "") +
                  (this.props.rowClassGetter ? this.props.rowClassGetter(row) : "")
                } 
                onClick={()=>{ 
                  if(this.props.rowLink){
                    dispatch(push(this.props.rowLink(row)))
                  }
                }}>
                {
                  this.props.headers.map((header, j) => {
                    if(!header) return;
                    return <td key={j}>{header.getter(row, i)}</td>
                  })
                }
              </tr>
            })
          }
        </tbody>
      </table>
      {
        (this.props.rawData.length > 0 && !this.props.hidePagination) &&
      
        <div className="list-right">
          <span className="text-themed-muted">
            Showing {this.state.currentPage * this.state.pageSize + 1} to {filteredData.length < (this.state.currentPage + 1) * this.state.pageSize ? (filteredData.length).toLocaleString() : (this.state.currentPage + 1) * this.state.pageSize} of {filteredData.length.toLocaleString()}
          </span>
          <CustomButton
            size="small"
            display="Prev"
            color="grey"
            disabled={this.state.currentPage === 0}
            onClick={()=>{
              this.setState({
                currentPage: this.state.currentPage - 1
              })
            }}
            />
          <CustomButton
            size="small"
            display="Next"
            color="grey"
            disabled={(this.state.currentPage + 1) * this.state.pageSize > filteredData.length}
            onClick={()=>{
              this.setState({
                currentPage: this.state.currentPage + 1
              })
            }}
            />
        </div>
      }
    </div>
  }
}
  
const mapStateToProps = (state) => {
  const { guiReducer, userReducer, router } = state;

  return {
    guiReducer,
    userReducer,
    router
  }
}

export default connect(mapStateToProps)(CustomTable);
