import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ReactJson from 'react-json-view'
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import CustomButton from 'kit/components/CustomButton/CustomButton'
import CustomTable from 'kit/components/CustomTable/CustomTable.js'
import QueryChartWrapper from 'components/QueryChartWrapper/QueryChartWrapper'
import AbbreviateNumber from 'components/AbbreviateNumber/AbbreviateNumber'

import { generateColor } from 'utilities/colorUtilities';

import {
  tryToGetProjectAPILog
  
} from 'actions/actions.export'

import './ProjectAPILogs.scss';

class ProjectAPILogs extends Component {
  constructor(props){
    super(props);


    this.state = {
      start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD HH:mm:ss')
    }
  }

  componentDidMount(){
    const { dispatch } = this.props;

    dispatch(tryToGetProjectAPILog({
      id: this.props.id
    }));
  }

  render(){

    const { dispatch, projectReducer, userReducer, orgReducer, canWrite, componentReducer } = this.props;

    const project = projectReducer.cache[this.props.id];
    if(!orgReducer.membershipsReady) return <span/>;
    if(!project) return <span>Failed to load project.</span>;

    return <div className="project-api-logs">
      <div className="row">
        <div className="col-xl-12 margin-top-05rem margin-bottom-2rem">
          <div>
            <h4 className="no-margin-top no-margin-bottom">Reporting & Logs</h4>
            <small>
              View the usage and history of API calls made to this project.
            </small>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="box">
            <QueryChartWrapper
              queryType={'project'}
              cacheID={this.props.id}
              projectID={this.props.id}
              query={'getApiUsageAndCostForAProjectByComponent'}
              parameters={{
                start_date: this.state.start_date,
                end_date: this.state.end_date
              }}
              height={'25rem'}
              chartTitle={'API Calls per Agent Last 30 Days'}
              chart={rawData => {

                const getUniqueComponents = (data) => {
                  const uniqueComponents = [];
                  data.forEach(item => {
                    if (!uniqueComponents.includes(item.component_id)) {
                      uniqueComponents.push(item.component_id);
                    }
                  });
                  return uniqueComponents;
                }

                const  transformData = (data, startDate, endDate, valueKey) => {
                  // Generate all dates between startDate and endDate
                  let currentDate = moment(startDate);
                  const end = moment(endDate);
                  const allDates = [];
                  while (currentDate <= end) {
                      allDates.push(currentDate.format('YYYY-MM-DD'));
                      currentDate = currentDate.add(1, 'days');
                  }
              
                  // Initialize the result array with all dates and default values
                  const result = allDates.map(date => ({ day: date }));
              
                  // Iterate over the data and fill in the counts or costs
                  data.forEach(item => {
                      const formattedDay = moment(item.day).format('YYYY-MM-DD');
                      const resultEntry = result.find(entry => entry.day === formattedDay);
                      if(resultEntry === undefined) return;
              
                      // Use transaction_count or use total_cost
                      resultEntry[item.component_id] = item[valueKey];
                  });

              
                  return result;
                }

                const data = transformData(rawData, this.state.start_date, this.state.end_date, 'transaction_count');
                const components = getUniqueComponents(rawData);


                const CustomTooltip = ({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="recharts-default-tooltip">
                        {
                          components.map((component, index) => {
                            if(payload[0].payload[component]){
                              let name = (componentReducer.cache[component] || {}).display_name || "Loading name... ";
                              // if(component === 'demo') name = 'Demo GUI';
                              return <div key={index}>
                                <strong><i className="fas fa-square icon-before-text" style={{color: generateColor(index, components.length)}}/>{name}:</strong> {payload[0].payload[component]}
                              </div>
                            }
                          })
                        }
                      </div>
                    );
                  }

                  return null;
                };

                return <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="day" tickSize={8} tickMargin={5}/>
                    <YAxis axisLine={false} tickSize={0} tickMargin={5} tickFormatter={v => { return parseInt(v)}}/>
                    <Tooltip content={<CustomTooltip />} />
                    {
                      components.map((project, index) => {
                        return <Bar dataKey={project} activeBar={false} key={index} stackId={'a'} fill={generateColor(index, components.length)}/>
                      })
                    }
                  </BarChart>
                </ResponsiveContainer>
              }}
              />
          </div>

          <QueryChartWrapper
            queryType={'project'}
            cacheID={this.props.id + '_alltime'}
            projectID={this.props.id}
            query={'getTotalTokensByProject'}
            chartTitle={''}
            parameters={{
              start_date: '2024-01-01',
              end_date: '2999-01-01'
            }}
            chart={data => {
              
              let stacks = 0;
              let calls = 0;
              let tokens = 0;
              
              if(data[0] && data[0].unique_stack_ids) stacks = parseInt(data[0].unique_stack_ids);
              if(data[0] && data[0].total_api_calls) calls = parseInt(data[0].total_api_calls);
              if(data[0] && data[0].total_tokens_used) tokens = parseInt(data[0].total_tokens_used);

              return <div className="row margin-bottom-2rem row-eq-height">
                <div className="col flex-column-stretch">
                  <div className="box flex-grow">
                    <div>
                      <strong>Total ZeroWidth API Calls</strong>
                    </div>
                    <h1 className="no-margin">
                      <AbbreviateNumber value={stacks.toLocaleString()}/>
                    </h1>
                  </div>
                </div>
                <div className="col flex-column-stretch">
                  <div className="box flex-grow">
                    <div>
                      <strong>Total Third-Party API Calls</strong>
                    </div>
                    <h1 className="no-margin">
                      <AbbreviateNumber value={calls.toLocaleString()}/>
                    </h1>
                  </div>
                </div>
                <div className="col flex-column-stretch">
                  <div className="box flex-grow">
                    <div>
                      <strong>Total Tokens Used</strong>
                    </div>
                    <h1 className="no-margin">
                      <AbbreviateNumber value={tokens.toLocaleString()}/>
                    </h1>
                  </div>
                </div>
              </div>
            }}
            />
          <div className="box">
            <CustomTable
              rawData={project.apiLog || []}
              alignTop={true}
              headers={[
                {
                  name: 'timestamp',
                  display: "Timestamp",
                  width: '100px',
                  getter: r => <span>
                    <nobr>{moment(r.timestamp).format('h:mm:ss a')}</nobr><br/>
                    <small><nobr>{moment(r.timestamp).format('MMM D, YYYY')}</nobr></small>
                  </span>,
                  sortGetter: r => r.timestamp
                },
                {
                  name: 'response',
                  // width: '70%',
                  display: "API Response",
                  noSort: true,
                  getter: r => <div>
                    <ReactJson 
                      src={r} 
                      collapsed={0}
                      displayDataTypes={false}
                      sortKeys={true}
                      iconStyle="caret"
                      name={false}
                      enableClipboard={false}
                      />
                  </div>,
                },
                // {
                //   name: 'output_data',
                //   width: '50%',
                //   display: "output_data",
                //   getter: r => <div>
                //     <ReactJson 
                //       src={r.output_data} 
                //       collapsed={0}
                //       displayDataTypes={false}
                //       sortKeys={true}
                //       iconStyle="caret"
                //       name={false}
                //       enableClipboard={false}
                //       />
                //   </div>,
                // },
                // {
                //   name: 'agent_id',
                //   display: "agent_id",
                //   getter: r => r.agent_id,
                // },
                // {
                //   name: 'user_id',
                //   display: "user_id",
                //   getter: r => r.user_id,
                // },
                // {
                //   name: 'session_id',
                //   display: "session_id",
                //   getter: r => r.session_id,
                // },
                // {
                //   name: 'stateful',
                //   display: "stateful",
                //   getter: r => r.stateful,
                // }
              ]}
              />
          </div>
          <div className="flex-column-center">
            <CustomButton
              display={project.apiLogEmpty ? "Nothing more to load" : "Load More History"}
              color="grey"
              thinking={projectReducer.tryingToGetProjectAPILog}
              success={projectReducer.getProjectAPILogSuccess}
              fail={projectReducer.getProjectAPILogFail}
              disabled={project.apiLogEmpty}
              size="small"
              onClick={e => {
                dispatch(tryToGetProjectAPILog({
                  id: this.props.id,
                  startAfter: project.apiLog[project.apiLog.length - 1].id
                }));
              }}
              />
          </div>
        </div>
      </div>

    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, projectReducer, orgReducer, componentReducer } = state;

  return {
    userReducer, 
    projectReducer,
    orgReducer,
    componentReducer
  }
}

export default connect(mapStateToProps)(ProjectAPILogs);

  