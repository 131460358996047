import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import Hydrate from 'components/Hydrate/Hydrate'
import CustomField from 'kit/components/CustomField/CustomField'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import Callout from 'kit/components/Callout/Callout.js'
import Modal from 'kit/components/Modal/Modal.js'
import Checkbox from 'kit/components/Checkbox/Checkbox.js'
import CustomTable from 'kit/components/CustomTable/CustomTable.js'


import {
  tryToCreateAPIKey,
  tryToGetAPIKeys,
  tryToUpdateAPIKey,
  tryToRevokeAPIKey
} from 'actions/actions.export'

import './ProjectAPIKeys.scss';

class ProjectAPIKeys extends Component {
  constructor(props){
    super(props);


    this.state = {
      editingKey: false,
      editingKeyDisplayName: '',
      revokingKey: false
    }
  }



  componentWillMount(){
    const { dispatch } = this.props;

    dispatch(tryToGetAPIKeys({id: this.props.id}));

    // if(this.props.item){
    //   this.setState({
    //     editingKey: this.props.item,
    //   })
    // }
  }

  componentWillReceiveProps(nextProps){

    const { projectReducer } = nextProps;
    
    const project = projectReducer.cache[nextProps.id];
    if(nextProps.item && nextProps.item !== this.state.item && !projectReducer.tryingToGetAPIKeys){

      let canFind = (project.api_keys || []).find(a => a.id === nextProps.item);

      if(canFind){
        this.setState({
          editingKey: nextProps.item,
          editingKeyDisplayName: canFind.display_name
        })
      } else {
        this.setState({
          editingKey: false,
          editingKeyDisplayName: ''
        })
      }
    }
  }


  render(){

    const { dispatch, projectReducer, userReducer, orgReducer, canWrite } = this.props;

    const project = projectReducer.cache[this.props.id];

    if(!orgReducer.membershipsReady) return <span/>;

    if(!project) return <span>Failed to load project.</span>;

    let editingKey = (project.api_keys || []).find(a => a.id === this.state.editingKey) || {};
    let revokingKey = (project.api_keys || []).find(a => a.id === this.state.revokingKey) || {};

    return <div className="project-api-keys">
{/* 
      <Modal
        show={this.state.editingKey && editingKey.id}
        exitable={true}
        onExit={e => this.setState({editingKey: false})}
        cancelable={true}
        onCancel={e => this.setState({editingKey: false})}
        acceptable={true}
        acceptButtonLabel="Save"
        acceptButtonThinking={projectReducer.tryingToUpdateAPIKey}
        acceptButtonColor="black"
        acceptButtonSuccess={projectReducer.updateAPIKeySuccess}
        acceptButtonFail={projectReducer.updateAPIKeyFail}
        onAccept={e => {
          dispatch(tryToUpdateAPIKey({
            id: project.id,
            key_id: this.state.editingKey,
            display_name: this.state.editingKeyDisplayName
          }))
        }}
        content={<div>
          <h4 className="no-margin-top">Edit Key</h4>
          <CustomField
            label="Key Name"
            disabled={!canWrite}
            placeholder="Secret Key"
            value={this.state.editingKeyDisplayName}
            onChange={e => {
              this.setState({
                editingKeyDisplayName: e.value
              })
            }}
            />
        </div>}
        /> */}


      <Modal
        show={this.state.revokingKey && revokingKey.id}
        exitable={true}
        onExit={e => this.setState({revokingKey: false})}
        cancelable={true}
        onCancel={e => this.setState({revokingKey: false})}
        acceptable={true}
        acceptButtonLabel="Permanently Revoke"
        acceptButtonThinking={projectReducer.tryingToRevokeAPIKey}
        acceptButtonColor="danger"
        acceptButtonSuccess={projectReducer.revokeAPIKeySuccess}
        acceptButtonFail={projectReducer.revokeAPIKeyFail}
        onAccept={e => {
          dispatch(tryToRevokeAPIKey({
            id: project.id,
            key_id: this.state.revokingKey
          }))
        }}
        content={<div>
          <h4 className="no-margin-top">Revoke Key</h4>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td className="text-900">{revokingKey.display_name}</td>
              </tr>
              <tr>
                <td>Key:</td>
                <td className="text-900">{revokingKey.sanikey}</td>
              </tr>
            </tbody>
          </table>
          {
            revokingKey.last_used ? 
            <p>
              This key was last used {moment(revokingKey.last_used).fromNow()}
            </p>
            :
            <p>
              This key has never been used.
            </p>
          }
        </div>}
        />
      {
        (this.state.editingKey && editingKey.id) ? <div className="">
          <div className="flex-split margin-bottom-2rem">
            <div className="list-left">
              <CustomButton
                display={<span><i className="far fa-angle-left icon-before-text"/>Back to all keys</span>}
                size="small"
                color="grey"
                to={`/workbench/${project.scope}/projects/${project.id}/api_keys`}
                onClick={e => {
                  this.setState({
                    editingKey: false
                  })
                }}
                />
              <small className="text-muted">Last edited {moment(editingKey.last_updated || editingKey.created_at).fromNow() } by <Hydrate id={editingKey.last_updated_by || editingKey.created_by} type="user"/></small>
            </div>
            <div className="list-right">
              <CustomButton
                display="Permenantly Revoke Key"
                size="small"
                color="danger"
                onClick={e => {
                  this.setState({
                    revokingKey: editingKey.id
                  })
                }}
                />

              <CustomButton
                display="Save Changes"
                size="small"
                color="black"
                thinking={projectReducer.tryingToUpdateAPIKey}
                success={projectReducer.updateAPIKeySuccess}
                fail={projectReducer.updateAPIKeyFail}
                onClick={e => {
                  dispatch(tryToUpdateAPIKey({
                    id: project.id,
                    key_id: this.state.editingKey,
                    display_name: this.state.editingKeyDisplayName,
                  }))
                }}
                />
            </div>
          </div>
          <div className="box">
            <div className="row margin-bottom-2rem">
              <div className="col-md-6">
                <CustomField
                  label="Key"
                  inline={true}
                  disabled={true}
                  placeholder="Key"
                  value={editingKey.sanikey}
                  />
              </div>
              <div className="col-md-6">
                <CustomField
                  label="Name"
                  inline={true}
                  disabled={!canWrite}
                  description="A name to help you remember what this key is for."
                  placeholder="ex: Prod, Dev, etc"
                  value={this.state.editingKeyDisplayName}
                  onChange={e => {
                    this.setState({
                      editingKeyDisplayName: e.value
                    })
                  }}
                  />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {
                  editingKey.last_used && 
                  <Callout
                    style="warning"
                    title="Revoking this key may break things"
                    content={<div>
                      It was last used in an API call {editingKey.last_used ? moment(editingKey.last_used).fromNow() : 'never'} and was created by <Hydrate id={editingKey.created_by} type="user"/> on {moment(editingKey.created_at).format('MMMM D, YYYY')}.
                    </div>}
                    />
                }
              </div>
            </div>
          </div>
        </div>
        :
        <div>
          <div className="row">
            <div className="col-xl-12  margin-top-05rem margin-bottom-2rem">
              <div className="flex-split">
                <div className="list-left">
                  <div>
                    <h4 className="no-margin-top no-margin-bottom">Secret Keys</h4>
                    <small>
                      Create and manage secret API keys for this project.
                    </small>
                  </div>
                </div>
                <CustomButton
                  display={<span><i className="far fa-plus icon-before-text"/>New Key</span>}
                  color="projects"
                  size="small"
                  disabled={!canWrite}
                  thinking={projectReducer.tryingToCreateAPIKey}
                  onClick={e => {
                    dispatch(tryToCreateAPIKey({id: project.id}))
                  }}
                  />
              </div>
            </div>
          </div>
          <div className="box">
            {
              projectReducer.tryingToGetAPIKeys && <p>loading...</p>
            }

            


            <CustomTable
              rawData={project.api_keys || []}
              headers={[
                {
                  name: 'display_name',
                  display: "Name",
                  getter: r => r.display_name 
                },
                {
                  name: 'sanikey',
                  display: "Key",
                  width: 300,
                  getter: r => {
                    if(r.key){
                      return <div>
                        <span className="text-900 text-projects">{r.key}</span> <i className="far fa-copy icon-after-text text-hover-primary clickable" onClick={e => {
                          // copy to clipboard
                          navigator.clipboard.writeText(r.key);
                        }}/>
                      </div>
                    }
                    return r.sanikey;
                  }
                },
                {
                  name: 'created_on',
                  display: "Created",
                  getter: r => moment(r.created_at).format('MMM, D, YYYY')
                },
                {
                  name: 'created_by',
                  display: "Created By",
                  getter: r => <Hydrate type="user" id={r.created_by} field="display_name"/>
                },
                {
                  name: 'last_used',
                  display: "Last Used",
                  getter: r => r.last_used ? moment(r.last_used).format('MMM, D, YYYY h:mm a') : 'Never' 
                },
                {
                  name: 'edit',
                  display: " ",
                  width: 50,
                  noSort: true,
                  getter: r => <div className="list-right list-right-no-wrap">
                    <CustomButton 
                      size="small"
                      display="Edit Key"
                      disabled={!canWrite}
                      color="grey"
                      to={`/workbench/${project.scope}/projects/${project.id}/api_keys/${r.id}`}
                      />
                  </div>
                }
              ]}
              />
          </div>
        </div>
      }


    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, projectReducer, orgReducer } = state;

  return {
    userReducer, 
    projectReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(ProjectAPIKeys);

  