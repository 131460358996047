import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { push } from 'react-router-redux';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';

import './Workbench.scss';

import CustomButton from '../../kit/components/CustomButton/CustomButton'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect'
import CustomField from 'kit/components/CustomField/CustomField';
import Dropdown from '../../kit/components/Dropdown/Dropdown'
import Footer from '../../components/Footer/Footer'
import Hydrate from '../../components/Hydrate/Hydrate'
import Callout from 'kit/components/Callout/Callout';

import QueryChartWrapper from 'components/QueryChartWrapper/QueryChartWrapper';
import AbbreviateNumber from 'components/AbbreviateNumber/AbbreviateNumber';

import WorkbenchKindGallery from './WorkbenchKindGallery';
import WorkbenchSearch from './WorkbenchSearch'

import WorkbenchOrgSettings from './WorkbenchOrgSettings'
import WorkbenchOrgReporting from './WorkbenchOrgReporting'
import WorkbenchUserSettings from './WorkbenchUserSettings'

import WorkbenchPrototypeLauncher from './WorkbenchPrototypeLauncher';

import WorkbenchKind from './WorkbenchKind'

import isFeatureSwitchOn from '../../utilities/isFeatureSwitchOn'

import componentTools from '../../configs/config.component-tools.js'
import projectTools from '../../configs/config.project-tools.js'
import knowledgeTools from '../../configs/config.knowledge-tools.js'
import inquiryTools from '../../configs/config.inquiry-tools.js'

import ASCIIBackground from 'components/ASCIIBackground/ASCIIBackground.js'

import tips from 'configs/config.tips.js'

import {
  showTooltip,
  hideTooltip,
  tryToLogout,
  tryToGetProjectsByOrganization,
  tryToGetComponentsByOrganization,
  tryToGetKnowledgeByOrganization,
  tryToGetInquiriesByOrganization,
  tryToGetPrototypesByOrganization,
  toggleSideTray,
  tryToListPosts
} from '../../actions/actions.export'

import {
  generateColor
} from '../../utilities/colorUtilities'


class Workbench extends Component {
  constructor(props){
    super(props);

    this.state = {
      orgGetSent: false,
      search: "",
      searchHome: "",
      showSearch: true,
      start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      tip: tips[Math.floor(Math.random() * tips.length)]
    }

    this.getOrgDetails = this.getOrgDetails.bind(this);
  }

  componentDidMount(){

    const { dispatch, orgReducer } = this.props;

    let org_id = this.props.org_id;// || orgReducer.memberships[0].organization.id;

    if(org_id === "my-account" && this.props.page === 'settings'){

    } else if(org_id === "my-account"){
      return dispatch(push("/workbench/my-account/settings"));
    }

    let membership = orgReducer.memberships.find(m => m.organization.id === org_id);
    
    if(org_id === undefined){
      if(orgReducer.memberships.length > 0){
        org_id = orgReducer.memberships[0].organization.id;
      }
      membership = orgReducer.memberships.find(m => m.organization.id === org_id);
    }

    if(org_id) this.getOrgDetails(org_id);

    dispatch(tryToListPosts());
  }

  componentWillReceiveProps(newprops){
    const { dispatch, orgReducer } = newprops;

    if(!this.state.orgGetSent && orgReducer.membershipsReady){

      let org_id = newprops.org_id;
      if(org_id === "my-account") return;

      let membership = orgReducer.memberships.find(m => m.organization.id === org_id);
      
      if(org_id === undefined){
        if(orgReducer.memberships.length > 0){
          org_id = orgReducer.memberships[0].organization.id;
        }
        membership = orgReducer.memberships.find(m => m.organization.id === org_id);
      }

      if(!membership) dispatch(push("/new/organization"));

      if(org_id) this.getOrgDetails(org_id);
    }
  }

  getOrgDetails(org_id){
    const { dispatch } = this.props;

    if(org_id !== "my-account"){
      dispatch(tryToGetProjectsByOrganization({org_id: org_id}));
      dispatch(tryToGetComponentsByOrganization({org_id: org_id}));
      dispatch(tryToGetKnowledgeByOrganization({org_id: org_id}));
      // dispatch(tryToGetInquiriesByOrganization({org_id: org_id}));    
      dispatch(tryToGetPrototypesByOrganization({org_id: org_id}));
    }

    this.setState({
      orgGetSent: true
    })
  }
  
  render(){

    const { dispatch, projectReducer, featuresReducer, orgReducer, inquiryReducer, userReducer, componentReducer, knowledgeReducer, postReducer, guiReducer } = this.props;

    const renderedTip = <div className="workbench-tip flex-grow flex-column-center-center bg-gs0">
      <div className="margin-bottom-2rem">
        <img src={"/img/cascading-z-white-small.png"} width="50px"/>
      </div>
      <span className="text-500 text-muted">
          <i className="fal fa-spin fa-spinner-third icon-before-text"/>While we're gathering things, did you know...
        </span>
      <div className="text-center" style={{maxWidth: 500}}>
        <h3>
          {this.state.tip.text}
        </h3>
        {
          this.state.tip.link &&
          <a href={this.state.tip.link} target="_blank" className="text-400 text-muted">Learn More<small className="far fa-external-link icon-after-text"/></a>
        }
      </div>
      {/* <img src="/img/zebra-black.webp" className="workbench-tip-zebra"/> */}
    </div>
  
    if(!orgReducer.membershipsReady) return renderedTip

    let org_id = this.props.org_id;// || orgReducer.memberships[0].organization.id;
    let membership = orgReducer.memberships.find(m => m.organization.id === org_id);
    let organization = orgReducer.cache[org_id];
    if(org_id === undefined){
      if(orgReducer.memberships.length > 0){
        org_id = orgReducer.memberships[0].organization.id;
      }
      membership = orgReducer.memberships.find(m => m.organization.id === org_id);
      organization = orgReducer.cache[org_id];
    }
    if(!membership && org_id !== "my-account" && orgReducer.tryingToGetMemberships){
      return renderedTip
    } else if(!membership && org_id !== "my-account" && !orgReducer.tryingToGetMemberships){
      if(orgReducer.memberships.length === 0){
        dispatch(push("/new/organization"));
      } else {
        dispatch(push("/workbench"));
      }
      return renderedTip;
    }
    if(!membership) membership = {};
    


    let workbenchHeaderList = [
      // {
      //   url: "/workbench/" + org_id,
      //   display: "Workbench" 
      // }
    ]

    if(this.props.page === 'projects'){
      workbenchHeaderList.push({
        url: 'projects',
        display: "Projects"
      })

      if(this.props.subpage){
        let d = projectReducer.cache[this.props.subpage];
        if(d){
          workbenchHeaderList.push({
            url: this.props.subpage,
            display: d.display_name
          })

          if(this.props.subpage2){
            let tool = projectTools.find(t => t.name === this.props.subpage2);

            if(tool){
              workbenchHeaderList.push({
                url: tool.name,
                display: tool.display_name
              })
            }
          }
        }
      }
    }


    if(this.props.page === 'agents'){
      workbenchHeaderList.push({
        url: 'agents',
        display: "Agents"
      })

      if(this.props.subpage){
        let component = componentReducer.cache[this.props.subpage];
        if(component){
          workbenchHeaderList.push({
            url: this.props.subpage,
            display: component.display_name
          })

          if(this.props.subpage2){
            let tool = componentTools.find(t => t.name === this.props.subpage2);

            if(tool){
              workbenchHeaderList.push({
                url: tool.name,
                display: tool.display_name
              })

              if(this.props.subpage3 && component.versions){
                let version = component.versions.find(v => v.id === 'draft');
                if(version){
                  let content = (version[tool.name] || []).find(c => c.id === this.props.subpage3);
                  if(content){
                    workbenchHeaderList.push({
                      url: content.id,
                      display: content.display_name
                    })
                  }
                }
              }
            }
          }
        }
      }
    }



    if(this.props.page === 'knowledge'){
      workbenchHeaderList.push({
        url: 'knowledge',
        display: "Knowledge Bases"
      })

      if(this.props.subpage){
        let knowledge = knowledgeReducer.cache[this.props.subpage];
        if(knowledge){
          workbenchHeaderList.push({
            url: this.props.subpage,
            display: knowledge.display_name
          })

          if(this.props.subpage2){
            let tool = knowledgeTools.find(t => t.name === this.props.subpage2);

            if(tool){
              workbenchHeaderList.push({
                url: tool.name,
                display: tool.display_name
              })
            }
          }
        }
      }
    }


    if(this.props.page === 'inquiries'){
      workbenchHeaderList.push({
        url: 'inquiries',
        display: "Scoped Projects"
      })

      if(this.props.subpage){
        let inquiry = inquiryReducer.cache[this.props.subpage];
        if(inquiry){
          workbenchHeaderList.push({
            url: this.props.subpage,
            display: inquiry.display_name
          })

          if(this.props.subpage2){
            let tool = inquiryTools.find(t => t.name === this.props.subpage2);

            if(tool){
              workbenchHeaderList.push({
                url: tool.name,
                display: tool.display_name
              })
            }
          }
        }
      }
    }

    let workbenchHeaderFinal = [workbenchHeaderList[0]];

    if(this.props.org_id === 'my-account'){
      workbenchHeaderFinal = [];
      if(this.props.page === 'settings'){
        workbenchHeaderFinal.push({
          url: '/workbench/my-account/settings',
          display: 'Settings'
        })
      }
    }


    for (let i = 1; i < workbenchHeaderList.length; i++) {
      // if(i < workbenchHeaderList.length &&){
        workbenchHeaderFinal.push({
          url: '/', 
          display: <small style={{position: 'relative', top: -1, padding: 5}} className="no-margin text-semi-muted">/</small>
        });
      // }
      workbenchHeaderFinal.push(workbenchHeaderList[i]);
    }


    let knowledgeCacheList = Object.keys(knowledgeReducer.cache).map(id => knowledgeReducer.cache[id]);
    let projectCacheList = Object.keys(projectReducer.cache).map(id => projectReducer.cache[id]);
    let componentCacheList = Object.keys(componentReducer.cache).map(id => componentReducer.cache[id]);
    let inquiryCacheList = Object.keys(inquiryReducer.cache).map(id => inquiryReducer.cache[id]);
    
    let knowledge = [];
    let projects = [];
    let components = [];
    let inquiries = [];
    if(org_id){
      knowledge = knowledgeCacheList.filter(p => p.scope === org_id);
      projects = projectCacheList.filter(p => p.scope === org_id);
      components = componentCacheList.filter(p => p.scope === org_id);
      inquiries = inquiryCacheList.filter(p => p.scope === org_id);
    }


    let workbenchMenu = [
      {
        icon: 'fa-house',
        display: 'Home',
        url: '',
        exact: true,
        tooltip: "The central hub for all of your organization's projects, agents, and knowledge."
      },
      // {
      //   icon: 'fa-search',
      //   display: 'Search',
      //   url: '/search',
      //   tooltip: "Search your organization's agents, knowledge, and more."
      // },
      {
        icon: 'fa-robot text-projects',
        display: 'Projects',
        url: '/projects',
        tooltip: "Define, design, and develop projects that leverage your organization's agents and knowledge.",
        subItems: projects.slice(0,5),
        totalItems: projects.length,
        exact: true,
        loading: projectReducer.tryingToGetProjectsByOrganization
      },
      {
        icon: 'fa-project-diagram text-components',
        display: 'All Agent Flows',
        url: '/agents',
        tooltip: "Design, prototype, and adapt a language model for your organization.",
        // subItems: components.slice(0,3),
        // totalItems: components.length,
        exact: true,
        loading: componentReducer.tryingToGetComponentsByOrganization
      },
      {
        icon: 'fa-book-spells text-knowledge',
        display: 'All Knowledge',
        url: '/knowledge',
        tooltip: "Upload and manage information to extend your organization's agents.",
        // subItems: knowledge.slice(0,3),
        // totalItems: knowledge.length,
        exact: true,
        loading: knowledgeReducer.tryingToGetKnowledgeByOrganization
      },
      // {
      //   icon: 'fa-lightbulb-on text-inquiries',
      //   display: 'Inquiries',
      //   url: '/inquiries',
      //   tooltip: "Create focused goals & strategies for applying AI to your organization's needs.",
      //   hideIf: !isFeatureSwitchOn('inquiries_tool', userReducer, featuresReducer),
      //   subItems: inquiries.slice(0,3),
      //   loading: inquiryReducer.tryingToGetInquiriesByOrganization
      // },
    ]

    if(organization && organization.show_prototypes){
      workbenchMenu.push({
        icon: 'fa-browser text-gs10',
        display: 'Prototypes',
        url: '/prototypes',
        tooltip: "Access and test prototypes developed collaboratively between your organization and ZeroWidth."
      })
    }



    // let workbenchFooterMenu = [
    //   {
    //     icon: 'fa-user-cog',
    //     display: 'My Account',
    //     url: '/workbench/my-account/settings',
    //     tooltip: "Manage your ZeroWidth Account."
    //   },
    //   {
    //     icon: (userReducer.notifications.filter(n => !n.read).length > 0 ? 'fa-bell-on text-danger' : 'fa-bell'),
    //     display: 'Notifications',
    //     url: '/workbench/my-account/notifications',
    //     tooltip: "Check your account's notifications, invites to organizations, and more."
    //   }
    // ]

    let membershipToCheck = membership;
    if(this.props.org_id === 'my-account' && orgReducer.memberships[0]){
      membershipToCheck = orgReducer.memberships[0];
    }

    if(membershipToCheck){
      if(membershipToCheck.membership){

        if(membershipToCheck.membership.manage_billing){
          workbenchMenu.push({
            icon: 'fa-chart-line',
            display: 'Reporting & Usage',
            url: '/reporting',
            tooltip: "View your organization's usage and reporting details."
          })
        }
      }
      if(membershipToCheck.needsSettingsAccess){
        workbenchMenu.push({
          icon: 'fa-users-cog',
          display: 'Organization Settings',
          url: '/settings',
          tooltip: "Manage your organization's members and settings."
        })
      }
    }



    let mostRecents = projects.map(p => {
      return {
        ...p,
        kind: 'projects'
      }});

    mostRecents = mostRecents.concat(components.map(p => {
      return {
        ...p,
        kind: 'components'
      }}));

    mostRecents = mostRecents.concat(knowledge.map(p => {
      return {
        ...p,
        kind: 'knowledge'
      }}));

    mostRecents = mostRecents.concat(inquiries.map(p => {
      return {
        ...p,
        kind: 'inquiries'
      }}
    ));

    mostRecents = mostRecents.sort((a,b) => {
      return new Date(b.last_updated) - new Date(a.last_updated);
    });


    // set this to true if you have at least one provider enabled OR if you are not managing billing so you shouldnt see it anyway
    let hasAtLeastOneEnabledProvider = false;
    if(membership){
      if(membership.membership){
        if(membership.membership.manage_billing){
          if(organization){
            if(organization.enabled_providers){
              for(var i in organization.enabled_providers){
                if(organization.enabled_providers[i]){
                  hasAtLeastOneEnabledProvider = true;
                  break;
                }
              }
            }
          }
        } else {
          hasAtLeastOneEnabledProvider = true;
        }
      } else {
        hasAtLeastOneEnabledProvider = true;
      }
    } else {
      hasAtLeastOneEnabledProvider = true;
    }
    


    // if we're on your account then swap the left menu with a simple back button to load root /workbench
    if(org_id === "my-account"){
      // if you're in at least one organization, show the back to workbench button
      if(orgReducer.memberships.length > 0){

        workbenchMenu = [
          {
            icon: 'fa-fw fas fa-arrow-alt-left',
            display: 'Back to Workbench',
            rootUrl: '/workbench'
          }
        ]
      } else {
        // if you're not in any organizations, show a create button
        workbenchMenu = [
          {
            icon: 'fa-fw fa-plus-square',
            display: 'Create Organization',
            rootUrl: '/new/organization'
          }
        ]
      }
    }


    // determine if we need to hide things due to width breakpoint
    // 1 = mobile, 2 = tablet, 3 = desktop
    let breakpoint = 3
    if(window.innerWidth < 1200){
      breakpoint = 2;
    }
    if(window.innerWidth < 768){
      breakpoint = 1;
    }

    return <div className={"workbench"} key={org_id}>
      <div className="workbench-header flex-split">
        <div className="list-left">
          <Link to={"/"} className="header-brand-logo">
            <img src={"/img/cascading-z-white.png"} width="33"/>
          </Link>
          {
            org_id === "my-account" ?
            <div className="no-margin">My Account</div>
            :
            <div style={{maxWidth: 200}} className="no-margin">
              <CustomSelect
                value={org_id}
                inline={true}
                width={200}
                minWidth={200}
                maxWidth={200}
                options={(orgReducer.memberships || []).map((m,i)=>{
                  return {
                      label: <div className="list-left text-ellipsis list-left-no-wrap">
                      <span style={{marginRight: "1rem"}}>
                        <Hydrate
                          type="organization"
                          mode="avatar"
                          id={m.organization.id}
                          size={20}
                          />
                      </span>
                      <div className="text-ellipsis no-margin-right">{m.organization.display_name}</div>
                    </div>,
                    value: m.organization.id
                  }
                }).concat([{
                  label: "New Organization",
                  value: "new"
                }])}
                onChange={e => {
                  if(e === "new"){
                    return dispatch(push("/new/organization"));  
                  }
                  dispatch(push("/workbench/" + e));
                  this.getOrgDetails(e);
                }}
                />
            </div>
          }
          {
            org_id === "my-account" ?
            <small style={{position: 'relative', top: 0, padding: 5}} className="no-margin text-semi-muted">/</small>
            :
            <small style={{position: 'relative', top: 0, padding: 5}} className="no-margin text-semi-muted"></small>
          }
          {
            breakpoint > 2 && workbenchHeaderFinal.map((item, i)=>{
              if(!item) return null;

              if(item.url === '/') return <span key={i} className="no-margin">{item.display}</span>;
              let fullUrl = workbenchHeaderFinal.slice(0, i).map(c => c.url).join('');

              
              return <Link to={'/workbench/' + org_id + '/'  + fullUrl + item.url} key={i} style={{maxWidth: 200}} className={"text-ellipsis-parent link-no-decoration text-400 no-margin " + (i === workbenchHeaderFinal.length - 1  && workbenchHeaderFinal.length > 1 ? "text-900" : "")}>
                <span className="no-margin text-ellipsis" style={{position: 'relative', top: 2 }} >{item.display}</span>
              </Link>

            })
          }

        </div>
        <div className="list-right">
          {
            breakpoint === 3 &&
          
            <form className="list-right workbench-search-form" onSubmit={e => {
              e.preventDefault();
              dispatch(push("/workbench/" + org_id + "/search?query=" + this.state.search));
              }}>
              <div className={"workbench-search-form-input no-margin " + (this.state.showSearch ? "workbench-search-form-input-open" : "")}>
                <CustomField
                  id="search"
                  inline={true}
                  name="search"
                  icon="fas fa-search"
                  type="text"
                  minWidth={300}
                  placeholder={"search your agents and more..."}
                  value={this.state.search}
                  onChange={e => {
                    this.setState({search: e.value});
                  }}
                  />
                <button type="submit" style={{display: 'none'}}/>
              </div>
              <i className="fas fa-search workbench-search-header-icon no-margin clickable fa-fw" onClick={e => {
                // this.setState({
                //   showSearch: !this.state.showSearch
                // })
                // document.getElementById('field_search_search').focus();
                
                // if trimmed search is empty, just go to search page
                if(this.state.search.trim() === ""){
                  return dispatch(push("/workbench/" + org_id + "/search"));
                } else {
                  dispatch(push("/workbench/" + org_id + "/search?query=" + this.state.search));
                }
              }}/>
              
            </form>
          }
          

          <Link to={"/workbench/my-account/settings"} className="link-no-decoration text-400 margin-left-1rem">
            <span><i className="far fa-fw fa-cog"/></span>
          </Link>

          <Link to={"/workbench/my-account/settings/notifications"} className="link-no-decoration text-400">
            {
              userReducer.notifications.filter(n => !n.read).length > 0 ?
              <span className="text-danger"><i className="far fa-fw fa-bell-on"/></span>
              :
              <span><i className="far fa-fw fa-bell"/></span>
            }
          </Link>

          <Dropdown
            target={<div className="dropdown-toggle-default list-right list-right-marginless">
                <div className={userReducer.socketConnected ? "avatar-online" : "avatar-offline"}>
                  <Hydrate
                    type="me"
                    mode="avatar"
                    />
                </div>
                &nbsp;
                <i className="fas fa-angle-down fa-fw"/>
            </div>}
            align="right"
            items={[
                <Link to={"/"}>
                  <i className="far fa-fw fa-house-user icon-before-text"/> Home
                </Link>,
                <Link to={"/workbench/my-account/settings/notifications"}>
                  <i className="far fa-fw fa-bell icon-before-text"/> Notifications
                </Link>,
                <Link to="/workbench/my-account/settings">
                  <i className="far fa-fw fa-user-cog icon-before-text"/> Account Settings
                </Link>,
                "divider",
                <Link to={"/docs"}>
                  <i className="far fa-fw fa-book icon-before-text"/> Docs
                </Link>,

                isFeatureSwitchOn('blog',userReducer,featuresReducer) ?
                <Link to={"/blog"}>
                  <i className="far fa-fw fa-typewriter icon-before-text"/> Blog
                </Link>
                :
                undefined,
                // <Link to={"/help"}>
                //   <i className="far fa-fw fa-info-circle icon-before-text"/> Help Center
                // </Link>,
                
                "divider",
                <span onClick={() => { dispatch(tryToLogout()) }}>
                  <i className="far fa-fw fa-door-closed icon-before-text"/> Log Out
                </span>
              ]}
            />
        </div>
      </div>
      
      

      <div className="workbench-header-bumper"/>
      <div className="workbench-menu-body-wrapper">
        <div className={"workbench-menu " + (guiReducer.collapsedSideTray ? "workbench-menu-closed": "workbench-menu-open")}>
          <div className="workbench-menu-wrapper">
            <div>
              {
                workbenchMenu.map((item, i) => {
                  if(item.hideIf) return;

                  let org_id_to_use = org_id;
                  if(org_id === "my-account" && orgReducer.memberships[0]) org_id_to_use = orgReducer.memberships[0].organization.id;
                  
                  let isActive = false;
                  if(item.exact){
                    isActive = window.location.pathname === '/workbench/' + org_id_to_use + item.url;
                  } else {
                    isActive = window.location.pathname.indexOf('/workbench/' + org_id_to_use + item.url) > -1;
                  }


                  let url_to_use = '/workbench/' + org_id_to_use + item.url;
                  if(item.rootUrl){
                    url_to_use = item.rootUrl;
                    isActive = window.location.pathname === url_to_use;
                  }

                  return <div key={i}>
                      <Link   
                        className={"workbench-menu-link " + (isActive ? "workbench-menu-link-active" : "")} to={url_to_use}
                        onMouseEnter={e => {
                          dispatch(showTooltip({
                            el: e.target,
                            position: 'right',
                            lag: 500,
                            nobr: false,
                            content: <div style={{maxWidth: 250}}>
                              <h5 className="no-margin">{item.display}</h5>
                              <span className="text-400">{item.tooltip}</span>
                            </div>
                          }));
                        }}
                        onClick={e => {
                          dispatch(hideTooltip());
                        }}
                        onMouseLeave={e => {
                          dispatch(hideTooltip());
                        }}
                        >
                        <div className="flex-split flex-grow">
                          <div>
                            <i className={"fal " + item.icon + " fa-fw workbench-menu-link-icon"}/>
                            <span className="workbench-menu-link-text">{item.display}</span>
                          </div>
                          {
                            item.subItems && <Link 
                              to={"/workbench/" + org_id_to_use + item.url + '/new'} 
                              className="workbench-menu-link-new-icon"
                              onClick={e => {
                                e.stopPropagation();
                              }}
                              >
                              <i className="fas fa-plus  margin-right-1rem"/>
                            </Link>
                          }
                        </div>
                      </Link>
                    {
                      item.subItems &&
                      <div className="workbench-menu-link-subitems">
                        {
                          item.subItems.map((subItem, j) => {
                            return <Link 
                              className={"text-ellipsis workbench-menu-link-subitem " + (window.location.pathname.indexOf(subItem.id) > -1 ? "workbench-menu-link-subitem-active" : "")}
                              to={"/workbench/" + org_id_to_use + item.url + '/' + subItem.id}
                              key={j}
                              >
                              <span className="workbench-menu-link-subitem-text">{subItem.display_name}</span>
                            </Link>
                          })
                        }

                        {
                          item.loading &&
                          <div className="workbench-menu-link-subitem">
                            <div className="workbench-menu-link-subitem-loading text-gs10 text-muted">
                              <i className="fas fa-spinner-third fa-spin fa-fw"/>
                            </div>
                          </div>
                        }

                        {/* new item link */}
                        {
                          item.totalItems > 3 &&
                          <Link className={"text-ellipsis-1-line workbench-menu-link-subitem"} to={"/workbench/" + org_id_to_use + item.url}>
                            <span className="workbench-menu-link-subitem-text">
                              ...
                            </span>
                          </Link>
                        }
                        {
                          (item.subItems.length === 0 && !item.loading) &&
                          <Link 
                            to={"/workbench/" + org_id_to_use + item.url + '/new'} 
                            onClick={e => {
                              e.stopPropagation();
                            }}
                            className="workbench-menu-link-subitem"
                            >
                            <span className="workbench-menu-link-subitem-text">
                              Create your first
                            </span>
                          </Link>
                        }
                      </div>
                    }
                  </div>
                })
              }


            </div>
            <div>
              {/* {
                workbenchFooterMenu.map((item, i) => {
                  let isActive = window.location.pathname.indexOf(item.url) > -1;
                  
                  return <Link className={"workbench-menu-link " + (isActive ? "workbench-menu-link-active" : "")} to={item.url}>
                    <i className={"fal " + item.icon + " fa-fw workbench-menu-link-icon"}/>
                    <span className="workbench-menu-link-text">{item.display}</span>
                  </Link>
                })
              } */}
              <div className="workbench-menu-link" onClick={e => {
                dispatch(toggleSideTray())
              }}>
                {
                  guiReducer.collapsedSideTray ?
                  <i className="fal fa-angle-double-right fa-2x fa-fw workbench-menu-link-icon"/>
                  :
                  <i className="fal fa-angle-double-left fa-2x fa-fw workbench-menu-link-icon"/>
                }
                <span className="workbench-menu-link-text text-muted text-400">Hide</span>
              </div>
            </div>
          </div>
        </div>
        <div className="workbench-body flex-grow scroll-parent">
          <div className="scroll-child">
            
            {
              !this.props.page &&
              
              <div className="workbench-body-inner workbench-body-padding workbench-grey-bg">
                {
                  (!hasAtLeastOneEnabledProvider && organization.type !== 'enterprise') && 
                  <div className="row">
                    <div className="col-md-9">
                      <Callout
                        style="warning"
                        dismissID={"workbench_no_api_keys"}
                        dismissable={true}
                        title="No API Keys Set!"
                        content={
                          membership.membership.manage_billing ?
                          <div>
                            <div className="margin-bottom-2rem">
                              It looks like your organization hasn't setup any models yet. Agents will currently only work if there are API credits remaining for your organization. To enable a model, go to your organization settings and set <Link to={"/workbench/" + org_id + "/settings/third-party-keys"}>Third Party Keys</Link> for the foundational model providers you wish to enable.
                            </div>
                            <div className="list-right">
                              <CustomButton
                                display={<span><i className="fas fa-fw fa-key icon-before-text"/>Add an API Key</span>}
                                to={"/workbench/" + org_id + "/settings/third-party-keys"}
                                size="small"
                                color="black"
                              />
                            </div>
                          </div>
                          :
                          <div>
                            <p className="no-margin-bottom">
                              It looks like you organization hasn't enabled any models yet. Agents will currently only work if there are API credits remaining for your organization. To enable a model, contact your organization's administrator.
                            </p>
                          </div>
                        }
                        />
                    </div>
                  </div>
                }
                  
                <div className="row">
                 
                  <div className="col">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="flex-split margin-bottom-2rem">
                          <Dropdown
                              items={[
                                // <Link to={"/workbench/" + org_id + "/projects/new"}><i className="far fa-fw fa-lightbulb-on icon-before-text text-inquiries"/>New Project</Link>,
                                <Link to={"/workbench/" + org_id + "/projects/new"}><i className="far fa-fw fa-globe icon-before-text text-projects"/>New Project</Link>,
                                <Link to={"/workbench/" + org_id + "/agents/new"}><i className="far fa-fw fa-robot icon-before-text text-components"/>New Agent Flow</Link>,
                                <Link to={"/workbench/" + org_id + "/knowledge/new"}><i className="far fa-fw fa-book-spells icon-before-text text-knowledge"/>New Knowledge Base</Link>,
                              ]}
                              target={
                                <CustomButton
                                  size="small"
                                  display={<span><i className="far fa-plus icon-before-text"/>New</span>}
                                  color="black"
                                  />
                              }
                              />
                          <form onSubmit={e => {
                          e.preventDefault();
                          dispatch(push("/workbench/" + org_id + "/search?query=" + this.state.searchHome));
                        }} className="flex-split flex-grow ">
                          
                            <div className="flex-grow margin-left-1rem margin-right-1rem">
                            <CustomField
                              id="search"
                              inline={true}
                              name="search"
                              icon="fas fa-search"
                              type="text"
                              value={this.state.searchHome}
                              onChange={e => {
                                this.setState({searchHome: e.value});
                              }}
                              />
                            </div>
                            <CustomButton
                              display={<span><i className="fas fa-search"/></span>}
                              type="submit"
                              onClick={e => {
                                dispatch(push("/workbench/" + org_id + "/search?query=" + this.state.searchHome));
                              }}
                              color="grey"
                              size="small"
                              />
                            <button type="submit" style={{display: 'none'}}/>
                          </form>
                        </div>
                        
                        <div className="row">
                          <div className="col-md-4">
                            <small className="text-900 text-uppercase text-gs5">
                              Recently Updated{
                                (projectReducer.tryingToGetProjectsByOrganization || componentReducer.tryingToGetComponentsByOrganization || knowledgeReducer.tryingToGetKnowledgeByOrganization || inquiryReducer.tryingToGetInquiriesByOrganization) &&
                                <i className="fas fa-fw fa-spinner-third fa-spin icon-after-text"/>
                              }
                            </small>
                            <div className=" margin-top-1rem">

                              {
                                mostRecents.slice(0,5).map((item, i) => {
                                  return <div key={i} className="">
                                    <Hydrate
                                      type={item.kind}
                                      mode="thumbnail"
                                      id={item.id}
                                      />
                                  </div>
                                })
                              }

                              {
                                (mostRecents.length === 0 && !projectReducer.tryingToGetProjectsByOrganization && !componentReducer.tryingToGetComponentsByOrganization && !knowledgeReducer.tryingToGetKnowledgeByOrganization && !inquiryReducer.tryingToGetInquiriesByOrganization) &&
                                <div className="box box-placeholder">
                                  <strong className="text-muted">
                                    <i className="fas fa-fw fa-exclamation-triangle icon-before-text"/>
                                    No activity yet!
                                  </strong>
                                  <p className="thin-line-height text-muted">
                                    <small>Get started by creating a new project, agent, or knowledge base.</small>
                                  </p>
                                </div>
                              }

                            </div>
                          </div>
                          <div className="col-md-8">
                            <small className="text-900 text-uppercase text-gs5">Usage Trends Last 30 Days</small>
                            <QueryChartWrapper
                              queryType={'organization'}
                              cacheID={org_id + '_alltime'}
                              orgID={org_id}
                              query={'getTotalTokensByOrg'}
                              chartTitle={''}
                              parameters={{
                                start_date: this.state.start_date,
                                end_date: this.state.end_date
                              }}
                              chart={data => {
                                let stacks = 0;
                                let calls = 0;
                                let tokens = 0;
                                let characters = 0;
                                
                                if(data[0] && data[0].unique_stack_ids) stacks = parseInt(data[0].unique_stack_ids);
                                if(data[0] && data[0].total_api_calls) calls = parseInt(data[0].total_api_calls);
                                if(data[0] && data[0].total_tokens_used) tokens = parseInt(data[0].total_tokens_used);
                                if(data[0] && data[0].total_characters_used) characters = parseInt(data[0].total_characters_used);

                                return <div className="row">
                                  <div className="col">
                                    <div className="box">
                                      <div>
                                        <small className="text-gs5 text-uppercase text-900">Total Tokens (Input + Output)</small>
                                      </div>
                                      <h2 className="no-margin">
                                        <AbbreviateNumber value={tokens.toLocaleString()}/>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="box">
                                      <div>
                                        <small className="text-gs5 text-uppercase text-900">Total Agent Calls</small>
                                      </div>
                                      <h2 className="no-margin">
                                        <AbbreviateNumber value={calls.toLocaleString()}/>
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              }}
                              />
                            <div className="row margin-top-1rem">
                              <div className="col-md-12 margin-bottom-1rem">
                                <div className="box">
                                  <QueryChartWrapper
                                    queryType={'organization'}
                                    cacheID={this.props.org_id}
                                    orgID={org_id}
                                    query={'getApiUsageByDay'}
                                    parameters={{
                                      start_date: this.state.start_date,
                                      end_date: this.state.end_date
                                    }}
                                    height={'25rem'}
                                    chartTitle={'Tokens Processed per Day'}
                                    chart={rawData => {

                                      const  transformData = (data, startDate, endDate, valueKey) => {

                                        // Generate all dates between startDate and endDate
                                        let currentDate = moment(startDate);
                                        const end = moment(endDate);
                                        const allDates = [];
                                        while (currentDate <= end) {
                                            allDates.push(currentDate.format('YYYY-MM-DD'));
                                            currentDate = currentDate.add(1, 'days');
                                        }
                                    
                                        // Initialize the result array with all dates and default values
                                        const result = allDates.map(date => ({ day: date }));
                                    
                                        // Iterate over the data and fill in the counts or costs
                                        data.forEach(item => {
                                            const formattedDay = moment(item.day).format('YYYY-MM-DD');
                                            const resultEntry = result.find(entry => entry.day === formattedDay);
                                            if(resultEntry === undefined) return;
                                    
                                            // Use transaction_count or use total_tokens
                                            resultEntry['sum'] = item[valueKey];
                                        });
                                    
                                        return result;
                                      }
                                      
                                      const data = transformData(rawData, this.state.start_date, this.state.end_date, 'total_tokens');
                                      
                                      const CustomTooltip = ({ active, payload, label }) => {
                                      
                                        if (active && payload && payload.length) {
                                          return (
                                            <div className="recharts-default-tooltip">
                                              <div>
                                                <strong><i className="fas fa-square icon-before-text" style={{color: generateColor(0, 1)}}/>Tokens:</strong> {parseInt(payload[0].payload['sum']).toLocaleString()}
                                              </div>
                                            </div>
                                          );
                                        }

                                        return null;
                                      };

                                      return <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                          width={500}
                                          height={300}
                                          data={data}
                                          margin={{
                                            top: 5,
                                            right: 0,
                                            left: 0,
                                            bottom: 0,
                                          }}
                                        >
                                          <CartesianGrid strokeDasharray="3 3"/>
                                          <XAxis dataKey="day" tickSize={8} tickMargin={5}/>
                                          <YAxis axisLine={false} tickSize={0} tickMargin={5} tickFormatter={v => { return parseInt(v).toLocaleString()}}/>
                                          <Tooltip content={<CustomTooltip />} />
                                          <Bar dataKey={'sum'} activeBar={false} stackId={'a'} fill={generateColor(0, 1)}/>
                                        </BarChart>
                                      </ResponsiveContainer>
                                    }}
                                    />
                                </div>
                              </div>
                              <div className="col-md-12 margin-bottom-1rem">
                                <div className="box">
                                  <QueryChartWrapper
                                    queryType={'organization'}
                                    cacheID={this.props.org_id}
                                    orgID={org_id}
                                    query={'getApiUsageByDay'}
                                    parameters={{
                                      start_date: this.state.start_date,
                                      end_date: this.state.end_date
                                    }}
                                    height={'25rem'}
                                    chartTitle={'Agent Calls per Day'}
                                    chart={rawData => {

                                      const  transformData = (data, startDate, endDate, valueKey) => {

                                        // Generate all dates between startDate and endDate
                                        let currentDate = moment(startDate);
                                        const end = moment(endDate);
                                        const allDates = [];
                                        while (currentDate <= end) {
                                            allDates.push(currentDate.format('YYYY-MM-DD'));
                                            currentDate = currentDate.add(1, 'days');
                                        }
                                    
                                        // Initialize the result array with all dates and default values
                                        const result = allDates.map(date => ({ day: date }));
                                    
                                        // Iterate over the data and fill in the counts or costs
                                        data.forEach(item => {
                                            const formattedDay = moment(item.day).format('YYYY-MM-DD');
                                            const resultEntry = result.find(entry => entry.day === formattedDay);
                                            if(resultEntry === undefined) return;
                                    
                                            // Use transaction_count or use total_tokens
                                            resultEntry['sum'] = item[valueKey];
                                        });
                                    
                                        return result;
                                      }
                                      
                                      const data = transformData(rawData, this.state.start_date, this.state.end_date, 'transaction_count');
                                      
                                      const CustomTooltip = ({ active, payload, label }) => {
                                        
                                        if (active && payload && payload.length) {
                                          return (
                                            <div className="recharts-default-tooltip">
                                              <div>
                                                <strong><i className="fas fa-square icon-before-text" style={{color: generateColor(0, 1)}}/>Agent Calls:</strong> {payload[0].payload['sum']}
                                              </div>
                                            </div>
                                          );
                                        }

                                        return null;
                                      };

                                      return <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                          width={500}
                                          height={300}
                                          data={data}
                                          margin={{
                                            top: 5,
                                            right: 0,
                                            left: 0,
                                            bottom: 0,
                                          }}
                                        >
                                          <CartesianGrid strokeDasharray="3 3"/>
                                          <XAxis dataKey="day" tickSize={8} tickMargin={5}/>
                                          <YAxis axisLine={false} tickSize={0} tickMargin={5} tickFormatter={v => { return parseInt(v)}}/>
                                          <Tooltip content={<CustomTooltip />} />
                                          <Bar dataKey={'sum'} activeBar={false} stackId={'a'} fill={generateColor(0, 1)}/>
                                        </BarChart>
                                      </ResponsiveContainer>
                                    }}
                                    />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        {
                          postReducer.list.length > 0 &&
                          <div>
                            <div className="margin-bottom-1rem">
                              <small className="text-900 text-uppercase text-gs5">
                                Recent Posts from ZeroWidth
                              </small>
                            </div>
                          {
                            postReducer.list.slice(0,3).map((post, i) => {
                              return <Link to={"/blog/" + post.slug} className="box box-half-pad link-no-decoration box-clickable" key={i}>
                                <h5 className="no-margin">{post.title}</h5>
                                <small className="text-muted text-400">{moment(post.created_at).format('MMMM D, YYYY')}</small>
                              </Link>
                            })
                          }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {
              this.props.page === 'search' &&
              <div className="workbench-body-inner workbench-body-padding workbench-grey-bg">
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col-md-12">
                        <WorkbenchSearch org_id={org_id}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {
              (this.props.page === 'inquiries' && (!this.props.subpage || this.props.subpage === 'new')) &&
              <div className="workbench-body-inner workbench-body-padding workbench-grey-bg">
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col-md-12">
                        <WorkbenchKindGallery org_id={org_id} kind="inquiries" new={this.props.subpage === 'new'}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }


            {
              (this.props.page === 'agents' && (!this.props.subpage || this.props.subpage === 'new')) &&
              <div className="workbench-body-inner workbench-body-padding workbench-grey-bg">
                <div className="row">
                 
                  <div className="col">
                    <div className="row">
                      <div className="col-md-12">
                        <WorkbenchKindGallery org_id={org_id} kind="components" new={this.props.subpage === 'new'}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }


            {
              (this.props.page === 'projects' && (!this.props.subpage || this.props.subpage === 'new')) &&
              <div className="workbench-body-inner workbench-body-padding workbench-grey-bg">
                <div className="row ">
                  <div className="col">
                    <div className="row">
                      <div className="col-md-12">
                        <WorkbenchKindGallery org_id={org_id} kind="projects" new={this.props.subpage === 'new'}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }



            {
              (this.props.page === 'knowledge' && (!this.props.subpage || this.props.subpage === 'new')) &&
              <div className="workbench-body-inner workbench-body-padding workbench-grey-bg">
                <div className="row ">
                  <div className="col">
                    <div className="row">
                      <div className="col-md-12">
                        <WorkbenchKindGallery org_id={org_id} kind="knowledge" new={this.props.subpage === 'new'}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {
              (this.props.page === 'prototypes') &&
              <div className="workbench-body-inner workbench-body-padding workbench-grey-bg">
                <div className="row ">
                  <div className="col">
                    <div className="row">
                      <div className="col-md-12">
                        <WorkbenchPrototypeLauncher org_id={org_id}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {
              (this.props.page === 'settings' && org_id !== 'my-account') &&
              <div className="workbench-body-inner flex-column-stretch ">
                <WorkbenchOrgSettings org_id={org_id} page={this.props.subpage}/>
              </div>
            }


{
              (this.props.page === 'reporting' && org_id !== 'my-account') &&
              <div className="workbench-body-inner flex-column-stretch workbench-body-padding workbench-grey-bg">
                <WorkbenchOrgReporting org_id={org_id} page={this.props.subpage}/>
              </div>
            }

            

            {
              (this.props.page === 'settings' && org_id === 'my-account') &&
              <div className="workbench-body-inner flex-column-stretch">
                <WorkbenchUserSettings org_id={org_id} page={this.props.subpage}/>
              </div>
            }

            {/* {
              (this.props.page === 'notifications' && org_id === 'my-account') &&
              <div className="workbench-body-inner flex-column-stretch">
                <UserNotifications/>
              </div>
            } */}


            {
              (this.props.page === 'inquiries' && (this.props.subpage && this.props.subpage !== 'new')) &&
              <div className="workbench-body-inner flex-column-stretch">
                <WorkbenchKind 
                  org_id={org_id} 
                  kind='inquiries'
                  kind_id={this.props.subpage} 
                  page={this.props.subpage2}
                  subpage={this.props.subpage3}
                  pages={[
                    this.props.subpage, 
                    this.props.subpage2,
                    this.props.subpage3,
                    this.props.subpage4,
                    this.props.subpage5
                  ]}
                  />
              </div>
            }

            {
              (this.props.page === 'agents' && (this.props.subpage && this.props.subpage !== 'new')) &&
              <div className="workbench-body-inner flex-column-stretch">
                <WorkbenchKind 
                  org_id={org_id} 
                  kind='components'
                  kind_id={this.props.subpage} 
                  page={this.props.subpage2}
                  subpage={this.props.subpage3}
                  pages={[
                    this.props.subpage, 
                    this.props.subpage2,
                    this.props.subpage3,
                    this.props.subpage4,
                    this.props.subpage5
                  ]}
                  />
              </div>
            }

            {
              (this.props.page === 'projects' && (this.props.subpage && this.props.subpage !== 'new')) &&
              <div className="workbench-body-inner flex-column-stretch">
                <WorkbenchKind 
                  org_id={org_id} 
                  kind='projects'
                  kind_id={this.props.subpage} 
                  page={this.props.subpage2}
                  subpage={this.props.subpage3}
                  pages={[
                    this.props.subpage, 
                    this.props.subpage2,
                    this.props.subpage3,
                    this.props.subpage4,
                    this.props.subpage5
                  ]}
                  />
              </div>
            }

            {
              (this.props.page === 'knowledge' && (this.props.subpage && this.props.subpage !== 'new')) &&
              <div className="workbench-body-inner flex-column-stretch">
                <WorkbenchKind 
                  org_id={org_id} 
                  kind='knowledge'
                  kind_id={this.props.subpage} 
                  page={this.props.subpage2}
                  subpage={this.props.subpage3}
                  pages={[
                    this.props.subpage, 
                    this.props.subpage2,
                    this.props.subpage3,
                    this.props.subpage4,
                    this.props.subpage5
                  ]}
                  />
              </div>
            }

            {
              (
                !this.props.page || 
                this.props.page === 'search' ||
                (this.props.page === 'reporting' && org_id !== 'my-account') ||
                (
                  (
                    this.props.page === 'inquiries' ||
                    this.props.page === 'agents' ||
                    this.props.page === 'projects' ||
                    this.props.page === 'knowledge'
                  ) 
                  && (!this.props.subpage || this.props.subpage === 'new')
                )
              ) &&  
              <div className="workbench-grey-bg">
                <Footer simple={true} noMargin={true}/>
              </div> 
            }

          </div>
        </div>
      </div>
    </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, orgReducer, featuresReducer, projectReducer, inquiryReducer, guiReducer, componentReducer, knowledgeReducer, postReducer  } = state;

  return {
    userReducer,
    orgReducer,
    featuresReducer,
    inquiryReducer,
    projectReducer,
    guiReducer, 
    componentReducer, 
    knowledgeReducer,
    postReducer
  }
}

export default connect(mapStateToProps)(Workbench);
