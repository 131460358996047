import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

import {lighten, desaturate} from '../../utilities/colorConverter';

import './VisualProgrammingCanvas.scss';

const VisualProgrammingCanvas = ({ initialNodes, initialLinks, onNodeClick, selectedNodeId, onLinkClick, selectedLinkId, onNodeChange, onLinkChange, categories = [], bbox}) => {
    const svgRef = useRef(null);
    const containerRef = useRef(null);
    const overlayRef = useRef(null);
    const compassArrowRef = useRef(null);
    const linkContainerRef = useRef(null);
    const nodeContainerRef = useRef(null);
    const draggingLink = useRef(null);
    const invertedDraggingLink = useRef(null);
    const mouseoverNodeRef = useRef(null);
    const [nodes, setNodes] = useState([]);
    const [links, setLinks] = useState([]);
    const [pendingLinkCount, setPendingLinkCount] = useState(0);
    const [currentZoom, setCurrentZoom] = useState(d3.zoomIdentity);
    const nodeWidth = 200;
    const nodeHeight = 100;
    const nodePadding = 8;
    const draggedNodeRef = useRef(null); // Ref to keep track of the dragged node
    const [dimensions, setDimensions] = useState({ width: 1000, height: 1000 });
    const compassSize = 5;
    const arrowSize = 10;
    const canvasSize = 10000;
    const connectorDragRef = useRef(null);
    const invertedConnectorDragRef = useRef(null);
  
    // Define a line generator for S-curved lines
    const lineGenerator = d3.line()
      .curve(d3.curveBundle.beta(1) )
      .x(d => d.x)
      .y(d => d.y);

    useEffect(() => {
      // console.log(bbox, svgRef.current);
      if (!bbox || !svgRef.current) return;

      const svgElement = d3.select(svgRef.current);
    
      if (!svgElement.empty()) {
        
        // Calculate the transformation based on bbox
        const bboxCenterX = bbox.x + bbox.width / 2;
        const bboxCenterY = bbox.y + bbox.height / 2;
        const scale = Math.min(dimensions.width / bbox.width, dimensions.height / bbox.height) * 0.8;
        const translateX = dimensions.width / 2 - scale * bboxCenterX;
        const translateY = dimensions.height / 2 - scale * bboxCenterY;
        const zoomTransform = `translate(${translateX},${translateY}) scale(${scale})`;
      
        // Apply the transformation to containerRef.current
        if(containerRef.current && d3.select(containerRef.current)){
          if(d3.select(containerRef.current).attr){
            d3.select(containerRef.current).attr('transform', zoomTransform);
          }
        }
      }
    

    }, [bbox, svgRef]);


    useEffect(() => {
      // Resize observer to update dimensions
      const observeTarget = svgRef.current;
      const resizeObserver = new ResizeObserver(entries => {
          if (!entries || entries.length === 0) {
              return;
          }
          const entry = entries[0];
          setDimensions({
              width: entry.contentRect.width,
              height: entry.contentRect.height
          });
          d3.select(svgRef.current).attr("viewBox", [-entry.contentRect.width / 2, -entry.contentRect.height / 2, entry.contentRect.width, entry.contentRect.height])
          overlayRef.current.attr('transform', `translate(${-entry.contentRect.width / 2 + compassSize + 20 }, ${entry.contentRect.height / 2 - compassSize - 20})`);
      });

      // Start observing
      if (observeTarget) {
          resizeObserver.observe(observeTarget);
      }

      // Cleanup
      return () => {
          if (observeTarget) {
              resizeObserver.unobserve(observeTarget);
          }
      };
    }, [svgRef]);

    // Initialize the SVG canvas and zoom/pan functionality
    useEffect(() => {
      
      const { width, height } = dimensions;

      if (width === 0 || height === 0) return;
  
      // Remove any previous SVG contents
      d3.select(svgRef.current).selectAll("*").remove();
  
      // Create SVG and container elements
      const svg = d3.select(svgRef.current)
          .attr("viewBox", [-width / 2, -height / 2, width, height])
          .on('click', () => {
            onNodeClick(null);
            onLinkClick(null);
          });
  
      containerRef.current = svg.append("g").attr('class', 'container');


      const defs = svg.append('defs')
      
      defs.append('marker')
        .attr('id', 'arrowhead')
        .attr('markerWidth', 10)
        .attr('markerHeight', 7)
        .attr('refX', 1) // Adjust refX to move the tip a bit back
        .attr('refY', 3.5)
        .attr('orient', 'auto')
        .append('polygon')
            .attr('points', '0 0, 8 3.5, 0 7'); // Adjust tip point to make less pointy

      defs.append('pattern')
        .attr('id', 'dotGrid')
        .attr('width', 20)
        .attr('height', 20)
        .attr('patternUnits', 'userSpaceOnUse')
        .append('circle')
        .attr('cx', 10)
        .attr('cy', 10)
        .attr('r', 1)
        .attr('fill', 'black')
        .attr('opacity', .25)
        ;

      containerRef.current.append('rect')
        .attr('class', 'vpc-background')
        .attr('x', -canvasSize/2)
        .attr('y', -canvasSize/2)
        .attr('width', canvasSize)
        .attr('height', canvasSize)
        .attr('fill', 'url(#dotGrid)')
        ;
      
      containerRef.current.append('path')
        .attr('id', 'vpc-link-in-progress')

      linkContainerRef.current = containerRef.current.append('g').attr('class', 'link-container');
      nodeContainerRef.current = containerRef.current.append('g').attr('class', 'node-container');


      overlayRef.current = svg.append('g')
        .attr('transform', `translate(${-width / 2 + compassSize + 20 }, ${height / 2 - compassSize - 20})`);

      compassArrowRef.current = overlayRef.current.append('g');

      overlayRef.current.append('circle')
        .attr('r', compassSize)
        .attr('class', 'vpc-compass-circle')
        ;

    }, []);

    useEffect(() => {

      const { width, height } = dimensions;

      // Define zoom behavior
      const zoom = d3.zoom()
          .scaleExtent([0.25, 5])
          .translateExtent([[-canvasSize/2, -canvasSize/2], [canvasSize/2, canvasSize/2]]) // Restricts panning to the 5000x5000 area      
          .on('zoom', (event) => {
              containerRef.current.attr('transform', event.transform);

              let nodeR = 5 / event.transform.k;
              if(nodeR > 5) nodeR = 5;
              if(nodeR < 2) nodeR = 2;
              setCurrentZoom(event.transform);

              let needToInvertAngle = false;

              compassArrowRef.current.selectAll('.vpc-node-arrow')
                .attr('x1', 0) // Start x-coordinate at the group's origin
                .attr('y1', 0) // Start y-coordinate at the group's origin
                .attr('x2', d => {
                    let compass_angle = calculateAngle(
                        -width / 2 + compassSize + 20, height / 2 - compassSize - 20,
                        d.x + nodeWidth / 2, d.y + nodeHeight / 2, event.transform
                    );
            
                    if (needToInvertAngle) {
                        compass_angle += Math.PI; // Invert the angle if necessary
                    }
            
                    return (arrowSize + compassSize) * Math.cos(compass_angle); // End x-coordinate
                })
                .attr('y2', d => {
                    let compass_angle = calculateAngle(
                        -width / 2 + compassSize + 20, height / 2 - compassSize - 20,
                        d.x + nodeWidth / 2, d.y + nodeHeight / 2, event.transform
                    );
            
                    if (needToInvertAngle) {
                        compass_angle += Math.PI; // Invert the angle if necessary
                    }
            
                    return (arrowSize + compassSize) * Math.sin(compass_angle); // End y-coordinate
                });
          });

      d3.select(svgRef.current).call(zoom);

      // setZoomBehavior(zoom);
    }, [dimensions]);

    useEffect(() => {
      const nodeMap = new Map();
      const parsedNodes = initialNodes.map((node, index) => {

        // find this node in our nodes array by ID if it exists;
        // if it does, then lets use its x & y values
        let foundNode = nodes.find(n => n.id === node.id);

        let newNode = {
          x: currentZoom.x / currentZoom.k,
          y: currentZoom.y / currentZoom.k,
          ...node,
        };
        console.log(node.autoLayout)
        if(foundNode && !node.autoLayout){
          newNode.x = foundNode.x;
          newNode.y = foundNode.y;
        }

        delete newNode.autolayout;

        nodeMap.set(node.id, newNode);
        return newNode;
      });
  
      const parsedLinks = initialLinks.map(link => ({
          ...link,
          source: nodeMap.get(link.source),
          target: nodeMap.get(link.target),
      }));

      setNodes(parsedNodes);
      setLinks(parsedLinks);


    }, [initialNodes, initialLinks]);

      
    useEffect(() => {
      if (!containerRef.current) return;

      const { width, height } = dimensions;

      // Define and apply drag behavior at the SVG level
      const drag = d3.drag()
        .on('start', (event, d) => {
            draggedNodeRef.current = d; // Store the reference to the dragged node
        })
        .on('drag', (event) => {
          if (draggedNodeRef.current) {
              let snapThreshold = 10; // Threshold for snapping to alignment
              let distributionSnapThreshold = 10; // Threshold for snapping to distribution
              let alignX = event.x;
              let alignY = event.y;
      
              // First, check for direct alignment (as before)
              nodes.forEach(node => {
                  if (node.id !== draggedNodeRef.current.id) {
                      if (Math.abs(node.x - event.x) <= snapThreshold) {
                          alignX = node.x;
                      }
                      if (Math.abs(node.y - event.y) <= snapThreshold) {
                          alignY = node.y;
                      }
                  }
              });
      
              // Then, check for distribution alignment
              nodes.forEach((node1, index1) => {
                  nodes.forEach((node2, index2) => {
                      if (index1 !== index2 && node1.id !== draggedNodeRef.current.id && node2.id !== draggedNodeRef.current.id) {
                          // Horizontal distribution alignment
                          if (node1.y === node2.y && Math.abs(node1.y - event.y) <= snapThreshold) {
                              let dist = Math.abs(node1.x - node2.x);
                              let potentialX = [node1.x + dist, node1.x - dist, node2.x + dist, node2.x - dist];
                              potentialX.forEach(px => {
                                  if (Math.abs(px - event.x) <= distributionSnapThreshold) {
                                      alignX = px;
                                  }
                              });
                          }
                          // Vertical distribution alignment
                          if (node1.x === node2.x && Math.abs(node1.x - event.x) <= snapThreshold) {
                              let dist = Math.abs(node1.y - node2.y);
                              let potentialY = [node1.y + dist, node1.y - dist, node2.y + dist, node2.y - dist];
                              potentialY.forEach(py => {
                                  if (Math.abs(py - event.y) <= distributionSnapThreshold) {
                                      alignY = py;
                                  }
                              });
                          }
                      }
                  });
              });
      
              // Update position of the dragged node
              draggedNodeRef.current.x = alignX;
              draggedNodeRef.current.y = alignY;
      
              let foundNodeIndex = nodes.findIndex(n => n.id === draggedNodeRef.current.id);
              let newNodes = [...nodes];
      
              newNodes[foundNodeIndex] = {
                  ...newNodes[foundNodeIndex],
                  x: alignX,
                  y: alignY,
              }
      
              // Update any links connected to this node
              let newLinks = links.map(l => {
                  if (l.source.id === draggedNodeRef.current.id) {
                      l.source.x = alignX;
                      l.source.y = alignY;
                  }
                  if (l.target.id === draggedNodeRef.current.id) {
                      l.target.x = alignX;
                      l.target.y = alignY;
                  }
                  return l;
              });
      
              setNodes(newNodes);
              setLinks(newLinks);
          }
        })      
        .on('end', () => {
          draggedNodeRef.current = null; // Clear the reference

          onNodeChange(nodes)
        });

      // Define and apply drag behavior at the SVG level
      connectorDragRef.current = d3.drag()
        .on('start', (event, d) => {
          draggingLink.current = {
              startNode: d,
          }

          let updatedNode = nodes.find(n => n.id === d.id);

          containerRef.current.select('#vpc-link-in-progress')
              .attr('d', () => {
                  let controlPoints = calculateControlPoints(
                      updatedNode.x + nodeWidth, updatedNode.y + nodeHeight / 2, 
                      event.x + nodeWidth + (updatedNode.x - draggingLink.current.startNode.x), event.y + nodeHeight / 2 + (updatedNode.y - draggingLink.current.startNode.y)
                  );
                  return lineGenerator(controlPoints);
              })
              .attr('opacity', 1);
        })
        .on('drag', (event) => {
            let updatedNode = nodes.find(n => n.id === draggingLink.current.startNode.id);

            containerRef.current.select('#vpc-link-in-progress')
                .attr('d', () => {
                    let controlPoints = calculateControlPoints(
                        updatedNode.x + nodeWidth, updatedNode.y + nodeHeight / 2, 
                        event.x + nodeWidth + (updatedNode.x - draggingLink.current.startNode.x), event.y + nodeHeight / 2 + (updatedNode.y - draggingLink.current.startNode.y)
                    );
                    return lineGenerator(controlPoints);
                });
        })
        .on('end', () => {

          if(mouseoverNodeRef.current && draggingLink.current.startNode.id !== mouseoverNodeRef.current.id){
            let newLinks = [...initialLinks, {
              source: draggingLink.current.startNode.id,
              target: mouseoverNodeRef.current.id,
            }];
            setPendingLinkCount(newLinks.length);
            onLinkChange(newLinks);
            
          } else {
            containerRef.current.select('#vpc-link-in-progress')
              .attr('opacity', 0)
              ;
          }
          
          draggingLink.current = null; // Clear the reference
        });

      // Define and apply drag behavior at the SVG level
      invertedConnectorDragRef.current = d3.drag()
        .on('start', (event, d) => {
          invertedDraggingLink.current = {
              startNode: d,
          }

          let updatedNode = nodes.find(n => n.id === d.id);

          containerRef.current.select('#vpc-link-in-progress')
              .attr('d', () => {
                  let controlPoints = calculateControlPoints(
                      event.x + (updatedNode.x - invertedDraggingLink.current.startNode.x), event.y + nodeHeight / 2 + (updatedNode.y - invertedDraggingLink.current.startNode.y),
                      updatedNode.x, updatedNode.y + nodeHeight / 2
                  );
                  return lineGenerator(controlPoints);
              })
              .attr('opacity', 1);
        })
        .on('drag', (event) => {
            let updatedNode = nodes.find(n => n.id === invertedDraggingLink.current.startNode.id);

            containerRef.current.select('#vpc-link-in-progress')
                .attr('d', () => {
                    let controlPoints = calculateControlPoints(
                        event.x + (updatedNode.x - invertedDraggingLink.current.startNode.x), event.y + nodeHeight / 2 + (updatedNode.y - invertedDraggingLink.current.startNode.y),
                        updatedNode.x, updatedNode.y + nodeHeight / 2
                    );
                    
                    return lineGenerator(controlPoints);
                });
        })
        .on('end', () => {

          if(mouseoverNodeRef.current && invertedDraggingLink.current.startNode.id !== mouseoverNodeRef.current.id){
            let newLinks = [...initialLinks, {
              target: invertedDraggingLink.current.startNode.id,
              source: mouseoverNodeRef.current.id,
            }];
            setPendingLinkCount(newLinks.length);
            onLinkChange(newLinks);
            
          } else {
            containerRef.current.select('#vpc-link-in-progress')
              .attr('opacity', 0)
              ;
          }
          
          invertedDraggingLink.current = null; // Clear the reference
        });


      containerRef.current.selectAll('.vpc-node-handle-right')
        .call(connectorDragRef.current)
        ;
      
      containerRef.current.selectAll('.vpc-node-handle-left')
        .call(invertedConnectorDragRef.current)
        ;
      
  
      // Render links
      let filteredLinks = links.filter(l => l.source && l.target);
      const linkElements = linkContainerRef.current.selectAll('.vpc-link-group')
        .data(filteredLinks, d => `${d.source.id}-${d.target.id}`)
        .join(
            enter => {
              var g = enter.append('g')
                .attr('class', 'vpc-link-group')
                ;

              g.append('path')
                .attr('class', 'vpc-link-click-target')
                .attr('fill', 'none')
                .attr('stroke-width', 10)
                .on('click', (e, d) => {
                  e.stopPropagation();  // Stop the event from propagating up to the SVG
                  onLinkClick(d);
                })

              g.append('path')
                .attr('class', d => {
                  let retval = 'vpc-link ';
                  if(selectedLinkId === d.source.id + '_' + d.target.id) retval += 'vpc-link--selected';
          
                  if(selectedNodeId === d.source.id || selectedNodeId === d.target.id) retval += ' vpc-link--selected';
                  return retval;
                })
                .attr('fill', 'none')
                .attr('stroke-width', 1.5)
              },
            update => update,
            exit => exit.remove()
        )
        ;
  
      // Render nodes
      const nodeElements = nodeContainerRef.current.selectAll('.vpc-node-group')
          .data(nodes, d => d.id)
          .join(
              enter => {
                var g = enter.append('g')
                  .attr('class', d => {
                    let retval = 'vpc-node-group ';
                    if(selectedNodeId === d.id) retval += 'vpc-node-group--selected';
                    return retval;
                  })
                  .call(drag)
                  .attr('transform', d => `translate(${d.x}, ${d.y})`)
                  .on('mouseover', (e, d) => {
                    mouseoverNodeRef.current = d
                    
                  })
                  .on('mouseout', (e, d) => {
                    mouseoverNodeRef.current = null;
                  })
                  ;

                
                g.append('rect')
                  .attr('class', 'vpc-node')
                  .attr('width', nodeWidth)
                  .attr('height', nodeHeight)
                  .attr('rx', 3)
                  .attr('ry', 3)
                  .attr('stroke-width', 2)
                  .on('click', (e, d) => {
                      e.stopPropagation();  // Stop the event from propagating up to the SVG
                      onNodeClick(d.id);
                  });
                  ;

                g.append('foreignObject')
                  .attr('width', nodeWidth) // Set the width of the foreignObject
                  .attr('height', nodeHeight) // Set the height of the foreignObject
                  .attr('x', 0) // Set the x position
                  .attr('y', 0) // Set the y position
                  .style('pointer-events', 'none')
                  .append('xhtml:div') // Append a div for your HTML content
                  .attr('class', 'vpc-node-label') // Add any class or style as needed
                  .on('click', (e, d) => {
                      e.stopPropagation();  // Stop the event from propagating up to the SVG
                      onNodeClick(d.id);
                  });
                  ;

                
                g.append('circle')
                  .attr('class', 'vpc-node-handle vpc-node-handle-left')
                  .attr('cx', 0)
                  .attr('cy', nodeHeight / 2)
                  .attr('r', 5)
                  .attr('stroke-width', 2)
                  ;

                g.append('circle')
                  .attr('class', 'vpc-node-handle vpc-node-handle-right')
                  .attr('cx', nodeWidth)
                  .attr('cy', nodeHeight / 2)
                  .attr('r', 5)
                  .attr('stroke-width', 2)
                  ;

              },
                  
              update => {
                update.call(drag);
              },
              exit => exit.remove()
          )
          ;

        
        
        const nodeArrows = compassArrowRef.current.selectAll('.vpc-node-arrows-group')
          .data(nodes, d => d.id)
          .join(
              enter => {
                var g = enter.append('g')
                  .attr('class', d => {
                    let retval = 'vpc-node-arrows-group ';
                    if(selectedNodeId === d.id) retval += 'vpc-node-group--selected';
                    return retval;
                  })
                  ;
                
                        
                g.append('line')
                  .attr('class', 'vpc-node-arrow')
                  .attr('x1', 0)
                  .attr('y1', 0)
                  .attr('marker-end', 'url(#arrowhead)')
                  .attr('x2', d => {
                    let compass_angle = calculateAngle(-width / 2 + compassSize + 20, height / 2 - compassSize - 20, d.x + nodeWidth / 2, d.y + nodeHeight / 2, currentZoom);
                    return (arrowSize + compassSize) * Math.cos(compass_angle)
                  })
                  .attr('y2', d => {
                    let compass_angle = calculateAngle(-width / 2 + compassSize + 20, height / 2 - compassSize - 20, d.x + nodeWidth / 2, d.y + nodeHeight / 2, currentZoom);
                    return (arrowSize + compassSize) * Math.sin(compass_angle)
                  })
                  ;

              },
                  
              update => {
                update.select('.vpc-node-arrow')
                  .attr('marker-end', 'url(#arrowhead)')
                  .attr('x2', d => {
                    let compass_angle = calculateAngle(-width / 2 + compassSize + 20, height / 2 - compassSize - 20, d.x + nodeWidth / 2, d.y + nodeHeight / 2, currentZoom);
                    return (arrowSize + compassSize) * Math.cos(compass_angle)
                  })
                  .attr('y2', d => {
                    let compass_angle = calculateAngle(-width / 2 + compassSize + 20, height / 2 - compassSize - 20, d.x + nodeWidth / 2, d.y + nodeHeight / 2, currentZoom);
                    return (arrowSize + compassSize) * Math.sin(compass_angle)
                  })
                  // .attr('stroke', d => {
                  //   let category = categories.find(c => c.id === d.category);
                  //   if(category){
                  //     let color = lighten(desaturate(categories.find(c => c.id === d.category).color, 50), 50);
                  //     return color;
                  //   }
                  //   return '#000';
                  // })
              },
              exit => exit.remove()
          )
          ;
      
      if(links.length === pendingLinkCount){
        containerRef.current.select('#vpc-link-in-progress')
          .attr('opacity', 0)
          ;
        setPendingLinkCount(links.length);
      }
        
              
      // Update positions for both nodes and links
      updateGraph(nodes);
  }, [nodes, links, dimensions]);

  const calculateAngle = (overlayX, overlayY, targetX, targetY, currentZoom) => {
    // Reverse the zoom and pan transformations

    const translatedX = (targetX ) * currentZoom.k + currentZoom.x;
    const translatedY = (targetY ) * currentZoom.k + currentZoom.y;

    // Calculate the angle in radians
    const dx = translatedX - overlayX;
    const dy = translatedY - overlayY;
    const angle = Math.atan2(dy, dx);

    return angle;
  };

  const calculateControlPoints = (sourceX, sourceY, targetX, targetY) => {
   
    const midX = (sourceX + targetX) / 2;
    const midY = (sourceY + targetY) / 2;

    let curve_radius = Math.min(Math.min(Math.abs((sourceY - targetY) / 2), Math.abs((targetX - sourceX) / 2)), 40);


    const theta = Math.PI / 2 / 10;

    let controlPoints = [];
    

    if(sourceY < targetY && sourceX < targetX){
      curve_radius /= 2;
      // top left to bottom right

      const sourceCurveOriginX = midX - curve_radius;
      const sourceCurveOriginY = sourceY + curve_radius;

      const targetCurveOriginX = midX + curve_radius;
      const targetCurveOriginY = targetY - curve_radius;

      controlPoints = [
        { x: sourceX, y: sourceY },
        { x: sourceCurveOriginX, y: sourceY},
      ]
      
      let startTheta = -Math.PI / 2;
      let endTheta = 0;

      for(var i = startTheta; i <= endTheta; i += theta){
        controlPoints.push({
          x: sourceCurveOriginX + curve_radius * Math.cos(i),
          y: sourceCurveOriginY + curve_radius * Math.sin(i)
        })
      }

      controlPoints.push({ x: midX, y: midY });

      // now the next curve
      startTheta = 0;
      endTheta = -Math.PI / 2;

      for(var i = startTheta; i >= endTheta; i -= theta){
        controlPoints.push({
          x: targetCurveOriginX - curve_radius * Math.cos(i),
          y: targetCurveOriginY - curve_radius * Math.sin(i)
        })
      }

      controlPoints.push({ x: targetX, y: targetY });

    } else if(sourceY > targetY && sourceX < targetX){
      curve_radius /= 2;
      // bottom left to top right

      const sourceCurveOriginX = midX - curve_radius;
      const sourceCurveOriginY = sourceY - curve_radius;

      const targetCurveOriginX = midX + curve_radius;
      const targetCurveOriginY = targetY + curve_radius;

      controlPoints = [
        { x: sourceX, y: sourceY },
        { x: sourceCurveOriginX, y: sourceY},
      ]
    
      let startTheta = Math.PI / 2;
      let endTheta = 0;

      for(var i = startTheta; i >= endTheta; i -= theta){
        controlPoints.push({
          x: sourceCurveOriginX + curve_radius * Math.cos(i),
          y: sourceCurveOriginY + curve_radius * Math.sin(i)
        })
      }

      controlPoints.push({ x: midX, y: midY });

      // now the next curve
      startTheta = 0;
      endTheta = Math.PI / 2;

      for(var i = startTheta; i <= endTheta; i += theta){
        controlPoints.push({
          x: targetCurveOriginX - curve_radius * Math.cos(i),
          y: targetCurveOriginY - curve_radius * Math.sin(i)
        })
      }

      controlPoints.push({ x: targetX, y: targetY });

    } else if(sourceY < targetY && sourceX > targetX){
      curve_radius /= 2;
      
      // top right to bottom left


      controlPoints = [
        { x: sourceX, y: sourceY },
        { x: sourceX + curve_radius, y: sourceY},
      ]
      
      let startTheta = -Math.PI / 2;
      let endTheta = 0;

      for(var i = startTheta; i <= endTheta; i += theta){
        controlPoints.push({
          x: (sourceX + curve_radius) + curve_radius * Math.cos(i),
          y: (sourceY + curve_radius) + curve_radius * Math.sin(i)
        })
      }

      controlPoints.push({ x: (sourceX + curve_radius) + curve_radius, y: midY - curve_radius });

      for(var i = 0; i <= Math.PI / 2; i += theta){
        controlPoints.push({
          x: (sourceX + curve_radius) + curve_radius * Math.cos(i),
          y: midY - curve_radius + curve_radius * Math.sin(i)
        })
      }

      startTheta = -Math.PI / 2;
      endTheta = -Math.PI;

      for(var i = startTheta; i >= endTheta; i -= theta){
        controlPoints.push({
          x: (targetX - curve_radius) + curve_radius * Math.cos(i),
          y: (midY + curve_radius) + curve_radius * Math.sin(i)
        })
      }


      startTheta = -Math.PI;
      endTheta = -Math.PI - Math.PI / 2;

      for(var i = startTheta; i >= endTheta; i -= theta){
        controlPoints.push({
          x: (targetX - curve_radius) + curve_radius * Math.cos(i),
          y: (targetY - curve_radius) + curve_radius * Math.sin(i)
        })
      }

      controlPoints.push({ x: targetX, y: targetY });

    } else {
      // bottom right to top left

      curve_radius /= 2;
      
      controlPoints = [
        { x: sourceX, y: sourceY },
        { x: sourceX + curve_radius, y: sourceY},
      ]
      
      let startTheta = Math.PI / 2;
      let endTheta = 0;

      for(var i = startTheta; i >= endTheta; i -= theta){
        controlPoints.push({
          x: (sourceX + curve_radius) + curve_radius * Math.cos(i),
          y: (sourceY - curve_radius) + curve_radius * Math.sin(i)
        })
      }

      controlPoints.push({ x: (sourceX + curve_radius) + curve_radius, y: midY + curve_radius });

      for(var i = 0; i >= -Math.PI / 2; i -= theta){
        controlPoints.push({
          x: (sourceX + curve_radius) + curve_radius * Math.cos(i),
          y: midY + curve_radius + curve_radius * Math.sin(i)
        })
      }

      startTheta = Math.PI / 2;
      endTheta = Math.PI;

      for(var i = startTheta; i <= endTheta; i += theta){
        controlPoints.push({
          x: (targetX - curve_radius) + curve_radius * Math.cos(i),
          y: (midY - curve_radius) + curve_radius * Math.sin(i)
        })
      }


      startTheta = Math.PI;
      endTheta = Math.PI / 2 + Math.PI;

      for(var i = startTheta; i <= endTheta; i += theta){
        controlPoints.push({
          x: (targetX - curve_radius) + curve_radius * Math.cos(i),
          y: (targetY + curve_radius) + curve_radius * Math.sin(i)
        })
      }

      controlPoints.push({ x: targetX, y: targetY });
    }

    return controlPoints;
  }

  
  const updateGraph = (nodes) => {
    // Update nodes
    containerRef.current.selectAll('.vpc-node-group')
        .data(nodes, d => d.id)
        .attr('transform', d => `translate(${d.x}, ${d.y})`)
        ;

    containerRef.current.selectAll('.vpc-node-group').selectAll('.vpc-node')
      .attr('stroke', d => {
        d = nodes.find(n => n.id === d.id);
        let category = categories.find(c => c.name === d.category) || {};
        return category.color || 'black';
      })
      .attr('fill', d => {
        d = nodes.find(n => n.id === d.id);
        let category = categories.find(c => c.name === d.category) || {};
        if(category.color){
          return lighten(desaturate(category.color, 40), 65);
        }
        return '#fff';
      })


    containerRef.current.selectAll('.vpc-node-group').selectAll('.vpc-node-label')
      .html(d => {
        // grab updated D manually?
        d = nodes.find(n => n.id === d.id);
        let category = categories.find(c => c.name === d.category) || {};
        return `<div>
          <div class="list-left list-left-no-wrap margin-bottom-1rem">
            <i class="vpc-node-label-icon fal fa-fw ${category.icon}" style="color: ${category.color}"></i> <div class="vpc-node-label-text">${category.display_name || ""}</div>
          </div>
          <div class="vpc-node-label-name">${d.name || "..."}</div>
        </div>`;
      })
      ;
    
    
    // Update links
    let filteredLinks = links.filter(l => l.source && l.target);
    containerRef.current.selectAll('.vpc-link')
        .data(filteredLinks, d => `${d.source.id}-${d.target.id}`)
        .attr('d', d => {
            let controlPoints = calculateControlPoints(d.source.x + nodeWidth, d.source.y + nodeHeight / 2, d.target.x, d.target.y + nodeHeight / 2);

            return lineGenerator(controlPoints);
        })
        
        .attr('class', d => {
          let retval = 'vpc-link ';
          if(selectedLinkId === d.source.id + '_' + d.target.id) retval += 'vpc-link--selected';
  
          if(selectedNodeId === d.source.id || selectedNodeId === d.target.id) retval += ' vpc-link--selected';
          return retval;
        })
        ;

    containerRef.current.selectAll('.vpc-link-click-target')
        .data(filteredLinks, d => `${d.source.id}-${d.target.id}`)
        .attr('d', d => {
            let controlPoints = calculateControlPoints(d.source.x + nodeWidth, d.source.y + nodeHeight / 2, d.target.x, d.target.y + nodeHeight / 2);
            return lineGenerator(controlPoints);
        });

    
  };


  useEffect(() => {

    d3.select(svgRef.current)
      .selectAll('.vpc-node-group')
      .attr('class', d => {
        let retval = 'vpc-node-group ';
        if(selectedNodeId === d.id) retval += 'vpc-node-group--selected';
        return retval;
      })
      ;

    containerRef.current.selectAll('.vpc-link')
      .attr('class', d => {
        let retval = 'vpc-link ';
        if(selectedLinkId === d.source.id + '_' + d.target.id) retval += 'vpc-link--selected';

        if(selectedNodeId === d.source.id || selectedNodeId === d.target.id) retval += ' vpc-link--selected';
        return retval;
      })
      ;

  }, [selectedNodeId, selectedLinkId])
  

  return (
      <svg ref={svgRef} style={{ width: '100%', height: '100%' }} className="visual-programming-canvas">
        <defs>
          <marker id="arrowhead" markerWidth="10" markerHeight="7" 
                  refX="0" refY="3.5" orient="auto">
              <polygon points="0 0, 10 3.5, 0 7" />
          </marker>
        </defs>
      </svg>
  );
};

export default VisualProgrammingCanvas;
