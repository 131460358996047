import { push } from 'react-router-redux';
import Cookies from 'js-cookie';
import forceLogoutHandler from '../users/a.users.forceLogoutHandler';


////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// GET
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestListPosts = packet => {
  return {
    type: 'REQUEST_LIST_POSTS',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveListPostsSuccess = data => ({
  type: 'RECEIVE_LIST_POSTS_SUCCESS',
  data
})

export const receiveListPostsFail = data => ({
  type: 'RECEIVE_LIST_POSTS_FAIL',
  data
})

export const tryToListPosts = packet => (dispatch, getState) => {

  dispatch(requestListPosts(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/posts?' + new URLSearchParams(packet), 
    {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveListPostsSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveListPostsFail({errors:e, lastSubmit: packet}));
    })
}
