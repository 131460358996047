import Cookies from 'js-cookie';
import forceLogoutHandler from './a.users.forceLogoutHandler';
import socketClientManager from '../../sockets/socketClientManager';

import {
  dismissToast,
  tryToHeartbeat
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// VALIDATE SESSION
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestValidateSession = packet => {
  return {
    type: 'REQUEST_VALIDATE_SESSION',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveValidateSessionSuccess = data => ({
  type: 'RECEIVE_VALIDATE_SESSION_SUCCESS',
  data
})

export const receiveValidateSessionFail = data => ({
  type: 'RECEIVE_VALIDATE_SESSION_FAIL',
  data
})

export const tryToValidateSession = packet => (dispatch, getState) => {

  dispatch(requestValidateSession(packet));


  let headers = {
    "Content-Type": "application/json"
  }
  
  // let auth = Cookies.get(process.env.REACT_APP_COOKIE_AUTH);
  // if(auth){
  //   headers["Authorization"] = "Bearer " + auth;
  // }

  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/users/validate-session', {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveValidateSessionSuccess(json));
      if(json.isLoggedIn){
        dispatch(socketClientManager.ensureConnected());
        dispatch(dismissToast({id: 'cookieConsent'}));
        dispatch(tryToHeartbeat({
          verbose: true
        }));
      }
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveValidateSessionFail({errors:e, lastSubmit: packet}));
    })
}
