
import InquiryHome from '../tools/inquiry/InquiryHome/InquiryHome.js'

//Admin
import InquirySettings from '../tools/inquiry/InquirySettings/InquirySettings.js'
import InquiryFoundations from '../tools/inquiry/InquiryFoundations/InquiryFoundations.js'
import InquiryUseCases from '../tools/inquiry/InquiryUseCases/InquiryUseCases.js'
import InquiryContextMap from 'tools/inquiry/InquiryContextMap/InquiryContextMap.js'
import InquiryOpportunityMap from 'tools/inquiry/InquiryOpportunityMap/InquiryOpportunityMap.js'
import Team from '../tools/shared/Team/Team.js'


const tool_config = [
  { 
    name: undefined,
    display_name: "Overview",
    display_icon: "lightbulb-on",
    category: "",
    roleless: true,
    description: "The home dashboard for this project.",
    component: (id, canWrite) => <InquiryHome id={id} canWrite={canWrite}/>,
    keywords: "home dashboard"
  },
  { 
    name: 'foundations',
    display_name: "Project Foundations",
    display_icon: "cubes",
    category: "",
    fullWidth: true,
    noScroll: true,
    noFooter: true,
    description: "Assess and refine your project's description across multiple dimensions.",
    component: (id, canWrite) => <InquiryFoundations id={id} canWrite={canWrite}/>,
    keywords: "hunt refine statement perspective"
  },
  // { 
  //   name: 'refine_hunt',
  //   display_name: "Hunt Refinement",
  //   display_icon: "bullseye-arrow",
  //   category: "",
  //   fullWidth: true,
  //   description: "Refine your hunt statement and get critical feedback.",
  //   component: (id, canWrite) => <InquiryRefineHunt id={id} canWrite={canWrite}/>,
  //   keywords: "hunt refine statement perspective"
  // },
  { 
    name: 'context',
    display_name: "Context Exploration",
    display_icon: "chart-network",
    category: "",
    fullWidth: true,
    noScroll: true,
    description: "Discover and the business & socio-cultural context of your project.",
    component: (id, canWrite, item) => <InquiryContextMap id={id} canWrite={canWrite} item={item}/>,
    keywords: "context contextual mapping map",
    noFooter: true
  },
  { 
    name: 'workflow',
    display_name: "Workflow Mapping",
    display_icon: "project-diagram",
    category: "",
    fullWidth: true,
    noScroll: true,
    description: "Map out your existing or planned process and identify where AI can help.",
    component: (id, canWrite, item) => <InquiryOpportunityMap id={id} canWrite={canWrite} item={item}/>,
    keywords: "process mapping map flow",
    noFooter: true
  },
  { 
    name: 'use-cases',
    display_name: "AI Use Cases",
    display_icon: "robot",
    category: "",
    fullWidth: true,
    description: "Discover and evaluate the potential use cases for AI in your project.",
    component: (id, canWrite, item) => <InquiryUseCases id={id} canWrite={canWrite} item={item}/>,
    keywords: "use cases AI applications agents knowledge base",
    getCounter: (item) => item.use_cases ? item.use_cases.length : 0
  },
  { 
    name: "settings",
    display_name: "Settings",
    display_icon: "cog",
    category: "simple",
    description: "Change the name, description, and other metadata about this project.",
    component: (id, canWrite) => <InquirySettings id={id} canWrite={canWrite}/>,
    keywords: "name description meta info information metadata naming title thumbnail tags"
  },
  { 
    name: "team",
    display_name: "Team & Permissions",
    display_icon: "users",
    category: "simple",
    description: "Invite others to collaborate on this project.",
    component: (id, canWrite, item) => <Team id={id} kind={'inquiries'} canWrite={canWrite} item={item}/>,
    keywords: "users user members member roles privilege editor share team"
  },

]


export default tool_config