import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {
  addToSharedCache
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Create a new file in the knowledge base
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestCreateFile = packet => {
  return {
    type: 'REQUEST_CREATE_FILE',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveCreateFileSuccess = data => ({
  type: 'RECEIVE_CREATE_FILE_SUCCESS',
  data
})

export const receiveCreateFileFail = data => ({
  type: 'RECEIVE_CREATE_FILE_FAIL',
  data
})

export const tryToCreateFile = (packet, callback) => (dispatch, getState) => {

  dispatch(requestCreateFile(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/knowledge/files/' + packet.knowledge_id, {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveCreateFileSuccess(json));

      // dispatch(addToSharedCache({
      //   path: 'knowledge/' + packet.knowledge_id + '/info',
      //   item: json.data
      // }))
      
      // if(!packet.noPush){
      //   dispatch(push('/workbench/' + packet.scope + '/knowledge/' + packet.knowledge_id + '/info/' + json.data.id))
      // }


      if(callback) callback(null, json);
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveCreateFileFail({errors:e, lastSubmit: packet}));
      if(callback) callback({errors:e, lastSubmit: packet});
    })
}
