import { push } from 'react-router-redux';

import {
  socketCatchUserNotification,
  addToast,
  tryToMarkUserNotificationAsRead
} from '../actions/actions.export'


const kindRemap = {
  projects: 'Project',
  components: 'AI Flow',
  knowledge: 'Knowledge Base',
  inquiries: 'Contextual Inquiry'
}

const kindURIRemap = {
  projects: 'projects',
  components: 'agents',
  knowledge: 'knowledge',
  inquiries: 'inquiries'
}

const kindIconRemap = {
  projects: 'robot',
  components: 'project-diagram',
  knowledge: 'book-spells',
  inquiries: 'telescope'
}


const handleUserNotification = (dispatch, getState) => {
  return (event) => {
    dispatch(socketCatchUserNotification(event));
    dispatch(addToast({
      id: event.id,
      title: getToastTitle(event),
      detail: getToastDescription(event),
      autoDismiss: 10000,
      buttons: [
        {
          display: "View",
          dismissOnClick: true,
          destination: '/workbench/my-account/notifications'
        },
        // {
        //   display: "Mark as Read",
        //   dismissOnClick: true,
        //   onClick: () => {
        //     dispatch(tryToMarkUserNotificationAsRead({id: event.id}));
        //   }
        // }
      ]
    }))
  }
}



const getToastTitle = (event) => {
  let retval = ""
  switch(event.type){
    case 'organization_invite':
      retval = <span><i className="far fa-users icon-before-text"/> Organization Invites</span>
      break;
    case 'organization_invite_accepted':
      retval = <span><i className="far fa-users icon-before-text"/> Organization Invites</span>
      break;
    case 'organization_joined':
      retval = <span><i className="far fa-users icon-before-text"/> Organization Invites</span>
      break;
    case 'organization_ownership_transfer':
      retval = <span><i className="far fa-users icon-before-text"/> Organization Transfer</span>
      break;
    case 'kind_invite':
      retval = <span><i className={"far fa-users icon-before-text"}/> You've been added to a team.</span>
      break;
    default:
      retval = ""
      break;
  }

  return retval;
}



const getToastDescription = (event) => {
  let retval = ""
  switch(event.type){
    case 'organization_invite':
      retval = <span>You have a new invite, click view for more details.</span>
      break;
    case 'organization_invite_accepted':
      retval = <span>One of your invitations was accepted, click view for more details.</span>
      break;
    case 'organization_joined':
      retval = <span>You joined a new organization, click view for more details.</span>
      break;
    case 'organization_ownership_transfer':
      retval = <span>An organization's ownership was just transfered to you, click view for more details.</span>
      break;
    case 'kind_invite':

      retval = <span>You can now access the {event.data.kind_display_name} {kindRemap[event.data.kind]}.</span>
      break;
    default:
      retval = ""
      break;
  }

  return retval;
}

export default handleUserNotification;