import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux';
import moment from 'moment'
import nanoid from 'nanoid'

import {
  encode,
  decode
} from 'gpt-tokenizer'

import CustomTable from 'kit/components/CustomTable/CustomTable'
import CustomField from 'kit/components/CustomField/CustomField'
import Checkbox from 'kit/components/Checkbox/Checkbox'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import Callout from 'kit/components/Callout/Callout.js'
import Modal from 'kit/components/Modal/Modal.js'
import Hydrate from 'components/Hydrate/Hydrate';

import {
  tryToGetPaginated,
  tryToUpdateFile,
  tryToGetFile,
  tryToDeleteFile,
  // tryToSearchInformation
} from 'actions/actions.export'

// import './KnowledgeInfo.scss';

class KnowledgeFiles extends Component {
  constructor(props){
    super(props);


    this.state = {
      searchText: "",
      lastDelete: 0,
      info_custom_search: false,
      showTip: false,
      searchText: "",
      file_display_name: "",
      rechunking: false,
      chunking_strategy: "single",

      id: "",
      view: "table",
    }
  }
  

  componentWillMount(){
    const { dispatch, knowledgeReducer, sharedReducer, item } = this.props;

    const knowledge = knowledgeReducer.cache[this.props.id];
    if(knowledge){
      dispatch(tryToGetPaginated({
        kind: 'knowledge',
        id: this.props.id,
        collection: 'files',
        orderBy: 'created_at'
      }))
    }

    if(item){
      dispatch(tryToGetFile({
        knowledge_id: this.props.id,
        file_id: item
      }))
    }
  }

  componentWillReceiveProps(newprops){
    const { dispatch, item, sharedReducer, knowledgeReducer } = newprops;

    if(knowledgeReducer.deleteFileSuccess && knowledgeReducer.deleteFileSuccess !== this.state.lastDelete){
      var knowledge = knowledgeReducer.cache[newprops.id];

      this.setState({
        lastDelete: knowledgeReducer.deleteFileSuccess,
        deleteFileModalOpen: false
      })
      dispatch(push('/workbench/' + knowledge.scope + '/knowledge/' + knowledge.id + '/files'))
    }

    if(item && knowledgeReducer.fileCache[item] && (!this.state.id || item !== this.state.id)){

      let infoObject = knowledgeReducer.fileCache[item];
      
      if(infoObject){
        this.setState({
          loaded: true,
          id: item,
          file_display_name: infoObject.display_name,
          info_content: infoObject.content,
          info_embedded_content: infoObject.embedded_content,
          info_source_type: infoObject.source_type,
          info_source_name: infoObject.source_name,
          info_source_url: infoObject.source_url,
          info_source_page: infoObject.source_page
        })
      }
    }

    if(item && !knowledgeReducer.fileCache[item] && !knowledgeReducer.tryingToGetFile){
      dispatch(tryToGetFile({
        knowledge_id: newprops.id,
        file_id: item
      }))
    }

    if(!item){
      this.setState({
        loaded: false,
        file_display_name: "",
        info_content: "",
        info_embedded_content: "",
        info_source_type: "",
        info_source_name: "",
        info_source_url: "",
        info_source_page: ""
      })
    }
  }

  render(){
    const { dispatch, knowledgeReducer, userReducer, orgReducer, sharedReducer, item, canWrite } = this.props;

    const knowledge = knowledgeReducer.cache[this.props.id];

    if(!knowledge) return <span>Failed to load.</span>;

    const sharedCachePath = 'knowledge/' + knowledge.id + '/files';
    let fileList = sharedReducer.cache[sharedCachePath] ? sharedReducer.cache[sharedCachePath].data : [];
    let fileListEmpty = sharedReducer.cache[sharedCachePath] ? sharedReducer.cache[sharedCachePath].empty : false;

    if(this.state.lastSearchText){
      const searchResults = knowledgeReducer.searchResults[this.props.id] || {data: []};
      fileList = searchResults.data;
      fileListEmpty = searchResults.empty;
    }


    if(item){
      return <div className="">
        <Modal
          show={this.state.deleteFileModalOpen}
          exitable={true}
          onExit={e => this.setState({deleteFileModalOpen: false})}
          cancelable={true}
          onCancel={e => this.setState({deleteFileModalOpen: false})}
          acceptable={true}
          acceptButtonThinking={knowledgeReducer.tryingToDeleteInformation}
          acceptButtonFail={knowledgeReducer.deleteInformationFail}
          acceptButtonSuccess={knowledgeReducer.deleteFileSuccess}
          acceptButtonLabel="Delete"
          acceptButtonColor="danger"
          onAccept={e => {
            dispatch(tryToDeleteFile({
              knowledge_id: knowledge.id,
              id: item
            }))
          }}
          content={<div>
            <p>Are you sure you want to delete this file?</p>
            <p className="text-900">This cannot be undone.</p>
          </div>}
          />


        <div className="flex-split margin-bottom-2rem margin-top-05rem">
          <div className="list-left">
            <CustomButton
              size="small"
              color="grey"
              display={<span><i className="far fa-angle-left icon-before-text"/>Back</span>}
              to={"/workbench/" + knowledge.scope + "/knowledge/" + knowledge.id + "/files"}
              />
            {
              knowledgeReducer.tryingToGetFile ?
              <span>Loading...</span>
              :
              <span>Make changes to this file:</span>
            }
          </div>
          <div className="list-right">
            <CustomButton
              color="success"
              display="Save Changes"
              size="small"
              disabled={!canWrite}
              thinking={knowledgeReducer.tryingToUpdateFile}
              success={knowledgeReducer.updateFileSuccess}
              fail={knowledgeReducer.updateFileFail}
              onClick={e => {
                dispatch(tryToUpdateFile({
                  knowledge_id: knowledge.id,
                  id: item,
                  scope: knowledge.scope
                }))
              }}
              />
          </div>
        </div>

        <div className="box">
          <div className="row">
            <div className="col-md-6">
              <CustomField
                label="File Name"
                inline={true}
                required={true}
                disabled={!canWrite}
                name="file_display_name"
                placeholder="Fact #1"
                description=""
                value={this.state.file_display_name}
                onChange={e => { this.setState({file_display_name: e.value})}}
                />
              <CustomSelect
                label="Chunking Strategy"
                options={[
                  {label: "Single Chunk", value: "single"},
                  {label: "Token-based", value: "token"},
                  {label: "Delimiter", value: "delimiter"},
                ]}
                value={this.state.chunking_strategy}
                onChange={e => this.setState({chunking_strategy: e})}
                disabled={!canWrite || !this.state.rechunking}
                />
            </div>
            
            <div className="col-md-12">
              <div className="list-right margin-top-2rem">
                <CustomButton
                  color="transparent-danger"
                  display="Delete File"
                  disabled={!canWrite}
                  size="small"
                  onClick={e => {
                    this.setState({deleteFileModalOpen: true})
                  }}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    }


    // grid view
    return <div className="">
        <div className="flex-split margin-bottom-2rem margin-top-05rem">
          <div>
            <h4 className="no-margin-top no-margin-bottom">Files</h4>
            <small>
              When adding knowledge from a file, its contents can be stored here for easy reference, replacement, and rechunking.
            </small>
          </div>
          <form className="list-right margin-right-1rem" onSubmit={()=>{
            // dispatch(tryToSearchInformation({
            //   knowledge_id: knowledge.id,
            //   searchText: this.state.searchText
            // }))
            // this.setState({lastSearchText: this.state.searchText});
          }}>
            <span>
              <CustomButton
                display={<span><i className="far fa-list fa-fw"/></span>}
                size="small"
                color={this.state.view === "table" ? "black" : "grey"}
                onClick={e => this.setState({view: "table"})}
                />
            </span>
            <span className="no-margin-left margin-right-1rem">
              <CustomButton
                display={<span><i className="far fa-th-large fa-fw"/></span>}
                size="small"
                color={this.state.view === "grid" ? "black" : "grey"}
                onClick={e => this.setState({view: "grid"})}
                />
            </span>
            {/* <CustomField
              value={this.state.searchText}
              inline={true}
              minWidth={250}
              autoFocus={true}
              thinking={knowledgeReducer.tryingToSearchFiles}
              placeholder="search..."
              onChange={e => this.setState({searchText: e.value})}
              />

            <CustomButton
              display="Search"
              color="black"
              size="small"
              thinking={knowledgeReducer.tryingToSearchFiles}
              fail={knowledgeReducer.searchInformationFail}
              success={knowledgeReducer.searchInformationSuccess}
              disabled={!this.state.searchText}
              submit={true}
              onClick={()=>{
                dispatch(tryToSearchInformation({
                  knowledge_id: knowledge.id,
                  searchText: this.state.searchText
                }))
                this.setState({lastSearchText: this.state.searchText});
              }}
              /> */}
          </form>
      </div>

      <div className="row row-eq-height gx-3">
        {
          this.state.lastSearchText && <div className="col-md-12">
            <div className="flex-split margin-bottom-1rem">
              <div className="list-left">
                <CustomButton
                  display="Clear Search"
                  color="grey"
                  size="small"
                  onClick={e => {
                    this.setState({lastSearchText: ""})
                  }}
                  />
                {
                  knowledgeReducer.tryingToSearchFiles ?
                  <span>Searching for {this.state.lastSearchText}...</span>
                  :
                  <span>
                    Showing search results for <strong>{this.state.lastSearchText}</strong>
                  </span>
                }
              </div>
            </div>
          </div>
        }
        {
          (fileList.length === 0 && !sharedReducer.tryingToGetPaginated && !knowledgeReducer.tryingToSearchFiles) &&
          <Callout
            title="Loading your first file?"
            content={<div>
              <p>Click the button below to add a file to this Knowledge Base.</p>
              <div className="spacer-2rem"/>
              <CustomButton
                size="small"
                color="primary"
                display={<span><i className="far fa-plus icon-before-text"/>Add Information</span>}
                to={"/workbench/" + knowledge.scope + "/knowledge/" + knowledge.id + "/add"}
                />
            </div>}
            />
        }
        {
          this.state.view === 'grid' && fileList.map((file, i)=>{
            return <div className="col-md-3 flex-column-stretch margin-bottom-1rem" key={i}>
              <Link className="box box-clickable box-half-pad link-no-decoration" to={"/workbench/" + knowledge.scope + "/knowledge/" + knowledge.id + "/files/" + file.id}>
                <strong>
                  {file.display_name}
                </strong>
                <div>
                  <span className="text-400 text-semi-muted">
                    {file.tokens} tokens
                  </span>
                </div>
                
                <div className="list-left">
                  <small className="text-400 text-gs5">
                    {
                      file.last_updated ? 
                      <span>last updated { moment(file.last_updated).fromNow() }</span>
                      :
                      <span>created { moment(file.created_at).fromNow() }</span>
                    }
                  </small>
                  
                </div>
              </Link>
            </div>
          })
        }
        {
          this.state.view === 'table' && <div className="box">
            <CustomTable
              rawData={fileList}
              hidePagination={true}
              rowLink={row => "/workbench/" + knowledge.scope + "/knowledge/" + knowledge.id + "/files/" + row.id}
              headers={[
                // {
                //   display: "",
                //   name: "type",
                //   getter: r => r.type === "pdf" ? <i className="far fa-file-pdf fa-fw text-primary"/> : <i className="far fa-i-cursor fa-fw text-secondary fa-lg"/>,
                //   sortGetter: r => r.type
                // },
                {
                  display: "File Name",
                  name: "name",
                  getter: r => <div><strong>{r.display_name}</strong></div>,
                  sortGetter: r => r.display_name,
                  width: "80%"
                },
                {
                  display: "Chunks",
                  name: "chunks",
                  getter: r => <div>{r.chunks}</div>,
                  sortGetter: r => r.chunks
                },
                {
                  display: "Uploaded on",
                  name: "created_at",
                  getter: r => <nobr className="text-muted">{moment(r.created_at).format('MMM D, YYYY')}</nobr>,
                  sortGetter: r => r.created_at
                },
                {
                  display: "Last Updated",
                  name: "last_updated",
                  getter: r => <nobr className="text-muted">{r.last_updated ? moment(r.last_updated).fromNow() : "-"}</nobr>,
                  sortGetter: r => r.last_updated ? r.last_updated : "-"
                },
                {
                  display: "Uploaded By",
                  name: "by",
                  getter: r => <Hydrate type="user" mode="lockup" size={25} id={r.last_updated_by || r.created_by} tooltip={true}/>,
                  sortGetter: r => <Hydrate type="user" field="display_name" id={r.last_updated_by || r.created_by}/>
                }
              ]}
            />
          </div>
        }
        
        {
          fileList.length > 0 &&
          <div className="col-md-12 flex-column-center-center">
            <CustomButton
              display="Load More Chunks"
              color="transparent"
              disabled={fileListEmpty}
              size="small"
              onClick={e => {
                dispatch(tryToGetPaginated({
                  kind: 'knowledge',
                  id: this.props.id,
                  collection: 'info',
                  orderBy: 'created_at',
                  startAfter: fileList[fileList.length - 1].id
                }))
              }}
              />
          </div>
        }
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, knowledgeReducer, sharedReducer, orgReducer } = state;

  return {
    userReducer, 
    knowledgeReducer,
    sharedReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(KnowledgeFiles);

  