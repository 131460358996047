import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js';
import CustomField from 'kit/components/CustomField/CustomField.js';
import Hydrate from 'components/Hydrate/Hydrate.js';

import toCamelCaseFunctionName from 'utilities/toCamelCaseFunctionName';

function WebScraper({ options = {}, onChange, enabled, org_id, canWrite}) {
  const [selectedOption, setSelectedOption] = useState('');

  // Access the Redux dispatch function
  const dispatch = useDispatch();
  const knowledgeReducer = useSelector((state) => state.knowledgeReducer);

  let knowledgeCacheList = Object.keys(knowledgeReducer.cache).map(id => knowledgeReducer.cache[id]);
    
  let knowledge = [];
  if(org_id){
    knowledge = knowledgeCacheList.filter(p => p.scope === org_id);
  }


  return (
    <div>
      <p>
        The Web Scraper plugin allows the AI to scrape content from some websites. The Agent's ability to read a website's content is determined by the website's robots.txt file. If the website's robots.txt file blocks the Agent, the Agent will not be able to scrape the website.
      </p>
      <p>
        Additionally, some websites load content dynamically using JavaScript. The Agent is not able to scrape content that is loaded dynamically, so it may miss some content on websites that use this method.
      </p>
      <CustomField
        name="blocked_domains"
        label="Blocked Domains"
        value={options.blocked_domains}
        onChange={onChange}
        disabled={!canWrite}
        rows={10}
        description={<div>Note: Domains are case-insensitive and should not include the protocol (http:// or https://).<br/>'example.com' will block both 'http://example.com' and 'https://example.com</div>}
        placeholder={`Enter a comma-separated list of domains to block from being scraped. 

For example: "example.com, example.org, www.example.net"`}
        />
    </div>
  );
}

export default WebScraper;
