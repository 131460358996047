var tooltipTimeout;

export const showTooltipNow = data => ({
  type: 'SHOW_TOOLTIP_NOW',
  data
})


export const showTooltip = data => (dispatch, getState) => {
  let tooltip = getState().tooltip;

  if(data.e){
    data.e.persist();
    data.d = data.e.currentTarget.getBoundingClientRect();
    data.e.stopPropagation();
    data.e.preventDefault();
  }
  if(data.el){
    data.e = {
      target: data.el
    }
    data.d = data.el.getBoundingClientRect(); 
  }

  clearTimeout(tooltipTimeout);
  let now = new Date().getTime();
  
  if(data.lag && (now - tooltip.lastHideMillis > data.lag) || (now - tooltip.lastShowMillis > data.lag)){
    tooltipTimeout = setTimeout(function(){

      dispatch(showTooltipNow(data));
    }, data.lag)
  } else {
    dispatch(showTooltipNow(data));
  }
}

export const hideTooltipNow = data => ({
  type: 'HIDE_TOOLTIP_NOW',
  data
})


export const hideTooltip = data => dispatch => {
  clearTimeout(tooltipTimeout);
  dispatch(hideTooltipNow());
}
