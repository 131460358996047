import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import {
  encode
} from 'gpt-tokenizer'

import Hydrate from 'components/Hydrate/Hydrate'
import ComponentVersionBar from 'components/ComponentVersionBar/ComponentVersionBar.js'
import CustomField from 'kit/components/CustomField/CustomField'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import Callout from 'kit/components/Callout/Callout.js'
import TabNav from 'kit/components/TabNav/TabNav.js'

import {
  tryToSetComponentModel,
  tryToGetModels
} from 'actions/actions.export'

import './ComponentModel.scss';


class ComponentModel extends Component {
  constructor(props){
    super(props);

    this.state = {
      mode: 'about',
      model: "",
      promptCostTest: "This is a hypothetical prompt to estimate cost. It's what the user might say, and is split off from the response because each provider typically prices the input and output differently.",
      responseCostTest: "This is a hypothetical response to estimate cost. It's what the model might say, and is split off from the prompt because each provider typically prices the input and output differently.",
      parameters: {}
    }
  }

  componentWillMount(){
    const { dispatch, componentReducer } = this.props;

    dispatch(tryToGetModels());

    const component = componentReducer.cache[this.props.id];
    if(component){
      let draft = component.versions.find(v => v.id === 'draft');
      if(draft){

        // create a string of all instructions to load as the prompt test
        let promptCostTest = [];
        for(var i in draft.few_shots){
          for(var j in draft.few_shots[i].messages){
            promptCostTest.push(draft.few_shots[i].messages[j].content);
          }
        }

        promptCostTest = promptCostTest.join("\n\n");

        this.setState({
          model: draft.model,
          parameters: draft.parameters ? draft.parameters : {},
          promptCostTest: promptCostTest
        })
      }
    } 
  }

  componentWillReceiveProps(newprops){
    const component = newprops.componentReducer.cache[newprops.id];
    if(component){
      if(!newprops.componentReducer.tryingToSetComponentModel && this.state.submitted){
        let draft = component.versions.find(v => v.id === 'draft');
        if(draft){
          this.setState({
            model: draft.model
          })
        }
      }
    } 
  }

  render(){

    const { dispatch, componentReducer, userReducer, orgReducer, intelReducer, canWrite } = this.props;

    const component = componentReducer.cache[this.props.id];

    if(!component) return <span>Failed to load component.</span>;

    let canSubmit = this.state.model;

    let currentLLM = intelReducer.llms.find(l => l.name === this.state.model);

    let promptCostTokens = encode(this.state.promptCostTest).length;
    let responseCostTokens = encode(this.state.responseCostTest).length;
    let promptCostCharacters = this.state.promptCostTest.length;
    let responseCostCharacters = this.state.responseCostTest.length;


    return <div className="">
      <div className="row">
        <div className="col-xl-12 margin-top-05rem margin-bottom-2rem">
          <div className="flex-split">
            <div>
              <h4 className="no-margin ">Model</h4>
              <small>Select and configure the large language model to adapt and customize.</small>
            </div>

            <div className="list-right">
              <CustomButton 
                display={component.current_version === 'draft' ? "Save Changes" : "Save Changes to Draft"}
                thinking={componentReducer.tryingToSetComponentModel}
                success={componentReducer.setComponentModelSuccess}
                fail={componentReducer.setComponentModelFail}
                disabled={!canWrite || !canSubmit}
                color="black"
                size="small"
                onClick={()=>{

                  dispatch(tryToSetComponentModel({
                    id: component.id,
                    model: this.state.model,
                    parameters: this.state.parameters
                  }))

                  this.setState({
                    submitted: true
                  })
                }}
                />
            </div>
          </div>
        </div>
      </div>


      {
        componentReducer.setComponentModelForm.errors.error && <Callout style="danger" content={componentReducer.setComponentModelForm.errors.error}/>
      }
      <div className="row">
        <div className="col-md-12 margin-bottom-1rem">
          <div className="box">
            <CustomSelect
              value={this.state.model}
              label="Which foundational LLM would you like to build upon?"
              placeholder="GPT-4, PaLM2, etc"
              description="Which model and service should this agent use?"
              inline={true}
              large={true}
              required={true}
              disabled={!canWrite}
              serverError={componentReducer.setComponentModelForm.errors.display_name}
              lastSubmit={componentReducer.setComponentModelForm.lastSubmit.display_name}
              options={intelReducer.llms.filter(m => m.showForTypes.includes(component.type)).map((model, i)=>{
                
                return {
                  label: <div className="thin-line-height list-left">
                    {model.provider === 'OpenAI' && <img src="/img/openai_logo_small.png" width="30"/>}
                    {model.provider === 'Google PaLM2' && <img src="/img/palm2_logo_small.png" width="30"/>}
                    {model.provider === 'Google Gemini' && <img src="/img/gemini_logo_small.png" width="30"/>}
                    {model.provider === "Anthropic" && <img src="/img/anthropic_logo_small.png" width="30"/>}
                    {model.provider === "Meta" && <img src="/img/meta_logo_small.png" width="30"/>}
                    <div>
                      <strong>{model.name}</strong><br/>
                      <div className="text-ellipsis-1-lines"><small>by {model.provider}  {model.legacy ? <span className="margin-left-1rem text-tag text-tag-tiny text-tag-danger">LEGACY</span> : model.preview ? <span className="margin-left-1rem text-tag text-tag-tiny text-tag-warning">PREVIEW</span> : ""}</small></div>
                    </div>
                  </div>,
                  value: model.name
                }
              })}
              onChange={(e) => {

                let parameters = this.state.parameters;
                let newModel = intelReducer.llms.find(model => model.name === e);
                if(newModel.parameters){
                  parameters = {};
                  for(var i in newModel.parameters){
                    parameters[newModel.parameters[i].name] = newModel.parameters[i].default;
                  }
                }

                this.setState({
                  model: e,
                  parameters: parameters
                })
              }}
              />
              <div className="spacer-3rem"/>
              <TabNav
                value={this.state.mode}
                selectedStaysLit={true}
                onChange={(v)=>{
                  this.setState({mode: v})
                }}
                items={[

                  {
                    value: 'about',
                    display: 'About This Model'
                  },
                  {
                    value: 'parameters',
                    display: 'Adjustable Parameters'
                  },
                  {
                    value: 'cost',
                    display: 'Usage Cost Estimator'
                  }
                ]}
                />
              {
                (currentLLM && this.state.mode === "about") && <div className="padding-1rem">
                <p>
                  <div className="list-left margin-bottom-2rem">
                    <div className="margin-right-3rem">
                      <small>Provider:</small>
                      <h4 className="no-margin">{currentLLM.provider}</h4>
                    </div>
                  </div>

                  <div className="margin-bottom-2rem">
                    <small>Description from {currentLLM.provider}:</small><br/>
                    <small className="text-900">"{currentLLM.description}"</small>
                  </div>
                
                  <div className="list-left">
                    <div className="margin-right-3rem">
                      <small>Training data cutoff:</small>
                      <h5 className="no-margin-top margin-bottom-1rem">{currentLLM.training_data_cutoff}</h5>
                    </div>
                    <div className="margin-right-3rem">
                      <small>Context Window:</small>
                      <h5 className="no-margin-top margin-bottom-1rem">{currentLLM.token_cap.toLocaleString()} tokens</h5>
                    </div>
                    <div className="margin-right-3rem">
                      <small>Status:</small>
                      <h5 className="no-margin-top margin-bottom-1rem">
                        {
                          currentLLM.legacy ? <span className="text-tag text-tag-tiny text-tag-danger">LEGACY</span>
                          : currentLLM.preview ? <span className="text-tag text-tag-tiny text-tag-warning">PREVIEW</span>
                          :
                          <span className="text-tag text-tag-tiny text-tag-success">ACTIVELY SUPPORTED</span>
                        }
                      </h5>
                    </div>

                    <div className="margin-right-3rem">
                      <small>Supports Conversations:</small>
                      <h5 className="no-margin-top margin-bottom-1rem">
                        {
                          currentLLM.showForTypes.includes('chat') ? <span className="text-tag text-tag-tiny text-tag-success">YES</span> : <span className="text-tag text-tag-tiny text-tag-danger">NO</span>
                        }
                      </h5>
                    </div>

                    <div className="margin-right-3rem">
                      <small>Supports Function Calling:</small>
                      <h5 className="no-margin-top margin-bottom-1rem">
                        {
                          currentLLM.function_support === "stable" ? <span className="text-tag text-tag-tiny text-tag-success">YES</span>
                          : currentLLM.function_support === "preview" ? <span className="text-tag text-tag-tiny text-tag-warning">PREVIEW</span>
                          : <span className="text-tag text-tag-tiny text-tag-danger">NO</span>
                        }
                      </h5>
                    </div>
                  </div>
                </p>
                <hr/>
                <p className="no-margin-bottom margin-top-2rem">
                  By choosing and using this model, your data will be processed through the provider's API. We recommend you review the provider's terms of service and privacy policy before using their services. In addition to ZeroWidth's Terms of Service, violating these terms may result in account suspension or termination.
                </p>
                <p>
                  For your convenience, here are a few links to some of the relevant documents from this provider. This list is not exhaustive and you should review the provider's website for the most up-to-date information:
                </p>
                {
                  currentLLM.provider === 'OpenAI' &&
                  <ul className="ul-iconed">
                    <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://openai.com/policies/terms-of-use">OpenAI Terms of Use</a></li>
                    <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://openai.com/policies/business-terms">OpenAI Business terms</a></li>
                    <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://openai.com/policies/privacy-policy">OpenAI Privacy Policy</a></li>
                    <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://openai.com/policies/">OpenAI Policies</a></li>
                  </ul>
                }
                {
                  currentLLM.provider === 'Google PaLM2' &&
                  <ul className="ul-iconed">
                    <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://policies.google.com/terms">Google Privacy & Terms</a></li>
                    <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://ai.google.dev/terms-archive/terms_08_28_23">PaLM API and MakerSuite Additional Terms of Service</a></li>
                  </ul>
                }
                {
                  currentLLM.provider === 'Google Gemini' &&
                  <ul className="ul-iconed">
                    <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://policies.google.com/terms">Google Privacy & Terms</a></li>
                    <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://ai.google.dev/terms">Gemini API Additional Terms of Service</a></li>
                  </ul>
                }
                {
                  currentLLM.provider === 'Anthropic' &&
                  <ul className="ul-iconed">
                    <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://www.anthropic.com/legal/consumer-terms">Anthropic Consumer Terms of Service</a></li>
                    <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://www.anthropic.com/legal/commercial-terms">Anthropic Commercial Terms of Service</a></li>
                    <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://www.anthropic.com/legal/privacy">Anthropic Privacy Policy</a></li>
                    <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://www.anthropic.com/legal/aup">Anthropic Acceptable Use Policy</a></li>
                    <li className='margin-bottom-1rem'><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://trust.anthropic.com/">Anthropic Trust Center</a></li>
                  </ul>
                }

                </div>
              }
              {
                (currentLLM && this.state.mode === "parameters") && <div className="padding-1rem">
                  <p>
                    Each provider offers a variety of parameters that can be adjusted to customize the behavior of the model. While many share common names and behaviors, the specific parameters, ranges, and their effects can vary between providers and models. Please refer to the provider's documentation for more information on the parameters available for this model.
                  </p>
                  <div className="flex-split flex-split-align-start">
                    <CustomButton
                      size="small"
                      display="Reset to Defaults"
                      color="grey"
                      onClick={() => {
                        let parameters = {};
                        for(var i in currentLLM.parameters){
                          parameters[currentLLM.parameters[i].name] = currentLLM.parameters[i].default;
                        }
                        this.setState({
                          parameters: parameters
                        })
                      }}
                      />
                  </div>
                  <hr/>
                  {
                    (currentLLM.parameters || []).map((param, i) => {

                      if(param.type === 'range'){
                        return <div key={i}>
                          <CustomField
                            name={param.name}
                            label={param.display}
                            description={param.description}
                            type="range"
                            min={param.scale[0]}
                            max={param.scale[1]}
                            step={param.step}
                            placeholder={" "}
                            value={this.state.parameters[param.name]}
                            disabled={!canWrite}
                            onChange={e => {
                              let parameters = this.state.parameters;
                              parameters[param.name] = parseFloat(e.value)
                              this.setState({
                                parameters: parameters
                              })
                            }}
                            />
                        </div>
                      } else if(param.type === 'select'){
                        return <div key={i}>
                          <CustomSelect
                            name={param.name}
                            label={param.display}
                            description={param.description}
                            options={param.options}
                            placeholder={param.placeholder}
                            value={this.state.parameters[param.name]}
                            disabled={!canWrite}
                            onChange={e => {
                              let parameters = this.state.parameters;
                              parameters[param.name] = e;
                              this.setState({
                                parameters: parameters
                              })
                            }}
                            />
                        </div>

                      }

                      return 
                    })
                  }
                </div>
              }

              {
                (currentLLM && this.state.mode === "cost") && <div className="padding-1rem">
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        Use the form below to estimate the cost of a prompt & response from this model. This is an estimate only and actual costs may vary based on tokenization or character counting method applied by the model provider. This estimate does not include any additional LLM usage within a request to facilitate actions like summarization, agentic RAG, or other multi-model interactions.
                      </p>
                    </div>
                    <div className="col-md-12">
                      <CustomField
                        value={this.state.promptCostTest}
                        label="Hypothetical Prompt"
                        placeholder="Type a prompt to estimate cost"
                        description="This is an estimate, initially based on the currently configured Instructional Prompts. Tokens needed to describe the roles, variables, and potential user message will be counted in addition to this text."
                        rows={4}
                        onChange={e => {
                          this.setState({
                            promptCostTest: e.value
                          })
                        }}
                        />
                      
                      <CustomField 
                        value={this.state.responseCostTest}
                        label="Hypothetical Response"
                        placeholder="Type a response to estimate cost"
                        rows={4}
                        onChange={e => {
                          this.setState({
                            responseCostTest: e.value
                          })
                        }}
                        />
                    </div>
                    <div className="col-md-12">
                      <Callout content={
                        <div className="">
                          <h4 className="no-margin-top">Cost Estimate</h4>
                          {
                            currentLLM.price_per_ktoken && <span>
                              <strong>{currentLLM.name}</strong> calculates cost based on the number of tokens in the prompt and response.
                            </span>
                          }
                          {
                            currentLLM.price_per_kchar && <span>
                              <strong>{currentLLM.name}</strong> calculates cost based on the number of characters in the prompt and response.
                            </span>
                          }
                          {
                            currentLLM.price_per_ktoken && <div>
                              <p>
                                {promptCostTokens.toLocaleString()} tokens in prompt<br/>@ {currentLLM.price_per_ktoken.input} per 1,000 tokens = <strong>${((promptCostTokens / 1000) * currentLLM.price_per_ktoken.input).toFixed(8)}</strong>
                              </p>
                              <p>
                                {responseCostTokens.toLocaleString()} tokens in response<br/>@ {currentLLM.price_per_ktoken.output} per 1,000 tokens = <strong>${((responseCostTokens / 1000) * currentLLM.price_per_ktoken.output).toFixed(8)}</strong>
                              </p>
                              <p className="no-margin-bottom">
                                Total: <strong>${((promptCostTokens / 1000) * currentLLM.price_per_ktoken.input + (responseCostTokens / 1000) * currentLLM.price_per_ktoken.output).toFixed(8)}</strong>
                              </p>
                            </div>
                          }
                          {
                            currentLLM.price_per_kchar && <div>
                              <p>
                                {promptCostCharacters.toLocaleString()} characters in prompt<br/>@ {currentLLM.price_per_kchar.input} per 1,000 characters = <strong>${((promptCostCharacters / 1000) * currentLLM.price_per_kchar.input).toFixed(8)}</strong>
                              </p>
                              <p>
                                {responseCostCharacters.toLocaleString()} characters in response<br/>@ {currentLLM.price_per_kchar.output} per 1,000 characters = <strong>${((responseCostCharacters / 1000) * currentLLM.price_per_kchar.output).toFixed(8)}</strong>
                              </p>
                              <p className="no-margin-bottom">
                                Estimated Total Cost: <strong>${((promptCostCharacters / 1000) * currentLLM.price_per_kchar.input + (responseCostCharacters / 1000) * currentLLM.price_per_kchar.output).toFixed(8)}</strong>
                              </p>
                            </div>
                          }
                        </div>
                      }/>
                      </div>
                    </div>
                  </div>
              }

          </div>
        </div>
      </div>
      
      

    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, componentReducer, orgReducer, intelReducer } = state;

  return {
    userReducer, 
    componentReducer,
    orgReducer,
    intelReducer
  }
}

export default connect(mapStateToProps)(ComponentModel);

  