import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect.js'
import Checkbox from '../../kit/components/Checkbox/Checkbox.js'
import Callout from '../../kit/components/Callout/Callout.js'


import {
  tryToEditOrganizationKeys,
  tryToGetOrganizationKeys,
  showTooltip,
  hideTooltip
} from '../../actions/actions.export.js'

class EditOrganizationKeysForm extends Component {
  constructor(props){
    super(props);

    let keys = props.orgReducer.cache[props.id].keys || {};

    this.state = {
      keys: {
        openai: "",
        anthropic: "",
        replicate: "",
        gemini: "",
        ...JSON.parse(JSON.stringify(props.orgReducer.cache[props.id].keys || {}))
      },
      enabled_providers: {
        openai: "",
        anthropic: "",
        replicate: "",
        gemini: "",
        ...JSON.parse(JSON.stringify(props.orgReducer.cache[props.id].enabled_providers || {})),
      },
      lastKeyLoad: 0,
      editKeys: {},
      expandKeys: {}
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount(){
    const { dispatch } = this.props;

    dispatch(tryToGetOrganizationKeys({
      id: this.props.id
    }))
  }

  componentWillReceiveProps(nextProps){
    // if we have new keys, update the state
    if(nextProps.orgReducer.cache[nextProps.id] && nextProps.orgReducer.cache[nextProps.id].keys && nextProps.orgReducer.getOrganizationKeysSuccess > this.state.lastKeyLoad){

      this.setState({
        lastKeyLoad: nextProps.orgReducer.getOrganizationKeysSuccess,
        keys: JSON.parse(JSON.stringify(nextProps.orgReducer.cache[nextProps.id].keys || {})),
        enabled_providers: JSON.parse(JSON.stringify(nextProps.orgReducer.cache[nextProps.id].enabled_providers || {}))
      })
    } else if(nextProps.orgReducer.cache[nextProps.id] && nextProps.orgReducer.cache[nextProps.id].keys && nextProps.orgReducer.editOrganizationKeysSuccess > this.state.lastKeyLoad){

      let newEditKeys = this.state.editKeys;

      for(var i in nextProps.orgReducer.cache[nextProps.id].keys){
        if(nextProps.orgReducer.cache[nextProps.id].keys[i] !== undefined && nextProps.orgReducer.cache[nextProps.id].keys[i] !== null && nextProps.orgReducer.cache[nextProps.id].keys[i].length > 0){
          newEditKeys[i] = false;
        }
      }

      this.setState({
        lastKeyLoad: nextProps.orgReducer.editOrganizationKeysSuccess,
        keys: JSON.parse(JSON.stringify(nextProps.orgReducer.cache[nextProps.id].keys || {})),
        enabled_providers: JSON.parse(JSON.stringify(nextProps.orgReducer.cache[nextProps.id].enabled_providers || {})),
        editKeys: newEditKeys,
      })
    }
  }

  handleSubmit(provider){
    const { dispatch } = this.props;


    dispatch(tryToEditOrganizationKeys({
      id: this.props.id,
      keys: {
        [provider.id]: this.state.keys[provider.id]
      },
    }))
  }

  render(){

    const { orgReducer, dispatch} = this.props;

    let canSubmit = true;

    let canEditKeys = false;
    for(var i in orgReducer.memberships){
      if(orgReducer.memberships[i].organization.id === this.props.id){
        if(orgReducer.memberships[i].membership.manage_billing){
          canEditKeys = true;
        }
      }
    }

    if(!canEditKeys) return <span/>;
    
    const organization = orgReducer.cache[this.props.id];

    const drawProviderBox = (provider) => {

      return <div className={"box box-light-border margin-bottom-2rem box-no-shadow " + ( this.state.expandKeys[provider.id] ? "" : "box-clickable " )  } onClick={e => {
          if(this.state.expandKeys[provider.id]) return;
          let newExpandKeys = this.state.expandKeys;
          newExpandKeys[provider.id] = !newExpandKeys[provider.id];

          let newEditKeys = this.state.editKeys;
          if(this.state.keys[provider.id] === undefined || this.state.keys[provider.id] === null || this.state.keys[provider.id].length === 0){
            newEditKeys[provider.id] = true;
          }

          this.setState({
            expandKeys: newExpandKeys,
            editKeys: newEditKeys
          })
        }}>
        <div className="flex-split ">
          <div className="list-left">
            {
              this.state.expandKeys[provider.id] ?
              <i className="far fa-fw fa-chevron-down icon-before-text margin-right-1rem"/> :
              <i className="far fa-fw fa-chevron-right icon-before-text margin-right-1rem"/>
            }
            <img src={provider.logo} width="30" height="30"/>
            <h4 className="no-margin">
              {provider.name}
            </h4>
          </div>
          <div className="list-right">
            {
              (this.state.enabled_providers[provider.id]) ?
              <span className="text-900 text-success margin-right-1rem"> <i className="far fa-key icon-before-text"/>Currently set</span> :
              <span className="text-900 text-danger margin-right-1rem"><i className="far fa-times icon-before-text"/>No key</span>
            }
          </div>
        </div>
        {
          this.state.expandKeys[provider.id] &&
          <div>
            <hr/>
          
            <p>
              {provider.description}
            </p>
            <div className="list-left list-left-align-flex-start margin-bottom-3rem">
              <div className={"flex-grow " + (this.state.editKeys[provider.id] ? "no-margin" : "")}>
                <CustomField
                  inline={true}
                  placeholder={"Enter your " + provider.name + " API key here"}
                  description={provider.inputDescription}
                  value={this.state.keys[provider.id]}
                  disabled={this.state.editKeys[provider.id] ? false : true}
                  onChange={(e)=>{
                    let newKeys = this.state.keys;
                    newKeys[provider.id] = e.value;
                    this.setState({ keys: newKeys })
                  }}
                  />
              </div>
              {
                !this.state.editKeys[provider.id] &&
                <div className="no-margin">
                  <CustomButton
                    display="Edit"
                    color={"grey"}
                    size="small"
                    onClick={()=>{
                      let newEditKeys = this.state.editKeys;
                      newEditKeys[provider.id] = !newEditKeys[provider.id];

                      let newKeys = this.state.keys;
                      newKeys[provider.id] = "";

                      this.setState({
                        editKeys: newEditKeys,
                        keys: newKeys
                      })
                    }}
                    />
                </div>
              }
            </div>

            {
              orgReducer.editOrganizationKeysForm.errors.error &&
              <Callout
                style="danger"
                dismissable={true}
                content={orgReducer.editOrganizationKeysForm.errors.error}
              />
            }
            
            <div className="list-right">
              <CustomButton
                display="Cancel"
                color="transparent"
                size="small"
                onClick={(e)=>{
                  // let the box click fail first
                  setTimeout(()=>{
                    let newEditKeys = this.state.editKeys;
                    newEditKeys[provider.id] = false;

                    let newExpandKeys = this.state.expandKeys;
                    newExpandKeys[provider.id] = false;

                    let newKeys = this.state.keys;
                    
                    newKeys[provider.id] = JSON.parse(JSON.stringify(organization.keys[provider.id] || ""));

                    this.setState({
                      editKeys: newEditKeys,
                      expandKeys: newExpandKeys,
                      keys: newKeys
                    })
                  }, 10);
                }}
                />
              <CustomButton
                display="Save Changes"
                color="success"
                size="small"
                thinking={orgReducer.tryingToEditOrganizationKeys}
                fail={orgReducer.editOrganizationKeysFail}
                success={orgReducer.editOrganizationKeysSuccess}
                disabled={!canSubmit}
                onClick={()=>{
                  if(canSubmit) this.handleSubmit(provider)
                }}
                />
            </div>
          </div>
        }
      </div>
    }

    return <div className={""}>
      <div className="margin-bottom-2rem margin-top-05rem">
        <h4 className="no-margin">Third Party Keys</h4>
        <small>
          Manage how your organization uses third-party language models.
        </small>
      </div>
      <form onSubmit={()=>{
        if(canSubmit) this.handleSubmit()
      }}>

        <Callout
          style={"info"}
          title={"What are these for?"}
          content={<div>
            <p className="no-margin-top">
              ZeroWidth's infrastructure automatically manages the relationship between your designed agents and the relevant foundational language models that power them.
            </p>

            <p className="no-margin-bottom">
              If your organization does not have any credits, you will be required to provide an API key for each provider you wish to use. If you do not have an API key, you can sign up for one by following the links below.
            </p>
          </div>}
        />

        <div className="spacer-2rem"/>

        {
         drawProviderBox({
            id: 'openai',
            name: 'OpenAI',
            logo: '/img/openai_logo_small.png',
            description: <span>Used for models like GPT-3.5, GPT-4, and more.</span>,
            inputDescription: <span>Sign up for an API key on OpenAI's website <a href='https://platform.openai.com/signup' target='_blank'>here</a>.</span>
          })
        }

        {
          drawProviderBox({
            id: 'anthropic',
            name: 'Anthropic',
            logo: '/img/anthropic_logo_small.png',
            description: <span>Used for models like the Claude 3 variants, Claude 2.1 and more.</span>,
            inputDescription: <span>Sign up for an API key on Anthropic's website <a href='https://www.anthropic.com/' target='_blank'>here</a>.</span>
          })
        }

        {
          drawProviderBox({
            id: 'gemini',
            name: 'Google Gemini',
            logo: '/img/gemini_logo_small.png',
            description: <span>Used for models like Gemini 1.5 Flash.</span>,
            inputDescription: <span>Sign up for an API key on Google Gemini's website <a href='https://aistudio.google.com/app/apikey/' target='_blank'>here</a>.</span>
          })
        }

        {
          drawProviderBox({
            id: 'replicate',
            name: 'Replicate',
            logo: '/img/replicate_logo_small.png',
            description: <span>Used for open source models like Meta's Llama2.</span>,
            inputDescription: <span>Sign up for an API key on Replicate's website <a href='https://replicate.com/' target='_blank'>here</a>.</span>
          })
        }
      </form>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { orgReducer } = state;

  return {
    orgReducer
  }
}

export default connect(mapStateToProps)(EditOrganizationKeysForm);
