import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux';
import moment from 'moment';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import CustomField from '../../kit/components/CustomField/CustomField';
import CustomButton from '../../kit/components/CustomButton/CustomButton';
import AbbreviateNumber from 'components/AbbreviateNumber/AbbreviateNumber';

import QueryChartWrapper from '../QueryChartWrapper/QueryChartWrapper';

import { generateColor } from '../../utilities/colorUtilities';

class WorkbenchOrgSettings extends Component {
  constructor(props){
    super(props);

    this.state = {
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD'),
      trigger_rerun: 0
    }
  }
  
  render(){

    const { dispatch, userReducer, orgReducer, projectReducer, guiReducer } = this.props;

    let membership;
    let org;
    let needsSettingsAccess;
    for(var i in orgReducer.memberships){
      if(orgReducer.memberships[i].organization.id === this.props.org_id){
        needsSettingsAccess = orgReducer.memberships[i].needsSettingsAccess;
        membership = orgReducer.memberships[i].membership;
        org = orgReducer.memberships[i].organization;
      }
    }
    
    if(!needsSettingsAccess || !membership.manage_billing) dispatch(push('/oops'));

    return <div className="row margin-bottom-3rem" key={this.state.trigger_rerun}>
      <h5 className="no-margin-top margin-bottom-1rem text-uppercase text-primary">
        ORGANIZATION TOTAL USAGE & CREDITS
      </h5>
      <div className="row">
      <QueryChartWrapper
          queryType={'organization'}
          cacheID={this.props.org_id + '_alltime'}
          orgID={this.props.org_id}
          query={'getTotalAPICostByOrg'}
          chartTitle={''}
          parameters={{
            start_date: '2024-01-01',
            end_date: '2999-01-01'
          }}
          chart={data => {
            let cost = 0;
            let stacks = 0;
            let calls = 0;
            let tokens = 0;
            let characters = 0;
            let credits = 0;
            
            if(data[0] && data[0].total_cost_byokey && data[0].total_cost_non_byokey) cost = parseFloat(data[0].total_cost_byokey) + parseFloat(data[0].total_cost_non_byokey);
            if(data[0] && data[0].unique_stack_ids) stacks = parseInt(data[0].unique_stack_ids);
            if(data[0] && data[0].total_api_calls) calls = parseInt(data[0].total_api_calls);
            if(data[0] && data[0].total_tokens_used) tokens = parseInt(data[0].total_tokens_used);
            if(data[0] && data[0].total_characters_used) characters = parseInt(data[0].total_characters_used);
            if(data[0] && data[0].total_credits_remaining && data[0].total_cost_non_byokey) credits = parseInt(data[0].total_credits_remaining) - parseFloat(data[0].total_cost_non_byokey);

            if(credits < 0) credits = 0;


            return <div className="row margin-bottom-2rem row-eq-height">
              <div className="col flex-column-stretch">
                <div className="box flex-grow">
                  <div>
                    <strong>Total ZeroWidth API Calls</strong>
                  </div>
                  <h1 className="no-margin-top margin-bottom-1rem">
                    <AbbreviateNumber value={stacks.toLocaleString()}/>
                  </h1>
                </div>
              </div>
              <div className="col flex-column-stretch">
                <div className="box flex-grow">
                  <div>
                    <strong>Total Third Party API Calls</strong>
                  </div>
                  <h1 className="no-margin-top margin-bottom-1rem">
                    <AbbreviateNumber value={calls.toLocaleString()}/>
                  </h1>
                </div>
              </div>
              <div className="col flex-column-stretch">
                <div className="box flex-grow">
                  <div>
                    <strong>Total Third-party API Costs</strong>
                  </div>
                  <h1 className="no-margin-top margin-bottom-1rem">
                    {
                      cost > 0 && cost < .01 ? '< $0.01' : '$' + (cost || 0).toFixed(2)
                    }
                  </h1>
                </div>
              </div>
              <div className="col flex-column-stretch">
                <div className="box flex-grow">
                  <div>
                    <strong>Total API Credits Remaining</strong>
                  </div>
                  <h1 className="no-margin-top margin-bottom-1rem">
                    ${(credits || 0).toFixed(2)}
                  </h1>
                  <div className="thin-line-height">
                    <small className=""><i className="fal fa-info-circle icon-before-text"/> Credits let you run agents for free, without needing your own Third Party API keys.</small>
                  </div>
                </div>
              </div>
            </div>
          }}
          />
          <div className="col-md-12 margin-bottom-2rem">
            <hr className="" />
          </div>
        <div className="col-md-12">
          <h5 className="no-margin-top margin-bottom-1rem text-uppercase text-primary">
            ORGANIZATION RECENT USAGE DETAILS
          </h5>
          <div className="flex-split">
            <div className="list-left">
              <CustomField
                type="date"
                label="Start Date"
                value={this.state.start_date}
                onChange={e => {
                  console.log(e.value);
                  this.setState({ start_date: e.value });
                }}
                onBlur={e => {
                  this.setState({ trigger_rerun: this.state.trigger_rerun + 1 });
                }}
              />
              <CustomField
                type="date"
                label="End Date"
                value={this.state.end_date}
                onChange={e => {
                  this.setState({ end_date: e.value });
                }}
                onBlur={e => {
                  this.setState({ trigger_rerun: this.state.trigger_rerun + 1 });
                }}
              />

            </div>
          </div>
        </div>
        <QueryChartWrapper
          queryType={'organization'}
          cacheID={this.props.org_id + '_dated'}
          orgID={this.props.org_id}
          query={'getTotalAPICostByOrg'}
          chartTitle={''}
          parameters={{
            start_date: this.state.start_date,
            end_date: this.state.end_date
          }}
          chart={data => {
            let cost = 0;
            let stacks = 0;
            let calls = 0;
            let tokens = 0;
            let characters = 0;
            if(data[0] && data[0].total_cost) cost = parseFloat(data[0].total_cost);
            if(data[0] && data[0].unique_stack_ids) stacks = parseInt(data[0].unique_stack_ids);
            if(data[0] && data[0].total_api_calls) calls = parseInt(data[0].total_api_calls);
            if(data[0] && data[0].total_tokens_used) tokens = parseInt(data[0].total_tokens_used);
            if(data[0] && data[0].total_characters_used) characters = parseInt(data[0].total_characters_used);

            return <div className="row margin-bottom-2rem row-eq-height">
              
              <div className="col flex-column-stretch">
                <div className="box flex-grow">
                  <div>
                    <strong>ZeroWidth API Calls</strong>
                  </div>
                  <h1 className="no-margin-top margin-bottom-1rem">
                    <AbbreviateNumber value={stacks.toLocaleString()}/>
                  </h1>
                </div>
              </div>
              <div className="col flex-column-stretch">
                <div className="box flex-grow">
                  <div>
                    <strong>Third Party API Calls</strong>
                  </div>
                  <h1 className="no-margin-top margin-bottom-1rem">
                  <AbbreviateNumber value={calls.toLocaleString()}/>
                  </h1>
                </div>
              </div>
              <div className="col flex-column-stretch">
                <div className="box flex-grow">
                  <div>
                    <strong>Third-party API Costs</strong>
                  </div>
                  <h1 className="no-margin-top margin-bottom-1rem">
                    {
                      cost > 0 && cost < .01 ? '< $0.01' : '$' + (cost || 0).toFixed(2)
                    }
                  </h1>
                </div>
              </div>
              <div className="col flex-column-stretch">
                <div className="box flex-grow">
                  <div>
                    <strong>Characters Processed</strong>
                  </div>
                  <h1 className="no-margin-top margin-bottom-1rem">
                    <AbbreviateNumber value={characters.toLocaleString()}/>
                  </h1>
                  <small className="text-muted">For models that measure usage in characters.</small>
                </div>
              </div>
              <div className="col flex-column-stretch">
                <div className="box flex-grow">
                  <div>
                    <strong>Tokens Processed</strong>
                  </div>
                  <h1 className="no-margin-top margin-bottom-1rem">
                    <AbbreviateNumber value={tokens.toLocaleString()}/>
                  </h1>
                  <small className="text-muted">For models that measure usage in tokens.</small>
                </div>
              </div>
            </div>
          }}
          />
        <div className="col-md-12 margin-bottom-2rem">
          <div className="box">
            <QueryChartWrapper
              queryType={'organization'}
              cacheID={this.props.org_id}
              orgID={this.props.org_id}
              query={'getApiUsageAndCostByProjectByDay'}
              parameters={{
                start_date: this.state.start_date,
                end_date: this.state.end_date
              }}
              height={'25rem'}
              chartTitle={'Daily Third-party API Cost Breakdown'}
              chart={rawData => {

                const getUniqueProjects = (data) => {
                  const uniqueProjects = [];
                  data.forEach(item => {
                    if (!uniqueProjects.includes(item.project_id)) {
                      uniqueProjects.push(item.project_id);
                    }
                  });
                  return uniqueProjects;
                }

                const  transformData = (data, startDate, endDate, valueKey) => {
                  // Generate all dates between startDate and endDate
                  let currentDate = moment(startDate);
                  const end = moment(endDate);
                  const allDates = [];
                  while (currentDate <= end) {
                      allDates.push(currentDate.format('YYYY-MM-DD'));
                      currentDate = currentDate.add(1, 'days');
                  }
              
                  // Initialize the result array with all dates and default values
                  const result = allDates.map(date => ({ day: date }));
              
                  // Iterate over the data and fill in the counts or costs
                  data.forEach(item => {
                      const formattedDay = moment(item.day).format('YYYY-MM-DD');
                      const resultEntry = result.find(entry => entry.day === formattedDay);
                      if(resultEntry === undefined) return;
              
                      // Use transaction_count or use total_cost
                      resultEntry[item.project_id] = item[valueKey];
                  });

              
                  return result;
                }

                const data = transformData(rawData, this.state.start_date, this.state.end_date, 'total_cost');
                const projects = getUniqueProjects(rawData);


                const CustomTooltip = ({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="recharts-default-tooltip">
                        {
                          projects.map((project, index) => {
                            if(payload[0].payload[project]){
                              let name = (projectReducer.cache[project] || {}).display_name;
                              if(project === 'demo') name = 'Demo GUI';
                              return <div key={index}>
                                <strong><i className="fas fa-square icon-before-text" style={{color: generateColor(index, projects.length)}}/>{name}:</strong> ${payload[0].payload[project]}
                              </div>
                            }
                          })
                        }
                      </div>
                    );
                  }

                  return null;
                };

                return <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="day" tickSize={8} tickMargin={5}/>
                    <YAxis axisLine={false} tickSize={0} tickMargin={5} tickFormatter={v => { return '$' + parseFloat(v).toFixed(3)}}/>
                    <Tooltip content={<CustomTooltip />} />
                    {
                      projects.map((project, index) => {
                        return <Bar dataKey={project} activeBar={false} key={index} stackId={'a'} fill={generateColor(index, projects.length)}/>
                      })
                    }
                  </BarChart>
                </ResponsiveContainer>
              }}
              />
          </div>
        </div>

        <div className="col-md-8 margin-bottom-2rem">
          <div className="box">
            <QueryChartWrapper
              queryType={'organization'}
              cacheID={this.props.org_id}
              orgID={this.props.org_id}
              query={'getApiUsageAndCostByOrgByMonth'}
              parameters={{
                start_date:  moment(this.state.start_date).subtract(12, 'months').format('YYYY-MM-DD'),
                end_date: this.state.end_date
              }}
              height={'25rem'}
              chartTitle={'API Cost & Usage, Trailing 12 Months'}
              chart={data => {

                const CustomTooltip = ({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    // console.log(payload, label);
                    return  <div className="recharts-default-tooltip">
                        <div className="text-900 margin-bottom-05rem">{label}</div>
                        <strong><i className="fas fa-square icon-before-text" style={{color: '#03b2fb' }}/>API Cost:</strong> ${payload[0].value}<br/>
                        <strong><i className="fas fa-square icon-before-text" style={{color: '#9AE0FE'}}/>API Calls:</strong> {parseInt(payload[1].value).toLocaleString()}
                      </div>
                  }

                  return null;
                };

                return <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="formatted_month" tickSize={8} tickMargin={5}/>
                    <YAxis orientation="left" yAxisId="left" axisLine={false} tickSize={0} tickMargin={5} tickFormatter={v => { return '$' + parseFloat(v).toFixed(3)}}/>
                    <YAxis orientation="right" yAxisId="right"axisLine={false} tickSize={0} tickMargin={5} stroke={'#9AE0FE'} fill={'#9AE0FE'}/>
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="total_cost" yAxisId="left" activeBar={false}/>
                    <Bar dataKey="total_calls" yAxisId="right" activeBar={false} fill={'#9AE0FE'}/>
                  </BarChart>
                </ResponsiveContainer>
              }}
              />
          </div>
        </div>

        <div className="col-md-4 margin-bottom-2rem">
          <div className="box">
            <QueryChartWrapper
              queryType={'organization'}
              cacheID={this.props.org_id}
              orgID={this.props.org_id}
              query={'getApiUsageByOrgByModel'}
              parameters={{
                start_date:  this.state.start_date,
                end_date: this.state.end_date
              }}
              height={'25rem'}
              chartTitle={'API Usage by Model'}
              chart={data => {
                const CustomTooltip = ({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    // console.log(payload, label);
                    return  <div className="recharts-default-tooltip">
                        <div className="text-900 margin-bottom-05rem">{label}</div>
                        <strong><i className="fas fa-square icon-before-text" style={{color: '#03b2fb' }}/>API Cost:</strong> ${payload[0].value}<br/>
                        <strong><i className="fas fa-square icon-before-text" style={{color: '#9AE0FE'}}/>API Calls:</strong> {parseInt(payload[1].value).toLocaleString()}
                      </div>
                  }

                  return null;
                };

                return <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="model_name" tickSize={8} tickMargin={5}/>
                    <YAxis orientation="left" yAxisId="left" axisLine={false} tickSize={0} tickMargin={5} tickFormatter={v => { return '$' + parseFloat(v).toFixed(3)}}/>
                    <YAxis orientation="right" yAxisId="right"axisLine={false} tickSize={0} tickMargin={5} stroke={'#9AE0FE'} fill={'#9AE0FE'}/>
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="total_cost" yAxisId="left" activeBar={false}/>
                    <Bar dataKey="total_api_calls" yAxisId="right" activeBar={false} fill={'#9AE0FE'}/>
                  </BarChart>
                </ResponsiveContainer>
              }}
              />
          </div>
        </div>
      </div>
    </div>

  }
}
  

const mapStateToProps = (state) => {
  const { userReducer, orgReducer, projectReducer, guiReducer } = state;

  return {
    userReducer,
    orgReducer,
    projectReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(WorkbenchOrgSettings);
