import React, { useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import Hydrate from 'components/Hydrate/Hydrate';
import CustomButton from 'kit/components/CustomButton/CustomButton';

import projectTools from 'configs/config.project-tools.js'
import componentTools from 'configs/config.component-tools.js'
import knowledgeTools from 'configs/config.knowledge-tools.js'
import inquiryTools from 'configs/config.inquiry-tools.js'

import {
  tryToGetPaginated
} from '../../actions/actions.export'

import './ActivityFeed.css';

const ActivityFeed = ({ id, kind }) => {
    const dispatch = useDispatch();
    const thinking = useSelector(state => state.sharedReducer.getPaginationRequests[`${kind}/${id}/activity-log`]);
    const success = useSelector(state => state.sharedReducer.getPaginationSuccess[`${kind}/${id}/activity-log`]);
    const fail = useSelector(state => state.sharedReducer.getPaginationFail[`${kind}/${id}/activity-log`]);


    let tools;
    switch(kind){
      case 'projects':
        tools = projectTools;
        break;
      case 'components':
        tools = componentTools;
        break;
      case 'knowledge':
        tools = knowledgeTools;
        break;
      case 'inquiries':
        tools = inquiryTools;
        break;
    }

    // Load the feed on mount and when id or kind changes
    useEffect(() => {
        dispatch(tryToGetPaginated({
          id: id, 
          kind: kind,
          collection: 'activity-log'
        }));
    }, [id, kind, dispatch]);

    // Replace 'sharedReducer' with the actual name of your reducer
    const feedData = useSelector(state => state.sharedReducer.cache[`${kind}/${id}/activity-log`]);


    return (
        <div>
            <div className="flex-split margin-bottom-2rem">
                <div>
                    <h4 className="no-margin">Recent Updates</h4>
                </div>
                <div className="text-right">
                    <small>
                        Some feed items may be written by a ZeroWidth Agent. 
                        {/* <Link to="/" className="link-no-decoration">Learn More</Link> */}
                    </small>
                </div>
            </div>
            {
                feedData ? <div>
                    <div>
                        {
                        
                            feedData.data.map((a, i) => {

                                let this_tool;
                                for (let j in tools) {
                                    if (tools[j].name === a.tool) {
                                        this_tool = tools[j];
                                        break;
                                    }
                                    if (tools[j].subpages) {
                                        for (let k in tools[j].subpages) {
                                            if (tools[j].subpages[k].name === a.tool) {
                                                this_tool = tools[j].subpages[k];
                                            }
                                        }
                                    }
                                }

                                return (
                                    <div className={"activity-feed-item " + ((i === feedData.data.length - 1 && feedData.empty) ? "activity-feed-last-item" : "")} key={i}>
                                        <div className="activity-feed-icon">
                                            {
                                                (this_tool && this_tool.display_icon) ? 
                                                <i className={"fal fa-fw fa-" + this_tool.display_icon}></i>
                                                :
                                                a.tool ?
                                                <i className="fal fa-fw fa-pencil"></i>
                                                :
                                                <i className="fal fa-fw fa-comment"></i>
                                            }
                                        </div>
                                        {
                                            a.tool ? <div className="flex-column-stretch flex-grow">
                                                <div className="list-left activity-feed-header margin-bottom-1rem">
                                                    <Hydrate id={a.by} type="user" mode="lockup"/> 
                                                    <small className="text-muted">made a change {moment(a.timestamp).fromNow()}</small>
                                                    {
                                                        a.action && a.action.type === 'link' && a.action.tool &&

                                                        (a.action.id ? 
                                                            <Link to={`${window.location.pathname}/${a.action.tool}/${a.action.id}`} className="link-no-decoration">
                                                                <small>View Details</small>
                                                            </Link>
                                                            :
                                                            <Link to={`${window.location.pathname}/${a.action.tool}`} className="link-no-decoration">
                                                                <small>View Details</small>
                                                            </Link>
                                                        )
                                                    }
                                                </div>
                                                <div className="box box-half-pad no-margin">
                                                    <div className="activity-feed-content activity-feed-content-automated">
                                                        <ReactMarkdown>{a.message}</ReactMarkdown>
                                                    </div>
                                                    
                                                </div>
                                                
                                            </div>
                                            :
                                            a.event && <div className="flex-column-stretch flex-grow">
                                                <div className="list-left activity-feed-header margin-bottom-1rem">
                                                <Hydrate id={a.by} type="user" mode="lockup"/> 
                                                <small className="text-muted">made a change {moment(a.timestamp).fromNow()}</small>
                                            </div>
                                            <div className="box box-half-pad no-margin">
                                                <div className="activity-feed-content activity-feed-content-automated">
                                                    <ReactMarkdown>{a.event}</ReactMarkdown>
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                        }
                                        
                                    </div>
                                );
                            })
                        }
                    </div>
                    
                    {
                        !feedData.empty &&
                        <div className="list-center margin-top-2rem margin-bottom-2rem">
                            <CustomButton 
                                display="Load More"
                                color="transparent"
                                size="small"
                                thinking={thinking}
                                success={success}
                                fail={fail}
                                onClick={() => dispatch(tryToGetPaginated({
                                        id: id, 
                                        kind: kind,
                                        startAfter: feedData.data[feedData.data.length - 1].id,
                                        collection: 'activity-log'
                                    })
                                )}
                                />
                        </div>
                    }
                </div> : <div>
                    loading...
                </div>
            }
            
        </div>
    );
};

export default ActivityFeed;
