import Cookies from 'js-cookie';
import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from '../users/a.users.forceLogoutHandler';


import {
  tryToGetLoggedOutFeatureSwitches
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Load Features
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestLoadFeatures = packet => {
  return {
    type: 'REQUEST_LOAD_FEATURES_TOKEN',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveLoadFeaturesSuccess = data => ({
  type: 'RECEIVE_LOAD_FEATURES_TOKEN_SUCCESS',
  data
})

export const receiveLoadFeaturesFail = data => ({
  type: 'RECEIVE_LOAD_FEATURES_TOKEN_FAIL',
  data
})

export const tryToLoadFeaturesToken = packet => (dispatch, getState) => {

  dispatch(requestLoadFeatures(packet));

  // var token = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);;
  // console.log('tryToLoadFeaturesToken', token);

  // grab from localstorage instead
  var token = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  
  if(token){
    var parsed_features = JSON.parse(window.atob(token.split('.')[1]));
  
    dispatch(receiveLoadFeaturesSuccess(parsed_features))
  } else {
    dispatch(receiveLoadFeaturesFail());  
    dispatch(tryToGetLoggedOutFeatureSwitches())
  } 
}
