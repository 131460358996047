import React, { Component } from 'react'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton'
import CustomField from '../../kit/components/CustomField/CustomField'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect'
import Callout from '../../kit/components/Callout/Callout'

import isFeatureSwitchOn from '../../utilities/isFeatureSwitchOn'

import {
  showTooltip,
  hideTooltip,
  tryToCreateNewProject
} from '../../actions/actions.export'


class NewProjectForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      display_name: "",
      description: "",
      visibility: "org"
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToCreateNewProject({
      display_name: this.state.display_name,
      description: this.state.description,
      scope: this.props.org_id,
      visibility: this.state.visibility,
    }))
  }

  
  render(){

    const { dispatch, projectReducer, orgReducer } = this.props;

    let membership;
    if(orgReducer.memberships.length > 0){
      membership = orgReducer.memberships.find(m => m.organization.id === this.props.org_id).membership;
    }

    if(!membership) return <span/>;

    let canSubmit = this.state.display_name && !this.state.display_name_error;

    return <div className="box box-projects-left">
        <form onSubmit={()=>{
          if(canSubmit) this.handleSubmit()
        }}>
          <div className="flex-split margin-bottom-2rem">
            <h3 className="no-margin">
              New Project
            </h3>
            <i className="fas fa-robot text-project fa-2x text-muted"/>
          </div>
          <p className="no-margin-top ">
            Projects are the primary way to organize your agents and knowledge base against a specific application or use case. They can be shared with your team or kept private to you.
          </p>
          <p className="no-margin-top ">
            Each project can correspond with a real-world application, a specific use case, or a particular team's work. They contain tools for defining, designing, and developing the AI for your particular use case.
          </p>

          <hr/>

          <div className="row">
            <div className="col-md-6">
              <CustomField
                name="field_new_thing_name"
                label="Name"
                maxLength={64}
                placeholder="ex: Client Web Portal, Project X, etc."
                description="This can be changed later."
                required={true}
                disabled={projectReducer.tryingToCreateNewProject}
                value={this.state.display_name}
                serverError={projectReducer.newProjectForm.errors.display_name}
                lastSubmit={projectReducer.newProjectForm.lastSubmit.display_name}
                onChange={(e) => {
                  this.setState({
                    display_name: e.value,
                    display_name_error: e.error
                  })
                }}
                />
            </div>
            <div className="col-md-6">
              <CustomSelect
                label="Set Visibility"
                required={true}
                description={<div>
                  {!this.state.visibility && "Adjust who can see this."}
                  {(this.state.visibility === "org") && "This will be visible to all members of your organization."}
                  {(this.state.visibility === "team") && "This will only be visible to those you share it with."}

                </div>}
                value={this.state.visibility}
                onChange={(v)=>{
                  this.setState({
                    visibility: v
                  })
                }}
                options={[
                  {
                    label: <span><i className="far fa-fw fa-building icon-before-text"/> <span className="text-900">Anyone in this organization</span></span>,
                    value: "org"
                  },
                  {
                    label: <span><i className="far fa-fw fa-users icon-before-text text-warning"/> <span className="text-900">Only specific people</span></span>,
                    value: "team"
                  },
                ]}
                />
            </div>
            <div className="col-md-12 ">
              <CustomField
                label="What are you building?"
                name="field_new_thing_description"
                rows={5}
                maxLength={512}
                placeholder="Describe the purpose of this project, what you're building, and why."
                description="This will be used to help guide your project through the definition, design, and development process."
                disabled={projectReducer.tryingToCreateNewProject}
                value={this.state.description}
                serverError={projectReducer.newProjectForm.errors.description}
                lastSubmit={projectReducer.newProjectForm.lastSubmit.description}
                onChange={(e) => {
                  this.setState({
                    description: e.value
                  })
                }}
                />
            </div>
            <div className="col-md-12 margin-top-2rem">
              <Callout
                title="Do I need one of these for each agent?"
                content={<div>
                  <p className="p-small">
                    Nope! You can create a single project and then add as many agents and knowledge bases as you need to it. This is useful for organizing agents by application - keeping the purpose, decisions, keys, and configuration for a single application in one place, even though many agents may be involved throughout it.
                  </p>
                  <p className="p-small no-margin-bottom">
                    Any agent can also be added to multiple projects, letting you scale your AI across multiple applications or use cases.
                  </p>
                </div>}
                />
            </div>
           
          </div>

          <div className="spacer-2rem"/>

          {
            projectReducer.newProjectForm.errors.error &&
            <Callout
              style="danger"
              dismissable={true}
              content={projectReducer.newProjectForm.errors.error}
            />
          }
          
          <div className="list-right">

            <CustomButton
              display="Cancel"
              color="transparent"
              size="small"
              to={"/workbench/" + this.props.org_id + '/projects'}
              />

            <CustomButton
              display="Create Project"
              color="black"
              thinking={projectReducer.tryingToCreateNewProject}
              fail={projectReducer.newProjectFail}
              success={projectReducer.newProjectSuccess}
              disabled={!canSubmit || !membership.create_projects}
              onClick={()=>{
                if(canSubmit) this.handleSubmit()
              }}
              />
          </div>
        </form>          
      </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, orgReducer, projectReducer, guiReducer, postReducer, intelReducer  } = state;

  return {
    userReducer,
    orgReducer,
    projectReducer,
    guiReducer, 
    postReducer, 
    intelReducer
  }
}

export default connect(mapStateToProps)(NewProjectForm);
