import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'

import Hydrate from 'components/Hydrate/Hydrate'
import CustomField from 'kit/components/CustomField/CustomField'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import Callout from 'kit/components/Callout/Callout.js'
import Modal from 'kit/components/Modal/Modal.js'
import CustomTable from 'kit/components/CustomTable/CustomTable.js'

import {
  
} from 'actions/actions.export'
import { display_name } from 'docs_md/DocsOverview'


class InquiryUseCases extends Component {
  constructor(props){
    super(props);

    this.state = {
      view: 'table',
      display_name: '',
      description: '',
      business_category: '',
      bottlenecks: '',
      potential_training_data: '',
      ideal_learnings: '',
      // training_data_availability: 1,
      // ease_of_tech_integration: 1,
      // potential_impact: 1,
      // likelihood_of_off_the_shelf_solution: 1
    }
  }

  componentWillMount(){
    const { dispatch, inquiryReducer } = this.props;
  }

  componentWillReceiveProps(newprops){
    
  }

  render(){

    const { dispatch, inquiryReducer, userReducer, orgReducer, canWrite, item } = this.props;

    const inquiry = inquiryReducer.cache[this.props.id];

    if(!inquiry) return <span>Failed to load.</span>;

    if(item){
   

      return <div className="inquiry-settings">
        <div className="row">
          <div className="col-xl-12 margin-top-05rem margin-bottom-2rem">
            <div className="flex-split">
              <div className="list-left">
                <CustomButton
                  display={<span><i className="far fa-angle-left icon-before-text"/>Back</span>}
                  color="grey"
                  size="small"
                  onClick={()=>{
                    dispatch(push('/workbench/' + inquiry.scope + '/inquiries/' + inquiry.id + '/use-cases'));
                  }}
                  />
                <div>
                  {
                    item === "new" ?
                    <small>
                      Describe a new use case for AI in your project.
                    </small>
                    :
                    <small>
                      Describe a new use case for AI in your project.
                    </small>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="box">
              {
                item === "new" ?
                <h5 className="no-margin">
                  Create a new use case:
                </h5>
                :
                <h5 className="no-margin">
                  Edit your use case details:
                </h5>
              }
              <CustomField
                name="display_name"
                label="Use Case Name"
                placeholder="Name"
                value={this.state.display_name}
                onChange={(e)=>{
                  this.setState({
                    display_name: e.value
                  })
                }}
                />
              <CustomField
                name="description"
                label="Description"
                placeholder="Describe the use case in detail."
                rows={5}
                value={this.state.description}
                onChange={(e)=>{
                  this.setState({
                    description: e.value
                  })
                }}
                />
              <CustomField
                name="business_category"
                label="Business Category"
                placeholder="What part of your business does this use case impact?"
                value={this.state.business_category}
                onChange={(e)=>{
                  this.setState({
                    business_category: e.value
                  })
                }}
                />
              <hr/>
              <div className="row">
                <div className="col-md-6">
                  <CustomField
                    name="potential_training_data"
                    label="Potential Training Data"
                    placeholder="What data could be used to train the AI?"
                    rows={5}
                    value={this.state.potential_training_data}
                    onChange={(e)=>{
                      this.setState({
                        potential_training_data: e.value
                      })
                    }}
                    />
                </div>
                {/* <div className="col-md-6">
                  <CustomField
                    name="ideal_learnings"
                    label="Ideal Learnings"
                    placeholder="What would the AI learn from this data?"
                    rows={5}
                    value={this.state.ideal_learnings}
                    onChange={(e)=>{
                      this.setState({
                        ideal_learnings: e.value
                      })
                    }}
                    />
                </div> */}

                <div className="col-md-6">
                  <CustomField
                    name="bottlenecks"
                    label="Bottlenecks"
                    placeholder="What are the current bottlenecks in this process?"
                    rows={5}
                    value={this.state.bottlenecks}
                    onChange={(e)=>{
                      this.setState({
                        bottlenecks: e.value
                      })
                    }}
                    />
                </div>
                <div className="col-md-6">
                  <CustomSelect
                    name="training_data_availability"
                    label="Training Data Availability"
                    type="select"
                    placeholder="Select one..."
                    value={this.state.training_data_availability}
                    options={[
                      { value: 9, label: <span><i className="far fa-angle-up text-success fa-fw icon-before-text"/>High</span> },
                      { value: 3, label: <span><i className="far fa-minus-circle text-warning fa-fw icon-before-text"/>Medium</span> },
                      { value: 1, label: <span><i className="far fa-angle-down text-danger fa-fw icon-before-text"/>Low</span> },
                    ]}
                    onChange={(e)=>{
                      this.setState({
                        training_data_availability: e.value
                      })
                    }}
                    />
                </div>


                <div className="col-md-6">
                  <CustomSelect
                    name="ease_of_tech_integration"
                    label="Ease of Tech Integration"
                    type="select"
                    placeholder="Select one..."
                    value={this.state.ease_of_tech_integration}
                    options={[
                      { value: 9, label: <span><i className="far fa-angle-up text-success fa-fw icon-before-text"/>High</span> },
                      { value: 3, label: <span><i className="far fa-minus-circle text-warning fa-fw icon-before-text"/>Medium</span> },
                      { value: 1, label: <span><i className="far fa-angle-down text-danger fa-fw icon-before-text"/>Low</span> },
                    ]}
                    onChange={(e)=>{
                      this.setState({
                        ease_of_tech_integration: e.value
                      })
                    }}
                    />
                </div>

                <div className="col-md-6">
                  <CustomSelect
                    name="potential_impact"
                    label="Potential Impact"
                    type="select"
                    placeholder="Select one..."
                    value={this.state.potential_impact}
                    options={[
                      { value: 9, label: <span><i className="far fa-angle-up text-success fa-fw icon-before-text"/>High</span> },
                      { value: 3, label: <span><i className="far fa-minus-circle text-warning fa-fw icon-before-text"/>Medium</span> },
                      { value: 1, label: <span><i className="far fa-angle-down text-danger fa-fw icon-before-text"/>Low</span> },
                    ]}
                    onChange={(e)=>{
                      this.setState({
                        potential_impact: e.value
                      })
                    }}
                    />
                </div>

                <div className="col-md-6">
                  <CustomSelect
                    name="likelihood_of_off_the_shelf_solution"
                    label="Likelihood of an Off-the-shelf Solution"
                    type="select"
                    placeholder="Select one..."
                    value={this.state.likelihood_of_off_the_shelf_solution}
                    options={[
                      { value: 9, label: <span><i className="far fa-angle-up text-success fa-fw icon-before-text"/>High</span> },
                      { value: 3, label: <span><i className="far fa-minus-circle text-warning fa-fw icon-before-text"/>Medium</span> },
                      { value: 1, label: <span><i className="far fa-angle-down text-danger fa-fw icon-before-text"/>Low</span> },
                    ]}
                    onChange={(e)=>{
                      this.setState({
                        likelihood_of_off_the_shelf_solution: e.value
                      })
                    }}
                    />
                </div>
              </div>

              
            </div>
          </div>
        </div>
      </div>
    }


    return <div className="inquiry-settings">
      <div className="row">
        <div className="col-xl-12 margin-top-05rem margin-bottom-2rem">
          <div className="flex-split">
            <div>
              <h4 className="no-margin-top no-margin-bottom">AI Use Cases</h4>
              <small>
                Discover and evaluate the potential use cases for AI in your project.
              </small>
            </div>
            <div className="list-right">
              <div>
                <CustomButton
                  display={<span><i className="fas fa-list"/></span>}
                  size="xs"
                  color={this.state.view === 'table' ? 'black' : 'grey'}
                  noRadiusRight={true}
                  onClick={()=>{
                    this.setState({
                      view: 'table'
                    })
                  }}
                  />
                <CustomButton
                  display={<span>
                    {
                      this.state.view === 'grid' ? <i className="fas fa-th-large"/> : 
                      <i className="far fa-th-large"/>
                    }
                  </span>}
                  size="xs"
                  color={this.state.view === 'grid' ? 'black' : 'grey'}
                  noRadiusLeft={true}
                  onClick={()=>{
                    this.setState({
                      view: 'grid'
                    })
                  }}
                  />
              </div>
              <CustomButton 
                display={<span><i className="far fa-plus icon-before-text"/>Add Use Case</span>}
                color="grey" 
                size="xs"
                onClick={()=>{
                  dispatch(push('/workbench/' + inquiry.scope + '/inquiries/' + inquiry.id + '/use-cases/new'));
                }}
                />
              <CustomButton
                display={<span>Generate Use Cases<i className="fas fa-sparkles icon-after-text"/></span>}
                color="inquiry"
                size="small"
                />
            </div>
          </div>
        </div>
          <div className="col-md-12">
            <div className="row row-eq-height">
              {
                (!inquiry.use_cases || inquiry.use_cases.length === 0) &&
                <div className="col-md-4 flex-column-stretch">
                  <div className="box box-placeholder box-clickable box-half-pad">
                    <h5 className="no-margin">
                      Create your first use case
                    </h5>
                  </div>
                </div>
              }
            </div>
          </div>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, inquiryReducer, orgReducer } = state;

  return {
    userReducer, 
    inquiryReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(InquiryUseCases);

  