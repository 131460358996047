import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import shortid from 'shortid'


import './Tooltip.css';

class Tooltip extends Component {
  

  render(){
    const { tooltip } = this.props; 
    
    var style = {};

    if(tooltip.top !== undefined) style.top = tooltip.top;
    if(tooltip.bottom !== undefined) style.bottom = tooltip.bottom;
    if(tooltip.left !== undefined) style.left = tooltip.left;
    if(tooltip.right !== undefined) style.right = tooltip.right;

    return <div className="pc-tooltip-wrapper" id="tooltip">
      <div className="pc-tooltip-inner">
        <div 
        className={"pc-tooltip " + ("pc-tooltip-" + tooltip.position) + " " + (tooltip.show ? "pc-tooltip-show" : "")} 
        style={style}>
          <div className={"pc-tooltip-content " + ("pc-tooltip-content-" + tooltip.position)} key={shortid.generate()}>
          {
            tooltip.nobr === false ? tooltip.content : <nobr>{tooltip.content}</nobr>
          }
          </div>
        </div>
      </div>
    </div>
    
  }
}

const mapStateToProps = (state) => {
  const { tooltip } = state;

  return {
    tooltip
  }
}

export default connect(mapStateToProps)(Tooltip);
