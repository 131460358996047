import React, { Component } from 'react'
import shortid from 'shortid'
import { createNoise2D } from 'simplex-noise';

import LoopingCanvas from './_.LoopingCanvas';

const noise2D = createNoise2D();

class Sketch extends Component {

  constructor(props){
    super(props);

    this.state = {
      id: shortid.generate()
    }
  }

  componentDidMount(){
    let yNoiseOffsets = [];
    let ySlices = [];
    let textSize = 20;
    let N = 10;
    let sliceWidth;
    let sliceHeight;

    let tempCanvas = document.createElement('canvas');
    let tempCtx = tempCanvas.getContext('2d');  

    function map(value, start1, stop1, start2, stop2) {
      let retval = start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1));
      return retval;
    }

    document.fonts.load('12px MyCustomFont').then(() => {

      const reset = (ctx, e) => {
        yNoiseOffsets = [];
        ySlices = [];

        ctx.clearRect(0, 0, e.w, e.h);

        ctx.font = "bold " + textSize + "px MyCustomFont";

        ctx.lineWidth = 10;
        ctx.textAlign = "center";
        let width = ctx.measureText('ZeroWidth').width;
        
        if(width < e.w){
          while(width < e.w + 10){
            textSize += 5;
            ctx.font = "bold " + textSize + "px MyCustomFont";
            width = ctx.measureText('ZeroWidth').width;
          }
        } else {
           while(width > e.w + 10){
            textSize -= 5;
            ctx.font = "bold " + textSize + "px MyCustomFont";
            width = ctx.measureText('ZeroWidth').width;
          }
        }

        ctx.fillStyle = '#000';

        sliceHeight = textSize * .75;
        for(var y = 0; y < e.h; y += sliceHeight){
          ctx.fillText('ZeroWidth', e.w / 2, y - 10 + sliceHeight);

          let slices = [];
          let noiseOffsets = [];



          sliceWidth = e.w / N;
          for (let i = 0; i < N; i++) {
            const xOffset = i * sliceWidth;

            const imgData = ctx.getImageData(xOffset, y, sliceWidth, sliceHeight);
            slices.push(imgData);
          }


          for (let i = 0; i < N; i++) {
            noiseOffsets.push(Math.random() * 1000); // Random starting offsets for varied Perlin noise results
          }

          yNoiseOffsets.push(noiseOffsets);
          ySlices.push(slices);

        }
      }

      LoopingCanvas('sketch-' + this.state.id, 
        (ctx, e) => {
          // optional initialize
          // ctx.fillRect(0, 0, e.w, e.h);
          ctx.clearRect(0, 0, e.w, e.h);
          reset(ctx, e);

        },
        (ctx, e) => {
          ctx.clearRect(0, 0, e.w, e.h);


          for(var y = 0; y < ySlices.length; y++ ){
            let slices = ySlices[y];
            let noiseOffsets = yNoiseOffsets[y];

            const originalSliceWidth = e.w / N;
            let dynamicWidths = [];
            let totalDynamicWidth = 0;

            // 1. Compute dynamic widths for each slice
            for (let i = 0; i < N; i++) {
              const noiseValue = noise2D(noiseOffsets[i], 0);
              const normalizedNoiseValue = (noiseValue + 1) / 2;

              const dynamicWidth = map(normalizedNoiseValue, 0, 1, 0.001 * originalSliceWidth, 20 * originalSliceWidth);
              dynamicWidths.push(dynamicWidth);
              totalDynamicWidth += dynamicWidth;
            }

            // 2. & 3. Normalize each slice's width
            let xOffset = 0;
            for (let i = 0; i < N; i++) {
              const normalizedWidth = dynamicWidths[i] * (e.w / totalDynamicWidth);

              // Set the size of the temporary canvas to match the source area
              tempCanvas.width = sliceWidth; //normalizedWidth + 1;
              tempCanvas.height = sliceHeight;

              tempCtx.clearRect(0, 0, sliceWidth, sliceHeight);
              // Put the image data onto the temporary canvas
              tempCtx.putImageData(slices[i], 0, 0);

              // Use drawImage to draw the temporary canvas onto the original canvas with desired scaling
              ctx.drawImage(tempCanvas, 0, 0, sliceWidth, sliceHeight, xOffset - 1,  y * sliceHeight, normalizedWidth + 2, sliceHeight);

              // increase offset for next slice
              xOffset += normalizedWidth;

              // Increase the noise offset more slowly for slower animation
              noiseOffsets[i] += .002 * .25;
            }
          }
          
        })
    })
  }

  render(){

    return (
      <div className="sketch">
        <canvas id={"sketch-" + this.state.id} />
      </div>
    )
  }
}

export default Sketch;
