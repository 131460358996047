import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { push } from 'connected-react-router'

import {
  tryToGetPaginated
} from 'actions/actions.export'

import Hydrate from 'components/Hydrate/Hydrate.js'
import ActivityFeed from 'components/ActivityFeed/ActivityFeed'
import AbbreviateNumber from 'components/AbbreviateNumber/AbbreviateNumber'

import { generateColor } from 'utilities/colorUtilities';

import './ProjectHome.scss';

class ProjectHome extends Component {
  constructor(props){
    super(props);


    this.state = {
    }
  }

  componentWillMount(){
    const { dispatch, projectReducer } = this.props;

    dispatch(tryToGetPaginated({
      kind: 'projects',
      id: this.props.id,
      collection: 'activity-log'
    }))
  }

  render(){

    const { dispatch, orgReducer, projectReducer, componentReducer, userReducer, sharedReducer, canWrite } = this.props;
    
    const project = projectReducer.cache[this.props.id];
    if(!project) return <span>Project failed to load.</span>;
    
    
    return <div className="project-home">
      <div className="padding-1rem">
        <h1 className="no-margin">{project.display_name}</h1>
        <p className="margin-bottom-05rem margin-top-05rem">
          {
            project.description ? 
            project.description 
            : 
            <span 
              className="text-gs5 text-hover-black clickable"
              onClick={e => {
                dispatch(push("/workbench/" + project.scope + "/projects/" + project.id + "/settings"));
              }}>
                <small className="fal fa-pencil icon-before-text"/>Add a detailed description... 
            </span>
          }
        </p>
        <small className="text-muted">Created {moment(project.created_at).fromNow()} by <Hydrate type="user" id={project.created_by}/></small>
        
        <hr/>
        <ActivityFeed id={project.id} kind="projects"/>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, projectReducer, componentReducer,  orgReducer, sharedReducer } = state;

  return {
    userReducer, 
    projectReducer,
    componentReducer,
    orgReducer,
    sharedReducer
  }
}

export default connect(mapStateToProps)(ProjectHome);

  