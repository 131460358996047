import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

// import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

// import {
  
// } from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Get organization members by search
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestSearchOrganizationMembers = packet => {
  return {
    type: 'REQUEST_SEARCH_ORGANIZATION_MEMBERS',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveSearchOrganizationMembersSuccess = data => ({
  type: 'RECEIVE_SEARCH_ORGANIZATION_MEMBERS_SUCCESS',
  data
})

export const receiveSearchOrganizationMembersFail = data => ({
  type: 'RECEIVE_SEARCH_ORGANIZATION_MEMBERS_FAIL',
  data
})

export const tryToSearchOrganizationMembers = packet => (dispatch, getState) => {

  dispatch(requestSearchOrganizationMembers(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  let params = {
    search: packet.search
  };
  if(packet.startAfter) params.startAfter = packet.startAfter;
  if(packet.manager) params.manager = packet.manager;
  

  return fetch('/api/organizations/members/search/' + packet.id  + '?' + new URLSearchParams(params), {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveSearchOrganizationMembersSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveSearchOrganizationMembersFail({errors:e, lastSubmit: packet}));
    })
}
