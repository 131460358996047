import Cookies from 'js-cookie';
import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from './a.users.forceLogoutHandler';

// import {
  
// } from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// EditUserEmailSettings
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestEditUserEmailSettings = packet => {
  return {
    type: 'REQUEST_EDIT_USER_EMAIL_SETTINGS',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveEditUserEmailSettingsSuccess = data => ({
  type: 'RECEIVE_EDIT_USER_EMAIL_SETTINGS_SUCCESS',
  data
})

export const receiveEditUserEmailSettingsFail = data => ({
  type: 'RECEIVE_EDIT_USER_EMAIL_SETTINGS_FAIL',
  data
})

export const tryToEditUserEmailSettings = packet => (dispatch, getState) => {

  dispatch(requestEditUserEmailSettings(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/users/email-settings', {
      method: 'put',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveEditUserEmailSettingsSuccess(json.data));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveEditUserEmailSettingsFail({errors:e, lastSubmit: packet}));
    })
}
