import React, { Component } from 'react'
import { connect } from 'react-redux'

import moment from 'moment'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import CustomTable from 'kit/components/CustomTable/CustomTable.js'

import CodeHighlighter from 'components/CodeHighlighter/CodeHighlighter.js'

import {
  tryToUpdateInstalledComponent,
  showTooltip,
  hideTooltip
} from 'actions/actions.export'

import './ProjectAPISetup.scss';

class ProjectAPISetup extends Component {
  constructor(props){
    super(props);

    this.state = {
      editingComponent: false
    }
  }


  componentWillReceiveProps(nextProps){

    const { projectReducer } = nextProps;
    
    const project = projectReducer.cache[nextProps.id];

    if(nextProps.item){

      let canFind = (project.components || []).find(a => a.id === nextProps.item);

      if(canFind){
        this.setState({
          editingComponent: nextProps.item
        })
      } else {
        this.setState({
          editingComponent: false
        })
      }
    } else {
      this.setState({
        editingComponent: false
      })
    }
  }


  render(){

    const { dispatch, projectReducer, userReducer, orgReducer, sharedReducer, componentReducer, knowledgeReducer, canWrite } = this.props;

    const project = projectReducer.cache[this.props.id];

    if(!orgReducer.membershipsReady) return <span/>;

    if(!project) return <span>Failed to load project.</span>;

    let components = project.components || [];

    let both = [];

    for(let i = 0; i < components.length; i++){
      both.push({
        data: components[i],
        type: 'component'
      });
    }

    // load both array with info from componentReducer cache and knowledgeReducer cache
    for(let i = 0; i < both.length; i++){
      if(both[i].type === 'component'){
        let component = componentReducer.cache[both[i].data.id];
        if(component){
          both[i].cacheData = component;
        }
      } else {
        let knowledge = knowledgeReducer.cache[both[i].data.id];
        if(knowledge){
          both[i].cacheData = knowledge;
        }
      }
    }

    let exampleNonStatefulRequest = {
      data: {
        variables: {},
        messages: 
          [
            {
              role: 'user',
              content: 'Hello, I would like to order a pizza.'
            }
          ]
      },
      user_id: "optional_user_id_chosen_by_you",
      session_id: "optional_session_id_chosen_by_you",
    }

    
    return <div className="project-components">
      <div className="flex-split margin-bottom-2rem">
        <div className="list-left">
          <div>
            <h4 className="no-margin-top no-margin-bottom">API Setup</h4>
            <small>
              Manage API access to your project.
            </small>
          </div>
        </div>
      </div>
      <div className="box margin-bottom-2rem">
        <div className="flex-split margin-bottom-2rem">
          <div className="list-left">
            <div>
              <h4 className="no-margin-top no-margin-bottom">
                Toggle API Status
              </h4>
            </div>
          </div>
        </div>
        
        <CustomTable
          rawData={both}
          hidePagination={true}
          sortBy="type"
          headers={[
            {
              name: 'type',
              display: 'Type',
              width: '50px',
              sortGetter: (obj) => obj.type,
              getter: (obj) => {
                if(obj.type === 'knowledge'){
                  return <i className="padding-1rem far fa-book-spells text-knowledge fa-fw fa-lg"/>
                } else {
                  return <i className="padding-1rem far fa-project-diagram text-component fa-fw fa-lg"/>
                }
              }
            },
            {
              name: 'name',
              display: 'Name',
              width: '100%',
              sortGetter: (obj) => obj.cacheData.display_name,
              getter: (obj) => {
            
                if(obj.cacheData){
                  return <div className="text-ellipsis-2-lines">
                    <strong>{obj.cacheData.display_name}</strong><br/>
                    <span>{obj.cacheData.description}</span>
                  </div>
                }
                return <i className="text-muted">loading...</i>;
              }
            },
            {
              name: 'id',
              display: 'API ID',
              width: '100px',
              sortGetter: (obj) => obj.data.id,
              getter: obj => { return <nobr>
                <code>{obj.data.id}</code>
                <small className="clickable text-muted text-hover-black" onClick={e => {
                  if(navigator && navigator.clipboard) navigator.clipboard.writeText(`https://api.zerowidth.ai/beta/process/${project.id}/${obj.data.id}`);
                  dispatch(showTooltip({
                    el: e.target,
                    content: <span>
                      <i className="far fa-check text-success icon-before-text"/>Full URI copied to clipboard.
                    </span>,
                    position: 'top'
                  }));
                  setTimeout(() => {
                    dispatch(hideTooltip());
                  }, 2000);
                }}><i className="far fa-copy icon-after-text"/></small>
              </nobr>
              }
            },
            {
              name: 'last_api_called_on',
              display: 'Last Call',
              width: '100px',
              sortGetter: obj => obj.data.last_api_called_on,
              getter: obj => {
                if(obj.data.last_api_called_on){
                  return <nobr>{moment(obj.data.last_api_called_on).format('MMM D, YYYY h:mm:ss a')}</nobr>;
                } else {
                  return <i className="text-muted">never</i>;
                }
              }
            },
            {
              name: 'api_enabled',
              display: 'Status',
              width: '100px',
              sortGetter: (obj) => obj.data.api_enabled,
              getter: (obj) => {
                if(obj.cacheData){
                  if(obj.cacheData.current_version === 'draft'){
                    return <nobr className="text-warning">
                      Not yet published<i className="far fa-clock icon-after-text"/>
                    </nobr>
                  }
                }
                
                return (obj.data.api_enabled === true || obj.data.api_enabled === undefined) ? <nobr className="text-success">
                    Enabled<i className="far fa-check-circle icon-after-text"/>
                  </nobr> : 
                  <nobr className="text-danger">
                    Disabled<i className="far fa-times-circle icon-after-text"/>
                  </nobr>
              }
            },
            {
              name: 'api_enabled_button',
              display: '',
              width: '100px',
              sortGetter: (obj) => obj.data.api_enabled,
              getter: (obj) => {
                let request = {};

                if(obj.type === 'component'){
                  request = projectReducer.updateInstalledComponentRequests[obj.data.id] || {};
                }
                
                return <CustomButton
                  displayOptions={['Disable API', 'Enable API']}
                  disabled={obj.cacheData && obj.cacheData.current_version === 'draft'}
                  display={(obj.data.api_enabled === true || obj.data.api_enabled === undefined) ? 
                    <span>
                      Disable API
                    </span> : 
                    <span>
                      Enable API
                    </span>}
                  color={(obj.data.api_enabled === true || obj.data.api_enabled === undefined) ? 'success' : 'grey'}
                  size="xs"
                  thinking={request.trying}
                  fail={request.fail}
                  success={request.success}
                  onClick={e => {
                    dispatch(tryToUpdateInstalledComponent({
                      id: project.id,
                      component_id: obj.data.id,
                      api_enabled: (obj.data.api_enabled === true || obj.data.api_enabled === undefined) ? false : true
                    }))
                  }}
                  />
              }
            },
            // {
            //   key: 'description',
            //   display: 'Description'
            // },
            // {
            //   key: 'version',
            //   display: 'Version'
            // },
            // {
            //   key: 'status',
            //   display: 'Status'
            // },
            // {
            //   key: 'actions',
            //   display: 'Actions'
            // }
          ]}
          />
      </div>
      <div className="box">

        <div className="flex-split margin-bottom-2rem">
          <div className="list-left">
            <div>
              <h4 className="no-margin-top no-margin-bottom">Quick Start Guide</h4>
            </div>
          </div>
          <div className="list-right">
            <CustomButton
              color="black"
              size="small"
              display={<span><i className="far fa-code icon-before-text"></i>View Full API Docs</span>}
              to={'/docs'}
              />
          </div>
        </div>
        <div className="margin-bottom-3rem margin-top-3rem">
          URI for POST requests to process data through a flow in this project:
          <CodeHighlighter
            code={`https://api.zerowidth.ai/beta/process/${project.id}/AGENT_FLOW_API_ID_GOES_HERE`}
            block={true}
            />
          <small><span className="text-900">Pro Tip:</span> Add <code>?verbose=true</code> at the end of the URI to include the verbose details object in the API response.</small>
        </div>

        <div className="margin-bottom-3rem margin-top-3rem">
          Required Authorization Header:
          <CodeHighlighter
            code={`"Authorization": "Bearer sk0w-XXXXXXXXXXXXXXXXXX" // Replace with your Secret Key`}
            block={true}
            language="javascript"
            />
        </div>
        <div className="margin-bottom-3rem margin-top-3rem">
          <p>
            Example POST request body for <span className="text-900">non-stateful</span> use. Both <code>user_id</code> and <code>session_id</code> are optional and only used for easily debugging API logs. In this scenario, responsibility for managing the conversation history is on your application's code. Each request will require the entire conversation history that has occurred up to that point.
          </p>
          <div className="margin-bottom-3rem">
            <CodeHighlighter
              code={JSON.stringify(exampleNonStatefulRequest, null, 2)}
              language="json"
              block={true}
              />
            
          </div>
        </div>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, projectReducer, componentReducer, knowledgeReducer, orgReducer, sharedReducer } = state;

  return {
    userReducer, 
    projectReducer,
    componentReducer,
    knowledgeReducer,
    orgReducer,
    sharedReducer
  }
}

export default connect(mapStateToProps)(ProjectAPISetup);

  