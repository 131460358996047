import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Search for information directly against pinecone
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestSearchInformation = packet => {
  return {
    type: 'REQUEST_SEARCH_INFORMATION',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveSearchInformationSuccess = data => ({
  type: 'RECEIVE_SEARCH_INFORMATION_SUCCESS',
  data
})

export const receiveSearchInformationFail = data => ({
  type: 'RECEIVE_SEARCH_INFORMATION_FAIL',
  data
})

export const tryToSearchInformation = (packet, callback) => (dispatch, getState) => {

  dispatch(requestSearchInformation(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/knowledge/' + packet.knowledge_id + '/search', {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveSearchInformationSuccess(json));
      if(callback) callback();
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveSearchInformationFail({errors:e, lastSubmit: packet}));
      if(callback) callback(e);
    })
}
