
export const postReducer = (state = {
  cache: {},
  list: [],
}, action) => {

  switch(action.type){


    case 'REQUEST_GET_POST':
      return {
        ...state,
        tryingToGetPost: true,
        getPostForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GET_POST_FAIL':
       return {
        ...state,
        tryingToGetPost: false,
        getPostFail: new Date().getTime(),
        getPostForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GET_POST_SUCCESS':

      var cache = state.cache;
      cache[action.data.id] = action.data;

      return {
        ...state,
        tryingToGetPost: false,
        getPostSuccess: new Date().getTime(),
        cache: cache,
        getPostForm: {errors: {}, lastSubmit: {}},
        recentThumbnailUrl: false
      }



    case 'REQUEST_LIST_POSTS':
      return {
        ...state,
        listOutOfPages: false,
        tryingToListPosts: true
      }

    case 'RECEIVE_LIST_POSTS_FAIL':
       return {
        ...state,
        tryingToListPosts: false,
        listPostsFail: new Date().getTime()
      }

    case 'RECEIVE_LIST_POSTS_SUCCESS':

      var list = state.list;

      for(var i in action.data.data){
        let index = list.findIndex(p => p.id === action.data.data[i].id);
        if(index === -1){
          list.push(action.data.data[i]);
        }
      }
      

      return {
        ...state,
        tryingToListPosts: false,
        listOutOfPages: action.data.length === 0 || action.data.length < 100,
        listPostsSuccess: new Date().getTime(),
        list: list
      }




   
    default:
      return state
  }
}
