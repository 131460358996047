import React, { useState } from 'react';

import './Histogram.scss';

const Histogram = ({ data, objData, bucketSize, maxBucket, yMax, logScale = false}) => {

  let arrayToStart = [];
  if (data) {
    arrayToStart = data;
  }

  if(objData){
    for(var i = 0; i <= maxBucket; i+= bucketSize){
      let count = 0;
      if(objData[i]){
        count = objData[i];
      }
      arrayToStart.push(count);
    }
  }
  
  const [buckets, setBuckets] = useState(arrayToStart);

  let maxRawHeight = Math.max(...buckets); // Find the maximum value to scale bars

  if(yMax){
    maxRawHeight = yMax;
  }

  const barWidth = 100 / buckets.length; // Calculate the width of each bar

  let maxHeight = logScale ? Math.log10(maxRawHeight) : maxRawHeight;
  
  // Calculate height based on scale type
  const calculateHeight = (value) => {
    if (logScale) {
      return (Math.log10(value) / maxHeight) * 100;
    }
    return (value / maxHeight) * 100;
  };
  
  return (
    <div className={'histogramContainer'}>
      {buckets.map((value, index) => (
        <div
          key={index}
          className={'histogramBar'}
          style={{ height: `${calculateHeight(value)}%`, width: `${barWidth}%` }}
        />
      ))}
    </div>
  );
};

export default Histogram;
