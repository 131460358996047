import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Generate a refined description of a project based on feedback from the user
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestGenerateRefinedProjectDescription = packet => {
  return {
    type: 'REQUEST_GENERATE_REFINED_PROJECT_DESCRIPTION',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveGenerateRefinedProjectDescriptionSuccess = data => ({
  type: 'RECEIVE_GENERATE_REFINED_PROJECT_DESCRIPTION_SUCCESS',
  data
})

export const receiveGenerateRefinedProjectDescriptionFail = data => ({
  type: 'RECEIVE_GENERATE_REFINED_PROJECT_DESCRIPTION_FAIL',
  data
})

export const tryToGenerateRefinedProjectDescription = packet => (dispatch, getState) => {

  dispatch(requestGenerateRefinedProjectDescription(packet));

  let headers = {
    "Content-Type": "application/json"
  }


  let fs = Cookies.get(process.env.REACT_APP_COOKIE_FEATURE_SWITCH)
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/inquiries/foundations-refiner/' + packet.inquiry_id, {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveGenerateRefinedProjectDescriptionSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveGenerateRefinedProjectDescriptionFail({errors:e, lastSubmit: packet}));
    })
}
