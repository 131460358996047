import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import Checkbox from '../../kit/components/Checkbox/Checkbox.js'
import Callout from '../../kit/components/Callout/Callout.js'

import {
  tryToEditUserProfileVisibility
} from '../../actions/actions.export'

class EditUserProfileVisibilityForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      // hide_profile: props.userReducer.myData.hide_profile
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleSubmit(v){
    const { dispatch } = this.props;

    dispatch(tryToEditUserProfileVisibility({
      hide_profile: v
    }))
  }

  render(){

    const { dispatch, userReducer } = this.props;

    return <div className="box box-extra-pad">
      <form onSubmit={()=>{
        this.handleSubmit()
      }}>

        <h3 className="no-margin-top margin-bottom-2rem">
          Profile Visibility
        </h3>

        {
          userReducer.myData.hide_profile ?
          <div>
            <p className="text-900 text-danger no-margin-top">
              Your profile is currently disabled.
            </p>
            <p>
              The dedicated URL to your profile page and public content is disabled - but <span className="text-700">your avatar, name, and profile information are still visible</span> in various locations where you publicly engage or create content.
            </p>
          </div>
          :

          <div>
            <p className="text-900 text-success no-margin-top">
              Your profile is publicly enabled.
            </p>
            {/*<p>
              The dedicated URL to your profile page is: <Link to={"/uid/" + userReducer.myData.id}>{"https://zerowidth.ai/uid/" + userReducer.myData.id}</Link>
            </p>*/}
          </div>
        }

      
        <div className="spacer-2rem"/>

        {
          userReducer.editUserProfileVisibilityForm.errors.error &&
          <Callout
            style="danger"
            dismissable={true}
            content={userReducer.editUserProfileVisibilityForm.errors.error}
          />
        }
        
        <div className="list-right">
          <CustomButton
            display={<span>Disable Public Profile</span>}
            color="danger"
            thinking={userReducer.tryingToEditUserProfileVisibility}
            fail={userReducer.editUserProfileVisibilityFail}
            success={userReducer.editUserProfileVisibilitySuccess}
            disabled={userReducer.myData.hide_profile}
            onClick={()=>{
              this.handleSubmit(true)
            }}
            />
          <CustomButton
            display={<span>Enable Public Profile</span>}
            color="success"
            thinking={userReducer.tryingToEditUserProfileVisibility}
            fail={userReducer.editUserProfileVisibilityFail}
            success={userReducer.editUserProfileVisibilitySuccess}
            disabled={!userReducer.myData.hide_profile}
            onClick={()=>{
              this.handleSubmit(false)
            }}
            />
        </div>
      </form>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(EditUserProfileVisibilityForm);
