import React, { Component } from 'react'
import { connect } from 'react-redux'
import EmailVerifyForm from '../components/EmailVerifyForm/EmailVerifyForm.js'

class AlmostSignedUpRoute extends Component {

  componentDidMount(){
    window.document.title = "zerowidth.ai - Almost Sign Up";
  }

  render(){
    const { userReducer, guiReducer } = this.props;

    return <div className="hf-stretched">
      <div className="body flex-column-center-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <h1 className="text-center">nearly there</h1>
              <p className="text-center">
                A 6 digit code was sent to <strong>{userReducer.myData.email}</strong>.
              </p>
              <p className="text-center">
                Please enter it here:
              </p>
              <EmailVerifyForm hideEmailChangeLink={true}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state) => {
  const { userReducer, guiReducer } = state;

  return {
    userReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(AlmostSignedUpRoute);
