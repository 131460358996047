import Cookies from 'js-cookie';
import forceLogoutHandler from './a.users.forceLogoutHandler';

import {
  
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// MarkUserNotificationAsRead
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestMarkUserNotificationAsRead = packet => {
  return {
    type: 'REQUEST_MARK_USER_NOTIFICATION_AS_READ',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveMarkUserNotificationAsReadSuccess = data => ({
  type: 'RECEIVE_MARK_USER_NOTIFICATION_AS_READ_SUCCESS',
  data
})

export const receiveMarkUserNotificationAsReadFail = data => ({
  type: 'RECEIVE_MARK_USER_NOTIFICATION_AS_READ_FAIL',
  data
})

export const tryToMarkUserNotificationAsRead = packet => (dispatch, getState) => {

  dispatch(requestMarkUserNotificationAsRead(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/users/notification/read', {
      method: 'put',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveMarkUserNotificationAsReadSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveMarkUserNotificationAsReadFail({errors:e, lastSubmit: packet}));
    })
}
