import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import Hydrate from 'components/Hydrate/Hydrate'
import ComponentVersionBar from 'components/ComponentVersionBar/ComponentVersionBar.js'
import CustomField from 'kit/components/CustomField/CustomField'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import Callout from 'kit/components/Callout/Callout.js'

import {
  tryToSetComponentMemoryOptions,
  tryToGetLLMs
} from 'actions/actions.export'

import './ComponentMemoryOptions.scss';

class ComponentMemoryOptions extends Component {
  constructor(props){
    super(props);

    this.state = {
      memory_to_pull: 1,
      memory_threshold: .75
    }
  }

  componentWillMount(){
    const { dispatch, componentReducer } = this.props;

    const component = componentReducer.cache[this.props.id];
    if(component){
      let draft = component.versions.find(v => v.id === 'draft');
      if(draft){
        this.setState({
          memory_to_pull: draft.memory_to_pull,
          memory_threshold: draft.memory_threshold,
          memory_method: draft.memory_method,
          memory_agentic_prompt: draft.memory_agentic_prompt,
          memory_agentic_model: draft.memory_agentic_model,
          memory_agentic_history_length: draft.memory_agentic_history_length,
        })
      }
    } 
  }

  componentWillReceiveProps(newprops){
    const component = newprops.componentReducer.cache[newprops.id];
    if(component){
      if(!newprops.componentReducer.tryingToSetComponentMemoryOptions && this.state.submitted){
        let draft = component.versions.find(v => v.id === 'draft');
        if(draft){
          this.setState({
            memory_to_pull: draft.memory_to_pull,
            memory_threshold: draft.memory_threshold,
            memory_method: draft.memory_method,
            memory_agentic_prompt: draft.memory_agentic_prompt,
            memory_agentic_model: draft.memory_agentic_model,
            memory_agentic_history_length: draft.memory_agentic_history_length,
          })
        }
      }
    } 
  }

  render(){

    const { dispatch, componentReducer, userReducer, orgReducer, intelReducer, canWrite } = this.props;

    const component = componentReducer.cache[this.props.id];

    if(!component) return <span>Failed to load component.</span>;

    let canSubmit = this.state.memory_to_pull && this.state.memory_threshold !== undefined && this.state.memory_threshold !== null;

    return <div className="component-metadata">
      <div className="row">
        <div className="col-md-12 margin-top-05rem margin-bottom-2rem">
          <div className="flex-split">
            <h3 className="no-margin ">Knowledge Options</h3>

            <CustomButton 
              display={component.current_version === 'draft' ? "Save Changes" : "Save Changes to Draft"}
              thinking={componentReducer.tryingToSetComponentMemoryOptions}
              success={componentReducer.setComponentMemoryOptionsSuccess}
              fail={componentReducer.setComponentMemoryOptionsFail}
              size="small"
              disabled={!canWrite || !canSubmit}
              color="black"
              onClick={()=>{

                dispatch(tryToSetComponentMemoryOptions({
                  id: component.id,
                  memory_to_pull: this.state.memory_to_pull,
                  memory_threshold: this.state.memory_threshold,
                  memory_method: this.state.memory_method,
                  memory_agentic_prompt: this.state.memory_agentic_prompt
                }))

                this.setState({
                  submitted: true
                })
              }}
              />
          </div>
        </div>
      </div>
      
      
      <Callout
        style="warning"
        title="These settings have moved!"
        content={<div>
          <p>
            Knowledge options are now controlled within each individual variable that should be replaced with a linked Knowledge Base. This change lets you customize the knowledge options for each connection, allowing you to use different Knowledge Bases in unique ways.
          </p>
          <p>
            If you've already set knowledge options for this component, these settings will still apply for now. We recommend that you create a variable for each knowledge base you're using to take advantage of the new flexibility and avoid issues as this settings page will be fully deprecated.
          </p>
          <p className="no-margin-bottom text-900">
            This page will be removed March 1st, 2024.
          </p>
        </div>
        }
        />
    

      <div className="row">
        <div className="col-md-12 margin-bottom-1rem">
          <div className="box">
            <CustomSelect
              value={this.state.memory_method}
              label="Retrieval Method"
              disabled={true}
              options={[
                {
                  value: 'naive',
                  label: <div>
                    <div className="text-900">Naive</div>
                    <small className="text-500">Faster but simpler, searches linked knowledge by from memory based on only the most recent message from the user.</small>
                  </div>
                },

                {
                  value: 'agentic',
                  label: <div>
                    <div className="text-900">Agentic</div>
                    <small className="text-500">Slower but more focused, tasks an LLM to oversee the conversation and determine the best search string to use for knowledge look up.</small>
                  </div>
                }
              ]}
              description="How should this agent retrieve information from linked knowledge?"
              onChange={e => {
                this.setState({
                  memory_method: e
                })
              }}
              />

            <CustomField
              name={'memory_to_pull'}
              label={"Information to Pull"}
              description={"What is the maximum number of items that should be pulled from a linked Knowledge Base?"}
              type="range"
              min={1}
              max={32}
              required={true}
              step={1}
              placeholder={1}
              disabled={true}
              value={this.state.memory_to_pull}
              thinking={componentReducer.tryingToSetComponentMemoryOptions}
              serverError={componentReducer.setComponentMemoryOptionsForm.errors.memory_to_pull}
              lastSubmit={componentReducer.setComponentMemoryOptionsForm.lastSubmit.memory_to_pull}
              onChange={e => {
                this.setState({
                  memory_to_pull: e.value
                })
              }}
              />


            <CustomField
              name={'memory_threshold'}
              label={"Similarity Threshold"}
              description={"What is the minimum similarity threshold for a piece of information to be worth pulling?"}
              type="range"
              min={0}
              max={1}
              required={true}
              step={.01}
              placeholder={.75}
              disabled={true}
              value={this.state.memory_threshold}
              thinking={componentReducer.tryingToSetComponentMemoryOptions}
              serverError={componentReducer.setComponentMemoryOptionsForm.errors.memory_threshold}
              lastSubmit={componentReducer.setComponentMemoryOptionsForm.lastSubmit.memory_threshold}
              onChange={e => {
                this.setState({
                  memory_threshold: e.value
                })
              }}
              />


            <div className="margin-top-3rem">
              {
                this.state.showAgenticOptions ?
                <span className="clickable text-dark-primary text-hover-primary" onClick={e => { this.setState({ showAgenticOptions: false })}}>
                  <i className="far fa-angle-down icon-before-text"/>
                  Hide agentic knowledge retrieval options
                </span>
                :
                <span className="clickable text-dark-primary text-hover-primary" onClick={e => { this.setState({ showAgenticOptions: true })}}>
                  <i className="far fa-angle-right icon-before-text"/>
                  Show additional agentic knowledge retrieval options
                </span>
              }
            </div>

            {
              this.state.showAgenticOptions &&
              <div className="margin-top-2rem">
                {
                  this.state.memory_method === 'naive' &&
                  <Callout
                    style="warning"
                    
                    content={<div>
                      <div className="text-900"><i className="far fa-exclamation-triangle icon-before-text"/>Switch to Agentic to leverage these settings</div>
                      <small>The agentic retrieval method is required to use these settings. This will result in a potentially slower processing time for each request, but can help the agent better understand the which information from a linked Knowledge Base is relevant as a conversation shifts, references content using pronouns instead of proper nouns or demonstrative pronouns like "that", "these", or "those".</small>
                      </div>}
                    />
                }

                <CustomSelect
                  value={'gpt-4-1106-preview'}
                  label="LLM to Use for Agent Retrieval"
                  placeholder="GPT-4, PaLM2, etc"
                  description="Currently, agentic analysis runs on the GPT-4 Turbo model from OpenAI."
                  required={true}
                  serverError={componentReducer.setComponentMemoryOptionsForm.errors.memory_agentic_model}
                  lastSubmit={componentReducer.setComponentMemoryOptionsForm.lastSubmit.memory_agentic_model}
                  disabled={true}
                  options={intelReducer.llms.filter(m => m.showForTypes.includes('chat')).map((model, i)=>{
                    return {
                      label: <div className="thin-line-height">
                        <strong>{model.name}</strong><br/>
                        <small>by {model.provider}</small>
                      </div>,
                      value: model.name
                    }
                  })}
                  onChange={(e) => {

                    this.setState({
                      memory_agentic_model: e
                    })
                  }}
                  />      

                <CustomField
                  name={'memory_agentic_prompt'}
                  label={"Agent Prompt"}
                  placeholder="Analyze the current topic and user request from the following conversation to create an effective search string to use when looking up information."
                  description={"Customize the prompt that the agent will use to convert the request into more accurate search terms."}
                  required={true}
                  maxLength={1000}
                  rows={4}
                  value={this.state.memory_agentic_prompt}
                  thinking={componentReducer.tryingToSetComponentMemoryOptions}
                  serverError={componentReducer.setComponentMemoryOptionsForm.errors.memory_agentic_prompt}
                  lastSubmit={componentReducer.setComponentMemoryOptionsForm.lastSubmit.memory_agentic_prompt}
                  disabled={true}
                  onChange={e => {
                    this.setState({
                      memory_agentic_prompt: e.value
                    })
                  }}
                  />
                <div className="list-right">
                  <CustomButton
                    size="small"
                    display="Reset to Default"
                    disabled={true}
                    onClick={()=>{
                      this.setState({
                        memory_agentic_prompt: "Analyze the current topic and user request from the following conversation to create an effective search string to use when looking up information."
                      })
                    }}
                    />
                </div>
                <CustomField
                  name={'memory_agentic_history_length'}
                  label={"Messages to Analyze"}
                  placeholder="3"
                  description={"How many recent messages should the agent analyze to determine the best search string to use?"}
                  type="range"
                  required={true}
                  min={2}
                  max={10}
                  value={this.state.memory_agentic_history_length}
                  thinking={componentReducer.tryingToSetComponentMemoryOptions}
                  serverError={componentReducer.setComponentMemoryOptionsForm.errors.memory_agentic_history_length}
                  lastSubmit={componentReducer.setComponentMemoryOptionsForm.lastSubmit.memory_agentic_history_length}
                  disabled={true}
                  onChange={e => {
                    this.setState({
                      memory_agentic_history_length: e.value
                    })
                  }}
                  />

              </div>
            }
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="spacer-2rem"/>

          {
            componentReducer.setComponentMemoryOptionsForm.errors.error && <Callout style="danger" content={componentReducer.setComponentMemoryOptionsForm.errors.error}/>
          }

        </div>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, componentReducer, orgReducer, intelReducer } = state;

  return {
    userReducer, 
    componentReducer,
    orgReducer,
    intelReducer
  }
}

export default connect(mapStateToProps)(ComponentMemoryOptions);

  