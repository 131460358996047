
export * from './users/a.users.POST.waitlist.js';
export * from './users/a.users.POST.register.js';
export * from './users/a.users.POST.resend-email-verification-code.js';
export * from './users/a.users.POST.send-password-reset-link.js';
export * from './users/a.users.POST.reset-password.js';
export * from './users/a.users.POST.set-password.js';
export * from './users/a.users.POST.login.js';
export * from './users/a.users.POST.verify-email.js';
export * from './users/a.users.POST.heartbeat.js';
export * from './users/a.users.POST.logout-everywhere.js';
export * from './users/a.users.POST.avatar.js';
export * from './users/a.users.POST.contact.js';

export * from './users/a.users.GET.profile.js';
export * from './users/a.users.GET.log.js';
export * from './users/a.users.GET.notifications.js';

export * from './users/a.users.PUT.notification.read.js';
export * from './users/a.users.PUT.profile.js';
export * from './users/a.users.PUT.hide-profile.js';
export * from './users/a.users.PUT.email-address.js';
export * from './users/a.users.PUT.email-settings.js';
export * from './users/a.users.PUT.password.js';

export * from './users/a.users.DELETE.js';
export * from './users/a.users.GET.validate-session.js';
export * from './users/a.users.GET.logout-session.js';


export const logout = error => ({
  type: 'LOGOUT',
  error
})


export const clearUserLog = data => ({
  type: 'CLEAR_USER_LOG',
  data
})


export const socketCatchUserData = data => ({
  type: 'SOCKET_CATCH_USER_DATA',
  data
})


export const socketCatchUserNotification = data => ({
  type: 'SOCKET_CATCH_USER_NOTIFICATION',
  data
})


export const socketConnected = data => ({
  type: 'SOCKET_CONNECTED',
  data
})


export const socketDisconnected = data => ({
  type: 'SOCKET_DISCONNECTED',
  data
})


