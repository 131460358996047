import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Prompt } from 'react-router-dom'
import { push } from 'react-router-redux';
import moment from 'moment'
import JSZip from 'jszip'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import sortKeys from 'sort-keys';


import {
  tryToAddContentToComponent,
  tryToSaveComponentContent,
  tryToDeleteComponentContent,
  clearComponentUndoHistory,
  addToComponentUndoHistory,
  moveComponentUndoHistoryIndex,
  toggleContentPane,
  tryToUpdateComponentContentOrder
} from '../../../actions/actions.export'

import Hydrate from 'components/Hydrate/Hydrate.js'
import ComponentContentThumbnail from 'components/ComponentContentThumbnail/ComponentContentThumbnail.js'

import CustomField from 'kit/components/CustomField/CustomField.js'
import CustomButton from 'kit/components/CustomButton/CustomButton.js'
import Modal from 'kit/components/Modal/Modal.js'
import Dropdown from 'kit/components/Dropdown/Dropdown.js'

import downloadFile from 'utilities/downloadFile'

import tools from '../../../configs/config.component-tools.js'
import { deepCompare } from 'utilities/deepCompare';

const DragHandle = SortableHandle(() => <span className="padding-2rem text-hover-primary draggable"><i className="fas fa-grip-vertical"/></span>);

const SortableItem = SortableElement(({props, tool, component, content}) => <div className="flex-split margin-bottom-1rem">
    <DragHandle />
    <div className="flex-grow">
      <ComponentContentThumbnail 
        type={tool.name} 
        content={content} 
        showDetails={props.showDetails} 
        offerDetails={props.showDetails}
        linkedComponent={component}
        style={props.subpage === content.id ? "primary" : undefined}
        /> 
    </div>
  </div>);

const SortableList = SortableContainer(({props, tool, component, contentList}) => {
  return (
    <div>
      {contentList.map((content, index) => (
        <SortableItem key={`item-${content.id}`} index={index} props={props} tool={tool} component={component} content={content} />
      ))}
    </div>
  );
});


class ComponentDynamicToolManager extends Component {
  constructor(props){
    super(props);


    this.state = {
      lastSave: 0,
      lastDelete: 0,
      sortedContentList: [],
      data: {
        
      },
      reordering: false,
      menuToMerge: [],
      lastReorder: 0,
    }

    this.setStateAndHistory = this.setStateAndHistory.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  setStateAndHistory(id, d, skipHistory){
    const { dispatch } = this.props;
    
    this.setState({
      data: {
        ...this.state.data,
        ...d
      }
    });

    if(!skipHistory){
      dispatch(addToComponentUndoHistory({
        id: id,
        data: d
      }))
    }
  }


  download(){
    const { dispatch, componentReducer } = this.props;
    const component = componentReducer.cache[this.props.id];

    const zip = new JSZip();

    this.props.tool.createDownloadPacket(this.state.data, zip);

    zip.generateAsync({type:"blob"}).then((blob) => {
      
      let fileName = this.props.typeSingular + ' - ' + this.state.data.display_name + ' from ' + component.display_name + ' - Zero Width';

      downloadFile(fileName, blob);
    })
  }

  componentWillReceiveProps(newprops){  
    const { dispatch, componentReducer } = newprops;

    let tool = tools.find(t => t.name === this.props.type);

    const component = componentReducer.cache[newprops.id];
    if(!component || !component.versions) return;

    let draftComponent = component.versions.find(v => v.id === 'draft');
    if(!draftComponent) return;
    let content = (draftComponent[this.props.type] || []).find(c => c.id === newprops.subpage);

    if(!componentReducer.tryingToUpdateComponentContentOrder){
      let sortedContentList = (draftComponent[tool.name] || []).filter(item => !item.deleted);
      sortedContentList.sort((a,b)=>{
        if(a.created_at < b.created_at) return -1;
        if(a.created_at > b.created_at) return 1;
      });
      sortedContentList.sort((a,b)=>{
        if(a.order < b.order) return -1;
        if(a.order > b.order) return 1;
      });

      this.setState({
        sortedContentList: sortedContentList
      })

      if(componentReducer.updateComponentContentOrderSuccess && componentReducer.updateComponentContentOrderSuccess !== this.state.lastReorder){
        this.setState({
          reordering: false,
          lastReorder: componentReducer.updateComponentContentOrderSuccess
        })
      }
    }

    if(content && !content.deleted){

      if(content.id !== this.state.data.id || content.type !== this.state.type){

        // get the initialized packet from our tool
        let d = {
          id: content.id,
          ...this.props.tool.createInitPacket(content)
        }

        this.setState({
          deleting: false,
          type: content.type,
          confirmModalOpen: false
        });

        dispatch(clearComponentUndoHistory({id: content.id}));

        this.setStateAndHistory(content.id, d);
      }
    } else {
      // find undeleted content 
      let t = (draftComponent[this.props.type] || []).filter(c => !c.deleted);
      
      if(t.length > 0){
        // return dispatch(push("/component/" + component.id + "/" + this.props.type + "/" + t[t.length - 1].id))
      } else {
        // return dispatch(push("/component/" + component.id + "/" + this.props.type + ""));
      }
    }

    if(content){
      if(componentReducer.deleteComponentContentSuccess && componentReducer.deleteComponentContentSuccess !== this.state.lastDelete){
        this.setState({
          lastDelete: componentReducer.deleteComponentContentSuccess,
          confirmModalOpen: false
        })

        // grab the root url
        let rootUrl = window.location.pathname.split(this.props.type)[0];
        // add back just the type
        dispatch(push(rootUrl + this.props.type));
      }

      if(componentReducer.saveComponentContentSuccess && componentReducer.saveComponentContentSuccess !== this.state.lastSave){
        // if just saved, then overwrite the local with the results from the server that include sanitization 
        this.setState({
          lastSave: componentReducer.saveComponentContentSuccess,
          data: content
        })
      } else if(componentReducer.history[content.id]){
        let s = componentReducer.history[content.id].changes[componentReducer.history[content.id].index];
        if(s){
          if(JSON.stringify(s) !== JSON.stringify(this.state.data)){
            this.setState({
              data: {
                ...this.state.data,
                ...s
              }
            });
          }
        }
      }
    }
  }

  componentWillMount(){

    const { dispatch, componentReducer } = this.props;
    let tool = tools.find(t => t.name === this.props.type);
    const component = componentReducer.cache[this.props.id];
    if(!component || !component.versions) return;

    let draftComponent = component.versions.find(v => v.id === 'draft');

    if(!draftComponent) return;

    if(!componentReducer.tryingToUpdateComponentContentOrder){
      let sortedContentList = (draftComponent[tool.name] || []).filter(item => !item.deleted);
      sortedContentList.sort((a,b)=>{
        if(a.created_at < b.created_at) return -1;
        if(a.created_at > b.created_at) return 1;
      });
      sortedContentList.sort((a,b)=>{
        if(a.order < b.order) return -1;
        if(a.order > b.order) return 1;
      });

      this.setState({
        sortedContentList: sortedContentList
      })
    }

    let content = (draftComponent[this.props.type] || []).find(c => c.id === this.props.subpage);
    if(content && !content.deleted){

      // get the initialized packet from our tool
      let d = {
        id: content.id,
        ...this.props.tool.createInitPacket(content)
      }

      dispatch(clearComponentUndoHistory({id: content.id}));
      this.setStateAndHistory(content.id, d);

      this.setState({
        type: content.type
      })
      
    } else {
      let t = (draftComponent[this.props.type] || []).filter(c => !c.deleted);
      if(t.length > 0){
        // return dispatch(push("/component/" + component.id + "/" + this.props. type + "/" + t[t.length - 1].id))
      } else {
        // return dispatch(push("/component/" + component.id + "/" + this.props. type + ""));
      }
    }
  }

  onSortEnd(e, ui){
    

    const { dispatch, componentReducer } = this.props;
    
    const component = componentReducer.cache[this.props.id];
    let draftComponent = component.versions.find(v => v.id === 'draft');
    let contentList = this.state.sortedContentList;

    let newContentList = arrayMoveImmutable(contentList, e.oldIndex, e.newIndex);

    let updatedContentList = newContentList.map((c, i) => {
      return {
        ...c,
        order: i
      };
    });

    this.setState({
      sortedContentList: updatedContentList
    })
  }

  handleGallerySelection = (draftContent) => {
    const { dispatch, componentReducer } = this.props;

    let tool = tools.find(t => t.name === this.props.type);

    dispatch(tryToAddContentToComponent({
      id: this.props.id,
      type: tool.name,
      data: draftContent
    }), e => {
      this.setState({
        showGallery: false
      })
    })
  }


  render(){

    const { dispatch, orgReducer, componentReducer, intelReducer, userReducer, guiReducer, canWrite} = this.props;

    const component = componentReducer.cache[this.props.id];
    if(!component || !component.versions) return <span><i className="far fa-spinner-third fa-spin"/></span>;
    
    let draftComponent = component.versions.find(v => v.id === 'draft');
    if(!draftComponent) return <span><i className="far fa-spinner-third fa-spin"/></span>;
    let content = (draftComponent[this.props.type] || []).find(c => c.id === this.props.subpage);

    let tool = tools.find(t => t.name === this.props.type);
    

    if(!this.props.subpage || !content){
      return <div className="">
        <div className="flex-split  margin-bottom-2rem margin-top-05rem">
          <div className="list-left">
            {
              this.state.showGallery &&
              <div className="margin-right-2rem">
                <CustomButton
                  size="xs"
                  color="grey"
                  thinking={componentReducer.tryingToAddContentToComponent}
                  fail={componentReducer.addContentToComponentFail}
                  success={componentReducer.addContentToComponentSuccess}
                  display={<span><i className="far fa-angle-left icon-before-text"/>Back</span>}
                  disabled={!this.props.canWrite}
                  onClick={()=>{
                    this.setState({
                      showGallery: false
                    }) 
                  }}
                  />
              </div>
            }
            {
              !this.state.showGallery && !(tool.getRenderedGallery && (draftComponent[tool.name] || []).length === 0) &&
              <CustomButton
                size="xs"
                color="primary"
                thinking={componentReducer.tryingToAddContentToComponent}
                fail={componentReducer.addContentToComponentFail}
                success={componentReducer.addContentToComponentSuccess}
                display={<span><i className="far fa-plus icon-before-text"/>Add {this.state.sortedContentList.length > 0 ? "Another" : "Your First"} { tool.display_name_singular }</span>}
                disabled={this.state.reordering || !this.props.canWrite}
                onClick={()=>{
                  if(tool.getRenderedGallery){
                    this.setState({
                      showGallery: true
                    }) 
                  } else {
                    dispatch(tryToAddContentToComponent({
                      id: component.id,
                      type: tool.name
                    }))
                  }
                }}
                />
            }
            <div className="">
              {/* <h4 className="no-margin-top no-margin-bottom margin-right-2rem">
                {tool.display_name}
              </h4> */}
              <small>{tool.description}</small>
            </div>
          </div>
          <div className="list-right list-right-no-wrap">
            {
              (draftComponent[tool.name] || []).length > 0 && (
                this.state.reordering ?
                <CustomButton
                  size="xs"
                  color="success"
                  thinking={componentReducer.tryingToUpdateComponentContentOrder}
                  success={componentReducer.updateComponentContentOrderSuccess}
                  fail={componentReducer.updateComponentContentOrderFail}
                  display={<span>Save Order</span>}
                  onClick={()=>{
                    dispatch(tryToUpdateComponentContentOrder({
                      component_id: component.id,
                      type: tool.name,
                      contentList: this.state.sortedContentList.map((c, i) => {
                        return {
                          ...c,
                          order: i
                        }
                      })
                    }))
                  }}
                  />
                :
                tool.sortable &&
                <CustomButton
                  size="xs"
                  color=""
                  display={<span><i className="far fa-sort icon-before-text"/>Reorder</span>}
                  disabled={this.state.sortedContentList.length < 2}
                  onClick={()=>{
                    this.setState({
                      reordering: true
                    })
                  }}
                  />
              )
            }
            {
              tool.bulkActions && tool.bulkActions.map((action, i) => {
                return <CustomButton
                  key={i}
                  size="xs"
                  color={action.color || "grey"}
                  display={<span><i className={"far fa-" + action.icon + " icon-before-text"}/>{action.display_name}</span>}
                  onClick={()=>{
                    if(action.confirm){
                      if(window.confirm(action.confirm)){
                        action.onClick(component, this.props)
                      } 
                    } else {
                      action.onClick(component, this.props)
                    }
                  }}
                  />
              })
            }
            

          </div>
        </div>
        {
          this.state.showGallery ?
          <div>
            {tool.getRenderedGallery(draftComponent[tool.name], this.props, this.handleGallerySelection)}
          </div>
          :
          <div>
              {
                tool.sortable ?
                <div>
                  {
                    this.state.reordering ?
                    <SortableList 
                      useDragHandle 
                      tool={tool}
                      props={this.props}
                      component={component}
                      contentList={this.state.sortedContentList} 
                      onSortEnd={this.onSortEnd} 
                      helperClass="component-content-thumbnail-dragging"
                      />
                    :
      
                    this.state.sortedContentList.map((item, i)=>{
      
                      return <div className="margin-bottom-1rem" key={item.id}>
                        <ComponentContentThumbnail 
                          type={tool.name} 
                          content={item} 
                          showDetails={this.props.showDetails} 
                          offerDetails={this.props.showDetails}
                          linkedComponent={component}
                          style={this.props.subpage === item.id ? "primary" : undefined}
                          /> 
                      </div>
                      }
                    )
                  
                  }
                  {/* <ReactSortable
                    group="groupName"
                    animation={200}
                    delayOnTouchStart={true}
                    delay={2}
                    >
                    
                  </ReactSortable> */}
      
                </div>
                :
                <div className="row gx-2 row-eq-height">
                  {
                    (draftComponent[tool.name] || []).map((item, i)=>{
                      if(item.deleted) return;
      
                      return <div className={" margin-bottom-1rem flex-column-stretch col-md-" + (tool.thumbnailColSize || 6)} key={i}>
                        <ComponentContentThumbnail 
                          type={tool.name} 
                          content={item} 
                          showDetails={this.props.showDetails} 
                          offerDetails={this.props.showDetails}
                          linkedComponent={component}
                          style={this.props.subpage === item.id ? "primary" : undefined}
                          /> 
                      </div>
                      }
                    )
                  }
                </div> 
              }
      
              {
                ((draftComponent[tool.name] || []).length === 0 && !tool.getRenderedGallery) &&
                <span className="text-muted">You haven't added any yet.</span>
              }
              {
                (tool.getRenderedGallery && (draftComponent[tool.name] || []).length === 0) &&
                <div className="">
                  {tool.getRenderedGallery(draftComponent[tool.name], this.props, this.handleGallerySelection)}
                </div>
              }
          </div>
        }
      </div> 
    }


    let menu = [
      {
        display_name: "Actions",
        items: [
          // {
          //   display_name: 'New ' + this.props.typeSingular,
          //   display_icon: 'plus',
          //   shortcut: true,
          //   thinking: componentReducer.tryingToAddContentToComponent,
          //   tooltip: <span>Create a new {this.props.typeSingular} in this component</span>,
          //   disabled: tool.maxInstancesPerComponent ? draftComponent[this.props.type].length >= tool.maxInstancesPerComponent : false,
          //   hidden: tool.maxInstancesPerComponent === 1,
          //   onClick: () => {
          //    dispatch(tryToAddContentToComponent({
          //       id: component.id,
          //       type: this.props.type
          //     }))
          //   }
          // },
          {
            display_name: 'Make a Copy',
            display_icon: 'copy',
            shortcut: true,
            thinking: componentReducer.tryingToMakeACopyOfContent,
            tooltip: <span>Make a copy of this {this.props.typeSingular} in this component</span>,
            disabled: tool.maxInstancesPerComponent ? draftComponent[this.props.type].length >= tool.maxInstancesPerComponent : false,
            hidden: tool.maxInstancesPerComponent === 1 || tool.disableCopy,
            onClick: () => {
             dispatch(tryToAddContentToComponent({
                id: component.id,
                type: this.props.type,
                content_id: content.id
              }))
            }
          },
          // {
          //   display_name: 'Undo',
          //   display_icon: 'undo',
          //   disabled: componentReducer.history[content.id] ? (componentReducer.history[content.id].changes.length - 1 === componentReducer.history[content.id].index ? true : false) : true,
          //   shortcut: true,
          //   // tooltip: <span>Saves changes to draft for review ahead of publishing</span>,
          //   onClick: () => {
          //     dispatch(moveComponentUndoHistoryIndex({
          //       id: content.id,
          //       direction: 1
          //     }))
          //   }
          // },
          // {
          //   display_name: 'Redo',
          //   display_icon: 'redo',
          //   disabled: componentReducer.history[content.id] ? (componentReducer.history[content.id].index === 0 ? true : false) : true,
          //   shortcut: true,
          //   // tooltip: <span>Saves changes to draft for review ahead of publishing</span>,
          //   onClick: () => {
          //     dispatch(moveComponentUndoHistoryIndex({
          //       id: content.id,
          //       direction: -1
          //     }))
          //   }
          // },
          // {
          //   display_name: 'Download',
          //   display_icon: 'file-download',
          //   shortcut: true,
          //   // thinking: componentReducer.tryingToSaveComponentContent,
          //   tooltip: <span>Download a zip containing the raw data for this {this.props.typeSingular}.</span>,
          //   onClick: () => {
          //     this.download();
          //   }
          // },
          // !(tool.maxInstancesPerComponent === 1 || tool.disableCopy) && 'divider',
          {
            display_name: 'Delete',
            display_icon: 'trash',
            // shortcut: true,
            thinking: componentReducer.tryingToDeleteComponentContent,
            hidden: tool.maxInstancesPerComponent === 1,
            // tooltip: <span>Deletes this color completely from this component</span>,
            onClick: () => {
              this.setState({
                confirmModalOpen: true
              })
            }
          }
        ]
      },
    ]

    menu = menu.concat(this.state.menuToMerge);

    let unsavedChanges = false;

    if(content.id === this.state.data.id){

      // add data here to reflect what is stored based on the reducer
      let serverComparisonPacket = sortKeys(this.props.tool.createStructuredPacket(content), {deep:true});

      // add content here to reflect what is stored within state
      let clientComparisonPacket = sortKeys(this.props.tool.createStructuredPacket(this.state.data), {deep: true});
    

      if(!deepCompare(clientComparisonPacket, serverComparisonPacket)){
        unsavedChanges = true;
      }
    }

    
    return <div className="">

      {
        !this.state.deleting &&
        <Prompt
          when={unsavedChanges}
          message={"There are unsaved changes, are you sure you want to leave without saving?"}/>
      }

      <Modal 
        show={this.state.confirmModalOpen}
        content={<div>
          <p>Are you sure you want to delete this?</p>
        </div>}
        acceptButtonLabel="Yes, delete it"
        acceptable={true}
        acceptButtonThinking={componentReducer.tryingToDeleteComponentContent}
        onAccept={()=>{
          this.setState({
            deleting: true
          })
          setTimeout(()=>{
            dispatch(tryToDeleteComponentContent({
              component_id: component.id,
              content_id: content.id,
              type: this.props.type
            }))
          },100);
        }}
        cancelButtonLabel="Nevermind"
        cancelable={true}
        onCancel={()=>{
          this.setState({
            confirmModalOpen: false
          })
        }}
        exitable={true}
        onExit={()=>{
          this.setState({
            confirmModalOpen: false
          })
        }}
        />

      <div className="">
        <div className="flex-split margin-top-05rem">        
          <div className="list-left">
            <CustomButton
              size="xs"
              color="grey"
              display={<span><i className="far fa-angle-left icon-before-text"/>Back to all {tool.display_name}</span>}
              onClick={e => {
                // split by type
                let rootUrl = window.location.pathname.split(this.props.type)[0];
                dispatch(push(rootUrl + this.props.type))
                this.setState({
                  showGallery: false
                })
              }}
              />
            
            {
              menu.length > 1 && menu.map((m, i) => {
                if(i === 0) return;
                return <Dropdown
                  disabled={!canWrite}
                  key={i}
                  align={m.dropdownAlign || "right"}
                  items={
                    m.items.map((item, i) => {
                      if(item.hidden) return;

                      if(item === 'divider') return item;

                      return {
                        disabled: item.disabled,
                        content: <span onClick={item.onClick}>
                          <i className={"fal fa-fw fa-" + item.display_icon + " icon-before-text"}/>{item.display_name}
                        </span>
                      }
                    })
                  }
                  target={
                    <CustomButton
                      display={<span>{m.display_name}<i className="fal fa-angle-down icon-after-text"/></span>}
                      color={m.color || "transparent"}
                      size="xs"
                      disabled={!canWrite}
                      />
                  }/>
              }) 
            }
          </div>
          <div className="list-right">
            <div className="margin-right-1rem">
              {
                unsavedChanges ? 
                <small className="text-danger">You've made changes that haven't been saved yet.</small>
                :
                <small className="text-muted">Last updated {moment(content.last_updated).fromNow()} by <Hydrate id={content.last_updated_by} type="user"/></small>
              }
            </div>
            <Dropdown
              disabled={!canWrite}
              align="right"
              items={
                menu[0].items.map((item, i) => {
                  if(item.hidden) return;

                  if(item === 'divider') return item;

                  return {
                    disabled: item.disabled,
                    content: <span onClick={item.onClick}>
                      <i className={"fal fa-fw fa-" + item.display_icon + " icon-before-text"}/>{item.display_name}
                    </span>
                  }
                })
              }
              target={
                <CustomButton
                  display={<span>Actions<i className="fal fa-angle-down icon-after-text"/></span>}
                  color="transparent"
                  size="xs"
                  disabled={!canWrite}
                  />
              }/> 

              <CustomButton
                display={<span>Save Changes</span>}
                thinking={componentReducer.tryingToSaveComponentContent}
                success={componentReducer.saveComponentContentSuccess}
                fail={componentReducer.saveComponentContentFail}
                disabled={!unsavedChanges || !canWrite}
                color="black"
                size="xs"
                onClick={()=>{
                  dispatch(tryToSaveComponentContent({
                    component_id: component.id,
                    content_id: content.id,
                    type: this.props.type,
                    data: this.props.tool.createStructuredPacket(this.state.data)
                  }))
                }}
                />
          </div>
          
        </div>
        <div className="">
          <div className="margin-top-2rem" key={content.id}>
            <this.props.tool.Tool 
              data={this.state.data} 
              org_id={component.scope}
              component={component}
              draftComponent={draftComponent}
              content={content}
              canWrite={canWrite}
              unsavedChanges={unsavedChanges}
              menuMerge={(m) => {
                this.setState({
                  menuToMerge: m
                })
              }}
              onChange={(d, skipHistory)=>{
                this.setStateAndHistory(content.id, d, skipHistory);
              }}
              onManualHistoryLog={(d)=>{
                dispatch(addToComponentUndoHistory({
                  id: content.id,
                  data: d
                }))
              }}
              onSave={()=>{
                dispatch(tryToSaveComponentContent({
                  component_id: component.id,
                  content_id: content.id,
                  type: this.props.type,
                  data: this.props.tool.createStructuredPacket(this.state.data)
                }), e => {
                  console.log('save callback')
                })
              }}
              />
          </div>
        </div>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, componentReducer, intelReducer, orgReducer, guiReducer } = state;

  return {
    userReducer, 
    componentReducer,
    intelReducer,
    orgReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(ComponentDynamicToolManager);

  