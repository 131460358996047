import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import './BlogPostThumbnail.css';

class BlogPostThumbnail extends Component {
  _isMounted = false;

  constructor(props){
    super(props);

  }


  render(){
    let classList = "";

    let post = {
      ...{
        title: ''
      },
      ...this.props.post
    }

    return <Link className={"blog-post-thumbnail " + classList} to={this.props.url}>
      <img src={post.thumbnail_url} className="img-rounded-square-wrapper"/>
      <h4 className="blog-post-thumbnail-title">{post.title}</h4>
      <small className="text-400 no-text-decoration">{moment(post.created_at).format('MMM D, YYYY')}</small>

    </Link>
  }
};

export default BlogPostThumbnail;