
const toast = (state = {
  toast_list: []
}, action) => {

  switch(action.type){
    case 'ADD_TOAST':
      
      var new_toast = action.data;

      if(!new_toast.buttons){
        new_toast.buttons = [
          {
            display: "Dismiss",
            dismissOnClick: true
          }
        ]
      }

      var new_toast_list = state.toast_list;

      var alreadyAdded = false;
      for(var i in new_toast_list){
        // if(new_toast_list[i].title === new_toast.title && new_toast_list[i].detail === new_toast.detail){
        //   alreadyAdded = true;
        //   break;
        // }

        if(new_toast_list[i].id == new_toast.id){
          alreadyAdded = true;
          break;
        }
      }

      if(!alreadyAdded){
        new_toast_list.push(new_toast);
      }

      return {
        ...state,
        toast_list: new_toast_list
      }

    case 'DISMISS_TOAST':

      var new_toast_list = state.toast_list;

      for(var i = 0; i < new_toast_list.length; i++){
        if(new_toast_list[i].id === action.data.id){
          if(new_toast_list[i].func){
            // if(specialToastFunctionDictionary[new_toast_list[i].func]){
            //   specialToastFunctionDictionary[new_toast_list[i].func](new_toast_list[i]);
            // }
          }
          new_toast_list.splice(i, 1);
          break;
        }
      }

      return {
        ...state,
        toast_list: new_toast_list
      }
        
    default:
      return state
  }
}

export default toast;