import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomField from 'kit/components/CustomField/CustomField'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import MegaMarkdown from 'components/MegaMarkdown/MegaMarkdown';

export const NodeSettings_prompt = ({ node, setNodeSettings, canWrite }) => {

  if (!node.settings) {
    return null;
  }

  const [settings, setSettings] = useState(node.settings || {});

  return (
    <div>
      <CustomSelect
        value={node.settings.role}
        placeholder="Who is saying this?"
        description="Most of the time, Instructional message is the right choice."
        disabled={!canWrite}
        label="Role"
        large={true}
        options={[
          {
            label: <div className="list-left list-left-no-wrap">
              <div>
                <i className="far fa-chalkboard-teacher fa-lg fa-fw"/>
              </div>
              <div className="thin-line-height">
                <strong>Instructional Message</strong><br/>
                <small>For speaking directly to the LLM, giving it direct training instructions, and inserting variables.</small>
              </div>
            </div>,
            value: 'system', 
          },
          {
            label: <div className="list-left list-left-no-wrap">
              <div>
                <i className="far fa-robot fa-lg fa-fw"/>
              </div>
              <div className="thin-line-height">
                <strong>From the Agent</strong><br/>
                <small>Injected messages as the agent itself, most frequently used for welcome messages.</small>
              </div>
            </div>,
            value: 'agent', 
          },
          {
            label: <div className="list-left list-left-no-wrap">
              <div>
                <i className="far fa-user fa-lg fa-fw"/>
              </div>
              <div className="thin-line-height">
                <strong>From the User</strong><br/>
                <small>For speaking directly as the user, not common but useful for injecting additional context.</small>
              </div>
            </div>,
            value: 'user', 
          },
        ]}
        onChange={(v)=>{

          setNodeSettings({
            role: v
          })
        }}
        />

      <CustomField
        label="Text"
        value={settings.text}
        rows={10}
        canWrite={canWrite}
        placeholder="Enter text here..."
        description="This node will output the configured text."
        onChange={(e) => {
          setSettings({
            ...settings,
            text: e.value,
          })
        }}
        onBlur={(e) => {
          setNodeSettings(settings)
        }}
        />
    </div>
  );
}


export const NodeRenderer_prompt = ({ node, type }) => {

  let role = node.settings.role;
  if(!role) {
    role = type.settings.find(s => s.name === 'role').default;
  }
  
  return (
    <div className="">
      <div className="padding-1rem thin-line-height">
        {
          role === 'system' &&
          <div className="text-900">
            <i className="fas fa-chalkboard-teacher fa-fw icon-before-text"/> Instruction
          </div>
        }
        {
          role === 'agent' &&
          <div className="text-900">
            <i className="fas fa-robot fa-fw icon-before-text"/> Agent
          </div>
        }
        {
          role === 'user' &&
          <div className="text-900">
            <i className="fas fa-user fa-fw icon-before-text"/> User
          </div>
        }
        {
          role === 'assistant' &&
          <div className="text-900">
            <i className="fas fa-robot fa-fw icon-before-text"/> Assistant (legacy)
          </div>
        }
      </div>
      <div className="scroll-parent" style={{width: 250, height: 200}}>
        <div className="scroll-child padding-1rem thin-line-height">
          {/* <small>{node.settings.text}</small> */}
          <MegaMarkdown text={node.settings.text} />
        </div>
      </div>
    </div>
  );
}