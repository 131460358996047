import React, { Component } from 'react'
import { connect } from 'react-redux'
import EmailVerifyForm from '../components/EmailVerifyForm/EmailVerifyForm.js'

import {
  tryToLogout
} from '../actions/actions.export.js'

class AlmostSignedUpRoute extends Component {

  componentDidMount(){
    window.document.title = "zerowidth.ai - Hold Up";
  }


  render(){
    const { userReducer } = this.props;

    return <div className="hf-stretched">
      <div className="body flex-column-center-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <h1 className="text-center no-margin-top">✋&nbsp;hold up</h1>
              <p className="text-center">
                Due to suspicious activity on your account, a 6 digit code was just sent to <strong>{userReducer.myData.email}</strong>.
              </p>
              <p className="text-center">
                Please enter it here:
              </p>
              <EmailVerifyForm hideEmailChangeLink={true}/>
              <p className="text-center">
                <small><a href="#" onClick={(e) => {
                  e.preventDefault();
                  this.props.dispatch(tryToLogout());
                }}>Log out instead.</a></small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(AlmostSignedUpRoute);
