import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { push } from 'connected-react-router'

import {
  tryToGetPaginated
} from 'actions/actions.export'

import Hydrate from 'components/Hydrate/Hydrate.js'
import ActivityFeed from 'components/ActivityFeed/ActivityFeed'

import tools from 'configs/config.knowledge-tools'

import './KnowledgeHome.scss';


class KnowledgeHome extends Component {
  constructor(props){
    super(props);


    this.state = {

    }
  }

  componentWillMount(){
    const { dispatch, knowledgeReducer } = this.props;

    dispatch(tryToGetPaginated({
      kind: 'knowledge',
      id: this.props.id,
      collection: 'activity-log'
    }))
  }

  render(){

    const { dispatch, orgReducer, knowledgeReducer, userReducer, sharedReducer, canWrite } = this.props;
    
    const knowledge = knowledgeReducer.cache[this.props.id];
    if(!knowledge) return <span>Failed to load.</span>;
    
    
    return <div className="knowledge-home">
      <div className="box box-transparent box-no-shadow box-near-no-pad">
        <div className="flex-split">
          <div>
            <h5 className="no-margin text-uppercase text-knowledge">Knowledge Base</h5>
            <h2 className="no-margin">{knowledge.display_name}</h2>
            <p className="margin-bottom-05rem margin-top-05rem">
              {
                knowledge.description ? 
                knowledge.description 
                : 
                <span 
                  className="text-gs5 text-hover-black clickable"
                  onClick={e => {
                    dispatch(push("/workbench/" + knowledge.scope + "/knowledge/" + knowledge.id + "/settings"));
                  }}>
                    <small className="fal fa-pencil icon-before-text"/>Add a detailed description... 
                </span>
              }
            </p>
            <small className="text-muted">Created {moment(knowledge.created_at).fromNow()} by <Hydrate type="user" id={knowledge.created_by}/></small>
          </div>
          
        </div>
      </div>
      <div className="row row-eq-height margin-top-1rem">
        <div className="col-md-4 flex-column-stretch">
          <Link to={"/workbench/" + knowledge.scope + "/knowledge/" + knowledge.id + "/add"} className="text-400 link-no-decoration box flex-grow box-half-pad">
            <div className="flex-split margin-bottom-05rem flex-split-align-start">
              <h5 className="no-margin ">Add Information</h5>
              <small className="text-muted"><i className="fal fa-plus"/></small>
            </div>
            <p className="thin-line-height no-margin-bottom">
              <small>Try the new guided process to load files and add information to this knowledge base.</small>
            </p>
          </Link>
        </div>
        <div className="col-md-4 flex-column-stretch">
          <Link to={"/workbench/" + knowledge.scope + "/knowledge/" + knowledge.id + "/info"} className="text-400 link-no-decoration box flex-grow box-half-pad">
            <div className="flex-split margin-bottom-05rem flex-split-align-start">
              <h5 className="no-margin ">Chunks of Information</h5>
              <small className="text-muted"><i className="fal fa-info"/></small>
            </div>
            <h2 className="no-margin-top no-margin-bottom margin-left-1rem ">
              {(knowledge.total_info || 0).toLocaleString()}
            </h2>
          </Link>
        </div>
        {/* <div className="col-md-4 flex-column-stretch">
          <Link to={"/workbench/" + knowledge.scope + "/knowledge/" + knowledge.id + "/files"} className="text-400 link-no-decoration box flex-grow box-half-pad">
            <div className="flex-split margin-bottom-05rem flex-split-align-start">
              <h5 className="no-margin ">Uploaded Files</h5>
              <small className="text-muted"><i className="fal fa-file-alt"/></small>
            </div>
            <h2 className="no-margin-top no-margin-bottom margin-left-1rem ">
              {(knowledge.total_files || 0).toLocaleString()}
            </h2>
          </Link>
        </div> */}
        
      </div>
      <div className="row">
        <div className="col-md-12">
          <hr/>
        </div>
        <div className="col-md-12">
          <ActivityFeed id={knowledge.id} kind="knowledge"/>
        </div>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, knowledgeReducer, orgReducer, sharedReducer } = state;

  return {
    userReducer, 
    knowledgeReducer,
    orgReducer,
    sharedReducer
  }
}

export default connect(mapStateToProps)(KnowledgeHome);

  