import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const hash = window.location.hash;

    if (hash && this.props.location !== prevProps.location) {
      const el = document.getElementById(hash.replace('#', ''));
      if (el) {
        const top = el.getBoundingClientRect().top + window.pageYOffset;

        if (this.props.location.pathname === prevProps.location.pathname) {
          window.scrollTo({ top: top, behavior: 'smooth' });
        } else {
          window.scrollTo(0, top);  
        }

        return true;
      } 
    }

    if (!hash && this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
