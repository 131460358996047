const use_cases = [
  "Suggest products based on what people like.",
  "Improve search results to help customers find things faster.",
  "I want to automate form filling on my site.",
  "Guide new users with tips that adjust to their actions.",
  "I’m aiming to make checkout smoother by predicting user actions.",
  "Provide instant answers to customer questions.",
  "Send notifications that match user interests.",
  "I want to gather feedback without interrupting users.",
  "Make search filters easier based on preferences.",
  "Add smart suggestions in forms and searches.",
  "Let’s create helpful tips based on user activity.",
  "Suggest next steps to guide users through our app.",
  "Analyze customer feedback to improve products.",
  "Streamline vendor communication and methods.",
  "Improve demand forecasts for better planning.",
  "Monitor competitors automatically.",
  "I’m aiming to optimize sales by analyzing performance data.",
  "Automate quality checks to maintain standards.",
  "Align employee schedules with business needs.",
  "Track industry trends to stay competitive.",
  "Personalize marketing with customer data.",
  "I’m aiming to automate lead generation from customer behavior.",
  "I want to use our internal data to automate personalized customer experiences.",
  "Leverage our historical knowledge to optimize decision-making in real-time.",
  "Let’s build AI that uses our best practices to streamline operations.",
  "Automate customer support by tapping into our existing knowledge base.",
  "I’m aiming to enhance product development with insights from our past projects.",
  "Use our internal expertise to build smarter training modules.",
  "Optimize marketing strategies with AI trained on our own data.",
  "I want to automate quality checks using our established standards.",
  "Let’s create AI-driven recommendations based on our customer data.",
  "Leverage our context to automate risk management processes.",
  "I’m aiming to refine sales tactics with insights from our internal data.",
  "Use our knowledge base to improve employee onboarding automatically.",
  "I want to automate contract management using our legal templates.",
  "Create a system that uses our data to predict and prevent issues.",
  "Let’s automate compliance tracking against our own standards.",
  "Enhance customer loyalty programs by using insights from our history."
]
export default use_cases;