import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from './a.users.forceLogoutHandler';

import {
  tryToGetUserLog
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Log out everywhere
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestLogoutEverywhere = packet => {
  return {
    type: 'REQUEST_LOGOUT_EVERYWHERE',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveLogoutEverywhereSuccess = data => ({
  type: 'RECEIVE_LOGOUT_EVERYWHERE_SUCCESS',
  data
})

export const receiveLogoutEverywhereFail = data => ({
  type: 'RECEIVE_LOGOUT_EVERYWHERE_FAIL',
  data
})

export const tryToLogoutEverywhere = packet => (dispatch, getState) => {

  dispatch(requestLogoutEverywhere(packet));


  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/users/logout-everywhere', {
      method: 'post',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveLogoutEverywhereSuccess());
      dispatch(tryToGetUserLog({type: 'login'}));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveLogoutEverywhereFail({errors:e, lastSubmit: packet}));
    })
}
