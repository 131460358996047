
export const prototypeReducer = (state = {
  cache: {},
  cacheRequests: [],
  ready: false
}, action) => {

  switch(action.type){

    

    case 'REQUEST_GET_PROTOTYPES_BY_ORGANIZATION':
      return {
        ...state,
        tryingToGetPrototypesByOrganization: true
      }

    case 'RECEIVE_GET_PROTOTYPES_BY_ORGANIZATION_FAIL':
       return {
        ...state,
        tryingToGetPrototypesByOrganization: false,
        getPrototypesByOrganizationFail: new Date().getTime()
      }

    case 'RECEIVE_GET_PROTOTYPES_BY_ORGANIZATION_SUCCESS':
      
      var cache = state.cache;
      for(var i in action.data){
        let p = action.data[i];
        cache[p.id] = {
          ...cache[p.id],
          ...p
        };
      }

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToGetPrototypesByOrganization: false,
        getPrototypesByOrganizationSuccess: new Date().getTime()
      }


    case 'REQUEST_GET_PROTOTYPE':

      var cacheRequests = state.cacheRequests;
      cacheRequests.push(action.data.lastSubmit.id);

      return {
        ...state,
        cacheRequests: cacheRequests,
        tryingToGetPrototype: true
      }

    case 'RECEIVE_GET_PROTOTYPE_FAIL':
       return {
        ...state,
        tryingToGetPrototype: false,
        getPrototypeFail: new Date().getTime()
      }

    case 'RECEIVE_GET_PROTOTYPE_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id] = {
        ...cache[action.data.id],
        ...action.data
      }

      return {
        ...state,
        cache: cache,
        tryingToGetPrototype: false,
        getPrototypeSuccess: new Date().getTime()
      }

    case 'REQUEST_LAUNCH_PROTOTYPE':
      return {
        ...state,
        tryingToLaunchPrototype: action.data.lastSubmit.id
      }

    case 'RECEIVE_LAUNCH_PROTOTYPE_FAIL':
        return {
          ...state,
          tryingToLaunchPrototype: false,
          launchPrototypeFail: new Date().getTime()
        }

    case 'RECEIVE_LAUNCH_PROTOTYPE_SUCCESS':

      return {
        ...state,
        tryingToLaunchPrototype: false,
        launchPrototypeSuccess: new Date().getTime()
      }

    default:
      return state
  }
}
