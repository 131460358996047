const isFeatureSwitchOn = (name, usersReducer, featuresReducer) => {
  
  let feature = featuresReducer.cache[name] || {};

  if(feature.switch === "off") return false;
  if(feature.switch === "ga") return true;

  if(feature.alpha){
    if(feature.alpha.user_enabled) return true;
  }
}

export default isFeatureSwitchOn;