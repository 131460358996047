import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import CustomField from 'kit/components/CustomField/CustomField'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import Callout from 'kit/components/Callout/Callout.js'

import {
  tryToSetComponentContextOptions,
  tryToGetLLMs
} from 'actions/actions.export'

import './ComponentContextOptions.scss';

class ComponentContextOptions extends Component {
  constructor(props){
    super(props);

    this.state = {
    }
  }

  componentWillMount(){
    const { dispatch, componentReducer } = this.props;

    const component = componentReducer.cache[this.props.id];
    if(component){
      let draft = component.versions.find(v => v.id === 'draft');
      if(draft){
        this.setState({
          context_cap_method: draft.context_cap_method,
          context_truncate_threshold: draft.context_truncate_threshold,
          context_summarize_prompt: draft.context_summarize_prompt,
          context_summarize_instruction: draft.context_summarize_instruction
        })
      }
    } 
  }

  componentWillReceiveProps(newprops){
    const component = newprops.componentReducer.cache[newprops.id];
    if(component){
      if(!newprops.componentReducer.tryingToSetComponentContextOptions && this.state.submitted){
        let draft = component.versions.find(v => v.id === 'draft');
        if(draft){
          this.setState({
            context_cap_method: draft.context_cap_method,
            context_truncate_threshold: draft.context_truncate_threshold,
            context_summarize_prompt: draft.context_summarize_prompt,
            context_summarize_instruction: draft.context_summarize_instruction
          })
        }
      }
    } 
  }

  render(){

    const { dispatch, componentReducer, userReducer, orgReducer, intelReducer, canWrite } = this.props;

    const component = componentReducer.cache[this.props.id];

    if(!component) return <span>Failed to load component.</span>;

    let canSubmit = this.state.context_cap_method;

    return <div className="component-metadata">
      <div className="row">
        <div className="col-md-12 margin-top-05rem margin-bottom-2rem">
          <div className="flex-split">
            <div>
              <h4 className="no-margin ">Context Options</h4>
              <small>
                Configure how the agent should handle input prompts that are longer than its context window.
              </small>
            </div>

            <CustomButton 
              display={component.current_version === 'draft' ? "Save Changes" : "Save Changes to Draft"}
              thinking={componentReducer.tryingToSetComponentContextOptions}
              success={componentReducer.setComponentContextOptionsSuccess}
              fail={componentReducer.setComponentContextOptionsFail}
              size="small"
              disabled={!canWrite || !canSubmit}
              color="black"
              onClick={()=>{

                dispatch(tryToSetComponentContextOptions({
                  id: component.id,
                  context_cap_method: this.state.context_cap_method,
                  context_truncate_threshold: this.state.context_truncate_threshold,
                  context_summarize_prompt: this.state.context_summarize_prompt,
                  context_summarize_instruction: this.state.context_summarize_instruction
                }))

                this.setState({
                  submitted: true
                })
              }}
              />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 margin-bottom-1rem">
          <div className="box">
            {
              component.type !== 'chat' &&
              <Callout
                style="info"
                title="Context Options are only relevant for Conversational Agents"
                content={<div>
                  <div>For example, if you are using this component to power a chatbot, these settings will apply. For single data-processing, reformatting, or single-response generation tasks, these settings aren't relevant.</div>
                </div>}
                />
            }
            <CustomSelect
              value={this.state.context_cap_method}
              label="Context Window Cap Method"
              disabled={!canWrite}
              options={[
                {
                  value: 'error',
                  label: <div>
                    <div className="text-900 text-danger">Return an error</div>
                    <small className="text-500">The API and demo GUI will return an error if the context window is reached.</small>
                  </div>
                },
                {
                  value: 'truncate',
                  label: <div>
                    <div className="text-900">Truncate messages, oldest first.</div>
                    <small className="text-500">Automatically remove the oldest messages (keeping all instructions) from the conversation in order to meet context window requirements.</small>
                  </div>
                },
                {
                  value: 'summarize',
                  label: <div>
                    <div className="text-900">Summarize the conversation</div>
                    <small className="text-500">Slower, as the context window is reached - another LLM will be tasked with writing a summary to compress older messages.</small>
                  </div>
                }
              ]}
              description="How would you like to handle when a conversation exceeds the context window limit?"
              onChange={e => {
                this.setState({
                  context_cap_method: e
                })
              }}
              />

            <CustomField
              name={'context_truncate_threshold'}
              label={"Context Cap Buffer Threshold"}
              description={"How many tokens should be left available for the LLM's response?"}
              type="range"
              min={1}
              max={2096}
              required={true}
              step={1}
              placeholder={1}
              disabled={!canWrite || this.state.context_cap_method === 'error' || !this.state.context_cap_method}
              value={this.state.context_truncate_threshold}
              thinking={componentReducer.tryingToSetComponentContextOptions}
              serverError={componentReducer.setComponentContextOptionsForm.errors.context_truncate_threshold}
              lastSubmit={componentReducer.setComponentContextOptionsForm.lastSubmit.context_truncate_threshold}
              onChange={e => {
                this.setState({
                  context_truncate_threshold: e.value
                })
              }}
              />


            <CustomSelect
              value={'gpt-4-turbo'}
              label="LLM to Use for Summarizing"
              placeholder="GPT-4, PaLM2, etc"
              description="Currently, summary analysis runs on the GPT-4 Turbo model from OpenAI."
              required={true}
              serverError={componentReducer.setComponentContextOptionsForm.errors.context_summarize_model}
              lastSubmit={componentReducer.setComponentContextOptionsForm.lastSubmit.context_summarize_model}
              disabled={true}
              options={intelReducer.llms.filter(m => m.showForTypes.includes('chat')).map((model, i)=>{
                return {
                  label: <div className="thin-line-height">
                    <strong>{model.name}</strong><br/>
                    <small>by {model.provider}</small>
                  </div>,
                  value: model.name
                }
              })}
              onChange={(e) => {

                this.setState({
                  context_summarize_model: e
                })
              }}
              />      

            <CustomField
              name={'context_summarize_prompt'}
              label={"Agent Prompt"}
              placeholder="In a few sentences, summarize the current topic and user request from the following conversation so that it can be easily resumed in the future."
              description={"Customize the prompt that the agent will use to summarize the conversation."}
              required={true}
              maxLength={1000}
              rows={4}
              value={this.state.context_summarize_prompt}
              thinking={componentReducer.tryingToSetComponentContextOptions}
              serverError={componentReducer.setComponentContextOptionsForm.errors.context_summarize_prompt}
              lastSubmit={componentReducer.setComponentContextOptionsForm.lastSubmit.context_summarize_prompt}
              disabled={this.state.context_cap_method !== 'summarize' || !canWrite}
              onChange={e => {
                this.setState({
                  context_summarize_prompt: e.value
                })
              }}
              />

            <div className="list-right">
              <CustomButton
                size="small"
                display="Reset to Default"
                disabled={this.state.context_cap_method !== 'summary' || !canWrite}
                onClick={()=>{
                  this.setState({
                    context_summarize_prompt: "In a few sentences, summarize the current topic and user request from the following conversation so that it can be easily resumed in the future."
                  })
                }}
                />
            </div>

            <CustomField
              name={'context_summarize_instruction'}
              label={"Injected Summary Instruction"}
              placeholder="You are resuming a conversation in-progress, continue as if there was no interuption. Here is a brief summary of the conversation so far: ${0W_SUMMARY}"
              description={"Please include the ${0W_SUMMARY} variable in your instruction, it will be replaced with the summary."}
              required={true}
              maxLength={1000}
              rows={4}
              value={this.state.context_summarize_instruction}
              thinking={componentReducer.tryingToSetComponentContextOptions}
              serverError={componentReducer.setComponentContextOptionsForm.errors.context_summarize_instruction}
              lastSubmit={componentReducer.setComponentContextOptionsForm.lastSubmit.context_summarize_instruction}
              disabled={this.state.context_cap_method !== 'summarize' || !canWrite}
              onChange={e => {
                this.setState({
                  context_summarize_instruction: e.value
                })
              }}
              />

            <div className="list-right">
              <CustomButton
                size="small"
                display="Reset to Default"
                disabled={this.state.context_cap_method !== 'summary' || !canWrite}
                onClick={()=>{
                  this.setState({
                    context_summarize_instruction: "You are resuming a conversation in-progress, continue as if there was no interuption. Here is a brief summary of the conversation so far: ${0W_SUMMARY}"
                  })
                }}
                />
            </div>

          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="spacer-2rem"/>

          {
            componentReducer.setComponentContextOptionsForm.errors.error && <Callout style="danger" content={componentReducer.setComponentContextOptionsForm.errors.error}/>
          }

        </div>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, componentReducer, orgReducer, intelReducer } = state;

  return {
    userReducer, 
    componentReducer,
    orgReducer,
    intelReducer
  }
}

export default connect(mapStateToProps)(ComponentContextOptions);

  