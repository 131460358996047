import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux';

import Hydrate from 'components/Hydrate/Hydrate.js'
import MemberSearchSelect from 'components/MemberSearchSelect/MemberSearchSelect.js'
import CustomButton from 'kit/components/CustomButton/CustomButton.js'
import Checkbox from 'kit/components/Checkbox/Checkbox.js'
import Callout from 'kit/components/Callout/Callout.js'

import {
  tryToGetTeam,
  tryToAddTeamMember,
  tryToEditTeamMember,
  tryToRemoveTeamMember
} from 'actions/actions.export'

import './Team.scss';


import projectTools from 'configs/config.project-tools.js'
import componentTools from 'configs/config.component-tools.js'
import knowledgeTools from 'configs/config.knowledge-tools.js'
import inquiryTools from 'configs/config.inquiry-tools.js'

import isFeatureSwitchOn from 'utilities/isFeatureSwitchOn.js'

class Team extends Component {
  constructor(props){
    super(props);


    this.state = {

    }
  }

  componentWillMount(){
    const { dispatch } = this.props;

    dispatch(tryToGetTeam({
      kind: this.props.kind,
      id: this.props.id
    }))
  }

  componentWillReceiveProps(newprops){    
    const { dispatch, memberReducer } = newprops;

    let team = memberReducer.cache[newprops.kind][newprops.id];

    if(team && newprops.item){
      if(!this.state.editingUserData){
        this.setState({
          editingUserData: {
            roles: {},
            ...JSON.parse(JSON.stringify(team.find(m => m.user_id === newprops.item)))
          }
        })
      } else if(this.state.editingUserData.user_id !== newprops.item){
        this.setState({
          editingUserData: {
            roles: {},
            ...JSON.parse(JSON.stringify(team.find(m => m.user_id === newprops.item)))
          }
        })
      }
    } else if(team && !newprops.item){
      if(this.state.editingUserData){
        this.setState({
          editingUserData: undefined
        })
      }
    }

    if(memberReducer.addTeamMemberSuccess){
      if(new Date().getTime() - memberReducer.addTeamMemberSuccess < 500 && this.state.searchedMember){
        this.setState({
          searchedMember: false
        })
      }
    }
  }

  render(){

    const { dispatch, memberReducer, userReducer, featuresReducer, canWrite } = this.props;

    const regex = /\/workbench\/([^\/]+)\/?.*/;
    const scope = window.location.pathname.match(regex)[1];
    

    const team = memberReducer.cache[this.props.kind][this.props.id];

    let flattenedTools = [];

    let tools;
    switch(this.props.kind){
      case 'projects':
        tools = projectTools;
        break;
      case 'components':
        tools = componentTools;
        break;
      case 'knowledge':
        tools = knowledgeTools;
        break;
      case 'inquiries':
        tools = inquiryTools;
        break;
    }

    for(var i in tools){
      if(tools[i].feature_switch){
        if(!isFeatureSwitchOn(tools[i].feature_switch, userReducer, featuresReducer)){
          continue;
        }
      }

      if(tools[i].roleless) continue; // doesn't need a role


      if(tools[i].subpages){
        for(var j in tools[i].subpages){
          let t = tools[i].subpages[j];
          t.parent = {
            name: tools[i].name,
            display_name: tools[i].display_name,
            display_icon: tools[i].display_icon
          }
          flattenedTools.push(t);
        }
      } else {
        flattenedTools.push(tools[i]);
      }
    }


    const kindRemap = {
      projects: 'projects',
      components: 'agents',
      knowledge: 'knowledge',
      inquiries: 'inquiries'
    }
    

    if(this.state.editingUserData){

      const unsavedChanges = JSON.stringify(team.find(t => t.id === this.state.editingUserData.id)) !== JSON.stringify(this.state.editingUserData);

      return <div className="tool-team">
        <div className="flex-split margin-top-05rem margin-bottom-2rem">
          <div className="list-left">
            <div>
              <h4 className="no-margin-top no-margin-bottom">Sharing & Privileges</h4>
            </div>
            <span><i className="fal fa-angle-right"/></span>
            <Hydrate id={this.state.editingUserData.user_id} type="user" mode="lockup" size={25}/>
          </div>
          <div className="list-right">
            {/*{
              unsavedChanges && <small className="text-muted margin-right-1rem"><em>There are unsaved changes.</em></small>
            }*/}
            <CustomButton
              color="grey"
              display="Cancel"
              size="small"
              onClick={()=>{
                this.setState({
                  editingUserData: JSON.parse(JSON.stringify(team.find(t => t.id === this.state.editingUserData.id)))
                })
                dispatch(push('/workbench/' + scope + '/' + kindRemap[this.props.kind] + '/' + this.props.id + '/team'))
              }}
              />
            <CustomButton
              display="Revoke All"
              color="danger"
              disabled={!canWrite}
              size="small"
              onClick={()=>{
                let editingUserData = this.state.editingUserData;
                for(var i in flattenedTools){
                  editingUserData.roles[flattenedTools[i].name] = 'none'
                }
                this.setState({
                  editingUserData: editingUserData
                })
              }}
              />
            <CustomButton
              color="success"
              size="small"
              display="Save Changes"
              disabled={!unsavedChanges || !canWrite}
              thinking={memberReducer.tryingToEditTeamMember}
              success={memberReducer.editTeamMemberSuccess}
              fail={memberReducer.editTeamMemberFail}
              onClick={()=>{
                dispatch(tryToEditTeamMember({
                  id: this.props.id,
                  kind: this.props.kind,
                  member: this.state.editingUserData
                }))
              }}
              />
          </div>
        </div>
        <div className="box">
          <table className="table-basic">
            <thead>
              <tr>
                <th>Tool</th>
                <th>
                  Read - <span className="text-success clickable text-hover-primary" 
                    onClick={()=>{
                      let editingUserData = this.state.editingUserData;
                      for(var i in flattenedTools){
                        editingUserData.roles[flattenedTools[i].name] = 'read'
                      }
                      this.setState({
                        editingUserData: editingUserData
                      })
                    }}>
                    Grant All
                  </span>
                </th>
                <th>
                  Write - <span className="text-success clickable text-hover-primary" 
                    onClick={()=>{
                      let editingUserData = this.state.editingUserData;
                      for(var i in flattenedTools){
                        editingUserData.roles[flattenedTools[i].name] = 'write'
                      }
                      this.setState({
                        editingUserData: editingUserData
                      })
                    }}>
                    Grant All
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                flattenedTools.map((t, i)=>{
                  

                  return <tr key={i} className={t.disabled ? "text-very-muted no-pointer-events" : ""}>
                    <td className="list-left" style={{minHeight: 32}}>
                      <i className={"fal fa-lg fa-fw text-muted fa-" + t.display_icon + " margin-right-1rem"}/>
                      <div>
                        {t.parent && <div className="no-margin text-muted text-very-small text-400">{t.parent.display_name}</div>}
                        <div className="text-smaller text-700">{t.display_name}</div>
                      </div>
                    </td>
                    <td>
                      <Checkbox
                        disabled={this.state.editingUserData.admin}
                        value={t.disabled ? false : (this.state.editingUserData.roles[t.name] === 'read' || this.state.editingUserData.roles[t.name] === 'write')}
                        radio={true}
                        onToggle={(v)=>{
                          let editingUserData = this.state.editingUserData;
                          if(v){
                            editingUserData.roles[t.name] = 'read';
                          } else {
                            editingUserData.roles[t.name] = 'none';
                          }
                          this.setState({
                            editingUserData: editingUserData
                          })
                        }}
                        />
                    </td>
                    <td>
                      <Checkbox
                        disabled={this.state.editingUserData.admin}
                        value={t.disabled ? false : (this.state.editingUserData.roles[t.name] === 'write')}
                        radio={true}
                        onToggle={(v)=>{
                          let editingUserData = this.state.editingUserData;
                          if(v){
                            editingUserData.roles[t.name] = 'write';
                          } else {
                            editingUserData.roles[t.name] = 'read';
                          }
                          this.setState({
                            editingUserData: editingUserData
                          })
                        }}
                        />
                    </td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
        
      </div>
    }

    return <div className="tool-access">
      <div className="flex-split margin-top-05rem margin-bottom-2rem">
        <div className="list-left">
          <div>
            <h4 className="no-margin-top no-margin-bottom">Sharing & Privileges</h4>
            <small>
              Manage fine-grained access and permissions for this team.
            </small>
          </div>
        </div>

        <div className="list-right">
          <MemberSearchSelect 
            id={scope}
            placeholder="Search for members in your organization"
            inline={true}
            removeList={team}
            value={this.state.searchedMember}
            disabled={!canWrite}
            onChange={(member)=>{
              this.setState({
                searchedMember: member
              })
            }}
            onClear={()=>{
              this.setState({
                searchedMember: undefined
              })
            }}
            />
          <CustomButton
            display={"Add to Team"}
            disabled={!this.state.searchedMember || !canWrite}
            thinking={memberReducer.tryingToAddTeamMember}
            success={memberReducer.addTeamMemberSuccess}
            fail={memberReducer.addTeamMemberFail}
            color="black"
            size="small"
            onClick={()=>{
              dispatch(tryToAddTeamMember({
                id: this.props.id,
                kind: this.props.kind,
                user_id: this.state.searchedMember.user_id
              }))
            }}
            />
        </div>
      </div>

      {
        memberReducer.addTeamMemberForm.errors.error &&
        <Callout 
          style="danger" 
          content={memberReducer.addTeamMemberForm.errors.error}
          dismissable={true}
          />
      }
      
      <div className="box">
        <table className="table-basic">
          <thead>
            <tr>
              <th>Name</th>
              {/*<th>Admin</th>*/}
              <th>Read Privileges</th>
              <th>Write Privileges</th>
            </tr>
          </thead>
          <tbody>
            {
              team && team.map((t, i) => {

                let readCounter = 0;
                let writeCounter = 0;

                for(var j in flattenedTools){
                  if(t.roles[flattenedTools[j].name] === 'write'){
                    writeCounter++;
                    readCounter++;
                  } else if(t.roles[flattenedTools[j].name] === 'read'){
                    readCounter++;
                  }
                }

                return <tr key={i} className={canWrite ? "table-row-linked" : ""} onClick={()=>{
                  if(canWrite){
                    dispatch(push('/workbench/' + scope + '/' + kindRemap[this.props.kind] + '/' + this.props.id + '/team/' + t.user_id))
                  }
                }}>
                  <td><Hydrate id={t.user_id} type="user" mode="lockup" size={20}/></td>
                  {
                    readCounter === 0 ?
                    <td className="text-gs5"><small>None</small></td>
                    :
                    readCounter === flattenedTools.length ?
                    <td className="text-success text-900"><small>All</small></td>
                    :
                    <td><span className="text-900">{readCounter}</span><small className="text-gs5"> of {flattenedTools.length} privileges</small></td>
                  }
                  {
                    writeCounter === 0 ?
                    <td className="text-gs5"><small>None</small></td>
                    :
                    writeCounter === flattenedTools.length ?
                    <td className="text-success text-900"><small>All</small></td>
                    :
                    <td><span className="text-900">{writeCounter}</span><small className="text-gs5"> of {flattenedTools.length} privileges</small></td>
                  }
                  {/*<td>
                    {t.admin && <i className="fas fa-check-circle text-success"/>}
                  </td>*/}
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, projectReducer, featuresReducer, memberReducer } = state;

  return {
    userReducer, 
    memberReducer,
    featuresReducer
  }
}

export default connect(mapStateToProps)(Team);

  