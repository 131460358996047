import React, { Component } from 'react'

import './Checkbox.css';

class Checkbox extends Component {
  constructor(props){
    super(props);

  }

  render(){    

    let style = this.props.style ? this.props.style : "align-left";

    let checkbox = this.props.value ? 
      (
        <span className={"checkbox checkbox-true " + (this.props.disabled ? "checkbox-disabled" : "")} key={'true-check'} onClick={()=>{ this.props.onToggle(!this.props.value)}}>
          <i className={"fas fa-fw "  + ( this.props.switch ? "fa-toggle-on" : this.props.radio ? "fa-check-circle" : "fa-check-square") }></i> <span className="checkbox-text">{this.props.display}</span>
        </span>
      ) :
      (
        <span className={"checkbox checkbox-false " + (this.props.disabled ? "checkbox-disabled" : "")} key={'false-check'} onClick={()=>{ this.props.onToggle(!this.props.value)}}>
          <i className={"fa-rotate-180 fa-fw "  + ( this.props.switch ? "fas fa-toggle-on" : this.props.radio ? "far fa-circle" : "far fa-square") }></i> <span className="checkbox-text">{this.props.display}</span>
        </span>
      );

    if(this.props.description){
      if(style === "split-right"){
        return <div className="checkbox-style checkbox-style-split-right">
          <span className={"checkbox-style-description" + (this.props.switch ? " checkbox-style-description-switch" : "") + (this.props.disabled ? " checkbox-style-description-disabled" : "")}>{this.props.description}</span>
          {checkbox}
        </div>
      }

      if(style === "split-left"){
        return <div className="checkbox-style checkbox-style-split-left">
          {checkbox}
          <span className={"checkbox-style-description" + (this.props.switch ? " checkbox-style-description-switch" : "") + (this.props.disabled ? " checkbox-style-description-disabled" : "")}>{this.props.description}</span>
        </div>
      }

      if(style === "align-right"){
        return <div className="checkbox-style checkbox-style-align-right">
          <span className={"checkbox-style-description" + (this.props.switch ? " checkbox-style-description-switch" : "") + (this.props.disabled ? " checkbox-style-description-disabled" : "")}>{this.props.description}</span>
          {checkbox}
        </div>
      }

      if(style === "align-left"){
        return <div className="checkbox-style checkbox-style-align-left">
          {checkbox}
          <span className={"checkbox-style-description" + (this.props.switch ? " checkbox-style-description-switch" : "") + (this.props.disabled ? " checkbox-style-description-disabled" : "")}>{this.props.description}</span>
        </div>
      }

      if(style === "center"){
        return <div className="checkbox-style checkbox-style-center">
          {checkbox}
          <span className={"checkbox-style-description" + (this.props.switch ? " checkbox-style-description-switch" : "") + (this.props.disabled ? " checkbox-style-description-disabled" : "")}>{this.props.description}</span>
        </div>
      }
      
    } else {
      return checkbox;
    }
  }
};

export default Checkbox;