import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomButton from '../../kit/components/CustomButton/CustomButton.js';
import Notification from '../Notification/Notification.js';
import { tryToGetUserNotifications } from '../../actions/actions.export';

function UserNotifications({ page }) {
  const dispatch = useDispatch();
  const userReducer = useSelector(state => state.userReducer);

  useEffect(() => {
    dispatch(tryToGetUserNotifications());
  }, [dispatch]);

  return (
    <div>
      {
        !page &&
        <div className="row">
          <div className="col-md-12">
          <div className="margin-bottom-2rem margin-top-05rem">
            <h4 className="no-margin">Notifications</h4>
            <small>
              You have {userReducer.notifications.filter(n => !n.read).length} unread notifications.
            </small>
          </div>
            {
              userReducer.notifications.map((n, i) => {
                return <Notification notification={n} key={i} />
              })
            }
            {
              userReducer.notifications.length > 0 && (
                userReducer.notificationsEmpty ?
                <p className="text-center text-muted text-smaller">
                  You've reached the end.
                </p>
                :
                <div className="flex-column-center margin-top-2rem">
                  <CustomButton
                    display="Load More"
                    thinking={userReducer.tryingToGetUserNotifications}
                    success={userReducer.getUserNotificationsSuccess}
                    fail={userReducer.getUserNotificationsFail}
                    onClick={() => {
                      dispatch(tryToGetUserNotifications({
                        startAfter: userReducer.notifications[userReducer.notifications.length - 1].id
                      }))
                    }}
                  />
                </div>
              )
            }
          </div>
        </div>
      }
      {
        page === "organization-invites" && <div>
          <div className="margin-bottom-2rem margin-top-05rem">
            <h4 className="no-margin">Organization Invites</h4>
            <small>
              If you're invited to join an organization, you'll see it here.
            </small>
          </div>
          <div className="row">
            <div className="col-md-12">
              {
                userReducer.notifications.filter(n => ["organization_invite"].indexOf(n.type) > - 1).map((n, i) => {
                  return <Notification notification={n} key={i} />
                })
              }
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default UserNotifications;
