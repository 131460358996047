// src/components/FlowBuilder/FlowNode.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  showTooltip,
  hideTooltip
} from 'actions/actions.export';

import nodeCategories from 'configs/config.node-categories';

import {
  nodeHeaderHeight, 
  nodePaddingPerPort,
  nodeWidth,
  calculateNodeHeight,
  customRenderDictionary,
  formatLinkId
} from './FlowBuilderUtils';

import './Flow.scss';

const FlowNode = React.memo(({ 
  component_id,
  node,
  links = [],
  selected,
  onSelectNode,
  onMouseDown,
  onMouseUp,
  onDragNodeStart,
  onDragNodeEnd,
  onNodeChange,
  onLinkStart,
  onLinkEnd,
  onPlay,
  inputDrafting,
  outputDrafting,
  interactive = true,
  highlight = false, 
  simplified = false,
  acceptableInputs = [],
  acceptableOutputs = [],
  }) => {
  if(!node) return null;

  const { x, y } = node;
  const [isDragging, setIsDragging] = React.useState(false);

  const dispatch = useDispatch();
  const intelReducer = useSelector(state => state.intelReducer);
  const componentReducer = useSelector(state => state.componentReducer);
  const tooltip = useSelector(state => state.tooltip);

  const defaultNodeHeight = 50;

  const nodeHeight = calculateNodeHeight(node, intelReducer.flow_nodes) || defaultNodeHeight;
  
  const nodeType = (intelReducer.flow_nodes || []).find(n => n.name === node.type) || {};
  
  const category = nodeCategories.find(c => c.name === nodeType.category) || {};

  let missingRequiredSettings = false;

  if(nodeType.settings){
    nodeType.settings.forEach(setting => {
      if(!node.settings){
        missingRequiredSettings = true; 
        return;
      }
      if(setting.required && node.settings[setting.name] === undefined){
        missingRequiredSettings = true;
      }
    });
  }


  let ready = false;
  let currentlyCached = false;
  let filteredToLinks = links.filter(l => l.to.node_id === node.id);
  let filteredFromLinks = links.filter(l => l.from.node_id === node.id);

  let hasAllRequiredInputs = false;
  if(nodeType.inputs?.length === 0){
    hasAllRequiredInputs = true;
    ready = true;
  } else {
    hasAllRequiredInputs = nodeType.inputs.filter(i => i.required).every(i => {
      return filteredToLinks.find(l => l.to.input === i.name) !== undefined;
    });

    if(componentReducer.linkCache[component_id] && hasAllRequiredInputs){
      // check if each filteredToLinks has a value in the linkCache
      ready = filteredToLinks.every(l => {
        return componentReducer.linkCache[component_id][formatLinkId(l)] !== undefined;
      });
    }
  } 

  if(componentReducer.nodeOutputCache[component_id]){
    currentlyCached = filteredFromLinks.every(l => {
      return componentReducer.nodeOutputCache[component_id][l.from.node_id + ':' + l.from.output] !== undefined;
    });
  }

  return (<g 
    transform={`translate(${x}, ${y})`} 
    className={"flow-node " + 
      (isDragging ? " flow-node__dragging" : "") + 
      (selected ? " flow-node__selected" : "") +
      (interactive == false ? " no-pointer-events" : "") + 
      (highlight ? " flow-node__highlight" : "")
    }
    onDoubleClick={e => {
      if(!interactive || simplified) return;

      e.stopPropagation();
      if(onSelectNode) onSelectNode(node.id);
    }}
    onMouseDown={e => {
      if(onMouseDown) onMouseDown(e, node.id);
    }}
    onMouseUp={onMouseUp ? onMouseUp : null}
    >
      
      {/* If theres a missing setting error, lets put a triangle centered above the node in a foreign object */}
      {
        (missingRequiredSettings && interactive) && <foreignObject x={0} y={-30} width={nodeWidth} height={30}>
          <div className="flow-node-missing-settings-error">
            <div>
              <i className="fas fa-exclamation-triangle icon-before-text"></i>Missing Required Settings
            </div>
          </div>
        </foreignObject>
      }


      {/* background */}
      <rect x={0} y={0} width={nodeWidth} height={nodeHeight} className="flow-node-background" />
      
      {/* header as foreign object */}
      <foreignObject 
        x={0} 
        y={0} 
        width={nodeWidth} 
        height={nodeHeaderHeight} 
        className={`flow-node-label flow-node-label__${nodeType.category}` + 
        (interactive == false ? " flow-node-label__no-interaction" : "")}
        onMouseDown={e => {
          // this needs to pass a dragging state to the parent component
          if(onDragNodeStart) onDragNodeStart(e, node.id);
          if(tooltip.show){
            dispatch(hideTooltip());
          }
          setIsDragging(true);
        }}
        onMouseUp={e => {
          e.stopPropagation();
          if(onDragNodeEnd) onDragNodeEnd(e, node.id);
          setIsDragging(false);
        }}
        onMouseEnter={e =>{
          if(isDragging) return
          dispatch(showTooltip({
            el: e.target,
            nobr: false,
            position: 'top',
            lag: 1000,
            content: <div style={{minWidth: 250, maxWidth: 250}} className="">
              <h5 className="no-margin">{nodeType.display_name}</h5>
              <p className="thin-line-height text-400 no-margin-bottom margin-top-05rem">
                <small>
                  {nodeType.description}
                </small>
              </p>
            </div>
          }))
        }}
        onMouseLeave={e => {
          dispatch(hideTooltip());
        }}
        >
          {
            !simplified && 
            <div className="flex-split">
              <div className="text-ellipsis-1-lines">
                <span>
                  <i className={"flow-node-label-icon fal fa-fw fa-" + category.icon}></i> <span className="flow-node-label-text">{nodeType.display_name || ""}</span>
                </span>
              </div>
              {
                interactive &&
                
                <div className="list-right list-right-no-wrap">
                  {
                    (!nodeType.is_static && ready) && 
                    <div className={"flow-node-label-button-icon "}  onClick={e => {
                      if(onPlay) onPlay(node.id);
                    }}>
                      {currentlyCached ? <i className="far fa-sync"/> : <i className="far fa-play"/>}
                    </div>
                  }
                  <div className={"flow-node-label-button-icon " + (missingRequiredSettings ? "flow-node-label-button-icon__error" : "")} 
                    onMouseDown={e => {
                      e.stopPropagation();
                    }} 
                    onMouseUp={e => {
                      e.stopPropagation();
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      onSelectNode(node.id);
                    }}>
                      <i className="far fa-cog"></i>
                  </div>
                </div>
              }
            </div>
          }
      </foreignObject>


      {/* custom renderer */}
      {
        (nodeType && customRenderDictionary[nodeType.name]) && 
        <foreignObject x={0} y={nodeHeaderHeight} width={nodeWidth} height={nodeType.custom_render_height}>
          <div className="flow-node-custom-render">
            {
              customRenderDictionary[nodeType.name]({
                node, 
                type: nodeType, 
                interactive: true, 
                component_id: component_id,
                from_links: filteredFromLinks,
                to_links: filteredToLinks,
                setNodeSettings: (id, settings) => {

                // pass this change up to the parent component
                if(onNodeChange){
                  onNodeChange({
                    node_id: id, 
                    changes: {
                      settings: {
                        ...node.settings,
                        ...settings
                      }
                    }});
                }

              }})
            }
          </div>
        </foreignObject>
      }

      {/* border rect on top */}
      <rect x={0} y={0} width={nodeWidth} height={nodeHeight} className={"flow-node-border " + ((missingRequiredSettings && interactive) ? " flow-node-border__error" : "") }/>

      {/* circle input ports with text labels */}
      {
        (nodeType.inputs && !simplified) && nodeType.inputs.map((input, i) => {
          let y = nodeHeaderHeight + nodePaddingPerPort + i * nodePaddingPerPort;

          if(nodeType.custom_render_height){
            y += nodeType.custom_render_height;
          }

          let requiredAndMissing = false;
          let connected = filteredToLinks.find(l => l.to.input === input.name);

          if(!input.optional && interactive){            
            if(connected === undefined){
              requiredAndMissing = true;
            }
          }


          let isAcceptable = false;
          let isUnaccceptable = false;

          if(acceptableInputs.find(a => a.input.name === input.name)){
            isAcceptable = true;
          } else if(acceptableInputs.length > 0 || acceptableOutputs.length > 0){
            isUnaccceptable = true;
          }

          let typeSplit = input.type.split(' or ');

          return <g transform={`translate(0, ${y})`} key={i} 
            onMouseUp={e => {
              if(onLinkEnd) onLinkEnd(e, node.id, input.name, 'inputs');
            }}>
            <circle 
              className={"flow-node-port " + 
                (inputDrafting == input.name ? "flow-node-port__drafting" : "") + 
                (connected ? " flow-node-port__connected" : "") + 
                (isAcceptable ? " flow-node-port__acceptable" : "") +
                (isUnaccceptable ? " flow-node-port__unacceptable" : "")
              }
              onMouseEnter={e => {
                dispatch(showTooltip({
                    el: e.target,
                    nobr: false,
                    position: 'left',
                    lag: 1000,
                    content: <div style={{minWidth: 250, maxWidth: 250}} className="">
                      <div className="flex-split">
                        <h5 className="no-margin">
                          {input.display_name} {(input.allow_multiple && !input.destructive) && "+*"} {(input.allow_multiple && input.destructive) && "+"}
                        </h5>
                      </div>
                      <div className="list-left margin-top-1rem margin-bottom-1rem">
                        {
                          typeSplit.map((type, ti) => {
                            return <span className="text-tag text-tag-tiny " key={ti}>
                              {type}
                            </span>
                          })
                        }
                      </div>
                      <p className="thin-line-height text-400 margin-top-05rem">
                        <small>
                          {input.description}
                        </small>
                      </p>
                      {
                        input.default !== undefined && <p className="no-margin-bottom">
                          <small>Default Value: </small> 
                          <small className="text-400">
                            {JSON.stringify(input.default)}
                          </small>
                        </p>
                      }
                      {
                        input.allow_multiple && <hr className="hr-mini"/>
                      }

                      {       
                        (input.allow_multiple && !input.destructive) && <p className="thin-line-height no-margin">
                          <small className="text-400">
                            The <strong>+*</strong> means multiple connections can be made, but this node will wait for all of them to be ready before processing them together.
                          </small>
                        </p>
                      }
                      {
                        input.destructive && <p className="thin-line-height no-margin">
                          <small className="text-400">
                            The <strong>+</strong> means multiple connections can be made, but this node will process each connection individually as data comes in.
                          </small>
                        </p>
                      }
                    </div>
                }))
              }}
              onMouseLeave={e => {
                dispatch(hideTooltip())
              }}
              onMouseDown={e => {
                if(onLinkStart) onLinkStart(e, node.id, input.name, 'inputs');
              }}
              />
            <text x={12} y={4} className={"flow-node-port-label " + (requiredAndMissing ? "flow-node-port-label-required-error" : "")}>
              {input.display_name} {(input.allow_multiple && !input.destructive) && "+*"} {(input.allow_multiple && input.destructive) && "+"}
              </text>
          </g>
        })
      }

      {/* circle output ports with text labels */}
      {
        (nodeType.outputs && !simplified) && nodeType.outputs.map((output, i) => {

          let y = nodeHeaderHeight + nodePaddingPerPort + i * nodePaddingPerPort;
          if(nodeType.custom_render_height){
            y += nodeType.custom_render_height;
          }

          let connected = filteredFromLinks.find(l => l.from.output === output.name);

          let isAcceptable = false;
          let isUnaccceptable = false;

          if(acceptableOutputs.find(a => a.input.name === input.name)){
            isAcceptable = true;
          } else if(acceptableInputs.length > 0 || acceptableOutputs.length > 0){
            isUnaccceptable = true;
          }

          let typeSplit = output.type.split(' or ');

          return <g transform={`translate(${nodeWidth}, ${y})`} key={i} 
            onMouseUp={e => {
              if(onLinkEnd) onLinkEnd(e, node.id, output.name, 'outputs');
            }}>
            <circle 
              className={"flow-node-port " + 
                (outputDrafting == output.name ? "flow-node-port__drafting" : "") +
                (connected ? " flow-node-port__connected" : "") +
                (isAcceptable ? " flow-node-port__acceptable" : "") +
                (isUnaccceptable ? " flow-node-port__unacceptable" : "")
              } 
              onMouseEnter={e => {
                dispatch(showTooltip({
                    el: e.target,
                    nobr: false,
                    position: 'right',
                    lag: 1000,
                    content: <div style={{minWidth: 250, maxWidth: 250}} className="">
                      <div className="flex-split">
                        <h5 className="no-margin">
                          {output.display_name}
                        </h5>
                      </div>
                      <div className="list-left margin-top-1rem margin-bottom-1rem">
                        {
                          typeSplit.map((type, ti) => {
                            return <span className="text-tag text-tag-tiny " key={ti}>
                              {type}
                            </span>
                          })
                        }
                      </div>
                      <p className="thin-line-height text-400 margin-top-05rem no-margin-bottom">
                        <small>
                          {output.description}
                        </small>
                      </p>
                      {
                        output.default !== undefined && <p>
                          <small>Default Value:</small> 
                          <small className="text-400">
                            {output.default}
                          </small>
                        </p>
                      }
                    </div>
                }))
              }}
              onMouseLeave={e => {
                dispatch(hideTooltip())
              }}
              onMouseDown={e => {
                if(onLinkStart) onLinkStart(e, node.id, output.name, 'outputs');
              }}
              />
            <text x={-12} y={4} className="flow-node-port-label" textAnchor="end">{output.display_name}</text>
          </g>
        })
      }
    </g>
  );
});

export default FlowNode;
