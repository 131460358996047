import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect.js'
import Checkbox from '../../kit/components/Checkbox/Checkbox.js'
import Callout from '../../kit/components/Callout/Callout.js'

import {
  tryToEditUserProfile
} from '../../actions/actions.export'

class EditUserProfileForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      display_name: props.userReducer.myData.display_name,
      bio: props.userReducer.myData.bio || "",
      location: props.userReducer.myData.location || "",
      company: props.userReducer.myData.company || "",
      url: props.userReducer.myData.url || ""
    }


    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToEditUserProfile({
      display_name: this.state.display_name,
      bio: this.state.bio ? this.state.bio : undefined,
      location: this.state.location ? this.state.location : undefined,
      company: this.state.company ? this.state.company : undefined,
      url: this.state.url ? this.state.url : undefined
    }))
  }

  render(){

    const { userReducer } = this.props;

    let canSubmit = this.state.display_name && !this.state.display_name_error && !this.state.bio_error && !this.state.location_error && !this.state.company_error && !this.state.url_error;

    return <div>
      <div className="margin-bottom-2rem margin-top-05rem">
        <h4 className="no-margin">Name & Info</h4>
        <small>
          Manage the information about you that others see.
        </small>
      </div>
      <div className="box">
        <form onSubmit={()=>{
          if(canSubmit) this.handleSubmit()
        }}>
          <h5 className="no-margin-top">
            Edit your profile
          </h5>
          <CustomField
            name="display_name"
            label="Display Name"
            placeholder="ex: Jane Smith"
            description="What name do you want to go by?"
            maxLength={64}
            inline={true}
            required={true}
            disabled={userReducer.tryingToEditUserProfile}
            value={this.state.display_name}
            serverError={userReducer.editUserProfileForm.errors.display_name}
            lastSubmit={userReducer.editUserProfileForm.lastSubmit.display_name}
            onChange={(e) => {
              this.setState({
                display_name: e.value,
                display_name_error: e.error
              })
            }}
            />


          <CustomField
            name="bio"
            label="Bio"
            placeholder="ex: A post-modern industrialist and loose leaf tea aficionado."
            description="This can be work related, or not."
            maxLength={256}
            rows={3}
            disabled={userReducer.tryingToEditUserProfile}
            value={this.state.bio}
            serverError={userReducer.editUserProfileForm.errors.bio}
            lastSubmit={userReducer.editUserProfileForm.lastSubmit.bio}
            onChange={(e) => {
              this.setState({
                bio: e.value,
                bio_error: e.error
              })
            }}
            />


  {/* 
          <CustomField
            name="location"
            label="Location"
            placeholder="ex: Humboldt Park, Los Angeles, Minnesota, US, etc"
            description="Be as granular or general as you'd like."
            maxLength={64}
            disabled={userReducer.tryingToEditUserProfile}
            value={this.state.location}
            serverError={userReducer.editUserProfileForm.errors.location}
            lastSubmit={userReducer.editUserProfileForm.lastSubmit.location}
            onChange={(e) => {
              this.setState({
                location: e.value,
                location_error: e.error
              })
            }}
            />


          <CustomField
            name="url"
            label="Website"
            placeholder="ex: https://example.com"
            description="Must start with http:// or https://"
            regex={"url"}
            disabled={userReducer.tryingToEditUserProfile}
            value={this.state.url}
            serverError={userReducer.editUserProfileForm.errors.url}
            lastSubmit={userReducer.editUserProfileForm.lastSubmit.url}
            onChange={(e) => {
              this.setState({
                url: e.value,
                url_error: e.error
              })
            }}
            />



          <CustomField
            name="company"
            label="Company"
            placeholder="ex: Smith Consulting, NewCo Insurance, etc"
            description="Where do you work?"
            maxLength={64}
            disabled={userReducer.tryingToEditUserProfile}
            value={this.state.company}
            serverError={userReducer.editUserProfileForm.errors.company}
            lastSubmit={userReducer.editUserProfileForm.lastSubmit.company}
            onChange={(e) => {
              this.setState({
                company: e.value,
                company_error: e.error
              })
            }}
            /> */}


          <div className="spacer-2rem"/>

          {
            userReducer.editUserProfileForm.errors.error &&
            <Callout
              style="danger"
              dismissable={true}
              content={userReducer.editUserProfileForm.errors.error}
            />
          }
          
          <div className="list-right">
            <CustomButton
              display="Save Changes"
              color="success"
              size="small"
              thinking={userReducer.tryingToEditUserProfile}
              fail={userReducer.editUserProfileFail}
              success={userReducer.editUserProfileSuccess}
              disabled={!canSubmit}
              onClick={()=>{
                if(canSubmit) this.handleSubmit()
              }}
              />
          </div>
        </form>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(EditUserProfileForm);
