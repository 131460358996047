import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import tooltip from '../kit/reducers/reducers.tooltip.js';
import toast from '../kit/reducers/reducers.toast.js';

import { userReducer } from './reducers.user.js';
import { guiReducer } from './reducers.gui.js';
import { featuresReducer } from './reducers.features.js';
import { orgReducer } from './reducers.organizations.js';
import { projectReducer } from './reducers.projects.js';
import { knowledgeReducer } from './reducers.knowledge.js';
import { componentReducer } from './reducers.components.js';
import { inquiryReducer } from './reducers.inquiries.js';
import { intelReducer } from './reducers.intel.js';
import { postReducer } from './reducers.post.js';
import { memberReducer } from './reducers.members.js';
import { sharedReducer } from './reducers.shared.js';
import { instructionReducer } from './reducers.instructions.js';
import { prototypeReducer } from './reducers.prototypes.js';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  tooltip,
  toast,
  userReducer,
  guiReducer,
  featuresReducer,
  orgReducer,
  projectReducer,
  knowledgeReducer,
  componentReducer,
  inquiryReducer,
  intelReducer,
  postReducer,
  memberReducer,
  sharedReducer,
  instructionReducer,
  prototypeReducer
  // 
})
export default rootReducer