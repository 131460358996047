import React, { Component } from 'react'
import { connect } from 'react-redux'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import SetPasswordForm from '../components/SetPasswordForm/SetPasswordForm.js'

class SetPasswordRoute extends Component {
  componentDidMount(){
    window.document.title = "zerowidth.ai - Set Password";
  }

  render(){
    return <div className="hf-stretched">
      <Header/>
      <div className="body">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <h1>Set Your Password</h1>  
              <SetPasswordForm invite_code={this.props.invite_code}/>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  }
}

const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(SetPasswordRoute);
