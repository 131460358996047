import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Install knowledge on a project
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestInstallKnowledge = packet => {
  return {
    type: 'REQUEST_INSTALL_KNOWLEDGE',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveInstallKnowledgeSuccess = data => ({
  type: 'RECEIVE_INSTALL_KNOWLEDGE_SUCCESS',
  data
})

export const receiveInstallKnowledgeFail = data => ({
  type: 'RECEIVE_INSTALL_KNOWLEDGE_FAIL',
  data
})

export const tryToInstallKnowledge = packet => (dispatch, getState) => {
  const projectReducer = getState().projectReducer;
  const project = projectReducer.cache[packet.id];

  dispatch(requestInstallKnowledge(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = Cookies.get(process.env.REACT_APP_COOKIE_FEATURE_SWITCH)
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/projects/install-knowledge/' + packet.id, {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveInstallKnowledgeSuccess(json));
      dispatch(push('/workbench/' + project.scope + '/projects/' + project.id + '/knowledge/' + json.data.knowledge_id));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveInstallKnowledgeFail({errors:e, lastSubmit: packet}));
    })
}
