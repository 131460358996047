module.exports = {
  name: 'policies',
  display_name: 'Policies & Legal',
  children: [
    {
      name: 'terms-of-service',
      display_name: 'Terms of Service',
      content: `
This section lays out the terms and conditions that guide the use of services and features provided by ZeroWidth, LLC (referred to as ZeroWidth). By accessing or using our services, you agree to abide by these terms.


## Acceptance of Terms
Welcome to [zerowidth.ai](https://zerowidth.ai). The following terms and conditions (the "Terms") govern all use of the ZeroWidth website and all content, services, and products available at or through the website. The website is owned and operated by ZeroWidth, LLC ("ZeroWidth").

By accessing or using any part of the website, you agree to become bound by these Terms. If you do not agree to all the Terms, then you may not access or use ZeroWidth's services.

Please read these Terms carefully before accessing or using our website. If you are under the age of 13, you may not use the website unless your parent or guardian has provided us with their consent for you to use the website.

ZeroWidth's services are not directed to children younger than 13, and access and use of our services is only offered to users 13 years of age or older. If you are under 13 years old, please do not register to use our services. Any person who registers as a user or provides their personal information to our services represents that they are 13 years of age or older.


### Entire Agreement
These Terms, together with any other documents or policies referenced herein, represent the entire agreement between you and ZeroWidth, LLC concerning your use of the ZeroWidth website and services. These Terms supersede any prior or contemporaneous agreements, communications, representations, or discussions, whether oral or written, between you and ZeroWidth regarding the subject matter contained in these Terms.

No modification to these Terms, nor any waiver of any rights, shall be effective except by an instrument in writing signed by the duly authorized representatives of ZeroWidth, and the waiver of any breach or default will not constitute a waiver of any other right hereunder or any subsequent breach or default.


### Modifications to Terms
ZeroWidth, LLC ("ZeroWidth") reserves the right, at its sole discretion, to modify or replace any part of these Terms. It is your responsibility to check these Terms periodically for changes. Your continued use of or access to the website following the posting of any changes to these Terms constitutes acceptance of those changes.

ZeroWidth may also, in the future, offer new services and/or features through the website ([zerowidth.ai](https://zerowidth.ai)). Such new features and/or services shall be subject to the terms and conditions of these Terms.

We recommend reviewing our Terms of Service regularly to stay informed about any modifications. If you disagree with the changes to these Terms, you should stop using ZeroWidth's services immediately.

In case of significant changes, ZeroWidth may seek to inform users by providing a notice of changes through email or on the [zerowidth.ai](https://zerowidth.ai) platform. What constitutes a significant change will be determined at ZeroWidth's sole discretion.


## Use of Site
The [zerowidth.ai](https://zerowidth.ai) website and all associated services provided by ZeroWidth, LLC ("ZeroWidth") are available for both personal and commercial use. Users are expected to utilize the services in a manner that is respectful, legal, and in line with these Terms.

Users are responsible for any activity that occurs under their account and are required to maintain the security of their account credentials. Sharing of account credentials, especially with malicious intent, is strictly prohibited and may result in permanent suspension.

All users must adhere to the laws of their respective jurisdictions when using the website. You may not use our services for any illegal or unauthorized purpose. You agree to comply with all local, state, national, and international laws, rules, and regulations.

Any unauthorized use of the website, or any content or data contained therein, is expressly prohibited and may result in termination of your access rights, at the sole discretion of ZeroWidth.

ZeroWidth reserves the right to modify, suspend, or discontinue any feature, service, or content offered on the website at any time without notice.


### Intellectual Property Rights
All content, features, and functionality on the ZeroWidth website, including but not limited to text, graphics, logos, icons, images, audio clips, video clips, data compilations, and software, are the property of ZeroWidth, LLC or its licensors and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.

Users are granted a limited, non-exclusive, non-transferable, non-sublicensable license to access and use the ZeroWidth website and services for their personal or internal business use, subject to these Terms. Any use of the website or services not expressly permitted by these Terms is a breach of these Terms and may also violate copyright, trademark, and other laws.

ZeroWidth, the ZeroWidth logo, and all related names, logos, product and service names, designs, and slogans are trademarks of ZeroWidth, LLC or its affiliates or licensors. You may not use such marks without the prior written permission of ZeroWidth, LLC. All other names, logos, product and service names, designs, and slogans on this website are the trademarks of their respective owners.

Unless otherwise indicated, you may not reproduce, modify, distribute, publicly display or perform, or prepare derivative works based upon any portion of the ZeroWidth website, services, or any content therein without express written consent from ZeroWidth or the appropriate third-party content provider.


### Content Guidelines
Users of [zerowidth.ai](https://zerowidth.ai) and associated services provided by ZeroWidth, LLC ("ZeroWidth") are expected to adhere to the following content guidelines. These guidelines ensure that the platform remains a constructive and respectful environment for all users.

- Content that promotes hate, discrimination, violence, or harm towards any individual or group is prohibited.
- Uploading or sharing content that you do not have the rights to is not allowed.
- Spamming, phishing, or any malicious activities are strictly prohibited.
- Content that violates any local, state, national, or international laws or regulations is not permitted.

Additionally, some features of ZeroWidth may leverage third-party tools and APIs. By using these features, you agree to also abide by the content policies of these third-party tools. Failure to adhere to the guidelines of third-party tools may result in limitations or suspension of your access to ZeroWidth services.

ZeroWidth is not responsible for the content policies of third-party tools. We recommend users to familiarize themselves with these policies. A list of our Data Subprocessors and their respective policies can be found on [our website](https://www.zerowidth.ai/docs/policies/data-subprocessors).

We reserve the right to remove or restrict access to content that violates these guidelines, and repeated violations may lead to suspension or termination of your account.


### Privacy & Data Protection
ZeroWidth is committed to safeguarding the privacy and data of our users. We operate in compliance with global data protection regulations, including the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).

For detailed information on how we collect, use, and protect your data, as well as your rights regarding your data, please refer to our [Privacy Policy](https://www.zerowidth.ai/docs/policies/privacy).


### Termination of Service
ZeroWidth reserves the right to suspend or terminate your access to its services at any time, with or without cause, and with or without notice. Examples of activities that may result in such termination include, but are not limited to, a breach of these Terms or any activity that ZeroWidth believes may harm its reputation or expose it to undue risk of liability.

If ZeroWidth decides to terminate your access to the service, ZeroWidth will make reasonable efforts to notify you through the contact information you have provided. You acknowledge that if your access to the service is suspended or terminated, you may no longer have access to the data that is stored with the service.

Upon termination, all licenses and other rights granted to you by these Terms will immediately cease. ZeroWidth is not obligated to refund any fees or other amounts paid by you in connection with the service on account of any termination or suspension.


## Disclaimers
ZeroWidth's services, including all content, software, functions, materials, and information made available on, provided in connection with, or accessible through the services, are provided "as is." To the fullest extent permissible by law, ZeroWidth and its affiliates make no representation or warranties of any kind whatsoever for the content or the materials, information, and functions made accessible. Further, ZeroWidth and its affiliates disclaim any express or implied warranties, including, without limitation, non-infringement, merchantability, or fitness for a particular purpose. ZeroWidth and its affiliates shall not be liable for the use of the services, including, without limitation, the content and any errors contained therein.

ZeroWidth does not warrant that the functions contained in the services or any content, features, or services contained therein will be uninterrupted or error-free, that defects will be corrected, or that the server that makes them available will always be secure or free from bugs, errors, or other limitations.

ZeroWidth does not warrant or make any representations regarding the use or the results of the use of the services in terms of their correctness, accuracy, reliability, or otherwise.

You acknowledge that ZeroWidth is not responsible for any third-party tools or services that you may use while interacting with our platform, and any data processed or accessed using those third-party tools or services is subject to their respective terms and conditions.


### Limitation of Liability
To the fullest extent permitted by applicable law, in no event will ZeroWidth, its affiliates, directors, employees, or licensors be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including, without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the services; (ii) any unauthorized access to or use of our servers and/or any personal information or other data stored therein; (iii) any interruption or cessation of transmission to or from the services; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services by any third party; or (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the services.

The limitations of this section apply whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if ZeroWidth has been advised of the possibility of such damage. Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitations or exclusions may not apply to you.

Notwithstanding any provisions in these Terms, ZeroWidth's total liability to you for all damages, losses, and causes of action, whether in contract, tort, or otherwise, shall not exceed the amount you have paid ZeroWidth for accessing or using its services in the last twelve months.


### Waiver and Severability
If ZeroWidth fails to enforce any right or provision of these Terms, it does not constitute a waiver of such right or provision. Just because we may not enforce or insist upon strict adherence to any provision in a particular instance doesn’t mean that we have waived our right to later enforce such a provision.

In the event that any provision of these Terms is held to be invalid or unenforceable by a court or competent jurisdiction, the remaining provisions of these Terms will remain in full force and effect. The unenforceable provision will be modified to reflect the parties' intention and only to the extent necessary to make it enforceable, and if no modification can render it enforceable, it will be severed from these Terms.


### Governing Law
These Terms, the rights and remedies provided hereunder, and any and all claims and disputes related hereto and/or to the services, their interpretation, or the breach, termination or validity thereof, the relationships which result from or pursuant to these Terms, or any related transaction or purchase, shall be governed by, construed under, and enforced in all respects solely and exclusively in accordance with the internal substantive laws of the United States, without respect to its conflict of laws principles.

Any and all such claims and disputes shall be brought in, and you hereby consent to them being litigated in and decided exclusively by a court of competent jurisdiction located in the State of Illinois. The application of the United Nations Convention of Contracts for the International Sale of Goods is hereby expressly excluded.


## Feedback and Complaints
ZeroWidth values feedback from our users. We welcome you to share any feedback, comments, or suggestions you may have regarding our services. While we may not be able to respond to everyone, please know that we carefully consider all feedback provided to us.

Should you have any complaints about our services or wish to report any issues, please contact our support team immediately. We are dedicated to addressing concerns promptly and fairly. You can reach us via our contact page or through the dedicated support channels provided on our website.

By submitting feedback, you grant ZeroWidth an unrestricted, irrevocable, non-exclusive, royalty-free license to use, reproduce, display, perform, modify, transmit, distribute, and create derivative works from such feedback, and you waive all rights to claim for any fees or royalties related to ZeroWidth's use of such feedback.

License
Copyright © ZeroWidth, LLC All rights reserved
This document may be reused under a Creative Commons Attribution-ShareAlike License.`
    },
    {
      name: 'privacy',
      display_name: 'Privacy Policy',
      content: `
At ZeroWidth, we value and respect your privacy. This Privacy Policy outlines our commitment to safeguarding the personal information you entrust to us when you use our tools and services. Our primary objective is to be transparent about the data we collect, why we collect it, and how we use it, all while ensuring it remains secure and confidential.

We encourage you to take a moment to understand our privacy practices. If you have any questions or concerns, we’re always here to address them. Our aim is to cultivate trust and ensure you feel confident in our handling of your data.

By using ZeroWidth's suite of tools and services, you agree to the terms set forth in this Privacy Policy and our overarching Terms of Service.


## How does ZeroWidth collect data about me?

ZeroWidth collects data about you:
- when you browse the website, zerowidth.ai
- when you use the website to create an account, update your account, and sign up for ZeroWidth services
- when you send support, privacy, legal, and other requests to ZeroWidth

ZeroWidth may inadvertently collect data about you if:
- it is included in the uploaded & configured content within ZeroWidth's tools
- it is included in data that is processed via ZeroWidth's API
- you use an application that depends on ZeroWidth's services or leverages ZeroWidth to process data

## What data is collected about me?
#### ZeroWidth collects data about how you use the website.

When you visit www.zerowidth.ai, and other ZeroWidth websites, ZeroWidth uses cookies, server logs, and other methods to collect data about what pages you visit, and when. ZeroWidth also collects technical information about the software and computer you use, such as but not limited to:
- your IP address
- your preferred language
- the web browser software you use
- the kind of computer you use
- the website that referred you

ZeroWidth uses data about how you use the website to:
- optimize the website
- diagnose and debug technical errors
- defend the website from abuse and technical attacks
- compile statistics on the kinds of software and computers visitors use
- compile statistics on visitor searches and needs, to guide development of new website pages and functionality

#### ZeroWidth collects account data.

Many features of ZeroWidth services require an ZeroWidth account. Creating a ZeroWidth account, requires a working email address. ZeroWidth uses this data to provide you access to features and identify you across ZeroWidth services, publicly and within ZeroWidth.

ZeroWidth uses your email to:
- notify you about actions related to your account
- reset your password and help keep your account secure
- contact you in special circumstances related to your account or packages
- contact you about support requests
- contact you about legal requests, like DMCA takedown requests and privacy complaints
- announce new product offerings, service changes, and features
- send you tips about how to better use free and paid services
- send you messages about paid services you might want

You can choose to upload a profile picture, subject to ZeroWidth's Terms of Service. Both this profile picture and your chosen name may be visible publicly when sharing public links to demos you have created on ZeroWidth.

#### ZeroWidth collects agent data, uploaded knowledge data, and other data processed through ZeroWidth's services.
When you utilize ZeroWidth's tools and services, especially for building and deploying AI solutions, you will often configure or set up organizations, agents, projects, knowledge bases, and endpoints and more. The data related to these, as well as any other data processed through our tools, is stored and managed by ZeroWidth to ensure the seamless operation of your configured applications and services.

- Organization Data: This includes team members, configurations, settings, metadata, analytics, and other specifics about organizations you create and manage on ZeroWidth.
- Agent Data: Specific configurations, relationships, and training data related to individual agents within an organization.
- Knowledge Base Data: This includes uploaded raw and chunked knowledge content, configurations, settings, metadata, analytics, and other specifics about knowledge bases you create and manage on ZeroWidth.
- Projects Data: This includes configurations, settings, metadata, analytics, and other specifics about projects you create and manage on ZeroWidth.
- Endpoint Data: This includes configurations, settings, metadata, analytics, and other specifics about endpoints you create and manage on ZeroWidth.
- Processed Data: Any data sent to ZeroWidth, be it for processing, analyzing, or any other purpose, including data sent through the API or the user interface. This can be raw data, processed data, or any other type of data you use with our tools.

While you retain ownership of your data and it's protected under our stringent data usage and privacy policies, it's essential to note that this data is necessary for ZeroWidth to provide the services and tools you use. We maintain this data with the utmost care and only use it to facilitate your chosen configurations and operations.

For more details about how we handle data, refer to our Data Usage Policy.

## Why does ZeroWidth collect my data?

ZeroWidth collects your data for various purposes, all with the intent of improving your experience on our platform.
- to register, maintain, and manage your account, and to offer personalized services and support.
- to understand user needs and preferences, which allows us to refine and expand our offerings.
- to send you important notifications, updates, and promotional offers based on your preferences.
- to monitor, troubleshoot, and optimize our platform's performance.
- to protect against fraud, unauthorized access, and to maintain the safety of our user community.
- to comply with relevant legal, regulatory, and policy requirements.
- to process and manage data you send to us via our API or interface, ensuring the proper functioning of the tools and services you've configured.

We only collect data when necessary, with a firm commitment to transparency and respect for your privacy.


## How is my data used?

#### Improving ZeroWidth Services
At ZeroWidth, our primary goal is to continually enhance the user experience. The data we collect offers invaluable insights into how our users interact with our platform. By understanding these interactions, we can identify areas that might benefit from refinement, introduce innovative features tailored to user needs, and optimize existing functionalities to ensure that they align seamlessly with the evolving demands of our clientele.

#### Service Maintenance & Support
Communication is pivotal in the digital age, especially when it pertains to services you rely on. Your email address, in this regard, serves as a vital communication bridge. Through it, we keep you abreast of pivotal service updates, relay crucial account-related notifications, and ensure that you receive timely and efficient support whenever you encounter challenges or have queries about our offerings.

#### Security Measures
In an era where digital threats are ever-evolving, maintaining a robust security posture is imperative. At ZeroWidth, we diligently analyze data patterns to unearth and preemptively counteract unauthorized or potentially malicious activities. This rigorous approach not only safeguards your account and other associated data but also guarantees the overall integrity and reliability of our platform.

#### Legal & Regulatory Obligations
Upholding the rule of law is a responsibility we at ZeroWidth take very seriously. The data we collect may, on occasion, be utilized to ensure that we are in full compliance with legal or regulatory stipulations. Additionally, it assists us in responding effectively to lawful requests, court mandates, or any associated legal proceedings, ensuring that we act with both transparency and integrity.

#### Facilitating Configured Applications
ZeroWidth's functionality is, in many ways, sculpted by the needs and configurations of our users. When you dispatch data through our API or interface for processing, we utilize that data exclusively to actualize and streamline the operations of the tools and applications you've meticulously configured. This meticulous approach underscores our commitment to ensuring that ZeroWidth's services dovetail perfectly with your unique requirements.

#### Data Retention & Moderation
In our quest to offer a platform that resonates with the highest standards of safety and reliability, we sometimes deem it necessary for a select group of ZeroWidth personnel to inspect data. Such inspections are undertaken with the objective of ensuring that our platform isn't being misused for malicious intents or in manners that contradict our terms of service. This meticulous approach reinforces our commitment to fostering a user environment characterized by trust and mutual respect.


## Who does ZeroWidth share my data with?

#### Trusted Partners
ZeroWidth has a stringent policy when it comes to sharing your data. We only share specific pieces of user data with trusted partners who play an integral role in ensuring the seamless functionality of our services. These partners are bound by strict data processing agreements and are committed to upholding the highest standards of data protection and privacy.

#### Subprocessors
To ensure transparency, we maintain a detailed list of our subprocessors — entities that might process user data on our behalf. For a comprehensive list of our subprocessors and their respective roles, kindly visit our [Data Subprocessors](https://www.zerowidth.ai/docs/policies/data-subprocessors) page.

#### Legal Obligations
On rare occasions, we may be compelled to share specific data due to legal obligations. Such instances could include responding to lawful requests from regulatory bodies, court orders, or other associated legal proceedings. However, in all such cases, our priority remains the safeguarding of our users' rights and interests.

#### Protecting ZeroWidth's Interests
There might be situations where we believe sharing data is necessary to protect ZeroWidth's rights, interests, and property. This includes, but is not limited to, cases involving potential fraud, threats to security, or technical vulnerabilities. In such scenarios, our primary objective is to ensure the continued security, reliability, and integrity of our services.


## Data Retention Policy
At ZeroWidth, we are committed to protecting your data and ensuring that we only hold onto it for as long as necessary. The retention periods are established in consideration of the nature of the data, its utility for ongoing business processes, and legal and regulatory obligations.

#### Website Usage Data
Data about how you use ZeroWidth websites, such as visited pages, and technical information, is retained for up to 12 months. This enables us to optimize our platform, ensure security, and better understand user behavior.

#### Account Data
Your account information, including email address and any optional profile details, is retained as long as your account remains active. If you decide to close or deactivate your ZeroWidth account, we will anonymize or delete your account data within 90 days, unless there are legitimate business or legal reasons to retain it.

#### Data Processed through ZeroWidth's Services
Data that you send to us for processing through our tools or API, is retained for up to 30 days after which it is automatically purged. However, aggregated or anonymized data, which cannot identify any individual user, might be retained longer for analytics and product improvement purposes.

#### Data Sharing with Third Parties
Data shared with third-party services, as outlined in our [Data Subprocessor](https://www.zerowidth.ai/docs/policies/data-subprocessors) list, is subjected to the retention policies of those respective services.

#### Adjustments to Retention Periods
In specific cases, legal or regulatory requirements might necessitate that we retain certain data for a longer duration. If any adjustments are made to our data retention policies, we will provide updated information on this platform.


## Your Rights Regarding Your Data
At ZeroWidth, we recognize and respect the importance of your personal data and the rights you hold in relation to it. Depending on where you reside, you have certain rights concerning your personal information, as provided by data protection laws, including the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA). Here's a summary of your key rights:

#### Access:
You have the right to request access to your personal data we process. This includes details on how and why it's processed, who it's shared with, and how long we'll retain it.

#### Rectification:
If you believe that the personal data we hold about you is inaccurate or incomplete, you have the right to request its correction.

#### Erasure ("Right to be Forgotten"):
In certain circumstances, you have the right to request the deletion of your personal data. However, this isn't an absolute right and may be subject to our own legal obligations.

#### Restriction of Processing:
You have the right to request a restriction on the processing of your personal data. This allows you to ask us to suspend the processing of the data, for example, if you want us to verify its accuracy or the reason for processing it.

#### Data Portability:
In certain circumstances, you have the right to receive the personal data we've gathered about you in a structured, commonly-used, and machine-readable format, and have the right to transmit that data to another entity.

#### Object to Processing:
You have the right to object to the processing of your personal data for direct marketing purposes or when our processing is based on legitimate interests.

#### Automated Decision Making and Profiling:
You have the right to not be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or similarly significantly affects you.

If you have any queries or wish to exercise any of these rights, please contact us directly. To learn more about how your data is processed and stored, or to find further details on these rights, please refer to our Data Subprocessors page.


## Cookies
ZeroWidth makes use of cookies to enhance user experience, remember user preferences, and track website performance. A cookie is a small piece of data stored on your device by your web browser while browsing websites.

Cookies can be classified into several types based on their duration and purpose. We utilize both session and persistent cookies. Session cookies are temporary and will be removed once you close your browser, while persistent cookies remain on your device for a set duration or until you manually clear them.
- Types of Cookies We Use:
- Essential Cookies: These are required for the proper functioning of our website. They include, for example, cookies that enable you to log into secure areas.
- Performance and Analytics Cookies: These help us analyze how our website is used, detect any technical issues, and improve user experience.
- Functionality Cookies: These are used to recognize you when you return to our website, helping us to personalize content for you and remember your preferences.

You can manage or block cookies using your browser settings. However, if you disable essential cookies, some parts of our website may not function as intended. For more detailed information about cookies and how they work, you can visit websites like [All About Cookies](https://www.allaboutcookies.org).

We may also make use of third-party cookies, such as those provided by analytics services. These cookies help us understand user behavior and improve our services. For more details about the third-party cookies we use, you can refer to our [Data Subprocessors](https://www.zerowidth.ai/docs/policies/data-subprocessors) list.


## Children's Privacy
ZeroWidth takes children's privacy seriously. Our services are designed for businesses and adults. They are not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children under the age of 13 or knowingly allow such persons to register for an online account or to post personal information on our websites.

If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will take steps to remove that information. If you believe we might have any information from or about a child under 13, please contact us immediately.

We recommend that minors 13 years of age or older ask their parents for permission before sending any information about themselves to anyone over the internet and we encourage parents to teach their children about safe internet use practices.

Parents can review, edit, request the deletion of, or prevent further collection or use of their child's personal information by contacting us. To respect the privacy of parents, information collected and used for the sole purpose of obtaining verifiable parental consent or providing notice to parents is not maintained in retrievable form by the site or service if parental consent is not obtained after a reasonable time.


## Data Transfers Across Borders
ZeroWidth operates primarily in the United States but has users and partners around the world. As such, we and our service providers may transfer your personal data to, or access it in, jurisdictions (including the United States) that may not provide equivalent levels of data protection as your home jurisdiction. We will take steps to ensure that your personal information receives an appropriate level of protection in the jurisdictions in which we process it.

If you have any questions or concerns about the international transfer of your personal data, or would like more information on our data protection efforts, please feel free to contact us.


## Third Party Links
ZeroWidth's services, including our website and tools, may contain links to third-party websites or services that are not owned or controlled by ZeroWidth. These links are provided as a convenience and do not signify an endorsement or affiliation.

We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services. By using our services, you acknowledge and agree that ZeroWidth is not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such third-party websites or services.

We strongly advise you to review the privacy policy and terms of service of every website you visit or service you use. If you decide to access any of the third-party websites linked from our services, you do so entirely at your own risk.


## How to Contact
If you have any questions, concerns, or complaints about our privacy policies, practices, or the way your data is being handled, or if you'd like to exercise any of the rights outlined in this policy, please contact us at:

ZeroWidth, Inc.
Email: contact@zerowidth.ai
If you have concerns specifically regarding how your data is being handled or concerns related to data protection, you can reach out to our Data Protection Officer at:

Data Protection Officer
ZeroWidth, Inc.
Email: dpo@zerowidth.ai
We strive to address all inquiries promptly and will work with you to address any concerns you might have.
`
    },
    {
      name: 'data-usage-policy',
      display_name: 'Data Usage Policy',
      content: `
Welcome to ZeroWidth's Data Usage Policy. Here, we explain how we handle, process, and protect the data you entrust to us.

## Our Commitment
ZeroWidth provides tools designed to empower businesses with AI-driven solutions. We understand the significance and sensitivity of the data you use within our tools, especially when configuring AI models. As such, we're committed to the highest standards of data integrity, ownership, and protection.

## Data Ownership
Any data, including proprietary and intellectual property, that you upload to our servers for configuring training data remains unequivocally owned by you or the business entity that uploaded it. ZeroWidth respects your ownership rights and does not claim any rights to such data.

## Limited Use of Your Data
While you retain ownership of your data, we assure you that we will not use this data to retrain any models or for any other business purposes. The sole exception is a limited moderation process, conducted by a limited number of ZeroWidth employees, to prevent any misuse or abuse of our services.

Additionally, as applications built on ZeroWidth might require data to be processed through our platform and potentially onto third-party platforms, such as OpenAI or Google, we assure you that this data is solely used to facilitate the specific application you have configured. ZeroWidth does not repurpose this data for any other use.

## Data Storage and Security
All the data you upload or process through ZeroWidth is stored within the United States. We prioritize the security of your data. As testament to that, our site and API are exclusively available over SSL, ensuring that any data transferred is encrypted and secured.

## Data Retention and Purging
For the sake of transparency, we'd like to inform you that while incoming data from applications can be inspected for moderation and to ensure no abuse occurs, we maintain detailed records of this live data for a period of only 30 days. Post this period, the data is automatically purged, ensuring your data does not linger on our servers beyond what's necessary.

## Your Trust is Our Priority
We're dedicated to maintaining your trust. If you have any questions or concerns about our data handling practices, please don't hesitate to contact us. We're always here to assist and ensure you feel confident about using ZeroWidth's suite of tools.      
`
    },
    {
      name: 'data-subprocessors',
      display_name: 'Data Subprocessors',
      content: `

ZeroWidth uses certain subprocessors to provide its services. This page provides information about the subprocessors we use and their respective roles.
      
| Subprocessor Name | Purpose                                                        | Location       |
|-------------------|----------------------------------------------------------------|----------------|
| Google            | Data Processing, AI services, Cloud infrastructure for hosting & storage | United States |
| Redis             | Content delivery and caching                                   | United States |
| Pinecone          | Data Storage                                                   | United States |
| GitHub            | Code repository & development collaboration                    | United States |
| Mailjet           | Sending automated emails to users                              | European Union |
| OpenAI            | Data processing & AI services                                  | United States |
| Anthropic         | Data processing & AI services                                  | United States |
| Replicate         | Data processing & AI services                                  | United States |
| Algolia           | Search functionality                                           | United States |
| Geoapify          | Geolocation services                                           | European Union |


Check back regularly for updates to our subprocessors list.
`
    }
  ]
}