import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import CustomField from 'kit/components/CustomField/CustomField';

function Datetime({ options = {}, onChange, enabled, org_id, canWrite}) {
  const [selectedOption, setSelectedOption] = useState('');
  const [test, setTest] = useState('');

  // Access the Redux dispatch function
  const dispatch = useDispatch();
  const knowledgeReducer = useSelector((state) => state.knowledgeReducer);

  useEffect(() => {
    console.log('loaded');
  },[]);


  return (
    <div>
      <p>
        Once enabled, this plugin lets you easily inject the current date and time into an instruction using the following pattern:
      </p>
      <pre>
        {'${'}DATETIME="YYYY-MM-DD HH:mm:ss{'"}'}
      </pre>
      <p>
        This entire string will be replaced with the current date and time in the format you've specified in quotation marks and can be used in any instruction field as many times as you'd like.
      </p>
      <hr/>
      <p>
        Here is an example of how you might use this in an instruction:
      </p>
      <blockquote>
        You are a helpful Christmas shopping planning assistant, the current date is <strong>{'${'}DATETIME="YYYY-MM-DD{'"}'}</strong> and it is currently <strong>{'${'}DATETIME="h{'"}'}</strong> o'clock in the <strong>{'${'}DATETIME="a{'"}'}</strong>.
      </blockquote>
      <p>
        This would be replaced with the following completed instruction, which would enable the agent to tailor its recommendations based on typical online order shipping times and store hours:
      </p>
      <blockquote>
        You are a helpful Christmas shopping planning assistant, the current date is <strong>{moment().format('YYYY-MM-DD')}</strong> and it is currently <strong>{moment().format('h')}</strong> o'clock in the <strong>{moment().format('a')}</strong>.
      </blockquote>
      <hr/>
      <p>
        Use this field to test the plugin's functionality:
      </p>
      <div className="flex-split">
        <div className="margin-right-1rem flex-50">
          <CustomField
            label={"${DATETIME=" + '"..."' + "}"}
            placeholder="YYYY-MM-DD HH:mm:ss"
            value={test}
            onChange={(e)=>{
              setTest(e.value)
            }}
            />
        </div>
        <div className="margin-left-1rem flex-50">
          <CustomField
            label="Result"
            value={moment().format(test)}
            disabled={true}
            />
        </div>
      </div>
      <p>
        The available date and time format options are:
      </p>

      <table className="table-basic">
        <thead>
          <tr>
            <th className="text-left">Token</th>
            <th className="text-left">Output</th>
            <th className="text-left">Example</th>
          </tr>
        </thead>
        <tbody>
          
          <tr>
            <td>M</td>
            <td>1 2 ... 11 12</td>
            <td>1</td>
          </tr>
          <tr>
            <td>Mo</td>
            <td>1st 2nd ... 11th 12th</td>
            <td>1st</td>
          </tr>
          <tr>
            <td>MM</td>
            <td>01 02 ... 11 12</td>
            <td>01</td>
          </tr>
          <tr>
            <td>MMM</td>
            <td>Jan Feb ... Nov Dec</td>
            <td>Jan</td>
          </tr>
          <tr>
            <td>MMMM</td>
            <td>January February ... November December</td>
            <td>January</td>
          </tr>
          <tr>
            <td>Q</td>
            <td>1 2 3 4</td>
            <td>1</td>
          </tr>
          <tr>
            <td>Qo</td>
            <td>1st 2nd 3rd 4th</td>
            <td>1st</td>
          </tr>
          <tr>
            <td>D</td>
            <td>1 2 ... 30 31</td>
            <td>1</td>
          </tr>
          <tr>
            <td>Do</td>
            <td>1st 2nd ... 30th 31st</td>
            <td>1st</td>
          </tr>
          <tr>
            <td>DD</td>
            <td>01 02 ... 30 31</td>
            <td>01</td>
          </tr>
          <tr>
            <td>DDD</td>
            <td>1 2 ... 364 365</td>
            <td>1</td>
          </tr>
          <tr>
            <td>DDDo</td>
            <td>1st 2nd ... 364th 365th</td>
            <td>1st</td>
          </tr>
          <tr>
            <td>DDDD</td>
            <td>001 002 ... 364 365</td>
            <td>001</td>
          </tr>
          <tr>
            <td>d</td>
            <td>0 1 ... 5 6</td>
            <td>0 (Sunday)</td>
          </tr>
          <tr>
            <td>do</td>
            <td>0th 1st ... 5th 6th</td>
            <td>0th</td>
          </tr>
          <tr>
            <td>dd</td>
            <td>Su Mo ... Fr Sa</td>
            <td>Su</td>
          </tr>
          <tr>
            <td>ddd</td>
            <td>Sun Mon ... Fri Sat</td>
            <td>Sun</td>
          </tr>
          <tr>
            <td>dddd</td>
            <td>Sunday Monday ... Friday Saturday</td>
            <td>Sunday</td>
          </tr>
          <tr>
            <td>e</td>
            <td>0 1 ... 5 6</td>
            <td>0 (Sunday, Locale)</td>
          </tr>
          <tr>
            <td>E</td>
            <td>1 2 ... 6 7</td>
            <td>1 (Monday, ISO)</td>
          </tr>
          <tr>
            <td>w</td>
            <td>1 2 ... 52 53</td>
            <td>1</td>
          </tr>
          <tr>
            <td>wo</td>
            <td>1st 2nd ... 52nd 53rd</td>
            <td>1st</td>
          </tr>
          <tr>
            <td>ww</td>
            <td>01 02 ... 52 53</td>
            <td>01</td>
          </tr>
          <tr>
            <td>W</td>
            <td>1 2 ... 52 53</td>
            <td>1 (ISO)</td>
          </tr>
          <tr>
            <td>Wo</td>
            <td>1st 2nd ... 52nd 53rd</td>
            <td>1st (ISO)</td>
          </tr>
          <tr>
            <td>WW</td>
            <td>01 02 ... 52 53</td>
            <td>01 (ISO)</td>
          </tr>
          <tr>
            <td>YY</td>
            <td>70 71 ... 29 30</td>
            <td>70</td>
          </tr>
          <tr>
            <td>YYYY</td>
            <td>1970 1971 ... 2029 2030</td>
            <td>1970</td>
          </tr>
          <tr>
            <td>YYYYYY</td>
            <td>-001970 -001971 ... +001907 +001971</td>
            <td>+001970</td>
          </tr>
          <tr>
            <td>Y</td>
            <td>1970 1971 ... 9999 +10000 +10001</td>
            <td>1970</td>
          </tr>
          <tr>
            <td>y</td>
            <td>1 2 ... 2020 ...</td>
            <td>2020</td>
          </tr>
          <tr>
            <td>N, NN, NNN, NNNN, NNNNN</td>
            <td>BC AD</td>
            <td>AD</td>
          </tr>
          <tr>
            <td>gg</td>
            <td>70 71 ... 29 30</td>
            <td>70</td>
          </tr>
          <tr>
            <td>gggg</td>
            <td>1970 1971 ... 2029 2030</td>
            <td>1970</td>
          </tr>
          <tr>
            <td>GG</td>
            <td>70 71 ... 29 30</td>
            <td>70</td>
          </tr>
          <tr>
            <td>GGGG</td>
            <td>1970 1971 ... 2029 2030</td>
            <td>1970</td>
          </tr>
          <tr>
            <td>A</td>
            <td>AM PM</td>
            <td>AM</td>
          </tr>
          <tr>
            <td>a</td>
            <td>am pm</td>
            <td>am</td>
          </tr>
          <tr>
            <td>H</td>
            <td>0 1 ... 22 23</td>
            <td>0</td>
          </tr>
          <tr>
            <td>HH</td>
            <td>00 01 ... 22 23</td>
            <td>00</td>
          </tr>
          <tr>
            <td>h</td>
            <td>1 2 ... 11 12</td>
            <td>1</td>
          </tr>
          <tr>
            <td>hh</td>
            <td>01 02 ... 11 12</td>
            <td>01</td>
          </tr>
          <tr>
            <td>k</td>
            <td>1 2 ... 23 24</td>
            <td>1</td>
          </tr>
          <tr>
            <td>kk</td>
            <td>01 02 ... 23 24</td>
            <td>01</td>
          </tr>
          <tr>
            <td>m</td>
            <td>0 1 ... 58 59</td>
            <td>0</td>
          </tr>
          <tr>
            <td>mm</td>
            <td>00 01 ... 58 59</td>
            <td>00</td>
          </tr>
          <tr>
            <td>s</td>
            <td>0 1 ... 58 59</td>
            <td>0</td>
          </tr>
          <tr>
            <td>ss</td>
            <td>00 01 ... 58 59</td>
            <td>00</td>
          </tr>
          <tr>
            <td>S</td>
            <td>0 1 ... 8 9</td>
            <td>0</td>
          </tr>
          <tr>
            <td>SS</td>
            <td>00 01 ... 98 99</td>
            <td>00</td>
          </tr>
          <tr>
            <td>SSS</td>
            <td>000 001 ... 998 999</td>
            <td>000</td>
          </tr>
          <tr>
            <td>SSSS ... SSSSSSSSS</td>
            <td>000[0..] 001[0..] ... 998[0..] 999[0..]</td>
            <td>0000</td>
          </tr>
          <tr>
            <td>Z</td>
            <td>-07:00 -06:00 ... +06:00 +07:00</td>
            <td>-07:00</td>
          </tr>
          <tr>
            <td>ZZ</td>
            <td>-0700 -0600 ... +0600 +0700</td>
            <td>-0700</td>
          </tr>
          <tr>
            <td>X</td>
            <td>1360013296</td>
            <td>1360013296</td>
          </tr>
          <tr>
            <td>x</td>
            <td>1360013296123</td>
            <td>1360013296123</td>
          </tr>
        </tbody>
      </table>
      
    </div>

  );
}

export default Datetime;
