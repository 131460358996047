import React, { Component } from 'react'
import { connect } from 'react-redux'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import RegisterForm from '../components/RegisterForm/RegisterForm.js'
import Callout from '../kit/components/Callout/Callout.js'
import ASCIIBackground from '../components/ASCIIBackground/ASCIIBackground.js'


import isFeatureSwitchOn from '../utilities/isFeatureSwitchOn'


class RegisterRoute extends Component {
  componentDidMount(){
    window.document.title = "zerowidth.ai - Sign Up";
  }


  render(){
    const { userReducer, featuresReducer } = this.props;

    return <div className="hf-stretched">
      <div className="fixed-big-image">
        <ASCIIBackground 
          imagePath={'/home-img/sign-up.png'} 
          foreground={'#C0C7D3'} 
          background={'#fff'} 
          autoTween={1000} 
          fontSize={16}
          placementMode={'contain-bottom'}
          />
      </div>
      <Header borderless={true}/>
      <div className="body">
        <div className="container">
          <div className="row justify-content-center">
            {
              isFeatureSwitchOn('registration', userReducer, featuresReducer) ?
              <div className="col-md-4">
                <div className="spacer-3rem"/>
                <div className="">
                  <h1>Sign Up</h1>
                  <RegisterForm/>
                </div>
              </div>
              :
              <div className="col-md-4">
                <div className="spacer-3rem"/>
                <Callout
                  title="New account sign up is currently disabled"
                  content="Please try again later."
                />
              </div>
            }
          </div>
        </div>
      </div>
      <Footer simple={true}/>
    </div>
  }
}

const mapStateToProps = (state) => {
  const { userReducer, featuresReducer } = state;

  return {
    userReducer, featuresReducer
  }
}

export default connect(mapStateToProps)(RegisterRoute);
