import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import Callout from '../../kit/components/Callout/Callout.js'

import {
  tryToGetUserLog
} from '../../actions/actions.export'

class UserPasswordHistory extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
    const { dispatch, userReducer } = this.props;

    dispatch(tryToGetUserLog({
      type: 'password'
    }))
  }

  render(){

    const { dispatch, userReducer } = this.props;


    return <div className="box">

        <div className="flex-split margin-bottom-2rem">
          <h5 className="no-margin-top no-margin-bottom">
            Password History
          </h5>
          <div className="list-right">
            {
              (userReducer.tryingToGetUserLog === "password") ?
              <i className="far fa-spinner-third fa-spin text-muted fa-fw"/>
              :
               <i className="far fa-sync-alt clickable icon-muted-until-hover fa-fw" onClick={()=>{ 
                dispatch(tryToGetUserLog({type: 'password'}))
              }}/>
            }
          </div>
        </div>

        {
          (userReducer.log.password) &&
          <div>
            {
              userReducer.log.password.map((e, i)=>{
                return <div className="subtle-section" key={i}>
                  <div className="text-700 margin-bottom-05rem">
                    <i className="far fa-key text-semi-muted icon-before-text"/>{e.event}
                  </div>
                  <div className="list-left margin-bottom-05rem no-margin-top text-smaller">
                    <span>{moment(e.timestamp).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
                    <span className="text-very-muted">|</span>
                    <span className="text-muted">{moment(e.timestamp).fromNow()}</span>
                  </div>
                  <p className="no-margin-top no-margin-bottom text-smaller">
                    IP Address: <span className="text-700">{e.req_ip}</span>
                  </p>
                </div>
              })
            }
          </div>
        }


        {
          (userReducer.log.password && !userReducer.logEmpty.password) && 
          <div className="flex-column-center">
            <CustomButton
              display="Load More"
              thinking={userReducer.tryingToGetUserLog === "password"}
              success={userReducer.getUserLogSuccess}
              fail={userReducer.getUserlogFail}
              onClick={()=>{
                dispatch(tryToGetUserLog({
                  type: 'password',
                  startAfter: userReducer.log.password[userReducer.log.password.length - 1].id
                }))
              }}
              />
          </div>
        }
        {
          userReducer.logEmpty.password &&
          <p className="text-center text-muted text-smaller">
            You've reached the end of the log.
          </p>
        }

    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(UserPasswordHistory);
