import React, { Component } from 'react'
import { connect } from 'react-redux'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import ForgotForm from '../components/ForgotForm/ForgotForm.js'
import ASCIIBackground from '../components/ASCIIBackground/ASCIIBackground.js'

class LoginRoute extends Component {
  componentDidMount(){
    window.document.title = "zerowidth.ai - Forgot Password";
  }

  render(){
    return <div className="hf-stretched">
      <div className="fixed-big-image">
        <ASCIIBackground 
          imagePath={'/home-img/forgot-password.png'} 
          foreground={'#C0C7D3'} 
          background={'#fff'} 
          autoTween={1000} 
          fontSize={16}
          placementMode={'contain-bottom'}
          />
      </div>
      <Header borderless={true}/>
      <div className="body">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="spacer-3rem"/><div className="spacer-3rem"/>
              <h1>Forgot Password</h1>
              <ForgotForm/>
            </div>
          </div>
        </div>
      </div>
      <Footer simple={true}/>
    </div>
  }
}

const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(LoginRoute);
