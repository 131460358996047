import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Get inquiries by organization
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestGetInquiriesByOrganization = packet => {
  return {
    type: 'REQUEST_GET_INQUIRIES_BY_ORGANIZATION',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveGetInquiriesByOrganizationSuccess = data => ({
  type: 'RECEIVE_GET_INQUIRIES_BY_ORGANIZATION_SUCCESS',
  data
})

export const receiveGetInquiriesByOrganizationFail = data => ({
  type: 'RECEIVE_GET_INQUIRIES_BY_ORGANIZATION_FAIL',
  data
})

export const tryToGetInquiriesByOrganization = packet => (dispatch, getState) => {

  dispatch(requestGetInquiriesByOrganization(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/inquiries/org/' + packet.org_id, {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveGetInquiriesByOrganizationSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveGetInquiriesByOrganizationFail({errors:e, lastSubmit: packet}));
    })
}
