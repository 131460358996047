import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import {
  tryToGetComponentVersion,
  toggleContentPane
} from 'actions/actions.export'

import Hydrate from 'components/Hydrate/Hydrate.js'
import ComponentVersionBar from 'components/ComponentVersionBar/ComponentVersionBar.js'
import WorkbenchRecentComponentDynamic from 'components/Workbench/WorkbenchRecentComponentDynamic.js'

import CustomButton from 'kit/components/CustomButton/CustomButton.js'

import tools from 'configs/config.component-tools'

import './ComponentVersion.scss';


class ComponentVersion extends Component {
  constructor(props){
    super(props);


    // this.state = {
    //   version: this.props.subpage
    // }
  }

  // componentWillMount(){
  //   const { dispatch, componentReducer } = this.props;

  //   dispatch(toggleContentPane(true));

  //   if(this.props.subpage){

  //     let now = new Date().getTime();
  //     let loadedVersion = componentReducer.cache[this.props.id].versions.find(v => v.id === this.props.subpage);
  //     // only refire this if we havent pulled the full data yet or if we are grabbing draft and its been at least a minute
  //     if(!loadedVersion || !loadedVersion.lastGetSuccess || (now - loadedVersion.lastGetSuccess > 60*1000 && loadedVersion.id === 'draft')){
  //       dispatch(tryToGetComponentVersion({
  //         id: this.props.id,
  //         version: this.props.subpage 
  //       }))
  //     }
  //   }
  // }

  // componentWillReceiveProps(newprops){
  //   const { dispatch, componentReducer } = newprops;

  //   if(newprops.subpage !== this.state.version){
  //     this.setState({
  //       version: newprops.subpage
  //     });

  //     if(newprops.subpage){
  //       let now = new Date().getTime();
  //       let loadedVersion = componentReducer.cache[newprops.id].versions.find(v => v.id === newprops.subpage);
  //       // only refire this if we havent pulled the full data yet or if we are grabbing draft and its been at least a minute
  //       if(!loadedVersion || !loadedVersion.lastGetSuccess || (now - loadedVersion.lastGetSuccess > 60*1000 && loadedVersion.id === 'draft')){
  //         dispatch(tryToGetComponentVersion({
  //           id: newprops.id,
  //           version: newprops.subpage 
  //         }))
  //       }
  //     }
  //   }
  // }

  render(){

    const { dispatch, orgReducer, componentReducer, userReducer } = this.props;

    const component = componentReducer.cache[this.props.id];
    if(!component) return <span>component failed to load.</span>;

    let currentVersion = component.versions.find(v => v.id === this.props.item);

    return <div className="row">
      <div className="col-xl-12 margin-top-05rem">
        <div className="flex-split">
          <div>
            <h4 className="no-margin">
              Versions
            </h4>
            <small>
              Agents are versioned to enable you to make changes and improvements over time.
            </small>
          </div>   
        </div>
        <hr/>
      </div>
      <div className="col-md-3">
        {
          component.versions.filter(v => v.id !== 'draft').map((version, i) => {
            return <Link 
              to={"/workbench/" + component.scope + "/agents/" + component.id + "/versions/" + version.id} 
              className={"box flex-split box-near-no-pad margin-bottom-05rem link-no-decoration text-400 " + (currentVersion && currentVersion.id === version.id ? "box-primary" : "") } key={i}>
              <div>
                {version.id}<br/>
                <small className="text-muted">
                  {moment(version.created_at).fromNow()}
                </small>
              </div>
              <Hydrate type="user" mode="avatar" id={version.created_by} size={30}/>
            </Link>
          })
        }
        {
          component.versions.filter(v => v.id !== 'draft').length == 0 &&
          <p className="text-muted">You haven't published yet.</p>
        }
      </div>

      {
        currentVersion && 
        
        <div className="col-md-9">
          <div className="margin-top-05rem flex-split">
            <h3 className="no-margin">{currentVersion.id}</h3>
            <CustomButton
              display={<span>Launch Demo GUI<small className="far fa-external-link icon-after-text"/></span>}
              color="black"
              size="small"
              toBlank={"/c/demo/" + component.id + "/" + currentVersion.id}
              />
          </div>
          <p>{currentVersion.description}</p>
          {
            tools.filter(t => t.dynamicComponent).map((t, i) => {
              console.log(t);
              return <WorkbenchRecentComponentDynamic
                component={component}
                showAll={true}
                showDetails={true}
                currentVersion={currentVersion}
                tool={t}
                />
            })
          }
        </div>
      }
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, componentReducer, orgReducer } = state;

  return {
    userReducer, 
    componentReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(ComponentVersion);

  