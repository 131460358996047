import React, { Component } from 'react'
import shortid from 'shortid'
import { connect } from 'react-redux'

import { showTooltip, hideTooltip } from '../../../actions/actions.export.js'

import './CustomField.css';

class CustomField extends Component {
  constructor(props){
    super(props);

    this.state = {
      id: props.id || shortid.generate(),
      blurCounter: 0,
      focusCounter: 0
    }
    
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.getError = this.getError.bind(this);
  }

  handleChange(e){
    if(this.props.onChange){
      let error = this.getError(e.target.value);
      
      this.props.onChange({
        value: e.target.value,
        error: error || false
      });
    }
    
    this.autoResizeRows();
  }

  autoResizeRows(){
    if(this.props.rows && this.props.autoResizeRows){
      var el = document.getElementById('field_' + this.props.name + "_" + this.state.id);
      if(el){
        el.style.cssText = 'height:auto;';
        let height = (2 + el.scrollHeight - 21) + 'px';
        el.style.cssText = 'height:' + height + ';';
      }
    }
  }

  handleFocus(){
    this.setState({
      focusCounter: this.state.focusCounter + 1,
      hasFocus: true
    })

    if(this.props.onFocus) this.props.onFocus();

    setTimeout(()=>{
      let error = this.getError();
      this.renderTooltip(error);  
    })
  }

  handleBlur(){
    const { dispatch } = this.props;

    this.setState({
      blurCounter: this.state.blurCounter + 1,
      hasFocus: false
    });

    if(this.props.onBlur) this.props.onBlur();

    dispatch(hideTooltip());
  }

  handleKeyDown(){

  }

  renderTooltip(error){
    const { dispatch } = this.props;

    if(error && this.state.hasFocus){
      dispatch(showTooltip({
        el: document.getElementById('field_' + this.props.name + '_' + this.state.id),
        nobr: false,
        position: this.props.tooltipDirection || 'right',
        content: <div style={{maxWidth: 150}} className="text-danger text-center">
          {error}
        </div>
      }))
    } else {
      dispatch(hideTooltip());
    }
  }


  componentDidUpdate(){
    const { dispatch } = this.props;

    let error = this.getError();

    if(this.state.error !== error){

      if(this.state.focusCounter > 0){
        this.renderTooltip(error);

        this.setState({
          error: error
        })

        if(this.props.onError){
          this.props.onError({
            value: this.props.value,
            error: error
          });
        }
      }
    }

    this.autoResizeRows();
  }

  getError(v){

    let value = v || this.props.value || "";

    if(this.state.blurCounter > 0){
      if(this.props.required){
        if(value === undefined || value === ""){
          return "Required.";
        }
      }
    }
    
    if(this.props.serverError && this.props.lastSubmit === value){
      return this.props.serverError;
    }

    if(this.props.clientError && this.props.clientError(value)){
      return this.props.clientError(value);
    }

    if(this.props.maxLength){
      if(value.length > this.props.maxLength){
        return "Cannot be longer than " + this.props.maxLength + " characters.";
      }
    }

    if(this.props.minLength){
      if(value.length < this.props.minLength && value.length > 0){
        return "Must be at least " + this.props.minLength + " characters.";
      }
    }

    if(this.props.max){
      if(value > this.props.max){
        return "Cannot be larger than " + this.props.max + ".";
      }
    }

    if(this.props.min){
      if(value < this.props.min){
        return "Must be at least " + this.props.min + ".";
      }
    }


    if(this.props.regex){ // && this.state.blurCounter > 0
      if(value.length > 0 && this.state.focusCounter > 0){          
        var r;
        var err = ''
        let delimiter = false;
        switch(this.props.regex){
          case 'version':
            r = /^\d{1,2}\.\d{1,2}\.\d{1,2}$/
            err = 'Needs to be X.Y.Z format.'
            break;
          case 'alphanumeric':
            r = /^[a-zA-Z0-9]+$/
            err = 'Alphanumeric characters only.'
            break;
          case 'alphanumeric-hyphen':
            r = /^[a-zA-Z0-9-]+$/
            err = 'Alphanumeric characters and hyphens only.'
            break;
          case 'lowercase-alphanumeric-hyphen':
            r = /^[a-z0-9-]+$/
            err = 'Lowercase alphanumeric characters and hyphens only.'
            break;
          case 'lowercase-alphanumeric-hyphen-underscore':
            r = /^[a-z0-9-_]+$/
            err = 'Lowercase letters, numbers, hyphens, and underscores only.'
            break;
          case 'lowercase-alphanumeric-underscore':
            r = /^[a-z0-9_]+$/
            err = 'Lowercase letters, numbers, and underscores only.'
            break;
          case 'hexadecimal':
            r = /^#(?:[0-9a-fA-F]{3}){1,2}$/
            err = 'Please enter a valid hexadecimal code, starting with #.';
            break;
          case 'alphanumeric-hyphen-underscore':
            r = /^[a-zA-Z0-9-_]+$/
            err = 'Letters, numbers, hyphens, and underscores only.'
            break;
          case 'alphanumeric-underscore':
            r = /^[a-zA-Z0-9_]+$/
            err = 'Letters, numbers, and underscores only.'
            break;
          case 'email':
            r = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            err = 'Invalid email address.'
            break;
          case 'list of emails':
            r = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            delimiter = /\s*,\s*|\s+/;
            err = 'Invalid email address.'
            break;
          case 'url':
            r = /https?:\/\/(www\.)?([-a-zA-Z0-9@:%._\+~#=]{1,256}|localhost)(:[0-9]+)?\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
            err = 'Must be a valid URL.'
            break;
        }
        if(r){
          var re = new RegExp(r);

          if(delimiter){
            let pieces = value.trim().split(delimiter);
            for(var i in pieces){
              pieces[i] = pieces[i].trim();
              if(!re.test(pieces[i])){
                return err;
              }
            }
          } else {
            if(!re.test(value)){
              return err;
            }
          }
        }
      }
    }
  }

  render(){    

    return <div className={"custom-field " + (this.props.inline ? "custom-field-inline" : "")} disabled={this.props.disabled || this.props.thinking} style={{minWidth: this.props.minWidth}}>
    {
      this.props.label &&
      <div className="custom-field-label">
        <span>  
          {this.props.label}{this.props.required && <span className="custom-field-required">*</span>}&nbsp;
        </span>
      </div>
    }
    {
      this.props.rows ? 
      <textarea
        id={'field_' + this.props.name + '_' + this.state.id}
        ref={this.fieldRef}
        className={"input " + (this.state.error ? "input-error" : "") + (" input-" + this.props.size)  + (this.props.noResizeRows || this.props.autoResizeRows ? " custom-field-no-resize" : "")} 
        name={this.props.name}
        type={this.props.type || 'text'} 
        rows={this.props.rows || 2}
        value={this.props.value}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        placeholder={(this.props.placeholder || this.props.name) + (this.props.required && !this.props.label ? ' *' : '')}
        disabled={this.props.disabled} 
        required={this.props.required}
        minLength={this.props.minLength}
        maxLength={this.props.maxLength}
        autoFocus={this.props.autoFocus}
        />
        :
      this.props.type === 'range' ?
      <div className="flex-split">
        <span className="custom-field-range-value">
          <input
            id={'field_' + this.props.name + '_' + this.state.id}
            ref={this.fieldRef}
            className={"input input-mini " + (this.state.error ? "input-error" : "")} 
            name={this.props.name}
            type={'number'} 
            value={this.props.value}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            placeholder={(this.props.placeholder || this.props.name) + (this.props.required && !this.props.label ? ' *' : '')}
            disabled={this.props.disabled} 
            required={this.props.required}
            step={this.props.step}
            min={this.props.min}
            max={this.props.max}
            autoFocus={this.props.autoFocus}
            />
        </span>
        <div className="flex-grow">
          <input
            id={'field_' + this.props.name + '_' + this.state.id + '_range'}
            width="100%"
            ref={this.fieldRef + '_range'}
            className={"input input-range " + (this.state.error ? "input-error" : "") + (" input-" + this.props.size)} 
            name={this.props.name + '_range'}
            type={this.props.type || 'text'} 
            value={this.props.value}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            placeholder={(this.props.placeholder || this.props.name || '') + (this.props.required && !this.props.label ? ' *' : '')}
            disabled={this.props.disabled} 
            required={this.props.required}
            minLength={this.props.minLength}
            maxLength={this.props.maxLength}
            step={this.props.step}
            min={this.props.min}
            max={this.props.max}
            autoFocus={this.props.autoFocus}
            />
        </div>
      </div>
      :
      <input
        id={'field_' + this.props.name + '_' + this.state.id}
        ref={this.fieldRef}
        className={"input " + (this.state.error ? "input-error" : "") + (" input-" + this.props.size)} 
        name={this.props.name}
        type={this.props.type || 'text'} 
        value={this.props.value}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        placeholder={(this.props.placeholder || this.props.name || '') + (this.props.required && !this.props.label ? ' *' : '')}
        disabled={this.props.disabled} 
        required={this.props.required}
        minLength={this.props.minLength}
        maxLength={this.props.maxLength}
        step={this.props.step}
        min={this.props.min}
        max={this.props.max}
        autoFocus={this.props.autoFocus}
        />
    }
    {
      (!this.props.inline || this.props.description) && <div className={"custom-field-description " + (this.state.error ? "custom-field-description-error" : "")}>
        {this.props.description}
      </div>
    }
    </div>
  }
};

const mapStateToProps = (state) => {
  // const {  } = state;

  return {
    // styleReducer
  }
}

export default connect(mapStateToProps)(CustomField);
