import {
  tryToLogout
} from '../actions.export'

var Cookies = require('js-cookie');

const forceLogoutHandler = (e) => (dispatch) => {
  
  if(e.message === 'Invalid signature.'
  || e.message === 'jwt malformed'
  || e.message === 'Suspended.'
  || e.message === 'force_logout'
  ){
    // // Log you out and remove the auth cookie
    // Cookies.remove(process.env.REACT_APP_COOKIE_AUTH);
    // window.location.href = '/';
    dispatch(tryToLogout());
  }
}

export default forceLogoutHandler;