// socketClientManager.js
import { io } from "socket.io-client";
import handleUserObject from './socket.handleUserObject';
import handleUserNotification from './socket.handleUserNotification';
import handleCursorEvent from './socket.handleCursorEvent';
import handleUpdateRoom from './socket.handleUpdateRoom';

import {
  socketConnected,
  socketDisconnected
} from '../actions/actions.export'

let socketClientManager = {
  socket: null,
  connected: false,
  eventHandlers: {},
  joinedRooms: new Set()
};

const initializeSocket = (dispatch, getState) => {

  let url = process.env.REACT_APP_LOCALHOST ? 'http://zerowidth.local:8001' : 'https://zerowidth.ai';

  const socket = io(url, { withCredentials: true });

  socket.on("connect", () => {
    socketClientManager.connected = true;
    // console.log('connected to socket.io');
    dispatch(socketConnected());
  });

  socket.on("disconnect", () => {
    // console.log('disconnected from socket.io');
    dispatch(socketDisconnected());
  });

  socket.on("unauthorized", (...args) => {
    // console.log('unauthorized socket', args);
    socketClientManager.disconnect(dispatch);
  });

  // Default event handlers
  socket.on("userObject", handleUserObject(dispatch, getState));
  socket.on("userNotification", handleUserNotification(dispatch, getState));
  socket.on("updateCursor", e => {
    
    if(e.cursor){
      
      if(!socketClientManager.joinedRooms.has(e.room)){
        // add to joined rooms
        socketClientManager.joinedRooms.add(e.room);
      }
    } else {
      // remove from joined rooms
      socketClientManager.joinedRooms.delete(e.room);
    }

    handleCursorEvent(dispatch, getState)(e);

    if(e.piggyback){
      // handle piggyback events
    }
  });
  socket.on("updateRoom", handleUpdateRoom(dispatch, getState));

  // Re-register all event handlers
  Object.keys(socketClientManager.eventHandlers).forEach(event => {
    socketClientManager.eventHandlers[event].forEach(handler => {
      socket.on(event, handler);
    });
  });

  return socket;
};

socketClientManager.ensureConnected = packet => (dispatch, getState) => {
  // console.log('ensureConnected');
  const isLoggedIn = getState().userReducer.isLoggedIn;

  if (!socketClientManager.connected && isLoggedIn) {
    socketClientManager.socket = initializeSocket(dispatch, getState);
  }
};

socketClientManager.disconnect = (dispatch) => {
  // console.log('disconnecting from socket.io');
  if (socketClientManager.socket) socketClientManager.socket.disconnect();
  socketClientManager.connected = false;
  dispatch(socketDisconnected());
};

// Emit an event to the server
socketClientManager.emitEvent = async (event, data) => {
  const room = `${data.type}-${data.id}`;

  // use sharedReducer to check how many people are in this room and if this should fire or not
  // TODO
  
  if (socketClientManager.connected) {
    if (!socketClientManager.joinedRooms.has(room)) {
      try {
        await socketClientManager.joinRoom({ type: data.type, id: data.id });
        if(socketClientManager.joinedRooms.has(room)){
          socketClientManager.socket.emit(event, data);
        }
      } catch (error) {
        // console.warn(`Failed to join room ${room}: ${error.message}`);
        // Handle the error gracefully without breaking the application
      }
    } else {
      socketClientManager.socket.emit(event, data);
    }
  } else {
    // console.warn(`Socket is not connected`);
  }
};

socketClientManager.onEvent = (event, handler) => {
  if (!socketClientManager.eventHandlers[event]) {
    socketClientManager.eventHandlers[event] = [];
  }
  socketClientManager.eventHandlers[event].push(handler);

  if (socketClientManager.socket) {
    socketClientManager.socket.on(event, handler);
  }
};

socketClientManager.offEvent = (event, handler) => {
  if (socketClientManager.eventHandlers[event]) {
    socketClientManager.eventHandlers[event] = socketClientManager.eventHandlers[event].filter(h => h !== handler);

    if (socketClientManager.socket) {
      socketClientManager.socket.off(event, handler);
    }

    if (socketClientManager.eventHandlers[event].length === 0) {
      delete socketClientManager.eventHandlers[event];
    }
  }
};


socketClientManager.joinRoom = ({type, id}) => {
  // console.log('joinRoom', type, id);
  if (socketClientManager.connected) {
    socketClientManager.socket.emit('joinRoom', {type, id});
  } else {
    // console.warn('Socket is not connected');
  }
};

socketClientManager.leaveRoom = ({type, id}) => {
  // console.log('leaveRoom', type, id);
  if (socketClientManager.connected) {
    socketClientManager.socket.emit('leaveRoom', {type, id});
  } else {
    // console.warn('Socket is not connected');
  }
};

export default socketClientManager;
