import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Hydrate from 'components/Hydrate/Hydrate'
import CustomField from 'kit/components/CustomField/CustomField'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import Callout from 'kit/components/Callout/Callout.js'
import Modal from 'kit/components/Modal/Modal.js'
import Checkbox from 'kit/components/Checkbox/Checkbox.js'

import {
  tryToSetProjectSettings,
  tryToDeleteProject
} from 'actions/actions.export'

import './ProjectSettings.scss';

class ProjectSettings extends Component {
  constructor(props){
    super(props);


    this.state = {
      display_name: "",
      description: "",
      retypeNameDelete: ""
    }
  }

  componentWillMount(){
    const { dispatch, projectReducer } = this.props;

    const project = projectReducer.cache[this.props.id];
    if(project){
      
      this.setState({
        display_name: project.display_name,
        description: project.description || "",
        visibility: project.visibility || "org"
      })
    } 
  }

  componentWillReceiveProps(newprops){
    const project = newprops.projectReducer.cache[newprops.id];
    if(project){
      if(!newprops.projectReducer.tryingToSetProjectSettingsMetadata && this.state.submittedMetadata){
        this.setState({
          display_name: project.display_name,
          description: project.description
        })
      }

      if(!newprops.projectReducer.tryingToSetProjectSettingsVisibility && this.state.submittedVisibility){
        this.setState({
          visibility: project.visibility
        })
      }
    } 
  }

  render(){

    const { dispatch, projectReducer, userReducer, orgReducer, canWrite } = this.props;

    const project = projectReducer.cache[this.props.id];

    if(!project) return <span>Failed to load.</span>;

    let canSubmit = this.state.display_name && !this.state.display_name_error && !this.state.description_error;


    return <div className="project-settings">
      <div className="row">
        <div className="col-xl-12 margin-top-05rem margin-bottom-2rem">
          <div className="flex-split">
            <div>
              <h4 className="no-margin-top no-margin-bottom">Settings</h4>
              <small>
                Edit aspects like the name, description, and visibility of this project.
              </small>
            </div>
          </div>
        </div>

        <div className="col-md-12 margin-bottom-2rem">
          <div className="box">
            <div className="row">
              <div className="col-xl-3">
                <h5 className="no-margin">General Info</h5>
                <p className="thin-line-height margin-top-05rem padding-right-2rem">
                  <small>
                    Give your project a name and description. This is how it will be referred to throughout ZeroWidth and can help let others know what it does.
                  </small>
                </p>
                <p className="thin-line-height margin-top-05rem padding-right-2rem">
                  <small>
                    Your project's description will be used in the <strong>define</strong> tools as the starting point to evaluate, iterate, and improve upon.
                  </small>
                </p>
                <small className="">
                  Created by <span className="text-900"><Hydrate type="user" id={project.created_by}/></span><br/>
                  Created at <span className="text-900">{new Date(project.created_at).toLocaleString()}</span>
                </small>
              </div>
              <div className="col-xl-9">
              
                    
                <CustomField
                  name="display_name"
                  label="Name"
                  placeholder="ex: Assistant, Data Processor"
                  maxLength={64}
                  required={true}
                  disabled={projectReducer.tryingToSetProjectSettings || !canWrite}
                  value={this.state.display_name}
                  serverError={projectReducer.setProjectSettingsForm.errors.display_name}
                  lastSubmit={projectReducer.setProjectSettingsForm.lastSubmit.display_name}
                  onChange={(e) => {
                    this.setState({
                      display_name: e.value,
                      display_name_error: e.error
                    })
                  }}
                  />


                <CustomField
                  name="description"
                  label="Description"
                  placeholder="ex: A conversational guide through our training materials."
                  maxLength={256}
                  rows={8}
                  disabled={projectReducer.tryingToSetProjectSettings || !canWrite}
                  value={this.state.description}
                  serverError={projectReducer.setProjectSettingsForm.errors.description}
                  lastSubmit={projectReducer.setProjectSettingsForm.lastSubmit.description}
                  onChange={(e) => {
                    this.setState({
                      description: e.value,
                      description_error: e.error
                    })
                  }}
                  />
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <hr className=""/>
              </div>
              <div className="col-xl-3">
                <h5 className="no-margin">General Access</h5>
                <p className="thin-line-height margin-top-05rem padding-right-2rem">
                  <small>
                    Manage the overall access to this project.
                  </small>
                </p>
                <p className="thin-line-height margin-top-05rem padding-right-2rem">
                  <small>
                    This organization's administrators will always be able to see and manage content within this organization.
                  </small>
                </p>
              </div>

              <div className="col-xl-9">

                <div className="row margin-top-2rem">
                  <div className="col-lg-6 padding-top-1rem">
                    <span className="text-900 ">
                      Who can see this project?
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <CustomSelect
                      inline={true}
                      required={true}
                      disabled={!canWrite || projectReducer.tryingToSetProjectSettings}
                      serverError={projectReducer.setProjectSettingsForm.errors.visibility}
                      lastSubmit={projectReducer.setProjectSettingsForm.lastSubmit.visibility}
                      value={this.state.visibility}
                      onChange={(v)=>{

                        let editability = this.state.editability;

                        if(v === 'team'){
                          editability = 'team';
                        }

                        this.setState({
                          visibility: v,
                          editability: editability,
                          submitted: false
                        })
                      }}
                      options={[
                        { 
                          label: <span><i className="far fa-fw fa-globe icon-before-text text-success"/> <span className="text-900">Anyone on the Internet</span></span>,
                          value: "public"
                        },
                        {
                          label: <span><i className="far fa-fw fa-building icon-before-text"/> <span className="text-900">Anyone in this organization</span></span>,
                          value: "org"
                        },
                        {
                          label: <span><i className="far fa-fw fa-users icon-before-text text-warning"/> <span className="text-900">Only specific people</span></span>,
                          value: "team"
                        }
                      ]}
                      />
                  </div>
                  <div className="col-lg-12 margin-top-2rem">
                    <Callout
                      style="info"
                      title="Managing Access"
                      content={<div className="thin-line-height">
                        <p>
                          Privileges for a project are controlled at a more granular level than other parts of ZeroWidth.
                        </p>
                        <p className="no-margin">
                          To manage the detailed visibility and editability of each team member within this project, visit the <Link to={`/workbench/${project.scope}/projects/${project.id}/team`}>Team</Link> page.
                        </p>
                      </div>}
                      />
                  </div>
                </div>
              </div>
            </div>  

            <div className="row margin-top-3rem">
              <div className="flex-split">
                <div/>
                <div className="list-right">
                  <CustomButton 
                    display={"Save Changes"}
                    thinking={projectReducer.tryingToSetProjectSettings}
                    success={projectReducer.setProjectSettingsSuccess}
                    fail={projectReducer.setProjectSettingsFail}
                    disabled={!canWrite || !canSubmit || !canWrite}
                    color="success"
                    size=""
                    onClick={()=>{
                      dispatch(tryToSetProjectSettings({
                        id: project.id,
                        display_name: this.state.display_name,
                        description: this.state.description,
                        visibility: this.state.visibility
                      }))

                      this.setState({
                        submitted: true
                      })
                    }}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          canWrite &&
          <div className="col-md-12">
            <div className="box box-danger">
              <div className="flex-split">
                <h4 className="no-margin">Delete</h4>
                
              </div>
              <p>
                Deleting is permanent and will immediately disable active API endpoints associated with this project and all other data & configurations.
              </p>
              <p>
                Flows and Knowledge Bases used by this project will still exist, but will be disassociated from this project. They can be re-associated with another project at any time.
              </p>
              <div className="flex-split">
                <Checkbox
                  value={this.state.confirmDelete}
                  style="align-left"
                  description="I understand the consequences of deleting."
                  onToggle={(e)=>{
                    this.setState({
                      confirmDelete: e
                    })
                  }}
                  />
                <CustomButton 
                  display="Delete"
                  disabled={!canWrite || !canSubmit || !this.state.confirmDelete}
                  color="danger"
                  onClick={()=>{
                    this.setState({
                      deleteModal: true,
                      retypeNameDelete: ""
                    })
                  }}
                  />
              </div>
            </div>
          </div>
        }
        <Modal
          show={this.state.deleteModal}
          exitable={true}
          onExit={e => this.setState({deleteModal: false})}
          cancelable={true}
          onCancel={e => this.setState({deleteModal: false})}
          acceptButtonLabel="Permenantly Delete"
          acceptButtonColor="danger"
          acceptButtonThinking={projectReducer.tryingToDeleteProject}
          acceptButtonFail={projectReducer.deleteProjectFail}
          acceptButtonSuccess={projectReducer.deleteProjectSuccess}
          acceptButtonDisabled={this.state.retypeNameDelete !== project.display_name}
          acceptable={true}
          onAccept={e => {
            dispatch(tryToDeleteProject({
              id: project.id,
              scope: project.scope
            }))
          }}
          content={<div>
            <h4 className="no-margin">Are you sure?</h4>
            <p>Deleting cannot be undone and may break things.</p>

            <CustomField
              label={<span>Type the name of this project to confirm.</span>}
              value={this.state.retypeNameDelete}
              description={<span>The name is: <strong>{project.display_name}</strong></span>}
              onChange={e => {
                this.setState({
                  retypeNameDelete: e.value
                })
              }}
              />
          </div>}
          /> 
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, projectReducer, orgReducer } = state;

  return {
    userReducer, 
    projectReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(ProjectSettings);

  