import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Hydrate from '../components/Hydrate/Hydrate.js'


import BlogPostThumbnail from '../kit/components/BlogPostThumbnail/BlogPostThumbnail.js'
import BlogPost from '../kit/components/BlogPost/BlogPost.js'
import CustomButton from '../kit/components/CustomButton/CustomButton.js'
import ASCIIBackground from '../components/ASCIIBackground/ASCIIBackground.js'


import {
  tryToListPosts,
  tryToGetPost
} from '../actions/actions.export'


class BlogRoute extends Component {
  componentDidMount(){
    window.document.title = "Blog - zerowidth.ai";
  }

  componentWillMount(){
    const { dispatch, postReducer, slug } = this.props;

    if(postReducer.list.length === 0){
      dispatch(tryToListPosts());
    }

    if(slug){
      dispatch(tryToGetPost({slug: slug}))
    }
  }

  render(){
    const { postReducer, slug } = this.props;

    let currentPost = postReducer.list.find(p => p.slug === slug);

    return <div className={"hf-stretched hf-stretched-noscroll"}>
      
      <div className="fixed-big-image">
        <ASCIIBackground 
          imagePath={currentPost ? '/home-img/center-middle-focus.png' : '/home-img/blog.png'} 
          foreground={'#C0C7D3'} 
          background={'#fff'} 
          autoTween={1000} 
          fontSize={16}
          placementMode={currentPost ? 'cover' : 'contain-bottom'}
          />
      </div>
      <Header borderless={true}/>

      {
        currentPost &&
        <Helmet>
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@zerowidth_ai" />
          <meta name="twitter:title" content={currentPost.title} />
          <meta name="twitter:description" content={currentPost.content.substring(0, 140)} />
          <meta name="twitter:image" content={currentPost.thumbnail_url} />
        </Helmet>
      }

      <div className="body">
      {
        this.props.slug ?
        <div className="container margin-top-2rem">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 padding-3rem">
              <div className="margin-bottom-2rem">
                <CustomButton
                  display={<span><i className="far fa-angle-double-left icon-before-text"/>All Posts</span>}
                  size="small"
                  to="/blog"
                  />
              </div>
              {
                currentPost &&
                <BlogPost post={currentPost} hydrateByLine={currentPost.author_id && <Hydrate type="user" id={currentPost.author_id} mode="lockup" placeholder=" "/>}/>
              }
              <div className="flex-split">
                <div>
                  {
                    postReducer.list.indexOf(currentPost) < postReducer.list.length - 1 &&
                    <CustomButton
                      display={<span><i className="far fa-angle-left icon-before-text"/>Previous</span>}
                      size="small"
                      to={"/blog/" + postReducer.list[postReducer.list.indexOf(currentPost) + 1].slug}
                      />
                  }
                </div>
                <div>
                  {
                    postReducer.list.indexOf(currentPost) > 0 &&
                    <CustomButton
                      display={<span>Next<i className="far fa-angle-right icon-after-text"/></span>}
                      size="small"
                      to={"/blog/" + postReducer.list[postReducer.list.indexOf(currentPost) - 1].slug}
                      />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="container margin-top-1rem margin-bottom-3rem">
          <div className="row padding-3rem">
            <div className="col-md-12">
             
            </div>
            {
              postReducer.list.filter(post => post.type === 'blog').map((post,i)=>{
                
                return <div className="col-md-4 margin-bottom-3rem padding-2rem" key={i}>
                  <BlogPostThumbnail post={post} url={"/blog/" + post.slug}/>
                </div>
              })
            }
          </div>
        </div>
      }
      </div>
      <Footer simple={true}/>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, guiReducer, postReducer } = state;

  return {
    userReducer,
    postReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(BlogRoute);

  