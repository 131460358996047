import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { push } from 'react-router-redux';
import moment from 'moment';

import CustomButton from '../../kit/components/CustomButton/CustomButton'
import CustomField from '../../kit/components/CustomField/CustomField'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect'
import Dropdown from '../../kit/components/Dropdown/Dropdown'
import TabNav from '../../kit/components/TabNav/TabNav'

import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Hydrate from '../../components/Hydrate/Hydrate'
import ProjectThumbnail from '../../components/ProjectThumbnail/ProjectThumbnail'
import ComponentContentThumbnail from '../../components/ComponentContentThumbnail/ComponentContentThumbnail'

import isFeatureSwitchOn from '../../utilities/isFeatureSwitchOn'

import {
  showTooltip,
  hideTooltip,
  tryToAddContentToComponent
} from '../../actions/actions.export'


class WorkbenchRecentComponents extends Component {
  constructor(props){
    super(props);

    this.state = {

    }
  }
  
  render(){

    const { dispatch, componentReducer, orgReducer, userReducer, postReducer, intelReducer, component, currentVersion, tool } = this.props;
    console.log(component,currentVersion,tool);
    if(!component || !currentVersion || !tool) return <span/>;
    
    let items = currentVersion[tool.name] || [];
    console.log(tool.name, currentVersion);
    
    let itemsToShow;
    if(this.props.showAll){
      itemsToShow = items;
    } else {
      itemsToShow = items.slice(0,3);
    }

    if(itemsToShow.length === 0) return <span/>;

    return <div className="margin-bottom-3rem">
      <div className="flex-split no-margin-top margin-bottom-05rem margin-right-05rem">
        <span>{!this.props.showAll && "Recent "}{tool.display_name_plural}</span>
        {
          (currentVersion['total_' + tool.name] > 0 && !this.props.showAll) &&
          <Link to={"/workbench/" + component.scope + "/agents/" + component.id + "/" + tool.name} className="link-no-decoration text-400"><small className="text-muted">see all {currentVersion['total_' + tool.name].toLocaleString()}</small></Link>
        }
      </div>
      {
        itemsToShow.map((item, i)=>{
          return <ComponentContentThumbnail 
            key={i}
            type={tool.name} 
            content={item} 
            showDetails={this.props.showDetails} 
            offerDetails={this.props.showDetails}
            style={this.props.subpage === item.id ? "primary" : undefined}
            linkedComponent={this.props.linked ? component : undefined}
            /> 
        })
      }
      {
        (items.length === 0 && this.props.createIfNone) &&
        <CustomButton
          size="small"
          block={true}
          color="grey"
          thinking={componentReducer.tryingToAddContentToComponent}
          fail={componentReducer.addContentToComponentFail}
          success={componentReducer.addContentToComponentSuccess}
          display={<span><i className="far fa-plus icon-before-text"/>Create your first</span>}
          onClick={()=>{
            dispatch(tryToAddContentToComponent({
              id: component.id,
              type: tool.name
            }))
          }}
          />
      }

      {
        (items.length === 0 && !this.props.createIfNone) &&
        <div>
          <small className="text-muted">None created.</small>
        </div>
      }
    </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, orgReducer, componentReducer, guiReducer, postReducer, intelReducer  } = state;

  return {
    userReducer,
    orgReducer,
    componentReducer,
    guiReducer, 
    postReducer, 
    intelReducer
  }
}

export default connect(mapStateToProps)(WorkbenchRecentComponents);
