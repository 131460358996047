import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import Callout from '../../kit/components/Callout/Callout.js'

import EmailVerifyForm from '../EmailVerifyForm/EmailVerifyForm.js'

import {
  tryToEditUserEmailAddress
} from '../../actions/actions.export'

class EditUserEmailAddressForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      email: props.userReducer.myData.email,
      email_verified: props.userReducer.myData.email_verified,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToEditUserEmailAddress({
      email: this.state.email
    }))
  }

  render(){

    const { dispatch, userReducer } = this.props;

    let canSubmit = this.state.email && !this.state.email_error && this.state.email !== userReducer.myData.email;

    return <div className="box">
      <form onSubmit={()=>{
        if(canSubmit) this.handleSubmit()
      }}>

        <h5 className="no-margin-top margin-bottom-3rem">
          Edit your email address
        </h5>

        <CustomField
          name="field_email"
          regex="email"
          label="Email"
          placeholder="email@example.com"
          description="Your personal or workplace email."
          required={true}
          thinking={false}
          disabled={userReducer.tryingToEditUserEmailAddress}
          value={this.state.email}
          serverError={userReducer.editUserEmailAddressForm.errors.email}
          lastSubmit={userReducer.editUserEmailAddressForm.lastSubmit.email}
          onChange={(e) => {
            this.setState({
              email: e.value,
              email_error: e.error
            })
          }}
          />

        {
          this.state.email !== userReducer.myData.email &&
          <Callout
            style="warning"
            title="Heads up!"
            content="Once you click Save, you'll need to re-complete email verification in the form below. Your old email address will also receive a confirmation of this change."
            />
        }


        <div className="spacer-2rem"/>

        {
          userReducer.editUserEmailAddressForm.errors.error &&
          <Callout
            style="danger"
            dismissable={true}
            content={userReducer.editUserEmailAddressForm.errors.error}
          />
        }
        
        <div className="list-right">
          <CustomButton
            display="Change Email Address"
            color="success"
            size="small"
            thinking={userReducer.tryingToEditUserEmailAddress}
            fail={userReducer.editUserEmailAddressFail}
            success={userReducer.editUserEmailAddressSuccess}
            disabled={!canSubmit}
            onClick={()=>{
              if(canSubmit) this.handleSubmit()
            }}
            />
        </div>
      </form>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(EditUserEmailAddressForm);
