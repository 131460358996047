
export const orgReducer = (state = {
  cache: {},
  cacheRequests: [],
  memberships: [],
  queryResultsCache: {},
  newOrganizationForm:                        {errors: {}, lastSubmit: {}},
  deleteOrganizationForm:                        {errors: {}, lastSubmit: {}},
  editOrganizationProfileForm:                {errors: {}, lastSubmit: {}},
  editOrganizationKeysForm:                   {errors: {}, lastSubmit: {}},
  editOrganizationProfileVisibilityForm:      {errors: {}, lastSubmit: {}},
  sendOrganizationInvitesForm:                {errors: {}, lastSubmit: {}},
  acceptOrganizationInviteForm:               {errors: {}, lastSubmit: {}},
  retractOrganizationInviteForm:              {errors: {}, lastSubmit: {}},
  upgradeOrganizationForm:                    {errors: {}, lastSubmit: {}},
  cancelProjectLicenseForm:                   {errors: {}, lastSubmit: {}},
  cancelUserLicenseForm:                      {errors: {}, lastSubmit: {}},
  editLicenseForm:                            {errors: {}, lastSubmit: {}},
  createOrganizationCheckoutSessionForm:      {errors: {}, lastSubmit: {}},
  createOrganizationPaymentPortalSessionForm: {errors: {}, lastSubmit: {}},
  transferOrganizationOwnershipForm:          {errors: {}, lastSubmit: {}},
  cancelSubscriptionForm:          {errors: {}, lastSubmit: {}},
  undoCancelSubscriptionForm:          {errors: {}, lastSubmit: {}},
}, action) => {

  switch(action.type){
    
    case 'REQUEST_CREATE_NEW_ORGANIZATION':
      return {
        ...state,
        tryingToCreateNewOrganization: true,
        newOrganizationForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_NEW_ORGANIZATION_FAIL':
       return {
        ...state,
        tryingToCreateNewOrganization: false,
        newOrganizationFail: new Date().getTime(),
        newOrganizationForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_NEW_ORGANIZATION_SUCCESS':
      var cache = state.cache;
      cache[action.data.id] = action.data;

      return {
        ...state,
        cache: cache,
        tryingToCreateNewOrganization: false,
        newOrganizationSuccess: new Date().getTime(),
        newOrganizationForm: {errors: {}, lastSubmit: {}}
      }






    case 'REQUEST_TRANSFER_ORGANIZATION_OWNERSHIP':
      return {
        ...state,
        tryingToTransferOrganizationOwnership: true,
        transferOrganizationOwnershipForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_TRANSFER_ORGANIZATION_OWNERSHIP_FAIL':
       return {
        ...state,
        tryingToTransferOrganizationOwnership: false,
        transferOrganizationOwnershipFail: new Date().getTime(),
        transferOrganizationOwnershipForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_TRANSFER_ORGANIZATION_OWNERSHIP_SUCCESS':
      // var cache = state.cache;
      // cache[action.data.id] = action.data;

      return {
        ...state,
        // cache: cache,
        tryingToTransferOrganizationOwnership: false,
        transferOrganizationOwnershipSuccess: new Date().getTime(),
        transferOrganizationOwnershipForm: {errors: {}, lastSubmit: {}}
      }






    case 'REQUEST_GET_ORGANIZATION_PROFILE':

      var cacheRequests = state.cacheRequests;
      cacheRequests.push(action.data.lastSubmit.id);

      return {
        ...state,
        cacheRequests: cacheRequests,
        tryingToGetOrganizationProfile: true,
      }

    case 'RECEIVE_GET_ORGANIZATION_PROFILE_FAIL':
      return {
        ...state,
        tryingToGetOrganizationProfile: false
      }

    case 'RECEIVE_GET_ORGANIZATION_PROFILE_SUCCESS':

      var cache = state.cache;
      cache[action.data.id] = {...cache[action.data.id], ...action.data};

      return {
        ...state,
        tryingToGetOrganizationProfile: false,
        cache: cache
      }

    


    case 'REQUEST_QUERY_ORGANIZATION_DATA':

      var queryResultsCache = state.queryResultsCache;
      if(!queryResultsCache[action.data.lastSubmit.cacheID]){
        queryResultsCache[action.data.lastSubmit.cacheID] = {}
      }
      queryResultsCache[action.data.lastSubmit.cacheID][action.data.lastSubmit.query] = {
        trying: true
      }
      
      return {
        ...state,
        queryResultsCache: queryResultsCache,
      }

    case 'RECEIVE_QUERY_ORGANIZATION_DATA_FAIL':

      var queryResultsCache = state.queryResultsCache;
      if(!queryResultsCache[action.data.lastSubmit.cacheID]){
        queryResultsCache[action.data.lastSubmit.cacheID] = {}
      }
      queryResultsCache[action.data.lastSubmit.cacheID][action.data.lastSubmit.query] = {
        trying: false,
        fail: new Date().getTime()
      }
      
      return {
        ...state,
        queryResultsCache: queryResultsCache,
      }

    case 'RECEIVE_QUERY_ORGANIZATION_DATA_SUCCESS':

      var queryResultsCache = state.queryResultsCache;
      if(!queryResultsCache[action.data.cacheID]){
        queryResultsCache[action.data.cacheID] = {}
      }

      queryResultsCache[action.data.cacheID][action.data.data.query] = {
        trying: false,
        success: new Date().getTime(),
        data: action.data.data.data
      }

      return {
        ...state,
        queryResultsCache: queryResultsCache,
      }




    case 'REQUEST_EDIT_ORGANIZATION_PROFILE':
      return {
        ...state,
        editOrganizationProfileForm: {errors: {}, lastSubmit: action.data.lastSubmit},
        tryingToEditOrganizationProfile: true,
      }

    case 'RECEIVE_EDIT_ORGANIZATION_PROFILE_FAIL':
      return {
        ...state,
        editOrganizationProfileFail: new Date().getTime(),
        editOrganizationProfileForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit},
        tryingToEditOrganizationProfile: false
      }

    case 'RECEIVE_EDIT_ORGANIZATION_PROFILE_SUCCESS':

      var cache = state.cache;
      cache[action.data.id] = {
        ...cache[action.data.id],
        ...action.data
      }

      return {
        ...state,
        tryingToEditOrganizationProfile: false,
        editOrganizationProfileForm: {errors: {}, lastSubmit: {}},
        editOrganizationProfileSuccess: new Date().getTime(),
        cache: cache
      }


    


    case 'REQUEST_EDIT_ORGANIZATION_KEYS':
      return {
        ...state,
        editOrganizationKeysForm: {errors: {}, lastSubmit: action.data.lastSubmit},
        tryingToEditOrganizationKeys: true,
      }

    case 'RECEIVE_EDIT_ORGANIZATION_KEYS_FAIL':
      return {
        ...state,
        editOrganizationKeysFail: new Date().getTime(),
        editOrganizationKeysForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit},
        tryingToEditOrganizationKeys: false
      }

    case 'RECEIVE_EDIT_ORGANIZATION_KEYS_SUCCESS':

      var cache = state.cache;
      cache[action.data.id] = {
        ...cache[action.data.id],
        keys: action.data.keys,
        enabled_providers: action.data.enabled_providers
      }

      return {
        ...state,
        tryingToEditOrganizationKeys: false,
        editOrganizationKeysForm: {errors: {}, lastSubmit: {}},
        editOrganizationKeysSuccess: new Date().getTime(),
        cache: cache
      }

    
    case 'REQUEST_GET_ORGANIZATION_KEYS':
      return {
        ...state,
        tryingToGetOrganizationKeys: true,
      }

    case 'RECEIVE_GET_ORGANIZATION_KEYS_FAIL':
      return {
        ...state,
        getOrganizationKeysFail: new Date().getTime(),
        tryingToGetOrganizationKeys: false
      }

    case 'RECEIVE_GET_ORGANIZATION_KEYS_SUCCESS':

      var cache = state.cache;
      cache[action.data.id].keys = action.data.keys;

      return {
        ...state,
        tryingToGetOrganizationKeys: false,
        getOrganizationKeysSuccess: new Date().getTime(),
        cache: cache
      }

  



    case 'REQUEST_EDIT_ORGANIZATION_PROFILE_VISIBILITY':
      return {
        ...state,
        editOrganizationProfileVisibilityForm: {errors: {}, lastSubmit: action.data.lastSubmit},
        tryingToEditOrganizationProfileVisibility: true,
      }

    case 'RECEIVE_EDIT_ORGANIZATION_PROFILE_VISIBILITY_FAIL':
      return {
        ...state,
        editOrganizationProfileVisibilityFail: new Date().getTime(),
        editOrganizationProfileVisibilityForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit},
        tryingToEditOrganizationProfileVisibility: false
      }

    case 'RECEIVE_EDIT_ORGANIZATION_PROFILE_VISIBILITY_SUCCESS':

      var cache = state.cache;
      cache[action.data.id] = action.data;

      return {
        ...state,
        tryingToEditOrganizationProfileVisibility: false,
        editOrganizationProfileVisibilityForm: {errors: {}, lastSubmit: {}},
        editOrganizationProfileVisibilitySuccess: new Date().getTime(),
        cache: cache
      }







    case 'REQUEST_CANCEL_ORGANIZATION_SUBSCRIPTION_AT_PERIOD_END':
      return {
        ...state,
        cancelSubscriptionForm: {errors: {}, lastSubmit: action.data.lastSubmit},
        tryingToCancelSubscription: true,
      }

    case 'RECEIVE_CANCEL_ORGANIZATION_SUBSCRIPTION_AT_PERIOD_END_FAIL':
      return {
        ...state,
        cancelSubscriptionFail: new Date().getTime(),
        cancelSubscriptionForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit},
        tryingToCancelSubscription: false
      }

    case 'RECEIVE_CANCEL_ORGANIZATION_SUBSCRIPTION_AT_PERIOD_END_SUCCESS':


      var cache = state.cache;
      var sub_index = cache[action.data.org_id].subscriptions.findIndex(s => s.id === action.data.subscription_id);
      if(sub_index > -1){
        cache[action.data.org_id].subscriptions[sub_index].renewing = action.data.renewing;
      }

      return {
        ...state,
        cache: cache,
        tryingToCancelSubscription: false,
        cancelSubscriptionForm: {errors: {}, lastSubmit: {}},
        cancelSubscriptionSuccess: new Date().getTime()
      }



    case 'REQUEST_UNDO_CANCEL_ORGANIZATION_SUBSCRIPTION_AT_PERIOD_END':
      return {
        ...state,
        undoCancelSubscriptionForm: {errors: {}, lastSubmit: action.data.lastSubmit},
        tryingToUndoCancelSubscription: true,
      }

    case 'RECEIVE_UNDO_CANCEL_ORGANIZATION_SUBSCRIPTION_AT_PERIOD_END_FAIL':
      return {
        ...state,
        undoCancelSubscriptionFail: new Date().getTime(),
        undoCancelSubscriptionForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit},
        tryingToUndoCancelSubscription: false
      }

    case 'RECEIVE_UNDO_CANCEL_ORGANIZATION_SUBSCRIPTION_AT_PERIOD_END_SUCCESS':

      var cache = state.cache;
      var sub_index = cache[action.data.org_id].subscriptions.findIndex(s => s.id === action.data.subscription_id);
      if(sub_index > -1){
        cache[action.data.org_id].subscriptions[sub_index].renewing = action.data.renewing;
      }

      return {
        ...state,
        cache: cache,
        tryingToUndoCancelSubscription: false,
        undoCancelSubscriptionForm: {errors: {}, lastSubmit: {}},
        undoCancelSubscriptionSuccess: new Date().getTime()
      }





    case 'REQUEST_CREATE_ORGANIZATION_CHECKOUT_SESSION':
      return {
        ...state,
        createOrganizationCheckoutSessionForm: {errors: {}, lastSubmit: action.data.lastSubmit},
        tryingToCreateOrganizationCheckoutSession: true,
      }

    case 'RECEIVE_CREATE_ORGANIZATION_CHECKOUT_SESSION_FAIL':
      return {
        ...state,
        createOrganizationCheckoutSessionFail: new Date().getTime(),
        createOrganizationCheckoutSessionForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit},
        tryingToCreateOrganizationCheckoutSession: false
      }

    case 'RECEIVE_CREATE_ORGANIZATION_CHECKOUT_SESSION_SUCCESS':

      // var cache = state.cache;
      // cache[action.data.id] = action.data;

      return {
        ...state,
        tryingToCreateOrganizationCheckoutSession: false,
        createOrganizationCheckoutSessionForm: {errors: {}, lastSubmit: {}},
        createOrganizationCheckoutSessionSuccess: new Date().getTime(),
        // cache: cache
      }




    case 'REQUEST_CREATE_ORGANIZATION_PAYMENT_PORTAL_SESSION':
      return {
        ...state,
        createOrganizationPaymentPortalSessionForm: {errors: {}, lastSubmit: action.data.lastSubmit},
        tryingToCreateOrganizationPaymentPortalSession: true,
      }

    case 'RECEIVE_CREATE_ORGANIZATION_PAYMENT_PORTAL_SESSION_FAIL':
      return {
        ...state,
        createOrganizationPaymentPortalSessionFail: new Date().getTime(),
        createOrganizationPaymentPortalSessionForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit},
        tryingToCreateOrganizationPaymentPortalSession: false
      }

    case 'RECEIVE_CREATE_ORGANIZATION_PAYMENT_PORTAL_SESSION_SUCCESS':

      // var cache = state.cache;
      // cache[action.data.id] = action.data;

      return {
        ...state,
        tryingToCreateOrganizationPaymentPortalSession: false,
        createOrganizationPaymentPortalSessionForm: {errors: {}, lastSubmit: {}},
        createOrganizationPaymentPortalSessionSuccess: new Date().getTime(),
        // cache: cache
      }



    case 'REQUEST_GET_MEMBERSHIPS':
      return {
        ...state,
        membershipsReady: false,
        tryingToGetMemberships: true,
      }

    case 'RECEIVE_GET_MEMBERSHIPS_FAIL':
      return {
        ...state,
        tryingToGetMemberships: false
      }

    case 'RECEIVE_GET_MEMBERSHIPS_SUCCESS':

      var cache = state.cache;

      var rolesThatNeedSettings = [
        'manage_info',
        'manage_members',
        'manage_billing',
        'create',
        'manage_content'
      ]

      for(var i in action.data){
        cache[action.data[i].organization.id] = {
          ...cache[action.data[i].organization.id],
          ...action.data[i].organization
        };

        action.data[i].needsSettingsAccess = false;
        for(var j in rolesThatNeedSettings){
          if(action.data[i].membership[rolesThatNeedSettings[j]]){
            action.data[i].needsSettingsAccess = true;
            break;
          }
        }
      }

      action.data.sort((a,b)=>{
        if(a.organization.display_name < b.organization.display_name) return -1;
        if(a.organization.display_name > b.organization.display_name) return 1;
      })

      return {
        ...state,
        cache: cache,
        tryingToGetMemberships: false,
        memberships: action.data,
        membershipsReady: true
      }


    case 'REQUEST_SEND_ORGANIZATION_INVITES':
    
      return {
        ...state,
        tryingToSendOrganizationInvites: true,
        sendOrganizationInvitesForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SEND_ORGANIZATION_INVITES_FAIL':
       return {
        ...state,
        tryingToSendOrganizationInvites: false,
        sendOrganizationInvitesFail: new Date().getTime(),
        sendOrganizationInvitesForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SEND_ORGANIZATION_INVITES_SUCCESS':
      var cache = state.cache;
      cache[action.data.id].inviteResults = action.data.inviteResults;

      return {
        ...state,
        cache: cache,
        tryingToSendOrganizationInvites: false,
        sendOrganizationInvitesSuccess: new Date().getTime(),
        sendOrganizationInvitesForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_GET_ORGANIZATION_INVITES':
      return {
        ...state,
        tryingToGetOrganizationInvites: true,
      }

    case 'RECEIVE_GET_ORGANIZATION_INVITES_FAIL':
      return {
        ...state,
        tryingToGetOrganizationInvites: false,
        getOrganizationInvitesFail: new Date().getTime()
      }

    case 'RECEIVE_GET_ORGANIZATION_INVITES_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].invitesEmpty = false;
        if(!cache[action.data.id].invites || cache[action.data.id].invites.length === 0 || !action.data.startedAfter){
          cache[action.data.id].invites = action.data.data;
        } else {
          if(cache[action.data.id].invites[0].id !== action.data.data[0].id){
            cache[action.data.id].invites = cache[action.data.id].invites.concat(action.data.data);
          }
        }
      } else if(action.data.startedAfter){
        cache[action.data.id].invitesEmpty = true;
      } else {
        cache[action.data.id].invitesEmpty = true;
        cache[action.data.id].invites = [];
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        cache[action.data.id].invitesEmpty = true; 
      }

      return {
        ...state,
        tryingToGetOrganizationInvites: false,
        getOrganizationInvitesSuccess: new Date().getTime(),
        cache: cache
      }





    case 'REQUEST_GET_ORGANIZATION_INVITES_LOG':
      return {
        ...state,
        tryingToGetOrganizationInvitesLog: true,
      }

    case 'RECEIVE_GET_ORGANIZATION_INVITES_LOG_FAIL':
      return {
        ...state,
        tryingToGetOrganizationInvitesLog: false,
        getOrganizationInvitesLogFail: new Date().getTime()
      }

    case 'RECEIVE_GET_ORGANIZATION_INVITES_LOG_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].invitesLogEmpty = false;
        if(!cache[action.data.id].invitesLog || cache[action.data.id].invitesLog.length === 0 || !action.data.startedAfter){
          cache[action.data.id].invitesLog = action.data.data;
        } else {
          if(cache[action.data.id].invitesLog[0].id !== action.data.data[0].id){
            cache[action.data.id].invitesLog = cache[action.data.id].invitesLog.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].invitesLogEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        cache[action.data.id].invitesLogEmpty = true; 
      }

      return {
        ...state,
        tryingToGetOrganizationInvitesLog: false,
        getOrganizationInvitesLogSuccess: new Date().getTime(),
        cache: cache
      }



    case 'REQUEST_GET_ORGANIZATION_PROJECT_LICENSES_LOG':
      return {
        ...state,
        tryingToGetOrganizationProjectLicensesLog: true,
      }

    case 'RECEIVE_GET_ORGANIZATION_PROJECT_LICENSES_LOG_FAIL':
      return {
        ...state,
        tryingToGetOrganizationProjectLicensesLog: false,
        getOrganizationProjectLicensesLogFail: new Date().getTime()
      }


    case 'RECEIVE_GET_ORGANIZATION_PROJECT_LICENSES_LOG_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].projectLicensesLogEmpty = false;
        if(!cache[action.data.id].projectLicensesLog || cache[action.data.id].projectLicensesLog.length === 0 || !action.data.startedAfter){
          cache[action.data.id].projectLicensesLog = action.data.data;
        } else {
          if(cache[action.data.id].projectLicensesLog[0].id !== action.data.data[0].id){
            cache[action.data.id].projectLicensesLog = cache[action.data.id].projectLicensesLog.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].projectLicensesLogEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        cache[action.data.id].projectLicensesLogEmpty = true; 
      }

      return {
        ...state,
        tryingToGetOrganizationProjectLicensesLog: false,
        getOrganizationProjectLicensesLogSuccess: new Date().getTime(),
        cache: cache
      }





    case 'REQUEST_GET_ORGANIZATION_USER_LICENSES_LOG':
      return {
        ...state,
        tryingToGetOrganizationUserLicensesLog: true,
      }

    case 'RECEIVE_GET_ORGANIZATION_USER_LICENSES_LOG_FAIL':
      return {
        ...state,
        tryingToGetOrganizationUserLicensesLog: false,
        getOrganizationUserLicensesLogFail: new Date().getTime()
      }


    case 'RECEIVE_GET_ORGANIZATION_USER_LICENSES_LOG_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].userLicensesLogEmpty = false;
        if(!cache[action.data.id].userLicensesLog || cache[action.data.id].userLicensesLog.length === 0 || !action.data.startedAfter){
          cache[action.data.id].userLicensesLog = action.data.data;
        } else {
          if(cache[action.data.id].userLicensesLog[0].id !== action.data.data[0].id){
            cache[action.data.id].userLicensesLog = cache[action.data.id].userLicensesLog.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].userLicensesLogEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        cache[action.data.id].userLicensesLogEmpty = true; 
      }

      return {
        ...state,
        tryingToGetOrganizationUserLicensesLog: false,
        getOrganizationUserLicensesLogSuccess: new Date().getTime(),
        cache: cache
      }






    case 'REQUEST_GET_ORGANIZATION_SUBSCRIPTIONS_LOG':
      return {
        ...state,
        tryingToGetOrganizationSubscriptionsLog: true,
      }

    case 'RECEIVE_GET_ORGANIZATION_SUBSCRIPTIONS_LOG_FAIL':
      return {
        ...state,
        tryingToGetOrganizationSubscriptionsLog: false,
        getOrganizationSubscriptionsLogFail: new Date().getTime()
      }


    case 'RECEIVE_GET_ORGANIZATION_SUBSCRIPTIONS_LOG_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].subscriptionsLogEmpty = false;
        if(!cache[action.data.id].subscriptionsLog || cache[action.data.id].subscriptionsLog.length === 0 || !action.data.startedAfter){
          cache[action.data.id].subscriptionsLog = action.data.data;
        } else {
          if(cache[action.data.id].subscriptionsLog[0].id !== action.data.data[0].id){
            cache[action.data.id].subscriptionsLog = cache[action.data.id].subscriptionsLog.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].subscriptionsLogEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        cache[action.data.id].subscriptionsLogEmpty = true; 
      }

      return {
        ...state,
        tryingToGetOrganizationSubscriptionsLog: false,
        getOrganizationSubscriptionsLogSuccess: new Date().getTime(),
        cache: cache
      }






    case 'REQUEST_CANCEL_ORGANIZATION_PROJECT_LICENSE':
      return {
        ...state,
        tryingToCancelProjectLicense: true,
        cancelProjectLicenseForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CANCEL_ORGANIZATION_PROJECT_LICENSE_FAIL':
      return {
        ...state,
        tryingToCancelProjectLicense: false,
        cancelProjectLicenseFail: new Date().getTime(),
        cancelProjectLicenseForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CANCEL_ORGANIZATION_PROJECT_LICENSE_SUCCESS':

      var cache = state.cache;

      var index = cache[action.data.id].projectLicenses.findIndex(i => i.id === action.data.license_id);
      if(index > -1){
        cache[action.data.id].projectLicenses.splice(index, 1);
      }

      return {
        ...state,
        tryingToCancelProjectLicense: false,
        cancelProjectLicenseSuccess: new Date().getTime(),
        cache: cache,
        cancelProjectLicenseForm: {errors: {}, lastSubmit: {}}
      }



    case 'REQUEST_GET_ORGANIZATION_MEMBERS_LOG':
      return {
        ...state,
        tryingToGetOrganizationMembersLog: true,
      }

    case 'RECEIVE_GET_ORGANIZATION_MEMBERS_LOG_FAIL':
      return {
        ...state,
        tryingToGetOrganizationMembersLog: false,
        getOrganizationMembersLogFail: new Date().getTime()
      }

    case 'RECEIVE_GET_ORGANIZATION_MEMBERS_LOG_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].membersLogEmpty = false;
        if(!cache[action.data.id].membersLog || cache[action.data.id].membersLog.length === 0 || !action.data.startedAfter){
          cache[action.data.id].membersLog = action.data.data;
        } else {
          if(cache[action.data.id].membersLog[0].id !== action.data.data[0].id){
            cache[action.data.id].membersLog = cache[action.data.id].membersLog.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].membersLogEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        cache[action.data.id].membersLogEmpty = true; 
      }

      return {
        ...state,
        tryingToGetOrganizationMembersLog: false,
        getOrganizationMembersLogSuccess: new Date().getTime(),
        cache: cache
      }





    case 'REQUEST_SEARCH_ORGANIZATION_MEMBERS':
      return {
        ...state,
        tryingToSearchOrganizationMembers: true,
        searchOrganizationMembersForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SEARCH_ORGANIZATION_MEMBERS_FAIL':
      return {
        ...state,
        tryingToSearchOrganizationMembers: false,
        searchOrganizationMembersFail: new Date().getTime(),
        searchOrganizationMembersForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SEARCH_ORGANIZATION_MEMBERS_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].memberSearchEmpty = false;
        if(!cache[action.data.id].memberSearch || cache[action.data.id].memberSearch.length === 0 || !action.data.startedAfter){
          cache[action.data.id].memberSearch = action.data.data;
        } else {
          if(cache[action.data.id].memberSearch[0].id !== action.data.data[0].id){
            cache[action.data.id].memberSearch = cache[action.data.id].memberSearch.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].memberSearchEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        cache[action.data.id].memberSearchEmpty = true; 
      }

      return {
        ...state,
        tryingToSearchOrganizationMembers: false,
        searchOrganizationMembersSuccess: new Date().getTime(),
        cache: cache,
        searchOrganizationMembersForm: {errors: {}, lastSubmit: {}}
      }





    case 'REQUEST_GET_ORGANIZATION_MEMBERS':
      return {
        ...state,
        tryingToGetOrganizationMembers: true,
      }

    case 'RECEIVE_GET_ORGANIZATION_MEMBERS_FAIL':
      return {
        ...state,
        tryingToGetOrganizationMembers: false,
        getOrganizationMembersFail: new Date().getTime()
      }

    case 'RECEIVE_GET_ORGANIZATION_MEMBERS_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].membersEmpty = false;
        if(!cache[action.data.id].members || cache[action.data.id].members.length === 0 || !action.data.startedAfter){
          cache[action.data.id].members = action.data.data;
        } else {
          if(cache[action.data.id].members[0].id !== action.data.data[0].id){
            cache[action.data.id].members = cache[action.data.id].members.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].membersEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        cache[action.data.id].membersEmpty = true; 
      }

      return {
        ...state,
        tryingToGetOrganizationMembers: false,
        getOrganizationMembersSuccess: new Date().getTime(),
        cache: cache
      }




    case 'REQUEST_GET_ORGANIZATION_PROJECT_LICENSES':
      return {
        ...state,
        tryingToGetOrganizationProjectLicenses: true,
      }

    case 'RECEIVE_GET_ORGANIZATION_PROJECT_LICENSES_FAIL':
      return {
        ...state,
        tryingToGetOrganizationProjectLicenses: false,
        getOrganizationProjectLicensesFail: new Date().getTime()
      }

    case 'RECEIVE_GET_ORGANIZATION_PROJECT_LICENSES_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].projectLicensesEmpty = false;
        if(!cache[action.data.id].projectLicenses || cache[action.data.id].projectLicenses.length === 0 || !action.data.startedAfter){
          cache[action.data.id].projectLicenses = action.data.data;
        } else {
          if(cache[action.data.id].projectLicenses[0].id !== action.data.data[0].id){
            cache[action.data.id].projectLicenses = cache[action.data.id].projectLicenses.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].projectLicensesEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 100){
        cache[action.data.id].projectLicensesEmpty = true; 
      }

      return {
        ...state,
        tryingToGetOrganizationProjectLicenses: false,
        getOrganizationProjectLicensesSuccess: new Date().getTime(),
        cache: cache
      }





    case 'REQUEST_GET_ORGANIZATION_USER_LICENSES':
      return {
        ...state,
        tryingToGetOrganizationUserLicenses: true,
      }

    case 'RECEIVE_GET_ORGANIZATION_USER_LICENSES_FAIL':
      return {
        ...state,
        tryingToGetOrganizationUserLicenses: false,
        getOrganizationUserLicensesFail: new Date().getTime()
      }

    case 'RECEIVE_GET_ORGANIZATION_USER_LICENSES_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].userLicensesEmpty = false;
        if(!cache[action.data.id].userLicenses || cache[action.data.id].userLicenses.length === 0 || !action.data.startedAfter){
          cache[action.data.id].userLicenses = action.data.data;
        } else {
          if(cache[action.data.id].userLicenses[0].id !== action.data.data[0].id){
            cache[action.data.id].userLicenses = cache[action.data.id].userLicenses.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].userLicensesEmpty = true;
        cache[action.data.id].userLicenses = [];
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 100){
        cache[action.data.id].userLicensesEmpty = true; 
      }

      return {
        ...state,
        tryingToGetOrganizationUserLicenses: false,
        getOrganizationUserLicensesSuccess: new Date().getTime(),
        cache: cache
      }




    case 'REQUEST_GET_ORGANIZATION_SUBSCRIPTIONS':
      return {
        ...state,
        tryingToGetOrganizationSubscriptions: true,
      }

    case 'RECEIVE_GET_ORGANIZATION_SUBSCRIPTIONS_FAIL':
      return {
        ...state,
        tryingToGetOrganizationSubscriptions: false,
        getOrganizationSubscriptionsFail: new Date().getTime()
      }

    case 'RECEIVE_GET_ORGANIZATION_SUBSCRIPTIONS_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].subscriptionsEmpty = false;
        if(!cache[action.data.id].subscriptions || cache[action.data.id].subscriptions.length === 0 || !action.data.startedAfter){
          cache[action.data.id].subscriptions = action.data.data;
        } else {
          if(cache[action.data.id].subscriptions[0].id !== action.data.data[0].id){
            cache[action.data.id].subscriptions = cache[action.data.id].subscriptions.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].subscriptionsEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 100){
        cache[action.data.id].subscriptionsEmpty = true; 
      }

      return {
        ...state,
        tryingToGetOrganizationSubscriptions: false,
        getOrganizationSubscriptionsSuccess: new Date().getTime(),
        cache: cache
      }






    case 'REQUEST_RETRACT_ORGANIZATION_INVITE':
      return {
        ...state,
        tryingToRetractOrganizationInvite: true,
        retractOrganizationInviteForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_RETRACT_ORGANIZATION_INVITE_FAIL':
      return {
        ...state,
        tryingToRetractOrganizationInvite: false,
        retractOrganizationInviteFail: new Date().getTime(),
        retractOrganizationInviteForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_RETRACT_ORGANIZATION_INVITE_SUCCESS':

      var cache = state.cache;

      var index = cache[action.data.id].invites.findIndex(i => i.id === action.data.invite_id);
      if(index > -1){
        cache[action.data.id].invites.splice(index, 1);
      }

      return {
        ...state,
        tryingToRetractOrganizationInvite: false,
        retractOrganizationInviteSuccess: new Date().getTime(),
        cache: cache,
        retractOrganizationInviteForm: {errors: {}, lastSubmit: {}}
      }







    case 'REQUEST_REMOVE_ORGANIZATION_MEMBER':
      return {
        ...state,
        tryingToRemoveOrganizationMember: true,
        removeOrganizationMemberForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_REMOVE_ORGANIZATION_MEMBER_FAIL':
      return {
        ...state,
        tryingToRemoveOrganizationMember: false,
        removeOrganizationMemberFail: new Date().getTime(),
        removeOrganizationMemberForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_REMOVE_ORGANIZATION_MEMBER_SUCCESS':

      var cache = state.cache;

      var index = cache[action.data.id].members.findIndex(i => i.id === action.data.member_id);
      if(index > -1){
        cache[action.data.id].members.splice(index, 1);
        cache[action.data.id].member_count--;
      }

      return {
        ...state,
        tryingToRemoveOrganizationMember: false,
        removeOrganizationMemberSuccess: new Date().getTime(),
        cache: cache,
        removeOrganizationMemberForm: {errors: {}, lastSubmit: {}}
      }







    case 'REQUEST_EDIT_ORGANIZATION_MEMBER_PRIVILEGE':
      return {
        ...state,
        tryingToEditOrganizationMemberPrivilege: true,
        editOrganizationMemberPrivilegeForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_EDIT_ORGANIZATION_MEMBER_PRIVILEGE_FAIL':
      return {
        ...state,
        tryingToEditOrganizationMemberPrivilege: false,
        editOrganizationMemberPrivilegeFail: new Date().getTime(),
        editOrganizationMemberPrivilegeForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_EDIT_ORGANIZATION_MEMBER_PRIVILEGE_SUCCESS':

      var cache = state.cache;

      var index = cache[action.data.id].members.findIndex(i => i.id === action.data.member_id);
      
      if(index > -1){
        cache[action.data.id].members[index][action.data.privilege_name] = action.data.privilege_value;
      }

      return {
        ...state,
        tryingToEditOrganizationMemberPrivilege: false,
        editOrganizationMemberPrivilegeSuccess: new Date().getTime(),
        cache: cache,
        editOrganizationMemberPrivilegeForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_ACCEPT_ORGANIZATION_INVITE':
      return {
        ...state,
        tryingToAcceptOrganizationInvite: true,
        acceptOrganizationInviteForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_ACCEPT_ORGANIZATION_INVITE_FAIL':
      return {
        ...state,
        tryingToAcceptOrganizationInvite: false,
        acceptOrganizationInviteFail: new Date().getTime(),
        acceptOrganizationInviteForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_ACCEPT_ORGANIZATION_INVITE_SUCCESS':

      return {
        ...state,
        tryingToAcceptOrganizationInvite: false,
        acceptOrganizationInviteSuccess: new Date().getTime(),
        acceptOrganizationInviteForm: {errors: {}, lastSubmit: {}}
      }



    case 'REQUEST_LEAVE_ORGANIZATION':
      return {
        ...state,
        tryingToLeaveOrganization: true,
        acceptOrganizationInviteForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_LEAVE_ORGANIZATION_FAIL':
      return {
        ...state,
        tryingToLeaveOrganization: false,
        leaveOrganizationFail: new Date().getTime(),
        leaveOrganizationForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_LEAVE_ORGANIZATION_SUCCESS':

      return {
        ...state,
        tryingToLeaveOrganization: false,
        leaveOrganizationSuccess: new Date().getTime(),
        leaveOrganizationForm: {errors: {}, lastSubmit: {}}
      }






    case 'REQUEST_UPLOAD_ORGANIZATION_AVATAR':
      return {
        ...state,
        tryingToUploadOrganizationAvatar: true,
      }

    case 'RECEIVE_UPLOAD_ORGANIZATION_AVATAR_FAIL':
      return {
        ...state,
        tryingToUploadOrganizationAvatar: false,
        uploadOrganizationAvatarFail: new Date().getTime()
      }

    case 'RECEIVE_UPLOAD_ORGANIZATION_AVATAR_SUCCESS':

      
      var cache = state.cache;
      cache[action.data.id].avatar_url = action.data.avatar_url;

      return {
        ...state,
        tryingToUploadOrganizationAvatar: false,
        uploadOrganizationAvatarSuccess: new Date().getTime(),
        cache: cache
      }


    
      case 'REQUEST_DELETE_ORGANIZATION':
        return {
          ...state,
          tryingToDeleteOrganization: true,
          deleteOrganizationForm: {errors: {}, lastSubmit: action.data.lastSubmit}
        }
  
      case 'RECEIVE_DELETE_ORGANIZATION_FAIL':
         return {
          ...state,
          tryingToDeleteOrganization: false,
          deleteOrganizationFail: new Date().getTime(),
          deleteOrganizationForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
        }
  
      case 'RECEIVE_DELETE_ORGANIZATION_SUCCESS':
        var cache = state.cache;
        cache[action.data.id] = action.data;
  
        return {
          ...state,
          cache: cache,
          tryingToDeleteOrganization: false,
          deleteOrganizationSuccess: new Date().getTime(),
          deleteOrganizationForm: {errors: {}, lastSubmit: {}}
        }
  
  




    default:
      return state
  }
}
