import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js';
import CustomField from 'kit/components/CustomField/CustomField.js';
import TabNav from 'kit/components/TabNav/TabNav.js';
import Hydrate from 'components/Hydrate/Hydrate.js';

import toCamelCaseFunctionName from 'utilities/toCamelCaseFunctionName';

function GenerateImage({ options = {}, onChange, enabled, org_id, canWrite}) {
  const [selectedOption, setSelectedOption] = useState('');
  const [mode, setMode] = useState('about');

  // Access the Redux dispatch function
  const dispatch = useDispatch();
  const intelReducer = useSelector((state) => state.intelReducer);

  const thisModel = intelReducer.image_models.find(model => model.name === options.model);

  return (
    <div>
      <CustomSelect
        value={options.model}
        label="Which image generation model should this agent use?"
        placeholder="Dall-e 2, Dall-e 3, etc"
        description="This doesn't need to match the provider of the foundational LLM this agent uses."
        
        large={true}
        required={true}
        disabled={!canWrite}
        options={(intelReducer.image_models || []).map((model, i)=>{
          
          return {
            label: <div className="thin-line-height list-left">
              {model.provider === 'OpenAI' && <img src="/img/openai_logo_small.png" width="30"/>}
              {model.provider === 'Google Gemini' && <img src="/img/gemini_logo_small.png" width="30"/>}
              {model.provider === "Meta" && <img src="/img/meta_logo_small.png" width="30"/>}
              <div>
                <strong>{model.name}</strong><br/>
                <div className="text-ellipsis-1-lines"><small>by {model.provider}  {model.legacy ? <span className="margin-left-1rem text-tag text-tag-tiny text-tag-danger">LEGACY</span> : model.preview ? <span className="margin-left-1rem text-tag text-tag-tiny text-tag-warning">PREVIEW</span> : ""}</small></div>
              </div>
            </div>,
            value: model.name
          }
        })}
        onChange={(e) => {

          let parameters = {};
          let newModel = intelReducer.image_models.find(model => model.name === e);
          if(newModel.parameters){
            for(var i in newModel.parameters){
              parameters[newModel.parameters[i].name] = newModel.parameters[i].default;
            }
          }

          onChange({
            model: e,
            parameters: parameters
          })
        }}
        />

        {
          thisModel &&
          <div>

            <div className="spacer-2rem"/>
            <TabNav
              value={mode}
              selectedStaysLit={true}
              onChange={(v)=>{
                setMode(v);
              }}
              items={[

                {
                  value: 'about',
                  display: 'About This Model'
                },
                {
                  value: 'parameters',
                  display: 'Adjustable Parameters'
                },
                {
                  value: 'cost',
                  display: 'Cost per Image'
                }
              ]}
              />

              {
                mode === 'about' && <div className="padding-1rem">
                  <p>
                    <div className="list-left margin-bottom-2rem">
                      <div className="margin-right-3rem">
                        <small>Provider:</small>
                        <h4 className="no-margin">{thisModel.provider}</h4>
                      </div>
                    </div>

                    <div className="margin-bottom-2rem">
                      <small>Description from {thisModel.provider}:</small><br/>
                      <small className="text-900">"{thisModel.description}"</small>
                    </div>
                    <hr/>
                    <p className="no-margin-bottom margin-top-2rem">
                      By choosing and using this model, your data will be processed through the provider's API. We recommend you review the provider's terms of service and privacy policy before using their services. In addition to ZeroWidth's Terms of Service, violating these terms may result in account suspension or termination.
                    </p>
                    <p>
                      For your convenience, here are a few links to some of the relevant documents from this provider. This list is not exhaustive and you should review the provider's website for the most up-to-date information:
                    </p>

                    {
                      thisModel.provider === 'OpenAI' && <div>
                        <ul className="ul-iconed">
                          <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://openai.com/policies/terms-of-use">OpenAI Terms of Use</a></li>
                          <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://openai.com/policies/business-terms">OpenAI Business terms</a></li>
                          <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://openai.com/policies/privacy-policy">OpenAI Privacy Policy</a></li>
                          <li className="margin-bottom-1rem"><i className="far fa-long-arrow-right icon-after-text icon-before-text"/><a target="_blank" href="https://openai.com/policies/">OpenAI Policies</a></li>
                        </ul>
                      </div>
                    }
                  </p>
                </div>
              }
                  
              
              {
                mode === 'parameters' && <div className="padding-1rem">
                  <p>
                    Each provider offers a variety of parameters that can be adjusted to customize the behavior of the model. While many share common names and behaviors, the specific parameters, ranges, and their effects can vary between providers and models. Please refer to the provider's documentation for more information on the parameters available for this model.
                  </p>
                  <hr/>
                  {
                    thisModel.parameters.map((parameter, i) => {

                    if(parameter.type === "select"){
                      return <CustomSelect
                        value={options.parameters[parameter.name]}
                        label={parameter.display_name}
                        options={parameter.options}
                        onChange={(e) => {
                          let newParameters = {...options.parameters};
                          newParameters[parameter.name] = e;
                          onChange({
                            parameters: newParameters
                          })
                        }}
                        disabled={!canWrite}
                        placeholder={parameter.default}
                        description={parameter.description}
                        />
                    }
                  
                    return <CustomField
                      label={parameter.name}
                      value={options.parameters[parameter.name]}
                      onChange={(e) => {
                        let newParameters = {...options.parameters};
                        newParameters[parameter.name] = e.value;
                        onChange({
                          parameters: newParameters
                        })
                      }}
                      disabled={!canWrite}
                      placeholder={parameter.default}
                      description={parameter.description}
                      />
                    })
                  }
                </div>
              }

              {
                (mode === 'cost' && thisModel.name === 'dall-e-3') && <div className="padding-1rem">
                  
                    <p>
                      DALL-E 3 pricing is dependent on the quality and size of the image generated. The following table provides a general overview of the cost per image based on the quality and size selected.
                    </p>
                    <p>
                      Please note that these prices are subject to change and may vary based on the provider's pricing model. For the most up-to-date pricing information, please refer to the provider's website.
                    </p>
                    <table className="table-basic">
                      <thead>
                        <tr>
                          <th>Quality Mode</th>
                          <th>Size in Pixels</th>
                          <th>Cost per Image</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>SD</td>
                          <td>1024x1024</td>
                          <td>${thisModel.price_per_image.sd['1024x1024']}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>1024x1792</td>
                          <td>${thisModel.price_per_image.sd['1024x1792']}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>1792x1024</td>
                          <td>${thisModel.price_per_image.sd['1792x1024']}</td>
                        </tr>
                        <tr>
                          <td>HD</td>
                          <td>1024x1024</td>
                          <td>${thisModel.price_per_image.hd['1024x1024']}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>1024x1792</td>
                          <td>${thisModel.price_per_image.hd['1024x1792']}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>1792x1024</td>
                          <td>${thisModel.price_per_image.hd['1792x1024']}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              }

              {
                (mode === 'cost' && thisModel.name === 'dall-e-2') && <div className="padding-1rem">
                  <p>
                    DALL-E 2 pricing is dependent on the size of the image generated. The following table provides a general overview of the cost per image based on the quality and size selected.
                  </p>
                  <p>
                    Please note that these prices are subject to change and may vary based on the provider's pricing model. For the most up-to-date pricing information, please refer to the provider's website.
                  </p>

                  <table className="table-basic">
                    <thead>
                      <tr>
                        <th>Size in Pixels</th>
                        <th>Cost per Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>256x256</td>
                        <td>${thisModel.price_per_image['256x256']}</td>
                      </tr>
                      <tr>
                        <td>512x512</td>
                        <td>${thisModel.price_per_image['512x512']}</td>
                      </tr>
                      <tr>
                        <td>1024x1024</td>
                        <td>${thisModel.price_per_image['1024x1024']}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              }
          </div>
        }

{/* 
      <CustomField
        label="Function Name"
        value={options.knowledge_function_name}
        maxLength={32}
        onChange={e => {
          onChange({
            knowledge_function_name: e.value
          })
        }}
        disabled={!canWrite}
        placeholder="ex: getKnowledge"
        description="Automatically set based on Knowledge Base's name, but can be changed if necessary. This is the name of the function that will be called in the AI and should be unique across all enabled Functional Retrieve Knowledge on this agent."
        />

      <CustomField
        label="When and why should the Agent choose to search this Knowledge Base?"
        value={options.knowledge_function_description}
        maxLength={512}
        rows={10}
        onChange={e => {
          onChange({
            knowledge_function_description: e.value
          })
        }}
        disabled={!canWrite}
        placeholder="ex: use this knowledge base when the user is asking about our best practices"
        />

      <CustomField
        name={'max_information_returned'}
        label={<span>How many pieces of information should be returned?<br/><small className="text-muted">(defaults to 1)</small></span>}
        description={""}
        type="range"
        min={1}
        max={32}
        step={1}
        placeholder={'1'}
        disabled={!canWrite}
        value={options.max_information_returned}
        onChange={e => {
          onChange({
            max_information_returned: e.value
          })
        }}
        /> */}

    </div>
  );
}

export default GenerateImage;
