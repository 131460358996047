import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { nanoid } from 'nanoid'

import Dropdown from 'kit/components/Dropdown/Dropdown'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import TabNav from 'kit/components/TabNav/TabNav'
import Callout from 'kit/components/Callout/Callout.js'
import CustomField from 'kit/components/CustomField/CustomField.js'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js'
import Modal from 'kit/components/Modal/Modal.js'

import {
  tryToGetPaginated,
} from 'actions/actions.export'

// import './ComponentQualityControl.scss';

class ComponentQualityControl extends Component {
  constructor(props){
    super(props);

    this.state = {
      mode: 'reports',
      showDeleteModal: false
    }
  }

  componentWillMount(){
    const { dispatch, componentReducer } = this.props;

    const component = componentReducer.cache[this.props.id];
    if(component){
      if(!component.inaccuracies){
        // try to get paginated list of inaccuracies
        dispatch(tryToGetPaginated({
          kind: 'components',
          id: this.props.id,
          collection: 'inaccuracies',
          orderBy: 'timestamp'
        }))
      }
    } 
  }

  componentWillReceiveProps(newprops){
    const component = newprops.componentReducer.cache[newprops.id];

    // load in the test if we haven't already
    if(component && !this.state.tests){
      this.setState({
        tests: component.tests
      })
    }

    // if(component){
    //   if(!newprops.componentReducer.tryingToSetComponentQualityControl && this.state.submitted){
    //     let draft = component.versions.find(v => v.id === 'draft');
    //     if(draft){
    //       this.setState({
    //         context_cap_method: draft.context_cap_method,
    //         context_truncate_threshold: draft.context_truncate_threshold,
    //         context_summarize_prompt: draft.context_summarize_prompt,
    //         context_summarize_instruction: draft.context_summarize_instruction
    //       })
    //     }
    //   }
    // } 
  }

  render(){

    const { dispatch, componentReducer, userReducer, intelReducer, sharedReducer, canWrite } = this.props;

    const component = componentReducer.cache[this.props.id];

    if(!component) return <span>Failed to load component.</span>;

    const currentVersion = (component.versions || []).find(v => v.id === 'draft');

    if(!currentVersion) return <span/>;

    let modelData = intelReducer.llms.find(llm => llm.name === currentVersion.model) || {};


    return <div className="component-quality-control">
     
      <div className="row">
        <div className="col-md-12 margin-top-05rem margin-bottom-2rem">
          <div className="flex-split">
            <div>
              <h4 className="no-margin ">Quality Control</h4>
              <small>
                Track and measure your agent's performance and accuracy.
              </small>
            </div>
{/* 
            <CustomButton 
              display={component.current_version === 'draft' ? "Save Changes" : "Save Changes to Draft"}
              thinking={componentReducer.tryingToSetComponentQualityControl}
              success={componentReducer.setComponentQualityControlSuccess}
              fail={componentReducer.setComponentQualityControlFail}
              size="small"
              disabled={!canWrite}
              color="success"
              onClick={()=>{

                dispatch(tryToSetComponentQualityControl({
                  id: component.id,
                  context_cap_method: this.state.context_cap_method,
                  context_truncate_threshold: this.state.context_truncate_threshold,
                  context_summarize_prompt: this.state.context_summarize_prompt,
                  context_summarize_instruction: this.state.context_summarize_instruction
                }))

                this.setState({
                  submitted: true
                })
              }}
              /> */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {
            componentReducer.setComponentQualityControlForm.errors.error && <Callout style="danger" content={componentReducer.setComponentQualityControlForm.errors.error}/>
          }
        </div>
      </div>
      <div className="box box-no-pad box-no-border">
        <TabNav
          value={this.state.mode}
          style={"box-top"}
          onChange={(v)=>{
            this.setState({mode: v})
          }}
          items={[
            {
              value: 'reports',
              display: 'Inaccuracy Reports'
            }
          ]}
          />

        {
          this.state.mode === 'reports' && <div className="padding-2rem">
            <p className="no-margin-top">
              If an Inaccuracy Reporter plugin is enabled, users can report inaccuracies in the agent's responses. These reports will appear here.
            </p>
            {
              sharedReducer.cache[ 'components/' + component.id + '/inaccuracies'] &&
              (sharedReducer.cache[ 'components/' + component.id + '/inaccuracies'].data || []).map((inaccuracy, i) => {
                return <div key={i} className="margin-bottom-1rem box box-light-border box-no-shadow">
                  <div className="flex-split margin-bottom-05rem flex-split-align-start">
                    <div className="list-left">
                      <small className="text-muted">Reported {moment(inaccuracy.timestamp).format('MMM D YYYY h:mm a')} on {inaccuracy.version}</small>
                    </div>
                    {/* <div className="list-right">
                      <CustomButton
                        color="grey"
                        size="xs"
                        display="View Details"
                        />
                      <CustomButton
                        color="danger"
                        size="xs"
                        display="Delete"
                        />
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <small className="text-semi-muted text-900">Issue</small><br/>
                      <p className="thin-line-height no-margin-top">
                        <small>{inaccuracy.inaccuracy_description}</small>
                      </p>
                    </div>
                    <div className="col-md-1"></div>


                    <div className="col-md-7">
                      <small className="text-semi-muted text-900">Reformatted Prompt</small><br/>
                      <p className="thin-line-height no-margin-top ">
                        <small>"{inaccuracy.reframed_training_prompt}"</small>
                      </p>
                      <small className="text-components text-900">Reformatted Response</small><br/>
                      <p className="thin-line-height no-margin-top no-margin-bottom">
                        <small>"{inaccuracy.reframed_training_response}"</small>
                      </p>
                    </div>
                  </div>
                </div>
              })
            }
            {
              sharedReducer.cache[ 'components/' + component.id + '/inaccuracies'] &&
              <div>
                <div className="list-center margin-top-3rem">
                  <CustomButton
                    display="Load More"
                    color="grey"
                    size="small"
                    thinking={sharedReducer.getPaginationRequests['components/' + component.id + '/inaccuracies']}
                    onClick={()=>{
                      dispatch(tryToGetPaginated({
                        kind: 'components',
                        id: this.props.id,
                        collection: 'inaccuracies',
                        orderBy: 'timestamp',
                        startAfter: component.inaccuracies[component.inaccuracies.length - 1].timestamp
                      }))
                    }}
                    />
                </div>
              </div>
            }
          </div>
        }
       
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, componentReducer, intelReducer, sharedReducer } = state;

  return {
    userReducer, 
    componentReducer,
    intelReducer,
    sharedReducer
  }
}

export default connect(mapStateToProps)(ComponentQualityControl);

  