export function deepCompare(obj1, obj2) {
  // Check if both are objects (including arrays)
  if (typeof obj1 === 'object' && obj1 !== null && typeof obj2 === 'object' && obj2 !== null) {
      // If both are objects, but not the same type (array vs object), they are not equal
      if (Array.isArray(obj1) !== Array.isArray(obj2)) {
          return false;
      }

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      // If they have different number of keys, they are not equal
      if (keys1.length !== keys2.length) {
          return false;
      }

      // Check if all keys and values are the same, recursively
      for (let key of keys1) {
          if (!keys2.includes(key) || !deepCompare(obj1[key], obj2[key])) {
              return false;
          }
      }

      // All keys and values are the same
      return true;
  } else if (typeof obj1 === 'string' && typeof obj2 === 'string') {
      // If both are strings, compare them after trimming
      return obj1.trim() === obj2.trim();
  } else {
      // For all other types, compare directly
      return obj1 === obj2;
  }
}
