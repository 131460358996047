import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import Hydrate from 'components/Hydrate/Hydrate'
import CustomField from 'kit/components/CustomField/CustomField'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import Modal from 'kit/components/Modal/Modal.js'

import NewKnowledgeForm from 'components/Workbench/NewKnowledgeForm'
import WorkbenchKind from 'components/Workbench/WorkbenchKind'

import {
  tryToGetSearch,
  tryToInstallKnowledge
} from 'actions/actions.export'

import './ProjectKnowledge.scss';

class ProjectKnowledge extends Component {
  constructor(props){
    super(props);


    this.state = {
      editingKnowledge: false,
      addToProjectModal: false,
    }

    this.searchFormSubmit = this.searchFormSubmit.bind(this);
  }



  componentWillMount(){
    this.loadURLIntoStateAndSearch(this.props);
  }


  loadURLIntoStateAndSearch(props){
    const { projectReducer } = props;
    
    const project = projectReducer.cache[props.id];

    const { dispatch } = props;
    let loadedState = {};

    // grab the url parameters and load them into state where relevant 
    const params = new URLSearchParams(window.location.search);
    const query = params.get('query');
    const kinds = (params.get('kinds') || "").split(' ').filter(k => k.length > 0);
    const page = parseInt(params.get('page')) || 0;

    if(query){
      loadedState.query = query;
    }

    if(kinds.length > 0){
      loadedState.kinds = kinds;
    }

    loadedState.page = page;

    loadedState.lastSearchURL = window.location.search;

    this.setState(loadedState);
  
    if(query){
      let queryPacket = {
        organization: project.scope,
        query: query,
      }

      if(kinds.length > 0){
        queryPacket.kinds = kinds.join(" ");
      }

      if(page){
        queryPacket.page = page;
      }

      dispatch(tryToGetSearch(queryPacket))
    }
  }

  componentWillReceiveProps(nextProps){

    const { projectReducer } = nextProps;
    
    const project = projectReducer.cache[nextProps.id];

    if(nextProps.item){

      let canFind = (project.knowledge || []).find(a => a.id === nextProps.item);

      if(canFind){
        this.setState({
          editingKnowledge: nextProps.item,
          addToProjectModal: false
        })
      } else {
        this.setState({
          editingKnowledge: false,
          addToProjectModal: false
        })
      }
    } else {
      this.setState({
        editingKnowledge: false
      })
    }

    // if the url changes, load the new url into state
    if(window.location.search !== this.state.lastSearchURL){
      this.loadURLIntoStateAndSearch(nextProps);
    }
  }

  searchFormSubmit(e){
    if(e.preventDefault) e.preventDefault();
    let url = window.location.pathname + '?query=' + this.state.query;
    
    url += '&kinds=knowledge';

    if(this.state.page){
      url += '&page=' + this.state.page;
    }

    this.props.dispatch(push(url));
  }

  render(){

    const { dispatch, projectReducer, userReducer, orgReducer, sharedReducer, canWrite } = this.props;

    const project = projectReducer.cache[this.props.id];

    if(!orgReducer.membershipsReady) return <span/>;

    if(!project) return <span>Failed to load project.</span>;

    let knowledge = project.knowledge || [];

    const params = new URLSearchParams(window.location.search);
    const searchString = 'organization=' + project.scope + '&' + new URLSearchParams(params).toString();
    const query = params.get('query');
    const kinds = (params.get('kinds') || "").split('+').filter(k => k.length > 0);

    // grab the results from the sharedReducer
    let searchResults = sharedReducer.searchRequests[searchString];
    
    return <div className="project-knowledge flex-grow flex-column-stretch">

      <Modal
        show={this.state.addToProjectModal}
        exitable={true}
        onExit={e => this.setState({addToProjectModal: false})}
        cancelable={true}
        onCancel={e => this.setState({addToProjectModal: false})}
        acceptable={true}
        onAccept={e => {
          dispatch(tryToInstallKnowledge({
            id: project.id,
            knowledge_id: this.state.addToProjectModal
          }))
        }}
        acceptButtonThinking={projectReducer.tryingToInstallComponent}
        acceptButtonColor="knowledge"
        acceptButtonLabel="Add to Project"
        content={<div>
          <h4 className="no-margin-top">Add to Project</h4>
          <p>Would you like to add this flow to your project?</p>
          
          </div>}
        />

      {
        this.state.editingKnowledge ? <div className="flex-grow flex-column-stretch">
          <WorkbenchKind
            org_id={project.scope} 
            kind='knowledge'
            nestedBy={project.id}
            kind_id={this.state.editingKnowledge} 
            page={this.props.pages[3]}
            subpage={this.props.pages[4]}
            />
        </div>
        :
        this.props.item === 'new' ? <div className="flex-grow scroll-parent">
          <div className="scroll-child padding-2rem">
            <NewKnowledgeForm org_id={project.scope} project_id={project.id}/>
          </div>
        </div>
        :
        <div className="scroll-parent flex-grow">
          <div className="scroll-child padding-2rem">
            <div className="flex-split margin-bottom-2rem">
              <div className="list-left">
                <CustomButton
                  color="knowledge"
                  size="small"
                  display={<span><i className="fas fa-plus"></i> New Knowledge Base</span>}
                  to={`/workbench/${project.scope}/projects/${this.props.id}/knowledge/new`}
                  />
              </div>
              <form className="list-right" onSubmit={e => {
                e.preventDefault();
                this.searchFormSubmit();
              }}>

                <CustomField
                  inline={true}
                  minWidth="350px"
                  placeholder="search to add knowledge from your organization"
                  value={this.state.query}
                  onChange={(e)=>this.setState({query: e.value})}
                  />
                <CustomButton
                  color="black"
                  size="small"
                  display={<span><i className="fas fa-search"></i> Search</span>}
                  onClick={this.searchFormSubmit}
                  />
              </form>
            </div>
            <hr/>
            {
              searchResults ? <div className="row">
                {
                  searchResults.trying ? <div className="col-xl-12">
                    <h5 className="no-margin">Searching...</h5>
                  </div>
                  :
                  searchResults.results.nbHits === 0 ?
                  <p>
                    No results found for <strong>{query}</strong>.
                  </p>
                  :          
                  <div className="col-xl-12">
                    <div className="flex-split margin-bottom-2rem">
                      <div>
                        Found {searchResults.results.nbHits} results for <strong>{query}</strong> in your organization:
                      </div>
                      <CustomButton
                        color="grey"
                        size="xs"
                        display="Clear Search"
                        onClick={e => {
                          this.setState({query: null});
                          this.props.dispatch(push(window.location.pathname));
                        }}
                        />
                    </div>
                    <div className="row">
                      {searchResults.results && searchResults.results.hits.map((hit, i)=>{ 
                        return <div className="col-xl-3 col-lg-4 col-md-6" key={i}>
                          <Hydrate type={'knowledge'}id={hit.objectID} mode="thumbnail" noLink={true} obj={hit} key={i} project={project.id} onClick={e => {
                            this.setState({addToProjectModal: hit.objectID});
                          }}/>
                        </div>
                      })}
                    </div>
                  </div>
                }
              </div>
              :
              <div className="row">
                {
                  knowledge.map((kindThing, i)=>{
                    if(this.props.limit && i >= this.props.limit) return null;

                    return <div className="col-xl-3 col-lg-4 col-md-6">
                      <Hydrate type={'knowledge'} id={kindThing.id} mode="thumbnail" obj={kindThing} key={i} project={project.id}/>
                    </div>
                  })
                }
                {
                  (knowledge.length === 0 && !projectReducer.tryingToGetProject) && <div className="col-xl-12">
                    <div className="flex-column-center-center">
                      <h5 className="text-muted">You haven't added any Knowledge Bases to this project yet.</h5>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      }
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, projectReducer, orgReducer, sharedReducer } = state;

  return {
    userReducer, 
    projectReducer,
    orgReducer,
    sharedReducer
  }
}

export default connect(mapStateToProps)(ProjectKnowledge);

  