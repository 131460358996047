import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect.js'
import Checkbox from '../../kit/components/Checkbox/Checkbox.js'
import Callout from '../../kit/components/Callout/Callout.js'
import Modal from '../../kit/components/Modal/Modal.js'

import MemberSearchSelect from '../../components/MemberSearchSelect/MemberSearchSelect.js'


import {
  tryToTransferOrganizationOwnership
} from '../../actions/actions.export'

class TransferOrganizationOwnershipForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      password: ""
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToTransferOrganizationOwnership({
      id: this.props.id,
      member_id: this.state.newOwnerMember.id,
      password: this.state.password
    }))
  }

  render(){

    const { orgReducer, userReducer, dispatch } = this.props;
    
    let canOpenModal = this.state.newOwnerMember !== undefined;
    let canSubmit = this.state.newOwnerMember && this.state.password && !this.state.password_error;

    let organization;
    for(var i in orgReducer.memberships){
      if(orgReducer.memberships[i].organization.id === this.props.id){
        organization = orgReducer.memberships[i].organization;
      }
    }

    if(!organization) return <span/>;

    let canTransferOrganization = false;

    if(organization.owner === userReducer.myData.id){
      canTransferOrganization = true;
    }

    if(!canTransferOrganization) return <span/>;

    const reminderDiv = <div>
      <p>
        After transferring ownership:
      </p>
      <ul className="ul-iconed margin-bottom-3rem">
        <li className="margin-bottom-1rem"><i className="fas fa-check-circle text-success li-icon"></i>The new owner will be able to transfer the organization another member.</li>
        <li className="margin-bottom-1rem"><i className="fas fa-check-circle text-success li-icon"></i>The new owner will be able to delete the organization entirely.</li>
        <li className="margin-bottom-1rem"><i className="fas fa-check-circle text-success li-icon"></i>The new owner or other members with the Manage Members privilege could remove you as a member.</li>
        <li className="margin-bottom-1rem"><i className="fas fa-check-circle text-success li-icon"></i>The only way for you to regain ownership is if the new owner chooses to transfer the organization to you.</li>
      </ul>
    </div>

    if(this.state.understood){
      return <div className={""}>
          <div className="margin-bottom-2rem margin-top-05rem">
            <h4 className="no-margin">Transfer Organization Ownership</h4>
            <small>
              Transfer ownership of this organization to another ZeroWidth account.
            </small>
          </div>
          <div className="box">
          <h5 className="no-margin-top margin-bottom-2rem">
            Who would you like to transfer ownership to?
          </h5>
          <div className="list-left">
            <MemberSearchSelect 
              id={this.props.id}
              manager={true}
              removeMe={true}
              value={this.state.newOwnerMember}
              onChange={(member)=>{
                this.setState({
                  newOwnerMember: member,
                })
              }}
              onClear={()=>{
                this.setState({
                  newOwnerMember: undefined
                })
              }}
              />
          </div>

          {
            reminderDiv
          }

          <div className="spacer-2rem"/>

          {
            orgReducer.transferOrganizationOwnershipForm.errors.error &&
            <Callout
              style="danger"
              dismissable={true}
              content={orgReducer.transferOrganizationOwnershipForm.errors.error}
            />
          }
          
          <div className="list-right">
            <CustomButton
              display="Transfer Ownership"
              color="black"
              size="small"
              disabled={!canOpenModal}
              onClick={()=>{
                if(canOpenModal) this.setState({
                  confirmModalOpen: true
                })
              }}
              />
          </div>

          {
            (this.state.confirmModalOpen && this.state.newOwnerMember !== undefined) &&
            <Modal
              maxWidth={500}
              show={this.state.confirmModalOpen}
              content={<div>
                  <h4 className="no-margin">Confirm Transfer</h4>
                  <p>Are you sure you want to transfer ownership to {this.state.newOwnerMember.user_display_name}?</p>
                  <p>Please enter your password to confirm. This cannot be undone.</p>

                  <CustomField
                    name="field_password"
                    type="password"
                    label="Your Password"
                    placeholder="********"
                    required={true}
                    thinking={false}
                    disabled={orgReducer.tryingToTransferOrganizationOwnership}
                    value={this.state.password}
                    serverError={orgReducer.transferOrganizationOwnershipForm.errors.password}
                    lastSubmit={orgReducer.transferOrganizationOwnershipForm.lastSubmit.password}
                    onChange={(e) => {
                      this.setState({
                        password: e.value,
                        password_error: e.error
                      })
                    }}
                    />

                  <div className="modal-inner-button-row">
                    <CustomButton
                      display="Cancel"
                      disabled={orgReducer.tryingToTransferOrganizationOwnership}
                      onClick={()=>{ 
                        this.setState({ confirmModalOpen: false }) 
                      }}
                      />
                    <CustomButton
                      display={"Transfer Ownership"}
                      color="danger"
                      thinking={orgReducer.tryingToTransferOrganizationOwnership}
                      success={orgReducer.transferOrganizationOwnershipSuccess}
                      fail={orgReducer.transferOrganizationOwnershipFail}
                      disabled={!canSubmit}
                      onClick={()=>{
                        if(canSubmit) this.handleSubmit();
                      }}
                      />
                  </div>
                </div>}
              exitable={true}
              onExit={()=>{ 
                this.setState({ confirmModalOpen: false }) 
              }}
              />
            }
          </div>
        </div>
      } else {
        return <div className={""}>
          <div className="margin-bottom-2rem margin-top-05rem">
            <h4 className="no-margin">Transfer Organization Ownership</h4>
            <small>
              Transfer ownership of this organization to another ZeroWidth account.
            </small>
          </div>
          <div className="box">
            <h5 className="no-margin-top no-margin-bottom">Before you proceed, a reminder about transferring ownership</h5>
            <div>
              {reminderDiv}
              <div className="list-right">
                <CustomButton
                  display="I Understand"
                  color="black"
                  size="small"
                  onClick={()=>{
                    this.setState({
                      understood: true
                    })
                  }}
                  />
              </div>
            </div>
          </div>
        </div>
        }

  }
}


const mapStateToProps = (state) => {
  const { orgReducer, userReducer } = state;

  return {
    orgReducer, userReducer
  }
}

export default connect(mapStateToProps)(TransferOrganizationOwnershipForm);
