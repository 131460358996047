import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

// import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {
  tryToGetMemberships,
  tryToGetUserNotifications
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Accept invite to an org
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestAcceptOrganizationInvite = packet => {
  return {
    type: 'REQUEST_ACCEPT_ORGANIZATION_INVITE',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveAcceptOrganizationInviteSuccess = data => ({
  type: 'RECEIVE_ACCEPT_ORGANIZATION_INVITE_SUCCESS',
  data
})

export const receiveAcceptOrganizationInviteFail = data => ({
  type: 'RECEIVE_ACCEPT_ORGANIZATION_INVITE_FAIL',
  data
})

export const tryToAcceptOrganizationInvite = packet => (dispatch, getState) => {

  dispatch(requestAcceptOrganizationInvite(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/organizations/invites/' + packet.id, {
      method: 'put',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveAcceptOrganizationInviteSuccess(json));
      dispatch(tryToGetMemberships());
      dispatch(tryToGetUserNotifications());
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveAcceptOrganizationInviteFail({errors:e, lastSubmit: packet}));
    })
}
