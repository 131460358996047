import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect.js'
import Checkbox from '../../kit/components/Checkbox/Checkbox.js'
import Callout from '../../kit/components/Callout/Callout.js'

import {
  tryToEditOrganizationProfile
} from '../../actions/actions.export'

class EditOrganizationProfileForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      display_name: props.orgReducer.cache[props.id].display_name,
      bio: props.orgReducer.cache[props.id].bio,
      location: props.orgReducer.cache[props.id].location,
      url: props.orgReducer.cache[props.id].url,
      has_public_profile: props.orgReducer.cache[props.id].has_public_profile || false,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToEditOrganizationProfile({
      id: this.props.id,
      display_name: this.state.display_name,
      bio: this.state.bio,
      location: this.state.location,
      url: this.state.url,
      has_public_profile: this.state.has_public_profile || false
    }))
  }

  render(){

    const { orgReducer } = this.props;

    let canSubmit = this.state.display_name && !this.state.display_name_error && !this.state.bio_error && !this.state.location_error && !this.state.company_error && !this.state.url_error;

    let canEditProfile = false;
    for(var i in orgReducer.memberships){
      if(orgReducer.memberships[i].organization.id === this.props.id){
        if(orgReducer.memberships[i].membership.manage_info){
          canEditProfile = true;
        }
      }
    }

    if(!canEditProfile) return <span/>;
    
    const organization = orgReducer.cache[this.props.id];

    return <div className={"box"}>
      <form onSubmit={()=>{
        if(canSubmit) this.handleSubmit()
      }}>
        <div className="row no-margin-top margin-bottom-3rem">
          <div className="col-md-12">
            <h5 className="no-margin">
              Enable a public profile page for your organization?
            </h5>
            <p className="thin-line-height">
              <small>
                By default, your organization's profile is private. When enabled, your organization's profile page will be visible to anyone on the internet - with or without a ZeroWidth account. 
              </small>
            </p>

            <p className="thin-line-height">
              <small>
                Only Projects, Flows, and Knowledge Bases that have been specifically configured to be visible by anyone on the internet will be appear on your organization's public profile page.
              </small>
            </p>
          </div>
          <div className="col-md-12">
            <CustomSelect
              large={true}
              // disabled={true}
              value={this.state.has_public_profile}
              description="Organization profiles are coming soon."
              placeholder="By default, your organization's profile is private."
              options={[
                {
                  value: false,
                  label: <span><i className="far fa-fw fa-eye-slash icon-before-text text-danger"/> <span className="text-900">Private, no public profile page.</span></span>,
                },
                {
                  value: true,
                  label: <span><i className="far fa-fw fa-globe icon-before-text text-success"/> <span className="text-900">Public profile enabled.</span></span>,
                }
              ]}
              onChange={(e)=>{
                this.setState({
                  has_public_profile: e
                })
              }}
              />

            {/* <p className="thin-line-height">
              <small>
                Whether your organization's profile is public or private, as an administrator you can preview it at any time by visiting the URL below:
              </small>
            </p>
            <p className="thin-line-height">
              <small>
                <Link to={`/o/${this.props.id}`}>https://zerowidth.ai/o/{this.props.id}</Link>
              </small>
            </p> */}
          </div>
        </div>

        <hr/>

        <CustomField
          name="display_name"
          label="Display Name"
          placeholder="ex: Cool Biz Inc."
          description="This is the name that will be displayed to your members, regardless of your organization's public profile settings."
          maxLength={64}
          required={true}
          disabled={orgReducer.tryingToEditOrganizationProfile}
          value={this.state.display_name}
          serverError={orgReducer.editOrganizationProfileForm.errors.display_name}
          lastSubmit={orgReducer.editOrganizationProfileForm.lastSubmit.display_name}
          onChange={(e) => {
            this.setState({
              display_name: e.value,
              display_name_error: e.error
            })
          }}
          />


        <CustomField
          name="bio"
          label="Bio"
          placeholder="ex: A small agency based out of the tri-city region."
          description="A few sentences about your organization."
          maxLength={256}
          rows={3}
          disabled={orgReducer.tryingToEditOrganizationProfile}
          value={this.state.bio}
          serverError={orgReducer.editOrganizationProfileForm.errors.bio}
          lastSubmit={orgReducer.editOrganizationProfileForm.lastSubmit.bio}
          onChange={(e) => {
            this.setState({
              bio: e.value,
              bio_error: e.error
            })
          }}
          />


        <CustomField
          name="location"
          label="Location"
          placeholder="ex: Humboldt Park, Los Angeles, Minnesota, US, etc"
          description="Be as granular or general as you'd like."
          maxLength={64}
          disabled={orgReducer.tryingToEditOrganizationProfile}
          value={this.state.location}
          serverError={orgReducer.editOrganizationProfileForm.errors.location}
          lastSubmit={orgReducer.editOrganizationProfileForm.lastSubmit.location}
          onChange={(e) => {
            this.setState({
              location: e.value,
              location_error: e.error
            })
          }}
          /> 

        <CustomField
          name="url"
          label="Website"
          placeholder="ex: https://.example.com"
          description="Must start with http:// or https://"
          regex={"url"}
          disabled={orgReducer.tryingToEditOrganizationProfile}
          value={this.state.url}
          serverError={orgReducer.editOrganizationProfileForm.errors.url}
          lastSubmit={orgReducer.editOrganizationProfileForm.lastSubmit.url}
          onChange={(e) => {
            this.setState({
              url: e.value,
              url_error: e.error
            })
          }}
          /> 

        <div className="spacer-2rem"/>

        {
          orgReducer.editOrganizationProfileForm.errors.error &&
          <Callout
            style="danger"
            dismissable={true}
            content={orgReducer.editOrganizationProfileForm.errors.error}
          />
        }
        
        <div className="list-right">
          <CustomButton
            display="Save Changes"
            color="success"
            size="small"
            thinking={orgReducer.tryingToEditOrganizationProfile}
            fail={orgReducer.editOrganizationProfileFail}
            success={orgReducer.editOrganizationProfileSuccess}
            disabled={!canSubmit}
            onClick={()=>{
              if(canSubmit) this.handleSubmit()
            }}
            />
        </div>
      </form>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { orgReducer } = state;

  return {
    orgReducer
  }
}

export default connect(mapStateToProps)(EditOrganizationProfileForm);
