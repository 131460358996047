import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

// import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

// import {
  
// } from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Get a project's api keys
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestGetAPIKeys = packet => {
  return {
    type: 'REQUEST_GET_API_KEYS',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveGetAPIKeysSuccess = data => ({
  type: 'RECEIVE_GET_API_KEYS_SUCCESS',
  data
})

export const receiveGetAPIKeysFail = data => ({
  type: 'RECEIVE_GET_API_KEYS_FAIL',
  data
})

export const tryToGetAPIKeys = packet => (dispatch, getState) => {

  dispatch(requestGetAPIKeys(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  let params = {};
  if(packet.startAfter){
    params.startAfter = packet.startAfter;
  }

  return fetch('/api/projects/api-keys/' + packet.id  + '?' + new URLSearchParams(params), {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveGetAPIKeysSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveGetAPIKeysFail({errors:e, lastSubmit: packet}));
    })
}
