import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect.js'
import Callout from '../../kit/components/Callout/Callout.js'
import Checkbox from '../../kit/components/Checkbox/Checkbox.js'


import {
  tryToJoinWaitlist
} from '../../actions/actions.export'

class WaitlistForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      email: ""
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToJoinWaitlist({
      email: this.state.email
    }))
  }


  render(){

    const { userReducer } = this.props;

    let canSubmit = this.state.email && !this.state.email_error && this.state.agree;

    if(userReducer.joinWaitlistSuccess){
      return <div>
        <h3 className="text-success"><i className="fas fa-check"/> waitlist joined</h3>
        <p>We appreciate your interest and will be in touch.</p>
      </div>
    }

    return <div>
      <form onSubmit={()=>{
        if(canSubmit) this.handleSubmit()
      }}>
        <p>
          If you're interested in early access, drop your email here. We'll reachout when it's time to set up your account.
        </p>

        <CustomField
          name="field_email"
          regex="email"
          label="Email"
          placeholder="email"
          required={true}
          thinking={false}
          disabled={userReducer.tryingToJoinWaitlist}
          value={this.state.email}
          serverError={userReducer.joinWaitlistForm.errors.email}
          lastSubmit={userReducer.joinWaitlistForm.lastSubmit.email}
          onChange={(e) => {
            this.setState({
              email: e.value,
              email_error: e.error
            })
          }}
          />

        <Checkbox 
          value={this.state.agree} 
          onToggle={(v)=>{ this.setState({ agree: v }) }}
          description={<span>
            I have read and accept the <Link to="/docs/policies/terms-of-service" target="_blank" rel="noopener noreferrer">Terms</Link> and <Link to="/docs/policies/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>.
          </span>}
          style="align-left"
          disabled={userReducer.tryingToRegister}
          />

        <div className="spacer-2rem"/>

        <div className="spacer-2rem"/>

        {
          userReducer.joinWaitlistForm.errors.error &&
          <Callout
            style="danger"
            dismissable={true}
            content={userReducer.joinWaitlistForm.errors.error}
          />
        }
        
        <div className="list-right">
          <CustomButton
            display="Join"
            color="success"
            thinking={userReducer.tryingToJoinWaitlist}
            fail={userReducer.joinWaitlistFail}
            success={userReducer.joinWaitlistSuccess}
            disabled={!canSubmit}
            onClick={()=>{
              if(canSubmit) this.handleSubmit()
            }}
            />
        </div>
      </form>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(WaitlistForm);
