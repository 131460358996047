import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import Hydrate from 'components/Hydrate/Hydrate.js'

import CustomButton from 'kit/components/CustomButton/CustomButton.js'

import TabNav from 'kit/components/TabNav/TabNav.js'

import {
  tryToGetOrganizationProfile
} from '../actions/actions.export'
import { push } from 'connected-react-router'

class OrgProfileRoute extends Component {
  componentDidMount(){
    window.document.title = "zerowidth.ai";
  }

  componentWillMount(){
    const { dispatch, org_id } = this.props;

    dispatch(tryToGetOrganizationProfile({
      id: org_id
    }))
  }

  componentWillReceiveProps(nextProps){
    // if we have the org, and it has a public profile, then let's get the projects, flows, and knowledge bases
    if(nextProps.orgReducer.cache[nextProps.org_id] && nextProps.orgReducer.cache[nextProps.org_id].has_public_profile){
      if(nextProps.subpage === "projects"){
        // get projects
        console.log("get projects")
      } else if(nextProps.subpage === "flows"){
        // get flows
        console.log("get flows")
      } else if(nextProps.subpage === "knowledge"){
        // get knowledge bases
        console.log("get knowledge bases")
      }
    }
  }

  render(){
    const { dispatch, userReducer, org_id, subpage, orgReducer } = this.props;

    let org = orgReducer.cache[org_id];

    return <div className={"hf-stretched hf-stretched-noscroll"}>
      <Header borderless={true}/>
      {
        orgReducer.tryingToGetOrganizationProfile ? <div className="body flex-column-center-center">
          <div className="loading"><i className="far fa-spinner-third fa-spin"/></div>
        </div>
        :
        (org && org.has_public_profile) ?
        <div className="body bg-gs95">
          <div className="container">
            <div className="row margin-top-2rem justify-content-center">
              <div className="col-xl-8 col-lg-8 col-md-10 margin-bottom-2rem">
                <div className="box box-no-pad box-no-border overflow-hidden">
                  <div className="padding-2rem">
                    <div className="list-left">
                      <Hydrate id={org_id} type="organization" mode="avatar" size={65}/>
                      <div className="margin-left-2rem">
                        <h2 className="no-margin">{org.display_name}</h2>
                        <div className="list-left">
                          <small>
                            <i className="fas fa-map-marker-alt icon-before-text"/>{org.location}
                          </small>
                          <small>
                            <Link to={org.url} target="_blank" className="link-no-decoration">
                              <i className="fas fa-link icon-before-text"/>{org.url}
                            </Link>
                          </small>
                        </div>
                      </div>
                    </div>
                    <p className="thin-line-height no-margin-bottom">
                      <small>{org.bio}</small>
                    </p>
                  </div>

                  <TabNav
                    value={subpage}
                    items={[
                      {
                        display: "Recent Updates",
                        value: undefined
                      },
                      {
                        display: "Projects",
                        value: "projects"
                      },
                      {
                        display: "Flows",
                        value: "flows"
                      },
                      {
                        display: "Knowledge Bases",
                        value: "knowledge"
                      },
                      // {
                      //   display: "Members",
                      //   value: "members"
                      // }
                    ]}
                    onChange={e => {
                      if(!e){
                        dispatch(push(`/o/${org_id}`));
                      } else {
                        dispatch(push(`/o/${org_id}/${e}`));
                      }
                    }}
                    />
                </div>
              </div>
              <div className="row justify-content-center">
                
                <div className="col-xl-8 col-lg-8 col-md-10">
                  <div className="box">
                    

                    <div className="padding-2rem">
                      stuff
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="body flex-column-center-center">
          
          <p className="margin-bottom-3rem">
            We either can't find this organization, or it's been made private.
          </p>
          <CustomButton
            to={"/"}
            display={"Go Home"}
            color="black"
            size="small"
            />
        </div>
        }
      <div className="bg-gs95">
        <Footer simple={true}/>
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, guiReducer, orgReducer } = state;

  return {
    userReducer,
    orgReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(OrgProfileRoute);

  