import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Edit content on a component's draft
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestSaveComponentContent = packet => {
  return {
    type: 'REQUEST_SAVE_COMPONENT_CONTENT',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveSaveComponentContentSuccess = data => ({
  type: 'RECEIVE_SAVE_COMPONENT_CONTENT_SUCCESS',
  data
})

export const receiveSaveComponentContentFail = data => ({
  type: 'RECEIVE_SAVE_COMPONENT_CONTENT_FAIL',
  data
})

export const tryToSaveComponentContent = packet => (dispatch, getState) => {

  dispatch(requestSaveComponentContent(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/components/draft/content/' + packet.component_id, {
      method: 'put',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveSaveComponentContentSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveSaveComponentContentFail({errors:e, lastSubmit: packet}));
    })
}
