
import {
  updateComponentFromSocket,
  bulkUpdateComponentFromSocket
} from '../actions/actions.export'

const handleUpdateRoom = (dispatch, getState) => {
  return (data) => {
    // console.log(data);

    if(data.user_id === getState().userReducer.myData.id){
      // console.log("BLOCKING SELF UPDATE");
      return;
    } 

    // split data.room into type and id
    const [type, id] = data.room.split('-');
    
    switch(type){
      case 'components':
        // console.log('still made it here');
        dispatch(bulkUpdateComponentFromSocket({
          id: id,
          events: data.events
        }));
        // for(var event of data.events){
        //   dispatch(updateComponentFromSocket({
        //     id: id,
        //     event: event
        //   }));
        // }
        break;
      default:
        console.warn('Unhandled updateRoom type:', type);
    }
    
    // dispatch(updateComponentFromSocket(data));
  }
}

export default handleUpdateRoom;