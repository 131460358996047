import Cookies from 'js-cookie';
import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from './a.users.forceLogoutHandler';


////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// GET LOG
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestGetUserLog = packet => {
  return {
    type: 'REQUEST_GET_USER_LOG',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveGetUserLogSuccess = data => ({
  type: 'RECEIVE_GET_USER_LOG_SUCCESS',
  data
})

export const receiveGetUserLogFail = data => ({
  type: 'RECEIVE_GET_USER_LOG_FAIL',
  data
})

export const tryToGetUserLog = packet => (dispatch, getState) => {

  dispatch(requestGetUserLog(packet));


  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/users/log?' + new URLSearchParams(packet), {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveGetUserLogSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveGetUserLogFail({errors:e, lastSubmit: packet}));
    })
}
