import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history';

// import monitorReducersEnhancer from './enhancers/monitorReducers'
// import loggerMiddleware from './middleware/logger'
import createRootReducer from './reducers/reducers.root'

export let history = createBrowserHistory()

export function configureStore(preloadedState) {
  let middlewares = [thunkMiddleware]

  // If we aren't in production add a logger to the middleware array
  if(process.env.NODE_ENV !== 'production'){
    // log everything except redux-form
    middlewares.push(createLogger({
      predicate: (getState, action) => action.type.indexOf('@@redux-form') === -1
    }));
  }

  const middlewareEnhancer = applyMiddleware(...middlewares, routerMiddleware)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = compose(...enhancers)


  const store = createStore(createRootReducer(history), applyMiddleware(...middlewares, routerMiddleware(history)))

  return store;
}