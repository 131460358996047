import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Add content to a component's draft
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestAddContentToComponent = packet => {
  return {
    type: 'REQUEST_ADD_CONTENT_TO_COMPONENT',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveAddContentToComponentSuccess = data => ({
  type: 'RECEIVE_ADD_CONTENT_TO_COMPONENT_SUCCESS',
  data
})

export const receiveAddContentToComponentFail = data => ({
  type: 'RECEIVE_ADD_CONTENT_TO_COMPONENT_FAIL',
  data
})

export const tryToAddContentToComponent = packet => (dispatch, getState) => {

  dispatch(requestAddContentToComponent(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/components/draft/content/' + packet.id, {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveAddContentToComponentSuccess(json));
      if(packet.noNav) return;
      // determine the pathname after packet.type 
      dispatch(push(window.location.pathname + '/' + json.content.data.id));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveAddContentToComponentFail({errors:e, lastSubmit: packet}));
    })
}
