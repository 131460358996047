import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import {
  passProfileProjectsToCache
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// GET ORGANIZATION PROFILE
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestGetOrganizationProfile = packet => {
  return {
    type: 'REQUEST_GET_ORGANIZATION_PROFILE',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveGetOrganizationProfileSuccess = data => ({
  type: 'RECEIVE_GET_ORGANIZATION_PROFILE_SUCCESS',
  data
})

export const receiveGetOrganizationProfileFail = data => ({
  type: 'RECEIVE_GET_ORGANIZATION_PROFILE_FAIL',
  data
})

export const tryToGetOrganizationProfile = packet => (dispatch, getState) => {

  dispatch(requestGetOrganizationProfile(packet));


  let headers = {
    "Content-Type": "application/json",
  }

  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/organizations/profile?id=' + packet.id, {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveGetOrganizationProfileSuccess(json));
      dispatch(passProfileProjectsToCache(json.projects));
    })
    .catch(e => {
      dispatch(receiveGetOrganizationProfileFail({errors:e, lastSubmit: packet}));
      if(window.location.pathname.indexOf('/oid/' + packet.id) === 0){
        dispatch(push('/oops'));
      }
    })
}
