import Cookies from 'js-cookie';

// function for creating new state and saving the cookie
// this is used in the guiReducer

const saveGuiSettings = (state, data) => {
  let newState = {
    ...state,
    ...data
  }
  Cookies.set('gui_settings', window.btoa(JSON.stringify(newState)));
  return newState
}


export const guiReducer = (state = {
  collapsedSideTray: true,
  collapsedKindTools: true,
  kindFilters: [],
  kindSort: 'last_updated',
  draggingGUIState: false
}, action) => {

  switch(action.type){

    case 'FLOW_ZOOM_TO_FIT':
      return {
        ...state,
        flowZoomToFit: new Date().getTime()
      }

    case 'SET_DRAGGING_GUI_STATE':
      
      return {
        ...state,
        draggingGUIState: action.data
      }

    case 'TOGGLE_KIND_TOOLS':

      return saveGuiSettings(state, {
        collapsedKindTools: action.data === undefined ? !state.collapsedKindTools : action.data
      })

    case 'TOGGLE_SIDE_TRAY':
      
      return saveGuiSettings(state, {
        collapsedSideTray: action.data === undefined ? !state.collapsedSideTray : action.data
      })

    case 'SET_KIND_FILTERS':
        
      return saveGuiSettings(state, {
        kindFilters: action.data
      })

    case 'SET_KIND_SORT':
      
      return saveGuiSettings(state, {
        kindSort: action.data
      })

    default:

      // check if gui settings are in a cookie
      let cookie = Cookies.get('gui_settings');
      if(cookie){
        let settings = JSON.parse(window.atob(cookie));
        return {
          ...state,
          ...settings
        }
      }

      return state;
  }
}
