import agents from '../docs_md/DocsAgents.js';
import knowledge from '../docs_md/DocsKnowledge.js';
import endpoint from '../docs_md/DocsEndpoint.js';

const doc_config = [
  require('../docs_md/DocsOverview.js'),
  // agents,
  // knowledge,
  // endpoint,
  require('../docs_md/DocsAPI.js'),
  require('../docs_md/DocsPolicies.js'),
]


export default doc_config