import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import './Header.scss';

import CustomButton from '../../kit/components/CustomButton/CustomButton'
import Dropdown from '../../kit/components/Dropdown/Dropdown'

import Hydrate from '../../components/Hydrate/Hydrate'

import isFeatureSwitchOn from '../../utilities/isFeatureSwitchOn'

import projectTools from '../../configs/config.project-tools'
import componentTools from '../../configs/config.component-tools'

import SketchManager from '../../components/SketchManager/SketchManager.js'
import ZeroHeader from '../../sketches/ZeroHeader.js'

import {
  tryToLogout,
} from '../../actions/actions.export'


class Header extends Component {

  constructor(props){
    super(props);

    this.state = {
      currentTool: false,
      subTool: false,
      search: ""
    }
  }

  static getDerivedStateFromProps(newProps, prevState){
    let currentTool;
    let subTool;
    if(window.location.pathname.indexOf("/project/") === 0 || window.location.pathname.indexOf("/component/") === 0){

      currentTool = "home";
      if(window.location.pathname.indexOf("/") > -1){
        currentTool = window.location.pathname.split("/")[3];
        subTool = window.location.pathname.split("/")[4];
      }
    }

    let mode;
    if(window.location.pathname.indexOf("/project/") === 0){
      mode = 'project'
    }
    if(window.location.pathname.indexOf("/component/") === 0){
      mode = 'component'
    }

    return {
      ...prevState,
      mode: mode,
      currentTool: currentTool,
      subTool: subTool
    }
  }
  
  render(){

    const { dispatch, alfred, userReducer, projectReducer, featuresReducer, orgReducer, guiReducer, componentReducer } = this.props;

    let isLoading = false;

    for(var i in userReducer){
      if(i.indexOf('tryingTo') === 0 && userReducer[i] === true){
        isLoading = true;
        break;
      }
    }
    if(!isLoading){
      for(var i in orgReducer){
        if(i.indexOf('tryingTo') === 0 && orgReducer[i] === true){
          isLoading = true;
          break;
        }
      }
    }

    if(!isLoading){
      for(var i in featuresReducer){
        if(i.indexOf('tryingTo') === 0 && featuresReducer[i] === true){
          isLoading = true;
          break;
        }
      }
    }

    if(!isLoading){
      for(var i in projectReducer){
        if(i.indexOf('tryingTo') === 0 && projectReducer[i] === true){
          isLoading = true;
          break;
        }
      }
    }


    if(!isLoading){
      for(var i in componentReducer){
        if(i.indexOf('tryingTo') === 0 && componentReducer[i] === true){
          isLoading = true;
          break;
        }
      }
    }


    let currentProject = projectReducer.cache[projectReducer.current];
    let currentComponent = componentReducer.cache[componentReducer.current];
    let currentComponentDraft;

    let currentTool;
    let subTool;

    if(currentProject){
      if(this.state.currentTool){
        currentTool = projectTools.find(t => t.name === this.state.currentTool);
      }

      if(currentTool && this.state.subTool){
        subTool = currentTool.subpages.find(m => m.name === this.state.subTool);
      }
    }
    if(currentComponent){
      if(currentComponent.current_version === 'draft'){
        currentComponentDraft = (currentComponent.versions || []).find(v => v.id === 'draft');
      }
      if(!currentComponentDraft) currentComponentDraft = {};

      if(this.state.currentTool){
        currentTool = componentTools.find(t => t.name === this.state.currentTool);
      }

      if(currentTool && this.state.subTool){
        if(currentTool.subpages){
          subTool = currentTool.subpages.find(m => m.name === this.state.subTool);
        } else if(currentTool.dynamicSubpages){
          let menu = currentTool.getSubpageItemData(currentComponent).filter(m => m);
          subTool = menu.find(m => m.id === this.state.subTool);
        }
      } 
    }

    let sideTrayStatus;

    let classList = "header ";
    if(userReducer.isLoggedIn){
      classList += " header-logged-in";
    } else {
      classList += " header-logged-out";
    }

    if(window.location.pathname === '/' && !userReducer.isLoggedIn){
      classList += " header-landing";
    }
    if(this.props.borderless) classList += " header-borderless";
    if(this.props.whiteBackground) classList += " header-white-background";
    if(this.props.blackBackground) classList += " header-black-background";
    if(this.props.whiteDropShadow) classList += " header-white-drop-shadow";
    
    if(window.location.pathname.indexOf("/project/") === 0){
      if(currentProject) classList += " header-project-mode header-obj-mode";

      if(guiReducer.sideTray === 'open' && (currentProject)) classList += " header-side-tray-open";
      if(guiReducer.sideTray === 'docked' && (currentProject)) classList += " header-side-tray-docked";
    } else if(window.location.pathname.indexOf("/component/") === 0){
      if(currentComponent) classList += " header-component-mode header-obj-mode";
      currentProject = undefined;

      if(guiReducer.sideTray === 'open' && (currentComponent || currentProject)) classList += " header-side-tray-open";
      if(guiReducer.sideTray === 'docked' && (currentComponent || currentProject)) classList += " header-side-tray-docked";
    } else {
      currentProject = undefined;
      currentComponent = undefined;
    }

    return <div className={classList}>

      {
        userReducer.isLoggedIn ? 
      
        <div className={"header-inner header-inner-logged-in " + (isLoading ? "header-inner-logged-in-loading" : "")}>
          <div className="header-left">
            
            <Link to={"/"} className="header-brand-logo">
              <img src={this.props.blackBackground ? "/img/cascading-z-white.png" : "/img/cascading-z.png"} width="33"/>
            </Link>

            <Link to={"/"} className="header-brand-text d-none d-md-block">
              <span className="">ZeroWidth</span>
            </Link>

            {
              window.location. pathname === '/' &&
              <Link to={"/#for-who"} className="d-none d-lg-block margin-left-2rem">
                <span className="text-400">For Strategists</span>
              </Link>
              }

            {
              window.location. pathname === '/' &&
              <Link to={"/#for-who"} className="d-none text-400 d-lg-block margin-left-2rem">
                <span className="text-400">For Designers</span>
              </Link>
            }

            {
              window.location. pathname === '/' &&
              <Link to={"/#for-who"} className="d-none text-400 d-lg-block margin-left-2rem">
                <span className="text-400">For Developers</span>
              </Link>
            }
          
          </div>

          {
            featuresReducer.ready &&
            <div className="header-right d-md-none">
             
              {
                (window.location.pathname.indexOf('/workbench') !== 0 && userReducer.isLoggedIn) &&

                <CustomButton
                  display={<span>Open Workbench<small className="far fa-external-link icon-after-text"/></span>}
                  toBlank={"/workbench"}
                  size="small"
                  color="black"
                  />
              }

              <Dropdown
                target={<div className="dropdown-toggle-default list-right list-right-marginless">
                    <div className={userReducer.socketConnected ? "avatar-online" : "avatar-offline"}>
                      <Hydrate
                        type="me"
                        mode="avatar"
                        />
                    </div>
                    &nbsp;
                    <i className="fas fa-angle-down fa-fw"/>
                </div>}
                align="right"
                items={[
                    <Link to={"/"}>
                      <i className="far fa-fw fa-house-user icon-before-text"/> Home
                    </Link>,
                    <Link to={"/workbench/my-account/settings/notifications"}>
                      <div className="flex-split">
                        <span><i className="far fa-fw fa-bell icon-before-text"/> Notifications</span>
                        {userReducer.notifications.filter(n => !n.read).length > 0 && <small className="">({userReducer.notifications.filter(n => !n.read).length})</small>}
                      </div>
                    </Link>,
                    <Link to="/workbench/my-account/settings">
                      <i className="far fa-fw fa-user-cog icon-before-text"/> Account Settings
                    </Link>,
                    // orgReducer.memberships.length > 0 && "divider",
                
                    // orgReducer.memberships.filter(m => !m.organization.personal).map((m, i) => {
                    //   return {
                    //     content: <Link to={"/oid/" + m.organization.id} key={i}>
                    //       <div className="list-left">
                    //         <Hydrate size={25} type="organization" mode="avatar" id={m.organization.id}/>
                    //         <nobr>{m.organization.display_name}</nobr>
                    //       </div>
                    //     </Link>,
                    //     disabled: m.organization.hide_profile
                    //   }
                    // }),

                    // isFeatureSwitchOn('create_organization', userReducer, featuresReducer) &&
                    // <Link to={"/new/organization"}>
                    //   <i className="far fa-fw fa-users icon-before-text"/> New Organization
                    // </Link>,


                    "divider",

                    <Link to={"/docs"}>
                      <i className="far fa-fw fa-book icon-before-text"/> Docs
                    </Link>,

                    
                    isFeatureSwitchOn('blog',userReducer,featuresReducer) ?
                    <Link to={"/blog"}>
                      <i className="far fa-fw fa-typewriter icon-before-text"/> Blog
                    </Link>
                    :
                    undefined
                    ,

                    // <Link to={"/help"}>
                    //   <i className="far fa-fw fa-info-circle icon-before-text"/> Help Center
                    // </Link>, 
                    "divider",
                    <span onClick={() => { dispatch(tryToLogout()) }}>
                      <i className="far fa-fw fa-door-closed icon-before-text"/> Log Out
                    </span>
                  ]}
              />
            </div>
          }

          {
            (featuresReducer.ready) &&
            <div className="header-right d-none d-md-flex">
              <Link to="/docs" className="d-none d-lg-block margin-right-1rem text-400 header-link">Docs</Link>
              {
                isFeatureSwitchOn('blog',userReducer,featuresReducer) &&
                <Link to="/blog" className="d-none d-lg-block margin-right-1rem text-400">Blog</Link>
              }
              {
                (window.location.pathname.indexOf('/workbench') !== 0 && userReducer.isLoggedIn) &&
                <CustomButton
                  display={<span>Open Workbench<small className="far fa-external-link icon-after-text"/></span>}
                  toBlank={"/workbench"}
                  size="small"
                  color="black"
                  />
              }
             
              
              <Link to="/workbench/my-account/settings/notifications">
                {
                  userReducer.notifications.filter(n => !n.read).length > 0 ? 
                  <i className="far fa-fw fa-bell-on text-danger"/>
                  : 
                  <i className="far fa-fw fa-bell"/>
                }
              </Link>
              <span className="text-very-muted">|</span>
              <Dropdown
                target={<div className="dropdown-toggle-default list-right list-right-marginless">
                    <div className={userReducer.socketConnected ? "avatar-online" : "avatar-offline"}>
                      <Hydrate
                        type="me"
                        mode="avatar"
                        />
                    </div>
                    &nbsp;
                    <i className="fas fa-angle-down fa-fw"/>
                </div>}
                align="right"
                items={[
                   <Link to={"/"}>
                      <i className="far fa-fw fa-house-user icon-before-text"/> Home
                    </Link>,
                    <Link to={"/workbench/my-account/settings/notifications"}>
                      <i className="far fa-fw fa-bell icon-before-text"/> Notifications
                    </Link>,
                    <Link to="/workbench/my-account/settings">
                      <i className="far fa-fw fa-user-cog icon-before-text"/> Account Settings
                    </Link>,
                    "divider",
                    <Link to={"/docs"}>
                      <i className="far fa-fw fa-book icon-before-text"/> Docs
                    </Link>,

                    isFeatureSwitchOn('blog',userReducer,featuresReducer) ?
                    <Link to={"/blog"}>
                      <i className="far fa-fw fa-typewriter icon-before-text"/> Blog
                    </Link>
                    :
                    undefined,
                    // <Link to={"/help"}>
                    //   <i className="far fa-fw fa-info-circle icon-before-text"/> Help Center
                    // </Link>,
                    
                    "divider",
                    <span onClick={() => { dispatch(tryToLogout()) }}>
                      <i className="far fa-fw fa-door-closed icon-before-text"/> Log Out
                    </span>
                  ]}
              />
            </div>
          }
        </div>
        :
        <div className={"header-inner header-inner-logged-out " +  (isLoading ? "header-inner-logged-out-loading" : "")}>

            <div className="header-left">
              
              <Link to={"/"} className="header-brand-logo">
                <img src={this.props.blackBackground ? "/img/cascading-z-white.png" : "/img/cascading-z.png"} width="33px"/>
              </Link>
            
              <Link to={"/"} className="header-brand-text d-none d-md-block">
                <span className="text-">ZeroWidth</span>
              </Link>

              {
                window.location. pathname === '/' &&
                <Link to={"/#for-who"} className="d-none d-lg-block margin-left-2rem">
                  <span className="text-400">For Strategists</span>
                </Link>
                }

              {
                window.location. pathname === '/' &&
                <Link to={"/#for-who"} className="d-none text-400 d-lg-block margin-left-2rem">
                  <span className="text-400">For Designers</span>
                </Link>
              }

              {
                window.location. pathname === '/' &&
                <Link to={"/#for-who"} className="d-none text-400 d-lg-block margin-left-2rem">
                  <span className="text-400">For Developers</span>
                </Link>
              }

            </div>

          <div className="header-right">
            {/* <span className="d-none d-md-block margin-right-2rem text-400 clickable text-hover-primary">
              <Dropdown 
                target={<span>Explain ZeroWidth like I'm...<i className="far fa-angle-down icon-after-text"/></span>}
                items={[
                  <Link to="/explained/business-lead">a business leader</Link>,
                  <Link to="/explained/designer">a designer</Link>,
                  <Link to="/explained/developer">a developer</Link>,
                  <Link to="/explained/5">a five year old</Link>,
                ]}
                />
            </span> */}
            <Link to="/docs" className="d-none d-lg-block margin-right-2rem text-400">Docs</Link>
            {
              (isFeatureSwitchOn('blog',userReducer,featuresReducer)) &&
              <Link to="/blog" className="d-none d-lg-block margin-right-2rem text-400">Blog</Link>
            }
            <Link to="/sign-in" className="margin-right-1rem text-400">Sign In</Link>
            {
              (!isFeatureSwitchOn('registration', userReducer, featuresReducer) && isFeatureSwitchOn('waitlist', userReducer, featuresReducer)) &&
              <CustomButton
                display={<span>Join Waitlist</span>}
                size="small"
                color=""
                to="/waitlist"
              />
            }
            {
              isFeatureSwitchOn('registration', userReducer, featuresReducer) &&
              <CustomButton
                display="Sign Up"
                size="small"
                color="black"
                to="/sign-up"
              />
            }
            
          </div>
        </div>
      }
    </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, projectReducer, componentReducer, featuresReducer, orgReducer, guiReducer, router } = state;

  return {
    userReducer,
    projectReducer,
    componentReducer,
    featuresReducer,
    orgReducer,
    guiReducer,
    router
  }
}

export default connect(mapStateToProps)(Header);
