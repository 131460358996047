import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomField from 'kit/components/CustomField/CustomField'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'

export const NodeSettings_key_value_maker = ({ node, setNodeSettings, canWrite }) => {

  const [settings, setSettings] = useState(node.settings || {});

  if (!node.settings) {
    return null;
  }

  return (
    <div>
      <CustomField
        value={node.settings.key}
        placeholder="Enter a valid key here"
        description="The key to be used in the key value pair"
        disabled={!canWrite}
        label="Key"
        onChange={(e)=>{
          setNodeSettings({
            key: e.value
          })
        }}
        />
    </div>
  );
}


export const NodeRenderer_key_value_maker = ({ node, type }) => {

  return (
    <div className="padding-1rem thin-line-height text-center">
      <div className="text-900 text-monospace">
        {node.settings.key || 'key'}
      </div>
    </div>
  );
}