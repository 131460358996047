import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './CustomButton.css';

class CustomButton extends Component {
  _isMounted = false;

  constructor(props){
    super(props);

    this.state = {
      lastClicked: 0,
      clickCounter: 0,
      lastSuccess: new Date().getTime(),
      lastFail: new Date().getTime()
    }

    this.handleClick = this.handleClick.bind(this);
  }


  handleClick(e){
    if(!this.props.to && !this.props.toBlank) e.preventDefault();

    if(this.props.disabled) return;
    if(this.props.thinking) return;
    if(this.state.success) return;

    // If onClick is set
    if(this.props.onClick){

      // If we are debouncing clicks
      if(this.props.debounce){

        // Make sure enough time has passed since last click attempt
        let now = new Date().getTime();
        if(now - this.state.lastClicked > this.props.debounce){

          // Call function
          this.props.onClick(this.state.clickCounter + 1);

          // Increment click counter
          this.setState({
            clickCounter: this.state.clickCounter + 1
          })
        }
        this.setState({
          lastClicked: now
        });
      } else {
        // Call function
        this.props.onClick(this.state.clickCounter + 1);

        // Increment click counter
        this.setState({
          clickCounter: this.state.clickCounter + 1
        })

      }
    }
  } 

  componentDidMount(){
    this._isMounted = true;
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  componentDidUpdate(){

    if(this.props.success - this.state.lastSuccess > 1000){
      this.setState({
        lastSuccess: this.props.success,
        success: true
      })

      setTimeout(() => {
        if(this._isMounted){
          this.setState({
            success: false
          })
        }
      }, 1500)
    }


    if(this.props.fail - this.state.lastFail > 1000){
      this.setState({
        lastFail: this.props.fail,
        fail: true
      })

      setTimeout(() => {
        if(this._isMounted){
          this.setState({
            fail: false
          })
        }
      }, 1500)
    }

  }

  render(){    
    let classList = "";

    if(this.props.color) classList += " button-" + this.props.color;
    if(this.props.block) classList += " button-block";
    if(this.props.size) classList += " button-" + this.props.size;
    if(this.props.inlineForm) classList += " button-inline-form";
    if(this.props.noRadiusLeft) classList += " button-no-radius-left";
    if(this.props.noRadiusRight) classList += " button-no-radius-right";
    if(this.props.noMargin) classList += " no-margin-bottom";

    let display_options = this.props.displayOptions;
    if(!display_options) display_options = [this.props.display];

    let button = <button 
      className={"button " + classList} disabled={this.props.disabled || this.props.thinking} 
      onClick={this.handleClick}
      onMouseEnter={e => {
        if(this.props.onMouseEnter) this.props.onMouseEnter(e);
      }}
      onMouseLeave={e => {
        if(this.props.onMouseLeave) this.props.onMouseLeave(e);
      }}
      >
      <div className="button-inner">
        <div className={"button-inner-display " + (this.props.thinking ? " button-inner-display-hidden" : "") + (this.state.success ? " button-inner-display-hidden" : "") + (this.state.fail ? " fail-shaking-no" : "")}>
          { this.props.display }
        </div>

        <div className="button-inner-display-possibilities">
          {
            display_options.map((n, i)=>{
              return <span key={i}>{n}</span>
            })
          }
        </div>

        <div className={"button-inner-absolute " + ((this.props.thinking && !this.state.success) ? "button-inner-absolute-show" : "")}>
          <i className="fad fa-spinner-third fa-spin"/>
        </div>

        <div className={"button-inner-absolute " + (this.state.success ? "button-inner-absolute-show" : "")}>
          <i className="fas fa-check"/>
        </div>
      </div>
    </button>


    if(this.props.to && !this.props.disabled){
      return <Link to={this.props.to} className="button-link">{button}</Link>
    } else if(this.props.toBlank && !this.props.disabled){
      return <a href={this.props.toBlank} target="_blank" rel="noopener noreferrer" className="button-link">{button}</a>
    } else {
      return button;
    }

  }
};

export default CustomButton;