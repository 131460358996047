import Cookies from 'js-cookie';
import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from './a.users.forceLogoutHandler';

// import {
  
// } from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Edit User Profile Visibility
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestEditUserProfileVisibility = packet => {
  return {
    type: 'REQUEST_EDIT_USER_PROFILE_VISIBILITY',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveEditUserProfileVisibilitySuccess = data => ({
  type: 'RECEIVE_EDIT_USER_PROFILE_VISIBILITY_SUCCESS',
  data
})

export const receiveEditUserProfileVisibilityFail = data => ({
  type: 'RECEIVE_EDIT_USER_PROFILE_VISIBILITY_FAIL',
  data
})

export const tryToEditUserProfileVisibility = packet => (dispatch, getState) => {

  dispatch(requestEditUserProfileVisibility(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/users/hide-profile', {
      method: 'put',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveEditUserProfileVisibilitySuccess(json.data));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveEditUserProfileVisibilityFail({errors:e, lastSubmit: packet}));
    })
}
