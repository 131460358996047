import { addToast } from '../actions/actions.export.js';

var Cookies = require('js-cookie');

const addCookieWithConsent = (name, value, dispatch, days, acceptedTerms, after) => {

  // console.log('addCookieWithConsent', name, value, days, acceptedTerms, after);
  
  var cookieConsent = Cookies.get('cookieToastDismissed') || acceptedTerms;
  var authed = Cookies.get(process.env.REACT_APP_COOKIE_AUTH);

  if(name === process.env.REACT_APP_COOKIE_AUTH || authed || acceptedTerms){
    Cookies.set('cookieToastDismissed', true);
    cookieConsent = true;
  }

  if(cookieConsent){
    Cookies.remove(name);
    Cookies.set(name, value, { expires: days || 7 });
    console.log('cookie set');
    return true;
  } else if(dispatch){
    dispatch(addToast({
      id: 'cookieConsent',
      title: "We use cookies 🍪",
      detail: "To keep track of your account and measure use of our site, we store a few cookies in your browser.",
      func: 'cookieToastDismissed',
      buttons: [
        {
          display: "Accept",
          dismissOnClick: true,
          onClick: () => {
            Cookies.set('cookieToastDismissed', true);
            Cookies.set(name, value, { expires: days || 7 });
            if(after) after();
          }
        },
        {
          display: "Privacy Policy",
          destinationToBlank: '/docs/policies/privacy#cookies'
        }
      ]
    }))
  }
  return false;
}

export default addCookieWithConsent;