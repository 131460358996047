module.exports = {
  name: 'api',
  display_name: 'API Guide & Reference',
  children: [
    {
      name: 'authentication',
      display_name: 'Authentication',
      content: 
`
## Overview
To ensure secure and personalized access to the api.zerowidth.ai, all API calls must be authenticated using secret keys.

As a user, you have the autonomy to create and manage secret keys for each Project you create on ZeroWidth. This system ensures an added layer of security, as each key is exclusive to a specific app.

- Keep your secret keys confidential to protect against unauthorized access.
- Regenerate keys through your Project's settings on the ZeroWidth Workbench if you suspect a compromise.
- Always use secure connections (HTTPS) to safeguard your API communications from potential eavesdropping.

Ensuring the correct key is used for each app is critical. A missing or incorrect key in your API request will return a \`401\` error response, signaling authentication issues.


## How to Use Secret Keys

After creating a Secret Key for on the ZeroWidth platform, the Secret Key needs to be included in the headers of all API requests using the “Authorization” field.

All Secret Keys start with \`sk0w-\`, followed by a unique alphanumeric string. Here's an example of how to include the Secret Key in the headers of an API request:

\`\`\`javascript
"Authorization": "Bearer sk0w-XXXXXXXXXXXXXXXXXX" // Replace with your Secret Key
\`\`\`
`
    },
    {
      name: 'api-versions',
      display_name: 'API Versions',
      content: 
`
## Overview

ZeroWidth API is versioned to ensure that changes to the API do not break existing client applications. When a new version of the API is released, existing applications can continue to use the old version of the API, while new applications can use the new version, until they are ready to upgrade.

Prior to deprecation of an API version, ZeroWidth will provide a deprecation notice to all users of the API version and 3 months notice before an API version is deprecated.

## Versioning Scheme

The API version is specified in the URL path of the API request. The current version of the API is \`beta\`. 

Here's an example of how to specify the \`beta\` API version in the URL path:

\`\`\`javascript
https://api.zerowidth.ai/beta/process/{endpoint_id}/{agent_id}
\`\`\`


Future versions will follow the same pattern, with simple versioning used to indicate the version number once the API is out of beta, for example:

\`\`\`javascript
https://api.zerowidth.ai/v1/process/{endpoint_id}/{agent_id}
\`\`\`
`
    },
    {
      name: 'content-types',
      display_name: 'Content Types',
      content: 
`
## JSON in, JSON out
The ZeroWidth API always expects to receive and return data in JSON format. 

This means that the \`Content-Type\` and \`Accept\` headers of all API requests and responses should be set to \`application/json\`.

In the case of a successful API request, the response will be a JSON object containing the data you requested. If the request fails, the response will be a JSON object containing an error message.

Here is an example of a simple JSON request body:
\`\`\`json
{
  "data": {
    "messages": [
      {
        "role": "user",
        "content": "Hello, world!"
      }
    ]
  }
}
\`\`\`

and an example JSON response body:
\`\`\`json
{
  "agent": {
      "id": "agent_id_here",
      "version": "current_version"
  },
  "endpoint": {
      "id": "endpoint_id_here",
      "sanikey": "sk0w-...XXXX"
  },
  "input_data": {
      "messages": [
          {
              "content": "Hello, world!",
              "role": "user"
          }
      ],
      "stateful": false
  },
  "output_data": {
      "content": "Hello! How can I assist you today?",
      "id": "OMWEhkBXC",
      "role": "agent",
      "timestamp": "2024-02-14T20:52:24.382Z",
      "usage": {
          "completion_tokens": 9,
          "prompt_tokens": 11,
          "total_tokens": 20
      }
  }
}
\`\`\`
`
    },
    {
      name: 'rate-limits-and-speed',
      display_name: 'Rate Limits & Speed',
      content: 
`
## Beta Rate Limits
While in beta, each individual configuration of an Agent in an Project has a standard rate limit of 10 requests per minute.

This rate limit is subject to change as the API matures and is not guaranteed to remain the same.

If your needs exceed the rate limit, please contact ZeroWidth support to discuss your requirements.

## Rate Limit Headers
When you make a request to the ZeroWidth API, the response will include headers that indicate the current rate limit status.

Here are the headers you can expect to see in the response:
| Header | Description | Example |
| ------ | ----------- | ------- |
| RateLimit-Policy | A simple description of the total number of calls per window size (in seconds) | 10;w=60 |
| RateLimit | A more detailed list of the limit, your number remaining, and time until the window resets | limit=10, remaining=7, reset=42 |


Using these headers, you can monitor your rate limit usage and adjust your application's behavior accordingly. If you exceed the rate limit, the API will return a \`429\` error response. You should then reference the headers and wait until the window resets before making more requests.


## Understanding Response Speed
The response speed of an agent is dependent on a number of factors, including:
- the complexity of the agent
- the complexity of the current task its been given to complete
- the amount of data being processed
- the number of tokens being generated
- the current load on the ZeroWidth platform.

As agents get more complex, requiring multiple stages of LLM processing, the response time will increase. This is especially true in scenarios where the agent is tasked with generating a large amount of text in a single response.

If you are finding that the response time of an agent is too slow for your needs, please contact ZeroWidth support to discuss your requirements.
`
    },
    {
      name: 'streaming-events',
      display_name: 'Streaming Events',
      content:
`
## What is Streaming?

As an Agent designed on ZeroWidth becomes more complex, the amount of data and the number of steps required to process that data can increase. This can lead to longer response times, especially when the agent is tasked with generating a large amount of text in a single response.

Additionally, debugging and monitoring the progress of an agent can be difficult when the agent is capable of calling functions, looking through databases, summarizing context, and more.

To address these issues, we've built a streaming feature that allows you to monitor the progress of an agent as it processes data and generates a response.

## How to Stream
On the standard API processing endpoint, you can add a \`stream\` field to the request body to enable streaming. When streaming is enabled, the API will return the main events in sequence as the agent processes the request.

These are sent as Server-Sent Events (SSE), which are a standard for sending events from a server to a client. The client can listen to these events and receive updates as they occur, using the updates to provide transparency in their application, debug the agent, or monitor the agent's progress.


Example request with streaming enabled:
\`\`\`json
{
  "data": {
    "messages": [
      {
        "role": "user",
        "content": "Hello, world!"
      }
    ],
    "number": 1
  },
  "stream": true
}
\`\`\`

Each step of the Agent's chain will be sent as a separate SSE, with a final completed object sent when the agent has finished processing the request.

Example \`progress\` event:
\`\`\`json
{
  "id": "65ece8d5-214d-4a8b-b9d6-8e3fab00fa28",
  "timestamp": "2024-02-14T21:09:54.196Z",
  "event": "prompt_llm",
  "purpose": "Generate output content.",
  "category": "generate_output",
  "data": {
      "model": "gpt-4-turbo-preview",
      "input_data": {
          "messages": [
              {
                  "role": "user",
                  "content": "Hello, world!",
                  "tokens": 8
              }
          ]
      },
      "output_data": {
          "id": "a9EOhuCLQ",
          "content": "Hello! How can I assist you today?",
          "role": "agent",
          "timestamp": "2024-02-14T21:09:54.196Z",
          "usage": {
              "prompt_tokens": 11,
              "completion_tokens": 9,
              "total_tokens": 20
          }
      }
  }
}
\`\`\`

This continues for each step of the agent's chain, with each step being sent as a separate \`progress\` event.

Followed by a final \`completed\` event:
\`\`\`json
{
  "agent": {
      "id": "agent_id_here",
      "version": "current_version"
  },
  "endpoint": {
      "id": "endpoint_id_here",
      "sanikey": "sk0w-...XXXX"
  },
  "input_data": {
      "messages": [
          {
              "content": "Hello, world!",
              "role": "user"
          }
      ],
      "stateful": false
  },
  "output_data": {
      "content": "Hello! How can I assist you today?",
      "id": "a9EOhuCLQ",
      "role": "agent",
      "timestamp": "2024-03-14T21:09:54.196Z",
      "usage": {
          "completion_tokens": 9,
          "prompt_tokens": 11,
          "total_tokens": 20
      }
  }
}
\`\`\`

### What get's streamed?
With streaming disabled, you will only receive the final response from the agent - unless you've enabled \`verbose\` mode. If you've enabled verbose mode, you will receive a detailed breakdown of the agent's processing steps in the response. 

You can learn more about understanding an agent's chain in the [Agent chain](/docs/api/agent-chain) documentation.

With streaming enabled, you will receive one \`progress\` event for each step of the agent's chain, followed by a final \`completed\` event.

### What doesn't get streamed?
Enabling streaming does not result in a per-token stream-events being sent back. For steps in an Agent's chain that involve prompting an LLM, the tokens generated by the LLM are not streamed back to the client. These generations are done in full before moving onto the next step.

This is primarily due to the variety of formats, structured data, and intricate relationships between the moving parts of an agent's chain. Often many of these parts are not relevant to the client, and the client is only interested in the final response.
`
    },
    {
      name: 'calling-an-agent',
      display_name: 'Calling an Agent',
      content: 
`## Endpoint Format
Once you've installed an agent in an Project, you can use the ZeroWidth API to call the agent and get a response.

To call an agent, you need to make a \`POST\` request to the agent's endpoint URL. The request body should include the input data for the agent to process.

Projects can have multiple agents, each with its own endpoint URL. You can find the endpoint URL for a specific agent in the ZeroWidth Workbench, but they all follow the same pattern:

\`\`\`javascript
https://api.zerowidth.ai/beta/process/{endpoint_id}/{agent_id}
\`\`\`

## Formatting the Request Body
All agents use the same general request body format, regardless of the agent's type or configured LLM.

The most simple request body includes a \`data\` object with a \`messages\` array, which contains the messages of the entire conversation. Here's an example of a request body:
\`\`\`json
{
  "data": {                         
    "messages": [                   
      {
        "role": "user",             
        "content": "Hello, world!"  
      }
    ]
  }
}
\`\`\`

### Stateless Processing

The most common method of calling an agent is using stateless processing, where the agent processes the input data and returns a response and each individual API call carries no impact or reference to future or previous API calls.

In stateless mode, you are responsible for maintaining the state of the conversation and passing the entire relevant conversation history to the agent using the \`messages\` array with each API call.

Body parameters for stateless processing:
\`\`\`parameter
[
  {
    "name": "data",
    "type": "object",
    "required": true,
    "description": "The input data object is always required and acts as a wrapper for the data that will be sent into the agent.",
    "parameters": [
      {
        "name": "messages",
        "type": "array of objects",
        "required": false,
        "description": "An array of messages that make up the entire conversation.",
        "parameters": [
          {
            "name": "role",
            "type": "string",
            "required": true,
            "description": "Must be one of 'user', 'agent' (formerly 'assistant'), 'system', or 'tool'"
          },
          {
            "name": "content",
            "type": "string",
            "required": true,
            "description": "The text content of the actual message, can only be null if the including a message from the agent that contained a null body with a tool call"
          }
        ]
      },
      {
        "name": "variables",
        "type": "object",
        "required": false,
        "description": "An object containing any variables that you want to pass to the agent, these need to be defined in the agent's configuration and are passed in 'KEY': 'value' format."
      }
    ]
  },
  {
    "name": "stream",
    "type": "boolean",
    "required": false,
    "description": "A boolean value to enable or disable streaming of the agent's processing steps, defaults to false. Cannot be combined with verbose mode."
  },
  {
    "name": "verbose",
    "type": "boolean",
    "required": false,
    "description": "A boolean value to enable or disable verbose mode, defaults to false. Verbose mode will return a detailed breakdown of the agent's processing steps in the response. Cannot be combined with streaming."
  },
  {
    "name": "session_id",
    "type": "string",
    "required": false,
    "description": "Optionally log an alphanumeric string session ID from your client for easier tracking and logging."
  },
  {
    "name": "user_id",
    "type": "string",
    "required": false,
    "description": "Optionally log an alphanumeric string user ID from your client for easier tracking and logging."
  }
]
\`\`\`

Examples stateless request body:
\`\`\`json
{
  "data": {
    "messages": [
      {
        "role": "user",
        "content": "Hello world, I'm sending the entire conversation history in each request as an array!"
      }
    ],
    "variables": {
      "NAME": "Jane Smith"
    }
  },
  "stream": false,
  "verbose": true,
  "user_id": "my_user_123",
  "session_id": "their_session_abc"
}
\`\`\`

***

### Stateful Processing

Stateful processing is a more advanced method of calling an agent, where the agent processes the input data and returns a response, but also maintains a state of the conversation history and context.

In stateful mode, the agent maintains the state of the conversation and you only need to pass the new messages to the agent with each API call. As your Agent processes the conversation, it will maintain the state of the conversation and context, and return a response based on the entire conversation history. 

This is useful for maintaining context and history across multiple API calls, but causes makes it easy to run into the context window limit of the LLM you've configured. If your Agent has been configured with one of the automatic methods for gracefully handling context windows, stateful processing will also automatically handle the context window for you.

\`\`\`parameter
[
  {
    "name": "data",
    "type": "object",
    "required": true,
    "description": "The input data object is always required and acts as a wrapper for the data that will be sent into the agent.",
    "parameters": [
      {
        "name": "message",
        "type": "object",
        "required": true,
        "parameters": [
          {
            "name": "role",
            "type": "string",
            "required": true,
            "description": "Must be one of 'user', 'agent' (formerly 'assistant'), 'system', or 'tool'"
          },
          {
            "name": "content",
            "type": "string",
            "required": true,
            "description": "The text content of the actual message"
          }
        ]
      }
    ]
  },
  {
    "name": "stateful",
    "type": "boolean",
    "required": true,
    "description": "A boolean value to enable or disable stateful processing, defaults to false. Required to be true for stateful processing."
  },
  {
    "name": "stream",
    "type": "boolean",
    "required": false,
    "description": "A boolean value to enable or disable streaming of the agent's processing steps, defaults to false. Cannot be combined with verbose mode."
  },
  {
    "name": "verbose",
    "type": "boolean",
    "required": false,
    "description": "A boolean value to enable or disable verbose mode, defaults to false. Verbose mode will return a detailed breakdown of the agent's processing steps in the response. Cannot be combined with streaming."
  },
  {
    "name": "session_id",
    "type": "string",
    "required": true,
    "description": "A required alphanumeric string session ID from your client for connecting this message to the automatically managed conversation history."
  },
  {
    "name": "user_id",
    "type": "string",
    "required": true,
    "description": "A required alphanumeric string user ID from your client for connecting this message to the automatically managed conversation history."
  }
]
\`\`\`

Examples of stateful request body:
\`\`\`json
{
  "data": {
    "message": {
      "role": "user",
      "content": "Hello world, I'm only sending the new message in each request!"
    }
  },
  "stateful": true,
  "stream": false,
  "verbose": true,
  "user_id": "my_stateful_user_123",
  "session_id": "their_stateful_session_abc"
}
\`\`\`
`

    }
  ]
}