import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import CustomSelect from '../../kit/components/CustomSelect/CustomSelect.js'
import Hydrate from '../../components/Hydrate/Hydrate.js'

import stringToColor from '../../utilities/stringToColor'

import './MemberSearchSelect.scss';

import {
  tryToSearchOrganizationMembers
} from '../../actions/actions.export'

let timeout;
let timeoutDuration = 1000;

class MemberSearchSelect extends Component {

  constructor(props){
    super(props);

    this.state = {
      search: "",
      selected: undefined,
      options: ['a','b','c']
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){
    const { dispatch } = this.props;
  }

  handleChange(v){
    if(v === undefined){

      if(this.props.onClear){
        this.props.onClear();
      }

      return this.setState({
        search: "",
        selected: false,
        lastChange: undefined
      })
    }


    const { orgReducer } = this.props;

    let organization = orgReducer.cache[this.props.id];
    if(!organization) return;

    let member = organization.memberSearch.find(m => m.user_display_name + " " + m.user_email === v);
    
    if(this.props.onChange){
      this.setState({
        lastChange: member
      })
      setTimeout(()=>{
        this.props.onChange(member);  
      })
    } 
  }

  componentWillReceiveProps(newprops){
    if(!newprops.value && this.state.lastChange){
      this.handleChange();
    }
  }

  handleInputChange(e){
    const { dispatch } = this.props;

    if(e.length > 0){
      clearTimeout(timeout);
      timeout = setTimeout(()=>{
        this.setState({
          search: e
        });

        dispatch(tryToSearchOrganizationMembers({
          id: this.props.id,
          manager: this.props.manager,
          search: e
        }))
      }, timeoutDuration);
    }
  }
  
  render(){

    const { dispatch, userReducer, orgReducer} = this.props;

    let organization = orgReducer.cache[this.props.id];
    if(!organization) return <span/>

    let options = [];

    if(organization.memberSearch) options = organization.memberSearch
    
    if(this.props.removeMe){
      options = options.filter(m => m.user_id !== userReducer.myData.id);
    }

    if(this.props.removeList){
      for(var i in this.props.removeList){
        options = options.filter(m => m.user_id !== this.props.removeList[i].user_id);   
      }
    }
    
    options = options.map((m) => {
      return {
        label: <div className="list-left">
          <Hydrate
            mode="avatar"
            type="user"
            id={m.user_id}
            />
          {m.user_display_name}
        </div>,
        value: m.user_display_name + " " + m.user_email,
        extraField: m.user_id
      }
    });


    if(this.state.search === ""){
      options = [];
    }

    return <div className={"member-search-select"}>
      <CustomSelect
        label={this.props.inline ? undefined: "Search for a member"}
        placeholder={this.props.placeholder || "type here to search by name or email"}
        value={this.state.selected}
        inline={this.props.inline}
        options={options}
        noOptionsMessage={()=>{
          if(orgReducer.tryingToSearchOrganizationMembers) return "Searching...";
          if(this.state.search === "") return null;
          return "No members found."
        }}
        isClearable={true}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        />
    </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, orgReducer } = state;

  return {
    userReducer, orgReducer
  }
}

export default connect(mapStateToProps)(MemberSearchSelect);
