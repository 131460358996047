import Cookies from 'js-cookie';
import { push } from 'react-router-redux';

import addCookieWithConsent from '../../utilities/addCookieWithConsent';

import {
  tryToLoadFeaturesToken,
  receiveLoadFeaturesSuccess
} from '../actions.export'


////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Get feature switches as logged out users
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestGetLoggedOutFeatureSwitches = packet => {
  return {
    type: 'REQUEST_LOGGED_OUT_FEATURE_SWITCHES',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveGetLoggedOutFeatureSwitchesSuccess = data => ({
  type: 'RECEIVE_LOGGED_OUT_FEATURE_SWITCHES_SUCCESS',
  data
})

export const receiveGetLoggedOutFeatureSwitchesFail = data => ({
  type: 'RECEIVE_LOGGED_OUT_FEATURE_SWITCHES_FAIL',
  data
})

export const tryToGetLoggedOutFeatureSwitches = packet => (dispatch, getState) => {

  dispatch(requestGetLoggedOutFeatureSwitches(packet));

  let headers = {
    "Content-Type": "application/json"
  }

  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;  return fetch('/api/features/anon', {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveGetLoggedOutFeatureSwitchesSuccess(json.data));

      if(json.fs_token){
        var parsed_features = JSON.parse(window.atob(json.fs_token.split('.')[1]));    
        dispatch(receiveLoadFeaturesSuccess(parsed_features));
      }


      // addCookieWithConsent(process.env.REACT_APP_COOKIE_FEATURE_SWITCH, json.fs_token, dispatch, 1, false, ()=>{
      //   dispatch(tryToLoadFeaturesToken());  
      // });

      // put the token in localstorage instead
      localStorage.setItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH, json.fs_token);
      dispatch(tryToLoadFeaturesToken());

      
    })
    .catch(e => {
      dispatch(receiveGetLoggedOutFeatureSwitchesFail({errors:e, lastSubmit: packet}));
    })
}
