import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Prompt } from 'react-router-dom'
import { push } from 'react-router-redux';
import moment from 'moment'

import {
  encode
} from 'gpt-tokenizer'

import {
  showTooltip,
  hideTooltip,
  tryToGetInstructionsByOrganization,
  tryToGetSearch,
  tryToCreateNewInstruction,
  tryToDeleteInstruction,
  tryToAddContentToComponent
} from 'actions/actions.export'

import CustomField from 'kit/components/CustomField/CustomField.js'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js'
import CustomButton from 'kit/components/CustomButton/CustomButton.js'
import Checkbox from 'kit/components/Checkbox/Checkbox.js'
import Callout from 'kit/components/Callout/Callout.js'
import TabNav from 'kit/components/TabNav/TabNav';
import Modal from 'kit/components/Modal/Modal';
import Dropdown from 'kit/components/Dropdown/Dropdown';

import ComponentContentThumbnail from 'components/ComponentContentThumbnail/ComponentContentThumbnail.js'

import './ComponentFewShotGPTTool.scss';
import Hydrate from 'components/Hydrate/Hydrate';
import isFeatureSwitchOn from 'utilities/isFeatureSwitchOn';

// Load the default initialized state for this content, might include parsing or some bonus arrays or something
export const createInitPacket = (content) => {
  return {
    id: content.id,
    display_name: content.display_name,
    order: content.order,
    messages: content.messages,
    knowledge_id: content.knowledge_id,
    enabled: content.enabled,
    // knowledge_link_method: content.knowledge_link_method,
    // max_information_returned: content.max_information_returned || 1,
    // embedding_similarity_threshold: content.embedding_similarity_threshold || .75,
    // rag_manual_search_text: content.rag_manual_search_text || "",
    // rag_agentic_prompt: content.rag_agentic_prompt || "",

  }
}


// Parse relevant data from the content to show any unsaved changes
export const createStructuredPacket = (content) => {
  return {
    id: content.id,
    display_name: content.display_name,
    order: content.order,
    messages: content.messages,
    knowledge_id: content.knowledge_id,
    enabled: content.enabled,
    // knowledge_link_method: content.knowledge_link_method,
    // max_information_returned: content.max_information_returned || 1,
    // embedding_similarity_threshold: content.embedding_similarity_threshold || .75,
    // rag_manual_search_text: content.rag_manual_search_text || "",
    // rag_agentic_prompt: content.rag_agentic_prompt || "",
  }
}

// Append relevant info to download zip
const createDownloadPacket = (content, zip) => {
  zip.file(content.display_name + ".json", JSON.stringify({
    display_name: content.display_name,
    order: content.order,
    messages: content.messages,
    knowledge_id: content.knowledge_id,
    enabled: content.enabled,
    // knowledge_link_method: content.knowledge_link_method,
    // max_information_returned: content.max_information_returned || 1,
    // embedding_similarity_threshold: content.embedding_similarity_threshold || .75,
    // rag_manual_search_text: content.rag_manual_search_text || "",
    // rag_agentic_prompt: content.rag_agentic_prompt || "",
  }, null, 2))

  return zip;
}


// Don't change anything here, this little guy just helps us run our menu updates up the tree to the manager
const Observer = ({ value, didUpdate }) => {
  useEffect(() => {
    didUpdate(value)
  }, [value])
  return null // component does not render anything
}

class CustomComponent extends Component {
  constructor(props){
    super(props);


    this.state = {
      showLibrary: false,
      search: "",
      libraryTab: '0W',
      openWarnings: {}
    }

    this.updateMenu = this.updateMenu.bind(this);
    this.searchInstructions = this.searchInstructions.bind(this);
  }

  componentWillMount(){
    if(!this.props.data.messages){
      this.props.onChange({
        messages: [{
          role: "system",
          content: "",
          welcome_message: false
        }]
      })
    }
  }

  componentWillReceiveProps(nextProps){
    const { instructionReducer } = nextProps;

    if(instructionReducer.newInstructionSuccess && this.state.showSaveToLibrary && instructionReducer.newInstructionSuccess !== this.state.lastNewInstructionSuccess){
      setTimeout(()=>{
        this.setState({
          showSaveToLibrary: false,
          lastNewInstructionSuccess: instructionReducer.newInstructionSuccess
        });
      }, 500);
    }

    if(instructionReducer.deleteInstructionSuccess && this.state.showDeleteInstructionModal && instructionReducer.deleteInstructionSuccess !== this.state.lastDeleteInstructionSuccess){
      this.setState({
        showDeleteInstructionModal: false,
        lastDeleteInstructionSuccess: instructionReducer.deleteInstructionSuccess
      });
    }
  }

  updateMenu(){
    const { dispatch, userReducer, featuresReducer } = this.props;

    let menu = [];

    if(isFeatureSwitchOn('instruction_library', userReducer, featuresReducer)){
      menu.push({
        display_name: <span><i className="far fa-folder-open icon-before-text"/></span>,
        color: 'components',
        dropdownAlign: 'left',
        items: [
          {
            display_name: "Load from Library",
            display_icon: 'folder-open',
            // disabled: ,
            shortcut: true,
            tooltip: <span>Load an instruction from the library to use in this agent.</span>,
            onClick: () => {
              dispatch(tryToGetInstructionsByOrganization({org_id: this.props.org_id}));
              this.setState({showLibrary: true});
            }
          },
          {
            display_name: "Save to Library",
            display_icon: 'save',
            // disabled: ,
            shortcut: true,
            tooltip: <span>Save this instruction to the library for future use by anyone in your organization.</span>,
            onClick: () => {
              this.setState({
                showSaveToLibrary: true
              })
            }
          },
        ]
      })
    }

    this.props.menuMerge(menu);
  }

  searchInstructions(e){
    const { dispatch } = this.props;
    if(e && e.preventDefault) e.preventDefault();

  
    let queryPacket = {
      organization: this.props.org_id,
      query: this.state.search,
      kinds: "instructions"
    }

    dispatch(tryToGetSearch(queryPacket));
  }

  render(){

    const { dispatch, componentReducer, userReducer, draftComponent, component, content, instructionReducer, knowledgeReducer, canWrite, sharedReducer } = this.props;

    let loadedMessages = JSON.parse(JSON.stringify(this.props.data.messages || []));

    if(loadedMessages.length === 0){
      loadedMessages.push({
        role: "system",
        content: ""
      })
    } else if(loadedMessages.length > 1){
      loadedMessages = loadedMessages[0];
    }


    let message = loadedMessages[0];
    if(!Array.isArray(loadedMessages)){
      message = loadedMessages; 
    }


    let knowledgeCacheList = Object.keys(knowledgeReducer.cache).map(id => knowledgeReducer.cache[id]);
    
    let knowledge = [];
    if(this.props.org_id){
      knowledge = knowledgeCacheList.filter(p => p.scope === this.props.org_id);
    }

    let warnings = [];

    let availableVariables = (draftComponent.variables || []).filter(d => !d.deleted).map(v => v);

    // flatten a single string of all instructions
    let bigstring = draftComponent.few_shots.map(f => {
      if(f.id === this.props.data.id) return '';
      return (f.messages || []).map(m => m.content).join(' ');
    }).join(' ') + (message ? message.content : '');

    let unusedVariables = availableVariables.filter(v => bigstring.indexOf('${' + v.key + '}') === -1);

    if(unusedVariables.length > 0){
      warnings.push({
        preview: "This agent has variables that aren't used in any instructions yet.",
        style: "warning",
        details: <div>
          <p>These variables are available to use in this instruction, but are not currently being used:</p>
          <div className="row">
            {
              unusedVariables.map((v, i) => {
                return <div className="col-md-12" key={i}>
                  <ComponentContentThumbnail 
                    type={'variables'} 
                    content={v} 
                    showDetails={false} 
                    offerDetails={false}
                    linkedComponent={component}
                    />
                  </div>
              })
            }
          </div>
        </div>
      })
    }

    // Regular expression to find words within ${...}
    const regex = /\${(.*?)}/g;

    // Array to hold the matched words
    let matchedWords = [];

    // Find matches and extract words
    let match;
    while ((match = regex.exec(message ? message.content : '')) !== null) {
      // match[1] contains the captured group content
      matchedWords.push(match[1]);
    }

    // look through matchedWords to find any items that are not availableVariables and create a new warning about the use of a variable that doesnt appear to exist yet
    let missingVariables = matchedWords.filter(m => !availableVariables.map(v => v.key).includes(m));

    for(let i = 0; i < missingVariables.length; i++){
      if(missingVariables[i].startsWith('DATETIME="')) continue;
      warnings.push({
        preview: <span>The variable {missingVariables[i]} is used in this instruction, but doesn't appear to exist yet.</span>,
        style: "danger",
        details: <div className="flex-split">
          <p className="margin-right-2rem">The variable <strong>{missingVariables[i]}</strong> is used in this instruction, but it doesn't appear to exist yet. You can create a new variable in the Prompt Variables tab with this key in order to use it in this instruction.</p>
          <CustomButton
            display="Create Variable"
            color="black"
            size="small"
            thinking={componentReducer.tryingToAddContentToComponent}
            fail={componentReducer.addContentToComponentFail}
            success={componentReducer.addContentToComponentSuccess}
            onClick={()=>{
              dispatch(tryToAddContentToComponent({
                id: component.id,
                type: 'variables',
                data: {
                  key: missingVariables[i],
                },
                noNav: true
              }))
            }}
            />
        </div>
      })
    }


    let instructions = Object.keys(instructionReducer.cache).map(id => instructionReducer.cache[id]);
    instructions = instructions.filter(i => i.scope === this.props.org_id);

    // if we searched for something, filter the instructions
    let searchString = `organization=${this.props.org_id}&query=${this.state.search}&kinds=instructions`;
    if(this.state.search && sharedReducer.searchRequests[searchString]){
      instructions = [];
      if(sharedReducer.searchRequests[searchString].success){
        instructions = sharedReducer.searchRequests[searchString].results.hits;
      }
    }


    let templateInstructions = instructionReducer.templates;
    if(this.state.search){
      templateInstructions = templateInstructions.filter(i => i.display_name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1);
    }

    let isCurrentContentEmpty = true;
    if(this.props.data.messages){
      this.props.data.messages[0].content === "" || this.props.data.messages[0].content === undefined || this.props.data.messages[0].content === null;
    }
    
    return <div className="component-template-tool">
      {/*invisible component to tuck the hook within this class component, don't edit*/}
      <Observer value={this.state} didUpdate={this.updateMenu} />
      {/*invisible component to tuck the hook within this class component, don't edit*/}

      <Modal
        show={this.state.showSaveToLibrary}
        exitable={true}
        onExit={()=>{
          this.setState({showSaveToLibrary: false})
        }}
        cancelable={true}
        onCancel={()=>{
          this.setState({showSaveToLibrary: false})
        }}
        acceptable={true}
        acceptButtonThinking={instructionReducer.tryingToCreateNewInstruction}
        acceptButtonFailed={instructionReducer.newInstructionFail}
        acceptButtonSuccess={instructionReducer.newInstructionSuccess}
        onAccept={()=>{
          dispatch(tryToCreateNewInstruction({
            display_name: this.props.data.display_name || "Untitled",
            content: this.props.data.messages[0].content,
            role: this.props.data.messages[0].role,
            scope: this.props.org_id
          }))
        }}
        acceptButtonLabel="Save to Library"
        content={<div>
          <h3 className="no-margin">Save to Library</h3>
          <p>
            Save a copy of this instruction to the library for future use by anyone in your organization. Future changes to this instruction will not affect the library copy.
          </p>
        </div>}
        />


      <Modal
        show={this.state.showDeleteInstructionModal}
        exitable={true}
        onExit={()=>{
          this.setState({showDeleteInstructionModal: false})
        }}
        cancelable={true}
        onCancel={()=>{
          this.setState({showDeleteInstructionModal: false})
        }}
        acceptable={true}
        acceptButtonColor="danger"
        acceptButtonLabel="Remove from Library"
        acceptButtonThinking={instructionReducer.tryingToDeleteInstruction}
        acceptButtonFailed={instructionReducer.deleteInstructionFail}
        acceptButtonSuccess={instructionReducer.deleteInstructionSuccess}
        onAccept={()=>{
          dispatch(tryToDeleteInstruction({
            id: this.state.showDeleteInstructionModal
          }))
        }}
        content={<div>
          <h3 className="no-margin">Are you sure?</h3>
          <p>
            Deleting will remove this instruction from the library, but not impact any agents that made use of it.
          </p>
        </div>}
        />

      {
        this.state.showLibrary ?
        <div className="margin-bottom-2rem box">
          <div className="row">
            <div className="col-md-12">
              <div className="flex-split flex-split-align-start margin-bottom-1rem">
                <div>
                  <h3 className="no-margin margin-bottom-05rem">Instruction Library</h3>
                  <p className="no-margin-top thin-line-height">
                    <small>
                      The Instruction Library is a place to store and share instructions across your organization. You can use the library to load instructions into this agent, or save instructions from this agent to the library for future use. This can be helpful if there are commonly referenced instructions that you want to use across multiple agents without retyping.
                    </small>
                  </p>
                </div>
                <div className="margin-left-2rem">
                  <CustomButton
                    display={<i className="far fa-times"/>}
                    color="grey"
                    size="small"
                    onClick={()=>{
                      this.setState({showLibrary: false})
                    }}
                    />
                </div>
              </div>

              {
                !isCurrentContentEmpty &&
                <div className="margin-bottom-2rem">
                  <Callout
                    style="danger"
                    title="Loading an instruction will overwrite your current instruction!"
                    content={<div>
                      If you want to keep your current instruction's content, create a new empty instruction to load a library instruction into instead.
                    </div>}
                    />
                </div>
              }
              
            </div>
          </div>
          <div className="spacer-2rem"/>
          <TabNav
            value={this.state.libraryTab}
            selectedStaysLit={true}
            onChange={(v)=>{
              this.setState({libraryTab: v});
              if(v === 'saved' && !instructionReducer.getInstructionsSuccess){
                dispatch(tryToGetInstructionsByOrganization({org_id: this.props.org_id}));
              }
            }}
            items={[
              {
                display: <span>ZeroWidth Templates ({templateInstructions.length.toLocaleString()})</span>,
                value: '0W'
              },
              {
                display: <span>Your Organization's Saved Instructions ({instructions.length.toLocaleString()})</span>,
                value: 'saved'
              }
            ]}
            />
          <div className="padding-1rem margin-top-1rem">
            {
              this.state.libraryTab === '0W' ?
              <div className="row">
                <div className="col-md-12 margin-bottom-2rem">
                  <form className="flex-split">
                    <div className="flex-grow">
                      <CustomField
                        inline={true}
                        placeholder="Search for a ZeroWidth template..."
                        value={this.state.search}
                        onChange={(v)=>{
                          this.setState({search: v.value})
                        }}
                        />
                    </div>
                  </form>
                </div>
                {
                  templateInstructions.map((instruction, index) => {
                    return <div className="col-md-6 margin-bottom-2rem" key={index}>
                      <div className="box box-half-pad box-no-shadow box-light-border">
                        <div className="flex-split">
                          <h5 className="no-margin text-ellipsis-1-lines">{instruction.display_name}</h5>
                          <img src="/img/cascading-z.png" alt="ZeroWidth" className="icon-small" width="25" height="25"/>
                        </div>
                        
                        <p className="text-ellipsis-4-lines thin-line-height" style={{minHeight: 66}}>
                          <small>
                            {
                              instruction.content
                            }
                          </small>
                        </p>
                        <div className="list-right margin-top-1rem">
                          <CustomButton
                            size="xs"
                            display="Load"
                            color="grey"
                            onClick={()=>{
                              
                              var messages = loadedMessages;

                              messages[0].display_name = instruction.display_name;
                              messages[0].content = instruction.content;
                              messages[0].role = instruction.role;


                              this.props.onChange({
                                messages: messages
                              })

                              this.setState({showLibrary: false});


                            }}
                            />
                        </div>
                      </div>
                    </div>
                  })
                }
              </div>
              :
              (
                instructionReducer.tryingToGetInstructionsByOrganization ?
                <div className="row">
                  <div className="col-md-12 margin-top-2rem">
                    <p className="no-margin-bottom">
                      <small>
                        <i className="fas fa-spinner-third fa-spin icon-before-text"/>Loading Instructions
                      </small>
                    </p>
                  </div>
                </div>
                :
                
                <div className="row">
                  <div className="col-md-12 margin-bottom-2rem">
                    <form className="flex-split" onSubmit={this.searchInstructions}>
                      <div className="flex-grow margin-right-1rem">
                        <CustomField
                          inline={true}
                          placeholder="Search your saved instructions..."
                          thinking={sharedReducer.tryingToSearch}
                          value={this.state.search}
                          onChange={(v)=>{
                            this.setState({search: v.value})
                          }}
                          />
                      </div>
                      <CustomButton
                        display={<i className="fas fa-search"/>}
                        size="small"
                        color="black"
                        type="submit"
                        thinking={sharedReducer.tryingToSearch}
                        onClick={this.searchInstructions}
                        />
                      <button type="submit" style={{display: 'none'}}/>
                    </form>
                  </div>
                  {
                    instructions.map((instruction, index) => {
                      return <div className="col-md-6 margin-bottom-2rem" key={index}>
                      <div className="box box-half-pad box-no-shadow box-light-border">
                        <div className="flex-split">
                          <h5 className="no-margin text-ellipsis-1-lines">{instruction.display_name}</h5>
                          <div className="list-right">
                            <Hydrate type="user" mode="avatar" size={25} id={instruction.created_by}/>
                            {
                              instruction.created_by === userReducer.myData.id &&
                              <Dropdown
                                align="right"
                                items={[
                                  <span
                                    onClick={e => {
                                      this.setState({
                                        showDeleteInstructionModal: instruction.id
                                      })
                                    }}
                                    >
                                    <i className="far fa-trash-alt fa-fw icon-before-text text-danger"/> Delete
                                  </span>
                                ]}
                                target={<i className="fas fa-ellipsis-v fa-fw icon-before-text text-muted clickable text-hover-unmute"/>}
                                />
                            }
                          </div>
                        </div>
                        
                        <p className="text-ellipsis-4-lines thin-line-height" style={{minHeight: 66}}>
                          <small>
                            {
                              instruction.content
                            }
                          </small>
                        </p>
                        <div className="list-right margin-top-1rem">
                          <CustomButton
                            size="xs"
                            display="Load"
                            color="grey"
                            onClick={()=>{
                              
                              var messages = loadedMessages;

                              messages[0].display_name = instruction.display_name;
                              messages[0].content = instruction.content;
                              messages[0].role = instruction.role;


                              this.props.onChange({
                                messages: messages
                              })

                              this.setState({showLibrary: false});


                            }}
                            />
                        </div>
                      </div>
                    </div>
                    })
                  }
                  {
                    instructions.length === 0 &&
                    <div className="col-md-12">
                      <p className="no-margin-bottom">
                        <small>
                          No instructions were found in your library. Try searching for something else, saving a new instruction, or check back later.
                        </small>
                      </p>
                    </div>
                  }
                </div>
              )
            }
          </div>
        </div>
        :
        <div className="margin-bottom-2rem box">
          <div className="row">
            
            <div className="col-md-6">
              <CustomField
                value={this.props.data.display_name}
                label="Name"
                inline={true}
                placeholder="Attitude, training, etc"
                maxLength={64}
                description="Just to stay organized, not shown to the LLM."
                disabled={!canWrite}
                onChange={(v)=>{
                  this.props.onChange({
                    display_name: v.value
                  })
                }}
                />
            </div>
            <div className="col-md-6">
              <div className="smaller-inline-button-vertical-shift"/>
              <div className="list-right">
                {
                  (this.props.data.enabled || this.props.data.enabled === undefined) ?
                  <small className="text-900 text-success margin-right-1rem"> <i className="far fa-check icon-before-text"/>Currently enabled</small> :
                  <small className="text-900 text-danger margin-right-1rem"><i className="far fa-times icon-before-text"/>Currently disabled</small>
                }
                <CustomButton
                  displayOptions={[
                    <span>
                    Disable
                  </span>,
                  <span>
                    Enable
                  </span>
                  ]}
                  display={(this.props.data.enabled || this.props.data.enabled === undefined) ? <span>
                    Disable
                  </span> : 
                  <span>
                    Enable
                  </span>
                  } 
                  color={(this.props.data.enabled || this.props.data.enabled === undefined) ? "grey" : "grey"}
                  size="small"
                  onClick={()=>{
                    let newValue;
                    if(this.props.data.enabled === undefined){
                      newValue = false;
                    } else {
                      newValue = !this.props.data.enabled;
                    }
                    this.props.onChange({
                      enabled: newValue
                    })
                  }}
                  onMouseEnter={(e)=>{
                    dispatch(showTooltip({
                      el: e.target,
                      position: 'bottom',
                      lag: 500,
                      nobr: false,
                      content: <div style={{maxWidth: 250}} className="text-400">
                        Disabling this instruction will remove it from the training set, but it will not be deleted.<br/><br/>You can re-enable it at any time and use this switch to easily test the impact of this instruction on the agent's performance.
                      </div>
                    }))
                  }}
                  onMouseLeave={()=>{
                    dispatch(hideTooltip())
                  }}
                  />
              </div>
            </div>
            <div className="col-md-12">
              <CustomSelect
                value={message.role}
                placeholder="Who is saying this?"
                description="Most of the time, Instructional message is the right choice."
                disabled={!canWrite}
                label="Role"
                large={true}
                options={[
                  {
                    label: <div className="list-left list-left-no-wrap">
                      <div>
                        <i className="far fa-chalkboard-teacher fa-lg fa-fw"/>
                      </div>
                      <div className="thin-line-height">
                        <strong>Instructional Message</strong><br/>
                        <small>For speaking directly to the LLM, giving it direct training instructions, and inserting variables.</small>
                      </div>
                    </div>,
                    value: 'system', 
                  },
                  {
                    label: <div className="list-left list-left-no-wrap">
                      <div>
                        <i className="far fa-robot fa-lg fa-fw"/>
                      </div>
                      <div className="thin-line-height">
                        <strong>From the Agent</strong><br/>
                        <small>Injected messages as the agent itself, most frequently used for welcome messages.</small>
                      </div>
                    </div>,
                    value: 'agent', 
                  },
                  {
                    label: <div className="list-left list-left-no-wrap">
                      <div>
                        <i className="far fa-user fa-lg fa-fw"/>
                      </div>
                      <div className="thin-line-height">
                        <strong>From the User</strong><br/>
                        <small>For speaking directly as the user, not common but useful for injecting additional context.</small>
                      </div>
                    </div>,
                    value: 'user', 
                  },
                ]}
                onChange={(v)=>{

                  var messages = loadedMessages;
                  messages[0].role = v;

                  this.props.onChange({
                    messages: messages
                  })
                }}
                />
            </div>
            <div className="col-md-12">
              <CustomField
                inline={true}
                value={message.content}
                placeholder={`ex: You are a...
                
It's your job to... 

Use this information to help answer the user's question...

Respond in the following format...
                `}
                description={<span>{(encode(message.content).length + encode('system').length + 3)} tokens {message.content.indexOf('${') > -1 ? "(not including potential values of any variables or knowledge)" : ""}</span>}
                rows={15}
                label="Content"
                disabled={!canWrite}
                onChange={(v)=>{

                  var messages = loadedMessages;
                  messages[0].content = v.value;

                  this.props.onChange({
                    messages: messages
                  })
                }}
                />
              
              <div className="flex-split">
                <Checkbox
                  style="align-left"
                  disabled={(message.role !== 'agent' && this.props.draftComponent.type !== 'madlib') || !canWrite}
                  description="Display as a welcome message in the demo interface."
                  value={message.welcome_message}
                  onToggle={(e)=>{

                    var messages = loadedMessages;
                    messages[0].welcome_message = e;

                    this.props.onChange({
                      messages: messages
                    })
                  }}
                  />
              
                <div className="list-right">
                
                </div>
              </div>
              {
                warnings.length > 0 && <div className="margin-bottom-2rem margin-top-2rem text-danger text-900">
                  <i className="far fa-exclamation-triangle icon-before-text"/>There {warnings.length > 1 ? 'are' : 'is'} {warnings.length} potential issue{warnings.length > 1 ? 's' : ''} with this instruction:
                </div>
              }
              {
                warnings.map((w, i)=>{
                  return <div key={i} className={"box box-half-pad box-danger " + (!this.state.openWarnings[w.preview] ? "box-clickable" : "") }  onClick={e => {

                    var openWarnings = this.state.openWarnings;
                    if(!openWarnings[w.preview]){
                      openWarnings[w.preview] = true;
                      this.setState({openWarnings: openWarnings})
                    }
                  }}>
                    {
                      this.state.openWarnings[w.preview] ?
                        <div className="clickable text-danger" onClick={e => {
                          var openWarnings = this.state.openWarnings;
                          openWarnings[w.preview] = false;
                          this.setState({openWarnings: openWarnings});
                          e.stopPropagation();
                        }}>
                          <i className="far fa-angle-down fa-fw icon-before-text"/>{w.preview}
                        </div>
                        :
                        <div className="clickable text-danger">
                          <i className="far fa-angle-right fa-fw icon-before-text clickable"/>{w.preview}
                        </div>
                    }

                    {
                      this.state.openWarnings[w.preview] &&
                      <div className="margin-left-1rem padding-1rem">{w.details}</div>
                    }
                    
                  </div>
                })
              }
            </div>
            <div className="col-md-12">
              <p>
                Instructions let you adapt a foundational LLM in real-time to the specific task. Each should be a short, succinct, and clear instruction that will help the LLM understand the role, task, context, and format of the desired generated text.
              </p>
              <p>
                For some LLMs, all instructional messages will concatenate together to form a single instruction or system prompt. For others, each message will be treated as a separate instruction. The LLM will use these instructions to adapt its behavior to the specific task at hand.
              </p>
            </div>
            {
              this.props.data.knowledge_id &&
              <Callout
                style="warning"
                title="Knowledge Base Link Deprecated"
                content={<div>
                    <p>
                      <small>
                        This instruction is linked to the {knowledge.find(k => k.id === this.props.data.knowledge_id).display_name} Knowledge Base using a legacy method. This method is deprecated and will be removed in a future release. Please update this Knowledge Base connection using a Dynamic Variable.
                      </small>
                    </p>
                    <div className="list-right">
                      <CustomButton
                        color="danger"
                        display="Disconnect Knowledge Base"
                        size="small"
                        onClick={e => {
                          this.props.onChange({
                            knowledge_id: false
                          })
                        }}
                        />
                    </div>
                  </div>
                }
                />  
            }
            {/* <div className="col-md-12">
              <hr className="hr-mega-pad"/>
              <div className="flex-split margin-bottom-1rem">
                <h4 className="no-margin">Retrieval Augmentation Options</h4>
                {
                  (this.props.data.knowledge_id || this.state.showConnectDropdown) &&
                  <div className="list-right">
                    <CustomButton
                      color="danger"
                      display="Disconnect Knowledge Base"
                      size="small"
                      onClick={e => {
                        this.setState({showConnectDropdown: false});
                        this.props.onChange({
                          knowledge_id: undefined
                        })
                      }}
                      />
                  </div>
                }
              </div>
              <p>
                Retrieval Augmentation is a technique that allows the LLM to leverage a Knowledge Base to help it understand the context of a request. This is done by injecting a placeholder into the instruction that will be replaced with the most relevant information from the Knowledge Base.
              </p>
              
              {
                (this.state.showConnectDropdown || this.props.data.knowledge_id) ?
                <div className="row">
                  <div className="col-md-12">
                    <CustomSelect
                      placeholder="Select one of your organization's Knowledge Bases"
                      disabled={!canWrite}
                      label="Which Knowledge Base should be searched?"
                      value={this.props.data.knowledge_id}
                      options={(knowledge).map((k, i)=>{
                        return {
                          value: k.id,
                          label: <div>
                            <span className="text-900">{k.display_name}</span> <br/>
                            <div className="text-ellipsis-2-lines">
                              <small className="text-muted">by <Hydrate id={k.created_by} type="user"/></small>
                            </div>
                          </div>
                        }
                      })}
                      onChange={e => {
                        this.props.onChange({
                          knowledge_id: e
                        })
                      }}
                      />

                    <div className="spacer-1rem"/>
                    <CustomField
                      name={'max_information_returned'}
                      label={<span>How many pieces of information should be inserted into this instructions content?<br/><small className="text-muted">(defaults to 1)</small></span>}
                      description={""}
                      type="range"
                      min={1}
                      max={32}
                      step={1}
                      placeholder={1}
                      disabled={!canWrite}
                      value={this.props.data.max_information_returned}
                      onChange={e => {
                        this.props.onChange({
                          max_information_returned: e.value
                        })
                      }}
                      />

                    <div className="spacer-1rem"/>
                    <CustomField
                      name={'embedding_similarity_threshold'}
                      label={<span>How similar should the inserted information be to the search text?<br/><small className="text-muted">0 will pull the nearest information no matter how far, while a value of 1 requires an exact match (default: .75)</small></span>}
                      description={""}
                      type="range"
                      min={0}
                      max={1}
                      step={.01}
                      placeholder={.75}
                      disabled={!canWrite}
                      value={this.props.data.embedding_similarity_threshold}
                      onChange={e => {
                        this.props.onChange({
                          embedding_similarity_threshold: e.value
                        })
                      }}
                      />
                  </div>
                  
                  <div className="spacer-2rem"/>
                  
                  <div className="col-md-12 margin-bottom-2rem">
                    <div className="row row-eq-height">
                      <div className="col-md-4 flex-column-stretch">
                        <div className={"box flex-grow no-margin-bottom box-clickable " + (this.props.data.knowledge_link_method === 'naive' ? 'box-primary' : 'box-muted')} onClick={e => this.props.onChange({ knowledge_link_method: 'naive' })}>
                          <h5 className="no-margin">Naive</h5>
                          <p>
                            The simplest and fastest method, this will search based on only the most recent user message.
                          </p>
                          <small className="text-semi-muted">Best suited for quick one-off lookups in a conversational interface.</small>
                        </div>
                      </div>

                      <div className="col-md-4 flex-column-stretch">
                        <div className={"box flex-grow no-margin-bottom box-clickable " + (this.props.data.knowledge_link_method === 'manual' ? 'box-primary' : 'box-muted')} onClick={e => this.props.onChange({ knowledge_link_method: 'manual' })}>
                          <h5 className="no-margin">Manual</h5>
                          <p>
                            Manually write the search text, with the ability to leverage any available variable from this agent.
                          </p>
                          <small className="text-semi-muted">Best suited for pulling known information based on passed variable values.</small>
                        </div>
                      </div>

                      <div className="col-md-4 flex-column-stretch">
                        <div className={"box flex-grow no-margin-bottom box-clickable " + (this.props.data.knowledge_link_method === 'agentic_basic' ? 'box-primary' : 'box-muted')} onClick={e => this.props.onChange({ knowledge_link_method: 'agentic_basic' })}>
                          <h5 className="no-margin">Agentic</h5>
                          <p>
                            Slower but more focused, tasks another LLM to determine the best search string to use for knowledge look up.
                          </p>
                          <small className="text-semi-muted">Best suited for complex information retrieval in a conversational interface, choose from a standard method or customize your own.</small>
                        </div>
                      </div>

                      
                    </div>
                  </div>

                  {
                    this.props.data.knowledge_link_method === 'manual' &&
                    <div className="col-md-12">
                      <div className="box">
                        <h5 className="no-margin">Customize Manual Search</h5>
                        <CustomField
                          value={this.props.data.rag_manual_search_text}
                          label="Search Text"
                          placeholder="Enter the search text here, using ${VARIABLE} syntax to leverage any available variables."
                          rows={5}
                          disabled={!canWrite}
                          onChange={(v)=>{
                            this.props.onChange({
                              rag_manual_search_text: v.value
                            })
                          }}
                          />
                      </div>
                    </div>
                  }

                  {
                    this.props.data.knowledge_link_method === 'agentic_basic' &&
                    <div className="col-md-12">
                      <div className="box">
                        <h5 className="no-margin">Customize Agent</h5>
                        <CustomSelect
                          label="Optionally, choose an initial prompt to customize"
                          placeholder="Select a preset"
                          disabled={!canWrite}
                          value={this.props.data.rag_agentic_prompt}
                          options={[
                            {
                              label: <div className="list-left list-left-no-wrap">
                                <div>
                                  <i className="far fa-file-search fa-lg fa-fw"/>
                                </div>
                                <div className="thin-line-height">
                                  <strong>HyDE</strong><br/>
                                  <small>Creates a hypothetical document to use the search text, based on recent messages</small>
                                </div>
                              </div>,
                              value: 'Analyze the current topic and user request to create a short hypothetical document to use as the search text. Respond with the hypothetical document, with no quotes or other descriptive information around it.',
                            },
                            {
                              label: <div className="list-left list-left-no-wrap">
                                <div>
                                  <i className="far fa-robot fa-lg fa-fw"/>
                                </div>
                                <div className="thin-line-height">
                                  <strong>Succinct Topic Search</strong><br/>
                                  <small>Creates succinct search text based on recent messages</small>
                                </div>
                              </div>,
                              value: 'Analyze the current topic and user request from the following conversation to create an effective search string to use when looking up information. Respond with the exact best search string, with no quotes or other descriptive information around it.',
                            }
                          ]}
                          onChange={(v)=>{
                            this.props.onChange({
                              rag_agentic_prompt: v
                            })
                          }}
                          />

                        <CustomField
                          value={this.props.data.rag_agentic_prompt}
                          label="Prompt"
                          placeholder="Write a prompt here"
                          rows={5}
                          disabled={!canWrite}
                          onChange={(v)=>{
                            this.props.onChange({
                              rag_agentic_prompt: v.value
                            })
                          }}
                          />

                        <p><small>Basic search agents run on GPT-4 turbo.</small></p>
                      </div>
                    </div>
                  }
                </div>
                :
                <div className="margin-top-2rem">
                  <CustomButton
                    size="small"
                    disabled={!canWrite || message.role !== 'system'}
                    display={<span>Connect Knowledge Base<i className="far fa-link icon-after-text"/></span>}
                    onClick={e => this.setState({showConnectDropdown: true})}
                    />
                </div>
              }
            
            </div> */}
          
          </div>
        </div>
      }

    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, instructionReducer, componentReducer, featuresReducer, orgReducer, knowledgeReducer, guiReducer, sharedReducer } = state;

  return {
    userReducer, 
    instructionReducer,
    componentReducer,
    featuresReducer,
    orgReducer,
    knowledgeReducer,
    guiReducer,
    sharedReducer
  }
}

const Tool = connect(mapStateToProps)(CustomComponent);

export default {
  createInitPacket,
  createStructuredPacket,
  createDownloadPacket,
  Tool
}
