import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import shortid from 'shortid'

import {
  tryToDemoComponent,
  stopDemoComponent,
  removeDemoCacheItem,
  removeDemoCacheAfterIndex,
  showTooltip,
  hideTooltip
} from 'actions/actions.export'

import convertMessageCacheToArray from 'utilities/convertMessageCacheToArray'

import Hydrate from 'components/Hydrate/Hydrate'
import MegaMarkdown from 'components/MegaMarkdown/MegaMarkdown'
import CodeHighlighter from 'components/CodeHighlighter/CodeHighlighter'

import Modal from 'kit/components/Modal/Modal'

import './BetterChat.scss';

// a basic react class component
class BetterChat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: shortid.generate(),
      message: "",
      lastResponseTimestamp: new Date().toISOString(),
      showBackOfHouse: false,
      showBackOfHouseData: {}
    };
  }

  scrollToBottom = () => {
    var objDiv = document.getElementById("better-chat-messages-inner-" + this.state.id);
    if(objDiv) objDiv.scrollTop = objDiv.scrollHeight;
  }

  componentWillReceiveProps(nextProps) {
    // we need to check to see if we should scroll to the bottom of the chat, based on a new response
    if(nextProps.demoCache && nextProps.demoCache.length > 0) {
      let lastResponse = nextProps.demoCache[nextProps.demoCache.length - 1].response;

      if(lastResponse && lastResponse.output_data && lastResponse.output_data.timestamp > this.state.lastResponseTimestamp) {
        setTimeout(() => {
          this.setState({ lastResponseTimestamp: lastResponse.output_data.timestamp });
          this.scrollToBottom();
        }, 100);
      }
    }
  }

  handleStopDemoComponent = (e) => {
    if(e){
      if(e.preventDefault) {
        e.preventDefault();
      }
    }
    this.props.dispatch(stopDemoComponent({
      id: this.props.component_id,
      version: this.props.version
    }));
  }

  handleRetryMessage = () => {
    let messages = convertMessageCacheToArray(this.props.demoCache);

    this.props.dispatch(tryToDemoComponent({
      id: this.props.component_id,
      version: this.props.version,
      data: {
        data: {
          variables: {
            ...this.props.variables
          },
          messages: messages
        },
        stream: true
      }
    }, true, this.props.publicDemo))
  }

  handleSendMessage = (e, message) => {
    if(e){
      if(e.preventDefault) {
        e.preventDefault();
      }
    }
    
    // assemble a new messages array using the props.demoCache array and the new message
    let newMessages = [];

    if(this.props.demoCache) {
      newMessages = convertMessageCacheToArray(this.props.demoCache);
    }

    newMessages.push({
      role: "user",
      content: message || this.state.message
    });

    this.props.dispatch(tryToDemoComponent({
      id: this.props.component_id,
      version: this.props.version,
      data: {
        data: {
          variables: {
            ...this.props.variables
          },
          messages: newMessages
        },
        stream: true
      }
    }, true, this.props.publicDemo))
    
    this.setState({ message: "" });
    this.scrollToBottom();
  }

  render() {
    const { userReducer, componentReducer, component, dispatch } = this.props;

    if(!component) {
      return null;
    }

    let messages = [];
    if(this.props.demoCache) {
      messages = convertMessageCacheToArray(this.props.demoCache);
    }

    return (
      <div className="better-chat">

        <Modal
          show={this.state.showBackOfHouse}
          maxWidth={600}
          exitable={true}
          hideExitButton={true}
          onExit={() => this.setState({showBackOfHouse: false})}
          content={<div>
            <CodeHighlighter
              language="json"
              code={JSON.stringify(this.state.showBackOfHouseData, null, 2)}
              collapsedJSONDepth={2}
              />
          </div>}
          />
        <div className="better-chat-messages">
          <div className="better-chat-messages-inner" id={"better-chat-messages-inner-" + this.state.id}>
            {messages.map((message, index) => (
              <div key={index} className="better-chat-message">
                <div className="better-chat-message-avatar">
                  { 
                    message.role === "user" ? 
                    <Hydrate type="user" id={userReducer.myData ? userReducer.myData.id : ""} mode="avatar" size={25}/>
                    : 
                    <Hydrate type="components" id={component.id} mode="avatar" size={25}/>
                  }
                </div>
                <div className="better-chat-message-body">
                  <div className="list-left list-left-align-flex-end better-chat-message-role-line">
                    <div className="better-chat-message-role">
                      { 
                        message.role === "user" ? 
                        (userReducer.myData ? userReducer.myData.display_name : "You")
                        : 
                        component.display_name
                      }
                    </div>
                    {
                      message.timestamp &&
                      <div className="better-chat-message-timestamp">
                        {
                          moment(message.timestamp).fromNow()
                        }
                      </div>
                    }
                  </div>
                  <div className="better-chat-message-content">
                    {message.content && 
                      <MegaMarkdown text={message.content.trim()} />
                    }
                  </div>
                  
                  {
                    !message.isInProgress &&      
                    <div className="better-chat-message-actions">
                      <i className="fal fa-copy fa-fw" 
                        onClick={e => {
                          dispatch(hideTooltip());
                          if(navigator.clipboard) {
                            navigator.clipboard.writeText(message.content);
                          }
                        }}
                        onMouseEnter={e => {
                          dispatch(showTooltip({
                            el: e.target,
                            lag: 250,
                            content: <span>
                              Copy Text
                            </span>,
                            position: 'bottom'
                          })
                          )
                        }}
                        onMouseLeave={e => {
                          dispatch(hideTooltip());
                        }}
                        />
                      <i className="fal fa-trash fa-fw" 
                        onClick={e => {
                          dispatch(hideTooltip());
                          dispatch(removeDemoCacheItem({
                            id: this.props.component_id,
                            version: this.props.version,
                            index: message.cacheIndex,
                            whichHalf: message.role === "user" ? "request" : "response"
                          }));
                        }}
                        onMouseEnter={e => {
                          dispatch(showTooltip({
                            el: e.target,
                            lag: 250,
                            content: <span>
                              Delete
                            </span>,
                            position: 'bottom'
                          })
                          )
                        }}
                        onMouseLeave={e => {
                          dispatch(hideTooltip());
                        }}
                        />
                      {
                        message.role === "user" &&
                        <i className="fal fa-fast-backward fa-fw"
                          onClick={e => {
                            dispatch(hideTooltip());
                            // move this message's content to this.state.message
                            this.setState({ message: message.content });

                            // remove all messages after this one including this one
                            dispatch(removeDemoCacheAfterIndex({
                              id: this.props.component_id,
                              version: this.props.version,
                              index: message.cacheIndex
                            }));
                          }}

                        onMouseEnter={e => {
                          dispatch(showTooltip({
                            el: e.target,
                            lag: 250,
                            content: <span>
                              Rewind to Here
                            </span>,
                            position: 'bottom'
                          })
                          )
                        }}
                        onMouseLeave={e => {
                          dispatch(hideTooltip());
                        }}
                          />
                      }
                      {
                        (message.role === "agent" && index === messages.length - 1) &&
                        <i className="fal fa-sync fa-fw"
                          onClick={ e => {
                            dispatch(hideTooltip());

                            // remove the response half of this message
                            dispatch(removeDemoCacheItem({
                              id: this.props.component_id,
                              version: this.props.version,
                              index: message.cacheIndex,
                              whichHalf: "response"
                            }));

                            // grab the request half of this message
                            let requestMessage = messages[index - 1];
                            

                            // remove the request half of this message
                            dispatch(removeDemoCacheItem({
                              id: this.props.component_id,
                              version: this.props.version,
                              index: requestMessage.cacheIndex,
                              whichHalf: "request"
                            }));

                            // set the request half of this message as the new message
                            this.handleSendMessage(e, requestMessage.content);
                          }}
                          onMouseEnter={e => {
                            dispatch(showTooltip({
                              el: e.target,
                              lag: 250,
                              content: <span>
                                Regenerate
                              </span>,
                              position: 'bottom'
                            })
                            )
                          }}
                          onMouseLeave={e => {
                            dispatch(hideTooltip());
                          }}
                          />
                      }
                      {
                        message.role === "agent" &&
                        <i className="fal fa-code fa-fw"
                          onClick={e => {
                            // open a model with the JSON data for this full message
                            
                            // find the original response from demoCache based on this message's cacheIndex
                            let data = this.props.demoCache.find((m, demoIndex) => demoIndex === message.cacheIndex).response;

                            this.setState({
                              showBackOfHouse: true,
                              showBackOfHouseData: data
                            });

                            dispatch(hideTooltip());
                          }}
                          onMouseEnter={e => {
                            dispatch(showTooltip({
                              el: e.target,
                              lag: 250,
                              content: <span>
                                View API Packet
                              </span>,
                              position: 'bottom'
                            })
                            )
                          }}
                          onMouseLeave={e => {
                            dispatch(hideTooltip());
                          }}
                          />
                      }
                      {
                        // message.role === "agent" &&
                        // <i className="fal fa-thumbs-up fa-fw"

                        //   onMouseEnter={e => {
                        //     dispatch(showTooltip({
                        //       el: e.target,
                        //       lag: 250,
                        //       content: <span>
                        //         Give Feedback
                        //       </span>,
                        //       position: 'bottom'
                        //     })
                        //     )
                        //   }}
                        //   onMouseLeave={e => {
                        //     dispatch(hideTooltip());
                        //   }}
                        //   />
                      }
                      {
                        // message.role === "agent" &&
                        // <i className="fal fa-thumbs-down fa-fw"

                        //   onMouseEnter={e => {
                        //     dispatch(showTooltip({
                        //       el: e.target,
                        //       lag: 250,
                        //       content: <span>
                        //         Give Feedback
                        //       </span>,
                        //       position: 'bottom'
                        //     })
                        //     )
                        //   }}
                        //   onMouseLeave={e => {
                        //     dispatch(hideTooltip());
                        //   }}
                        //   />
                      }
                    </div>
                  }
                </div>
              </div>
            ))}

          </div>
          {
            this.props.publicDemo &&
            <div className="better-chat-warning">
              AI can make mistakes, verify responses.Do not share sensitive information.
            </div>
          }
        </div>
        <form className="better-chat-input" onSubmit={this.handleSendMessage}>
          <textarea
            type="text"
            value={this.state.message}
            placeholder="Chat with this agent..."
            onChange={e => this.setState({ message: e.target.value })}
            onKeyDown={e => {
              // if enter key and NOT shift key
              if(e.keyCode === 13 && !e.shiftKey) {
                e.preventDefault();
                this.handleSendMessage();
              }
            }}
          />
          <button 
            className="better-chat-send"
            type="submit"
            onMouseEnter={e => this.setState({ hoveringSubmit: true })}
            onMouseLeave={e => this.setState({ hoveringSubmit: false })}
            onClick={e => {
              if(e.preventDefault) {
                e.preventDefault();
              }
              if(componentReducer.tryingToDemoComponent) {
                this.handleStopDemoComponent(e);
              } else {
                this.handleSendMessage(e)
              }
            }}>
              {
                componentReducer.tryingToDemoComponent ?
                (
                  this.state.hoveringSubmit ?
                  <i className="far fa-stop-circle"></i>  
                  :  
                  <i className="fad fa-spinner-third fa-spin"></i>
                )
                :
                <i className="fas fa-arrow-up"></i>
              }
            </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userReducer, guiReducer, componentReducer, sharedReducer } = state;

  return {
    userReducer,
    componentReducer,
    sharedReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(BetterChat);

  