import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Prompt } from 'react-router-dom'
import { push } from 'react-router-redux';
import moment from 'moment'
import ReactJson from 'react-json-view'

import {
  hideTooltip,
  showTooltip,
  tryToTestComponent
} from 'actions/actions.export'

import CustomField from 'kit/components/CustomField/CustomField.js'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js'
import CustomButton from 'kit/components/CustomButton/CustomButton.js'
import TabNav from 'kit/components/TabNav/TabNav.js'
import Hydrate from 'components/Hydrate/Hydrate';

import './ComponentTestTool.scss';
import Callout from 'kit/components/Callout/Callout';
import CodeHighlighter from 'components/CodeHighlighter/CodeHighlighter';

// Load the default initialized state for this content, might include parsing or some bonus arrays or something
export const createInitPacket = (content) => {
  return {
    id: content.id,
    display_name: content.display_name,
    test_data: content.test_data || {
      variables: {},
      messages: []
    },
    expected_response: content.expected_response || {
      role: 'agent',
      content: "",
    },
    last_test_result: content.last_test_result || null,
    last_test_analysis: content.last_test_analysis || null
  }
}


// Parse relevant data from the content to show any unsaved changes
export const createStructuredPacket = (content) => {
  return {
    id: content.id,
    display_name: content.display_name,
    test_data: content.test_data,
    expected_response: content.expected_response,
    // last_test_result: content.last_test_result,
    // last_test_analysis: content.last_test_analysis
  }
}

// Append relevant info to download zip
const createDownloadPacket = (content, zip) => {
  zip.file(content.display_name + ".json", JSON.stringify({
    id: content.id,
    display_name: content.display_name,
    test_data: content.test_data,
    expected_response: content.expected_response,
    // last_test_result: content.last_test_result,
    // last_test_analysis: content.last_test_analysis
  }, null, 2))

  return zip;
}


// Don't change anything here, this little guy just helps us run our menu updates up the tree to the manager
const Observer = ({ value, didUpdate }) => {
  useEffect(() => {
    didUpdate(value)
  }, [value])
  return null // component does not render anything
}

class CustomComponent extends Component {
  constructor(props){
    super(props);


    this.state = {
      tab: 'test',
      analysisTraits: [
        {
          "name": "accuracy",
          "expanded": true,
          "label": "Accuracy",
          "tooltip": "Evaluates the agent's response for factual correctness compared to the expected or goal response, ensuring alignment with the desired outcome."
        },
        {
          "name": "format",
          "label": "Format",
          "tooltip": "Evaluates the agent's response for alignment with the expected format, including length, structure, and organization, ensuring it meets the specific presentation and detail level of the goal response."
        },
        {
          "name": "coherence",
          "label": "Coherence",
          "tooltip": "Measures how well the agent's response maintains a logical structure and clarity, compared to the expected logical flow and coherence of the goal response."
        },
        {
          "name": "groundedness",
          "label": "Grounding",
          "tooltip": "Checks the extent to which the agent's response is supported by evidence and aligns with established knowledge, compared to the evidence and grounding expected in the goal response."
        },
        {
          "name": "tone",
          "label": "Tone",
          "tooltip": "Evaluates the appropriateness of the agent's tone, sentiment, and character, ensuring it aligns with the tone, sentiment, and character expected in the goal response."
        }
      ]      
    }

    this.updateMenu = this.updateMenu.bind(this);
  }

  componentWillMount(){
    
  }

  updateMenu(){
    this.props.menuMerge([])
  }

  render(){

    const { dispatch, draftComponent, componentReducer, content, canWrite } = this.props;

    const localData = JSON.parse(JSON.stringify(this.props.data));

    const cachedData = ((draftComponent || {}).tests || []).find(t => t.id === content.id);

    return <div className="component-template-tool">
      {/*invisible component to tuck the hook within this class component, don't edit*/}
      <Observer value={this.state} didUpdate={this.updateMenu} />
      {/*invisible component to tuck the hook within this class component, don't edit*/}

      <div className="margin-bottom-2rem box box-no-pad box-no-border">
        
        <TabNav
          value={this.state.tab}
          style="box-top"
          onChange={v => this.setState({tab: v})}
          items={[
            {display: <span><i className="far fa-fw fa-comment-alt-lines icon-before-text"/>Test Setup</span>, value: 'test'},
            {display: <span><i className="far fa-fw fa-play icon-before-text"/>Run Test</span>, value: 'run_test'},
            {display: <span><i className="far fa-fw fa-columns icon-before-text"/>Compare Results</span>, value: 'side-by-side', disabled: !cachedData.last_test_result},
            // {display: <span><i className="far fa-fw fa-magic icon-before-text"/>Analysis & Recommendations</span>, value: 'last_test_result', disabled: !cachedData.last_test_result},
          ]}
          />
        
        {
          this.state.tab === 'test' &&
          <div className="padding-2rem">
            <div className="row">
              <div className="col-md-12">
                <CustomField
                  name="display_name"
                  inline={true}
                  value={localData.display_name}
                  label="Test Name"
                  placeholder="Enter a name for this test"
                  disabled={(componentReducer.activeTestRequests[localData.id] || {}).trying}
                  onChange={e => {
                    this.props.onChange({
                      display_name: e.value
                    })
                  }}
                  />
                <p className="margin-top-2rem margin-bottom-3rem">
                  Configure the input data for your test. This is the data that will be sent to the agent to test its response and aligns to the format you would transmit to the agent via the API.
                </p>
              </div>
              <div className="col-md-3">
                <div className="margin-bottom-1rem"><small className="text-900 text-components text-uppercase">Variables</small></div>
                {
                  (draftComponent.variables || []).length === 0 &&
                  <small className="text-muted">
                    No variables have been defined for this agent, otherwise they would be displayed here to be configured for each test.
                  </small>
                }
                {
                  (draftComponent.variables || []).map((v, j) => {
                    const variable = v;
                    return <div key={j} className="margin-bottom-05rem">
                      {
                        (variable.type === 'text' && localData.test_data) &&
                        <CustomField
                          inline={true}
                          name={variable.key}
                          value={(localData.test_data || {}).variables[variable.key]}
                          label={variable.display_name || '${' + variable.key + '}'}
                          description={variable.description || " "}
                          placeholder={variable.placeholder || " "} 
                          minLength={variable.min_length}
                          maxLength={variable.max_length}
                          disabled={(componentReducer.activeTestRequests[localData.id] || {}).trying}
                          onChange={e => {
                            let test_data = localData.test_data;
                            if(!test_data.variables) test_data.variables = {};
                            test_data.variables[variable.key] = e.value;
                            this.props.onChange({
                              test_data: test_data
                            })
                          }}
                          />
                      }

                      {
                        variable.type === 'paragraph' &&
                        <CustomField
                          inline={true}
                          name={variable.key}
                          value={localData.test_data.variables[variable.key]}
                          label={variable.display_name || '${' + variable.key + '}'}
                          description={variable.description || " "}
                          placeholder={variable.placeholder || " "} 
                          minLength={variable.min_length}
                          maxLength={variable.max_length}
                          disabled={(componentReducer.activeTestRequests[localData.id] || {}).trying}
                          rows={5}
                          onChange={e => {
                            let test_data = localData.test_data;
                            if(!test_data.variables) test_data.variables = {};
                            test_data.variables[variable.key] = e.value;
                            this.props.onChange({
                              test_data: test_data
                            })
                          }}
                          />
                      }

                      {
                        variable.type === 'number' &&
                        <CustomField
                          inline={true}
                          name={variable.key}
                          type="number"
                          value={localData.test_data.variables[variable.key]}
                          label={variable.display_name || '${' + variable.key + '}'}
                          description={variable.description || " "}
                          placeholder={variable.placeholder || " "} 
                          min={variable.min}
                          max={variable.max}
                          disabled={(componentReducer.activeTestRequests[localData.id] || {}).trying}
                          onChange={e => {
                            let test_data = localData.test_data;
                            if(!test_data.variables) test_data.variables = {};
                            test_data.variables[variable.key] = e.value;
                            this.props.onChange({
                              test_data: test_data
                            })
                          }}
                          />
                      }

                      {
                        variable.type === 'select' &&
                        <CustomSelect
                          inline={true}
                          name={variable.key}
                          value={localData.test_data.variables[variable.key]}
                          label={variable.display_name || '${' + variable.key + '}'}
                          description={variable.description || " "}
                          placeholder={variable.placeholder || " "} 
                          options={variable.options || []}
                          disabled={(componentReducer.activeTestRequests[localData.id] || {}).trying}
                          onChange={e => {
                            let test_data = localData.test_data;
                            if(!test_data.variables) test_data.variables = {};
                            test_data.variables[variable.key] = e;
                            this.props.onChange({
                              test_data: test_data
                            })
                          }}
                          />
                      }
                    </div>
                  })
                }
              </div>
              <div className="col-md-9">
                <div className="margin-bottom-1rem"><small className="text-900 text-components text-uppercase">Input Messages</small></div>
                {
                  ((localData.test_data || {}).messages || []).map((m, j) => {
                    return <div key={j} className="margin-bottom-1rem box box-light-border box-no-shadow">
                      <div className="flex-split">
                        <div className="flex-grow margin-right-1rem">
                          <CustomSelect
                            inline={true}
                            name="role"
                            value={m.role}
                            options={[
                              {
                                label: <div className="list-left list-left-no-wrap">
                                  <div>
                                    <i className="far fa-chalkboard-teacher fa-fw"/>
                                  </div>
                                  <div className="thin-line-height">
                                    <strong>Instructional Message</strong><br/>
                                  </div>
                                </div>,
                                value: 'system', 
                              },
                              {
                                label: <div className="list-left list-left-no-wrap">
                                  <div>
                                    <i className="far fa-robot fa-fw"/>
                                  </div>
                                  <div className="thin-line-height">
                                    <strong>From the Agent</strong><br/>
                                  </div>
                                </div>,
                                value: 'agent', 
                              },
                              {
                                label: <div className="list-left list-left-no-wrap">
                                  <div>
                                    <i className="far fa-user fa-fw"/>
                                  </div>
                                  <div className="thin-line-height">
                                    <strong>From the User</strong><br/>
                                  </div>
                                </div>,
                                value: 'user', 
                              },
                            ]}
                            onChange={e => {
                              let test_data = localData.test_data;
                              test_data.messages[j].role = e;
                              this.props.onChange({
                                test_data: test_data
                              })
                            }}
                            />
                        </div>
                        <div className="">
                          <CustomButton
                            display={<span><i className="far fa-trash-alt"/></span>}
                            size="small"
                            color="danger"
                            onClick={()=>{
                              localData.test_data.messages.splice(j, 1);
                              this.props.onChange({
                                test_data: localData.test_data
                              })
                            }}
                            />
                        </div>
                      </div>
                      <div className="spacer-1rem"/>
                      <CustomField
                        inline={true}
                        name="content"
                        value={m.content}
                        placeholder="Enter the message's content here"
                        rows={3}
                        onChange={e => {
                          let test_data = localData.test_data;
                          test_data.messages[j].content = e.value;
                          this.props.onChange({
                            test_data: test_data
                          })
                        }}
                        />
                    </div>
                  })
                }
                <div 
                  className="box  box-placeholder box-clickable text-muted margin-bottom-2rem"
                  onClick={e => {
                    localData.test_data.messages.push({
                      role: localData.test_data.messages.length === 0 ? 'user' : localData.test_data.messages[localData.test_data.messages.length - 1].role === "agent" ? "user" : "agent",
                      content: ""
                    })
                    this.props.onChange({
                      test_data: localData.test_data
                    })
                  }}
                  >
                  <i className="far fa-plus icon-before-text"/>Add Input Message
                </div>

                <div className="margin-bottom-1rem"><small className="text-900 text-components text-uppercase">Expected Response</small></div>
                <div className="box box-no-shadow box-light-border margin-bottom-1rem">
                  <CustomSelect
                    inline={true}
                    name="role"
                    value={'agent'}
                    disabled={true}
                    options={[
                      {
                        label: <div className="list-left list-left-no-wrap">
                          <div>
                            <i className="far fa-robot fa-fw"/>
                          </div>
                          <div className="thin-line-height">
                            <strong>From the Agent</strong><br/>
                          </div>
                        </div>,
                        value: 'agent', 
                      }
                    ]}
                    />
                  <div className="spacer-1rem"/>
                  <CustomField
                    inline={true}
                    name="content"
                    value={localData.expected_response.content}
                    placeholder="What is the expected response content from the agent?"
                    rows={10}
                    onChange={e => {
                      this.props.onChange({
                        expected_response: {
                          role: 'agent',
                          content: e.value
                        }
                      })
                    }}
                    />
                  {
                    (localData.expected_response.tool_calls || []).map((tc, i) => {
                      if(!tc.function) tc.function = { name: '', arguments: [] }
                      return <div key = {i} className="box box-light-border box-no-shadow margin-top-1rem">
                        <div className="flex-split">
                          <div className="flex-grow margin-right-1rem">
                            <CustomSelect
                              inline={true}
                              name="function_name"
                              value={tc.function.name}
                              options={draftComponent.functions.map(f => {
                                return {
                                  label: f.display_name,
                                  value: f.display_name
                                }
                              })}
                              onChange={e => {
                                let test_data = localData.expected_response;
                                test_data.tool_calls[i].function.name = e;
                                this.props.onChange({
                                  expected_response: test_data
                                })
                              }}
                              />
                          </div>
                          <div>
                            <CustomButton
                              display={<span><i className="far fa-trash-alt"/></span>}
                              size="small"
                              color="danger"
                              onClick={()=>{
                                localData.expected_response.tool_calls.splice(i, 1);
                                this.props.onChange({
                                  expected_response: localData.expected_response
                                })
                              }}
                              />
                          </div>
                        </div>
                      </div>
                    })
                  }
                  <div 
                    className="box  box-placeholder box-clickable text-muted margin-top-1rem"
                    onClick={e => {
                      if(!localData.expected_response.tool_calls) localData.expected_response.tool_calls = [];
                      localData.expected_response.tool_calls.push({
                        id: 'placeholder',
                        type: 'function',
                        function: {
                          name: '',
                          arguments: []
                        }
                      })
                      this.props.onChange({
                        expected_response: localData.expected_response
                      })
                    }}
                    >
                    <i className="far fa-plus icon-before-text"/>Add Expected Tool Call
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {
          this.state.tab === 'run_test' &&
          <div className="padding-2rem">
            {
              this.props.unsavedChanges &&
              <Callout
                 style="danger"
                 title="Unsaved Changes"
                 content="You have unsaved changes. Please save your changes before running a test."
                 />
            } 
            <CustomButton
                display={<span><i className="fas fa-play icon-before-text"/>Run Test</span>}
                size="small"
                block={true}
                thinking={(componentReducer.activeTestRequests[localData.id] || {}).trying}
                failed={(componentReducer.activeTestRequests[localData.id] || {}).fail}
                success={(componentReducer.activeTestRequests[localData.id] || {}).success}
                disabled={this.props.unsavedChanges}
                onClick={()=>{
                  dispatch(tryToTestComponent({
                    id: this.props.component.id,
                    version: 'draft',
                    data: {
                      data: localData.test_data
                    },
                    test_id: localData.id
                  }))
                }}
                color="primary"
                />
            <p>
              
            </p>
            <p className="no-margin-top margin-bottom-3rem">
              Run the test to see how the agent responds to your input. This will provide you with a side-by-side comparison of your expected response and the agent's actual response, as well as an analysis of the agent's response.
            </p>
            <div className="row">
              
              <div className="col-md-12">
                <div className="flex-split flex-split-align-end margin-bottom-05rem">
                  <small className="text-900 text-components text-uppercase">Last Test Result API Packet</small>
                  <div className="text-right">
                    {
                      localData.last_test_result ?
                      <small className="text-muted">
                        Last tested {moment(localData.last_test_result.verbose.timestamp).format('MMMM Do YYYY, h:mm:ss a')} 
                      </small>
                      :
                      <small className="text-muted">
                        This test has not been run yet.
                      </small>
                    }
                  </div>
                </div>
                <div className="">
                  {
                    cachedData.last_test_result ?
                    <CodeHighlighter
                      code={JSON.stringify(cachedData.last_test_result)}
                      block={true}
                      language={'json'}
                      />
                      :
                      <i className="text-muted">Run test to view results...</i>
                  }
                </div>
              </div>
            </div>
          </div>
        }

        {
          this.state.tab === 'expected_response' &&
          <div className="padding-2rem">
            <div className="row">
              <div className="col-md-12">
                <p>
                  Based on your test input, what is the expected response from the agent? For the best analysis results: be specific, verbose, and in the voice of the agent.
                </p>
                
              </div>
            </div>
          </div>
        }

        {
          this.state.tab === 'side-by-side' &&
          <div className="padding-2rem">
            <div className="row">
              <div className="col-md-12">
                
              </div>
              <div className="col-md-6">
                <div className="margin-bottom-1rem"><small className="text-900 text-components text-uppercase">Expected Response</small></div>
                <CustomField
                  value={localData.expected_response.content}
                  placeholder="No message content expected"
                  disabled={true}
                  inline={true}
                  rows={localData.expected_response.content ? 10 : 1}
                  />
                {
                  ((localData.expected_response || {}).tool_calls && localData.expected_response.tool_calls.length > 0) &&
                  <div className="margin-top-2rem">
                    <div className="margin-bottom-1rem"><small className="text-900 text-components text-uppercase">Expected Function Calls</small></div>
                    {
                      (localData.expected_response.tool_calls || []).map((tc, i) => {

                        let demoCode = `${tc.function.name}()`;

                        return <div key = {i} className="margin-bottom-1rem">
                          <CodeHighlighter
                            code={demoCode}
                            />
                        </div>
                      })
                    }
                  </div>
                }
              </div>
              <div className="col-md-6">
              <div className="margin-bottom-1rem"><small className="text-900 text-components text-uppercase">Actual Response</small></div>
                {
                  cachedData.last_test_result ?
                  <CustomField
                    value={cachedData.last_test_result.output_data.content}
                    placeholder="No message content returned"
                    disabled={true}
                    inline={true}
                    rows={cachedData.last_test_result.output_data.content ? 10 : 1}
                    />
                  :
                  <div className="box box-light-border box-no-shadow padding-1rem">
                    This test has not been run yet.
                  </div>
                }
                {
                  ((cachedData.last_test_result || {}).output_data.tool_calls && cachedData.last_test_result.output_data.tool_calls.length > 0) &&
                  <div className="margin-top-2rem">
                    <div className="margin-bottom-1rem"><small className="text-900 text-components text-uppercase">Function Calls</small></div>
                    {
                      (cachedData.last_test_result.output_data.tool_calls || []).map((tc, i) => {

                        let demoCode = `${tc.function.name}(${tc.function.arguments})`;


                        return <div key = {i} className="margin-bottom-1rem">
                          <CodeHighlighter
                            code={demoCode}
                            />
                        </div>
                      })
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        }

        {
          this.state.tab === 'last_test_result' &&
          <div className="padding-2rem">
            <p className="no-margin-top margin-bottom-3rem">
              After each test is run, the results are compared against the test configuration and this agent's instructions in order to provide an analysis of the agent's performance. This analysis is distributed across several key dimensions, each of which contain detailed reasoning and recommendations for improvement.
            </p>
            {
              this.state.analysisTraits.map((t, i) => {
                if(!cachedData.last_test_analysis || !cachedData.last_test_analysis[t.name]) return null;

                
                  return <div key={i} className={"box box-light-border box-no-shadow margin-bottom-2rem " + (!t.expanded ? "box-clickable" : "")} 
                    onClick={e => {
                      e.stopPropagation();
                      if(!t.expanded){
                        this.state.analysisTraits[i].expanded = true;
                        this.setState({analysisTraits: this.state.analysisTraits})
                      }
                    }}
                    > 
                    <div className={"flex-split " + (t.expanded ? "clickable" : "")} onClick={e => {
                      if(t.expanded){
                        setTimeout(() => {
                          // lol solve it with a race condition
                          e.stopPropagation();     
                          this.state.analysisTraits[i].expanded = false;
                          this.setState({analysisTraits: this.state.analysisTraits})
                        }, 100)
                      }
                    }}>
                      <div className="flex-split margin-right-1rem" style={{minWidth: 200, width: 200}}>
                        <h5 className="no-margin">
                          <i className={"fas fa-fw icon-before-text " + (t.expanded ? "fa-caret-down" : "fa-caret-right")}/>
                          {t.label}
                          <small
                            onMouseEnter={e => {
                              dispatch(showTooltip({
                                el: e.target,
                                position: 'right',
                                lag: 500,
                                nobr: false,
                                content: <div style={{maxWidth: 250}}>
                                  <span className="text-400">{t.tooltip}</span>
                                </div>
                              }))
                            }}
                            onMouseLeave={e => {
                              dispatch(hideTooltip())
                            }}
                          >
                            <i className="fal fa-fw fa-info-circle icon-after-text text-muted"/>
                          </small>
                        </h5>
                        <span>
                          {cachedData.last_test_analysis[t.name].grade}/10
                        </span>
                      </div>
                      <div className="progress-bar-wrapper  flex-grow">
                        <div className={
                          "progress-bar-inner " + 
                          (cachedData.last_test_analysis[t.name].grade < 4 ? "bg-danger" : "") +
                          (cachedData.last_test_analysis[t.name].grade >= 4 && cachedData.last_test_analysis[t.name].grade < 7 ? "bg-warning" : "") +
                          (cachedData.last_test_analysis[t.name].grade >= 7 ? "bg-success" : "")
                        } 
                          style={{width: (cachedData.last_test_analysis[t.name].grade / 10) * 100 + '%'}}/>
                      </div>
                    </div>
                    {
                      t.expanded && <hr/>
                    }

                    {
                      t.expanded && <div className="row">
                        <div className="col-md-6">
                          <div><small className="text-900 text-components text-uppercase">Reasoning</small></div>
                          <p className="thin-line-height no-margin">
                            <small>
                              {cachedData.last_test_analysis[t.name].reasoning}
                            </small>
                          </p>
                        </div>
                        <div className="col-md-6">
                          <div><small className="text-900 text-components text-uppercase">Recommendations</small></div>
                          <p className="thin-line-height no-margin">
                            <small>
                              {cachedData.last_test_analysis[t.name].recommendations}
                            </small>
                          </p>
                        </div>
                      </div>
                    }
                  </div>
                }

              )
            }
          </div>
        }
      </div>
      

    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, componentReducer, knowledgeReducer, orgReducer, guiReducer } = state;

  return {
    userReducer, 
    knowledgeReducer,
    componentReducer,
    orgReducer,
    guiReducer
  }
}

const Tool = connect(mapStateToProps)(CustomComponent);

export default {
  createInitPacket,
  createStructuredPacket,
  createDownloadPacket,
  Tool
}
