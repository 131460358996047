import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Get prototypes by organization
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestGetPrototypesByOrganization = packet => {
  return {
    type: 'REQUEST_GET_PROTOTYPES_BY_ORGANIZATION',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveGetPrototypesByOrganizationSuccess = data => ({
  type: 'RECEIVE_GET_PROTOTYPES_BY_ORGANIZATION_SUCCESS',
  data
})

export const receiveGetPrototypesByOrganizationFail = data => ({
  type: 'RECEIVE_GET_PROTOTYPES_BY_ORGANIZATION_FAIL',
  data
})

export const tryToGetPrototypesByOrganization = packet => (dispatch, getState) => {

  dispatch(requestGetPrototypesByOrganization(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/prototypes/org/' + packet.org_id, {
      method: 'get',
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveGetPrototypesByOrganizationSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveGetPrototypesByOrganizationFail({errors:e, lastSubmit: packet}));
    })
}
