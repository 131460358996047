const Compatibility = {
  COMPATIBLE: 'compatible',
  INCOMPATIBLE: 'incompatible',
  MAYBE: 'maybe'
};

const typeCheck = (value, type) => {
  if (value === null) return true;

  type = type.toLowerCase().trim();

  if (type === "any") return true;

  // Handle union types split by " or "
  if (type.includes(" or ")) {
    return type.split(" or ").some(t => typeCheck(value, t.trim()));
  }

  if (Array.isArray(type)) {
    // Union type (e.g., ["string", "object"])
    return type.some(t => typeCheck(value, t));
  } else if (type.startsWith("array of ")) {
    // Array of a specific type (e.g., "array of string" or "array of strings")
    if (!Array.isArray(value)) return false;
    const itemType = type.slice(9).replace(/s$/, ''); // Handle both singular and plural
    return value.every(item => typeCheck(item, itemType));
  } else if (type === "object") {
    return value !== null && typeof value === "object" && !Array.isArray(value);
  } else {
    return typeof value === type;
  }
};

const typeCompatible = (type1, type2) => {
  if(!type1 || !type2) return Compatibility.INCOMPATIBLE;
  type1 = type1.toLowerCase().trim();
  type2 = type2.toLowerCase().trim();

  if(type1 === type2) return Compatibility.COMPATIBLE;
  if (type1 === "any") return Compatibility.MAYBE;
  if (type2 === "any") return Compatibility.COMPATIBLE;

  // Handle union types split by " or "
  if (type1.includes(" or ")) {
    const results = type1.split(" or ").map(t => typeCompatible(t.trim(), type2));
    if (results.includes(Compatibility.COMPATIBLE)) return Compatibility.COMPATIBLE;
    if (results.includes(Compatibility.MAYBE)) return Compatibility.MAYBE;
    return Compatibility.INCOMPATIBLE;
  }
  if (type2.includes(" or ")) {
    const results = type2.split(" or ").map(t => typeCompatible(type1, t.trim()));
    if (results.includes(Compatibility.COMPATIBLE)) return Compatibility.COMPATIBLE;
    if (results.includes(Compatibility.MAYBE)) return Compatibility.MAYBE;
    return Compatibility.INCOMPATIBLE;
  }

  if (Array.isArray(type1) || Array.isArray(type2)) {
    const types1 = Array.isArray(type1) ? type1 : [type1];
    const types2 = Array.isArray(type2) ? type2 : [type2];
    const results = types1.map(t1 => types2.map(t2 => typeCompatible(t1, t2))).flat();
    if (results.includes(Compatibility.COMPATIBLE)) return Compatibility.COMPATIBLE;
    if (results.includes(Compatibility.MAYBE)) return Compatibility.MAYBE;
    return Compatibility.INCOMPATIBLE;
  }

  if (type1.startsWith("array of ") && type2.startsWith("array of ")) {
    const itemType1 = type1.slice(9).replace(/s$/, '');
    const itemType2 = type2.slice(9).replace(/s$/, '');
    return typeCompatible(itemType1, itemType2);
  }

  if (type1 === "object" && type2 === "object") {
    return Compatibility.COMPATIBLE;
  }

  return type1 === type2 ? Compatibility.COMPATIBLE : Compatibility.INCOMPATIBLE;
};

const configCompatible = (config1, config2) => {
  // safely bail if either config is missing a type
  if (!config1 || !config2) return Compatibility.INCOMPATIBLE;


  const { type: type1, allow_multiple: allowMultiple1 = false } = config1;
  const { type: type2, allow_multiple: allowMultiple2 = false } = config2;

  let compatibility = typeCompatible(type1, type2);

  if (compatibility === Compatibility.INCOMPATIBLE) {
    if (allowMultiple2 && type2.startsWith("array of ")) {
      const itemType2 = type2.slice(9).replace(/s$/, '');
      compatibility = typeCompatible(type1, itemType2);
      if (compatibility === Compatibility.COMPATIBLE) {
        return Compatibility.COMPATIBLE;
      }
    }
    if (allowMultiple1 && type1.startsWith("array of ")) {
      const itemType1 = type1.slice(9).replace(/s$/, '');
      compatibility = typeCompatible(itemType1, type2);
      if (compatibility === Compatibility.COMPATIBLE) {
        return Compatibility.COMPATIBLE;
      }
    }
  }

  return compatibility;
};


export { Compatibility, typeCheck, typeCompatible, configCompatible };