import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux';

import './SketchManager.css';

var Sketch;

class SketchManager extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);

    this.loadSketch = this.loadSketch.bind(this);
  }

  componentWillMount(){
    
    this.loadSketch();
  }

  loadSketch(){
    const { dispatch } = this.props;
    
    var sketch_id = this.props.sketch;
    Sketch = require('../../sketches/' + sketch_id + '.js').default;
  }

  componentWillReceiveProps(){
    this.loadSketch();
  }

  render(){

    return (
      <div className="sketch-manager">
        <div className="sketch-manager-inner">
          <Sketch data={this.props.data}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  // const { user } = state;
  return {
    // user
  }
}

export default connect(mapStateToProps)(SketchManager);
