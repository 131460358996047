import React, { Component } from 'react'
import { connect } from 'react-redux'

import Workbench from '../components/Workbench/Workbench.js'

class WorkbenchRoute extends Component {
  componentDidMount(){
    window.document.title = "ZeroWidth Workbench";
  }

  render(){
    const { guiReducer, featuresReducer, userReducer } = this.props;

    return <div className={"hf-stretched hf-stretched-noscroll"}>
      {
        (featuresReducer.ready && userReducer.isLoggedIn) &&
        <Workbench
          org_id={this.props.org_id}
          page={this.props.page}
          subpage={this.props.subpage}
          subpage2={this.props.subpage2}
          subpage3={this.props.subpage3}
          subpage4={this.props.subpage4}
          subpage5={this.props.subpage5}
          
          />
      }
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, featuresReducer, guiReducer } = state;

  return {
    userReducer,
    featuresReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(WorkbenchRoute);

  