import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import Checkbox from '../../kit/components/Checkbox/Checkbox.js'
import Callout from '../../kit/components/Callout/Callout.js'

import EmailVerifyForm from '../EmailVerifyForm/EmailVerifyForm.js'

import {
  tryToEditUserEmailSettings
} from '../../actions/actions.export'

class EditUserEmailSettingsForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      email_settings_marketing: props.userReducer.myData.email_settings_marketing,
      email_settings_notifications: props.userReducer.myData.email_settings_notifications,
      email_settings_digest: props.userReducer.myData.email_settings_digest,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToEditUserEmailSettings({
      email_settings_marketing: this.state.email_settings_marketing,
      email_settings_notifications: this.state.email_settings_notifications,
      email_settings_digest: this.state.email_settings_digest
    }))
  }

  render(){

    const { dispatch, userReducer } = this.props;

    return <div className="box">
      <form onSubmit={()=>{
        this.handleSubmit()
      }}>

        <h5 className="no-margin-top margin-bottom-3rem">
          Control which emails you'd like to receive
        </h5>

        
        <Checkbox 
          value={this.state.email_settings_marketing} 
          onToggle={(v)=>{ this.setState({ email_settings_marketing: v }) }}
          description="Marketing emails"
          style="align-left"
          disabled={userReducer.tryingToEditUserEmailSettings}
          />
{/*
        <Checkbox 
          value={this.state.email_settings_digest} 
          onToggle={(v)=>{ this.setState({ email_settings_digest: v }) }}
          description="Summary digests of activity on your projects and in your organizations"
          style="align-left"
          disabled={userReducer.tryingToEditUserEmailSettings}
          />*/}

        <Checkbox 
          value={this.state.email_settings_notifications} 
          onToggle={(v)=>{ this.setState({ email_settings_notifications: v }) }}
          description="Emailed versions of your notifications"
          style="align-left"
          disabled={userReducer.tryingToEditUserEmailSettings}
          />


        <div className="spacer-2rem"/>

        {
          userReducer.editUserEmailSettingsForm.errors.error &&
          <Callout
            style="danger"
            dismissable={true}
            content={userReducer.editUserEmailSettingsForm.errors.error}
          />
        }
        
        <div className="list-right">
          <CustomButton
            display="Save Changes"
            color="success"
            size="small"
            thinking={userReducer.tryingToEditUserEmailSettings}
            fail={userReducer.editUserEmailSettingsFail}
            success={userReducer.editUserEmailSettingsSuccess}
            disabled={
              this.state.email_settings_digest === userReducer.myData.email_settings_digest && 
              this.state.email_settings_notifications === userReducer.myData.email_settings_notifications && 
              this.state.email_settings_marketing === userReducer.myData.email_settings_marketing
            }
            onClick={()=>{
              this.handleSubmit()
            }}
            />
        </div>
      </form>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(EditUserEmailSettingsForm);
