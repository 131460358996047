import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// DELETE content on a component's draft
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestDeleteComponentContent = packet => {
  return {
    type: 'REQUEST_DELETE_COMPONENT_CONTENT',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveDeleteComponentContentSuccess = data => ({
  type: 'RECEIVE_DELETE_COMPONENT_CONTENT_SUCCESS',
  data
})

export const receiveDeleteComponentContentFail = data => ({
  type: 'RECEIVE_DELETE_COMPONENT_CONTENT_FAIL',
  data
})

export const tryToDeleteComponentContent = packet => (dispatch, getState) => {

  dispatch(requestDeleteComponentContent(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  
  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }

  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/components/draft/content/' + packet.component_id, {
      method: 'delete',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveDeleteComponentContentSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveDeleteComponentContentFail({errors:e, lastSubmit: packet}));
    })
}
