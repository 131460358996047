import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from './a.users.forceLogoutHandler';

import {
  
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Upload avatar
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestUploadUserAvatar = packet => {
  return {
    type: 'REQUEST_UPLOAD_USER_AVATAR',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveUploadUserAvatarSuccess = data => ({
  type: 'RECEIVE_UPLOAD_USER_AVATAR_SUCCESS',
  data
})

export const receiveUploadUserAvatarFail = data => ({
  type: 'RECEIVE_UPLOAD_USER_AVATAR_FAIL',
  data
})

export const tryToUploadUserAvatar = packet => (dispatch, getState) => {

  dispatch(requestUploadUserAvatar(packet));

  let headers = {
    // "Content-Type": "application/json",
    "Authorization": "Bearer " + Cookies.get(process.env.REACT_APP_COOKIE_AUTH),
  }

  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  var data = new FormData()
  data.append('file', packet)

  return fetch('/api/users/avatar', {
      method: 'post',
      body: data,
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveUploadUserAvatarSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveUploadUserAvatarFail({errors:e, lastSubmit: packet}));
    })
}
