import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { configureStore } from './configureStore'
import { unregister } from './registerServiceWorker';

// import reportWebVitals from './reportWebVitals';

// Load app
import App from './App.js'

// Grab bootstrap in an annoying way
import '../node_modules/bootstrap/dist/css/bootstrap-grid.css'
// import '../node_modules/bootstrap/dist/css/bootstrap-utilities.css'

// Load base styling
import './styles/index.css'

unregister();

// Create redux store
const store = configureStore();

render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(workbench.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(workbench.log);
