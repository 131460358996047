import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Set a Knowledge Base's settings
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestSetKnowledgeSettings = packet => {
  return {
    type: 'REQUEST_SET_KNOWLEDGE_SETTINGS',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveSetKnowledgeSettingsSuccess = data => ({
  type: 'RECEIVE_SET_KNOWLEDGE_SETTINGS_SUCCESS',
  data
})

export const receiveSetKnowledgeSettingsFail = data => ({
  type: 'RECEIVE_SET_KNOWLEDGE_SETTINGS_FAIL',
  data
})

export const tryToSetKnowledgeSettings = packet => (dispatch, getState) => {

  dispatch(requestSetKnowledgeSettings(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/knowledge/settings/' + packet.id, {
      method: 'put',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveSetKnowledgeSettingsSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveSetKnowledgeSettingsFail({errors:e, lastSubmit: packet}));
    })
}
