import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect.js'
import Callout from '../../kit/components/Callout/Callout.js'


import {
  tryToSetPassword
} from '../../actions/actions.export'

class SetPasswordForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      password: "",
      password_again: ""
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToSetPassword({
      password: this.state.password,
      password_again: this.state.password_again,
      invite_code: this.props.invite_code
    }))
  }


  render(){

    const { userReducer } = this.props;

    let canSubmit = this.state.password && !this.state.password_error && this.state.password_again && !this.state.password_again_error && this.state.password === this.state.password_again;


    return <div>
      <form onSubmit={()=>{
        if(canSubmit) this.handleSubmit()
      }}>

        <CustomField
          name="new_field_password"
          type="password"
          label="New Password"
          placeholder="********"
          minLength={8}
          required={true}
          disabled={userReducer.tryingToResetPassword}
          value={this.state.password}
          serverError={userReducer.resetPasswordForm.errors.password}
          lastSubmit={userReducer.resetPasswordForm.lastSubmit.password}
          onChange={(e) => {
            this.setState({
              password: e.value,
              password_error: e.error
            })
          }}
          />


        <CustomField
          name="new_field_password_verify"
          type="password"
          label="New Password Again"
          placeholder="********"
          minLength={8}
          required={true}
          disabled={userReducer.tryingToResetPassword}
          value={this.state.password_again}
          clientError={(v) => {return this.state.password !== v ? "Passwords must match." : false}}
          serverError={userReducer.resetPasswordForm.errors.password_again}
          lastSubmit={userReducer.resetPasswordForm.lastSubmit.password_again}
          onChange={(e) => {
            this.setState({
              password_again: e.value,
              password_again_error: e.error
            })
          }}
          />

        <div className="spacer-2rem"/>

        {
          userReducer.resetPasswordForm.errors.error &&
          <Callout
            style="danger"
            dismissable={true}
            content={userReducer.resetPasswordForm.errors.error}
          />
        }
        
        <div className="list-right">
          <CustomButton
            display="Set Password"
            color="success"
            thinking={userReducer.tryingToResetPassword}
            fail={userReducer.resetPasswordFail}
            success={userReducer.resetPasswordSuccess}
            disabled={!canSubmit}
            onClick={()=>{
              if(canSubmit) this.handleSubmit()
            }}
            />
        </div>
      </form>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer } = state;

  return {
    userReducer
  }
}

export default connect(mapStateToProps)(SetPasswordForm);
