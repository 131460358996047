
export const inquiryReducer = (state = {
  cache: {},
  cacheRequests: [],
  myInquiries: [],
  newInquiryForm:              {errors: {}, lastSubmit: {}},
  setInquirySettingsVisibilityForm:    {errors: {}, lastSubmit: {}},
  setInquirySettingsMetadataForm:      {errors: {}, lastSubmit: {}},
  deleteInquiryForm:    {errors: {}, lastSubmit: {}},
  refineHuntRequests: {},
  refineHuntSuccess: {},
  refineHuntFail: {},
  ready: false,
  answeringFoundationQuestionRequests: {},
  generateFoundationQuestionRequests: {},
}, action) => {

  switch(action.type){


    
    case 'REQUEST_CREATE_NEW_INQUIRY':
      return {
        ...state,
        tryingToCreateNewInquiry: true,
        newInquiryForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_NEW_INQUIRY_FAIL':
       return {
        ...state,
        tryingToCreateNewInquiry: false,
        newInquiryFail: new Date().getTime(),
        newInquiryForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_NEW_INQUIRY_SUCCESS':
      var cache = state.cache;
      cache[action.data.id] = action.data;

      var myInquiries = state.myInquiries;
      if(!myInquiries[action.data.scope]) myInquiries[action.data.scope] = [];
      myInquiries[action.data.scope].push(action.data);

      return {
        ...state,
        cache: cache,
        myInquiries: myInquiries,
        tryingToCreateNewInquiry: false,
        newInquirySuccess: new Date().getTime(),
        newInquiryForm: {errors: {}, lastSubmit: {}}
      }




      case 'REQUEST_DELETE_INQUIRY':

      return {
        ...state,
        tryingToDeleteInquiry: true,
        deleteInquiryForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }
  
    case 'RECEIVE_DELETE_INQUIRY_FAIL':
       return {
        ...state,
        tryingToDeleteInquiry: false,
        deleteInquiryFail: new Date().getTime(),
        deleteInquiryForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }
  
    case 'RECEIVE_DELETE_INQUIRY_SUCCESS':
      
      var cache = state.cache;
      delete cache[action.data.id];
      
      return {
        ...state,
        cache: cache,
        tryingToDeleteInquiry: false,
        deleteInquirySuccess: new Date().getTime(),
        deleteInquiryForm: {errors: {}, lastSubmit: {}}
      }
  
  
  



    case 'REQUEST_GET_INQUIRIES_BY_ORGANIZATION':
      return {
        ...state,
        tryingToGetInquiriesByOrganization: true
      }

    case 'RECEIVE_GET_INQUIRIES_BY_ORGANIZATION_FAIL':
       return {
        ...state,
        tryingToGetInquiriesByOrganization: false,
        getInquiriesByOrganizationFail: new Date().getTime()
      }

    case 'RECEIVE_GET_INQUIRIES_BY_ORGANIZATION_SUCCESS':
      
      var cache = state.cache;
      for(var i in action.data){
        let p = action.data[i];
        cache[p.id] = {
          ...cache[p.id],
          ...p
        };
      }

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToGetInquiriesByOrganization: false,
        getInquiriesByOrganizationSuccess: new Date().getTime()
      }


    case 'REQUEST_GET_INQUIRY':

      var cacheRequests = state.cacheRequests;
      cacheRequests.push(action.data.lastSubmit.id);

      return {
        ...state,
        cacheRequests: cacheRequests,
        tryingToGetInquiry: true
      }

    case 'RECEIVE_GET_INQUIRY_FAIL':
       return {
        ...state,
        tryingToGetInquiry: false,
        getInquiryFail: new Date().getTime()
      }

    case 'RECEIVE_GET_INQUIRY_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id] = {
        ...cache[action.data.id],
        ...action.data
      }

      return {
        ...state,
        cache: cache,
        tryingToGetInquiry: false,
        getInquirySuccess: new Date().getTime()
      }




    case 'REQUEST_SET_INQUIRY_SETTINGS_VISIBILITY':

      return {
        ...state,
        tryingToSetInquirySettingsVisibility: true,
        setInquirySettingsVisibilityForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_INQUIRY_SETTINGS_VISIBILITY_FAIL':
       return {
        ...state,
        tryingToSetInquirySettingsVisibility: false,
        setInquirySettingsVisibilityFail: new Date().getTime(),
        setInquirySettingsVisibilityForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_INQUIRY_SETTINGS_VISIBILITY_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id].visibility = action.data.visibility;

      return {
        ...state,
        cache: cache,
        tryingToSetInquirySettingsVisibility: false,
        setInquirySettingsVisibilitySuccess: new Date().getTime(),
        setInquirySettingsVisibilityForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_SET_INQUIRY_SETTINGS_METADATA':

      return {
        ...state,
        tryingToSetInquirySettingsMetadata: true,
        setInquirySettingsMetadataForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_INQUIRY_SETTINGS_METADATA_FAIL':
       return {
        ...state,
        tryingToSetInquirySettingsMetadata: false,
        setInquirySettingsMetadataFail: new Date().getTime(),
        setInquirySettingsMetadataForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_INQUIRY_SETTINGS_METADATA_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id].display_name = action.data.display_name;
      cache[action.data.id].description = action.data.description;

      return {
        ...state,
        cache: cache,
        tryingToSetInquirySettingsMetadata: false,
        setInquirySettingsMetadataSuccess: new Date().getTime(),
        setInquirySettingsMetadataForm: {errors: {}, lastSubmit: {}}
      }
    


    case 'REQUEST_INQUIRY_FOUNDATIONS_FEEDBACK':

      return {
        ...state,
        tryingToGetInquiryFoundationsFeedback: true,
        getInquiryFoundationsFeedbackForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_INQUIRY_FOUNDATIONS_FEEDBACK_FAIL':
      return {
        ...state,
        tryingToGetInquiryFoundationsFeedback: false,
        getInquiryFoundationsFeedbackFail: new Date().getTime(),
        getInquiryFoundationsFeedbackForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_INQUIRY_FOUNDATIONS_FEEDBACK_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id] = {
        ...cache[action.data.id],
        ...action.data
      }

      return {
        ...state,
        cache: cache,
        tryingToGetInquiryFoundationsFeedback: false,
        getInquiryFoundationsFeedbackSuccess: new Date().getTime(),
        getInquiryFoundationsFeedbackForm: {errors: {}, lastSubmit: {}}
      }
    

    

    case 'REQUEST_INQUIRY_FOUNDATIONS_FEEDBACK_FOLLOW_UP':

      var answeringFoundationQuestionRequests = state.answeringFoundationQuestionRequests;
      answeringFoundationQuestionRequests[action.data.lastSubmit.question] = {
        trying: true,
        lastSubmit: action.data.lastSubmit,
        errors: {}
      }

      return {
        ...state,
        answeringFoundationQuestionRequests: answeringFoundationQuestionRequests
      }

    case 'RECEIVE_INQUIRY_FOUNDATIONS_FEEDBACK_FOLLOW_UP_FAIL':

      var answeringFoundationQuestionRequests = state.answeringFoundationQuestionRequests;
      answeringFoundationQuestionRequests[action.data.lastSubmit.question] = {
        trying: false,
        fail: new Date().getTime(),
        lastSubmit: action.data.lastSubmit,
        errors: action.data.errors
      }
    
      return {
        ...state,
        answeringFoundationQuestionRequests: answeringFoundationQuestionRequests
      }

    case 'RECEIVE_INQUIRY_FOUNDATIONS_FEEDBACK_FOLLOW_UP_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.response.id] = {
        ...cache[action.data.response.id],
        ...action.data.response
      }

      var answeringFoundationQuestionRequests = state.answeringFoundationQuestionRequests;
      answeringFoundationQuestionRequests[action.data.request.question] = {
        trying: false,
        success: new Date().getTime(),
        lastSubmit: {},
        errors: {}
      }

      return {
        ...state,
        cache: cache,
        answeringFoundationQuestionRequests: answeringFoundationQuestionRequests,
        getInquiryFoundationsFeedbackFollowUpSuccess: new Date().getTime()
      }

    

      case 'REQUEST_GENERATE_INQUIRY_FOUNDATIONS_QUESTIONS':

      
      var generateFoundationQuestionRequests = state.generateFoundationQuestionRequests;
      generateFoundationQuestionRequests[action.data.lastSubmit.inquiry_id + '-' + action.data.lastSubmit.pillar + '-' + action.data.lastSubmit.dimension] = {
        trying: true,
        lastSubmit: action.data.lastSubmit,
        errors: {}
      }

      return {
        ...state,
        generateFoundationQuestionRequests: generateFoundationQuestionRequests
      }

    case 'RECEIVE_GENERATE_INQUIRY_FOUNDATIONS_QUESTIONS_FAIL':

      var generateFoundationQuestionRequests = state.generateFoundationQuestionRequests;
      generateFoundationQuestionRequests[action.data.lastSubmit.inquiry_id + '-' + action.data.lastSubmit.pillar + '-' + action.data.lastSubmit.dimension] = {
        trying: false,
        fail: new Date().getTime(),
        lastSubmit: action.data.lastSubmit,
        errors: action.data.errors
      }

      return {
        ...state,
        generateFoundationQuestionRequests: generateFoundationQuestionRequests
      }

    case 'RECEIVE_GENERATE_INQUIRY_FOUNDATIONS_QUESTIONS_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.response.id] = {
        ...cache[action.data.response.id],
        ...action.data.response
      }

      var generateFoundationQuestionRequests = state.generateFoundationQuestionRequests;
      generateFoundationQuestionRequests[action.data.request.inquiry_id + '-' + action.data.request.pillar + '-' + action.data.request.dimension] = {
        trying: false,
        success: new Date().getTime(),
        lastSubmit: {},
        errors: {}
      }

      return {
        ...state,
        cache: cache,
        generateFoundationQuestionRequests: generateFoundationQuestionRequests,
        getInquiryFoundationsFeedbackSuccess: new Date().getTime()
      }

    
    case 'REQUEST_GENERATE_REFINED_PROJECT_DESCRIPTION':

      return {
        ...state,
        tryingToGenerateRefinedProjectDescription: true,
        generateRefinedProjectDescriptionForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GENERATE_REFINED_PROJECT_DESCRIPTION_FAIL':
       return {
        ...state,
        tryingToGenerateRefinedProjectDescription: false,
        generateRefinedProjectDescriptionFail: new Date().getTime(),
        generateRefinedProjectDescriptionForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GENERATE_REFINED_PROJECT_DESCRIPTION_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id].description_refined = action.data.description_refined;

      return {
        ...state,
        cache: cache,
        tryingToGenerateRefinedProjectDescription: false,
        generateRefinedProjectDescriptionSuccess: new Date().getTime(),
        generateRefinedProjectDescriptionForm: {errors: {}, lastSubmit: {}}
      }

    
    case 'REQUEST_UPDATE_INQUIRY_HUNT':

      return {
        ...state,
        tryingToUpdateInquiryHunt: true,
        updateInquiryHuntForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_INQUIRY_HUNT_FAIL':
       return {
        ...state,
        tryingToUpdateInquiryHunt: false,
        updateInquiryHuntFail: new Date().getTime(),
        updateInquiryHuntForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_INQUIRY_HUNT_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id].hunt = action.data.hunt;
      // cache[action.data.id].perspectives = action.data.perspectives;

      return {
        ...state,
        cache: cache,
        tryingToUpdateInquiryHunt: false,
        updateInquiryHuntSuccess: new Date().getTime(),
        updateInquiryHuntForm: {errors: {}, lastSubmit: {}}
      }


    
      case 'REQUEST_REFINE_INQUIRY_HUNT':

      return {
        ...state,
        refineHuntRequests: {
          ...state.refineHuntRequests,
          [action.data.lastSubmit.inquiry_id + '_' + action.data.lastSubmit.perspective_id]: true
        }
      }

    case 'RECEIVE_REFINE_INQUIRY_HUNT_FAIL':
       return {
        ...state,
        refineHuntRequests: {
          ...state.refineHuntRequests,
          [action.data.lastSubmit.inquiry_id + '_' + action.data.lastSubmit.perspective_id]: false
        },
        refineHuntFail: {
          ...state.refineHuntFail,
          [action.data.lastSubmit.inquiry_id + '_' + action.data.lastSubmit.perspective_id]: new Date().getTime()
        }
      }

    case 'RECEIVE_REFINE_INQUIRY_HUNT_SUCCESS':
      
      var cache = state.cache;
      var index = cache[action.data.inquiry_id].perspectives.enabled.findIndex(p => p.id == action.data.perspective_id);

      if(index > -1){
        cache[action.data.inquiry_id].perspectives.enabled[index] = action.data.perspective;
      } else {
        cache[action.data.inquiry_id].perspectives.enabled.push(action.data.perspective);
      }

      return {
        ...state,
        cache: cache,
        refineHuntRequests: {
          ...state.refineHuntRequests,
          [action.data.inquiry_id + '_' + action.data.perspective_id]: false
        },
        refineHuntSuccess: {
          ...state.refineHuntSuccess,
          [action.data.inquiry_id + '_' + action.data.perspective_id]: new Date().getTime()
        }
      }
    
    


    
    case 'REQUEST_GET_INQUIRY_PERSPECTIVES':

      return {
        ...state,
        tryingToGetInquiryEnabledPerspectives: true,
      }

    case 'RECEIVE_GET_INQUIRY_PERSPECTIVES_FAIL':
      return {
        ...state,
        tryingToGetInquiryEnabledPerspectives: false,
        getInquiryEnabledPerspectivesFail: new Date().getTime(),
      }

    case 'RECEIVE_GET_INQUIRY_PERSPECTIVES_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id].perspectives = action.data;

      return {
        ...state,
        cache: cache,
        tryingToGetInquiryEnabledPerspectives: false,
        getInquiryEnabledPerspectivesSuccess: new Date().getTime(),
      }


    

    
    case 'REQUEST_UPDATE_INQUIRY_PERSPECTIVES':

      return {
        ...state,
        tryingToUpdateInquiryPerspectives: true,
        updateInquiryPerspectivesForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_INQUIRY_PERSPECTIVES_FAIL':
       return {
        ...state,
        tryingToUpdateInquiryPerspectives: false,
        updateInquiryPerspectivesFail: new Date().getTime(),
        updateInquiryPerspectivesForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_INQUIRY_PERSPECTIVES_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id].perspectives = action.data;

      return {
        ...state,
        cache: cache,
        tryingToUpdateInquiryPerspectives: false,
        updateInquiryPerspectivesSuccess: new Date().getTime(),
        updateInquiryPerspectivesForm: {errors: {}, lastSubmit: {}}
      }
    

    
    
    case 'REQUEST_GENERATE_INQUIRY_CONTEXT':

      return {
        ...state,
        tryingToGenerateInquiryContext: true,
        generateInquiryContextForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GENERATE_INQUIRY_CONTEXT_FAIL':
       return {
        ...state,
        tryingToGenerateInquiryContext: false,
        generateInquiryContextFail: new Date().getTime(),
        generateInquiryContextForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GENERATE_INQUIRY_CONTEXT_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id].context_map = action.data.context_map;

      return {
        ...state,
        cache: cache,
        tryingToGenerateInquiryContext: false,
        generateInquiryContextSuccess: new Date().getTime(),
        generateInquiryContextForm: {errors: {}, lastSubmit: {}}
      }

    
    case 'REQUEST_UPDATE_INQUIRY_CONTEXT':

      return {
        ...state,
        tryingToUpdateInquiryContext: true,
        updateInquiryContextForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_INQUIRY_CONTEXT_FAIL':
       return {
        ...state,
        tryingToUpdateInquiryContext: false,
        updateInquiryContextFail: new Date().getTime(),
        updateInquiryContextForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_INQUIRY_CONTEXT_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id].context_map = action.data.context_map;

      return {
        ...state,
        cache: cache,
        tryingToUpdateInquiryContext: false,
        updateInquiryContextSuccess: new Date().getTime(),
        updateInquiryContextForm: {errors: {}, lastSubmit: {}}
      }
    

    
    
      case 'REQUEST_UPDATE_INQUIRY_OPPORTUNITY_MAP':

      return {
        ...state,
        tryingToUpdateInquiryOpportunityMap: true,
        updateInquiryOpportunityMapForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_INQUIRY_OPPORTUNITY_MAP_FAIL':
       return {
        ...state,
        tryingToUpdateInquiryOpportunityMap: false,
        updateInquiryOpportunityMapFail: new Date().getTime(),
        updateInquiryOpportunityMapForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_INQUIRY_OPPORTUNITY_MAP_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id].opportunity_map_nodes = action.data.opportunity_map_nodes;
      cache[action.data.id].opportunity_map_links = action.data.opportunity_map_links;

      return {
        ...state,
        cache: cache,
        tryingToUpdateInquiryOpportunityMap: false,
        updateInquiryOpportunityMapSuccess: new Date().getTime(),
        updateInquiryOpportunityMapForm: {errors: {}, lastSubmit: {}}
      }

    default:
      return state
  }
}
