
export const knowledgeReducer = (state = {
  cache: {},
  infoCache: {},
  fileCache: {},
  cacheRequests: [],
  orgKnowledge: {},
  myKnowledge: [],
  newKnowledgeForm:              {errors: {}, lastSubmit: {}},
  setKnowledgeSettingsForm:    {errors: {}, lastSubmit: {}},
  addKnowledgeTeamMemberForm:    {errors: {}, lastSubmit: {}},
  deleteKnowledgeForm:    {errors: {}, lastSubmit: {}},
  ready: false,
  searchResults: {}
}, action) => {

  switch(action.type){

    
    case 'SET_CURRENT_KNOWLEDGE':
      return {
        ...state,
        current: action.data
      }


    case 'UNSET_CURRENT_KNOWLEDGE':
      return {
        ...state,
        current: undefined
      }


    case 'PASS_PROFILE_KNOWLEDGE_TO_CACHE':
      var cache = state.cache;

      var cache = state.cache;
      for(var i in action.data){
        for(var ii in action.data[i]){
          let p = action.data[i][ii];
          cache[p.id] = {
            ...cache[p.id],
            ...p
          };
        }
      }

      return {
        ...state,
        cache: cache
      }


    
    case 'REQUEST_CREATE_NEW_KNOWLEDGE':
      return {
        ...state,
        tryingToCreateNewKnowledge: true,
        newKnowledgeForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_NEW_KNOWLEDGE_FAIL':
       return {
        ...state,
        tryingToCreateNewKnowledge: false,
        newKnowledgeFail: new Date().getTime(),
        newKnowledgeForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_NEW_KNOWLEDGE_SUCCESS':
      var cache = state.cache;
      cache[action.data.id] = action.data;

      var myKnowledge = state.myKnowledge;
      if(!myKnowledge[action.data.scope]) myKnowledge[action.data.scope] = [];
      myKnowledge[action.data.scope].push(action.data);

      return {
        ...state,
        cache: cache,
        myKnowledge: myKnowledge,
        tryingToCreateNewKnowledge: false,
        newKnowledgeSuccess: new Date().getTime(),
        newKnowledgeForm: {errors: {}, lastSubmit: {}}
      }



    
    case 'REQUEST_CREATE_INFORMATION':
      return {
        ...state,
        tryingToCreateInformation: true,
        createInformationForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_INFORMATION_FAIL':
       return {
        ...state,
        tryingToCreateInformation: false,
        createInformationFail: new Date().getTime(),
        createInformationForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_INFORMATION_SUCCESS':
      var cache = state.cache;
      if(!cache[action.data.id].info) cache[action.data.id].info = [];
      cache[action.data.id].info.push(action.data.data);


      return {
        ...state,
        cache: cache,
        tryingToCreateInformation: false,
        createInformationSuccess: new Date().getTime(),
        createInformationForm: {errors: {}, lastSubmit: {}}
      }

    
    case 'REQUEST_SEARCH_INFORMATION':
      return {
        ...state,
        tryingToSearchInformation: true,
        searchInformationForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SEARCH_INFORMATION_FAIL':
        return {
        ...state,
        tryingToSearchInformation: false,
        searchInformationFail: new Date().getTime(),
        searchInformationForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SEARCH_INFORMATION_SUCCESS':

      var seachResults = state.searchResults;
      seachResults[action.data.knowledge_id] = action.data;      

      return {
        ...state,
        searchResults: seachResults,
        tryingToSearchInformation: false,
        searchInformationSuccess: new Date().getTime(),
        searchInformationForm: {errors: {}, lastSubmit: {}}
      }


    case 'REQUEST_GET_INFORMATION':
      return {
        ...state,
        tryingToGetInformation: true,
        getInformationForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GET_INFORMATION_FAIL':
       return {
        ...state,
        tryingToGetInformation: false,
        getInformationFail: new Date().getTime(),
        getInformationForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GET_INFORMATION_SUCCESS':
      var infoCache = state.infoCache;
      infoCache[action.data.information_id] = action.data.data;


      return {
        ...state,
        infoCache: infoCache,
        tryingToGetInformation: false,
        getInformationSuccess: new Date().getTime(),
        getInformationForm: {errors: {}, lastSubmit: {}}
      }


    case 'REQUEST_UPDATE_INFORMATION':
      return {
        ...state,
        tryingToUpdateInformation: true,
        updateInformationForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_INFORMATION_FAIL':
       return {
        ...state,
        tryingToUpdateInformation: false,
        updateInformationFail: new Date().getTime(),
        updateInformationForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_INFORMATION_SUCCESS':
      var infoCache = state.infoCache;
      infoCache[action.data.information_id] = action.data.data;


      return {
        ...state,
        infoCache: infoCache,
        tryingToUpdateInformation: false,
        updateInformationSuccess: new Date().getTime(),
        updateInformationForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_DELETE_INFORMATION':
      return {
        ...state,
        tryingToDeleteInformation: true,
        deleteInformationForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DELETE_INFORMATION_FAIL':
       return {
        ...state,
        tryingToDeleteInformation: false,
        deleteInformationFail: new Date().getTime(),
        deleteInformationForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DELETE_INFORMATION_SUCCESS':
      var infoCache = state.infoCache;
      infoCache[action.data.information_id] = action.data.data;


      return {
        ...state,
        infoCache: infoCache,
        tryingToDeleteInformation: false,
        deleteInformationSuccess: new Date().getTime(),
        deleteInformationForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_CRAWL_URL':
      return {
        ...state,
        tryingToCrawlURL: true,
        crawlURLForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CRAWL_URL_FAIL':
       return {
        ...state,
        tryingToCrawlURL: false,
        crawlURLFail: new Date().getTime(),
        crawlURLForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CRAWL_URL_SUCCESS':
      var infoCache = state.infoCache;
      infoCache[action.data.information_id] = action.data.data;


      return {
        ...state,
        infoCache: infoCache,
        tryingToCrawlURL: false,
        crawlURLSuccess: new Date().getTime(),
        crawlURLForm: {errors: {}, lastSubmit: {}}
      }



    case 'REQUEST_GET_KNOWLEDGE_BY_ORGANIZATION':
      return {
        ...state,
        tryingToGetKnowledgeByOrganization: true
      }

    case 'RECEIVE_GET_KNOWLEDGE_BY_ORGANIZATION_FAIL':
       return {
        ...state,
        tryingToGetKnowledgeByOrganization: false,
        getKnowledgeByOrganizationFail: new Date().getTime()
      }

    case 'RECEIVE_GET_KNOWLEDGE_BY_ORGANIZATION_SUCCESS':
      
      var cache = state.cache;
      for(var i in action.data){
      
        let p = action.data[i];
        cache[p.id] = {
          ...cache[p.id],
          ...p
        };
      }

      return {
        ...state,
        ready: true,
        cache: cache,
        tryingToGetKnowledgeByOrganization: false,
        getKnowledgeByOrganizationSuccess: new Date().getTime()
      }


    case 'REQUEST_GET_KNOWLEDGE':

      var cacheRequests = state.cacheRequests;
      cacheRequests.push(action.data.lastSubmit.id);

      return {
        ...state,
        cacheRequests: cacheRequests,
        tryingToGetKnowledge: true
      }

    case 'RECEIVE_GET_KNOWLEDGE_FAIL':
       return {
        ...state,
        tryingToGetKnowledge: false,
        getKnowledgeFail: new Date().getTime()
      }

    case 'RECEIVE_GET_KNOWLEDGE_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id] = {
        ...cache[action.data.id],
        ...action.data
      }

      return {
        ...state,
        cache: cache,
        tryingToGetKnowledge: false,
        getKnowledgeSuccess: new Date().getTime()
      }





    case 'REQUEST_SET_KNOWLEDGE_SETTINGS':

      return {
        ...state,
        tryingToSetKnowledgeSettings: true,
        setKnowledgeSettingsForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_KNOWLEDGE_SETTINGS_FAIL':
       return {
        ...state,
        tryingToSetKnowledgeSettings: false,
        setKnowledgeSettingsFail: new Date().getTime(),
        setKnowledgeSettingsForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_KNOWLEDGE_SETTINGS_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id].visibility = action.data.visibility;
      cache[action.data.id].editability = action.data.editability;
      cache[action.data.id].display_name = action.data.display_name;
      cache[action.data.id].description = action.data.description;

      return {
        ...state,
        cache: cache,
        tryingToSetKnowledgeSettings: false,
        setKnowledgeSettingsSuccess: new Date().getTime(),
        setKnowledgeSettingsForm: {errors: {}, lastSubmit: {}}
      }





    case 'REQUEST_GET_KNOWLEDGE_ACTIVITY_LOG':
      return {
        ...state,
        tryingToGetKnowledgeActivityLog: true,
      }

    case 'RECEIVE_GET_KNOWLEDGE_ACTIVITY_LOG_FAIL':
      return {
        ...state,
        tryingToGetKnowledgeActivityLog: false,
        getKnowledgeActivityLogFail: new Date().getTime()
      }

    case 'RECEIVE_GET_KNOWLEDGE_ACTIVITY_LOG_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].activityLogEmpty = false;
        if(!cache[action.data.id].activityLog || cache[action.data.id].activityLog.length === 0 || !action.data.startedAfter){
          cache[action.data.id].activityLog = action.data.data;
        } else {
          if(cache[action.data.id].activityLog[0].id !== action.data.data[0].id){
            cache[action.data.id].activityLog = cache[action.data.id].activityLog.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].activityLogEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        cache[action.data.id].activityLogEmpty = true; 
      }

      return {
        ...state,
        tryingToGetKnowledgeActivityLog: false,
        getKnowledgeActivityLogSuccess: new Date().getTime(),
        cache: cache
      }

    


    case 'REQUEST_DELETE_KNOWLEDGE':

      return {
        ...state,
        tryingToDeleteKnowledge: true,
        deleteKnowledgeForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }
  
    case 'RECEIVE_DELETE_KNOWLEDGE_FAIL':
       return {
        ...state,
        tryingToDeleteKnowledge: false,
        deleteKnowledgeFail: new Date().getTime(),
        deleteKnowledgeForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }
  
    case 'RECEIVE_DELETE_KNOWLEDGE_SUCCESS':
      
      var cache = state.cache;
      delete cache[action.data.id];
      
      return {
        ...state,
        cache: cache,
        tryingToDeleteKnowledge: false,
        deleteKnowledgeSuccess: new Date().getTime(),
        deleteKnowledgeForm: {errors: {}, lastSubmit: {}}
      }
  
    

    
    case 'REQUEST_CREATE_FILE':
      return {
        ...state,
        tryingToCreateFile: true,
        createFileForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_FILE_FAIL':
       return {
        ...state,
        tryingToCreateFile: false,
        createFileFail: new Date().getTime(),
        createFileForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_FILE_SUCCESS':
      var cache = state.cache;
      if(!cache[action.data.id].files) cache[action.data.id].files = [];
      cache[action.data.id].files.push(action.data.data);

      return {
        ...state,
        cache: cache,
        tryingToCreateFile: false,
        createFileSuccess: new Date().getTime(),
        createFileForm: {errors: {}, lastSubmit: {}}
      }

  
    case 'REQUEST_GET_FILE':
      return {
        ...state,
        tryingToGetFile: true,
        getFileForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GET_FILE_FAIL':
        return {
        ...state,
        tryingToGetFile: false,
        getFileFail: new Date().getTime(),
        getFileForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GET_FILE_SUCCESS':
      var fileCache = state.fileCache;
      fileCache[action.data.file_id] = action.data.data;


      return {
        ...state,
        fileCache: fileCache,
        tryingToGetFile: false,
        getFileSuccess: new Date().getTime(),
        getFileForm: {errors: {}, lastSubmit: {}}
      }


    case 'REQUEST_UPDATE_FILE':
      return {
        ...state,
        tryingToUpdateFile: true,
        updateFileForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_FILE_FAIL':
        return {
        ...state,
        tryingToUpdateFile: false,
        updateFileFail: new Date().getTime(),
        updateFileForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_FILE_SUCCESS':
      var fileCache = state.fileCache;
      fileCache[action.data.file_id] = action.data.data;


      return {
        ...state,
        fileCache: fileCache,
        tryingToUpdateFile: false,
        updateFileSuccess: new Date().getTime(),
        updateFileForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_DELETE_FILE':
      return {
        ...state,
        tryingToDeleteFile: true,
        deleteFileForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DELETE_FILE_FAIL':
        return {
        ...state,
        tryingToDeleteFile: false,
        deleteFileFail: new Date().getTime(),
        deleteFileForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DELETE_FILE_SUCCESS':
      var fileCache = state.fileCache;
      fileCache[action.data.file_id] = action.data.data;


      return {
        ...state,
        fileCache: fileCache,
        infoCache: {}, // clear this out so we can get fresh data
        tryingToDeleteFile: false,
        deleteFileSuccess: new Date().getTime(),
        deleteFileForm: {errors: {}, lastSubmit: {}}
      }


    default:
      return state
  }
}
