import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { push } from 'react-router-redux';
import Cookies from 'js-cookie'
import moment from 'moment'

import TabNav from '../../kit/components/TabNav/TabNav.js'
import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import Dropdown from '../../kit/components/Dropdown/Dropdown.js'
import Callout from '../../kit/components/Callout/Callout.js'

import EditOrganizationProfileForm from '../../components/OrganizationSettings/EditOrganizationProfileForm.js'
import EditOrganizationAvatarForm from '../../components/OrganizationSettings/EditOrganizationAvatarForm.js'

import OrganizationMembersList from '../../components/OrganizationSettings/OrganizationMembersList.js'
import OrganizationMembersLog from '../../components/OrganizationSettings/OrganizationMembersLog.js'

import SendOrganizationInvitesForm from '../../components/OrganizationSettings/SendOrganizationInvitesForm.js'
import OrganizationInvitesList from '../../components/OrganizationSettings/OrganizationInvitesList.js'
import OrganizationInvitesLog from '../../components/OrganizationSettings/OrganizationInvitesLog.js'

import OrganizationKeysSettings from '../OrganizationSettings/EditOrganizationKeysForm.js'


import TransferOrganizationOwnershipForm from '../../components/OrganizationSettings/TransferOrganizationOwnershipForm.js'
import DeleteOrganizationForm from 'components/OrganizationSettings/DeleteOrganizationForm.js';

import Footer from '../Footer/Footer.js'

import Hydrate from '../../components/Hydrate/Hydrate.js'

import isFeatureSwitchOn from '../../utilities/isFeatureSwitchOn'

import {
  showTooltip,
  hideTooltip
} from '../../actions/actions.export'


const menuConfig = [
  {
    display: "Profile Information",
    autoIcon: "far fa-address-card",
    value: undefined,
    disabled: (membership, org, props) => { 
      if(org.suspended) return true;
      return !membership.manage_info; 
    },
    section: 'general'
  },
  {
    display: "Avatar",
    autoIcon: "far fa-user-circle",
    value: 'avatar',
    disabled: (membership, org, props) => { 
      if(org.suspended) return true;
      return !membership.manage_info; 
    },
    section: 'general'
  },
  {
    display: "Third Party Keys",
    autoIcon: "far fa-key",
    value: 'third-party-keys',
    disabled: (membership, org, props) => { 
      if(org.suspended) return true;
      return !membership.manage_billing; 
    },
    section: 'billing'
  },
  {
    display: "Manage Members",
    autoIcon: "far fa-users",
    value: 'members',
    hideIf: org => (!org.quotas || org.quotas.members === 1),
    disabled: (membership, org, props) => { 
      if(org.suspended) return true;
      return !membership.manage_members; 
    },
    section: 'members'
  },
  {
    display: "Invite Members",
    autoIcon: "far fa-user-plus",
    value: 'invites',
    hideIf: org => (!org.quotas || org.quotas.members === 1),
    disabled: (membership, org, props) => { 
      if(org.suspended) return true;
      return !membership.manage_members; 
    },
    section: 'members'
  },


  // {
  //   display: "Manage Data",
  //   autoIcon: "far fa-file-user",
  //   value: 'manage-data',
  //   disabled: (membership, org, props) => { 
  //     if(org.suspended) return true;
  //     return org.owner !== props.userReducer.myData.id; 
  //   },
  //   section: 'danger'
  // },
  {
    display: "Transfer Ownership",
    autoIcon: "far fa-exchange",
    value: 'transfer-ownership',
    disabled: (membership, org, props) => { 
      if(org.suspended) return true;
      return org.owner !== props.userReducer.myData.id; 
    },
    section: 'danger'
  },
  // {
  //   display: "Convert Workspace",
  //   autoIcon: "far fa-exchange",
  //   value: 'convert-workspace',
  //   disabled: (membership, org, props) => { 
  //     if(org.suspended) return true;
  //     return org.owner !== props.userReducer.myData.id; 
  //   },
  //   hideIf: org => !org.personal,
  //   section: 'danger'
  // },
  {
    display: "Delete Organization",
    autoIcon: "far fa-trash",
    value: 'delete',
    disabled: (membership, org, props) => { 
      // if(org.suspended) return true;
      return org.owner !== props.userReducer.myData.id; 
    },
    section: 'danger'
  }
]

class WorkbenchOrgSettings extends Component {
  constructor(props){
    super(props);

    this.state = {}

    this.getUpdatedMenuPermissions = this.getUpdatedMenuPermissions.bind(this);
  }
  

  componentDidUpdate(){
    const { dispatch, orgReducer } = this.props;

    let org = orgReducer.memberships.find(m => m.organization.id === this.props.org_id);

    if(!org && orgReducer.membershipsReady){
      dispatch(push("/oops"));
    }

    if(orgReducer.membershipsReady && org){
      let menu = this.getUpdatedMenuPermissions();
      let currentPage = menu.find(m => m.value === this.props.page);
      if(!currentPage) return dispatch(push("/oops"));
      if(currentPage.disabled && !org.organization.suspended){
        // dispatch(push("/oops"));
      }
    }
  }


  getUpdatedMenuPermissions(){
    const { dispatch, userReducer, orgReducer } = this.props;

    let menu = [];
    let membership;
    let org;
    for(var i in orgReducer.memberships){
      if(orgReducer.memberships[i].organization.id === this.props.org_id){
        membership = orgReducer.memberships[i].membership;
        org = orgReducer.memberships[i].organization;
      }
    }
      
    if(org && membership){
      menu = menuConfig.map((m, i) => {
        let j = {...m};
        j.disabled = m.disabled(membership, org, this.props);
        return j;
      })

      for(var i in menu){
        if(menu[i].hideIf){
          menu[i].hideIf = menu[i].hideIf(org);
        }
      }
    }

    return menu;
  }

  render(){

    const { dispatch, userReducer, orgReducer, projectReducer, guiReducer } = this.props;

    let membership;
    let org;
    let needsSettingsAccess;
    for(var i in orgReducer.memberships){
      if(orgReducer.memberships[i].organization.id === this.props.org_id){
        needsSettingsAccess = orgReducer.memberships[i].needsSettingsAccess;
        membership = orgReducer.memberships[i].membership;
        org = orgReducer.memberships[i].organization;
      }
    }

    if(!needsSettingsAccess) dispatch(push('/oops'));

    let menu = this.getUpdatedMenuPermissions();

    let currentMenuItem = menu.find(m => m.value === this.props.page);
  
    if(!currentMenuItem) return <span/>;


    return <div className="workbench-component">
      <div className="flex-split flex-split-stretch flex-grow">
          <div className="workbench-kind-left-menu" style={{minWidth: '225px', width: '225px'}}>
            <div className="workbench-kind-left-menu-inner">
              <div className="flex-grow">
              
                <div className="margin-left-1rem margin-top-1rem margin-bottom-1rem">
                  <small className="text-900 text-uppercase">Organization Profile</small>
                </div>
                <TabNav
                  onChange={(v)=>{
                    if(v){
                      dispatch(push('/workbench/' + org.id + '/settings/' + v));
                    } else {
                      dispatch(push('/workbench/' + org.id + '/settings'));
                    }
                  }}
                  value={this.props.page}
                  vertical={true}
                  items={menu.filter(m => m.section === "general")}
                  />

                {
                  (org.type || "personal") !== "personal" &&
                  <div>
                    <div className="margin-left-1rem margin-bottom-05rem margin-top-2rem">
                      <small className="text-900 text-uppercase">
                        Members
                      </small>
                    </div>
                    <TabNav
                      onChange={(v)=>{
                        if(v){
                          dispatch(push('/workbench/' + org.id + '/settings/' + v));
                        } else {
                          dispatch(push('/workbench/' + org.id + '/settings'));
                        }
                      }}
                      value={this.props.page}
                      vertical={true}
                      items={menu.filter(m => m.section === "members")}
                      />
                  </div>
                }
                
                <div className="margin-left-1rem margin-bottom-05rem margin-top-2rem">
                  <small className="text-900 text-uppercase">
                    Billing
                  </small>  
                </div>

                <TabNav
                  onChange={(v)=>{
                    if(v){
                      dispatch(push('/workbench/' + org.id + '/settings/' + v));
                    } else {
                      dispatch(push('/workbench/' + org.id + '/settings'));
                    }
                  }}
                  value={this.props.page}
                  vertical={true}
                  items={menu.filter(m => m.section === "billing")}
                  />

                <div className="margin-left-1rem margin-bottom-05rem margin-top-2rem text-danger">
                  
                  <small className="text-900 text-uppercase">
                    Danger Zone
                  </small>
                </div>

                <TabNav
                  onChange={(v)=>{
                    if(v){
                      dispatch(push('/workbench/' + org.id + '/settings/' + v));
                    } else {
                      dispatch(push('/workbench/' + org.id + '/settings'));
                    }
                  }}
                  value={this.props.page}
                  vertical={true}
                  items={menu.filter(m => m.section === "danger")}
                  /> 
                
                <div className="padding-left-1rem padding-right-1rem text-center">
                  <hr/>
                  <div className="margin-bottom-1rem thin-line-height text-semi-muted">
                    <small>Looking to change your account's password or email?</small>
                  </div>
                <CustomButton
                  display="Go to Account Settings"
                  size="xs"
                  block={true}
                  color="grey"
                  to="/workbench/my-account/settings"
                  />
                </div>
            </div>
          </div>
        </div>
        <div className="flex-grow workbench-kind-right-half scroll-parent">
          <div className="scroll-child workbench-body-padding flex-column-stretch">
            <div className="row flex-grow">
                  
                
              <div className="col-md-9">
                {
                  org.suspended && <Callout
                    title="Your organization has been suspended."
                    content={<div>
                        {
                          org.suspended_until === "forever" ? 
                          <div>
                            <p>
                              This is a permanent suspension. The only action that can be taken is deleting the organization.
                            </p>
                          </div>
                          :
                          <div>
                            <p>
                              This suspension will lift <span className="text-700">{moment(org.suspended_until).format("dddd, MMMM Do YYYY @ h:mm:ss a")}</span>. Until then, the only action available is deleting the organization.
                            </p>
                          </div>
                        }
                        {
                          org.owner === userReducer.myData.id ?
                          <p>
                            As this organization's owner, you are the only member who can take this action.
                          </p>
                          :
                          <p>
                            This can only be done by the organization's current owner: <Hydrate mode="basic" tooltip={true} link={true} type="user" id={org.owner} field="display_name"/>
                          </p>
                        }
                        <div>
                          If you have questions or need assistance, please reach out to us: <a href="mailto:contact@zerowidth.ai">contact@zerowidth.ai</a>
                        </div>
                      </div>
                    }
                  />
                }
                {
                  currentMenuItem.disabled && !org.suspended && <Callout
                    title="Permission Denied"
                    content={<div>
                      <p>
                        You do not have the necessary permissions to access this page. Please select another option from the menu.
                      </p>
                    </div>}
                    />
                }
                {
                  (!this.props.page && !org.suspended && !currentMenuItem.disabled) &&
                  <div className="row">
                    <div className="col-md-12">
                      <div className="margin-bottom-2rem margin-top-05rem">
                        <h4 className="no-margin">Name & Info</h4>
                        <small>
                          Update the name and other information for this organization.
                        </small>
                      </div>
                      <EditOrganizationProfileForm id={this.props.org_id}/>
                    </div>
                  </div>
                }

                {
                  (this.props.page === "avatar" && !org.suspended && !currentMenuItem.disabled) &&
                  <div className="row">
                    <div className="col-md-12">
                      <div className="margin-bottom-2rem margin-top-05rem">
                        <h4 className="no-margin">Organization Avatar</h4>
                        <small>
                          Update the avatar for this organization.
                        </small>
                      </div>
                      <EditOrganizationAvatarForm id={this.props.org_id}/>
                    </div>
                  </div>
                }

                {
                  (this.props.page === "third-party-keys" && !org.suspended && !currentMenuItem.disabled) &&
                  <div className="row">
                    <div className="col-md-12">
                      <OrganizationKeysSettings id={this.props.org_id}/>
                    </div>
                  </div>
                }

                {
                  (this.props.page === "members" && !org.suspended && !currentMenuItem.disabled) &&
                  <div className="row">
                    <div className="col-md-12">

                      <div className="margin-bottom-2rem margin-top-05rem">
                        <h4 className="no-margin">Organization Members</h4>
                        <small>
                          Manage members of this organization and their permissions.
                        </small>
                      </div>
                      <OrganizationMembersList id={this.props.org_id}/>
                      <OrganizationMembersLog id={this.props.org_id}/>
                    </div>
                  </div>
                }


                {
                  (this.props.page === "invites" && !org.suspended && !currentMenuItem.disabled) &&
                  <div className="row">
                    <div className="col-md-12">
                      <div className="margin-bottom-2rem margin-top-05rem">
                        <h4 className="no-margin">Organization Invites</h4>
                        <small>
                          Send and manage invites to join this organization.
                        </small>
                      </div>
                      <SendOrganizationInvitesForm id={this.props.org_id}/>
                      <OrganizationInvitesList id={this.props.org_id}/>
                      <OrganizationInvitesLog id={this.props.org_id}/>
                    </div>
                  </div>
                }



                {
                  (this.props.page === "transfer-ownership" && !org.suspended && !currentMenuItem.disabled) &&
                  <div className="row">
                    <div className="col-md-12">
                      <TransferOrganizationOwnershipForm id={this.props.org_id}/>
                    </div>
                  </div>
                }

                {
                  (this.props.page === "delete" && !org.suspended && !currentMenuItem.disabled) &&
                  <div className="row">
                    <div className="col-md-12">
                      <DeleteOrganizationForm id={this.props.org_id}/>
                    </div>
                  </div>
                }

              </div>
              <div className="col-md-3">
                
              </div>
            </div>
            <Footer simple={true} padless={true}/>
          </div>
        </div>
      </div>
    </div>

  }
}
  

const mapStateToProps = (state) => {
  const { userReducer, orgReducer, projectReducer, guiReducer } = state;

  return {
    userReducer,
    orgReducer,
    projectReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(WorkbenchOrgSettings);
