import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'

import './Callout.scss';

import CustomButton from '../CustomButton/CustomButton'

class Callout extends Component {
  constructor(props){
    super(props);

    this.state = {
      show: true
    }

    this.handleDismissMemory = this.handleDismissMemory.bind(this);
  }

  handleDismissMemory(){
    if(this.props.dismissID){
      // set a cookie that expires in a day
      Cookies.set('zwcallout_' + this.props.dismissID, true, { expires: 1 });
    }
  }

  componentDidMount(){
    // check if there is a dismissID and if it has been dismissed
    if(this.props.dismissID){
      if(Cookies.get('zwcallout_' + this.props.dismissID)){
        this.setState({
          show: false
        })
      }
    }
  }

  render(){

    let classList = "";

    if(this.props.style) classList += " callout-" + this.props.style;
    if(this.props.mini) classList += " callout-mini";
    if(this.props.dismissable) classList += " callout-dismissable";

    if(!this.state.show || this.props.hide) return <span/>;

    return <div className={"callout " + classList}>
        {
          this.props.dismissable && <div className="callout-exit-button" onClick={()=>{
            this.handleDismissMemory();
            if(this.props.onDismiss) this.props.onDismiss();
            this.setState({
              show: false
            })
          }}>
            <i className="fal fa-times"/>
          </div>
        }
        { 
          this.props.title && 
          <h5 className="no-margin-top margin-bottom-1rem">
            {this.props.style === "info" && <i className="fas fa-info-circle callout-title-icon"/>}
            {this.props.style === "warning" && <i className="fas fa-exclamation-triangle callout-title-icon"/>}
            {this.props.style === "danger" && <i className="fas fa-exclamation-triangle callout-title-icon"/>}
            {this.props.style === "success" && <i className="fas fa-check callout-title-icon"/>}

            {this.props.title}
          </h5>
        }
        
        { this.props.customContent }
        { this.props.content && <div className="callout-basic-content">{this.props.content}</div>}
      </div>
  }
}

export default Callout
  