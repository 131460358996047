import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import Callout from '../../kit/components/Callout/Callout.js'
import Modal from '../../kit/components/Modal/Modal.js'

import Hydrate from '../../components/Hydrate/Hydrate.js'

import isFeatureSwitchOn from '../../utilities/isFeatureSwitchOn'

import {
  tryToLeaveOrganization
} from '../../actions/actions.export'

class UserMemberships extends Component {
  constructor(props){
    super(props);

    this.state = {
      leaveOrganizationModal: false,
      organizationToLeaveIndex: undefined
    }
  }

  componentWillReceiveProps(newprops){
    if(new Date().getTime() - newprops.orgReducer.leaveOrganizationSuccess < 500 && this.state.leaveOrganizationModal){
      this.setState({
        leaveOrganizationModal: false,
        organizationToLeaveIndex: undefined
      })
    }
  }

  render(){

    const { dispatch, userReducer, orgReducer, featuresReducer } = this.props;

    let roles = [
      {
        name: 'manage_members',
        display_name: 'Add & Manage Members',
        description: "Invite new members to the organization, edit privileges of existing members, and remove members."
      },
      {
        name: 'manage_billing',
        display_name: 'Manage Billing',
        description: "Upgrade or downgrade paid features, edit payment methods, and access invoice history for this organization."
      },
      {
        name: 'create',
        display_name: 'Create New Projects',
        description: "Create new projects in this organization and edit access to them."
      },
      {
        name: 'manage_content',
        display_name: 'Manage All Projects',
        description: "Manage access, data, and details about any project in this organization, including those you weren't invited to."
      }
    ]

    if(orgReducer.tryingToGetMemberships){
      return <div className="box flex-column-center box-extra-pad">
        <i className="fad fa-spinner-third fa-spin"/>
      </div>
    }

    if(orgReducer.memberships.filter(m => !m.organization.personal).length === 0 && !orgReducer.tryingToGetMemberships){
      return <div className="box flex-column-center box-extra-pad">
        <p className="text-700">You aren't a member of any organizations</p>
        {
          isFeatureSwitchOn('create_organization', userReducer, featuresReducer) && 
          <div className="flex-column-center">
            <p className="text-smaller text-center margin-bottom-3rem">You can be invited to join existing organizations,<br/>or you can create your own for free!</p>
          
            <CustomButton
              display="Create a New Organization"
              to="/new/organization"
              color="success"
              />
            <div className="spacer-2rem"/>
          </div>
        }
      </div>
    }

    return <div>
      <div className="margin-bottom-2rem margin-top-05rem">
        <h4 className="no-margin">Organization Memberships</h4>
        <small>
          You can leave organizations you're a member of, but you can't leave your personal organization.
        </small>
      </div>
      {
        orgReducer.memberships.map((m, i)=>{

          let adminRoles = roles.filter(r=> m.membership[r.name]);

          if(m.organization.personal) return;

          return <div className="box box-extra-pad" key={i}>
            <div className="flex-split ">
              <div className="list-left">
                <Hydrate mode="avatar" id={m.organization.id} type="organization" size={40}/>
                <div>
                  <div className="text-900">
                    <Hydrate  id={m.organization.id} type="organization" field="display_name"/>
                  </div>
                  <div>
                    <small className="text-muted">
                      {
                        m.organization.owner === userReducer.myData.id ?
                        "Created by you "
                        :
                        "Joined "
                      }
                      {moment(m.membership.joined_on).format("dddd, MMMM Do YYYY")}
                    </small>
                  </div>
                </div>
              </div>
              <div className="list-right">
                {
                  m.organization.owner === userReducer.myData.id ?
                  <CustomButton
                    display={<span><i className="far fa-exchange icon-before-text"/> Transfer Ownership</span>}
                    to={"/workbench/" + m.organization.id + "/settings/transfer-ownership"}
                    color="grey"
                    size="xs"
                    />
                  :
                  <CustomButton
                    display={<span><i className="far fa-door-open icon-before-text"/> Leave Organization</span>}
                    size="xs"
                    color="grey"
                    onClick={()=>{
                      this.setState({
                        leaveOrganizationModalOpen: true,
                        organazationIndexToLeave: i
                      })
                    }}
                    />
                }
                {
                  m.organization.owner === userReducer.myData.id &&
                  <CustomButton
                    display={<span><i className="far fa-trash icon-before-text"/> Delete</span>}
                    to={"/workbench/" + m.organization.id + "/settings/delete"}
                    color="danger"
                    size="xs"
                    />
                }
              </div>
            </div>
            {
              // adminRoles.length > 0 &&
              // <div>
              //   <p className="">Your current administrative privileges:</p>
              //   {
              //     adminRoles.map((r, j)=>{
              //       return <div className="subtle-section" key={j}>
              //         <div className="margin-bottom-05rem text-700">
              //           {r.display_name}
              //         </div>                    
              //         <div className="list-left margin-bottom-05rem no-margin-top text-smaller">
              //           <span>{r.description}</span>
              //         </div>
              //       </div>
              //     })
              //   }
              // </div>
            }
          </div>
        })
      }

      {
        (this.state.leaveOrganizationModalOpen && this.state.organazationIndexToLeave !== undefined && orgReducer.memberships[this.state.organazationIndexToLeave]) &&
        <Modal
          maxWidth={500}
          show={this.state.leaveOrganizationModalOpen}
          content={<div>
              <h3 className="no-margin">Leave Organization</h3>
              <p>Are you sure you want to leave <span className="text-900">{orgReducer.memberships[this.state.organazationIndexToLeave].organization.display_name}</span>?</p>
              <p>If you leave, you'll need to be reinvited in order to rejoin.</p>
              <div className="modal-inner-button-row">
                <CustomButton
                  display="Cancel"
                  disabled={orgReducer.tryingToLeaveOrganization}
                  onClick={()=>{ 
                    this.setState({ leaveOrganizationModalOpen: false }) 
                  }}
                  />
                <CustomButton
                  display={"Leave Organization"}
                  color="danger"
                  thinking={orgReducer.tryingToLeaveOrganization}
                  fail={orgReducer.leaveOrganizationFail}
                  success={orgReducer.leaveOrganizationSuccess}
                  onClick={()=>{ 
                    dispatch(tryToLeaveOrganization({
                      id: orgReducer.memberships[this.state.organazationIndexToLeave].organization.id
                    }))
                  }}
                  />
              </div>
            </div>}
          exitable={true}
          onExit={()=>{ 
            this.setState({ leaveOrganizationModalOpen: false }) 
          }}
          />
        }
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, orgReducer, featuresReducer } = state;

  return {
    userReducer, orgReducer, featuresReducer
  }
}

export default connect(mapStateToProps)(UserMemberships);
