import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import './QueryChartWrapper.scss';

import {
  tryToQueryOrganizationData,
  tryToQueryProjectData
} from '../../actions/actions.export'


class QueryChartWrapper extends Component {

  constructor(props){
    super(props);

    this.state = {
      
    }
  }

  componentWillMount(){
    const { dispatch } = this.props;

    switch(this.props.queryType){
      case 'organization':
        dispatch(tryToQueryOrganizationData({
          id: this.props.orgID,
          cacheID: this.props.cacheID,
          query: this.props.query,
          parameters: this.props.parameters || {}
        }))
        break;

      case 'project':
        dispatch(tryToQueryProjectData({
          id: this.props.projectID,
          cacheID: this.props.cacheID,
          query: this.props.query,
          parameters: this.props.parameters || {}
        }))
        break;
    }
  }
  
  render(){

    const { dispatch, orgReducer, projectReducer } = this.props;

    let queryResultsCache = {};
    
    switch(this.props.queryType){
      case 'organization':
        queryResultsCache = orgReducer.queryResultsCache;
        break;
      case 'project':
        queryResultsCache = projectReducer.queryResultsCache;
        break;
    }

    const cache = queryResultsCache[this.props.cacheID] || {};
    const queryResults = cache[this.props.query] || {};
    const trying = queryResults.trying;
    const success = queryResults.success;
    const fail = queryResults.fail;

    const showFail = (fail && !success && !trying) || (fail > success && !trying);
    let data = [];
    if(!showFail){
      data = queryResults.data || [];
    }

    const chart = this.props.chart(data);

    return <div className={"query-chart-wrapper"}>
      <div className="query-chart-wrapper-title">
        <small className="text-900 text-uppercase text-gs5">{this.props.chartTitle}</small>
      </div>
      <div className="query-chart-wrapper-inner" style={{minHeight: this.props.height || 0}}>
        {chart}
      </div>
    </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, orgReducer, projectReducer, router } = state;

  return {
    userReducer, orgReducer, projectReducer, router
  }
}

export default connect(mapStateToProps)(QueryChartWrapper);
