import React, { Component } from 'react'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton'
import CustomField from '../../kit/components/CustomField/CustomField'
import CustomSelect from '../../kit/components/CustomSelect/CustomSelect'
import Callout from '../../kit/components/Callout/Callout'

import isFeatureSwitchOn from '../../utilities/isFeatureSwitchOn'

import {
  showTooltip,
  hideTooltip,
  tryToCreateNewInquiry
} from '../../actions/actions.export'


class NewInquiryForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      display_name: "",
      entry: "foundations",
      visibility: "org",
      description: "",
      type: undefined
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToCreateNewInquiry({
      display_name: this.state.display_name,
      scope: this.props.org_id,
      description: this.state.description,
      visibility: this.state.visibility,
      entry: this.state.entry
    }))
  }

  
  render(){

    const { dispatch, inquiryReducer, orgReducer } = this.props;

    let membership;
    if(orgReducer.memberships.length > 0){
      membership = orgReducer.memberships.find(m => m.organization.id === this.props.org_id).membership;
    }

    if(!membership) return <span/>;

    let canSubmit = this.state.display_name && !this.state.display_name_error && this.state.description && !this.state.description_error && this.state.entry;

    return <div className="box box-inquiries-left">
        <form onSubmit={()=>{
          if(canSubmit) this.handleSubmit()
        }}>
          <div className="flex-split margin-bottom-2rem">
            <h3 className="no-margin">
              New Project
            </h3>
            <i className="fas fa-lightbulb-on text-inquiries fa-2x text-muted"/>
          </div>
          <p className="no-margin-top ">
            Before diving into agent design, this upfront project scoping tool is an AI-powered way to unpack, evaulate, investigate, and understand the context of a product design, project, or research plan.
          </p>

          <hr/>

          <div className="row">
            <div className="col-md-6">
              <CustomField
                name="field_new_thing_name"
                label="Name"
                maxLength={64}
                placeholder="Startup 55, Project X, etc."
                description="This can be changed later."
                required={true}
                disabled={inquiryReducer.tryingToCreateNewInquiry}
                value={this.state.display_name}
                serverError={inquiryReducer.newInquiryForm.errors.display_name}
                lastSubmit={inquiryReducer.newInquiryForm.lastSubmit.display_name}
                onChange={(e) => {
                  this.setState({
                    display_name: e.value,
                    display_name_error: e.error
                  })
                }}
                />
            </div>
            <div className="col-md-6">
              <CustomSelect
                label="Set Visibility"
                required={true}
                description={<div>
                  {!this.state.visibility && "Adjust who can see this."}
                  {(this.state.visibility === "org") && "This will be visible to all members of your organization."}
                  {(this.state.visibility === "team") && "This will only be visible to those you share it with."}

                </div>}
                value={this.state.visibility}
                onChange={(v)=>{
                  this.setState({
                    visibility: v
                  })
                }}
                options={[
                  {
                    label: <span><i className="far fa-fw fa-eye icon-before-text"/> <span className="text-900">Anyone at this organization</span></span>,
                    value: "org"
                  },
                  {
                    label: <span><i className="far fa-fw fa-lock icon-before-text"/> <span className="text-900">Invite Only</span></span>,
                    value: "team"
                  }
                ]}
                />
            </div>
          </div>

          <CustomField
            name="field_new_inquiry_description"
            label="What are you aiming to do?"
            maxLength={10000}
            placeholder="ex: Integrate gen AI into my healthcare doctor patient relationship management app."
            description="Don't worry about getting this perfect, these tools will help you refine it."
            rows={3}
            required={true}
            disabled={inquiryReducer.tryingToCreateNewInquiry}
            value={this.state.description}
            serverError={inquiryReducer.newInquiryForm.errors.description}
            lastSubmit={inquiryReducer.newInquiryForm.lastSubmit.description}
            onChange={(e) => {
              this.setState({
                description: e.value,
                description_error: e.error
              })
            }}
            /> 
          <div className="spacer-05rem"/>

          <div className="row">
            <div className="col-12">
              <p className="text-900">
                Where would you like to start?
              </p>
              <p className="p-small margin-bottom-2rem">
                Well defined projects have a better chance at achieving their goals. These scoping tools offer a variety of starting points to help you define your project. Each of these tools will be available to you, but depending on your current understanding of your project, one might be a better place to start.
              </p>
            </div>
            <div className="col-md-4 flex-column-stretch">
              <div className={"box flex-grow no-margin-bottom " + (this.state.entry === 'foundations' ? 'box-border-black box-clickable ' : ' box-clickable box-placeholder box-muted')} onClick={e => {
                this.setState({ 
                  entry: 'foundations'
                })
              }}>
                <h5 className="no-margin">
                  <i className="fal fa-cubes icon-before-text"/>Project Foundations
                </h5>
                <p>
                  Assess and refine your description across multiple dimensions.
                </p>
                <p className="thin-line-height no-margin-bottom">
                  <small className="text-semi-muted">
                    Start here if you have a general idea but aren't sure of the specifics.
                  </small>
                </p>
              </div>
            </div>
            
            <div className="col-md-4 flex-column-stretch">
              <div className={"box flex-grow no-margin-bottom " + (this.state.entry === 'context' ? 'box-border-black box-clickable ' : ' box-clickable box-placeholder box-muted')} onClick={e => {
                this.setState({ 
                  entry: 'context'
                })
              }}>
                <h5 className="no-margin">
                  <i className="fal fa-chart-network icon-before-text"/>Context Exploration
                </h5>
                <p>
                  Discover both the business and socio-cultural context of your project.
                </p>
                <p className="thin-line-height no-margin-bottom">
                  <small className="text-semi-muted">
                    Start here if you have an existing idea for how to apply AI and want to understand the context.
                  </small>
                </p>
              </div>
            </div>

            <div className="col-md-4 flex-column-stretch">
              <div className={"box flex-grow no-margin-bottom " + (this.state.entry === 'workflow' ? 'box-border-black box-clickable ' : ' box-clickable box-placeholder box-muted')} onClick={e => {
                this.setState({ 
                  entry: 'workflow'
                })
              }}>
                <h5 className="no-margin">
                  <i className="fal fa-project-diagram icon-before-text"/>Workflow Mapping
                </h5>
                <p>
                  Map out your existing process and identify where AI can help.
                </p>
                <p className="thin-line-height no-margin-bottom">
                  <small className="text-semi-muted">
                    Start here if you have a existing process to unpack and improve.
                  </small>
                </p>
              </div>
            </div>
          </div>


          <div className="spacer-2rem"/>

          {
            inquiryReducer.newInquiryForm.errors.error &&
            <Callout
              style="danger"
              dismissable={true}
              content={inquiryReducer.newInquiryForm.errors.error}
            />
          }
          
          <div className="list-right">

            <CustomButton
              display="Cancel"
              color="transparent"
              size="small"
              to={"/workbench/" + this.props.org_id + '/inquiries'}
              />

            <CustomButton
              display="Create Project"
              color="black"
              thinking={inquiryReducer.tryingToCreateNewInquiry}
              fail={inquiryReducer.newInquiryFail}
              success={inquiryReducer.newInquirySuccess}
              disabled={!canSubmit || !membership.create_inquiries}
              onClick={()=>{
                if(canSubmit) this.handleSubmit()
              }}
              />
          </div>
        </form>          
      </div>
  }
}
  
const mapStateToProps = (state) => {
  const { userReducer, orgReducer, inquiryReducer, guiReducer, postReducer, intelReducer  } = state;

  return {
    userReducer,
    orgReducer,
    inquiryReducer,
    guiReducer, 
    postReducer, 
    intelReducer
  }
}

export default connect(mapStateToProps)(NewInquiryForm);
