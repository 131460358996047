import React, { Component } from 'react'
import { connect } from 'react-redux'
import CustomButton from '../kit/components/CustomButton/CustomButton.js'


import Header from '../components/Header/Header.js'
import Footer from '../components/Footer/Footer.js'
import ASCIIBackground from '../components/ASCIIBackground/ASCIIBackground.js'

class ContactRoute extends Component {
  componentDidMount(){
    window.document.title = "zerowidth.ai - contact";
  }

  render(){

    let hfStretchedClassList = "hf-stretched";
    
    return <div className={hfStretchedClassList}>     
    <div className="fixed-big-image">
      <ASCIIBackground 
        imagePath={'/home-img/contact.png'} 
        foreground={'#C0C7D3'} 
        background={'#fff'} 
        autoTween={1000} 
        fontSize={16}
        placementMode={'cover'}
        />
    </div> 
      <Header borderless={true}/>
      <div className="body flex-column-center-center">
        <h1 className="no-margin">contact</h1>
        <ul className="ul-iconed">
          <li className="margin-bottom-1rem">
          <i className="fas fa-envelope fa-fw icon-before-text"/>Send us an email to <a href="mailto:contact@zerowidth.ai">contact@zerowidth.ai</a>
          </li>
          <li className="margin-bottom-1rem">
            <i className="fab fa-discord fa-fw icon-before-text"/>Join our community on <a href="https://discord.gg/ypcwTZeWxX" target="_blank">Discord</a>
          </li>
          <li className="margin-bottom-1rem">
            <i className="fab fa-youtube fa-fw icon-before-text"/>Subscribe to our <a href="https://www.youtube.com/channel/UCAFB_ciSIi3q0rAssPhBwTA" target="_blank">YouTube channel</a>
          </li>
          <li className="margin-bottom-1rem">
            <i className="fab fa-linkedin fa-fw icon-before-text"/>Find us on <a href="https://www.linkedin.com/company/zerowidth" target="_blank">LinkedIn</a>
          </li>
        </ul>
        
        <div className="spacer-2rem"/>
        <CustomButton
          to="/"
          color="grey"
          display="Go Home"
          size="xs"
          />
      </div>
      <Footer simple={true}/>
    </div>
  }
}

const mapStateToProps = (state) => {
  const { userReducer, guiReducer } = state;

  return {
    userReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(ContactRoute);

  