import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomButton from '../../kit/components/CustomButton/CustomButton.js'
import CustomField from '../../kit/components/CustomField/CustomField.js'
import Checkbox from '../../kit/components/Checkbox/Checkbox.js'
import Modal from '../../kit/components/Modal/Modal.js'
import Callout from '../../kit/components/Callout/Callout.js'

import Hydrate from '../Hydrate/Hydrate.js'


import {
  tryToDeleteOrganization
} from '../../actions/actions.export.js'

class DeleteOrganizationForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      password: "",
      orgName: ""
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleSubmit(){
    const { dispatch } = this.props;

    dispatch(tryToDeleteOrganization({
      id: this.props.id,
      password: this.state.password,
    }))
  }

  render(){

    const { dispatch, userReducer, orgReducer } = this.props;

    if(!orgReducer.membershipsReady) return <span/>;
    

    // check to see if you are the owner of this org
    const organization = orgReducer.cache[this.props.id];
    let isOwner = organization.owner === userReducer.myData.id;

    const canSubmit = this.state.orgName === organization.display_name && this.state.password && !this.state.password_error && this.state.agreeCheckbox && isOwner;
    
    if(!isOwner) return <span/>;

    return <div className="">
      
      <div className="margin-bottom-2rem margin-top-05rem">
        <h4 className="no-margin">Delete Organization</h4>
        <small>
          Permanently delete this organization and all its content.
        </small>
      </div>
      <div className="box">

        {
          isOwner ? 
          <div>
            <h5 className="no-margin-top">
              Deleting your organization is permanent.
            </h5>
            <p>
              You will immediately lose access to all its data and content. All members will be removed and all content will be deleted. All active APIs will be disabled. All agents and their demo pages will no longer function. All Knowledge Bases and their content will be deleted.
            </p>
          </div>
          :
          <div>
            <h5 className="no-margin-top">
              You are not the owner of this organization.
            </h5>
            <p>
              Only the owner of an organization can delete it. If you are not the owner, you can only leave the organization by removing yourself as a member via your <Link to="/workbench/my-account/settings/memberships">Account Settings</Link>.
            </p>
          </div>
        }

        <div className="spacer-2rem"/>

        <div className="list-right">
          
          <CustomButton
            color="danger"
            disabled={!isOwner}
            size="small"
            display="Delete Organization"
            onClick={()=>{ this.setState({deleteOrganizationModalOpen:true}) }}
            />
        </div>

        <Modal
          maxWidth={500}
          show={this.state.deleteOrganizationModalOpen}
          content={<div>
              <h4 className="no-margin">Confirm Deletion</h4>
              <p>Are you sure you want to permanently delete {organization.display_name}?</p>
              <p>Please enter your password and the name of this organization to confirm. This cannot be undone.</p>

              <CustomField
                name="field_password"
                type="password"
                label="Your Password"
                placeholder="********"
                required={true}
                thinking={false}
                disabled={orgReducer.tryingToDeleteOrganization}
                description="Please type your password to confirm."
                value={this.state.password}
                serverError={orgReducer.deleteOrganizationForm.errors.password}
                lastSubmit={orgReducer.deleteOrganizationForm.lastSubmit.password}
                onChange={(e) => {
                  this.setState({
                    password: e.value,
                    password_error: e.error
                  })
                }}
                />

              <CustomField
                name="field_orgName"
                label="Organization Name"
                placeholder={organization.display_name}
                description="Please type the name of this organization to confirm."
                required={true}
                thinking={false}
                disabled={orgReducer.tryingToDeleteOrganization}
                value={this.state.orgName}
                serverError={orgReducer.deleteOrganizationForm.errors.orgName}
                lastSubmit={orgReducer.deleteOrganizationForm.lastSubmit.orgName}
                onChange={(e) => {
                  this.setState({
                    orgName: e.value,
                    orgName_error: e.error
                  })
                }}
                />
              
              <Checkbox 
                value={this.state.agreeCheckbox} 
                onToggle={(v)=>{ this.setState({ agreeCheckbox: v }) }}
                description="I understand this cannot be undone."
                style="align-left"
                />

              {
                orgReducer.deleteOrganizationForm.errors.error &&
                <Callout
                  style="danger"
                  dismissable={true}
                  content={orgReducer.deleteOrganizationForm.errors.error}
                />
              }

              <div className="modal-inner-button-row">
                <CustomButton
                  display="Cancel"
                  color="grey"
                  size="xs"
                  disabled={orgReducer.tryingToDeleteOrganization}
                  onClick={()=>{ 
                    this.setState({ deleteOrganizationModalOpen: false }) 
                  }}
                  />
                <CustomButton
                  display={"Permanently Delete Organization"}
                  color="danger"
                  size="xs"
                  thinking={orgReducer.tryingToDeleteOrganization}
                  success={orgReducer.deleteOrganizationSuccess}
                  fail={orgReducer.deleteOrganizationFail}
                  disabled={!canSubmit}
                  onClick={()=>{
                    if(canSubmit) this.handleSubmit();
                  }}
                  />
              </div>
            </div>}
          exitable={true}
          onExit={()=>{ 
            this.setState({ deleteOrganizationModalOpen: false }) 
          }}
          />
      </div>
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, orgReducer } = state;

  return {
    userReducer,
    orgReducer
  }
}

export default connect(mapStateToProps)(DeleteOrganizationForm);
