import Cookies from 'js-cookie';
import { push } from 'react-router-redux';

import addCookieWithConsent from '../../utilities/addCookieWithConsent';
import forceLogoutHandler from './a.users.forceLogoutHandler';

import {
  tryToLogout,
  updateLatestFeatures,
  tryToLoadFeaturesToken,
  receiveGetMembershipsSuccess,
  tryToGetUserNotifications,
  tryToGetMemberships,
} from '../actions.export'

let heartbeatCounter = 0;

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// heartbeat
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestHeartbeat = packet => {
  return {
    type: 'REQUEST_HEARTBEAT',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveHeartbeatSuccess = data => ({
  type: 'RECEIVE_HEARTBEAT_SUCCESS',
  data
})

export const receiveHeartbeatFail = data => ({
  type: 'RECEIVE_HEARTBEAT_FAIL',
  data
})

export const tryToHeartbeat = packet => (dispatch, getState) => {
  if(!packet) packet = {};

  dispatch(requestHeartbeat(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  
  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }

  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  let verboseMode = packet.verbose;

  return fetch('/api/users/heartbeat', {
      method: 'post',
      body: JSON.stringify({verbose: verboseMode}),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      heartbeatCounter++;
      dispatch(receiveHeartbeatSuccess(json.data));

      if(verboseMode){
        // addCookieWithConsent(process.env.REACT_APP_COOKIE_FEATURE_SWITCH, json.fs_token, dispatch);
        // Putting feature switch token in localstorage instead of cookie
        localStorage.setItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH, json.fs_token);

        dispatch(tryToLoadFeaturesToken());
        dispatch(tryToGetMemberships());
        dispatch(tryToGetUserNotifications());
      }
      
      heartbeatCounter++;

      if(json.data.suspended || json.data.maintenance){
        return dispatch(tryToLogout())
      }

      if(json.data.bumped && window.location.pathname !== "/interrupt"){
        return dispatch(push('/interrupt')) 
      }
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveHeartbeatFail({errors:e, lastSubmit: packet}));
    })
}
