import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {
  // tryToGetMemberships,
  // tryToGetOrganizationMembersLog
} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Query Organization Data
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestQueryOrganizationData = packet => {
  return {
    type: 'REQUEST_QUERY_ORGANIZATION_DATA',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveQueryOrganizationDataSuccess = data => ({
  type: 'RECEIVE_QUERY_ORGANIZATION_DATA_SUCCESS',
  data
})

export const receiveQueryOrganizationDataFail = data => ({
  type: 'RECEIVE_QUERY_ORGANIZATION_DATA_FAIL',
  data
})

export const tryToQueryOrganizationData = packet => (dispatch, getState) => {

  dispatch(requestQueryOrganizationData(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/organizations/query/' + packet.id, {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveQueryOrganizationDataSuccess({
        cacheID: packet.cacheID,
        data: json
      }));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveQueryOrganizationDataFail({errors:e, lastSubmit: packet}));
    })
}
