import React, { useState, useRef, useEffect } from 'react';
import './Carousel.scss';

const Carousel = ({ assets, assetHeight, margin }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const carouselRef = useRef(null);
  
  const totalWidth = assets.reduce(
    (acc, asset) => acc + parseInt(asset.width) + parseInt(margin),
    0
  );

  const resetPosition = totalWidth; 

  // This will handle automatic sliding
  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setScrollPosition((prevPosition) => {
          const newPosition = prevPosition + 1;
          return newPosition >= resetPosition ? 0 : newPosition;
        });
      }, 20); // Adjust the speed here
      return () => clearInterval(interval);
    }
  }, [isPaused, resetPosition]);

  return (
    <div
      className="carousel"
      ref={carouselRef}
      
    >
      <div
        className="carousel-track"
        style={{
          transform: `translateX(-${scrollPosition}px)`,
          transition: isPaused ? 'none' : 'transform 0.1s linear',
        }}
      >
        {assets.concat(assets).map((asset, index) => (
          <div
            key={index}
            className={"carousel-item " + (asset.border ? "carousel-item-border" : "")}
            style={{
              width: asset.width,
              height: assetHeight,
              marginRight: margin,
            }}
          >
            {asset.jsx}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
