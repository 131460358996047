import { push } from 'react-router-redux';
import Cookies from 'js-cookie';
import addCookieWithConsent from '../../utilities/addCookieWithConsent';

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// JoinWaitlist
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestJoinWaitlist = credentials => {
  return {
    type: 'REQUEST_JOIN_WAITLIST',
    data: {
      lastSubmit: credentials
    }
  }
}

export const receiveJoinWaitlistSuccess = data => ({
  type: 'RECEIVE_JOIN_WAITLIST_SUCCESS',
  data
})

export const receiveJoinWaitlistFail = data => ({
  type: 'RECEIVE_JOIN_WAITLIST_FAIL',
  data
})

export const tryToJoinWaitlist = credentials => (dispatch, getState) => {

  dispatch(requestJoinWaitlist(credentials));

  let headers = {
    "Content-Type": "application/json",
  }

  let fs = localStorage.getItem(process.env.REACT_APP_COOKIE_FEATURE_SWITCH);
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;

  return fetch('/api/users/waitlist', {
      method: 'post',
      body: JSON.stringify(credentials),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveJoinWaitlistSuccess(json.data));
    })
    .catch(e => {
      dispatch(receiveJoinWaitlistFail({errors:e, lastSubmit: credentials}));
    })
}
