const categories = [
  {
      "display_name": "Data Collection",
      "name": "data_collection",
      "description": "Gathering raw data from various sources.",
      "icon": "fas fa-database",
      "color": "#007bff"
  },
  {
      "display_name": "Data Parsing",
      "name": "parsing_unstructured_data",
      "description": "Converting unstructured data into a structured format.",
      "icon": "fas fa-cogs",
      "color": "#6c757d"
  },
  {
      "display_name": "Transaction Processing",
      "name": "transaction_processing",
      "description": "Handling and processing financial transactions.",
      "icon": "fas fa-credit-card",
      "color": "#28a745"
  },
  {
      "display_name": "Data Validation",
      "name": "data_validation",
      "description": "Ensuring accuracy and consistency of data.",
      "icon": "fas fa-check-circle",
      "color": "#17a2b8"
  },
  {
      "display_name": "Data Analysis",
      "name": "analysis",
      "description": "Extracting insights and patterns from data.",
      "icon": "fas fa-search",
      "color": "#ffc107"
  },
  {
      "display_name": "Content Generation",
      "name": "content_generation",
      "description": "Producing textual or multimedia content.",
      "icon": "fas fa-pencil-alt",
      "color": "#dc3545"
  },
  {
      "display_name": "User Interaction",
      "name": "user_interaction_feedback",
      "description": "Engaging with users and collecting their feedback.",
      "icon": "fas fa-users",
      "color": "#f8f9fa"
  },
  {
      "display_name": "Automation",
      "name": "automation_workflow_optimization",
      "description": "Streamlining and automating repetitive tasks.",
      "icon": "fas fa-robot",
      "color": "#343a40"
  },
  {
      "display_name": "Predictive Modeling",
      "name": "predictive_modeling",
      "description": "Using historical data to predict future outcomes.",
      "icon": "fas fa-chart-line",
      "color": "#fd7e14"
  },
  {
      "display_name": "Decision Support",
      "name": "decision_support",
      "description": "Providing assistance in making informed decisions.",
      "icon": "fas fa-balance-scale",
      "color": "#6610f2"
  },
  {
      "display_name": "Compliance Monitoring",
      "name": "security_compliance_monitoring",
      "description": "Ensuring the system adheres to legal and security standards.",
      "icon": "fas fa-shield-alt",
      "color": "#e83e8c"
  },
  {
      "display_name": "Personalization",
      "name": "personalization_customization",
      "description": "Tailoring the service or product to individual user needs.",
      "icon": "fas fa-sliders-h",
      "color": "#20c997"
  },
  {
      "display_name": "Resource Allocation",
      "name": "resource_allocation_management",
      "description": "Efficient distribution and management of resources.",
      "icon": "fas fa-tasks",
      "color": "#007bff"
  },
  {
      "display_name": "Process Monitoring and Control",
      "name": "process_monitoring_control",
      "description": "Overseeing ongoing operations and making necessary adjustments.",
      "icon": "fas fa-binoculars",
      "color": "#6f42c1"
  },
  {
      "display_name": "Quality Assurance",
      "name": "quality_assurance_testing",
      "description": "Ensuring the product/service meets quality standards.",
      "icon": "fas fa-medal",
      "color": "#fd7e14"
  },
  {
      "display_name": "Integration",
      "name": "integration_data_synchronization",
      "description": "Combining multiple systems or datasets and keeping them in sync.",
      "icon": "fas fa-sync-alt",
      "color": "#6c757d"
  },
  {
      "display_name": "User Experience Design",
      "name": "user_experience_design_improvement",
      "description": "Designing and refining the user interface and experience.",
      "icon": "fas fa-object-group",
      "color": "#17a2b8"
  },
  {
      "display_name": "Market Analysis",
      "name": "market_analysis_strategy_development",
      "description": "Analyzing market trends and formulating strategies.",
      "icon": "fas fa-chart-pie",
      "color": "#28a745"
  },
  {
      "display_name": "Training and Education",
      "name": "training_educational_resources",
      "description": "Providing learning materials and resources for users or employees.",
      "icon": "fas fa-chalkboard-teacher",
      "color": "#e83e8c"
  },
  {
      "display_name": "Customer Support",
      "name": "customer_support_service",
      "description": "Assisting users with queries and issues.",
      "icon": "fas fa-headset",
      "color": "#ffc107"
  },
  {
      "display_name": "Reporting",
      "name": "reporting_documentation",
      "description": "Generating reports and maintaining records.",
      "icon": "fas fa-file-alt",
      "color": "#dc3545"
  },
  {
      "display_name": "Community Building",
      "name": "community_building_management",
      "description": "Creating and managing user communities.",
      "icon": "fas fa-users-cog",
      "color": "#f8f9fa"
  },
  {
      "display_name": "Brand Management",
      "name": "brand_reputation_management",
      "description": "Managing public perception and brand image.",
      "icon": "fas fa-bullhorn",
      "color": "#343a40"
  },
  {
      "display_name": "Innovation and Product Development",
      "name": "innovation_product_development",
      "description": "Developing new products or improving existing ones.",
      "icon": "fas fa-lightbulb",
      "color": "#007bff"
  }
]


export default categories;