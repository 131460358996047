import { push } from 'react-router-redux';
import Cookies from 'js-cookie';

import forceLogoutHandler from '../users/a.users.forceLogoutHandler';

import {

} from '../actions.export'

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Process feedback for a project description
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

export const requestInquiryFoundationsFeedback = packet => {
  return {
    type: 'REQUEST_INQUIRY_FOUNDATIONS_FEEDBACK',
    data: {
      lastSubmit: packet
    }
  }
}

export const receiveInquiryFoundationsFeedbackSuccess = data => ({
  type: 'RECEIVE_INQUIRY_FOUNDATIONS_FEEDBACK_SUCCESS',
  data
})

export const receiveInquiryFoundationsFeedbackFail = data => ({
  type: 'RECEIVE_INQUIRY_FOUNDATIONS_FEEDBACK_FAIL',
  data
})

export const tryToInquiryFoundationsFeedback = packet => (dispatch, getState) => {

  dispatch(requestInquiryFoundationsFeedback(packet));

  let headers = {
    "Content-Type": "application/json"
  }
  


  let fs = Cookies.get(process.env.REACT_APP_COOKIE_FEATURE_SWITCH)
  if(fs){
    headers["X-NFS"] = fs;
  }


  headers["X-CSRF-TOKEN"] = getState().userReducer.csrf_token;
  return fetch('/api/inquiries/foundations-feedback/' + packet.inquiry_id + '/' + packet.pillar, {
      method: 'post',
      body: JSON.stringify(packet),
      headers: headers
    })
    .then((response) => {
      let json = response.json();
      if (response.status >= 200 && response.status < 300) return json;
      return json.then(Promise.reject.bind(Promise));
    })
    .then(function(json){
      dispatch(receiveInquiryFoundationsFeedbackSuccess(json));
    })
    .catch(e => {
      dispatch(forceLogoutHandler(e));
      dispatch(receiveInquiryFoundationsFeedbackFail({errors:e, lastSubmit: packet}));
    })
}
